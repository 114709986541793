import { Color } from "../../../lib/color";
import {
  convertMeasurementSystem,
  convertPipeDiameterFromMetric,
  Precision,
  Units,
  UnitsContext,
} from "../../../lib/measurements";
import { SentryEntityError } from "../../../lib/sentry-entity-error";
import {
  assertUnreachable,
  Choice,
  cloneSimple,
  parseCatalogNumberExact,
  parseCatalogNumberOrMin,
} from "../../../lib/utils";
import { CoreContext, PipeConfiguration } from "../../calculations/types";
import { DuctManufacturerSpec } from "../../catalog/ventilation/ducts";
import {
  DuctPhysicalMaterial,
  isGas,
  isSewer,
  isStormwater,
  PIPE_MATERIAL_ROLES,
  PipePhysicalMaterial,
  StandardFlowSystemUids,
} from "../../config";
import CoreConduit from "../../coreObjects/coreConduit";
import { getFloorHeight } from "../../coreObjects/utils";
import { getTooltip } from "../../tooltips/tooltips";
import {
  AUX_SYSTEM_NETWORKS,
  DuctFlowSystemTypes,
  flowSystemHasVent,
  getSimilarNetwork,
  HORIZONTAL_SYSTEM_NETWORKS_BY_PRIORITY,
  isDuctFlowSystem,
  isPipeFlowSystem,
  PipeFlowSystemTypes,
  PipeMaterialRole,
  SIZING_MODE_CHOICES,
  VentilationFlowSystem,
  VERTICAL_SYSTEM_NETWORKS_BY_PRIORITY,
  type NetworkType,
} from "../flow-systems";
import { getFlowSystem } from "../utils";
import { FieldType } from "./field-type";
import { PropertyField } from "./property-field";
import {
  BaseEdgeEntity,
  DrawableEntity,
  DrawableEntityV1,
  NamedEntity,
} from "./simple-entities";
import { EntityType, EntityTypeV1 } from "./types";
import {
  getEntityNetwork,
  getEntitySystem,
  getPipeMaterial,
  getPipesChoices,
} from "./utils";

export interface PipeEntityV1 extends DrawableEntityV1, NamedEntity {
  type: EntityTypeV1.PIPE;
  parentUid: null;

  systemUid: StandardFlowSystemUids | string;
  network: NetworkType;

  material: PipePhysicalMaterial | null;
  maximumVelocityMS: number | null;
  maximumPressureDropRateKPAM: number | null;
  diameterMM: number | null;
  gradePCT: number | null;
  configurationCosmetic: PipeConfiguration | null;

  lengthM: number | null;
  heightAboveFloorM: number;

  color: Color | null;
  readonly endpointUid: [string, string];
}

interface ConduitEntityBase
  extends DrawableEntity,
    NamedEntity,
    BaseEdgeEntity {
  type: EntityType.CONDUIT;

  conduitType: ConduitType;

  parentUid: null;

  systemUid: StandardFlowSystemUids | string;

  conduit: ConduitConcrete;

  lengthM: number | null;
  heightAboveFloorM: number;

  color: Color | null;
}

export interface PipeConduitEntity extends ConduitEntityBase {
  conduitType: "pipe";
  conduit: PipeConduit;
}

export interface DuctConduitEntity extends ConduitEntityBase {
  conduitType: "duct";
  conduit: DuctConduit;
}

export interface CableConduitEntity extends ConduitEntityBase {
  conduitType: "cable";
  conduit: CableConduit;
}

export type ConduitType = "pipe" | "duct" | "cable";

type ConduitEntity = PipeConduitEntity | DuctConduitEntity | CableConduitEntity;

export default ConduitEntity;

export interface PipeConduit {
  network: NetworkType;

  material: PipePhysicalMaterial | null;
  maximumVelocityMS: number | null;
  maximumPressureDropRateKPAM: number | null;
  diameterMM: number | null;
  gradePCT: number | null;
  configurationCosmetic: PipeConfiguration | null;
}

export interface DuctConduit {
  shape: "circular" | "rectangular" | null;
  sizingMode: "discrete" | "increment" | "continuous" | null;
  sizingIncrementMM: number | null;
  rectSizingMethod: "max-dimensions" | "fixed-dimensions";

  diameterMM: number | null;
  widthMM: number | null;
  heightMM: number | null;
  maxWidthMM: number | null;
  maxHeightMM: number | null;
  targetWHRatio: number | null;

  // We need an angle for vertical segments that are rectangular.
  // Assume this rotation is about the axis of the duct.
  angleDEG: number | null;
  network: NetworkType;

  // As per 4.8.1 CIBSE Guide C, the maximum velocity for ducts is 6m/s
  maximumVelocityMS: number | null;
  maximumPressureDropRateKPAM: number | null;
  material: DuctPhysicalMaterial | null;
}

export interface CableConduit {}

export type ConduitConcrete = PipeConduit | DuctConduit | CableConduit;

export function isPipeEntity(
  entity: DrawableEntity | undefined,
): entity is PipeConduitEntity {
  return !!(
    entity &&
    entity.type === EntityType.CONDUIT &&
    (entity as ConduitEntity).conduitType === "pipe"
  );
}

export function isDuctEntity(
  entity: DrawableEntity | undefined,
): entity is DuctConduitEntity {
  return !!(
    entity &&
    entity.type === EntityType.CONDUIT &&
    (entity as ConduitEntity).conduitType === "duct"
  );
}

export function getConduitTypeName(type: ConduitType) {
  switch (type) {
    case "pipe":
      return "Pipe";
    case "duct":
      return "Duct";
    case "cable":
      return "Cable";
  }
  assertUnreachable(type);
}

export function getConduitName(entity: ConduitEntity) {
  return getConduitTypeName(entity.conduitType);
}

export interface MutableConduit {
  type: EntityType.CONDUIT;

  endpointUid: readonly [string, string];
}

export function networkTypeToChoice(type: NetworkType): Choice {
  switch (type) {
    case "risers":
    case "stacks":
      return {
        key: type,
        name: type[0].toUpperCase() + type.substring(1),
        disabled: true,
      };
    case "branches":
    case "connections":
    case "mains":
    case "pipes":
    case "reticulations":
    case "vents":
      return { key: type, name: type[0].toUpperCase() + type.substring(1) };
  }
  assertUnreachable(type, false);
}

function createConduitGeneralTabFields(
  entity: ConduitEntity,
  context: CoreContext,
): PropertyField[] {
  const { drawing, catalog, globalStore } = context;

  const floorHeight = getFloorHeight(globalStore, drawing, entity);

  const height = convertMeasurementSystem(
    drawing.metadata.units,
    Units.Meters,
    floorHeight + entity.heightAboveFloorM,
    Precision.DISPLAY,
  );

  const fields: PropertyField[] = [];
  fields.push(
    {
      property: "systemUid",
      title: "Flow System",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.FlowSystemChoice,
      beforeSet: (newFlowSystemUid: string) => {
        if (isPipeEntity(entity)) {
          entity.conduit.network = getSimilarNetwork(
            entity.conduit.network,
            context.drawing.metadata.flowSystems[newFlowSystemUid].type,
          );
        }
      },
      params: {
        systems: drawing.metadata.flowSystemUidsInOrder
          .map((id) => drawing.metadata.flowSystems[id])
          .filter((s) => {
            switch (entity.conduitType) {
              case "cable":
                throw new Error("not implemented");
              case "duct":
                return isDuctFlowSystem(s);
              case "pipe":
                return isPipeFlowSystem(s);
            }
            assertUnreachable(entity);
          }),
      },
      multiFieldId: "systemUid",
    },

    {
      property: "color",
      title: "Color",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Color,
      params: null,
      multiFieldId: "color",
    },

    {
      property: "lengthM",
      title: "Length",
      hasDefault: false,
      highlightOnOverride: true,
      isCalculated: true,
      type: FieldType.Number,
      params: { min: 0, max: null, initialValue: 0 },
      multiFieldId: null,
      units: Units.Meters,
    },
    {
      property: "heightAboveFloorM",
      title: "Height Above Floor",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: null, max: null },
      multiFieldId: "heightAboveFloorM",
      units: Units.Meters,
      description: `Height = ${height[1]}${height[0]}`,
    },
  );

  return fields;
}

function createPipeTechnicalTabFields(
  filled: PipeConduitEntity,
  context: CoreContext,
): PropertyField[] {
  const { drawing, catalog, globalStore } = context;

  const materials = Object.keys(catalog.pipes).map((mat) => {
    const c: Choice = {
      disabled: false,
      key: mat,
      name: catalog.pipes[mat].name,
    };
    return c;
  });
  const flowSystem = getFlowSystem(drawing, filled.systemUid)!;
  const material = getPipeMaterial(filled);
  const diameters = getPipesChoices({
    material,
    metadata: drawing.metadata,
    catalog,
    flowSystem,
    entity: filled,
    ensureNonEmpty: true,
  });

  const iAmSewage = isSewer(drawing.metadata.flowSystems[filled.systemUid]);
  const iAmStormwater = isStormwater(
    drawing.metadata.flowSystems[filled.systemUid],
  );
  const iAmGas = isGas(drawing.metadata.flowSystems[filled.systemUid]);

  const fields: PropertyField[] = [];

  fields.push(
    {
      property: "conduit.network",
      title: "Network Type",
      hint: getTooltip("Pipe", "Network Type"),
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Choice,
      multiFieldId: "network",
      params: {
        choices: [
          ...VERTICAL_SYSTEM_NETWORKS_BY_PRIORITY[flowSystem.type],
          ...HORIZONTAL_SYSTEM_NETWORKS_BY_PRIORITY[flowSystem.type],
          ...AUX_SYSTEM_NETWORKS[flowSystem.type],
        ]
          .map(networkTypeToChoice)
          .filter(Boolean),
      },
    },

    {
      property: "conduit.material",
      title: "Material",
      hasDefault: true,
      highlightOnOverride: true,
      isCalculated: false,
      type: FieldType.Choice,
      params: {
        choices: getAvailablePipeMaterials(materials, {
          drainage: iAmSewage || iAmStormwater,
          pressure: !(iAmSewage || iAmStormwater),
          ufh: false,
        }),
      },
      multiFieldId: "material",
    },

    {
      property: "conduit.diameterMM",
      title: "Diameter",
      hint: getTooltip("Pipe", "Diameter"),
      hasDefault: false,
      highlightOnOverride: true,
      isCalculated: true,
      type: FieldType.Choice,
      params: {
        choices: diameters,
        initialValue: diameters && diameters.length ? diameters[0].key : null,
      },
      multiFieldId: "diameterMM",
      readonly: iAmGas,
    },
  );

  if (!iAmSewage) {
    fields.push({
      property: "conduit.maximumVelocityMS",
      title: "Max. Velocity",
      hint: getTooltip("Pipe", "Maximum Velocity"),
      hasDefault: true,
      highlightOnOverride: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "maximumVelocityMS",
      units: Units.MetersPerSecond,
    });

    fields.push({
      property: "conduit.maximumPressureDropRateKPAM",
      title: "Max. Pressure Drop",
      hint: getTooltip("Pipe", "Maximum Pressure Drop"),
      hasDefault: true,
      highlightOnOverride: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "maximumPressureDropRateKPAM",
      units: Units.KiloPascalsPerMeter,
    });
  } else {
    if (filled.conduit.network === "reticulations") {
      fields.push({
        property: "conduit.gradePCT",
        title: "Grade (%)",
        hasDefault: false,
        isCalculated: true,
        highlightOnOverride: true,
        type: FieldType.Number,
        params: { min: 0, max: null, initialValue: 0 },
        multiFieldId: "gradePCT",
        units: Units.None,
      });
    }
  }

  return fields;
}

function createDuctTechnicalTabFields(
  filled: DuctConduitEntity,
  context: CoreContext,
): PropertyField[] {
  const { drawing, catalog, globalStore } = context;

  const fields: PropertyField[] = [];

  const flowSystem = getFlowSystem<"ventilation">(drawing, filled.systemUid)!;

  const materials = Object.keys(catalog.ducts).map((mat) => {
    const c: Choice = {
      disabled: false,
      key: mat,
      name: catalog.ducts[mat as DuctPhysicalMaterial]!.name,
    };
    return c;
  });

  fields.push(
    {
      property: "conduit.network",
      title: "Network Type",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Choice,
      multiFieldId: "network",
      params: {
        choices: [
          ...VERTICAL_SYSTEM_NETWORKS_BY_PRIORITY[flowSystem.type],
          ...HORIZONTAL_SYSTEM_NETWORKS_BY_PRIORITY[flowSystem.type],
          ...AUX_SYSTEM_NETWORKS[flowSystem.type],
        ]
          .map(networkTypeToChoice)
          .filter(Boolean),
      },
    },
    {
      property: "conduit.maximumVelocityMS",
      title: "Max. Velocity",
      hasDefault: true,
      highlightOnOverride: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "maximumVelocityMS",
      units: Units.MetersPerSecond,
    },
    {
      property: "conduit.maximumPressureDropRateKPAM",
      title: "Max. Pressure Drop",
      hint: getTooltip("Pipe", "Maximum Pressure Drop"),
      hasDefault: true,
      highlightOnOverride: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "maximumPressureDropRateKPAM",
      unitContext: UnitsContext.VENTILATION,
      units: Units.KiloPascalsPerMeter,
    },
    {
      property: "conduit.material",
      title: "Material",
      hasDefault: true,
      highlightOnOverride: true,
      isCalculated: false,
      type: FieldType.Choice,
      params: {
        choices: materials,
      },
      multiFieldId: "material",
    },
  );
  const o = globalStore.getObjectOfTypeOrThrow(EntityType.CONDUIT, filled.uid);
  const orig = o.entity as DuctConduitEntity;
  const page = CoreConduit.getDuctManufacturerCatalogPage(context, filled);

  fields.push(
    ...makeDuctConduitSizingFields(
      context,
      filled.conduit,
      orig.conduit,
      flowSystem,
      page,
    ),
  );

  return fields;
}

export function makeDuctConduitSizingFields(
  context: CoreContext,
  filledDC: DuctConduit,
  originalDC: DuctConduit,
  flowSystem: VentilationFlowSystem,
  page: DuctManufacturerSpec | null,
  prefix = "conduit.",
): PropertyField[] {
  if (prefix != "" && prefix[prefix.length - 1] != ".") {
    prefix += ".";
  }
  const { drawing, catalog, globalStore } = context;
  const fields: PropertyField[] = [];
  fields.push({
    property: `${prefix}sizingMode`,
    title: "Sizing Mode",
    hasDefault: true,
    isCalculated: false,
    type: FieldType.Choice,
    multiFieldId: "sizingMode",
    params: {
      choices: SIZING_MODE_CHOICES,
    },
  });

  if (filledDC.sizingMode === "increment") {
    fields.push({
      property: `${prefix}sizingIncrementMM`,
      title: "Sizing Increment",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      params: {
        min: 0,
        max: null,
        initialValue: 50,
      },
      multiFieldId: "sizingIncrementMM",
      units: Units.Millimeters,
    });
  }

  if (filledDC.shape) {
    fields.push({
      property: `${prefix}shape`,
      title: "Shape",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Choice,
      multiFieldId: "shape",
      params: {
        choices: [
          { key: "circular", name: "Circular" },
          { key: "rectangular", name: "Rectangular" },
        ],
      },
    });

    const sizeBeforeSetFn =
      filledDC.sizingMode === "increment"
        ? (size: number) => {
            return (
              Math.max(1, Math.round(size / filledDC.sizingIncrementMM!)) *
              filledDC.sizingIncrementMM!
            );
          }
        : undefined;

    const sizeStep =
      filledDC.sizingMode === "increment"
        ? { step: filledDC.sizingIncrementMM }
        : {};

    const minDuctDiameterSize = Math.max(
      flowSystem.networks[filledDC.network]!.minimumDuctDiameterSizeMM,
      filledDC.sizingMode === "increment" ? filledDC.sizingIncrementMM! : 0,
    );
    const minDuctWidthSize = Math.max(
      flowSystem.networks[filledDC.network]!.minimumDuctWidthSizeMM,
      filledDC.sizingMode === "increment" ? filledDC.sizingIncrementMM! : 0,
    );
    const minDuctHeightSize = Math.max(
      flowSystem.networks[filledDC.network]!.minimumDuctHeightSizeMM,
      filledDC.sizingMode === "increment" ? filledDC.sizingIncrementMM! : 0,
    );

    switch (filledDC.shape) {
      case "circular":
        switch (filledDC.sizingMode) {
          case "continuous":
          case "increment": {
            fields.push({
              property: `${prefix}diameterMM`,
              title: "Diameter",
              hasDefault: false,
              isCalculated: true,

              type: FieldType.Number,
              params: {
                min: minDuctDiameterSize,
                max: null,
                initialValue: minDuctDiameterSize,
                ...sizeStep,
              },
              multiFieldId: "diameterMM",
              units: Units.Millimeters,
              beforeSet: sizeBeforeSetFn,
            });
            break;
          }
          case "discrete":
            if (page) {
              const choices = Object.keys(page.circularByDiameter).map((d) => {
                const val = convertPipeDiameterFromMetric(
                  drawing.metadata.units,
                  parseCatalogNumberExact(d),
                );
                const c: Choice = {
                  disabled: false,
                  key: parseCatalogNumberOrMin(d),
                  name: val[1] + val[0],
                };
                return c;
              });
              fields.push({
                property: `${prefix}diameterMM`,
                title: "Diameter",
                hasDefault: false,
                isCalculated: true,

                type: FieldType.Choice,
                params: {
                  choices,
                  initialValue: choices && choices.length ? choices[0].key : 0,
                },
                multiFieldId: "diameterMM",
                units: Units.Millimeters,
              });
            }
          case null:
            break;
          default:
            assertUnreachable(filledDC.sizingMode);
        }

        break;
      case "rectangular":
        fields.push({
          property: `${prefix}rectSizingMethod`,
          title: "Sizing Method",
          hasDefault: false,
          isCalculated: false,
          type: FieldType.Choice,
          multiFieldId: "rectSizingMethod",
          params: {
            choices: [
              { key: "max-dimensions", name: "Max. Dimensions" },
              { key: "fixed-dimensions", name: "Fixed Dimensions" },
            ],
          },
          hint: "Max. Dimensions: Specify max width and/or height, smaller ducts will use the target WH ratio. Fixed Dimensions: Fixed width and/or height, and the other dimension is calculated.",
        });

        if (filledDC.rectSizingMethod === "max-dimensions") {
          switch (filledDC.sizingMode) {
            case "continuous":
            case "increment": {
              fields.push(
                {
                  property: `${prefix}maxWidthMM`,
                  title: "Max. Width",
                  hasDefault: false,
                  isCalculated: true,

                  type: FieldType.Number,
                  params: {
                    min: minDuctWidthSize,
                    max: null,
                    initialValue: minDuctWidthSize,
                    ...sizeStep,
                  },
                  beforeSet: sizeBeforeSetFn,
                  multiFieldId: "maxWidthMM",
                  units: Units.Millimeters,
                },
                {
                  property: `${prefix}maxHeightMM`,
                  title: "Max. Height",
                  hasDefault: false,
                  isCalculated: true,

                  type: FieldType.Number,
                  params: {
                    min: minDuctHeightSize,
                    max: null,
                    initialValue: minDuctHeightSize,
                    ...sizeStep,
                  },
                  beforeSet: sizeBeforeSetFn,
                  multiFieldId: "maxHeightMM",
                  units: Units.Millimeters,
                },
              );
              break;
            }
            case "discrete": {
              if (!page) {
                break;
              }
              const heightsMM = Object.keys(page.rectangularByHeightWidth).map(
                parseCatalogNumberExact,
              );
              const widthsSet = new Set<number>();
              for (const val of Object.values(page.rectangularByHeightWidth)) {
                for (const width of Object.keys(val)) {
                  widthsSet.add(parseCatalogNumberExact(width)!);
                }
              }
              const widthsMM = Array.from(widthsSet).sort((a, b) => a - b);

              const heightChoices = heightsMM.map((h) => {
                const val = convertMeasurementSystem(
                  drawing.metadata.units,
                  Units.Millimeters,
                  h,
                  Precision.DISPLAY,
                );
                const c: Choice = {
                  disabled: false,
                  key: h,
                  name: val[1] + val[0],
                };
                return c;
              });
              const widthChoices = widthsMM.map((w) => {
                const val = convertMeasurementSystem(
                  drawing.metadata.units,
                  Units.Millimeters,
                  w,
                  Precision.DISPLAY,
                );
                const c: Choice = {
                  disabled: false,
                  key: w,
                  name: val[1] + val[0],
                };
                return c;
              });

              fields.push(
                {
                  property: `${prefix}maxWidthMM`,
                  title: "Max. Width",
                  hasDefault: false,
                  isCalculated: true,

                  type: FieldType.Choice,
                  params: {
                    choices: widthChoices,
                    initialValue:
                      widthChoices && widthChoices.length
                        ? widthChoices[0].key
                        : null,
                  },
                  multiFieldId: "maxWidthMM",
                  units: Units.Millimeters,
                },
                {
                  property: `${prefix}maxHeightMM`,
                  title: "Max. Height",
                  hasDefault: false,
                  isCalculated: true,

                  type: FieldType.Choice,
                  params: {
                    choices: heightChoices,
                    initialValue:
                      heightChoices && heightChoices.length
                        ? heightChoices[0].key
                        : null,
                  },
                  multiFieldId: "maxHeightMM",
                  units: Units.Millimeters,
                },
              );
            }
          }
        }

        if (filledDC.rectSizingMethod === "fixed-dimensions") {
          switch (filledDC.sizingMode) {
            case "continuous":
            case "increment": {
              fields.push(
                {
                  property: `${prefix}widthMM`,
                  title: "Width",
                  hasDefault: false,
                  isCalculated: true,

                  type: FieldType.Number,
                  params: {
                    min: minDuctWidthSize,
                    max: null,
                    initialValue: minDuctWidthSize,
                    ...sizeStep,
                  },
                  multiFieldId: "widthMM",
                  units: Units.Millimeters,
                  beforeSet: sizeBeforeSetFn,
                },
                {
                  property: `${prefix}heightMM`,
                  title: "Height",
                  hasDefault: false,
                  isCalculated: true,

                  type: FieldType.Number,
                  params: {
                    min: minDuctHeightSize,
                    max: null,
                    initialValue: minDuctHeightSize,
                    ...sizeStep,
                  },
                  multiFieldId: "heightMM",
                  units: Units.Millimeters,
                  beforeSet: sizeBeforeSetFn,
                },
              );
              break;
            }
            case "discrete": {
              if (!page) {
                break;
              }
              const heightsMM = Object.keys(page.rectangularByHeightWidth).map(
                parseCatalogNumberExact,
              );
              const widthsSet = new Set<number>();
              for (const val of Object.values(page.rectangularByHeightWidth)) {
                for (const width of Object.keys(val)) {
                  widthsSet.add(parseCatalogNumberExact(width)!);
                }
              }
              const widthsMM = Array.from(widthsSet).sort((a, b) => a - b);

              const heightChoices = heightsMM.map((h) => {
                const val = convertMeasurementSystem(
                  drawing.metadata.units,
                  Units.Millimeters,
                  h,
                  Precision.DISPLAY,
                );
                const c: Choice = {
                  disabled: false,
                  key: h,
                  name: val[1] + val[0],
                };
                return c;
              });
              const widthChoices = widthsMM.map((w) => {
                const val = convertMeasurementSystem(
                  drawing.metadata.units,
                  Units.Millimeters,
                  w,
                  Precision.DISPLAY,
                );
                const c: Choice = {
                  disabled: false,
                  key: w,
                  name: val[1] + val[0],
                };
                return c;
              });

              fields.push(
                {
                  property: `${prefix}widthMM`,
                  title: "Width",
                  hasDefault: false,
                  isCalculated: true,

                  type: FieldType.Choice,
                  params: {
                    choices: widthChoices,
                    initialValue:
                      widthChoices && widthChoices.length
                        ? widthChoices[0].key
                        : null,
                  },
                  multiFieldId: "widthMM",
                  units: Units.Millimeters,
                  beforeSet: (widthMM: number) => {
                    if (originalDC.heightMM != null) {
                      // set height to closest available value
                      const availableHeights = Object.keys(
                        page.rectangularByHeightWidth[widthMM] ?? {},
                      )
                        .map(parseCatalogNumberExact)
                        .map(Number);
                      const closestHeight = availableHeights.reduce(
                        (prev, curr) =>
                          Math.abs(curr - originalDC.heightMM!) <
                          Math.abs(prev - originalDC.heightMM!)
                            ? curr
                            : prev,
                      );
                      originalDC.heightMM = closestHeight;
                    }
                  },
                },
                {
                  property: `${prefix}heightMM`,
                  title: "Height",
                  hasDefault: false,
                  isCalculated: true,

                  type: FieldType.Choice,
                  params: {
                    choices: heightChoices,
                    initialValue:
                      heightChoices && heightChoices.length
                        ? heightChoices[0].key
                        : null,
                  },
                  multiFieldId: "heightMM",
                  units: Units.Millimeters,
                  beforeSet: (heightMM: number) => {
                    if (originalDC.widthMM != null) {
                      // set width to closest available value
                      const availableWidths = Object.entries(
                        page.rectangularByHeightWidth,
                      )
                        .filter(([key, entry]) => entry[heightMM] != null)
                        .map(([key, entry]) => key)
                        .map(parseCatalogNumberExact)
                        .map(Number);
                      const closestWidth = availableWidths.reduce(
                        (prev, curr) =>
                          Math.abs(curr - originalDC.widthMM!) <
                          Math.abs(prev - originalDC.widthMM!)
                            ? curr
                            : prev,
                      );
                      originalDC.widthMM = closestWidth;
                    }
                  },
                },
              );
              break;
            }
            case null:
              break;
            default:
              assertUnreachable(filledDC.sizingMode);
          }
        }

        if (
          originalDC.widthMM == null ||
          originalDC.heightMM == null ||
          originalDC.rectSizingMethod === "max-dimensions"
        ) {
          fields.push({
            property: `${prefix}targetWHRatio`,
            title: "Target Width:Height Ratio",
            hasDefault: true,
            isCalculated: false,

            type: FieldType.Number,
            params: {
              min: 0,
              max: null,
            },
            multiFieldId: "targetWHRatio",
            units: Units.None,
          });
        }

        break;
      default:
        assertUnreachable(filledDC.shape);
    }
  }

  return fields;
}

function makePipeTabs(
  context: CoreContext,
  filled: PipeConduitEntity,
): PropertyField {
  return {
    type: FieldType.Tabs,
    id: "pipe-tabs",
    tabs: [
      {
        tabId: "general",
        tabName: "General",
        fields: createConduitGeneralTabFields(filled, context),
      },
      {
        tabId: "technical",
        tabName: "Technical",
        fields: createPipeTechnicalTabFields(filled, context),
      },
    ],
  };
}

function makeDuctTabs(
  context: CoreContext,
  filled: DuctConduitEntity,
): PropertyField {
  return {
    type: FieldType.Tabs,
    id: "pipe-tabs",
    tabs: [
      {
        tabId: "general",
        tabName: "General",
        fields: createConduitGeneralTabFields(filled, context),
      },
      {
        tabId: "technical",
        tabName: "Technical",
        fields: createDuctTechnicalTabFields(filled, context),
      },
    ],
  };
}

export function makeConduitFields(
  context: CoreContext,
  entity: ConduitEntity,
): PropertyField[] {
  const { drawing, catalog, globalStore } = context;
  const result = fillDefaultConduitFields(context, entity);
  const fields: PropertyField[] = [];

  fields.push({
    property: "entityName",
    title: "Name",
    hasDefault: false,
    isCalculated: false,
    type: FieldType.Text,
    params: null,
    multiFieldId: "entityName",
  });

  if (isPipeEntity(result)) {
    fields.push(makePipeTabs(context, result));
  } else if (isDuctEntity(result)) {
    fields.push(makeDuctTabs(context, result));
  }

  return fields;
}

function fillDefaultPipeFields(
  context: CoreContext,
  resultMut: PipeConduitEntity,
) {
  const { drawing, catalog } = context;
  const system = getEntitySystem(drawing, resultMut);

  if (system) {
    resultMut.conduit.network = getSimilarNetwork(
      resultMut.conduit.network,
      system.type,
    );
    const network = getEntityNetwork<PipeFlowSystemTypes>(drawing, resultMut);
    if (network) {
      if (resultMut.conduit.maximumVelocityMS == null) {
        resultMut.conduit.maximumVelocityMS =
          "velocityMS" in network ? Number(network.velocityMS) : 1e10;
      }
      if (resultMut.conduit.maximumPressureDropRateKPAM == null) {
        resultMut.conduit.maximumPressureDropRateKPAM =
          "pressureDropKPAM" in network
            ? Number(network.pressureDropKPAM)
            : 1e10;
      }
      if (resultMut.conduit.material == null) {
        resultMut.conduit.material = network.material;
      }
    } else {
      throw new SentryEntityError("Pipe Network is missing", resultMut.uid, {
        systemUid: system.uid,
        networkType: resultMut.conduit.network,
      });
    }
    if (resultMut.color == null) {
      resultMut.color = system.color;
      if (flowSystemHasVent(system)) {
        if (resultMut.conduit.network === "vents") {
          resultMut.color = system.ventColor;
        }
      }
    }
  } else {
    throw new Error(
      "Existing system not found for object " + JSON.stringify(resultMut),
    );
  }
}

export function fillDefaultDuctFields(
  context: CoreContext,
  resultMut: DuctConduitEntity,
) {
  const { drawing, catalog } = context;
  const system = getEntitySystem(drawing, resultMut);
  const calc = context.globalStore.getOrCreateCalculation(resultMut);

  if (system) {
    resultMut.conduit.network = getSimilarNetwork(
      resultMut.conduit.network,
      system.type,
    );
    const network = getEntityNetwork<DuctFlowSystemTypes>(drawing, resultMut);
    if (network) {
      if (resultMut.conduit.maximumVelocityMS == null) {
        resultMut.conduit.maximumVelocityMS = network.velocityMS;
      }
      if (resultMut.conduit.maximumPressureDropRateKPAM == null) {
        resultMut.conduit.maximumPressureDropRateKPAM =
          "pressureDropKPAM" in network
            ? Number(network.pressureDropKPAM)
            : 1e10;
      }
      if (resultMut.conduit.material == null) {
        resultMut.conduit.material = network.material;
      }

      if (resultMut.conduit.shape == null) {
        resultMut.conduit.shape = network.shape;
      }

      if (resultMut.conduit.sizingIncrementMM == null) {
        resultMut.conduit.sizingIncrementMM = network.sizingIncrementMM;
      }

      if (resultMut.conduit.sizingMode == null) {
        resultMut.conduit.sizingMode = network.sizingMode;
      }

      switch (resultMut.conduit.shape) {
        case "circular":
          if (resultMut.conduit.diameterMM == null) {
            resultMut.conduit.diameterMM = calc.diameterMM;
          }
          break;
        case "rectangular":
          if (resultMut.conduit.targetWHRatio == null) {
            resultMut.conduit.targetWHRatio = network.rectangleWHRatio;
          }
          // widthMM and heightMM variables are used to transfer calculation
          // results so we should overwrite them too when they are not applicable in
          // the UI - so that moot overridden values here don't block calculation
          // results from being set.
          // If the mode was "fixed-dimensions" though, then the overridden values
          // take effect and so don't need to be overwritten.
          if (
            resultMut.conduit.rectSizingMethod !== "fixed-dimensions" ||
            resultMut.conduit.widthMM == null
          ) {
            resultMut.conduit.widthMM = calc.widthMM;
          }
          if (
            resultMut.conduit.rectSizingMethod !== "fixed-dimensions" ||
            resultMut.conduit.heightMM == null
          ) {
            resultMut.conduit.heightMM = calc.heightMM;
          }
          break;
      }
    }
    if (!resultMut.color) {
      resultMut.color = system.color;
    }
  } else {
    throw new Error(
      "Existing system not found for object " + JSON.stringify(resultMut),
    );
  }
}
export function fillDefaultConduitFields<T extends ConduitEntity>(
  context: CoreContext,
  entity: T,
): T {
  const { globalStore } = context;
  const result = cloneSimple(entity);

  // Can be null if you delete the conduit while its properties window is open
  const obj = globalStore.getObjectOfType(EntityType.CONDUIT, entity.uid);
  const computedLengthM = obj?.computedLengthM ?? null;

  if (result.lengthM == null) {
    result.lengthM = computedLengthM;
  }

  if (isPipeEntity(result)) {
    fillDefaultPipeFields(context, result);
  } else if (isDuctEntity(result)) {
    fillDefaultDuctFields(context, result);
  } else {
    throw new Error("not supported");
  }
  return result;
}

export function getAvailablePipeMaterials(
  allChoices: Choice[],
  systemRolesAllowed: Record<PipeMaterialRole, boolean>,
): Choice[] {
  return allChoices.filter((c) =>
    (PIPE_MATERIAL_ROLES[c.key as PipePhysicalMaterial] || []).some(
      (role) => systemRolesAllowed[role],
    ),
  );
}
