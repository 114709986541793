import { GlobalStore } from "../../../lib/globalstore/global-store";
import { DrawableEntityConcrete } from "../entities/concrete-entity";
import { getEntityIndividualName } from "../entities/types";
import { EntityAnalyticProperties } from "./analytics";

export function getEntityAnalyticProperties(
  globalStore: GlobalStore,
  entity: DrawableEntityConcrete,
): EntityAnalyticProperties {
  const coreObject = globalStore.getSafe(entity.uid);
  return {
    uid: entity ? entity.uid : "Unknown",
    entityName: entity ? (getEntityIndividualName(entity) ?? "Unnamed") : "N/A",
    entityType: coreObject?.type ?? "N/A",
    subtype: coreObject?.subtype ?? "N/A",
    tertiaryType: coreObject?.tertiaryType ?? "N/A",
  };
}
