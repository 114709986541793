import Flatten from "@flatten-js/core";
import assert from "assert";
import { v4 } from "uuid";
import UnionFind from "../../../../../common/src/api/calculations/union-find";
import { getEntitySystem } from "../../../../../common/src/api/calculations/utils";
import {
  isDrainage,
  isPressure,
  LEVEL_HEIGHT_DIFF_M,
  StandardFlowSystemUids,
} from "../../../../../common/src/api/config";
import { getFloorHeight } from "../../../../../common/src/api/coreObjects/utils";
import {
  BigValveType,
  FlowConfiguration,
} from "../../../../../common/src/api/document/entities/big-valve/big-valve-entity";
import {
  ConduitConnectableEntityConcrete,
  DrawableEntityConcrete,
  isConnectableEntity,
} from "../../../../../common/src/api/document/entities/concrete-entity";
import {
  FittingEntity,
  makeFittingEntity,
} from "../../../../../common/src/api/document/entities/fitting-entity";
import { fillFixtureFields } from "../../../../../common/src/api/document/entities/fixtures/fixture-entity";
import { fillPlantDefaults } from "../../../../../common/src/api/document/entities/plants/plant-defaults";
import { PlantType } from "../../../../../common/src/api/document/entities/plants/plant-types";
import {
  isDualSystemNodePlant,
  isMultiOutlets,
} from "../../../../../common/src/api/document/entities/plants/utils";
import RiserEntity from "../../../../../common/src/api/document/entities/riser-entity";
import { EntityType } from "../../../../../common/src/api/document/entities/types";
import {
  FLOW_SYSTEM_TO_CONDUIT_TYPE,
  HORIZONTAL_SYSTEM_NETWORKS_BY_PRIORITY,
} from "../../../../../common/src/api/document/flow-systems";
import { Coord } from "../../../../../common/src/lib/coord";
import { SentryError } from "../../../../../common/src/lib/sentry-error";
import { assertUnreachable } from "../../../../../common/src/lib/utils";
import { addValveAndSplitPipe } from "../../../../src/htmlcanvas/lib/black-magic/split-pipe";
import {
  determineMaxMinHeight,
  getConnectedFlowComponent,
  makeConduitEntity,
} from "../../../../src/htmlcanvas/lib/black-magic/utils";
import CanvasContext from "../../../../src/htmlcanvas/lib/canvas-context";
import { InteractionType } from "../../../../src/htmlcanvas/lib/interaction";
import {
  addEntityToStore,
  maxHeightOfConnection,
  minHeightOfConnection,
} from "../../../../src/htmlcanvas/lib/utils";
import { EntityEvent, EntityParam } from "../../../../src/store/document/types";
import { MainEventBus } from "../../../../src/store/main-event-bus";
import DrawableCompound from "../../../htmlcanvas/objects/drawableCompound";
import { DrawableObjectConcrete } from "../../objects/concrete-object";
import DrawableBigValve from "../../objects/drawableBigValve";
import DrawableConduit from "../../objects/drawableConduit";
import DrawablePlant from "../../objects/drawablePlant";
import connectBigValveToSource from "./connect-big-valve-to-source";
import { GroupDistCache } from "./group-dist-cache";

const CEILING_HEIGHT_THRESHOLD_BELOW_PIPE_HEIGHT_MM = 500;
const FIXTURE_WALL_DIST_MM = 200;
const FIXTURE_WALL_DIST_COLD_MM = 100;
const BIG_VALVE_WALL_DIST_MM = 50;
const PLANT_EXTEND_DIST_MM = 150;
const WALL_INSERT_JOIN_THRESHOLD_MM = 150;

const WALL_SAME_ANGLE_THRESHOLD_DEG = 5;
const WALL_SAME_DIST_THRESHOLD_MM = 900;
const WALL_SNAP_DIST_THRESHOLD_MM = 900;

const VALVE_CONNECT_HANDICAP_MM = 30;
export const PIPE_STUB_MAX_LENGTH_MM = 300;
const MIN_PIPE_LEN_MM = 100;

// Note: there are some aggressive caches that help improve performance. The caches will only be
// correct if the following invariants are maintained:
// 1. For height caching, the height of objects only change when adding pipes (because the connectable
//      endpoints will have the new pipe's height to consider).
// 2. For entity-entity distance caching, the distance is either the same, or goes from a number to
//      null as entities becomes disqualified as auto-connect progresses and suffocates available
//      connections. Ie, the only change in distance over time should be from a valid distance to
//      null.
// 3. Group distance cache: When joining two groups, distances between other groups are not affected.
export class AutoConnector {
  selected: DrawableObjectConcrete[];
  allowedUids: Set<string> = new Set<string>();
  context: CanvasContext;
  unionFind: UnionFind<string> = new UnionFind<string>();
  gridLines: GridLine[] = [];
  walls: Wall[] = [];

  shapeCache = new Map<string, Flatten.Shape>();

  entityHeightCache = new Map<string, [number, number]>();

  joinEntitiesCache = new Map<string, number | null>();

  calls = 0;

  deleted = new Set<string>();

  entitiesToConsider: DrawableEntityConcrete[] = [];

  groupDistCache = new GroupDistCache();
  levelUid = "";

  constructor(selected: DrawableObjectConcrete[], context: CanvasContext) {
    if (selected === undefined || selected === null) {
      throw new Error("invalid argument for selected");
    }
    if (!context.document.uiState.levelUid) {
      throw new Error("Can only auto connect on a level");
    }
    this.levelUid = context.document.uiState.levelUid;
    this.selected = selected;
    this.context = context;
    this.processWalls();
  }

  getOrSetShape(obj: DrawableObjectConcrete) {
    if (!this.shapeCache.has(obj.uid)) {
      this.shapeCache.set(obj.uid, obj.shape!);
    }
    return this.shapeCache.get(obj.uid)!;
  }

  processInitialConnections() {
    this.unionFind = new UnionFind<string>();
    this.selected.forEach((o) => {
      this.allowedUids.add(o.uid);
      switch (o.entity.type) {
        case EntityType.CONDUIT:
          this.allowedUids.add(o.entity.endpointUid[0]);
          this.allowedUids.add(o.entity.endpointUid[1]);
          this.unionFind.join(o.entity.endpointUid[0], o.uid);
          this.unionFind.join(o.entity.endpointUid[1], o.uid);
          break;
        case EntityType.FIXTURE:
        case EntityType.GAS_APPLIANCE:
        case EntityType.BIG_VALVE: {
          const subs: string[] = [];
          switch (o.entity.type) {
            case EntityType.BIG_VALVE:
              switch (o.entity.valve.type) {
                case BigValveType.TMV:
                  subs.push(o.entity.valve.coldOutputUid);
                  subs.push(o.entity.valve.warmOutputUid);
                  // Check that the rough in inlet is unable to wrongly join to downstream fixtures
                  // before adding it to the system to help prevent accidentally selected upstream
                  // pipes from being weirdly connected. Convenience feature for the user.
                  if (
                    this.context.globalStore.getConnections(
                      o.entity.coldRoughInUid,
                    ).length > 0
                  ) {
                    this.unionFind.join(
                      o.entity.valve.coldOutputUid,
                      o.entity.coldRoughInUid,
                    );
                  }
                  break;
                case BigValveType.TEMPERING:
                  // Don't attach the hot to the warm - while it is correct to do so, it is also
                  // correct not to do so and it interferes with optimizations later that improves
                  // performance when every component has only once system.
                  subs.push(o.entity.valve.warmOutputUid);
                  break;
                case BigValveType.RPZD_HOT_COLD:
                  subs.push(o.entity.valve.coldOutputUid);
                  subs.push(o.entity.valve.hotOutputUid);
                  if (
                    this.context.globalStore.getConnections(
                      o.entity.coldRoughInUid,
                    ).length > 0
                  ) {
                    this.unionFind.join(
                      o.entity.valve.coldOutputUid,
                      o.entity.coldRoughInUid,
                    );
                  }
                  if (
                    this.context.globalStore.getConnections(
                      o.entity.hotRoughInUid,
                    ).length > 0
                  ) {
                    this.unionFind.join(
                      o.entity.valve.hotOutputUid,
                      o.entity.hotRoughInUid,
                    );
                  }
                  break;
              }
              break;
            case EntityType.FIXTURE:
              for (const suid of o.entity.roughInsInOrder) {
                if (
                  (isDrainage(
                    this.context.document.drawing.metadata.flowSystems[suid],
                  ) &&
                    this.context.document.uiState.drawingLayout ===
                      "drainage") ||
                  (isPressure(
                    this.context.document.drawing.metadata.flowSystems[suid],
                  ) &&
                    this.context.document.uiState.drawingLayout === "pressure")
                )
                  subs.push(o.entity.roughIns[suid].uid);
              }
              break;
            case EntityType.GAS_APPLIANCE:
              subs.push(o.entity.inletUid);
              break;
            default:
              assertUnreachable(o.entity);
          }

          subs.forEach((suid) => {
            this.unionFind.join(suid, suid);
            getConnectedFlowComponent(
              suid,
              this.context.globalStore,
              this.levelUid,
            ).forEach((c) => {
              this.allowedUids.add(c.uid);
              this.unionFind.join(suid, c.uid);
            });
          });
          break;
        }
        case EntityType.PLANT: {
          const p = o as DrawablePlant;
          p.getInletsOutletSpecs().forEach((spec) => {
            this.unionFind.join(spec.uid, spec.uid);
          });
          if (o.entity.inletUid) {
            if (isMultiOutlets(o.entity.plant)) {
              if (isDualSystemNodePlant(o.entity.plant)) {
                if (o.entity.plant.heatingOutletUid) {
                  this.unionFind.join(
                    o.entity.inletUid,
                    o.entity.plant.heatingOutletUid,
                  );
                }
                if (o.entity.plant.chilledOutletUid) {
                  this.unionFind.join(
                    o.entity.inletUid,
                    o.entity.plant.chilledOutletUid,
                  );
                }
              } else if (o.entity.plant.type === PlantType.DUCT_MANIFOLD) {
                for (const outlet of o.entity.plant.outlets) {
                  this.unionFind.join(o.entity.inletUid, outlet.uid!);
                }
              } else {
                for (const outlet of o.entity.plant.outlets) {
                  if (o.entity.inletSystemUid === outlet.outletSystemUid) {
                    this.unionFind.join(o.entity.inletUid, outlet.outletUid!);
                  }
                }
              }
            } else if (
              o.entity.inletSystemUid === o.entity.plant.outletSystemUid
            ) {
              this.unionFind.join(o.entity.inletUid, o.entity.plant.outletUid!);
            }
          }

          break;
        }
        case EntityType.COMPOUND: {
          const c = o as DrawableCompound;
          const connections = c.getInletsOutletSpecs();
          connections.forEach((spec) => {
            this.unionFind.join(spec.uid, spec.uid);
          });
          const inlets = connections.filter((spec) => spec.type === "inlet");
          const outlets = connections.filter((spec) => spec.type === "outlet");

          for (const inlet of inlets) {
            for (const outlet of outlets) {
              if (inlet.systemUid === outlet.systemUid) {
                this.unionFind.join(inlet.uid, outlet.uid);
              }
            }
          }
        }
        case EntityType.RISER:
        case EntityType.FITTING:
        case EntityType.LOAD_NODE:
        case EntityType.FLOW_SOURCE:
        case EntityType.DIRECTED_VALVE:
        case EntityType.MULTIWAY_VALVE:
          this.unionFind.join(o.uid, o.uid);
          break;
        case EntityType.BACKGROUND_IMAGE:
        case EntityType.EDGE:
        case EntityType.VERTEX:
        case EntityType.ROOM:
        case EntityType.WALL:
        case EntityType.FENESTRATION:
        case EntityType.LINE:
        case EntityType.ANNOTATION:
        case EntityType.ARCHITECTURE_ELEMENT:
        case EntityType.DAMPER:
        case EntityType.AREA_SEGMENT:
          break;
        case EntityType.SYSTEM_NODE:
          throw new Error("invalid object selected");
        default:
          assertUnreachable(o.entity);
      }
    });
  }

  processWalls() {
    this.selected.forEach((o) => {
      if (
        o.entity.type === EntityType.FIXTURE ||
        o.entity.type === EntityType.BIG_VALVE
      ) {
        const c = o.toWorldCoord({ x: 0, y: 0 });
        const p = Flatten.point(c.x, c.y);

        const norm = Flatten.vector([0, 1]).rotate(
          (o.toWorldAngleDeg(0) / 180) * Math.PI,
        );
        this.walls.push({
          line: Flatten.line(
            p.translate(norm.normalize().multiply(FIXTURE_WALL_DIST_MM)),
            norm,
          ),
          source: p,
        });

        this.gridLines.push({
          source: Flatten.point(c.x, c.y).translate(
            norm.normalize().multiply(BIG_VALVE_WALL_DIST_MM),
          ),
          lines: [norm, norm.rotate90CW()],
        });
      }
    });
  }

  // Returns the minimum and maximum of plant's height
  getEntityHeight(entity: DrawableEntityConcrete): [number, number] {
    if (this.entityHeightCache.has(entity.uid)) {
      return this.entityHeightCache.get(entity.uid)!;
    }

    const fun = (): [number, number] => {
      switch (entity.type) {
        case EntityType.CONDUIT:
          return [entity.heightAboveFloorM, entity.heightAboveFloorM];
        case EntityType.RISER:
          const re = this.context.globalStore.get(entity.uid)!
            .entity as RiserEntity;

          const { topMax, bottomMin } = determineMaxMinHeight(re, this.context);
          return [
            topMax! -
              getFloorHeight(
                this.context.globalStore,
                this.context.drawing,
                re,
              ),
            bottomMin! -
              getFloorHeight(
                this.context.globalStore,
                this.context.drawing,
                re,
              ),
          ];
        case EntityType.LOAD_NODE:
          return [-Infinity, Infinity];
        case EntityType.FLOW_SOURCE:
        case EntityType.FITTING:
        case EntityType.DIRECTED_VALVE:
        case EntityType.MULTIWAY_VALVE:
        case EntityType.SYSTEM_NODE:
          let maxh = maxHeightOfConnection(entity, this.context);
          let minh = minHeightOfConnection(entity, this.context);
          maxh = maxh === null ? -Infinity : maxh;
          minh = minh === null ? Infinity : minh;

          return [minh, maxh];
        case EntityType.BIG_VALVE:
          return [entity.heightAboveFloorM, entity.heightAboveFloorM];
        case EntityType.FIXTURE:
          const fixture = fillFixtureFields(this.context, entity);
          return [fixture.outletAboveFloorM!, fixture.outletAboveFloorM!];
        case EntityType.PLANT:
          // put all the inlets and outlets into an array and sort it for the min and the max
          // don't forget preheatInletHeightAboveFloorM
          const inletOutletHeights: number[] = [];
          const filled = fillPlantDefaults(this.context, entity);

          // inlets
          if (filled.plant.type === PlantType.RETURN_SYSTEM) {
            for (const preheat of filled.plant.preheats) {
              inletOutletHeights.push(preheat.heightAboveFloorM!);
            }
            if (filled.plant.addColdWaterInlet) {
              inletOutletHeights.push(filled.inletHeightAboveFloorM!);
            }
          } else {
            inletOutletHeights.push(filled.inletHeightAboveFloorM!);
          }

          // outlets
          if (isMultiOutlets(filled.plant)) {
            if (isDualSystemNodePlant(filled.plant)) {
              inletOutletHeights.push(filled.plant.heatingHeightAboveFloorM!);
              inletOutletHeights.push(filled.plant.chilledHeightAboveFloorM!);

              if (filled.plant.type === PlantType.AHU_VENT) {
                inletOutletHeights.push(
                  filled.plant.supplyHeightAboveFloorM!,
                  filled.plant.extractHeightAboveFloorM!,
                  filled.plant.exhaustHeightAboveFloorM!,
                  filled.plant.intakeHeightAboveFloorM!,
                );
              }
            } else if (filled.plant.type === PlantType.DUCT_MANIFOLD) {
              inletOutletHeights.push(filled.inletHeightAboveFloorM!);
            } else {
              filled.plant.outlets.forEach((outlet) => {
                inletOutletHeights.push(outlet.heightAboveFloorM!);
              });
            }
          } else {
            inletOutletHeights.push(filled.plant.outletHeightAboveFloorM!);
          }

          const sortedInletOutletHeights = inletOutletHeights.sort(
            (a, b) => a - b,
          );

          return [
            sortedInletOutletHeights[0],
            sortedInletOutletHeights[sortedInletOutletHeights.length - 1],
          ];
        case EntityType.GAS_APPLIANCE:
          return [entity.outletAboveFloorM, entity.outletAboveFloorM];
        case EntityType.BACKGROUND_IMAGE:
          throw new Error("entity has no height");
        case EntityType.COMPOUND:
        case EntityType.EDGE:
        case EntityType.VERTEX:
        case EntityType.ROOM:
        case EntityType.WALL:
        case EntityType.FENESTRATION:
        case EntityType.LINE:
        case EntityType.ANNOTATION:
        case EntityType.ARCHITECTURE_ELEMENT:
        case EntityType.DAMPER:
        case EntityType.AREA_SEGMENT:
          return [-Infinity, Infinity];
      }
      assertUnreachable(entity);
    };

    this.entityHeightCache.set(entity.uid, fun.bind(this)());
    return this.entityHeightCache.get(entity.uid)!;
  }

  isWallHeight(entity: DrawableEntityConcrete): boolean {
    const [min, _max] = this.getEntityHeight(entity);
    return (
      min <=
      LEVEL_HEIGHT_DIFF_M + CEILING_HEIGHT_THRESHOLD_BELOW_PIPE_HEIGHT_MM / 1000
    ); // && max >= 0; // leave that out 4 now
  }

  joinEntities(
    a: string,
    b: string,
    doit: boolean = true,
    cutoff?: number,
  ): number | null {
    const key = a < b ? a + b : b + a;
    this.calls++;
    if (!doit && this.joinEntitiesCache.has(key)) {
      return this.joinEntitiesCache.get(key)!;
    }

    if (cutoff !== undefined) {
      // we avoid unnecessary calculations by ignoring all entities that can't possibly be closer
      // than cutoff - euclidean distance.
      const as = this.getOrSetShape(this.context.globalStore.get(a)!);
      const bs = this.getOrSetShape(this.context.globalStore.get(b)!);
      if (as.distanceTo(bs)[0] > cutoff) {
        return Infinity;
      }
    }

    const run = () => {
      // types of things to join:
      // system nodes from fixtures
      // system nodes from big valves
      // pipes and stuff

      // rules:
      // Anything at roof height must travel through the roof
      // Pipes at wall height can travel via wall and through roof after a bring up
      // nodes at wall height can lead straight into pipes.
      // Otherwise, nodes at wall height must lead into wall, then it behaves like a pipe.
      //  => lead smaller for big valves which are already in the wall
      //  => lead larger for fixtures which are protruding from the wall
      let ao = this.context.globalStore.getOrThrow(a);
      let bo = this.context.globalStore.getOrThrow(b);
      let ae = ao.entity;

      // Must be same system
      const sa = getEntitySystem(ao.entity, this.context.globalStore);
      const sb = getEntitySystem(bo.entity, this.context.globalStore);
      if (sa !== null && sb !== null && sa !== sb) {
        return null;
      }
      if (sa === null && sb === null) {
        throw new Error("connecting objects with unspecified system uid");
      }
      const systemUid = (sa || sb) as string;
      if (
        [ao, bo].filter((o) =>
          o.offerInteraction({
            type: InteractionType.EXTEND_NETWORK,
            systemUid,
            worldRadius: 0,
            worldCoord: { x: 0, y: 0 },
            configuration: FlowConfiguration.BOTH,
          }),
        ).length !== 2
      ) {
        return null; // one of them can't handle it.
      }

      let totLen = 0;
      let solved = false;

      // Preparation. System nodes need to extend into the wall.
      [
        [ao, bo],
        [bo, ao],
      ].forEach(([me, them]) => {
        if (solved) {
          return;
        }
        if (me.entity.type === EntityType.SYSTEM_NODE) {
          let vec: Flatten.Vector;
          if (me.entity.parentUid === null) {
            throw new Error("System node has no parent");
          }
          const po = this.context.globalStore.get(
            me.entity.parentUid,
          )! as DrawableObjectConcrete;
          let heightM: number;
          switch (po.entity.type) {
            case EntityType.BIG_VALVE:
            case EntityType.GAS_APPLIANCE:
            case EntityType.FIXTURE:
              vec = Flatten.vector([0, -1]).rotate(
                (po.toWorldAngleDeg(0) / 180) * Math.PI,
              );
              heightM = -1; // type inference fix
              switch (po.entity.type) {
                case EntityType.BIG_VALVE:
                  vec = vec.multiply(BIG_VALVE_WALL_DIST_MM);
                  heightM = po.entity.heightAboveFloorM;
                  break;
                case EntityType.FIXTURE:
                  const fe = fillFixtureFields(this.context, po.entity);
                  if (
                    me.entity.systemUid === StandardFlowSystemUids.ColdWater
                  ) {
                    vec = vec.multiply(FIXTURE_WALL_DIST_COLD_MM);
                  } else {
                    vec = vec.multiply(FIXTURE_WALL_DIST_MM);
                  }
                  heightM = fe.outletAboveFloorM!;
                  break;
                case EntityType.GAS_APPLIANCE:
                  vec = vec.multiply(BIG_VALVE_WALL_DIST_MM);
                  heightM = po.entity.outletAboveFloorM;
                  break;
              }
              break;
            case EntityType.PLANT:
              const filled = fillPlantDefaults(this.context, po.entity);
              if (me.entity.uid === po.entity.inletUid) {
                vec = Flatten.vector([-1, 0])
                  .rotate((po.toWorldAngleDeg(0) / 180) * Math.PI)
                  .multiply(PLANT_EXTEND_DIST_MM);
                heightM = filled.inletHeightAboveFloorM!;
              } else {
                // SEED-205 this should be fixed for multiple outlets
                if (isMultiOutlets(filled.plant)) {
                  solved = true;
                  return;
                } else {
                  vec = Flatten.vector([1, 0])
                    .rotate((po.toWorldAngleDeg(0) / 180) * Math.PI)
                    .multiply(PLANT_EXTEND_DIST_MM);
                  heightM = filled.plant.outletHeightAboveFloorM!;
                }
              }

              break;
            case EntityType.COMPOUND:
            case EntityType.DAMPER:
              throw new Error("Compound not supported yet"); // TODO
            case EntityType.BACKGROUND_IMAGE:
            case EntityType.FITTING:
            case EntityType.DIRECTED_VALVE:
            case EntityType.MULTIWAY_VALVE:
            case EntityType.CONDUIT:
            case EntityType.FLOW_SOURCE:
            case EntityType.RISER:
            case EntityType.LOAD_NODE:
            case EntityType.SYSTEM_NODE:
            case EntityType.EDGE:
            case EntityType.VERTEX:
            case EntityType.ROOM:
            case EntityType.WALL:
            case EntityType.FENESTRATION:
            case EntityType.LINE:
            case EntityType.ANNOTATION:
            case EntityType.ARCHITECTURE_ELEMENT:
            case EntityType.AREA_SEGMENT:
              throw new Error("Can't do it");
            default:
              assertUnreachable(po.entity);
              throw new Error("Can't do it");
          }
          const center = me.toWorldCoord({ x: 0, y: 0 });
          const mntPt = Flatten.point(center.x, center.y).translate(vec);

          // if the mount point is very close to the other bloke, then just connect it straight away.
          if (them.entity.type !== EntityType.SYSTEM_NODE) {
            const d = this.getOrSetShape(them).distanceTo(mntPt);
            if (d[0] < WALL_INSERT_JOIN_THRESHOLD_MM) {
              totLen += d[1].distanceTo(this.getOrSetShape(me))[0];
              if (doit) {
                let v = them.entity as ConduitConnectableEntityConcrete;
                if (them.entity.type === EntityType.CONDUIT) {
                  const { deleted, focus } = addValveAndSplitPipe(
                    this.context,
                    them as DrawableConduit,
                    center,
                    systemUid,
                    10,
                  );
                  v = focus as ConduitConnectableEntityConcrete;
                  this.selected = this.selected.filter(
                    (s) => !deleted.includes(s.uid),
                  );
                  // this.selected.push(...created);
                }

                this.connectConnectablesWithPipe(
                  v as ConduitConnectableEntityConcrete,
                  me.entity,
                  heightM,
                  systemUid,
                );
              }
              solved = true;
            }
          }

          if (!solved) {
            // extend
            let v: FittingEntity = makeFittingEntity(this.context, {
              center: { x: mntPt.x, y: mntPt.y },
              parentUid: null,
              calculationHeightM: null,
              systemUid,
              uid: v4(),
              fittingType:
                FLOW_SYSTEM_TO_CONDUIT_TYPE[
                  this.context.drawing.metadata.flowSystems[systemUid].type
                ]!,
            });

            if (doit) {
              v = addEntityToStore(this.context, v);
              this.connectConnectablesWithPipe(
                v,
                me.entity,
                heightM,
                systemUid,
              );
            }

            if (ae.uid === me.uid) {
              ae = v;
              if (doit) {
                ao = this.context.globalStore.get(v.uid)!;
              }
            } else {
              if (doit) {
                bo = this.context.globalStore.get(v.uid)!;
              }
            }

            totLen += mntPt.distanceTo(Flatten.point(center.x, center.y))[0];
          }
        }
      });

      let bias = 0;
      if (isConnectableEntity(ao.entity)) {
        bias -= VALVE_CONNECT_HANDICAP_MM;
      }
      if (isConnectableEntity(bo.entity)) {
        bias -= VALVE_CONNECT_HANDICAP_MM;
      }
      totLen += bias;

      if (solved) {
        return totLen;
      }

      // Now, connect the remaining.
      let wallDist: number | null = null;
      if (this.isWallHeight(bo.entity) && this.isWallHeight(ao.entity)) {
        // we have the option to go through the wall.
        const dist = this.connectThroughWalls(ao, bo, systemUid, false);
        if (dist !== null) {
          wallDist = dist;
        }
      }

      const roofDist = this.connectThroughRoof(ao, bo, systemUid, false);

      if (roofDist === null && wallDist === null) {
        return null;
      } else if (wallDist === null || roofDist! < wallDist) {
        return totLen + this.connectThroughRoof(ao, bo, systemUid, doit)!;
      } else {
        return totLen + this.connectThroughWalls(ao, bo, systemUid, doit)!;
      }
    };

    const res = run.bind(this)();
    if (res !== null && isNaN(res)) {
      throw new SentryError("Distance result is NaN ", {
        a,
        b,
        doit,
      });
    }
    this.joinEntitiesCache.set(key, res);
    return res;
  }

  connectThroughRoof(
    ao: DrawableObjectConcrete,
    bo: DrawableObjectConcrete,
    systemUid: string,
    doit: boolean,
  ): number | null {
    // OK now try the option of connecting it through the roof
    let maxHeight = Math.max(
      this.getEntityHeight(ao.entity)[1],
      this.getEntityHeight(bo.entity)[1],
    );
    const flowSystem =
      this.context.document.drawing.metadata.flowSystems[systemUid];
    maxHeight = Math.max(maxHeight, flowSystem.defaultPipeHeightM);

    const straight = this.getOrSetShape(ao).distanceTo(this.getOrSetShape(bo));

    if (doit) {
      if (ao.type === EntityType.CONDUIT) {
        ao = this.context.globalStore.get(
          addValveAndSplitPipe(
            this.context,
            ao as DrawableConduit,
            straight[1].end,
            (ao as DrawableConduit).entity.systemUid,
            10,
          ).focus!.uid,
        )!;
      }

      if (bo.type === EntityType.CONDUIT) {
        bo = this.context.globalStore.get(
          addValveAndSplitPipe(
            this.context,
            bo as DrawableConduit,
            straight[1].start,
            (bo as DrawableConduit).entity.systemUid,
            10,
          ).focus!.uid,
        )!;
      }
    }
    const auxLength =
      (maxHeight -
        this.getEntityHeight(ao.entity)[1] +
        maxHeight -
        this.getEntityHeight(bo.entity)[1]) *
      1000;
    // Find grid
    const ga = this.findClosestGrid(straight[1].start);
    const gb = this.findClosestGrid(straight[1].end);
    const ac = straight[1].start;
    const ap = Flatten.point(ac.x, ac.y);
    const bc = straight[1].end;
    const bp = Flatten.point(bc.x, bc.y);

    let bestDist = Infinity;
    let bestCorner!: Flatten.Point;

    ga.lines.forEach((al) => {
      gb.lines.forEach((bl) => {
        const ix = Flatten.line(ap, al).intersect(Flatten.line(bp, bl));
        if (ix.length === 0) {
          return;
        }

        const dist = ix[0].distanceTo(ap)[0] + ix[0].distanceTo(bp)[0];
        if (dist < bestDist) {
          bestDist = dist;
          bestCorner = ix[0];
        }
      });
    });

    if (bestCorner) {
      if (doit) {
        if (
          Math.min(bestCorner.distanceTo(ap)[0], bestCorner.distanceTo(bp)[0]) <
          MIN_PIPE_LEN_MM
        ) {
          // connect directly
          this.connectConnectablesWithPipe(
            ao.entity as ConduitConnectableEntityConcrete,
            bo.entity as ConduitConnectableEntityConcrete,
            maxHeight,
            systemUid,
          );
        } else {
          let v: FittingEntity = makeFittingEntity(this.context, {
            center: { x: bestCorner.x, y: bestCorner.y },
            parentUid: null,
            calculationHeightM: null,
            systemUid,
            uid: v4(),
            fittingType:
              FLOW_SYSTEM_TO_CONDUIT_TYPE[
                this.context.drawing.metadata.flowSystems[systemUid].type
              ]!,
          });

          v = addEntityToStore(this.context, v);
          this.connectConnectablesWithPipe(
            v,
            ao.entity as ConduitConnectableEntityConcrete,
            maxHeight,
            systemUid,
          );
          this.connectConnectablesWithPipe(
            v,
            bo.entity as ConduitConnectableEntityConcrete,
            maxHeight,
            systemUid,
          );
        }
      }
      return bestDist + auxLength;
    } else {
      return null;
    }
  }

  findClosestGrid(coord: Coord): GridLine {
    let closeDist = Infinity;
    let closeGrid: GridLine = {
      source: Flatten.point(0, 0),
      lines: [Flatten.vector(0, 1), Flatten.vector(1, 0)],
    };
    this.gridLines.forEach((g) => {
      const dist = g.source.distanceTo(Flatten.point(coord.x, coord.y));
      if (dist[0] < closeDist) {
        closeDist = dist[0];
        closeGrid = g;
      }
    });
    return closeGrid;
  }

  findBestWall(o: DrawableObjectConcrete): Wall | null {
    // find best wall
    let bestWallDist = Infinity;
    let bestWall: Wall | null = null;

    const oshape = this.getOrSetShape(o);

    this.walls.forEach((w) => {
      const wallD = w.line.distanceTo(oshape!);
      if (wallD[0] <= WALL_SNAP_DIST_THRESHOLD_MM) {
        const sourceD = w.source.distanceTo(oshape!);
        if (sourceD[0] < bestWallDist) {
          bestWallDist = sourceD[0];
          bestWall = w;
        }
      }
    });

    return bestWall;
  }

  diffA(a: number, b: number) {
    return Math.min((a - b + 360) % 360, (-a + b + 360 * 2) % 360);
  }

  connectThroughWalls(
    ao: DrawableObjectConcrete,
    bo: DrawableObjectConcrete,
    systemUid: string,
    doit: boolean,
  ): number | null {
    // Do not DFS, this will lead to strange results. Only use wall if it might meet at a
    // corner.
    const wa = this.findBestWall(ao);
    const wb = this.findBestWall(bo);
    if (!wa || !wb) {
      return null;
    }

    // We want the highest pipe height that doesn't compromize
    const maxLow = Math.max(
      this.getEntityHeight(ao.entity)[0],
      this.getEntityHeight(bo.entity)[0],
    );
    const minHigh = Math.min(
      this.getEntityHeight(ao.entity)[1],
      this.getEntityHeight(bo.entity)[1],
    );
    const newHeight = Math.max(maxLow, minHigh);
    let pipeHeight: number = 3;
    const flowSystem =
      this.context.document.drawing.metadata.flowSystems[systemUid];

    pipeHeight = flowSystem.defaultPipeHeightM;

    const auxLength =
      (Math.max(0, newHeight - minHigh) + Math.abs(newHeight - pipeHeight)) *
      1000;

    const adeg = (wa.line.norm.angleTo(wb.line.norm) / Math.PI) * 180;
    const adiff = Math.min(this.diffA(adeg, 180), this.diffA(adeg, 0));

    if (adiff < WALL_SAME_ANGLE_THRESHOLD_DEG) {
      // within 5 degrees? consider that the same angle.
      if (wa.source.distanceTo(wb.line)[0] < WALL_SAME_DIST_THRESHOLD_MM) {
        // they snapped to the same wall. Just join directly.
        const path = this.getOrSetShape(ao).distanceTo(this.getOrSetShape(bo));
        if (doit) {
          if (ao.type === EntityType.CONDUIT) {
            ao = this.context.globalStore.get(
              addValveAndSplitPipe(
                this.context,
                ao as DrawableConduit,
                path[1].end,
                (ao as DrawableConduit).entity.systemUid,
                10,
              ).focus!.uid,
            )!;
          }

          if (bo.type === EntityType.CONDUIT) {
            bo = this.context.globalStore.get(
              addValveAndSplitPipe(
                this.context,
                bo as DrawableConduit,
                path[1].start,
                (bo as DrawableConduit).entity.systemUid,
                10,
              ).focus!.uid,
            )!;
          }

          assert(isConnectableEntity(ao.entity));
          assert(isConnectableEntity(bo.entity));

          this.connectConnectablesWithPipe(
            ao.entity as ConduitConnectableEntityConcrete,
            bo.entity as ConduitConnectableEntityConcrete,
            newHeight,
            systemUid,
          );
        }

        // Pipe length + length to connect other fitting to pipe + length to connect to ceiling.
        return path[0] + auxLength;
      } else {
        // on parallel walls that are too far. We have to go to roof. We don't have enough
        // information to figure out how to pipe it through other walls.
        return null;
      }
    } else {
      // Reachable.
      const corner = wa.line.intersect(wb.line)[0];
      if (corner === undefined) {
        throw new SentryError("Walls should have intersected, but they didnt", {
          adiff,
        });
      }
      const pd =
        corner.distanceTo(this.getOrSetShape(ao))[0] +
        corner.distanceTo(this.getOrSetShape(bo))[0];
      if (doit) {
        if (ao.type === EntityType.CONDUIT) {
          ao = this.context.globalStore.get(
            addValveAndSplitPipe(
              this.context,
              ao as DrawableConduit,
              corner,
              (ao as DrawableConduit).entity.systemUid,
              10,
            ).focus!.uid,
          )!;
        }

        if (bo.type === EntityType.CONDUIT) {
          bo = this.context.globalStore.get(
            addValveAndSplitPipe(
              this.context,
              bo as DrawableConduit,
              corner,
              (bo as DrawableConduit).entity.systemUid,
              10,
            ).focus!.uid,
          )!;
        }

        assert(isConnectableEntity(ao.entity));
        assert(isConnectableEntity(ao.entity));

        const ac = ao.toWorldCoord({ x: 0, y: 0 });
        const ap = Flatten.point(ac.x, ac.y);
        const aline = Flatten.line(ap, wa.line.norm);
        const bc = bo.toWorldCoord({ x: 0, y: 0 });
        const bp = Flatten.point(bc.x, bc.y);
        const bline = Flatten.line(bp, wb.line.norm);

        const realCorner = aline.intersect(bline)[0];

        let v: FittingEntity = makeFittingEntity(this.context, {
          center: { x: realCorner.x, y: realCorner.y },
          parentUid: null,
          calculationHeightM: null,
          systemUid,
          uid: v4(),
          fittingType:
            FLOW_SYSTEM_TO_CONDUIT_TYPE[
              this.context.drawing.metadata.flowSystems[systemUid].type
            ]!,
        });

        v = addEntityToStore(this.context, v);

        this.connectConnectablesWithPipe(
          v,
          ao.entity as ConduitConnectableEntityConcrete,
          newHeight,
          systemUid,
        );
        this.connectConnectablesWithPipe(
          v,
          bo.entity as ConduitConnectableEntityConcrete,
          newHeight,
          systemUid,
        );
      }

      return pd + auxLength;
    }
  }

  joinGroups(
    a: string[],
    b: string[],
    doit: boolean = true,
    cutoff?: number,
  ): number | null {
    // skip this if the systems are not compatible.
    const systems1 = new Set(
      a.map((uid) => {
        const s = getEntitySystem(
          this.context.globalStore.get(uid)!.entity,
          this.context.globalStore,
        );
        if (s === null) {
          throw new Error(
            "auto connected groups must belong to systems " + uid,
          );
        }
        return s;
      }),
    );
    const systems2 = new Set(
      b.map((uid) => {
        const s = getEntitySystem(
          this.context.globalStore.get(uid)!.entity,
          this.context.globalStore,
        );
        if (s === null) {
          throw new Error("auto connecteded groups must belong to systems");
        }
        return s;
      }),
    );
    if (systems1.size !== 1 || systems2.size !== 1) {
      throw new Error("only one system in ecah connected componet allowed");
    }
    if (Array.from(systems1.keys())[0] !== Array.from(systems2.keys())[0]) {
      return null;
    }

    let bestDist: number | null = null;
    let bestPair: [string, string] | null = null;
    a.forEach((auid) => {
      b.forEach((buid) => {
        const res = this.joinEntities(auid, buid, false, cutoff);
        if (res !== null) {
          if (bestDist === null || res < bestDist) {
            bestDist = res;
            bestPair = [auid, buid];
            cutoff = res;
          }
        }
      });
    });

    if (doit && bestDist !== null) {
      const res = this.joinEntities(bestPair![0], bestPair![1], true);
      if (res === null) {
        // Cache needs to be invalidated because these two entities are no longer a valid pair.
        const aa = bestPair![0];
        const bb = bestPair![1];
        const key = aa < bb ? aa + bb : bb + aa;
        this.joinEntitiesCache.delete(key);
      }
      return res;
    } else {
      return bestDist;
    }
  }

  groupDist(
    a: string[],
    b: string[],
    cutoff: number | undefined,
  ): number | null {
    return this.joinGroups(a, b, false, cutoff);
  }

  findCheapestJoin(groups: string[][]): [number, number] | null {
    let currDist: number | null = null;
    let bestAns: [number, number] = [-1, -1];
    for (let a = 0; a < groups.length; a++) {
      for (let b = a + 1; b < groups.length; b++) {
        const dist = this.groupDistCache.get(
          this.unionFind.find(groups[a][0]),
          this.unionFind.find(groups[b][0]),
        );
        if (dist !== null) {
          if (currDist === null || dist < currDist) {
            currDist = dist;
            bestAns = [a, b];
          }
        }
      }
    }

    if (currDist === null) {
      return null;
    }
    return bestAns;
  }

  onDeleteEntity = ({ entity }: EntityParam) => {
    this.deleted.add(entity.uid);
    if (entity.type === EntityType.CONDUIT) {
      this.entityHeightCache.delete(entity.endpointUid[0]);
      this.entityHeightCache.delete(entity.endpointUid[1]);
    }
    this.selected.splice(
      0,
      this.selected.length,
      ...this.selected.filter((o) => o.entity !== undefined),
    );
    // tslint:disable-next-line:semicolon
  };
  onAddEntity = ({ entity, levelUid }: EntityParam) => {
    if (this.context.document.uiState.levelUid === levelUid) {
      this.selected.push(this.context.globalStore.get(entity.uid)!);
      this.entitiesToConsider.push(entity);
    }
    if (entity.type === EntityType.CONDUIT) {
      this.entityHeightCache.delete(entity.endpointUid[0]);
      this.entityHeightCache.delete(entity.endpointUid[1]);
    }
    // tslint:disable-next-line:semicolon
  };

  rig() {
    MainEventBus.$on(EntityEvent.POST_DELETE_ENTITY, this.onDeleteEntity);
    MainEventBus.$on(EntityEvent.ADD_ENTITY, this.onAddEntity);
  }

  teardown() {
    MainEventBus.$off(EntityEvent.POST_DELETE_ENTITY, this.onDeleteEntity);
    MainEventBus.$off(EntityEvent.ADD_ENTITY, this.onAddEntity);
  }

  connectAllBigValves() {
    this.selected.forEach((o) => {
      if (o.type === EntityType.BIG_VALVE) {
        connectBigValveToSource(this.context, o as DrawableBigValve);
      }
    });
  }

  async autoConnect() {
    this.rig();
    this.calls = 0;

    try {
      // firstly
      this.connectAllBigValves();

      this.processInitialConnections();

      // Intiialise group dist cache.
      const groupsInitial = this.unionFind.groups();
      for (let i = 0; i < groupsInitial.length; i++) {
        const id = this.unionFind.find(groupsInitial[i][0]);
        const res = new Map<string, number | null>();
        for (let j = i + 1; j < groupsInitial.length; j++) {
          const jd = this.unionFind.find(groupsInitial[j][0]);
          const dist = this.groupDist(
            groupsInitial[i],
            groupsInitial[j],
            undefined,
          );
          res.set(jd, dist);
        }
        this.groupDistCache.addGroup(id, res);
      }

      let iters = 0;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        iters++;
        const groups = this.unionFind
          .groups()
          .map((g) => g.filter((u) => !this.deleted.has(u)));
        const res = this.findCheapestJoin(groups);
        if (!res) {
          break;
        }

        // Maintain group dist cache.
        const retval = this.joinGroups(groups[res[0]], groups[res[1]], true);

        if (retval !== null) {
          const toBustA = this.unionFind.find(groups[res[0]][0]);
          const toBustB = this.unionFind.find(groups[res[1]][0]);
          this.unionFind.join(toBustA, toBustB);
          let newGroup = this.unionFind.find(toBustA);
          this.groupDistCache.join(toBustA, toBustB, newGroup);

          // Add new items to the groups.
          this.entitiesToConsider.forEach((e) => {
            const result = new Map<string, number | null>();
            groups.forEach((g) => {
              const id = this.unionFind.find(g[0]);
              if (id === newGroup) {
                return;
              }
              result.set(id, this.groupDist(g, [e.uid], undefined));
            });
            this.unionFind.join(e.uid, newGroup);
            const newnewGroup = this.unionFind.find(newGroup);
            this.groupDistCache.addGroup(e.uid, result);
            this.groupDistCache.join(e.uid, newGroup, newnewGroup);
            newGroup = newnewGroup;
          });
          this.entitiesToConsider.splice(0);
        } else {
          if (this.entitiesToConsider.length) {
            throw new Error(
              "new entities after autoconnect failure - don't know what to do",
            );
          }

          console.log("autoconnect failed", {
            iters,
            calls: this.calls,
            a: groups[res[0]].map((u) => u.split(".")[0]),
            b: groups[res[1]].map((u) => u.split(".")[0]),
          });
          throw new Error("Could not auto connect");
        }
      }

      this.context.$store.dispatch("document/validateAndCommit");
    } finally {
      this.teardown();
    }
  }

  // TODO: fix auto connect for all conduits.
  connectConnectablesWithPipe(
    a: ConduitConnectableEntityConcrete,
    b: ConduitConnectableEntityConcrete,
    height: number,
    systemUid: string,
  ) {
    const system = this.context.drawing.metadata.flowSystems[systemUid];
    const network = HORIZONTAL_SYSTEM_NETWORKS_BY_PRIORITY[system.type].at(-1);

    addEntityToStore(
      this.context,
      makeConduitEntity({
        fields: {
          endpointUid: [a.uid, b.uid],
          heightAboveFloorM: height,
          systemUid,
          conduitType: "pipe",
          conduit: {
            network,
          },
        },
        extendedFrom: a,
        extendedTo: b,
      }),
    );
  }
}

export interface GridLine {
  source: Flatten.Point;
  lines: [Flatten.Vector, Flatten.Vector];
}

export interface Wall {
  source: Flatten.Point;
  line: Flatten.Line;
}
