import { APIResult } from "../../../common/src/api/document/types";
import { ApiClient } from "./ApiClient";
import { ReportS3Links } from "./calculationReportManager";

const reportsApi = new ApiClient("/api/reports");

export async function updateCalculationReport(
  id: number,
  nextOrderIndex: number,
  results: any,
): Promise<APIResult<void>> {
  return reportsApi.put(
    `document/${id}/calculation/${nextOrderIndex}`,
    results,
  );
}

export async function refreshDocumentReport(
  id: number,
): Promise<APIResult<void>> {
  return reportsApi.post(`document/${id}`);
}

export async function downloadDocumentReport(
  id: number,
): Promise<APIResult<string>> {
  return reportsApi.get(`document/${id}`);
}

export async function triggerSystemManufacturerReport(): Promise<
  APIResult<void>
> {
  return reportsApi.post("");
}

export async function getReportLinks(
  docId: number,
  lastOperationId: number, // acts as a fencing token
): Promise<APIResult<ReportS3Links>> {
  return reportsApi.get(`document/${docId}/links`, {
    params: { lastOperationId },
  });
}
