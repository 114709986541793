import { cloneSimple, EPS } from "../../lib/utils";
import { GetPressureLossOptions } from "../calculations/entity-pressure-drops";
import {
  CoreContext,
  CostBreakdown,
  PressureLossResult,
} from "../calculations/types";
import { FLOW_SOURCE_EDGE } from "../calculations/utils";
import { isDrainage } from "../config";
import FlowSourceCalculation, {
  emptyFlowSourceCalculation,
  emptyFlowSourceLiveCalculation,
  FlowSourceLiveCalculation,
} from "../document/calculations-objects/flow-source-calculation";
import {
  CalculatableEntityConcrete,
  ConnectableEntityConcrete,
} from "../document/entities/concrete-entity";
import ConduitEntity from "../document/entities/conduit-entity";
import FlowSourceEntity, {
  fillFlowSourceDefaults,
} from "../document/entities/flow-source-entity";
import { EntityType } from "../document/entities/types";
import { DEFAULT_VERTICAL_SYSTEM_NETWORKS } from "../document/flow-systems";
import { CoreConnectable } from "./core-traits/coreConnectable";
import { CoreCalculatableObject } from "./lib/CoreCalculatableObject";
import { getIdentityCalculationEntityUid } from "./utils";

export type CoreFlowSourceTagMap = {
  flow_source: CoreFlowSource;
};

export default class CoreFlowSource extends CoreConnectable(
  CoreCalculatableObject<FlowSourceEntity>,
) {
  type: EntityType.FLOW_SOURCE = EntityType.FLOW_SOURCE;

  get refPath(): string {
    return `${this.entity.type}`;
  }

  get filledEntity(): FlowSourceEntity {
    return fillFlowSourceDefaults(this.context, this.entity);
  }

  getComponentPressureLossKPA(
    options: GetPressureLossOptions,
  ): PressureLossResult {
    let { flowLS, from, to, signed } = options;
    let sign = 1;
    if (flowLS < 0) {
      const oldFrom = from;
      to = oldFrom;
      flowLS = -flowLS;
      if (signed) {
        sign = -1;
      }
    }

    // Avoid backflow
    if (to.connection === FLOW_SOURCE_EDGE) {
      return {
        pressureLossKPA: sign * (1e10 + flowLS),
      };
    } else {
      return { pressureLossKPA: null };
    }
  }
  getCalculationEntities(context: CoreContext): CalculatableEntityConcrete[] {
    const tower: Array<
      [ConnectableEntityConcrete, ConduitEntity] | [ConnectableEntityConcrete]
    > = this.getCalculationTower(context);

    const se = cloneSimple(this.entity);
    se.uid = this.getCalculationUid(context);
    se.parentUid = getIdentityCalculationEntityUid(context, se.parentUid);

    if (
      isDrainage(context.drawing.metadata.flowSystems[this.entity.systemUid])
    ) {
      // Drainage pipe has no height.
      se.heightAboveGroundM = tower[0]?.[0]?.calculationHeightM! || 0;
    }

    // we have a default of 0 here for the live calculations to not crash.
    se.calculationHeightM = se.heightAboveGroundM || 0;

    if (tower.length === 0) {
      return [se];
    }

    // Insert a flow source into the group somewhere to simulate the riser.

    const system = this.drawing.metadata.flowSystems[this.entity.systemUid];

    if (se.heightAboveGroundM! < tower[0][0].calculationHeightM!) {
      // TODO: non-pipe conduits.
      // Just plop it at the bottom.
      const pe: ConduitEntity = {
        color: null,
        conduitType: "pipe",
        endpointUid: [se.uid, tower[0][0].uid],
        heightAboveFloorM: 0,
        lengthM: null,
        parentUid: null,
        conduit: {
          diameterMM: null,
          material: null,
          maximumVelocityMS: null,
          maximumPressureDropRateKPAM: null,
          gradePCT: null,
          network: DEFAULT_VERTICAL_SYSTEM_NETWORKS[system.type],
          configurationCosmetic: null,
        },
        systemUid: this.entity.systemUid,
        type: EntityType.CONDUIT,
        uid: this.uid + ".-1.p",
        entityName: null,
      };
      return [se, pe, ...tower.flat()];
    }

    if (
      se.heightAboveGroundM! > tower[tower.length - 1][0].calculationHeightM!
    ) {
      // Just plop it at the top.
      const pe: ConduitEntity = {
        conduitType: "pipe",
        color: null,
        endpointUid: [se.uid, tower[tower.length - 1][0].uid],
        heightAboveFloorM: 0,
        lengthM: null,
        parentUid: null,
        conduit: {
          diameterMM: null,
          material: null,
          maximumVelocityMS: null,
          maximumPressureDropRateKPAM: null,
          gradePCT: null,
          network: DEFAULT_VERTICAL_SYSTEM_NETWORKS[system.type],
          configurationCosmetic: null,
        },
        systemUid: this.entity.systemUid,
        type: EntityType.CONDUIT,
        uid: this.uid + "." + tower.length + ".p",
        entityName: null,
      };
      return [se, pe, ...tower.flat()];
    }

    for (let i = 0; i < tower.length; i++) {
      if (
        Math.abs(se.heightAboveGroundM! - tower[i][0].calculationHeightM!) < EPS
      ) {
        // replace that part of the tower
        se.uid = tower[i][0].uid;
        tower[i][0] = se;
        return tower.flat();
      }

      if (se.heightAboveGroundM! < tower[i][0].calculationHeightM!) {
        const p1: ConduitEntity = {
          color: null,
          conduitType: "pipe",
          endpointUid: [se.uid, tower[i][0].uid],
          heightAboveFloorM: 0,
          lengthM: null,
          parentUid: null,
          systemUid: this.entity.systemUid,
          conduit: {
            diameterMM: null,
            material: null,
            maximumVelocityMS: null,
            maximumPressureDropRateKPAM: null,
            gradePCT: null,
            network: DEFAULT_VERTICAL_SYSTEM_NETWORKS[system.type],
            configurationCosmetic: null,
          },
          type: EntityType.CONDUIT,
          uid: this.uid + "." + tower.length + ".a.p",
          entityName: null,
        };

        const p2: ConduitEntity = {
          color: null,
          endpointUid: [se.uid, tower[i - 1][0].uid],
          conduitType: "pipe",
          parentUid: null,
          lengthM: null,
          conduit: {
            diameterMM: null,
            material: null,
            maximumVelocityMS: null,
            maximumPressureDropRateKPAM: null, // TODO: these values should be copied from the tower.
            gradePCT: null,
            network: DEFAULT_VERTICAL_SYSTEM_NETWORKS[system.type],
            configurationCosmetic: null,
          },
          heightAboveFloorM: 0,

          systemUid: this.entity.systemUid,
          type: EntityType.CONDUIT,
          uid: this.uid + "." + tower.length + ".b.p",
          entityName: null,
        };

        tower[i][1] = p1;

        return [p2, se, ...tower.flat()];
      }
    }

    throw new Error("Numerically, we shouldn't be here");
  }
  collectCalculations(context: CoreContext): FlowSourceCalculation {
    let calc: FlowSourceCalculation | undefined;

    const entities = this.getCalculationEntities(context);
    for (const e of entities) {
      if (e.type === EntityType.FLOW_SOURCE) {
        calc = context.globalStore.getOrCreateCalculation(e);
      }
    }

    if (calc === undefined) {
      throw new Error("Flow Source Calculations not found");
    }

    const res = emptyFlowSourceCalculation();
    res.flowRateLS = calc.flowRateLS;
    res.pressureKPA = calc.pressureKPA;
    res.warnings = calc.warnings;
    res.staticPressureKPA = calc.staticPressureKPA;
    res.reference = calc.reference;

    const tower = this.getCalculationTower(context);

    tower.forEach(([v, p]) => {
      res.warnings = [
        ...(res.warnings || []),
        ...(context.globalStore.getOrCreateCalculation(v).warnings || []),
      ];
    });

    return res;
  }

  collectLiveCalculations(context: CoreContext): FlowSourceLiveCalculation {
    // find the only directed valve in there and take its calc.
    const me = this.getCalculationEntities(context).find(
      (e) => e.type === EntityType.FLOW_SOURCE,
    ) as FlowSourceEntity;

    if (!me) {
      if (this.getCalculationEntities(context).length === 0) {
        return emptyFlowSourceLiveCalculation();
      } else {
        throw new Error("Can't find self in calculations " + this.uid);
      }
    } else {
      return context.globalStore.getOrCreateLiveCalculation(me);
    }
  }
  costBreakdown(context: CoreContext): CostBreakdown {
    return { cost: 0, breakdown: [] };
  }
}
