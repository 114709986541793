import { uniq } from "lodash";
import { assertUnreachable } from "../../lib/utils";
import CoreConduit from "../coreObjects/coreConduit";
import { PipeCalculation } from "../document/calculations-objects/conduit-calculations";
import { getFlowSystemLayouts } from "../document/calculations-objects/utils";
import { addWarning } from "../document/calculations-objects/warnings";
import { isHeatLoadPlant } from "../document/entities/plants/plant-entity";
import {
  PlantType,
  VolumiserPlant,
} from "../document/entities/plants/plant-types";
import { EntityType } from "../document/entities/types";
import { getFlowSystem } from "../document/utils";
import CalculationEngine from "./calculation-engine";

import { Logger } from "../../lib/logger";
import { SentryEntityError } from "../../lib/sentry-entity-error";
import { SentryError } from "../../lib/sentry-error";
import {
  StandardFlowSystemUids,
  isClosedSystem,
  isHeatingPlantSystem,
  isMechanical,
} from "../config";
import { determineConnectableSystemUid } from "../coreObjects/utils";
import {
  isBalancingEntity,
  setBalancingPressureDropKPA,
} from "../document/calculations-objects/balancing-entity";
import { CalculatableEntityConcrete } from "../document/entities/concrete-entity";
import ConduitEntity, {
  fillDefaultConduitFields,
} from "../document/entities/conduit-entity";
import { fillPlantDefaults } from "../document/entities/plants/plant-defaults";
import { isCIBSEDiversifiedPlant } from "../document/entities/plants/utils";
import { flowSystemNetworkHasSpareCapacity } from "../document/flow-systems";
import { TraceCalculation } from "./flight-data-recorder";
import { FlowAssignment } from "./flow-assignment";
import { FlowGraph } from "./flow-graph";
import FlowSolver from "./flow-solver";
import { solveFlow } from "./generic-flow-solver";
import { GlobalFDR } from "./global-fdr";
import Graph, { Edge, SubGraph, VISIT_RESULT_WRONG_WAY } from "./graph";
import {
  GenericReturnRecord,
  GenericReturnRecordPressureLoss,
  HeatLossResult,
  IterationResult,
  MINIMUM_BALANCING_VALVE_PRESSURE_DROP_KPA,
  ReturnCalculations,
} from "./returns";
import { ternarySearchForGlobalMin } from "./search-functions";
import { SPReturnCalculator } from "./sp-returns";
import {
  EdgeType,
  FlowEdge,
  FlowNode,
  PressureLossResult,
  PressurePushMode,
} from "./types";
import {
  IndexCircuitPath,
  applySpareCapacity,
  calculatePipeVolumeL,
  getConnectedPipeNetwork,
  getRecirculationPumpNodeCalc,
  getReturnOutletNodeCalc,
  maybeAddWarningForMisplacedHeatEmitters,
} from "./utils";

export class GenericReturnCalculator {
  @TraceCalculation("Precomputing heat loss")
  static precomputeHeatLostWATT(
    context: CalculationEngine,
    edges: Edge<FlowNode, FlowEdge>[],
    tempC: number,
    returnTempC: number,
    edgeHeatLoss: Map<string, number | null>,
    calculatePipeHeatLoad: boolean = true,
  ): number {
    let totalWatts = 0;
    for (const e of edges) {
      GlobalFDR.focusData([e.uid]);
      e.from.connectable;
      switch (e.value.type) {
        case EdgeType.CONDUIT:
          const pipe = context.globalStore.getObjectOfTypeOrThrow(
            EntityType.CONDUIT,
            e.value.uid,
          );
          const thisWatts =
            SPReturnCalculator.getOrIgnoreHeatLossOfPipeWATT(
              context,
              pipe,
              tempC,
              calculatePipeHeatLoad,
            ) || 0;
          edgeHeatLoss.set(e.uid, thisWatts);
          totalWatts += thisWatts;
          break;
        case EdgeType.PLANT_THROUGH: {
          const result = ReturnCalculations.getPlantThroughHeatLoss(
            context,
            e.value.uid,
            e.from.connectable,
          );

          const o = context.globalStore.getObjectOfTypeOrThrow(
            EntityType.PLANT,
            e.value.uid,
          );
          const nss = context.globalStore.getObjectOfTypeOrThrow(
            EntityType.SYSTEM_NODE,
            e.from.connectable,
          );
          if (isHeatLoadPlant(o.entity.plant)) {
            if (o.entity.plant.type !== PlantType.RETURN_SYSTEM) {
              let kw = 0;
              let plant = o.getHeatLossKW(nss.entity.systemUid)!;
              kw = plant.totalKW - plant.closedKW - plant.domesticKW;
              const thisWatts = (kw || 0) * 1000;
              edgeHeatLoss.set(e.uid, thisWatts);
              totalWatts += thisWatts;
            }
          }
          break;
        }
        case EdgeType.PLANT_PREHEAT: {
          const result = ReturnCalculations.getPlantPreheatHeatLoss(
            context,
            e.value.uid,
            e.from.connectable,
            tempC,
            returnTempC,
          );
          if (result) {
            const thisWatts =
              result.totalWATT - result.closedWATT - result.domesticWATT;
            edgeHeatLoss.set(e.uid, thisWatts);
            totalWatts += thisWatts;
          }
        }
        case EdgeType.BIG_VALVE_HOT_HOT:
        case EdgeType.BIG_VALVE_HOT_WARM:
        case EdgeType.BIG_VALVE_COLD_WARM:
        case EdgeType.BIG_VALVE_COLD_COLD:
        case EdgeType.FITTING_FLOW:
        case EdgeType.FLOW_SOURCE_EDGE:
        case EdgeType.CHECK_THROUGH:
        case EdgeType.ISOLATION_THROUGH:
        case EdgeType.RETURN_PUMP:
        case EdgeType.BALANCING_THROUGH:
          break;
        default:
          assertUnreachable(e.value.type);
      }
    }
    return totalWatts;
  }

  @TraceCalculation("Making return graph")
  static makeReturnGraph(
    context: CalculationEngine,
    biconnectedComponent: SubGraph<FlowNode, FlowEdge>,
    excludedEdges?: Set<string>,
  ) {
    // remove faux pump edge that was inserted to allow biconnected component detection, but will
    // mess with our flow assignment from here on out.
    const edges = biconnectedComponent[1].filter(
      (e) =>
        e.value.type !== EdgeType.RETURN_PUMP &&
        (!excludedEdges || !excludedEdges.has(e.uid)),
    );
    if (
      edges.length !==
      biconnectedComponent[1].length - 1 - (excludedEdges?.size || 0)
    ) {
      const pumpEdge = biconnectedComponent[1].find(
        (e) => e.value.type === EdgeType.RETURN_PUMP,
      );
      throw new SentryError(
        "Failed to remove return pump edge",
        {},
        {
          pumpEdge,
        },
      );
    }
    return Graph.fromSubgraph(
      [biconnectedComponent[0], edges],
      context.flowGraph.sn,
    );
  }

  // Assumes: record.graph exists, and flow directions have been set.
  // If there is a diverter valve, this function generates all the diverter
  // valve's possible configurations, and returns individual graphs that
  // must be solved. The pipe results are the max of each scenario.
  // Note: Correct results can only be guaranteed for this process with at most
  // one diverter valve, therefore the limit should be 1 diverter valve on the
  // network. With more diverter valves, balancing is not guaranteed to be correct.
  @TraceCalculation(
    "Extracting diverter valve situations to process",
    (_, r) => [r.plant.uid],
  )
  static getGraphsToProcess(
    context: CalculationEngine,
    record: GenericReturnRecord,
  ) {
    const inReturn = new Set<string>();
    for (const e of record.biconnectedComponent[1]) {
      inReturn.add(e.value.uid);
    }
    const diverterOfInterest = record.diverterValves.filter((d) => {
      const connections = context.globalStore
        .getConnections(d.uid)
        .filter((c) => inReturn.has(c));
      return connections.length === 3;
    });
    if (diverterOfInterest.length === 1) {
      const dUid = diverterOfInterest[0].uid;
      const connections = context.globalStore
        .getConnections(dUid)
        .filter((c) => inReturn.has(c));
      const pipeEdges = connections
        .map((pUid) => {
          const srcNode: FlowNode = {
            connectable: dUid,
            connection: pUid,
          };
          return context.flowGraph.adjacencyList
            .get(context.flowGraph.sn(srcNode))
            ?.find((e) => e.value.type === EdgeType.CONDUIT);
        })
        .filter((e): e is Edge<FlowNode, FlowEdge> => !!e);

      const incoming = pipeEdges.filter(
        (e) =>
          record.edgeFlowSource?.get(e.uid) ===
            context.flowGraph.sn({
              connectable: dUid,
              connection: e.value.uid,
            }) && inReturn.has(e.value.uid),
      );

      const outgoing = pipeEdges.filter(
        (e) =>
          record.edgeFlowSource?.get(e.uid) !==
            context.flowGraph.sn({
              connectable: dUid,
              connection: e.value.uid,
            }) && inReturn.has(e.value.uid),
      );

      if (incoming.length === 2) {
        return [
          this.makeReturnGraph(
            context,
            record.biconnectedComponent,
            new Set([incoming[0].uid]),
          ),
          this.makeReturnGraph(
            context,
            record.biconnectedComponent,
            new Set([incoming[1].uid]),
          ),
        ];
      } else if (outgoing.length === 2) {
        return [
          this.makeReturnGraph(
            context,
            record.biconnectedComponent,
            new Set([outgoing[0].uid]),
          ),
          this.makeReturnGraph(
            context,
            record.biconnectedComponent,
            new Set([outgoing[1].uid]),
          ),
        ];
      } else {
        throw new Error(
          "Unexpected diverter valve flow - either all incoming or all outgoing",
        );
      }
    } else if (diverterOfInterest.length > 1) {
      Logger.error("Too many diverter valves");
    }

    return [record.graph!];
  }

  @TraceCalculation(
    "Solving CIBSE Divisified Flow Rate on generic network",
    (_1, _2, r, _3, _4) => [r.plant.uid],
  )
  static solveDivisifiedFlowRateOnNonBypassableGraph(
    context: CalculationEngine,
    graph: Graph<FlowNode, FlowEdge>,
    record: GenericReturnRecord,
    result: Map<string, HeatLossResult>,
    totalresult: HeatLossResult,
  ) {
    // A non-bypassable graph is one where there's only one path from and to each diversified
    // load (along the already determined flow directions). This property is needed for non-
    // ambiguous diversification. If graph is bypassable, we cannot calculate diversification
    // so we add a warning. Eg of a bypassable generic return, is a reverse return setup.
    let isNonBypassable = true;
    // Map from edge uid to all downstream AND upstream diversified plants. We use
    // this to check that the graph is nonBypassable later.
    let streams: Map<string, Set<string>> = new Map();
    for (const [_, e] of graph.edgeList) {
      GlobalFDR.focusData([
        e.from.connectable,
        e.from.connection,
        e.value.uid,
        e.to.connectable,
        e.to.connection,
      ]);

      if (e.value.type !== EdgeType.CONDUIT) {
        if (e.value.type === EdgeType.PLANT_PREHEAT) {
          const plant = context.globalStore.getObjectOfTypeOrThrow(
            EntityType.PLANT,
            e.value.uid,
          );
          const nss = context.globalStore.getObjectOfTypeOrThrow(
            EntityType.SYSTEM_NODE,
            e.from.connectable,
          );
          if (isCIBSEDiversifiedPlant(plant.entity)) {
            let ls = plant.getHeatLossKW(nss.entity.systemUid);

            totalresult.totalWATT += ls?.totalKW! * 1000;
            totalresult.closedWATT += ls?.closedKW! * 1000;
            totalresult.domesticWATT += ls?.domesticKW! * 1000;
            totalresult.numberOfClosedAppliances +=
              ls?.numberOfClosedAppliances!;
            totalresult.numberOfDomesticAppliances +=
              ls?.numberOfDomesticAppliances!;
          }
        }
        continue;
      }
      // dfs forword to get downstream nodess
      graph.dfs(
        record.edgeFlowSource!.get(e.uid) === graph.sn(e.to) ? e.from : e.to,
        undefined,
        undefined,
        (edge) => {
          if (edge.value.type === EdgeType.FITTING_FLOW) {
            return;
          }
          if (
            record.edgeFlowSource!.has(edge.uid) &&
            record.edgeFlowSource!.get(edge.uid) !== graph.sn(edge.from)
          ) {
            return VISIT_RESULT_WRONG_WAY;
          }
          if (edge.value.type === EdgeType.PLANT_PREHEAT) {
            const plant = context.globalStore.getObjectOfTypeOrThrow(
              EntityType.PLANT,
              edge.value.uid,
            );
            if (isCIBSEDiversifiedPlant(plant.entity)) {
              let tmp = streams.get(e.value.uid) || new Set();
              tmp.add(plant.entity.uid);
              streams.set(e.value.uid, tmp);
            }
          }
        },
        undefined,
        undefined,
        undefined,
      );
      //dfs backwords to get upstream nodes
      graph.dfs(
        record.edgeFlowSource!.get(e.uid) === graph.sn(e.from) ? e.from : e.to,
        undefined,
        undefined,
        (edge) => {
          if (edge.value.type === EdgeType.FITTING_FLOW) {
            return;
          }
          if (
            record.edgeFlowSource!.has(edge.uid) &&
            record.edgeFlowSource!.get(edge.uid) !== graph.sn(edge.to)
          ) {
            // going the wrong way. Returning the wrong way constant removes this edge from seen list so
            // it will be revisited the other way next time.
            return VISIT_RESULT_WRONG_WAY;
          }
          if (edge.value.type === EdgeType.PLANT_PREHEAT) {
            const plant = context.globalStore.getObjectOfTypeOrThrow(
              EntityType.PLANT,
              edge.value.uid,
            );
            if (isCIBSEDiversifiedPlant(plant.entity)) {
              let tmp = streams.get(e.value.uid) || new Set();
              tmp.add(plant.entity.uid);
              streams.set(e.value.uid, tmp);
            }
          }
        },
        undefined,
        undefined,
        undefined,
        true,
        true,
      );
      // calculate diversified heat load
      let heatLoss: HeatLossResult = {
        totalWATT: 0,
        closedWATT: 0,
        domesticWATT: 0,
        numberOfClosedAppliances: 0,
        numberOfDomesticAppliances: 0,
      };
      for (let stream of streams.get(e.value.uid) || []) {
        const plant = context.globalStore.getObjectOfTypeOrThrow(
          EntityType.PLANT,
          stream,
        );
        const ns = context.globalStore.ofTagOrThrow(
          "connectable",
          e.from.connectable,
        );

        // systemUid must be defined in practice because every point in a network should
        // be connected, defining any entities that have defaults like directedValves.
        let systemUid =
          determineConnectableSystemUid(context.globalStore, ns.entity) ??
          StandardFlowSystemUids.Heating;

        // do we already calculate the data for the plant? we do!
        let ls = plant.getHeatLossKW(systemUid);

        heatLoss.totalWATT += ls?.totalKW! * 1000;
        heatLoss.closedWATT += ls?.closedKW! * 1000;
        heatLoss.domesticWATT += ls?.domesticKW! * 1000;
        heatLoss.numberOfClosedAppliances += ls?.numberOfClosedAppliances!;
        heatLoss.numberOfDomesticAppliances += ls?.numberOfDomesticAppliances!;
      }
      result.set(e.value.uid, heatLoss);

      //delete edge, check all streams disconnected to both way
      let thisStream: Set<string> = new Set();
      graph.dfs(
        {
          connectable: record.outletUid,
          connection: record.plant.uid,
        },
        undefined,
        undefined,
        (edge) => {
          if (edge.value.type === EdgeType.FITTING_FLOW) {
            return;
          }
          if (
            record.edgeFlowSource!.has(edge.uid) &&
            record.edgeFlowSource!.get(edge.uid) !== graph.sn(edge.from)
          ) {
            // going the wrong way. Returning the wrong way constant removes this edge from seen list so
            // it will be revisited the other way next time.
            return VISIT_RESULT_WRONG_WAY;
          }
          if (isNonBypassable === false) {
            return true;
          }
          if (edge.value.type === EdgeType.PLANT_PREHEAT) {
            const plant = context.globalStore.getObjectOfTypeOrThrow(
              EntityType.PLANT,
              edge.value.uid,
            );
            if (
              isCIBSEDiversifiedPlant(plant.entity) &&
              streams.get(e.value.uid)?.has(plant.entity.uid)
            ) {
              if (thisStream.has(plant.entity.uid)) {
                isNonBypassable = false;
                console.log("bypassable (forwards) due to", {
                  edge,
                  plant,
                });
                return true;
              }
              thisStream.add(plant.entity.uid);
            }
          }
          if (edge.uid === e.uid) {
            return true;
          }
        },
        undefined,
        undefined,
        undefined,
      );
      // DFS backwards
      graph.dfs(
        {
          connectable: record.returnUid,
          connection: record.plant.uid,
        },
        undefined,
        undefined,
        (edge) => {
          if (edge.value.type === EdgeType.FITTING_FLOW) {
            return;
          }
          if (
            record.edgeFlowSource!.has(edge.uid) &&
            record.edgeFlowSource!.get(edge.uid) !== graph.sn(edge.to)
          ) {
            // going the wrong way. Returning the wrong way constant removes this edge from seen list so
            // it will be revisited the other way next time.
            return VISIT_RESULT_WRONG_WAY;
          }
          if (isNonBypassable === false) {
            return true;
          }
          if (edge.value.type === EdgeType.PLANT_PREHEAT) {
            const plant = context.globalStore.getObjectOfTypeOrThrow(
              EntityType.PLANT,
              edge.value.uid,
            );
            if (
              isCIBSEDiversifiedPlant(plant.entity) &&
              streams.get(e.value.uid)?.has(plant.entity.uid)
            ) {
              if (thisStream.has(plant.entity.uid)) {
                isNonBypassable = false;
                console.log("bypassable (backwards) due to", {
                  e,
                  edge,
                  plant,
                });
                return true;
              }
              thisStream.add(plant.entity.uid);
            }
          }
          if (edge.uid === e.uid) {
            return true;
          }
        },
        undefined,
        undefined,
        undefined,
        true,
        true,
      );
    }
    // console.log(streams);
    return isNonBypassable;
  }
  // currently, only supports generic (including reverse return) for heating with
  // radiators but not fixtures.
  @TraceCalculation("Calculating Flow Rates (Generic)", (c, r) => [r.plant.uid])
  static genericReturnFlowRates(
    context: CalculationEngine,
    record: GenericReturnRecord,
  ) {
    const timeStart = performance.now();
    const filled = fillPlantDefaults(context, record.plant);
    // Find the specific outlet index of the root node.
    const selectedOutletIndex = filled.plant.outlets.findIndex(
      (outlet) => outlet.outletUid === record.outletUid,
    );
    const selectedOutlet = filled.plant.outlets[selectedOutletIndex];
    let outletCalc = getRecirculationPumpNodeCalc(context, selectedOutlet);
    if (outletCalc === null) {
      throw new Error("Failed to find outlet calc");
    }

    let graphsToProcess = this.getGraphsToProcess(context, record);

    const scenarios: {
      graph: Graph<FlowNode, FlowEdge>;
      needToCover: SubGraph<FlowNode, FlowEdge>;
    }[] = [];

    if (graphsToProcess.length > 1) {
      // Each diverter valve scenario will have a different amount of total heat
      // that needs to be accounted for.
      for (const graph of graphsToProcess) {
        const needToCover = ReturnCalculations.getReturnComponent(
          context,
          graph,
          record.plant,
          record.outletUid,
          record.returnUid,
        );
        if (!needToCover) {
          throw new Error("Failed to get return component");
        }
        scenarios.push({
          graph: this.makeReturnGraph(context, needToCover),
          needToCover,
        });
      }
    } else {
      console.log("entire loop");
      scenarios.push({
        graph: graphsToProcess[0],
        needToCover: record.biconnectedComponent,
      });
    }

    // check divisified flow rates
    let isDivisified = false;
    for (const { graph, needToCover } of scenarios) {
      for (const e of graph.edgeList) {
        if (
          e[1].value.type === EdgeType.PLANT_PREHEAT &&
          isCIBSEDiversifiedPlant(
            context.globalStore.getObjectOfTypeOrThrow(
              EntityType.PLANT,
              e[1].value.uid,
            ).entity,
          )
        ) {
          isDivisified = true;
        }
      }
    }

    // result for diversified flow rates
    let result: Map<string, HeatLossResult> = new Map<string, HeatLossResult>();
    let totalresult: HeatLossResult = {
      totalWATT: 0,
      closedWATT: 0,
      domesticWATT: 0,
      numberOfClosedAppliances: 0,
      numberOfDomesticAppliances: 0,
    };
    if (isDivisified) {
      // Try to solve if it is a non-bypassable graph
      let solved = true;
      for (const { graph, needToCover } of scenarios) {
        const lsresult = new Map<string, HeatLossResult>();
        const lstotalresult = {
          totalWATT: 0,
          closedWATT: 0,
          domesticWATT: 0,
          numberOfClosedAppliances: 0,
          numberOfDomesticAppliances: 0,
        };
        if (
          !this.solveDivisifiedFlowRateOnNonBypassableGraph(
            context,
            graph,
            record,
            lsresult,
            lstotalresult,
          )
        )
          solved = false;
        else {
          if (
            ReturnCalculations.diversifyDistrictW(lstotalresult) >
            ReturnCalculations.diversifyDistrictW(totalresult)
          ) {
            totalresult = lstotalresult;
          }
          for (const [key, value] of lsresult) {
            if (!result.has(key)) {
              result.set(key, value);
            } else {
              if (
                ReturnCalculations.diversifyDistrictW(value) >
                ReturnCalculations.diversifyDistrictW(result.get(key)!)
              ) {
                result.set(key, value);
              }
            }
          }
        }
      }
      if (!solved) {
        for (const { graph, needToCover } of scenarios) {
          for (const e of graph.edgeList) {
            if (e[1].value.type === EdgeType.CONDUIT) {
              addWarning(
                context,
                "CIBSE_DIVERSIFIED_FLOW_CAN_NOT_BE_CALCULATED",
                [
                  context.globalStore.ofTagOrThrow(
                    "calculatable",
                    e[1].value.uid,
                  ).entity,
                ],
                {
                  mode: "mechanical",
                },
              );
            }
          }
        }

        const pCalc = context.globalStore.getOrCreateCalculation(record.plant);

        pCalc.circulationFlowRateLS[selectedOutletIndex] = null;
        pCalc.returnLoopHeatLossKW[selectedOutletIndex] = null;
        return;
      } else {
      }
    }

    const RETURNS_RESIZE_MAX_ITER = 10;
    let lastIterationResult: IterationResult | null = null;
    for (let i = 0; i < RETURNS_RESIZE_MAX_ITER; i++) {
      const iterStart = performance.now();
      let didChange = false;
      for (const { graph, needToCover } of scenarios) {
        lastIterationResult = this.determineFlowRatesSingleIteration(
          context,
          record,
          graph,
          needToCover,
        );
        didChange = Boolean(didChange || lastIterationResult?.pipeSizesChanged);
      }
      console.log(
        "determineFlowRatesSingleIteration took",
        performance.now() - iterStart,
        "ms",
      );
      if (!didChange) {
        console.log("determineFlowRatesSingleIteration converged");
        break;
      }
    }

    let totalDiversifiedFlowRateLS = 0;
    if (isDivisified) {
      const filled = fillPlantDefaults(context, record.plant);

      const selectedOutlet = filled.plant.outlets.filter(
        (o) => o.outletUid === record.outletUid,
      )[0];

      totalDiversifiedFlowRateLS = ReturnCalculations.heatLoadToFlowRateLS(
        context,
        selectedOutlet.outletSystemUid,
        selectedOutlet.outletTemperatureC!,
        selectedOutlet.returnLimitTemperatureC!,
        ReturnCalculations.diversifyDistrictW(totalresult) / 1000,
      );

      for (const { graph, needToCover } of scenarios) {
        for (const [_, edge] of graph.edgeList) {
          switch (edge.value.type) {
            case EdgeType.CONDUIT: {
              const pipeD = context.getCalcByPipeId(edge.value.uid);
              if (!pipeD) {
                throw new Error(
                  "non-pipe conduit in return graph " + edge.value.uid,
                );
              }

              let nowresult: HeatLossResult = {
                totalWATT:
                  ReturnCalculations.flowRateToHeatLoadKW(
                    context,
                    selectedOutlet.outletSystemUid,
                    selectedOutlet.outletTemperatureC!,
                    selectedOutlet.returnLimitTemperatureC!,
                    pipeD.pCalc.returnFlowRateLS!,
                  ) * 1000,
                closedWATT: 0,
                domesticWATT: 0,
                numberOfClosedAppliances: 0,
                numberOfDomesticAppliances: 0,
              };
              pipeD.pCalc.totalKW = Math.abs(nowresult.totalWATT / 1000);
              pipeD.pCalc.closedKW = 0;
              pipeD.pCalc.domesticKW = 0;
              if (result.has(edge.value.uid)) {
                let diversifiedresult = result.get(edge.value.uid)!;
                nowresult = {
                  totalWATT: nowresult.totalWATT + diversifiedresult.totalWATT,
                  closedWATT:
                    nowresult.closedWATT + diversifiedresult.closedWATT,
                  domesticWATT:
                    nowresult.domesticWATT + diversifiedresult.domesticWATT,
                  numberOfClosedAppliances:
                    nowresult.numberOfClosedAppliances +
                    diversifiedresult.numberOfClosedAppliances,
                  numberOfDomesticAppliances:
                    nowresult.numberOfDomesticAppliances +
                    diversifiedresult.numberOfDomesticAppliances,
                };
              }
              pipeD.pCalc.totalKW = Math.abs(nowresult.totalWATT / 1000);
              pipeD.pCalc.closedKW = Math.abs(nowresult.closedWATT / 1000);
              pipeD.pCalc.domesticKW = Math.abs(nowresult.domesticWATT / 1000);

              if (
                nowresult.numberOfClosedAppliances > 0 ||
                nowresult.numberOfDomesticAppliances > 0
              ) {
                pipeD.pCalc.diversifiedTotalKW = Math.abs(
                  ReturnCalculations.diversifyDistrictW(nowresult) / 1000,
                );
                pipeD.pCalc.diversifiedClosedKW = Math.abs(
                  ReturnCalculations.diversifyDistrictClosed(nowresult) / 1000,
                );
                pipeD.pCalc.diversifiedDomesticKW = Math.abs(
                  ReturnCalculations.diversifyDistrictDomestic(nowresult) /
                    1000,
                );
              }

              pipeD.pCalc.rawReturnFlowRateLS =
                ReturnCalculations.heatLoadToFlowRateLS(
                  context,
                  selectedOutlet.outletSystemUid,
                  selectedOutlet.outletTemperatureC!,
                  selectedOutlet.returnLimitTemperatureC!,
                  ReturnCalculations.diversifyDistrictW(nowresult) / 1000,
                );

              pipeD.pCalc.returnFlowRateLS = applySpareCapacity(
                context,
                pipeD.pipe,
                pipeD.pCalc.rawReturnFlowRateLS,
              );
              pipeD.pCalc.totalPeakFlowRateLS = pipeD.pCalc.returnFlowRateLS;
            }
          }
        }
      }
    }

    const pCalc = context.globalStore.getOrCreateCalculation(record.plant);
    if (lastIterationResult?.totalFlowRateLS) {
      lastIterationResult.totalFlowRateLS += totalDiversifiedFlowRateLS;
    }
    if (lastIterationResult?.totalHeatLossKW) {
      lastIterationResult.totalHeatLossKW +=
        ReturnCalculations.diversifyDistrictW(totalresult); // TODO: this is suss
    }
    pCalc.circulationFlowRateLS[selectedOutletIndex] =
      lastIterationResult?.totalFlowRateLS || null;
    outletCalc.circulationFlowRateLS =
      lastIterationResult?.totalFlowRateLS || null;

    const totalHeatLoss = (pCalc.returnLoopHeatLossKW[selectedOutletIndex] =
      lastIterationResult?.totalHeatLossKW || null);
    if (record.isCooling && totalHeatLoss) {
      pCalc.returnLoopHeatLossKW[selectedOutletIndex] = totalHeatLoss;
    }

    const timeEnd = performance.now();
    console.log("genericReturnFlowRates took", timeEnd - timeStart, "ms");
  }

  @TraceCalculation("Marking return pipes (Generic)", (c, r) => [r.plant.uid])
  static determineFlowDirections(
    context: CalculationEngine,
    record: GenericReturnRecord,
  ) {
    const edgeFlowSource = (record.edgeFlowSource = new Map<string, string>());
    if (!record.graph) {
      throw new Error("Graph or edgeFlowSource not initialized");
    }
    const solver = new FlowSolver(record.graph, context);

    const demandsLS = new Map<string, number>();
    const suppliesKPA = new Map<string, number>();
    suppliesKPA.set(record.outletUid, 0);
    demandsLS.set(record.returnUid, 100);

    const assignment = solver.solveFlowDirectionOnly(demandsLS, suppliesKPA);

    for (const edge of record.biconnectedComponent[1]) {
      GlobalFDR.focusData([
        edge.from.connectable,
        edge.from.connection,
        edge.to.connectable,
        edge.to.connection,
      ]);
      if (edge.value.type === EdgeType.CONDUIT) {
        const pipe = context.globalStore.getObjectOfTypeOrThrow(
          EntityType.CONDUIT,
          edge.value.uid,
        );
        const flow = assignment.getFlow(edge.uid, record.graph.sn(edge.from));

        const fromUid = edge.from.connectable;
        const toUid = edge.to.connectable;
        const calc = context.globalStore.getOrCreateCalculation(pipe.entity);
        if (flow >= 0) {
          calc.flowFrom = fromUid;
          edgeFlowSource.set(edge.uid, record.graph.sn(edge.from));
        } else {
          calc.flowFrom = toUid;
          edgeFlowSource.set(edge.uid, record.graph.sn(edge.to));
        }
      } else if (edge.value.type === EdgeType.PLANT_THROUGH) {
        if (edge.isDirected) {
          if (edge.isReversed) {
            edgeFlowSource.set(edge.uid, record.graph.sn(edge.to));
          } else {
            edgeFlowSource.set(edge.uid, record.graph.sn(edge.from));
          }
        } else {
          const flow = assignment.getFlow(edge.uid, record.graph.sn(edge.from));
          if (flow >= 0) {
            edgeFlowSource.set(edge.uid, record.graph.sn(edge.from));
          } else {
            edgeFlowSource.set(edge.uid, record.graph.sn(edge.to));
          }
        }
      } else if (edge.isDirected) {
        if (edge.isReversed) {
          edgeFlowSource.set(edge.uid, record.graph.sn(edge.to));
        } else {
          edgeFlowSource.set(edge.uid, record.graph.sn(edge.from));
        }
      }
    }
  }

  @TraceCalculation("Generating initial flow assignment", (c, r, _1, _2) => [
    r.plant.uid,
  ])
  static generateInitialFlowAssignment(
    context: CalculationEngine,
    record: GenericReturnRecord,
    graph: Graph<FlowNode, FlowEdge>,
    totalFlowRateLS: number,
  ) {
    const flowGraph = new FlowGraph();

    if (!record.edgeFlowSource) {
      throw new Error("Edge flow source not set");
    }

    for (const [eUid, edge] of graph.edgeList) {
      GlobalFDR.focusData([
        edge.from.connectable,
        edge.from.connection,
        edge.to.connectable,
        edge.to.connection,
      ]);
      if (edge.value.type === EdgeType.CONDUIT) {
        const pipe = context.globalStore.getObjectOfTypeOrThrow(
          EntityType.CONDUIT,
          edge.value.uid,
        );
        const calc = context.globalStore.getOrCreateCalculation(pipe.entity);
        const flowFrom = calc.flowFrom;

        if (!flowFrom) {
          Logger.error("Flow from not set");
          return null;
        }

        if (flowFrom === edge.from.connectable) {
          flowGraph.addDirectedEdge(edge.from, edge.to, edge.value, edge.uid);
        } else if (flowFrom === edge.to.connectable) {
          flowGraph.addDirectedEdge(edge.to, edge.from, edge.value, edge.uid);
        } else {
          Logger.error("Flow from not set correctly", {
            flowFrom,
            edgeFrom: edge.from.connectable,
            edgeTo: edge.to.connectable,
          });
          return null;
        }
      } else {
        if (edge.isDirected) {
          if (edge.isReversed) {
            flowGraph.addDirectedEdge(edge.to, edge.from, edge.value, edge.uid);
          } else {
            flowGraph.addDirectedEdge(edge.from, edge.to, edge.value, edge.uid);
          }
        } else {
          flowGraph.addEdge(edge.from, edge.to, edge.value, edge.uid);
        }
      }
    }

    const cover = flowGraph.DAGFlowPathCover({
      sources: [
        {
          connectable: record.outletUid,
          connection: record.plant.uid,
        },
      ],
      sinks: [
        {
          connectable: record.returnUid,
          connection: record.plant.uid,
        },
      ],
    });

    const perUnitLS = totalFlowRateLS / cover.length;

    const assignment = new FlowAssignment();
    for (const path of cover) {
      for (const edge of path) {
        assignment.addFlow(edge.uid, flowGraph.sn(edge.from), perUnitLS);
      }
    }

    return assignment;
  }

  // Returns true if pipe sizes changed (and hence potentially requires re-sizing).
  @TraceCalculation(
    "Determining flow rates, single iteration (Generic)",
    (c, r, _1, _2) => [r.plant.uid],
  )
  static determineFlowRatesSingleIteration(
    context: CalculationEngine,
    record: GenericReturnRecord,
    graph: Graph<FlowNode, FlowEdge>,
    needToCover: SubGraph<FlowNode, FlowEdge>,
  ): IterationResult | null {
    const filled = fillPlantDefaults(context, record.plant);

    if (filled.plant.type !== PlantType.RETURN_SYSTEM) {
      throw new Error(
        "Can only set Recirculation Flow Rates for return system",
      );
    }

    if (!record.edgeFlowSource) {
      throw new Error("Cannot determine flow rates without edge flow source");
    }
    const selectedOutlet = filled.plant.outlets.filter(
      (o) => o.outletUid === record.outletUid,
    )[0];
    const edgeFlowSource = record.edgeFlowSource;

    // use hardy cross.
    const edgeHeatLoss = new Map<string, number>();
    const totalHeatLossWATT = this.precomputeHeatLostWATT(
      context,
      needToCover[1],
      selectedOutlet.outletTemperatureC!,
      selectedOutlet.returnLimitTemperatureC!,
      edgeHeatLoss,
      selectedOutlet.calculatePipeHeatLoad ||
        !isClosedSystem(
          context.drawing.metadata.flowSystems[selectedOutlet.outletSystemUid],
        ),
    );

    const system = getFlowSystem(
      context.drawing,
      selectedOutlet.outletSystemUid,
    );
    if (!system) {
      throw new Error("Flow system not found");
    }

    if (totalHeatLossWATT === null) {
      // can't.
      Logger.error("Cannot determine flow rates without heat loss");
      return null;
    }

    const totalFlowRateLSRaw = ReturnCalculations.heatLoadToFlowRateLS(
      context,
      selectedOutlet.outletSystemUid,
      selectedOutlet.outletTemperatureC!,
      selectedOutlet.returnLimitTemperatureC!,
      totalHeatLossWATT / 1000,
    );

    let totalFlowRateLS = totalFlowRateLSRaw;
    if (flowSystemNetworkHasSpareCapacity(system)) {
      const pipeNetwork = getConnectedPipeNetwork(
        selectedOutlet.outletUid!,
        context,
      );
      if (pipeNetwork) {
        totalFlowRateLS *=
          1 + Number(system.networks[pipeNetwork]!.spareCapacityPCT) / 100;
      }
    }

    console.log("totalFlowRateLS", totalFlowRateLS);

    // TODO outlet fix this for multiple outlets
    if (record.error || !record.returnUid) {
      return null;
    }

    const assignment = this.generateInitialFlowAssignment(
      context,
      record,
      graph,
      totalFlowRateLSRaw,
    );

    if (!assignment) {
      return null;
    }

    let evaluations = 0;
    let result: FlowAssignment;

    try {
      const escapeDelta = assignment.size * 1e-7;
      result = solveFlow<FlowNode, FlowEdge>({
        assignment: assignment,
        graph,
        escapeDelta,
        computeCycleImbalance: (flowAssignment, cycle, iterations) => {
          const heatsAndFlows: [number, number][] = [];

          for (const edge of cycle) {
            let flow = flowAssignment.getFlow(edge.uid, graph.sn(edge.from));

            if (flow === null) {
              continue;
            }
            const heatloss = edgeHeatLoss.get(edge.uid);
            if (heatloss === undefined) {
              continue;
            }
            if (Math.abs(flow) < 1e-10) {
              if (edgeFlowSource.get(edge.uid) === graph.sn(edge.from)) {
                flow = 1e-9;
              } else {
                flow = -1e-9;
              }
            }
            heatsAndFlows.push([heatloss, flow]);
          }

          const computeImbalance = (adj: number, print: boolean = false) => {
            evaluations++;
            if (print) {
              console.log("cycle", cycle, "adj", adj);
            }
            let imbalance = 0;
            for (const [heatloss, flow] of heatsAndFlows) {
              imbalance += heatloss / (flow + adj);
              if (print) {
                console.log(
                  `${heatloss}/${flow + adj} = ${heatloss / (flow + adj)}`,
                );
              }
            }

            return imbalance;
          };

          let minAdj = -Infinity;
          let maxAdj = Infinity;
          let needsAdjustment = false;

          for (const edge of cycle) {
            GlobalFDR.focusData([
              edge.from.connectable,
              edge.from.connection,
              edge.value.uid,
              edge.to.connectable,
              edge.to.connection,
            ]);
            const flow = flowAssignment.getFlow(edge.uid, graph.sn(edge.from));
            if (!edgeHeatLoss.has(edge.uid)) {
              if (edge.value.type === EdgeType.CONDUIT) {
                console.log("no heat loss for pipe edge", edge);
              }
              continue;
            }
            needsAdjustment = true;

            if (edgeFlowSource.get(edge.uid) === graph.sn(edge.from)) {
              // flow must be positive.
              if (flow < 0) {
                throw new SentryError(
                  "Network is not solvable - flow direction ambiguous for edge (Negative Flow) ",
                  {},
                  {
                    flow,
                    edge,
                    edgeFlowSource,
                    sn: graph.sn(edge.from),
                  },
                );
              }
              minAdj = Math.max(minAdj, -flow);
            } else {
              // flow must be negative.
              if (flow > 0) {
                throw new SentryError(
                  "Network is not solvable - flow direction ambiguous for edge (Positive Flow)",
                );
              }
              maxAdj = Math.min(maxAdj, -flow);
            }
          }

          if (!needsAdjustment) {
            return {
              imbalance: 0,
              suggestedAdjustment: 0,
            };
          }

          const { value: bestAdj } = ternarySearchForGlobalMin({
            fn: (adj, print) => {
              return Math.abs(computeImbalance(adj, print));
            },
            low: minAdj,
            high: maxAdj,
            maxIterations: Math.min(30, iterations + 3),
          });
          const finalImbalance = computeImbalance(bestAdj);
          return {
            imbalance: finalImbalance,
            suggestedAdjustment: bestAdj,
          };
        },
        onCycles: (cycles) => {
          cycles = cycles.filter((c) =>
            c.some((e) => e.value.type === EdgeType.CONDUIT),
          );

          if (typeof window !== "undefined") {
            (window as any).cycles = cycles.map((c) =>
              uniq(c.map((edge) => edge.value.uid.split(".")[0])),
            );
            (window as any).cyclesRaw = cycles;
          }

          // cycle sanity check
          for (const cycle of cycles) {
            for (let i = 1; i < cycle.length; i++) {
              const edge = cycle[i];
              const prevEdge = cycle[i - 1];
              if (graph.sn(prevEdge.to) !== graph.sn(edge.from)) {
                for (const e of cycle) {
                  console.log(e.value.type, graph.sn(e.from), graph.sn(e.to));
                }
                throw new Error("Cycle is not connected");
              }
            }
          }

          return cycles;
        },
      });
    } catch (e) {
      addWarning(context, "RETURN_NETWORK_MISCONFIGURED", [record.plant], {
        mode: ["pressure", "mechanical"],
      });
      Logger.error(SentryError.wrapError("Failed to Determine Flor Rates", e));
      return null;
    }
    // At the moment, only be able to support flow rate through pipes.
    let pipeSizesChanged = false;
    for (const edge of record.biconnectedComponent[1]) {
      switch (edge.value.type) {
        case EdgeType.CONDUIT: {
          const pipeD = context.getCalcByPipeId(edge.value.uid);
          if (!pipeD) {
            throw new SentryEntityError(
              "non-pipe conduit in return graph",
              edge.value.uid,
            );
          }
          const filledPipe = fillDefaultConduitFields(context, pipeD.pEntity);
          const origRawReturnFlowRateLS = pipeD.pCalc.rawReturnFlowRateLS;
          const thisFlowRateLS = result.getFlow(edge.uid);

          pipeD.pCalc.rawReturnFlowRateLS = thisFlowRateLS;
          pipeD.pCalc.totalPeakFlowRateLS = pipeD.pCalc.rawReturnFlowRateLS;

          // We take (max) of the flow rate here to calculate pipe sizes
          // given different diverter valve scenarios.
          if (origRawReturnFlowRateLS != null && thisFlowRateLS != null) {
            if (thisFlowRateLS < origRawReturnFlowRateLS) {
              pipeD.pCalc.rawReturnFlowRateLS = origRawReturnFlowRateLS;
              pipeD.pCalc.totalPeakFlowRateLS = pipeD.pCalc.rawReturnFlowRateLS;
            }
          }
          pipeD.pCalc.returnFlowRateLS = applySpareCapacity(
            context,
            pipeD.pipe,
            pipeD.pCalc.rawReturnFlowRateLS!,
          );
          const system = getFlowSystem(
            context.drawing,
            pipeD.pEntity.systemUid,
          );
          pipeD.pCalc.returnFlowRateLS = pipeD.pCalc.rawReturnFlowRateLS; //apply spare capacity

          let peakFlowRate = pipeD.pCalc.PSDFlowRateLS;
          if (peakFlowRate !== null) {
            if (selectedOutlet.addReturnToPSDFlowRate) {
              peakFlowRate += pipeD.pCalc.returnFlowRateLS;
            }
            pipeD.pCalc.totalPeakFlowRateLS = Math.max(
              peakFlowRate,
              pipeD.pCalc.returnFlowRateLS,
            );
          }

          const origSize = pipeD.pCalc.realNominalPipeDiameterMM;

          context.sizePipeForFlowRate(pipeD.pEntity, [
            [peakFlowRate, filledPipe.conduit.maximumVelocityMS!],
            [
              pipeD.pCalc.returnFlowRateLS,
              Math.min(
                selectedOutlet.returnVelocityMS!,
                filledPipe.conduit.maximumVelocityMS!,
              ),
            ],
          ]);

          // this is not needed if we are given that flow rates only increase.
          // if (
          //   pCalc.realNominalPipeDiameterMM !== null &&
          //   origSize !== null &&
          //   pCalc.realNominalPipeDiameterMM < origSize
          // ) {
          //   pCalc.realNominalPipeDiameterMM = origSize;
          // }

          if (
            pipeD.pCalc.realNominalPipeDiameterMM !== origSize &&
            pipeD.pCalc.realNominalPipeDiameterMM !== null
          ) {
            pipeSizesChanged = true;
          }
          break;
        }
        // TODO: RPZDs.
        case EdgeType.PLANT_THROUGH:
        case EdgeType.BIG_VALVE_HOT_HOT:
        case EdgeType.BIG_VALVE_HOT_WARM:
        case EdgeType.BIG_VALVE_COLD_WARM:
        case EdgeType.BIG_VALVE_COLD_COLD:
        case EdgeType.FITTING_FLOW:
        case EdgeType.FLOW_SOURCE_EDGE:
        case EdgeType.CHECK_THROUGH:
        case EdgeType.ISOLATION_THROUGH:
        case EdgeType.RETURN_PUMP:
        case EdgeType.BALANCING_THROUGH:
        case EdgeType.PLANT_PREHEAT:
          break;
        default:
          assertUnreachable(edge.value.type);
      }
    }

    return {
      pipeSizesChanged,
      totalFlowRateLS,
      totalHeatLossKW: totalHeatLossWATT / 1000,
    };
  }

  /*
Size the balancing valves within the network.
Strategy is to use hardy-cross like loop iteration strategy, but instead of
adjusting flow rates to balance the network, we adjust the pressure losses
of the balancing valves in each cycle.

Design prerequisites:
  Each loop must contain a balancing valve to size.
  Like any return system, the flow direction must be defined.

Calculation stage prerequisites:
  The flow rate (and therefore flow direction) is assumed to be calculated already.
  The pipe sizes are assumed to be calculated already.

*/

  @TraceCalculation("Calculating Blancing Valves (Generic)", (c, r) => [
    r.plant.uid,
  ])
  static genericReturnBalanceValves(
    context: CalculationEngine,
    record: GenericReturnRecord,
  ): GenericReturnRecordPressureLoss {
    const timeStart = performance.now();
    if (record.error) {
      return {
        ...record,
        pressureFromKPA: null,
        pressureToKPA: null,
        maxPressuresKPA: null,
        isEdgePressureLossValid: null,
        edge2PressureLoss: null,
      };
    }
    if (!record.graph) {
      throw new Error("Graph not found");
    }
    const biconnectedGraph = record.graph;

    if (!record.edgeFlowSource) {
      throw new Error(
        "Flow source determinations must already be calculated before calling genericReturnBalanceValves",
      );
    }
    if (record.plant.plant.type !== PlantType.RETURN_SYSTEM) {
      throw new Error(
        "genericReturnBalanceValves can only be used on return systems",
      );
    }
    const edgeFlowSource = record.edgeFlowSource;

    const flowOut: FlowNode = {
      connectable: record.outletUid,
      connection: record.plant.uid,
    };

    const entityPressureFromKPA = new Map<string, Map<string, number | null>>();
    const entityPressureToKPA = new Map<string, Map<string, number | null>>();
    const entityMaxPressuresKPA = new Map<string, number | null>();
    const isEdgePressureLossValid = new Map<string, boolean>();
    const edge2PressureLoss = new Map<string, PressureLossResult>();

    // Note: we do a precompute of pressure loss per entity here using unbalanced
    // values but of course it will not work if there's a fixed pressure item in the
    // loop like a tank.
    context.pushPressureThroughNetwork({
      start: flowOut,
      pressureKPA: 0,
      entityMaxPressuresKPA,
      nodePressureKPA: new Map(), // don't care
      entityPressureFromKPA,
      entityPressureToKPA,
      edge2PressureLoss,
      pressurePushMode: PressurePushMode.CirculationFlowOnly,
      tryBothDirections: true,
      isEdgePressureLossValid,
      allowApproximate: true,
    });

    const balancingEntityPressureDropsKPA = new Map<string, number>();
    if (typeof window !== "undefined") {
      (window as any).balancingEntityPressureDropsKPA =
        balancingEntityPressureDropsKPA;
    }

    const flowIn: FlowNode = {
      connectable: record.returnUid,
      connection: record.plant.uid,
    };

    const entityImbalance = new Map<string, number>();

    /*
    Picture:
    file://./generic-balancing-valves.png

    Thousand words:
  In this method, we will search the graph backwards, propagating required pressure
  increases that have accumulated so far and setting balancing valves that we encounter
  to those imbalances.

  - When encountering a node with multiple flow-ins, take the highest pressure-loss
    flow in and propagate upwards the rest of the edges keeping track of the imbalance
    against that highest flow edge. If there are any pending imbalances, add them to
    all the required imbalances as we propagate up.
  - When encountering a balancing valve, set the pressure loss to the current accumulated
    imbalance and reset the accumulated imbalance to 0.
  - When encountering a node with multiple flow-outs, we will not allow a non-zero imbalance.
    Theoretically, if other downstream nodes all have the same imbalance, we could allow
    a non-zero imbalance. However, this is rare enough to not be worth the trouble.
  */

    biconnectedGraph.dfs(
      flowIn,
      undefined,
      undefined,
      (edge) => {
        if (edge.value.type === EdgeType.FITTING_FLOW) {
          return;
        }
        if (
          edgeFlowSource.has(edge.uid) &&
          edgeFlowSource.get(edge.uid) !== biconnectedGraph.sn(edge.to)
        ) {
          // going the wrong way. Returning the wrong way constant removes this edge from seen list so
          // it will be revisited the other way next time.
          return VISIT_RESULT_WRONG_WAY;
        }

        const downstreamEntityUid = edge.from.connectable;
        const edgeEntityUid = edge.value.uid;
        const upstreamEntityUid = edge.to.connectable;

        GlobalFDR.focusData([edgeEntityUid, upstreamEntityUid]);

        const existingImbalance = entityImbalance.get(downstreamEntityUid) || 0;

        const pressuresTo = entityPressureToKPA.get(upstreamEntityUid);
        const pressuresFrom = entityPressureFromKPA.get(downstreamEntityUid);
        if (!pressuresTo) {
          throw new SentryEntityError(
            "No pressure records for upstream entity",
            upstreamEntityUid,
            {},
            {
              edgeEntityUid,
            },
          );
        }
        if (!pressuresFrom) {
          throw new SentryEntityError(
            "No pressure records for downstream entity",
            downstreamEntityUid,
            {},
            {
              edgeEntityUid,
            },
          );
        }

        if (pressuresTo.size > 1) {
          if (existingImbalance !== 0) {
            Logger.error(
              new SentryEntityError(
                "Non-zero imbalance at node with multiple flow-outs",
                edge.value.uid,
              ),
            );

            // throw new Error("Multiple flow-outs with non-zero imbalance");

            // Instead of crashing, we should warn the user that there is a balancing valve missing.
            // Fortunately, this is easy to do. The candidate pipes are a downstream path with no branches.
            // This is because we don't allow imbalances to merge when propagating upstream, as is
            // handled here. Splitting imbalances upstream is allowed, but the missing upstream paths
            // if there are multiple, will cause multiple instances of this error to be thrown to handle
            // each one.

            const entitiesToWarn: ConduitEntity[] = [];
            let warnSystemUid: string | undefined;

            const processEdge = (e: Edge<FlowNode, FlowEdge>) => {
              if (e.value.type === EdgeType.CONDUIT) {
                const pipe = context.globalStore.getObjectOfTypeOrThrow(
                  EntityType.CONDUIT,
                  e.value.uid,
                ).entity;

                const drawable = context.globalStore.get(
                  e.value.uid.split(".")[0],
                );
                if (drawable && drawable.type === EntityType.CONDUIT) {
                  entitiesToWarn.push(pipe);
                  warnSystemUid = pipe.systemUid;
                }
              }
            };

            processEdge(edge);

            biconnectedGraph.dfs(
              edge.from,
              (n) => {
                if (entityPressureToKPA.get(n.connectable)?.size !== 1) {
                  return true;
                }
                if (entityPressureFromKPA.get(n.connectable)?.size !== 1) {
                  return true;
                }
              },
              undefined,
              (e) => {
                if (
                  edgeFlowSource.has(e.uid) &&
                  edgeFlowSource.get(e.uid) !== biconnectedGraph.sn(e.from)
                ) {
                  return VISIT_RESULT_WRONG_WAY;
                }
                processEdge(e);
              },
            );

            if (entitiesToWarn.length === 0) {
              Logger.error(
                new SentryEntityError(
                  "No entities to warn about missing balancing valve",
                  edge.uid,
                ),
              );
            } else {
              const anyPipe = entitiesToWarn
                .map((e) => context.globalStore.get(e.uid))
                .filter(
                  (o) =>
                    context.globalStore.get(o.uid.split(".")[0])?.type ===
                    EntityType.CONDUIT,
                )[0] as CoreConduit;
              const allPipeUids = new Set(entitiesToWarn.map((e) => e.uid));

              let result: FlowNode | null = null as FlowNode | null;
              context.flowGraph.dfs(
                {
                  connectable: anyPipe.entity.endpointUid[0],
                  connection: anyPipe.uid,
                },
                undefined,
                undefined,
                (e) => {
                  if (e.value.type === EdgeType.CONDUIT) {
                    const pipe = context.globalStore.getObjectOfTypeOrThrow(
                      EntityType.CONDUIT,
                      e.value.uid,
                    );
                    const pipeCalc = context.globalStore.getOrCreateCalculation(
                      pipe.entity,
                    );
                    if (pipeCalc.flowFrom !== e.from.connectable) {
                      return VISIT_RESULT_WRONG_WAY;
                    }

                    const drawable = context.globalStore.get(
                      e.value.uid.split(".")[0],
                    );
                    if (drawable.type !== EntityType.CONDUIT) {
                      // could be a riser, vertical pipe, etc which we can't put a
                      // balancing valve on
                      return true;
                    }

                    if (!allPipeUids.has(pipe.uid)) {
                      return true;
                    }

                    result = e.to;
                  }
                },
              );

              if (result) {
                const isHeatingOrCooling = entitiesToWarn.every((entity) =>
                  isMechanical(
                    getFlowSystem(context.drawing, entity.systemUid),
                  ),
                );
                addWarning(
                  context,
                  isHeatingOrCooling
                    ? "MISSING_LOCKSHIELD_VALVE_FOR_RETURN"
                    : "MISSING_BALANCING_VALVE_FOR_RETURN",
                  entitiesToWarn,
                  {
                    mode: getFlowSystemLayouts(
                      context.drawing.metadata.flowSystems[warnSystemUid!],
                    ).layouts,
                    params: {
                      pipeUid: result.connection,
                      connectableUid: result.connectable,
                    },
                  },
                );
              }
            }
          }
        } else if (pressuresTo.size !== 1) {
          throw new Error(
            "this shouldn't be possible - no downstream edge to us.",
          );
        }

        if (
          edge.value.type === EdgeType.BALANCING_THROUGH ||
          edge.value.type === EdgeType.PLANT_THROUGH
        ) {
          const pontentialBalancingEntity = context.globalStore.get(
            edgeEntityUid,
          ).entity as CalculatableEntityConcrete;
          if (isBalancingEntity(pontentialBalancingEntity)) {
            if (pontentialBalancingEntity.type === EntityType.DIRECTED_VALVE) {
              // sanity checks
              if (downstreamEntityUid !== upstreamEntityUid) {
                throw new Error(
                  "Balancing valve edge should have same downstream and upstream entity",
                );
              }

              if (pressuresTo.size != 1) {
                throw new Error(
                  "Balancing valve with multiple flow-outs " +
                    upstreamEntityUid +
                    " " +
                    pressuresTo.size,
                );
              }
              const loadsIn = entityPressureFromKPA.get(downstreamEntityUid);
              if (!loadsIn || loadsIn.size !== 1) {
                throw new Error(
                  "Balancing valve with missing or multiple flow-ins",
                );
              }
            }
            // pressureDropDifferentialKPA is the missing pressure in this leg ASSUMING that the balancing valves were
            // ALREADY at min_ba... so that's why we add MINIMUM_... here.
            setBalancingPressureDropKPA(
              context,
              pontentialBalancingEntity,
              existingImbalance + MINIMUM_BALANCING_VALVE_PRESSURE_DROP_KPA,
            );
          }

          balancingEntityPressureDropsKPA.set(edgeEntityUid, existingImbalance);
          entityImbalance.set(upstreamEntityUid, 0);
        } else {
          let minPressureDownstream = Infinity;

          for (const [upstreamEntityUid, pressure] of pressuresFrom.entries()) {
            minPressureDownstream = Math.min(minPressureDownstream, pressure!);
          }

          if (upstreamEntityUid !== downstreamEntityUid) {
            const thisDestPressure = pressuresFrom.get(edge.value.uid);
            if (thisDestPressure === undefined || thisDestPressure === null) {
              throw new SentryError("no pressure record for edge", {
                upstreamEntityUid,
                downstreamEntityUid,
                pressuresFrom: [...pressuresFrom.entries()]
                  .map(([k, v]) => `[${k}: ${v}]`)
                  .join(", "),
              });
            }
            const additionalImbalance =
              thisDestPressure - minPressureDownstream;

            if (additionalImbalance < 0) {
              throw new SentryError("negative additional imbalance");
            }

            entityImbalance.set(
              upstreamEntityUid,
              existingImbalance + additionalImbalance,
            );
          }
        }
      },
      undefined,
      undefined,
      undefined,
      undefined,
      true,
    );

    const pCalc = context.globalStore.getOrCreateCalculation(record.plant);

    if (record.returnUid) {
      const pressuresFrom = entityPressureFromKPA.get(record.returnUid)!;
      let minPressureKPA = Infinity;
      for (const [entityUid, pressure] of pressuresFrom.entries()) {
        minPressureKPA = Math.min(minPressureKPA, pressure!);
      }
      const pCalc = context.globalStore.getOrCreateCalculation(record.plant);
      const circulationPressureLossKPA = -minPressureKPA;
      const checkAdjustment =
        ReturnCalculations.adjustPlantPressureDropByManufacturer({
          plant: record.plant,
          pCalc,
          catalog: context.catalog,
          drawing: context.drawing,
          pressureDropKPA: circulationPressureLossKPA,
        });
      const internalPressureDropKPA =
        record.plant.plant.outlets.find(
          (o) => o.outletUid === record.outletUid,
        )!.pressureDropKPA ?? 0;

      pCalc.circulationPressureLoss.push(
        circulationPressureLossKPA +
          checkAdjustment.totalKPA +
          internalPressureDropKPA,
      );
      pCalc.circulatingPumpModel.push(checkAdjustment.manufacturer);

      const selectedOutlet = record.plant.plant.outlets.find(
        (outlet) => outlet.outletUid === record.outletUid,
      )!;
      const recircCalc = getRecirculationPumpNodeCalc(context, selectedOutlet);
      const outletCalc = getReturnOutletNodeCalc(context, selectedOutlet);
      if (!recircCalc || !outletCalc) {
        throw new Error(" calc not found");
      }
      recircCalc.circulationPressureLossKPA =
        circulationPressureLossKPA +
        checkAdjustment.totalKPA +
        internalPressureDropKPA;
      recircCalc.circulatingPumpModel = checkAdjustment.manufacturer;
      // If the outlet is a flow source, inform it as to how much pressure drop is needed to
      // make it return at 0. pressureKPA is kinda used as a hack to pass that info on.
      outletCalc.pressureKPA = circulationPressureLossKPA;
    }

    const timeEnd = performance.now();
    console.log(
      "calculatebalancingEntityPressureDropsKPA took",
      timeEnd - timeStart,
      "ms",
    );

    // Upgrade record
    return {
      ...record,
      pressureFromKPA: entityPressureFromKPA,
      pressureToKPA: entityPressureToKPA,
      maxPressuresKPA: entityMaxPressuresKPA,
      isEdgePressureLossValid: isEdgePressureLossValid,
      edge2PressureLoss: edge2PressureLoss,
    };
  }

  @TraceCalculation("Detect misplaced heat emitters (Generic)", (e, r) => [
    r.plant.uid,
  ])
  static detectMisplacedHeatEmittersGeneric(
    engine: CalculationEngine,
    returnRecord: GenericReturnRecord,
  ) {
    // At the moment, calcs crashes if this happens so bit of a moot point
    for (const edge of returnRecord.biconnectedComponent[1]) {
      if (
        edge.value.type === EdgeType.PLANT_THROUGH ||
        edge.value.type === EdgeType.PLANT_PREHEAT
      ) {
        maybeAddWarningForMisplacedHeatEmitters(engine, edge.value.uid, [
          edge.from.connectable,
          edge.to.connectable,
        ]);
      }
    }
  }

  @TraceCalculation("Calculate total volume (Generic)", (e, r) => [r.plant.uid])
  static returnTotalVolumeGeneric(
    engine: CalculationEngine,
    returnRecord: GenericReturnRecord,
  ): {
    totalPipeVolumeL: number;
    totalHeatEmitterVolumeL: number;
    totalPlantVolumeL: number;
  } {
    let totalPipeVolumeL = 0;
    let totalHeatEmitterVolumeL = 0;
    let totalPlantVolumeL = 0;

    for (const edge of returnRecord.biconnectedComponent[1]) {
      GlobalFDR.focusData([edge.value.uid]);
      switch (edge.value.type) {
        case EdgeType.CONDUIT:
          const pipeCalc = engine.globalStore.calculationStore.get(
            edge.value.uid,
          ) as PipeCalculation;

          totalPipeVolumeL += calculatePipeVolumeL(pipeCalc);
          break;
        case EdgeType.PLANT_THROUGH:
          const plant = engine.globalStore.getObjectOfType(
            EntityType.PLANT,
            edge.value.uid,
          );
          if (plant) {
            const p = plant.entity.plant;
            switch (p.type) {
              case PlantType.RADIATOR:
              case PlantType.MANIFOLD:
              case PlantType.UFH:
                const calc = engine.globalStore.getOrCreateCalculation(
                  plant.entity,
                );
                const { volumeL, internalVolumeL } =
                  ReturnCalculations.calculateRadiatorEffectiveVolume(
                    engine,
                    plant.entity,
                  );
                calc.volumeL = volumeL ?? 0;
                calc.internalVolumeL = internalVolumeL;

                totalHeatEmitterVolumeL += volumeL ?? 0;
                break;
              case PlantType.FCU:
              case PlantType.AHU_VENT:
                const ns = engine.globalStore.getObjectOfTypeOrThrow(
                  EntityType.SYSTEM_NODE,
                  edge.from.connectable,
                );

                const isHeating = isHeatingPlantSystem(
                  engine.drawing.metadata.flowSystems[ns.entity.systemUid],
                );

                const capRating = isHeating
                  ? p.heatingCapacityRateLKW
                  : p.chilledCapacityRateLKW;

                totalHeatEmitterVolumeL +=
                  // ratingKW is positive, even for cooling so use this without Math.abs
                  capRating *
                  (ReturnCalculations.rating2KW(
                    engine,
                    p,
                    returnRecord.plant,
                    returnRecord.outletUid,
                    isHeating ? "heating" : "chilled",
                  ) || 0);
                break;
              case PlantType.VOLUMISER:
                const filled = fillPlantDefaults(engine, plant.entity);
                totalPlantVolumeL += (filled.plant as VolumiserPlant).volumeL!;
              case PlantType.CUSTOM:
              case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
              case PlantType.DRAINAGE_PIT:
              case PlantType.PUMP:
              case PlantType.PUMP_TANK:
              case PlantType.RETURN_SYSTEM:
              case PlantType.AHU:
              case PlantType.TANK:
              case PlantType.FILTER:
              case PlantType.RO:
              case PlantType.DUCT_MANIFOLD:
                break;
              default:
                assertUnreachable(p);
            }
          }
        case EdgeType.PLANT_PREHEAT:
          const preHeatPlant = engine.globalStore.getObjectOfType(
            EntityType.PLANT,
            edge.value.uid,
          );
          if (
            preHeatPlant &&
            preHeatPlant.entity.plant.type === PlantType.RETURN_SYSTEM
          ) {
            for (const preheat of preHeatPlant.entity.plant.preheats) {
              if (
                preheat.inletUid === edge.from.connectable ||
                preheat.returnUid === edge.from.connectable
              ) {
                totalPlantVolumeL += preheat.volumeL ?? 0;
              }
            }
          }
          break;
        case EdgeType.BIG_VALVE_HOT_HOT:
        case EdgeType.BIG_VALVE_HOT_WARM:
        case EdgeType.BIG_VALVE_COLD_WARM:
        case EdgeType.BIG_VALVE_COLD_COLD:
        case EdgeType.FITTING_FLOW:
        case EdgeType.FLOW_SOURCE_EDGE:
        case EdgeType.CHECK_THROUGH:
        case EdgeType.ISOLATION_THROUGH:
        case EdgeType.RETURN_PUMP:
        case EdgeType.BALANCING_THROUGH:
          break;
        default:
          assertUnreachable(edge.value.type);
      }
    }

    return { totalPipeVolumeL, totalHeatEmitterVolumeL, totalPlantVolumeL };
  }

  @TraceCalculation("Get downstream returns (Generic)", (e, r) => [r.plant.uid])
  static getDownstreamReturnsGeneric(
    engine: CalculationEngine,
    returnRecord: GenericReturnRecord,
  ) {
    const downstreamReturns: string[] = [];

    for (const edge of returnRecord.biconnectedComponent[1]) {
      GlobalFDR.focusData([edge.value.uid]);
      if (edge.value.type === EdgeType.PLANT_PREHEAT) {
        const plant = engine.globalStore.getObjectOfType(
          EntityType.PLANT,
          edge.value.uid,
        );
        if (plant && plant.entity.plant.type === PlantType.RETURN_SYSTEM) {
          downstreamReturns.push(edge.value.uid);
        }
      }
    }

    return downstreamReturns;
  }

  @TraceCalculation("Calculate index circuit (Generic)", (e, r) => [
    r.plant.uid,
  ])
  static returnIndexCircultGeneric(
    engine: CalculationEngine,
    returnRecord: GenericReturnRecordPressureLoss,
  ): IndexCircuitPath {
    // Step 1: Using DFS to find indegree for each nodes.
    // Step 2: Topologically travserse graph and track longest path.
    const dagGraph = returnRecord.graph;
    if (dagGraph == null) {
      throw new Error("graph must be calculated before index circuit");
    }

    const flowIn: FlowNode = {
      connectable: returnRecord.outletUid,
      connection: returnRecord.plant.uid,
    };
    const edgeFlowSource = returnRecord.edgeFlowSource;
    if (edgeFlowSource == null) {
      throw new Error("flow source must be calcualted before index circuit");
    }
    const edge2PressureLoss = returnRecord.edge2PressureLoss;
    if (edge2PressureLoss === null) {
      throw new Error("pressure must be calculated before index circuit");
    }

    // A simple topological sort algorithm. Push into queue if there is no more indegree edge.
    const indegree: Map<string, number> = new Map();
    const queue: string[] = [];
    const incrementCount = (uid: string) => {
      if (!indegree.has(uid)) {
        indegree.set(uid, 0);
      }
      indegree.set(uid, indegree.get(uid)! + 1);
    };
    const decrementCount = (uid: string) => {
      if (!indegree.has(uid)) {
        return;
      }
      indegree.set(uid, indegree.get(uid)! - 1);
    };

    const adjacenyList: {
      [key: string]: {
        childNode: string;
        edge: Edge<FlowNode, FlowEdge>;
      }[];
    } = {};

    const visitEdgeFunc = (
      edge: Edge<FlowNode, FlowEdge>,
    ): boolean | VISIT_RESULT_WRONG_WAY | undefined => {
      // VISIT the edge in wrong way
      if (
        edgeFlowSource.has(edge.uid) &&
        edgeFlowSource.get(edge.uid) === dagGraph?.sn(edge.to)
      ) {
        return VISIT_RESULT_WRONG_WAY;
      }

      // Fitting flow form edge connect to itself,
      // - connection (the node) is the same
      // Avoid overcount, skip
      if (edge.value.type === EdgeType.FITTING_FLOW) {
        return false;
      }

      // Increment indegree for toNode
      // Add toNode as fromNode's adjency list
      let fromNode = edge.from.connectable;
      let toNode = edge.to.connectable;
      if (fromNode === toNode) {
        return false;
      }

      incrementCount(toNode);
      if (adjacenyList[fromNode] === undefined) {
        adjacenyList[fromNode] = [];
      }
      adjacenyList[fromNode].push({
        childNode: toNode,
        edge,
      });
      return false;
    };
    dagGraph?.dfs(
      flowIn,
      undefined,
      undefined,
      visitEdgeFunc,
      undefined,
      undefined,
      undefined,
      true,
      false,
      false,
    );

    // Use backtracking to locate a path from endpoint to the startpoint
    const flowOut: FlowNode = {
      connectable: returnRecord.returnUid,
      connection: returnRecord.plant.uid,
    };

    const flowInConnectable = flowIn.connectable;
    const flowOutConnectable = flowOut.connectable;

    const longestPathDp: {
      [key: string]: {
        pressureDropKPa: number;
        parent: string | null;
        parentEdge: Edge<FlowNode, FlowEdge> | null;
        lengthM: number;
      };
    } = {};

    // Topo finding longest path
    queue.push(flowInConnectable);
    while (queue.length > 0) {
      const getEdgePressureDropKpa = (
        edge: Edge<FlowNode, FlowEdge>,
      ): number => {
        let edgeUid = edge.uid + "." + dagGraph.sn(edge.from);
        let edgePressureDropKpa =
          edge2PressureLoss.get(edgeUid)?.pressureLossKPA;
        if (edgePressureDropKpa === undefined || edgePressureDropKpa === null) {
          return 0;
        }
        return edgePressureDropKpa;
      };

      const initializeEmptyEdgeDp = (connectableEdge: string) => {
        longestPathDp[connectableEdge] = {
          pressureDropKPa: 0,
          parent: null,
          parentEdge: null,
          lengthM: 0,
        };
      };

      const inspectEdge = queue.pop();
      if (inspectEdge === undefined || inspectEdge === flowOutConnectable) {
        continue;
      }

      if (longestPathDp[inspectEdge] === undefined) {
        initializeEmptyEdgeDp(inspectEdge);
      }
      let pathDpLengthM = longestPathDp[inspectEdge].lengthM;
      let pathDpPressureDropKpa = longestPathDp[inspectEdge].pressureDropKPa;

      for (const { childNode, edge } of adjacenyList[inspectEdge] ?? []) {
        decrementCount(childNode);
        if (indegree.get(childNode) === 0) {
          queue.push(childNode);
        }

        let edgeLengthM = ReturnCalculations.getEdgeLengthM(engine, edge);
        let edgePressureDropKpa = getEdgePressureDropKpa(edge);
        let pressureDropTotal = pathDpPressureDropKpa + edgePressureDropKpa;

        if (longestPathDp[childNode] === undefined) {
          initializeEmptyEdgeDp(childNode);
        }

        // Compare and replace the longest path
        if (longestPathDp[childNode].pressureDropKPa < pressureDropTotal) {
          longestPathDp[childNode] = {
            pressureDropKPa: pressureDropTotal,
            parent: inspectEdge,
            parentEdge: edge,
            lengthM: pathDpLengthM + edgeLengthM,
          };
        }
      }
    }

    let path: (Edge<unknown, FlowEdge> | null)[] = [];
    let currentConnectable: string | null = flowOutConnectable;
    while (
      currentConnectable !== flowInConnectable &&
      currentConnectable !== null
    ) {
      path.push(longestPathDp[currentConnectable].parentEdge);
      currentConnectable = longestPathDp[currentConnectable].parent;
    }
    let { pressureDropKPa, lengthM } = longestPathDp[flowOutConnectable];

    function isNotNull<T>(value: T | null): value is T {
      return value !== null;
    }
    // Filter out null elements
    let pathFilter = path.filter(isNotNull);
    return {
      path: pathFilter,
      pressureDropKPA: pressureDropKPa,
      lengthM,
    };
  }
}
