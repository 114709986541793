import { GetterTree } from "vuex";
import { StandardFlowSystemUids } from "../../../../common/src/api/config";
import { buildPriceTable } from "../../../../common/src/api/context";
import { Level } from "../../../../common/src/api/document/drawing";
import { OPERATION_NAMES } from "../../../../common/src/api/document/operation-transforms";
import { assertUnreachable } from "../../../../common/src/lib/utils";
import { Operation } from "../../../../common/src/models/Operation";
import { RootState } from "../types";
import { DocumentState, initialDocumentState } from "./types";

export function sortedLevels(state: DocumentState): Level[] {
  const levels = Object.values(state.drawing.levels) as Level[];
  return levels.sort((a, b) => -(a.floorHeightM - b.floorHeightM));
}

export const getters: GetterTree<DocumentState, RootState> = {
  replayState(state) {
    return state.uiState.replayState;
  },
  isReplaying(state) {
    return state.uiState.replayState.isReplaying;
  },
  replayRawEvents(state) {
    const replayState = state.uiState.replayState;
    if (!replayState.isReplaying) return null;
    return replayState.rawEvents;
  },
  replayDocumentId(state) {
    const replayState = state.uiState.replayState;
    if (!replayState.isReplaying) return null;
    return replayState.documentId;
  },
  replayOrderIndex(state) {
    const replayState = state.uiState.replayState;
    if (!replayState.isReplaying) return null;
    return replayState.orderIndex;
  },
  replayEventsToLog(state) {
    const replayState = state.uiState.replayState;
    if (!replayState.isReplaying) return null;
    return replayState.eventsToLog;
  },
  replayVirtualTime(state) {
    const replayState = state.uiState.replayState;
    if (!replayState.isReplaying) return null;
    return replayState.virtualTime;
  },
  viewPort(state) {
    return state.uiState.viewPort;
  },
  title(state): string {
    return state.drawing.metadata.generalInfo.title;
  },
  isPreview(state): boolean {
    return state.isPreview || false;
  },
  activeFlowSystem(state): StandardFlowSystemUids | string {
    return state.activeflowSystemUid;
  },
  document(state): DocumentState {
    return state;
  },
  documentId(state): number {
    return state.documentId;
  },
  currentLevel(state): Level | null {
    if (!state.uiState.levelUid) {
      return null;
    }
    return state.drawing.levels[state.uiState.levelUid];
  },

  loaded(state): boolean {
    return (
      state.uiState.operationsLoaded && state.uiState.stickyCalculationsLoaded
    );
  },

  isBrandNew(state): boolean {
    return (
      state.history.length === 0 &&
      state.optimisticHistory.length === 0 &&
      state.nextId === initialDocumentState.nextId
    );
  },

  sortedLevels(state): Level[] {
    return sortedLevels(state);
  },

  uncommittedEntityUids(state): string[] {
    const res: string[] = [];

    if (state.diffFilter.shared) {
      res.push(...Object.keys(state.diffFilter.shared));
    }
    if (state.diffFilter.levels) {
      Object.keys(state.diffFilter.levels).forEach((lvlUid) => {
        if (
          state.diffFilter.levels[lvlUid] &&
          state.diffFilter.levels[lvlUid].entities
        ) {
          res.push(...Object.keys(state.diffFilter.levels[lvlUid].entities));
        }
      });
    }
    return res;
  },

  isSyncing(state): boolean {
    return state.optimisticHistory.length > 0;
  },

  discreteHistory(state): Operation[][] {
    const result: Operation[][] = [];
    let build: Operation[] = [];
    for (const op of state.fullHistory) {
      switch (op.operation.type) {
        case OPERATION_NAMES.DIFF_OPERATION:
          build.push(op);
          break;
        case OPERATION_NAMES.COMMITTED_OPERATION:
          if (build.length) {
            result.push(build);
            build = [];
          }
          break;
        default:
          assertUnreachable(op.operation);
      }
    }
    return result;
  },

  calculationsSuccess(state): boolean {
    return state.uiState.lastCalculationSuccess;
  },

  calculationsUpToDate(state): boolean {
    if (state.uiState.lastCalculationId < state.nextId) {
      return false;
    }
    return true;
  },

  priceTable(state): boolean {
    return buildPriceTable(state.drawing.metadata.priceTable);
  },

  isViewOnly(state, getters, rootState, rootGetters) {
    return (
      state.uiState.isCalculating ||
      state.uiState.hasErrorConnecting ||
      state.uiState.isSuperUser ||
      state.uiState.isGlobalSample ||
      (state.uiState.isShareLink && !state.uiState.isSandbox) ||
      (!rootGetters["profile/hasDesignSeat"] && !state.uiState.isSandbox)
    );
  },

  shouldSyncWithServer(state, getters): boolean {
    return !getters.isViewOnly && !state.uiState.isSandbox;
  },

  showDrawingNavBar(state) {
    return !state.uiState.isEmbedded;
  },

  locale(state) {
    return state.locale;
  },

  liveCalculationRenderCounter(state) {
    return state.liveCalculationRenderCounter;
  },

  metadata(state) {
    return state.drawing.metadata;
  },

  units(state) {
    return state.drawing.metadata.units;
  },

  workflows(state) {
    return state.drawing.metadata.workflows;
  },
};
