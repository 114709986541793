import {
  determineConnectableSystemUid,
  isDirectedValveFlowSystemValid,
} from "../../../../common/src/api/coreObjects/utils";
import {
  DrawableEntityConcrete,
  isConnectableEntity,
} from "../../../../common/src/api/document/entities/concrete-entity";
import DirectedValveEntity, {
  createBareValveEntity,
} from "../../../../common/src/api/document/entities/directed-valves/directed-valve-entity";
import {
  InsertDirectedValveSpec,
  InsertValveSpec,
  ValveType,
} from "../../../../common/src/api/document/entities/directed-valves/valve-types";
import { MultiwayValveEntity } from "../../../../common/src/api/document/entities/multiway-valves/multiway-valve-entity";
import { EntityType } from "../../../../common/src/api/document/entities/types";
import { getFlowSystem } from "../../../../common/src/api/document/utils";
import { cloneSimple } from "../../../../common/src/lib/utils";
import { addValveAndSplitPipe } from "../../../src/htmlcanvas/lib/black-magic/split-pipe";
import CanvasContext from "../../../src/htmlcanvas/lib/canvas-context";
import { InteractionType } from "../../../src/htmlcanvas/lib/interaction";
import { KeyCode } from "../../../src/htmlcanvas/utils";
import { MainEventBus } from "../../../src/store/main-event-bus";
import { addEntityToStore } from "../lib/utils";
import { ConnectableObjectConcrete } from "../objects/concrete-object";
import DrawableConduit from "../objects/drawableConduit";
import DrawableMultiwayValve from "../objects/drawableMultiwayValve";
import SnappingTool, {
  CONNECTABLE_SNAP_RADIUS_PX,
} from "./snapping-insert-tool";

function isInsertDirectedValveSpec(
  spec: InsertValveSpec,
): spec is InsertDirectedValveSpec {
  return (spec as InsertDirectedValveSpec).catalogId !== undefined;
}

export default function insertDirectedValve(
  context: CanvasContext,
  spec: InsertValveSpec,
) {
  let pipe: DrawableConduit | null = null;
  let flipped = true;

  const maximumConnections =
    spec.entityType === EntityType.MULTIWAY_VALVE
      ? DrawableMultiwayValve.getMaximumConnections(spec.valveType)
      : 2;

  let lastValveEntityCreated: DirectedValveEntity | MultiwayValveEntity | null =
    null;
  MainEventBus.$emit(
    "set-tool-handler",
    new SnappingTool({
      name: "Insert Directed Valve",
      context,
      getTitleCallback: () => {
        if (isInsertDirectedValveSpec(spec)) {
          switch (spec.catalogId) {
            case "csv":
              return "Insert Commissioning Set Valve";
            case "balancing":
              return "Insert Balancing Valves";
            case "lsv":
              return "Insert Locksheild Valves";
            case "picv":
              return "Insert Pressure Independent Control Valve";
            case "customValve":
              return "Insert Custom Valve";
            case "RPZD":
              switch (spec.valveType) {
                case ValveType.RPZD_SINGLE:
                  return "Insert RPZD";
                case ValveType.RPZD_DOUBLE_SHARED:
                  return "Insert Double RPZD - 50/50 Load Each";
                case ValveType.RPZD_DOUBLE_ISOLATED:
                  return "Insert Double RPZD - 100% Load Each";
              }
            case "rv":
              return "Insert Pressure Relief Valve";
            case "smokeDamper":
              return "Insert Smoke Damper";
            case "fireDamper":
              return "Insert Fire Damper";
            case "volumeControlDamper":
              return "Insert Volume Control Damper";
            case "attenuator":
              return "Insert Attenuator";
            case "prv":
              switch (spec.valveType) {
                case ValveType.PRV_SINGLE:
                  return "Insert Pressure Reducing Valve";
                case ValveType.PRV_DOUBLE:
                  return "Insert PRV Dual = 50% Load Each";
                case ValveType.PRV_TRIPLE:
                  return "Insert PRV Trio = 33% Load Each";
              }
            case "gateValve":
              switch (spec.valveType) {
                case ValveType.ISOLATION_VALVE:
                  return "Insert Isolation Valve";
              }
            case "fan":
              return "Insert Fan";

            case "":
              switch (spec.valveType) {
                case ValveType.GAS_REGULATOR:
                  return "Insert Gas Regulator";
                case ValveType.FILTER:
                  return "Insert Filter";
              }
          }
          return context.catalog.valves[spec.catalogId].name;
        } else return "Insert Multi Valves";
      },
      onFinish: (interrupted, displaced) => {
        MainEventBus.$emit("set-tool-handler", null);
        if (interrupted) {
          context.$store.dispatch("document/revert");
        } else {
          if (
            lastValveEntityCreated !== null &&
            lastValveEntityCreated.valve.type === ValveType.PRV_SINGLE
          ) {
            MainEventBus.$emit("select", {
              uid: lastValveEntityCreated.uid,
              variant: "warning",
              title: "Target Pressure Required",
              message: "Please enter the target pressure for this valve.",
              property: "valve.targetPressureKPA",
              recenter: false,
            });
            return; // Only allow inserting one PRVs at a time
          }

          if (!displaced) {
            insertDirectedValve(context, spec);
          }
        }
      },
      getSnapIntentions: () => {
        return [
          {
            accept: "point",
            systemUid: spec.systemUid || null,
            offset: { x: 0, y: 0 },
          },
        ];
      },
      onMove: async (wc, _event) => {
        const interactionA = context.offerInteraction(
          {
            type: InteractionType.INSERT,
            entityType: spec.entityType,
            systemUid: null,
            worldCoord: wc,
            worldRadius: context.viewPort.toWorldLength(
              CONNECTABLE_SNAP_RADIUS_PX,
            ),
          },
          (drawable) => {
            return (
              isConnectableEntity(drawable[0]) &&
              context.globalStore.getConnections(drawable[0].uid).length <=
                maximumConnections
            );
          },
        );

        const interactionB = context.offerInteraction(
          {
            type: InteractionType.INSERT,
            entityType: spec.entityType,
            systemUid: null,
            worldCoord: wc,
            worldRadius: 30,
          },
          (drawable) => {
            return (
              drawable[0].type === EntityType.CONDUIT ||
              (isConnectableEntity(drawable[0]) &&
                context.globalStore.getConnections(drawable[0].uid).length <=
                  maximumConnections)
            );
          },
        );

        const interaction = interactionA || interactionB;

        const valveEntity = addEntityToStore(
          context,
          createBareValveEntity(
            spec,
            cloneSimple(wc),
            context.drawing.metadata.units,
          ),
        );
        lastValveEntityCreated = valveEntity;

        if (!canConnectValve(context, interaction ?? [], valveEntity)) {
          context.scheduleDraw();
          return;
        }

        if (interaction && interaction.length) {
          if (interaction[0].type === EntityType.CONDUIT) {
            const pipeE = interaction[0];
            pipe = context.globalStore.getObjectOfTypeOrThrow(
              EntityType.CONDUIT,
              pipeE.uid,
            );
            // Project onto pipe
            addValveAndSplitPipe(context, pipe, wc, undefined, 10, valveEntity);

            if (flipped) {
              if (spec.entityType === EntityType.DIRECTED_VALVE) {
                context.globalStore
                  .getObjectOfTypeOrThrow(
                    EntityType.DIRECTED_VALVE,
                    valveEntity.uid,
                  )
                  .flip();
              }
            }
          } else {
            const object = context.globalStore.get<ConnectableObjectConcrete>(
              interaction[0].uid,
            );
            const entity = object.entity;

            const connections = cloneSimple(
              context.globalStore.getConnections(entity.uid),
            );

            connections.forEach((e) => {
              const other = context.globalStore.get(e);
              if (other instanceof DrawableConduit) {
                if (other.entity.endpointUid[0] === entity.uid) {
                  context.$store.dispatch("document/updatePipeEndpoints", {
                    entity: other.entity,
                    endpoints: [valveEntity.uid, other.entity.endpointUid[1]],
                  });
                } else {
                  context.$store.dispatch("document/updatePipeEndpoints", {
                    entity: other.entity,
                    endpoints: [other.entity.endpointUid[0], valveEntity.uid],
                  });
                }
              } else {
                throw new Error("Connection is not a conduit");
              }
            });

            const toReplace = object;
            valveEntity.center = toReplace.toWorldCoord();
            context.deleteEntity(toReplace);

            if (flipped) {
              if (spec.entityType === EntityType.DIRECTED_VALVE) {
                context.globalStore
                  .getObjectOfTypeOrThrow(
                    EntityType.DIRECTED_VALVE,
                    valveEntity.uid,
                  )
                  .flip();
              }
            }
          }
        }

        context.scheduleDraw();
      },
      onPointChosen: (_worldCoord, _event) => {
        context.interactive = null;
        context.$store.dispatch("document/validateAndCommit").then(() => {
          /**/
        });
      },
      clickActionName: "Insert",
      keyHandlers: [
        [
          [[KeyCode.F]],
          {
            name: "Flip",
            fn: (e, r) => {
              flipped = !flipped;
              r();
            },
          },
        ],
      ],
    }),
  );
}

export function canConnectValve(
  context: CanvasContext,
  entities: DrawableEntityConcrete[],
  target: DrawableEntityConcrete,
) {
  if (target.type !== EntityType.DIRECTED_VALVE) {
    return true;
  }

  for (const ent of entities) {
    if (isConnectableEntity(ent)) {
      const systemUid = determineConnectableSystemUid(context.globalStore, ent);
      if (systemUid) {
        const fs = getFlowSystem(context.drawing, systemUid);
        if (!fs) {
          return false;
        }
        if (!isDirectedValveFlowSystemValid(target, fs)) {
          return false;
        }
      }
    }

    if (ent.type === EntityType.CONDUIT) {
      const fs = getFlowSystem(context.drawing, ent.systemUid);
      if (!fs) {
        return false;
      }
      if (!isDirectedValveFlowSystemValid(target, fs)) {
        return false;
      }
    }
  }

  return true;
}
