import stringify from "json-stable-stringify";
import CoreBaseBackedObject from "./coreBaseBackedObject";

export default function Cached<T extends CoreBaseBackedObject>(
  getDependencies: (kek: T, value: any) => Set<string>,
  serializeArgs?: (...args: any[]) => string,
) {
  return (target: T, propertyKey: string, descriptor: PropertyDescriptor) => {
    if (!descriptor?.value) {
      // Probably playwright, skip it.
      return descriptor;
    }

    const originalMethod = descriptor.value;
    descriptor.value = function (this: T) {
      const cacheKey =
        propertyKey +
        (serializeArgs
          ? serializeArgs(...arguments)
          : stringify(Array.from(arguments)));
      if (!this.cache.has(cacheKey)) {
        const value = originalMethod.apply(this, arguments);
        const deps = getDependencies(this, value);
        this.globalStore.watchDependencies(this.uid, cacheKey, deps);
        this.cache.set(cacheKey, value);
      }
      return this.cache.get(cacheKey);
    };
    return descriptor;
  };
}
