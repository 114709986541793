<script setup lang="ts">
import { computed, getCurrentInstance, onMounted, ref } from "vue";
import Multiselect from "vue-multiselect";
import { Organization } from "../../../../common/src/models/Organization";
import { Subscription } from "../../../../common/src/models/Subscription";
import { getOrganizations } from "../../api/organizations";
import {
  associateSubscription,
  deleteSubscription,
  getAllSubscriptions,
} from "../../api/subscription";

const instance = getCurrentInstance();
const orgs = ref<Organization[]>([]);
const subs = ref<Map<string, Subscription>>(new Map());
const orgToSub = ref<Map<string, string>>(new Map());
const currentPage = ref<number>(1);
const search = ref<string>("");
const renderIdx = ref<number>(0);

const fields = ref<string[]>(["name", "id", "state", "subscription"]);
const options = [
  "id",
  "name",
  "state",
  "subscription",
  "type",
  "featureAccess",
  "subscriptionStatus",
  "enforceMFA",
];

const tableFields = computed(() =>
  fields.value.map((option) => {
    return {
      key: option,
      label: option.replace(/([a-z])([A-Z])/g, "$1 $2"),
      sortable: option !== "subscription" && option !== "state",
    };
  }),
);

const filteredOrgs = computed(() =>
  orgs.value.filter((item) => {
    const sub = getSubByOrgId(item.id);
    const query = search.value.toLowerCase();
    const orgMatch = fields.value.some((field) => {
      // @ts-ignore
      return JSON.stringify(item[field])?.toLowerCase().includes(query);
    });

    return (
      orgMatch ||
      sub?.state.toLocaleLowerCase().includes(query) ||
      sub?.maxioProductName.toLocaleLowerCase().includes(query) ||
      sub?.maxioInstanceId.toLocaleLowerCase().includes(query) ||
      sub?.currency.toLocaleLowerCase().includes(query)
    );
  }),
);

const totalRows = computed(() => filteredOrgs.value.length);

const getOrgs = async () => {
  const res = await getOrganizations();
  if (res.success) {
    orgs.value.splice(0, orgs.value.length, ...res.data);
  } else {
    instance?.proxy.$bvToast.toast(res.message, {
      variant: "danger",
      title: "Error retrieving org list",
    });
  }
};

const getSubs = async () => {
  const res = await getAllSubscriptions();
  if (res.success) {
    for (const sub of res.data) {
      subs.value.set(sub.organizationId, sub);
    }
  } else {
    instance?.proxy.$bvToast.toast(res.message, {
      variant: "danger",
      title: "Error retrieving subscriptions",
    });
  }
};

const associateSubWithOrg = () => {
  for (const sub of subs.value.values()) {
    orgToSub.value.set(sub.organizationId, sub.id);
  }
  renderIdx.value++;
};

const getSubByOrgId = (orgId: string): Subscription | null => {
  return subs.value.get(orgId) ?? null;
};

const getMaxioLink = (sub: Subscription | null): string => {
  if (!sub) return "";
  return `https://${sub.maxioInstanceId}.chargify.com/subscriptions/${sub.maxioSubscriptionId}`;
};

const handleEnterDirect = async (e: KeyboardEvent, orgId: string) => {
  if (e.key === "Enter") {
    const input = e.target as HTMLInputElement;
    const subscriptionId = input.value;
    const res = await associateSubscription(Number(subscriptionId), orgId);
    if (!res.success) {
      instance?.proxy.$bvToast.toast(res.message, {
        variant: "danger",
        title: "Error associating subscription",
      });
      return;
    }
    subs.value.set(orgId, res.data);
    orgToSub.value.set(orgId, res.data.id);
    input.value = "";
    renderIdx.value++;
  }
};

const handleDelete = async (orgId?: string) => {
  // confirm with user
  if (orgId) {
    const ok = await instance?.proxy.$bvModal.msgBoxConfirm(
      "Are you sure you want to delete this subscription?",
    );
    if (!ok) return;

    if (!orgId) return;
    const id = orgToSub.value.get(orgId);
    if (!id) return;
    const res = await deleteSubscription(id);
    if (!res.success) {
      instance?.proxy.$bvToast.toast(res.message, {
        variant: "danger",
        title: "Error deleting subscription",
      });
      return;
    }
    orgToSub.value.delete(orgId);
    subs.value.delete(orgId);
    renderIdx.value++;
  }
};

onMounted(async () => {
  await Promise.all([getOrgs(), getSubs()]);
  associateSubWithOrg();
});
</script>

<template>
  <div style="overflow: auto; max-height: calc(100% - 61px)">
    <b-container class="py-5">
      <b-row>
        <b-col cols="5">
          <b-form-group
            label-cols-lg="2"
            label="Columns"
            class="mb-5 right-align"
          >
            <Multiselect
              v-model="fields"
              :options="options"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Pick columns"
              :preselect-first="true"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4"></b-col>
        <b-col cols="3">
          <b-input
            v-model="search"
            type="search"
            placeholder="Search Table..."
            class="table-search"
          />
        </b-col>
      </b-row>

      <b-table
        :key="renderIdx"
        striped
        hover
        per-page="10"
        :current-page="currentPage"
        :items="filteredOrgs"
        :fields="tableFields"
      >
        <template #cell(state)="data">
          {{ getSubByOrgId(data.item.id)?.state }}
        </template>

        <template #cell(subscription)="data">
          <span v-if="getSubByOrgId(data.item.id)">
            {{ getSubByOrgId(data.item.id)?.maxioProductName }} •
            {{ getSubByOrgId(data.item.id)?.maxioInstanceId }} •
            {{ getSubByOrgId(data.item.id)?.currency }}
            <b-button variant="link" @click="handleDelete(data.item.id)"
              >x</b-button
            >
            <br />
            <a
              :href="getMaxioLink(getSubByOrgId(data.item.id))"
              target="_blank"
            >
              Maxio ↗
            </a>
          </span>
          <span v-else>
            <div class="center">
              <b-input
                size="sm"
                style="max-width: 300px"
                placeholder="Enter Maxio Subscription ID and hit Enter to save"
                @keyup.enter="handleEnterDirect($event, data.item.id)"
              ></b-input>
            </div>
          </span>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        per-page="10"
        size="sm"
        class="my-0 center"
        pills
      ></b-pagination>
    </b-container>
  </div>
</template>
