import Flatten from "@flatten-js/core";
import { Coord, coordDist } from "../../../../lib/coord";
import {
  getAreaM2,
  polygonClipping,
  polygonDifference,
} from "../../../../lib/mathUtils/mathutils";
import { assertUnreachable, cloneSimple } from "../../../../lib/utils";
import CoreArchitectureElement from "../../../coreObjects/coreArchitectureElement";
import CoreRoom from "../../../coreObjects/coreRoom";
import {
  ArchitectType,
  VeluxEntity,
  fillDefaultArcFields,
} from "../../../document/entities/architectureElement-entity";
import { RoofType } from "../../../document/entities/rooms/roof-type";
import {
  RoofEntityConcrete,
  RoomEntity,
} from "../../../document/entities/rooms/room-entity";
import { EntityType } from "../../../document/entities/types";
import { CoreContext } from "../../types";
import RoofSegmentation from "./RoofSegmentation";
import { TrapezoidHippingSegment } from "./TrapezoidHippingSegment";
import { TriangleSegment } from "./TriangleSegment";
import {
  CalculateRoofComponentConcrete,
  PolygonSegment,
  PolygonSegmentAfterAdjustment,
  RoofComponentType,
  RoofRectangleAssumption,
} from "./roof-types";
import { adjustHoriPos, createCenteredSegment } from "./utils";

// We ignore the area of the roof that is less than this value
export const FRAGMENTAL_COMP_AREA_M2 = 0.1;

export function breakToSlopeSectionWithRectangle(
  roofEntityConcrete: RoofEntityConcrete,
  rectangle: RoofRectangleAssumption,
): PolygonSegment[] {
  switch (roofEntityConcrete.selectedRoofType) {
    case RoofType.Flat:
      return [
        {
          polygonCw: cloneSimple([
            rectangle.leftTop.coord,
            rectangle.rightTop.coord,
            rectangle.rightBottom.coord,
            rectangle.leftBottom.coord,
          ]),
          slopeDeg: 0,
          slopeDirectionDegCW: 270,
          slopeAreaConversionRatio: 1,
          externalWallAreaM2: 0,
          externalWallConversionRatio: 0,
          volumeM3: 0,
          volumeConversionRatio: 0,
          lowestHeightM: 0,
        },
      ];
      break;
    case RoofType.Shed:
    case RoofType.RaisedShed: {
      let { heightM } = roofEntityConcrete;
      if (roofEntityConcrete.selectedRoofType === RoofType.RaisedShed) {
        heightM =
          roofEntityConcrete.peakHeightM! - roofEntityConcrete.raiseHeightM!;
      }

      return breakShedRoofSection(
        heightM!,
        roofEntityConcrete.raiseHeightM!,
        rectangle,
      );
      break;
    }
    case RoofType.Gable:
    case RoofType.RaisedGable: {
      let { heightM } = roofEntityConcrete;
      if (roofEntityConcrete.selectedRoofType === RoofType.RaisedGable) {
        heightM =
          roofEntityConcrete.peakHeightM! - roofEntityConcrete.raiseHeightM!;
      }

      return breakGableRoofSection(
        heightM!,
        roofEntityConcrete.raiseHeightM!,
        rectangle,
      );
      break;
    }
    case RoofType.Hip:
    case RoofType.RaisedHip: {
      let { heightM, lengthM } = roofEntityConcrete;
      if (roofEntityConcrete.selectedRoofType === RoofType.RaisedHip)
        heightM =
          roofEntityConcrete.peakHeightM! - roofEntityConcrete.raiseHeightM!;
      return breakHipRoofSection(
        heightM!,
        roofEntityConcrete.raiseHeightM!,
        lengthM!,
        rectangle,
      );
      break;
    }
    case RoofType.Hex:
    case RoofType.RaisedHex: {
      const { heightM, widthM } = roofEntityConcrete;

      return breakHexRoofSection(
        roofEntityConcrete.selectedRoofType === RoofType.RaisedHex
          ? (roofEntityConcrete.peakHeightM ?? 0) -
              (roofEntityConcrete.raiseHeightM ?? 0)
          : (heightM ?? 0),
        roofEntityConcrete.raiseHeightM ?? 0,
        widthM ?? 0,
        rectangle,
      );
    }
    case RoofType.Sloped:
    case RoofType.RaisedSloped: {
      let { heightM, widthM } = roofEntityConcrete;
      if (roofEntityConcrete.selectedRoofType === RoofType.RaisedSloped)
        heightM =
          roofEntityConcrete.peakHeightM! - roofEntityConcrete.raiseHeightM!;

      return breakSlopeRoofSection(
        heightM!,
        roofEntityConcrete.raiseHeightM!,
        widthM!,
        rectangle,
      );
      break;
    }
    default:
      assertUnreachable(roofEntityConcrete.selectedRoofType);
      break;
  }
  return [];
}

function breakShedRoofSection(
  heightM: number,
  raisedHeightM: number,
  rectangle: RoofRectangleAssumption,
): PolygonSegment[] {
  const { xDimensionM, yDimensionM } = rectangle;
  const slopeDeg = Math.atan(heightM / xDimensionM) * (180 / Math.PI);
  const externalWallAreaM2 =
    heightM * (yDimensionM + xDimensionM) +
    raisedHeightM * 2 * (yDimensionM + xDimensionM);
  const externalWallConversionRatio =
    externalWallAreaM2 / (yDimensionM * xDimensionM);
  const volumeM3 =
    (yDimensionM * xDimensionM * heightM) / 2 +
    raisedHeightM * yDimensionM * xDimensionM;
  const volumeConversionRatio = volumeM3 / (yDimensionM * xDimensionM);
  const slopeConversionRatio = 1 / Math.cos(slopeDeg * (Math.PI / 180));

  return [
    {
      polygonCw: cloneSimple([
        rectangle.leftTop.coord,
        rectangle.rightTop.coord,
        rectangle.rightBottom.coord,
        rectangle.leftBottom.coord,
      ]),
      slopeDeg: slopeDeg,
      slopeDirectionDegCW: 0,
      slopeAreaConversionRatio: slopeConversionRatio,
      externalWallAreaM2,
      externalWallConversionRatio,
      volumeM3,
      volumeConversionRatio,
      lowestHeightM: raisedHeightM,
    },
  ];
}

function breakGableRoofSection(
  heightM: number,
  raisedHeightM: number,
  rectangle: RoofRectangleAssumption,
): PolygonSegment[] {
  const { xDimensionM, yDimensionM } = rectangle;
  const slopeDeg = Math.atan(heightM! / (xDimensionM / 2)) * (180 / Math.PI);

  const topMiddleCoord = {
    x: (rectangle.leftTop.coord.x + rectangle.rightTop.coord.x) / 2,
    y: (rectangle.leftTop.coord.y + rectangle.rightTop.coord.y) / 2,
  };
  const bottomMiddleCoord = {
    x: (rectangle.leftBottom.coord.x + rectangle.rightBottom.coord.x) / 2,
    y: (rectangle.leftBottom.coord.y + rectangle.rightBottom.coord.y) / 2,
  };
  const externalWallAreaM2 =
    (heightM * xDimensionM + raisedHeightM * 2 * (xDimensionM + yDimensionM)) /
    2;
  const externalWallConversionRatio =
    externalWallAreaM2 / ((yDimensionM * xDimensionM) / 2);
  const volumeM3 =
    (yDimensionM * xDimensionM * heightM) / 4 +
    raisedHeightM * yDimensionM * xDimensionM;
  const volumeConversionRatio = volumeM3 / ((yDimensionM * xDimensionM) / 2);
  const slopeConversionRatio = 1 / Math.cos(slopeDeg * (Math.PI / 180));

  return [
    {
      polygonCw: cloneSimple([
        rectangle.leftTop.coord,
        topMiddleCoord,
        bottomMiddleCoord,
        rectangle.leftBottom.coord,
      ]),
      slopeDeg: slopeDeg,
      slopeDirectionDegCW: 0,
      slopeAreaConversionRatio: slopeConversionRatio,
      externalWallAreaM2: externalWallAreaM2,
      externalWallConversionRatio: externalWallConversionRatio,
      volumeM3,
      volumeConversionRatio: volumeConversionRatio,
      lowestHeightM: raisedHeightM,
    },
    {
      polygonCw: cloneSimple([
        topMiddleCoord,
        rectangle.rightTop.coord,
        rectangle.rightBottom.coord,
        bottomMiddleCoord,
      ]),
      slopeDeg: slopeDeg,
      slopeDirectionDegCW: 180,
      slopeAreaConversionRatio: slopeConversionRatio,
      externalWallAreaM2: externalWallAreaM2,
      externalWallConversionRatio: externalWallConversionRatio,
      volumeM3,
      volumeConversionRatio: volumeConversionRatio,
      lowestHeightM: raisedHeightM,
    },
  ];
}

function breakHipRoofSection(
  heightM: number,
  raisedHeightM: number,
  centerSegmentLengthM: number,
  rectangle: RoofRectangleAssumption,
): PolygonSegment[] {
  // Hack solution, adjust center length to avoid edge case
  if (centerSegmentLengthM == 0) {
    centerSegmentLengthM = 0.005;
  }

  const { xDimensionM, yDimensionM } = rectangle;
  const topMiddleCoord = {
    x: (rectangle.leftTop.coord.x + rectangle.rightTop.coord.x) / 2,
    y: (rectangle.leftTop.coord.y + rectangle.rightTop.coord.y) / 2,
  };
  const bottomMiddleCoord = {
    x: (rectangle.leftBottom.coord.x + rectangle.rightBottom.coord.x) / 2,
    y: (rectangle.leftBottom.coord.y + rectangle.rightBottom.coord.y) / 2,
  };

  const { start: middleSegmentStart, end: middleSegmentEnd } =
    createCenteredSegment(
      topMiddleCoord,
      bottomMiddleCoord,
      centerSegmentLengthM! * 1000,
    );

  const triangle = new TriangleSegment(
    coordDist(rectangle.leftTop.coord, rectangle.rightTop.coord) / 1e3,
    coordDist(topMiddleCoord, middleSegmentStart) / 1e3,
    heightM!,
    raisedHeightM!,
  );
  const trapezoid = new TrapezoidHippingSegment(
    coordDist(rectangle.leftTop.coord, rectangle.leftBottom.coord) / 1e3,
    coordDist(middleSegmentStart, middleSegmentEnd) / 1e3,
    coordDist(rectangle.leftTop.coord, topMiddleCoord) / 1e3,
    heightM!,
    raisedHeightM!,
  );
  return [
    {
      polygonCw: cloneSimple([
        rectangle.leftTop.coord,
        rectangle.rightTop.coord,
        middleSegmentStart,
      ]),
      slopeDeg: triangle.slopeDeg,
      slopeDirectionDegCW: 270,
      slopeAreaConversionRatio:
        1 / Math.cos(triangle.slopeDeg * (Math.PI / 180)),
      externalWallAreaM2: xDimensionM * raisedHeightM,
      externalWallConversionRatio:
        (xDimensionM * raisedHeightM) / triangle.areaM2,
      volumeM3: triangle.volumeM3,
      volumeConversionRatio: triangle.volumeM3 / triangle.areaM2,
      lowestHeightM: raisedHeightM,
    },
    {
      polygonCw: cloneSimple([
        rectangle.leftBottom.coord,
        middleSegmentEnd,
        rectangle.rightBottom.coord,
      ]),
      slopeDeg: triangle.slopeDeg,
      slopeDirectionDegCW: 90,
      slopeAreaConversionRatio:
        1 / Math.cos(triangle.slopeDeg * (Math.PI / 180)),
      externalWallAreaM2: xDimensionM * raisedHeightM,
      externalWallConversionRatio:
        (xDimensionM * raisedHeightM) / triangle.areaM2,
      volumeM3: triangle.volumeM3,
      volumeConversionRatio: triangle.volumeM3 / triangle.areaM2,
      lowestHeightM: raisedHeightM,
    },
    {
      polygonCw: cloneSimple([
        rectangle.leftTop.coord,
        middleSegmentStart,
        middleSegmentEnd,
        rectangle.leftBottom.coord,
      ]),
      slopeDeg: trapezoid.slopeDeg,
      slopeDirectionDegCW: 0,
      slopeAreaConversionRatio:
        1 / Math.cos(trapezoid.slopeDeg * (Math.PI / 180)),
      externalWallAreaM2: yDimensionM * raisedHeightM,
      externalWallConversionRatio:
        (yDimensionM * raisedHeightM) / trapezoid.areaM2,
      volumeM3: trapezoid.volumeM3,
      volumeConversionRatio: trapezoid.volumeM3 / trapezoid.areaM2,
      lowestHeightM: raisedHeightM,
    },
    {
      polygonCw: cloneSimple([
        middleSegmentStart,
        rectangle.rightTop.coord,
        rectangle.rightBottom.coord,
        middleSegmentEnd,
      ]),
      slopeDeg: trapezoid.slopeDeg,
      slopeDirectionDegCW: 180,
      slopeAreaConversionRatio:
        1 / Math.cos(trapezoid.slopeDeg * (Math.PI / 180)),
      externalWallAreaM2: yDimensionM * raisedHeightM,
      externalWallConversionRatio:
        (yDimensionM * raisedHeightM) / trapezoid.areaM2,
      volumeM3: trapezoid.volumeM3,
      volumeConversionRatio: trapezoid.volumeM3 / trapezoid.areaM2,
      lowestHeightM: raisedHeightM,
    },
  ];
}

/**
 * Breaks a hexagonal roof section into polygon segments for heat loss calculation.
 *
 * @param h2 - This is the total hight of the roof. For raised hex roofs, this is the peak height minus the raised height (h2 part of raised hex roofs) refer to the image node/frontend/public/img/roof/RaisedHex.png
 * @param h1 - The height of the raised portion of the roof in meters (h1 part of raised hex roofs). For standard hex roofs, this is 0.
 * @param W - The width of the center segment of the hexagonal roof in meters (this is the highest point of the hex roof).
 * @param rectangle - The rectangular assumption of the roof area.
 * @returns An array of polygon segments that make up the hexagonal roof.
 *
 *
 *                w            a
 *        ◄───────────────►◄─────────►
 *
 *        /───────────────┐\         ▲
 *       /│               │ \        │
 *     /  │               │  \       │  h2
 *    /   │               │   \      │
 *  /  1  │       3       │ 5  \     │
 * / ─────┴───────────────┴──────\x  ▼
 * │      |               |       │  ▲
 * │      |               |       │  │
 * │   2  |        4      |   6   │  │   h1 (raised roof)
 * │      |               |       │  │
 * ┴──────────────────────────────┤  ▼
 * │                              │
 * │                              │
 * │           Building           │
 * │                              │
 * │                              │
 * └──────────────────────────────┘
 * ◄──────────────────────────────►
 *            xDimensionM
 */
function breakHexRoofSection(
  h2: number,
  h1: number,
  W: number,
  rectangle: RoofRectangleAssumption,
): PolygonSegment[] {
  const { yDimensionM, xDimensionM } = rectangle;

  const { start: topSegmentStart, end: topSegmentEnd } = createCenteredSegment(
    rectangle.leftTop.coord,
    rectangle.rightTop.coord,
    W * 1000,
  );

  const { start: bottomSegmentStart, end: bottomSegmentEnd } =
    createCenteredSegment(
      rectangle.leftBottom.coord,
      rectangle.rightBottom.coord,
      W * 1000,
    );

  const sideSlopeDeg =
    Math.atan(
      h2 / (coordDist(rectangle.leftTop.coord, topSegmentStart) / 1e3),
    ) *
    (180 / Math.PI);

  const a = Math.max((xDimensionM - W) / 2, 0);

  // side areas (side being areas 1, 2, 5 and 6)
  const sideVerticalWallAreaM2 = h2 * a + h1 * 2 * a + h1 * yDimensionM; // this is all vertical walls of the sides in 3d
  const sideBirdsEyeAreaM2 = a * yDimensionM;
  const sideVolumeM3 = (sideBirdsEyeAreaM2 * h2) / 2 + sideBirdsEyeAreaM2 * h1;

  // middle areas (middle being areas 3 and 4)
  const middleVerticalWallAreaM2 = (h2 + h1) * W * 2;
  const middleBirdsEyeAreaM2 = W * yDimensionM;
  const middleVolumeM3 = middleBirdsEyeAreaM2 * (h2 + h1);

  return [
    {
      polygonCw: cloneSimple([
        rectangle.leftTop.coord,
        topSegmentStart,
        bottomSegmentStart,
        rectangle.leftBottom.coord,
      ]),
      slopeDeg: sideSlopeDeg,
      slopeDirectionDegCW: 0,
      slopeAreaConversionRatio: 1 / Math.cos(sideSlopeDeg * (Math.PI / 180)),
      externalWallAreaM2: sideVerticalWallAreaM2,
      externalWallConversionRatio: sideVerticalWallAreaM2 / sideBirdsEyeAreaM2,
      volumeM3: sideVolumeM3,
      volumeConversionRatio: sideVolumeM3 / sideBirdsEyeAreaM2,
      lowestHeightM: h1,
    },
    {
      polygonCw: cloneSimple([
        topSegmentStart,
        topSegmentEnd,
        bottomSegmentEnd,
        bottomSegmentStart,
      ]),
      slopeDeg: 0,
      slopeDirectionDegCW: 270,
      slopeAreaConversionRatio: 1,
      externalWallAreaM2: middleVerticalWallAreaM2,
      externalWallConversionRatio:
        middleVerticalWallAreaM2 / middleBirdsEyeAreaM2,
      volumeM3: middleVolumeM3,
      volumeConversionRatio: middleVolumeM3 / middleBirdsEyeAreaM2,
      lowestHeightM: h2 + h1,
    },
    {
      polygonCw: cloneSimple([
        topSegmentEnd,
        rectangle.rightTop.coord,
        rectangle.rightBottom.coord,
        bottomSegmentEnd,
      ]),
      slopeDeg: sideSlopeDeg,
      slopeDirectionDegCW: 180,
      slopeAreaConversionRatio: 1 / Math.cos(sideSlopeDeg * (Math.PI / 180)),
      externalWallAreaM2: sideVerticalWallAreaM2,
      externalWallConversionRatio: sideVerticalWallAreaM2 / sideBirdsEyeAreaM2,
      volumeM3: sideVolumeM3,
      volumeConversionRatio: sideVolumeM3 / sideBirdsEyeAreaM2,
      lowestHeightM: h1,
    },
  ];
}

function breakSlopeRoofSection(
  heightM: number,
  raisedHeightM: number,
  segmentWidthM: number,
  rectangle: RoofRectangleAssumption,
): PolygonSegment[] {
  const { yDimensionM: lengthM, xDimensionM: widthM } = rectangle;

  const topSegmentEnd = adjustHoriPos(
    rectangle.rightTop.coord,
    rectangle,
    -segmentWidthM! * 1000,
  );
  const bottomSegmentEnd = adjustHoriPos(
    rectangle.rightBottom.coord,
    rectangle,
    -segmentWidthM! * 1000,
  );

  const slopeDeg =
    Math.atan(
      heightM! / (coordDist(rectangle.leftTop.coord, topSegmentEnd) / 1e3),
    ) *
    (180 / Math.PI);

  const slopePartExternalWallAreaM2: number =
    heightM * Math.max(rectangle.xDimensionM - segmentWidthM, 0) +
    raisedHeightM * 2 * Math.max(rectangle.xDimensionM - segmentWidthM, 0) +
    raisedHeightM * rectangle.yDimensionM;
  const slopePartAreaM2: number =
    Math.max(rectangle.xDimensionM - segmentWidthM, 0) * lengthM;
  const slopeVolumeM3: number =
    (slopePartAreaM2 * heightM) / 2 + slopePartAreaM2 * raisedHeightM;
  const flatPartExternalWallAreaM2: number =
    2 * (heightM + raisedHeightM) * Math.min(segmentWidthM, widthM) +
    (heightM + raisedHeightM) * lengthM;
  const flatPartAreaM2: number = Math.min(segmentWidthM, widthM) * lengthM;
  const flatVolumeM3: number = flatPartAreaM2 * (heightM + raisedHeightM);

  return [
    {
      polygonCw: cloneSimple([
        rectangle.leftTop.coord,
        topSegmentEnd,
        bottomSegmentEnd,
        rectangle.leftBottom.coord,
      ]),
      slopeDeg: slopeDeg,
      slopeDirectionDegCW: 0,
      slopeAreaConversionRatio: 1 / Math.cos(slopeDeg * (Math.PI / 180)),
      externalWallAreaM2: slopePartExternalWallAreaM2,
      externalWallConversionRatio:
        slopePartExternalWallAreaM2 / slopePartAreaM2,
      volumeM3: slopeVolumeM3,
      volumeConversionRatio: slopeVolumeM3 / slopePartAreaM2,
      lowestHeightM: raisedHeightM,
    },
    {
      polygonCw: cloneSimple([
        topSegmentEnd,
        rectangle.rightTop.coord,
        rectangle.rightBottom.coord,
        bottomSegmentEnd,
      ]),
      slopeDeg: 0,
      slopeDirectionDegCW: 270,
      slopeAreaConversionRatio: 1,
      externalWallAreaM2: flatPartExternalWallAreaM2,
      externalWallConversionRatio: flatPartExternalWallAreaM2 / flatPartAreaM2,
      volumeM3: flatVolumeM3,
      volumeConversionRatio: flatVolumeM3 / flatPartAreaM2,
      lowestHeightM: heightM + raisedHeightM,
    },
  ];
}

export function breakRoofRectangleAssumption(
  coordsCw: Coord[],
): RoofRectangleAssumption {
  let xMin = coordsCw[0].x,
    xMax = coordsCw[0].x,
    yMin = coordsCw[0].y,
    yMax = coordsCw[0].y;

  for (let i = 1; i < coordsCw.length; i++) {
    if (coordsCw[i].x < xMin) xMin = coordsCw[i].x;
    if (coordsCw[i].x > xMax) xMax = coordsCw[i].x;
    if (coordsCw[i].y < yMin) yMin = coordsCw[i].y;
    if (coordsCw[i].y > yMax) yMax = coordsCw[i].y;
  }

  return {
    leftTop: {
      flatten: new Flatten.Point(xMin, yMax),
      coord: { x: xMin, y: yMax },
    },
    rightTop: {
      flatten: new Flatten.Point(xMax, yMax),
      coord: { x: xMax, y: yMax },
    },
    rightBottom: {
      flatten: new Flatten.Point(xMax, yMin),
      coord: { x: xMax, y: yMin },
    },
    leftBottom: {
      flatten: new Flatten.Point(xMin, yMin),
      coord: { x: xMin, y: yMin },
    },
    yDimensionM: coordDist({ x: xMin, y: yMin }, { x: xMin, y: yMax }) / 1e3,
    xDimensionM: coordDist({ x: xMin, y: yMin }, { x: xMax, y: yMin }) / 1e3,
  };
}

/**
 * Provide the ratio convert between intersect area of polygon
 * to actual area that considers the shape of roof
 *
 * Calculate
 * - roofAreaM2
 * - roofAreaConversionRatio
 */
export function doRoofCalculationsBaseOnSegmentation(
  context: CoreContext,
  coreRoom: CoreRoom,
  entity: RoomEntity,
  roofConcrete: RoofEntityConcrete,
  attachedArchitecturalEntityUids: string[],
) {
  let upgradeToRoofComponent = (
    slopes: PolygonSegmentAfterAdjustment[],
  ): CalculateRoofComponentConcrete[] => {
    let roofComponents: CalculateRoofComponentConcrete[] = [];
    for (let slope of slopes) {
      roofComponents.push({
        type: RoofComponentType.ROOFTOP,
        polygonCw: slope.polygonCw,
        slopeAreaConversionRatio: slope.slopeAreaConversionRatio,
        externalWallConversionRatio: slope.externalWallConversionRatio,
        externalWallMaterialUid:
          roofConcrete.externalWallMaterialUid ??
          context.drawing.metadata.heatLoss.defaultMaterial["External Wall"],
        roofMaterialUid:
          roofConcrete.roofMaterialUid ??
          context.drawing.metadata.heatLoss.defaultMaterial["External Wall"],
        volumeConversionRatio: slope.volumeConversionRatio,
        slopeDirectionDegCW: slope.slopeDirectionDegCW,
        slopeDeg: slope.slopeDeg,
        lowestHeightM: slope.lowestHeightM,
      });
    }

    return roofComponents;
  };
  let addWindowToRoofComponent = (
    components: CalculateRoofComponentConcrete[],
    coreArchitectureEntity: CoreArchitectureElement,
    entity: VeluxEntity,
  ): CalculateRoofComponentConcrete[] => {
    let roofComponents: CalculateRoofComponentConcrete[] = [];
    for (let component of components) {
      switch (component.type) {
        case RoofComponentType.ROOFTOP: {
          // Break the original component
          let roofTopDiffToWindowPoly = polygonDifference(
            component.polygonCw,
            coreArchitectureEntity.getPolygonCW(),
          );

          if (roofTopDiffToWindowPoly.length === 0) {
            roofComponents.push(component);
            continue;
          }

          // Then as the result, this rooftop section will be divided into multiple parts
          let newRoofTopComponents: CalculateRoofComponentConcrete[] = [];
          let originalAreaM2 = getAreaM2(component.polygonCw);
          let newRoofTopAreaM2 = 0;

          for (let i = 0; i < roofTopDiffToWindowPoly.length; i++) {
            let newRoofTopComponent: CalculateRoofComponentConcrete = {
              type: RoofComponentType.ROOFTOP,
              polygonCw: roofTopDiffToWindowPoly[i],
              slopeAreaConversionRatio: component.slopeAreaConversionRatio,
              externalWallConversionRatio: 0,
              externalWallMaterialUid: component.externalWallMaterialUid,
              roofMaterialUid: component.roofMaterialUid,
              volumeConversionRatio: component.volumeConversionRatio,
              slopeDirectionDegCW: component.slopeDirectionDegCW,
              slopeDeg: component.slopeDeg,
              lowestHeightM: component.lowestHeightM,
            };
            newRoofTopAreaM2 += getAreaM2(roofTopDiffToWindowPoly[i]);
            newRoofTopComponents.push(newRoofTopComponent);
          }

          let newExternalWallRatio =
            component.externalWallConversionRatio *
            (originalAreaM2 / newRoofTopAreaM2);
          for (let component of newRoofTopComponents) {
            if (component.type !== RoofComponentType.ROOFTOP) continue;
            component.externalWallConversionRatio = newExternalWallRatio;
          }
          roofComponents.push(...newRoofTopComponents);

          // Add window component
          let intersectionPolygons = polygonClipping(
            component.polygonCw,
            coreArchitectureEntity.getPolygonCW(),
          );
          if (intersectionPolygons.length === 0) continue;
          for (let intersection of intersectionPolygons) {
            let windowComponent: CalculateRoofComponentConcrete = {
              type: RoofComponentType.WINDOW,
              polygonCw: intersection,
              slopeDeg: component.slopeDeg,
              slopeDirectionDegCW: component.slopeDirectionDegCW,
              slopeAreaConversionRatio: component.slopeAreaConversionRatio,
              windowMaterialUid:
                entity.architecture.materialUid ??
                context.drawing.metadata.heatLoss.defaultMaterial["Window"],
              volumeConversionRatio: component.volumeConversionRatio,
              lowestHeightM: component.lowestHeightM,
            };
            roofComponents.push(windowComponent);
          }

          break;
        }
        case RoofComponentType.WINDOW:
          roofComponents.push(component);
          break;
        default:
          assertUnreachable(component);
      }
    }

    return roofComponents;
  };

  let roofCalc = context.globalStore.getOrCreateCalculation(entity);
  let roofSegment = new RoofSegmentation(coreRoom, roofConcrete);
  let sections = roofSegment.breakRoofToPolygonSegments();
  let components = upgradeToRoofComponent(sections);

  for (let architectureUid of attachedArchitecturalEntityUids) {
    let coreArchitectureEntity = context.globalStore.getObjectOfTypeOrThrow(
      EntityType.ARCHITECTURE_ELEMENT,
      architectureUid,
    );
    switch (coreArchitectureEntity.entity.arcType) {
      case ArchitectType.VELUX: {
        let filledArchitectureEntity = fillDefaultArcFields(
          context,
          coreArchitectureEntity.entity,
        );

        components = addWindowToRoofComponent(
          components,
          coreArchitectureEntity,
          filledArchitectureEntity,
        );
        break;
      }
      default:
        assertUnreachable(coreArchitectureEntity.entity.arcType);
    }
  }

  let areaM2 = 0,
    externalWallAreaM2 = 0,
    roofAreaM2 = 0,
    windowAreaM2 = 0;
  components.forEach((component) => {
    switch (component.type) {
      case RoofComponentType.ROOFTOP:
        roofAreaM2 +=
          getAreaM2(component.polygonCw) * component.slopeAreaConversionRatio;
        externalWallAreaM2 +=
          getAreaM2(component.polygonCw) *
          component.externalWallConversionRatio;
        break;
      case RoofComponentType.WINDOW:
        windowAreaM2 +=
          getAreaM2(component.polygonCw) * component.slopeAreaConversionRatio;
        break;
      default:
        assertUnreachable(component);
    }
  });

  areaM2 = roofAreaM2 + externalWallAreaM2 + windowAreaM2;
  roofCalc.areaM2 = areaM2;
  roofCalc.roofAreaM2 = roofAreaM2;
  roofCalc.externalWallAreaM2 = externalWallAreaM2;
  roofCalc.windowAreaM2 = windowAreaM2;
  roofCalc.roofComponents = components;
}
