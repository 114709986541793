import * as TM from "transformation-matrix";
import { isCenteredObject } from "../../../../common/src/api/coreObjects";
import CoreBackgroundImage from "../../../../common/src/api/coreObjects/coreBackgroundImage";
import { BackgroundEntity } from "../../../../common/src/api/document/entities/background-entity";
import { DrawableEntityConcrete } from "../../../../common/src/api/document/entities/concrete-entity";
import {
  FloorPlanRender,
  FloorPlanRenders,
} from "../../../../common/src/api/document/types";
import { Coord } from "../../../../common/src/lib/coord";
import { Rectangle } from "../../../../common/src/lib/rectangle";
import {
  assertType,
  assertUnreachable,
  cloneSimple,
} from "../../../../common/src/lib/utils";
import { getFloorPlanRenders } from "../../api/pdf";
import { DEFAULT_FONT_NAME } from "../../config";
import { MainEventBus } from "../../store/main-event-bus";
import CanvasContext from "../lib/canvas-context";
import { EntityDrawingArgs } from "../lib/drawable-object";
import ImageLoader from "../lib/image-loader";
import { Interaction, InteractionType } from "../lib/interaction";
import { Core2Drawable } from "../lib/object-traits/core2drawable";
import { Sizeable } from "../lib/object-traits/sizeable-object";
import { DrawingContext, ObjectConstructArgs } from "../lib/types";
import { updateToolInfoPanel } from "../tools/base-tool";
import { MouseMoveResult, UNHANDLED } from "../types";
import { KeyCode, parseScale } from "../utils";
import { ViewPort } from "../viewport";
import {
  CenteredObjectConcrete,
  DrawableObjectConcrete,
} from "./concrete-object";

export const RESOLUTION_TOLERANCE = 2;

const Base = Core2Drawable(CoreBackgroundImage);

export default class DrawableBackgroundImage extends Base implements Sizeable {
  // We could not add this to the base drawable class because
  // of some typescript thing so they have to be added at the concrete class.
  constructor(args: ObjectConstructArgs<BackgroundEntity>) {
    super(args.context, args.obj);
    this.onSelect = args.onSelect;
    this.onInteractionComplete = args.onInteractionComplete;
    this.document = args.document;
  }

  get width() {
    return this.entity.paperSize.widthMM / parseScale(this.entity.scaleName);
  }

  get height() {
    return this.entity.paperSize.heightMM / parseScale(this.entity.scaleName);
  }

  get center() {
    return this.entity.center;
  }

  set center(value) {
    this.entity.center = value;
  }

  get boundary() {
    return this.entity.crop;
  }

  set boundary(value: Rectangle) {
    this.entity.crop = value;
  }

  sizable: true = true;
  grabbedPoint: [number, number] | null = null;
  grabbedCenterState: Coord | null = null;
  grabbedOffsetState: Coord | null = null;
  grabbedCropState: Rectangle | null = null;
  hasDragged: boolean = false;

  oldKey: string = "";

  imageCache = new Map<string, HTMLImageElement | null>(); // null means we are loading.

  renderIndex: FloorPlanRenders | null | false = null; // false means loading
  drawPoint(context: DrawingContext, objectCoord: Coord, label: string) {
    const { ctx } = context;
    this.withScreen(context, objectCoord, () => {
      ctx.fillStyle = "#ff0000";
      ctx.beginPath();

      ctx.arc(0, 0, 12, 0, Math.PI * 2);
      ctx.fill();

      ctx.font = "14pt " + DEFAULT_FONT_NAME;
      ctx.fillStyle = "#FFFFFF";
      ctx.fillTextStable(label, -6, +7);
    });
  }

  prepareDelete(
    context: CanvasContext,
    _calleeEntityUid?: string,
  ): DrawableObjectConcrete[] {
    const result: DrawableObjectConcrete[] = [this];
    this.children.forEach((v) => {
      if (isCenteredObject(v)) {
        assertType<CenteredObjectConcrete>(v);
        v.debase(context);
      }
    });
    return result;
  }

  isActive() {
    return true;
  }

  // If an image is immediately available, return the best one. Otherwise (or in addition), load the best one and
  // redraw when appropriate.
  chooseImage(
    context: DrawingContext,
    forExport: boolean,
  ): HTMLImageElement | null {
    // target resolution
    const widthInPixels = context.vp.toScreenLength(
      this.toWorldLength(this.width),
    );

    // find image in thing.
    if (this.renderIndex === false) {
      return null;
    }

    if (this.renderIndex === null) {
      this.renderIndex = false;
      getFloorPlanRenders(this.entity.key).then((res) => {
        if (res.success) {
          this.renderIndex = res.data;
          MainEventBus.$emit("redraw");
        }
      });
      return null;
    }

    if (Object.keys(this.renderIndex.bySize).length === 0) {
      return null;
    }

    let bestVal: FloorPlanRender | null = null;
    let lastVal!: FloorPlanRender;
    const renders = Object.values(this.renderIndex.bySize).sort(
      (a, b) => a.width - b.width,
    );
    let bestValI = renders.length - 1;
    for (let i = 0; i < renders.length; i++) {
      const k = renders[i];
      if (k.width * RESOLUTION_TOLERANCE >= widthInPixels) {
        bestVal = k;
        bestValI = i;
        if (!forExport) {
          // Choosing lower res images is only for performance. Recover the high res one when exporting.
          break;
        }
      }
      lastVal = k;
    }
    if (bestVal === null) {
      bestVal = lastVal;
    }

    if (bestVal.images.length !== 1) {
      throw new Error("only layers with one image are supported right now");
    }

    // check if current image exists
    const imageVal = this.imageCache.get(bestVal.images[0].key);
    if (imageVal) {
      return imageVal;
    }

    // otherwise, provoke the loading of the image
    if (imageVal !== null) {
      // check that it isn't already loading
      this.imageCache.set(bestVal.images[0].key, null);
      ImageLoader.get(bestVal.images[0].key).then((img) => {
        this.imageCache.set(bestVal!.images[0].key, img);
        MainEventBus.$emit("redraw");
      });
    }

    // and finally return a more suitable image.
    for (let i = bestValI - 1; i >= 0; i--) {
      if (renders[i].images.length !== 1) {
        throw new Error("only layers with one image are supported right now");
      }

      const img = this.imageCache.get(renders[i].images[0].key);
      if (img) {
        return img;
      }
    }

    for (let i = bestValI + 1; i < renders.length; i++) {
      if (renders[i].images.length !== 1) {
        throw new Error("only layers with one image are supported right now");
      }

      const img = this.imageCache.get(renders[i].images[0].key);
      if (img) {
        return img;
      }
    }

    // no images loaded at all.
    return null;
  }

  // get ready for export.
  async ensureHighestResImageIsLoaded() {
    if (this.renderIndex === null) {
      this.renderIndex = false;
      const res = await getFloorPlanRenders(this.entity.key);
      if (res.success) {
        this.renderIndex = res.data;
      }
    }

    if (this.renderIndex === false) {
      return;
    }

    if (Object.keys(this.renderIndex.bySize).length === 0) {
      return;
    }

    const renders = Object.values(this.renderIndex.bySize).sort(
      (a, b) => a.width - b.width,
    );

    let imageObtained = false;
    let index = 1;
    while (!imageObtained && renders.length - index >= 0) {
      try {
        this.imageCache.set(
          renders[renders.length - index].images[0].key,
          await ImageLoader.get(renders[renders.length - index].images[0].key),
        );
        imageObtained = true;
      } catch (err: any) {
        if (err.includes("Could not load image background")) {
          index++;
          console.log(err);
        } else {
          break;
        }
      }
    }
  }

  /**
   * Draw with natural clip
   */
  naturalClipDraw(
    context: DrawingContext,
    alpha: number,
    l: number,
    t: number,
    w: number,
    h: number,
    active: boolean,
    forExport: boolean,
  ) {
    const ctx = context.ctx;

    const image = this.chooseImage(context, forExport);

    if (image) {
      const imgScaleX = this.width / image.width;
      const imgScaleY = this.height / image.height;

      const sx = context.vp.currToSurfaceScale(ctx);
      const oldAlpha = ctx.globalAlpha;
      ctx.globalAlpha = alpha;
      const { x, y } = {
        x: (l - image.naturalWidth / 2) * imgScaleX,
        y: (t - image.naturalHeight / 2) * imgScaleY,
      };
      const oldCompositeOperation = ctx.globalCompositeOperation;
      const sw = w * imgScaleX;
      const sh = h * imgScaleY;
      ctx.fillStyle = "#FFFFFF";
      ctx.fillRect(x, y, sw, sh);

      // Draw a potentially rotated image
      ctx.drawImage(
        image,
        l - this.entity.offset.x / imgScaleX,
        t - this.entity.offset.y / imgScaleY,
        w,
        h,
        x,
        y,
        sw,
        sh,
      );

      if (!active && !forExport) {
        ctx.fillStyle = "#f0f8ff";
        ctx.globalCompositeOperation = "color";
        ctx.fillRect(x, y, sw, sh);
      }

      if (!forExport) {
        ctx.lineWidth = 1 / sx;
        ctx.strokeStyle = "#AAAAAA";
        ctx.beginPath();
        ctx.strokeRect(x, y, sw, sh);
      }
      ctx.globalAlpha = oldAlpha;
      ctx.globalCompositeOperation = oldCompositeOperation;
    } else {
      throw new Error("Trying to draw natural image without a loaded image");
    }
  }

  objectClipDraw(
    context: DrawingContext,
    alpha: number,
    x: number,
    y: number,
    w: number,
    h: number,
    _selected: boolean,
    active: boolean,
    forExport: boolean,
  ) {
    // We use an inverse viewport to find the appropriate clip bounds.
    const ctx = context.ctx;
    const image = this.chooseImage(context, forExport);
    if (image) {
      const imgScaleX = this.width / image.width;
      const imgScaleY = this.height / image.height;

      const ivp = new ViewPort(
        TM.transform(TM.scale(imgScaleX, imgScaleY)),
        image.naturalWidth,
        image.naturalHeight,
      );

      const l = ivp.toSurfaceCoord({ x, y });
      const t = ivp.toSurfaceCoord({ x, y });

      this.naturalClipDraw(
        context,
        alpha,
        l.x + image.naturalWidth / 2,
        t.y + image.naturalHeight / 2,
        w / imgScaleX,
        h / imgScaleY,
        active,
        forExport,
      );
    } else {
      if (!forExport) {
        const oldAlpha = ctx.globalAlpha;
        ctx.globalAlpha = 0.5;
        ctx.fillStyle = "#AAAAAA";
        ctx.fillRect(x, y, w, h);
        const fontSize = Math.round(w / 20);
        ctx.font = fontSize + "pt " + DEFAULT_FONT_NAME;
        ctx.fillStyle = "#FFFFFF";
        const textW = ctx.measureText("Please Wait...");
        ctx.fillTextStable(
          "Please Wait...",
          x + w / 2 - textW.width / 2,
          y + h / 2 + fontSize / 2,
        );
        ctx.globalAlpha = oldAlpha;
      }
    }
  }

  // Draw without world space concerns
  drawEntity(
    context: DrawingContext,
    { selected, layerActive, forExport }: EntityDrawingArgs,
  ) {
    const image = this.chooseImage(context, forExport);
    if (selected && image) {
      const imgScaleX = this.width / image.width;
      const imgScaleY = this.height / image.height;

      this.naturalClipDraw(
        context,
        0.2,
        this.entity.offset.x / imgScaleX,
        this.entity.offset.y / imgScaleY,
        image.naturalWidth,
        image.naturalHeight,
        layerActive,
        forExport,
      );
    }

    let alpha = 1;
    if (!forExport && this.hasDragged) {
      alpha = 0.6;
    }

    this.objectClipDraw(
      context,
      alpha,
      this.boundary.x,
      this.boundary.y,
      this.boundary.w,
      this.boundary.h,
      selected,
      layerActive,
      forExport,
    );

    if (selected && layerActive && !forExport) {
      if (this.entity.pointA) {
        this.drawPoint(context, this.entity.pointA, "A");
      }
      if (this.entity.pointB) {
        this.drawPoint(context, this.entity.pointB, "B");
      }
    }
    if (forExport || this.document.uiState.toolHandlerName === "pdf-snapshot") {
      const transparency =
        this.document.uiState.exportSettings.transparency / 100;
      this.drawTransparencyOverlay(context, transparency);
    }
  }

  /**
   * The "Transparency" of the background image is actually just a white transparent rectangle drawn over the background image.
   * We cant make the PDF itself transparent as PDFs can overlay each other.
   *
   */
  private drawTransparencyOverlay(
    context: DrawingContext,
    transparency: number = 0.5,
  ) {
    const overlayTransparency = 1 - transparency;
    const { ctx } = context;
    ctx.fillStyle = `rgba(255, 255, 255, ${overlayTransparency})`;
    ctx.fillRect(
      this.boundary.x,
      this.boundary.y,
      this.boundary.w,
      this.boundary.h,
    );
  }

  getCopiedObjects(): DrawableObjectConcrete[] {
    const res: DrawableObjectConcrete[] = [this];
    this.children.forEach((o) => {
      assertType<DrawableObjectConcrete>(o);
      res.push(o);
      const conns = this.globalStore.getConnections(o.uid);
      res.push(...conns.map((c) => this.drawableStore.get(c)!));
    });
    return res;
  }

  /**
   * Event Handlers
   */

  inBounds(objectCoord: Coord): boolean {
    if (
      objectCoord.x < this.entity.crop.x ||
      objectCoord.y < this.entity.crop.y
    ) {
      return false;
    } else if (
      objectCoord.x <= this.entity.crop.x + this.entity.crop.w &&
      objectCoord.y <= this.entity.crop.y + this.entity.crop.h
    ) {
      return true;
    }
    return false;
  }

  get selectable() {
    return this.document.uiState.floorLockStatus;
  }

  lastShiftPressed: number | null = null;
  lastCtrlPressed: number | null = null;
  onMouseDown(event: MouseEvent, context: CanvasContext): boolean {
    if (!context.document.uiState.floorLockStatus) return false;
    const w = context.viewPort.toWorldCoord({
      x: event.clientX,
      y: event.clientY,
    });
    const o = this.toObjectCoord(w);
    if (this.inBounds(o)) {
      context.isLayerDragging = true;
      this.grabbedPoint = [w.x, w.y];
      this.grabbedCenterState = {
        x: this.center.x,
        y: this.center.y,
      };
      this.grabbedOffsetState = cloneSimple(this.entity.offset);
      this.grabbedCropState = cloneSimple(this.entity.crop);
      this.hasDragged = false;
      if (this.onSelect) {
        this.onSelect(event);
      }
      return true;
    }

    return false;
  }

  onMouseMove(event: MouseEvent, context: CanvasContext): MouseMoveResult {
    if (event.shiftKey) {
      this.lastShiftPressed = Date.now();
    }

    if (event.ctrlKey) {
      this.lastCtrlPressed = Date.now();
    }

    if (this.hasDragged) {
      updateToolInfoPanel(context.document.uiState, {
        keyHandlers: [
          [
            [[KeyCode.SHIFT]],
            {
              name: "Shift + drag offsets the background image within the crop.",
            },
          ],
          [
            [[KeyCode.CONTROL]],
            {
              name: "Ctrl + drag moves the background with moving the design.",
            },
          ],
        ],
        information: [],
      });
    } else {
      updateToolInfoPanel(context.document.uiState, {
        keyHandlers: [],
        information: [],
      });
    }

    if (event.buttons) {
      if (
        this.grabbedPoint != null &&
        this.grabbedCenterState != null &&
        this.grabbedOffsetState != null &&
        this.grabbedCropState != null
      ) {
        this.hasDragged = true;

        const w = context.viewPort.toWorldCoord({
          x: event.clientX,
          y: event.clientY,
        });

        if (this.lastCtrlPressed && Date.now() - this.lastCtrlPressed < 200) {
          // Move the object
          this.entity.crop = cloneSimple(this.grabbedCropState);
          this.entity.offset = cloneSimple(this.grabbedOffsetState);
          this.center.x =
            this.grabbedCenterState.x + w.x - this.grabbedPoint[0];
          this.center.y =
            this.grabbedCenterState.y + w.y - this.grabbedPoint[1];
        } else if (
          this.lastShiftPressed &&
          Date.now() - this.lastShiftPressed < 200
        ) {
          // Move the offset, not the object
          const o = this.toObjectCoord(w);
          const grabbedO = this.toObjectCoord({
            x: this.grabbedPoint[0],
            y: this.grabbedPoint[1],
          });

          this.entity.center = cloneSimple(this.grabbedCenterState);
          this.entity.crop = cloneSimple(this.grabbedCropState);
          this.entity.offset.x = this.grabbedOffsetState.x + o.x - grabbedO.x;
          this.entity.offset.y = this.grabbedOffsetState.y + o.y - grabbedO.y;
        } else {
          // Move the entire crop box and its content
          const deltaX = w.x - this.grabbedPoint[0];
          const deltaY = w.y - this.grabbedPoint[1];
          const angle = (360 - this.entity.rotation) * (Math.PI / 180);

          const rotatedDeltaX =
            deltaX * Math.cos(angle) - deltaY * Math.sin(angle);
          const rotatedDeltaY =
            deltaX * Math.sin(angle) + deltaY * Math.cos(angle);

          this.entity.center = cloneSimple(this.grabbedCenterState);
          this.entity.crop.x = this.grabbedCropState.x + rotatedDeltaX;
          this.entity.crop.y = this.grabbedCropState.y + rotatedDeltaY;
          this.entity.offset.x = this.grabbedOffsetState.x + rotatedDeltaX;
          this.entity.offset.y = this.grabbedOffsetState.y + rotatedDeltaY;
        }

        context.scheduleDraw();
        return { handled: true, cursor: "Move" };
      } else {
        return UNHANDLED;
      }
    } else {
      if (this.grabbedCenterState != null || this.grabbedPoint != null) {
        this.onInteractionComplete(event);
      }
      this.grabbedCenterState = null;
      this.grabbedPoint = null;
      this.grabbedOffsetState = null;
      this.grabbedCropState = null;
      this.hasDragged = false;
      return UNHANDLED;
    }
  }

  onMouseUp(event: MouseEvent, context: CanvasContext): boolean {
    if (
      !context.document.uiState.floorLockStatus &&
      this.document.uiState.selectedUids.length === 0 // avoid triggering the flash if user is deselecting a room by clicking on the pdf
    ) {
      MainEventBus.$emit("flash-pdf-lock");
    }
    this.lastCtrlPressed = null;
    this.lastShiftPressed = null;
    if (this.grabbedPoint || this.grabbedCenterState) {
      context.isLayerDragging = false;
      this.grabbedPoint = null;
      this.grabbedCenterState = null;
      this.hasDragged = false;
      context.scheduleDraw();
      this.onInteractionComplete(event);
      return true;
    }
    return false;
  }

  offerInteraction(interaction: Interaction): DrawableEntityConcrete[] | null {
    switch (interaction.type) {
      case InteractionType.INSERT:
      case InteractionType.CONTINUING_CONDUIT:
      case InteractionType.STARTING_CONDUIT:
      case InteractionType.SNAP_ONTO_RECEIVE:
      case InteractionType.SNAP_ONTO_SEND:
      case InteractionType.EXTEND_NETWORK:
      case InteractionType.LINK_ENTITY:
        return null;
      default:
        assertUnreachable(interaction);
    }
    return null;
  }

  onUpdate() {
    if (this.entity && this.entity.key !== this.oldKey) {
      this.oldKey = this.entity.key;
      getFloorPlanRenders(this.entity.key).then((res) => {
        if (res.success) {
          this.renderIndex = res.data;
          MainEventBus.$emit("redraw");
        }
      });
    }
  }
}
