import {
  InsulationJackets,
  InsulationMaterials,
  StandardFlowSystemUids,
} from "../../config";
import { MechanicalFlowSystem } from "../flow-systems";

export interface HeatingProperties {
  minLoopPressureKPA: number;
  maxLoopPressureKPA: number;
}

export const initialHeatingProperties: () => HeatingProperties = () => ({
  minLoopPressureKPA: 100,
  maxLoopPressureKPA: 300,
});

export const MECHANICAL_FLOW_SYSTEMS = (): MechanicalFlowSystem[] => [
  {
    type: "mechanical",
    role: "heating",
    name: "Heating",
    temperatureC: 40,
    color: { hex: "#F44E3B" },
    uid: StandardFlowSystemUids.Heating,
    fluid: "water",
    defaultPipeHeightM: 3,

    hasReturnSystem: true,
    ...initialHeatingProperties(),

    return: {
      color: { hex: "#007BFF" },
      maxVelocityMS: 1e10,
      insulated: true,
      insulation: {
        material: InsulationMaterials.mineralWool,
        jacket: InsulationJackets.allServiceJacket,
        thicknessMM: 25,
      },
    },

    networks: {
      risers: {
        // N/A For heating
        spareCapacityPCT: 0,
        velocityMS: 1,
        pressureDropKPAM: 0.35,
        material: "copperTypeB",
        minimumPipeSize: 15,
      },
      reticulations: {
        spareCapacityPCT: 0,
        velocityMS: 1,
        pressureDropKPAM: 0.35,
        material: "copperTypeB",
        minimumPipeSize: 15,
      },
    },

    // Radiator version at v1 while exports for v2 don't work - will switch to v2 after it is fully ready.
    radiatorVersion: "v1",
  },
  {
    type: "mechanical",
    role: "chilled",
    name: "Chilled",
    temperatureC: 5,
    color: { hex: "#009CE0" },
    uid: StandardFlowSystemUids.Chilled,
    fluid: "water",
    defaultPipeHeightM: 3,

    hasReturnSystem: true,

    ...initialHeatingProperties(),
    return: {
      color: { hex: "#73D8FF" },
      maxVelocityMS: 1e10,
      insulated: true,
      insulation: {
        material: InsulationMaterials.mineralWool,
        jacket: InsulationJackets.allServiceJacket,
        thicknessMM: 50,
      },
    },

    networks: {
      risers: {
        // N/A For heating
        spareCapacityPCT: 0,
        velocityMS: 1.2,
        pressureDropKPAM: 0.4,
        material: "copperTypeB",
        minimumPipeSize: 15,
      },
      reticulations: {
        spareCapacityPCT: 0,
        velocityMS: 1.2,
        pressureDropKPAM: 0.4,
        material: "copperTypeB",
        minimumPipeSize: 15,
      },
    },

    // Radiator version at v1 while exports for v2 don't work - will switch to v2 after it is fully ready.
    radiatorVersion: "v1",
  },
  {
    type: "mechanical",
    role: "condenser",
    name: "Condenser",
    temperatureC: 30,
    color: { hex: "#68BC00" },
    uid: StandardFlowSystemUids.Condenser,
    fluid: "water",
    defaultPipeHeightM: 3,

    hasReturnSystem: true,
    ...initialHeatingProperties(),

    return: {
      color: { hex: "#DBDF00" },
      maxVelocityMS: 1e10,
      insulated: true,
      insulation: {
        material: InsulationMaterials.mineralWool,
        jacket: InsulationJackets.allServiceJacket,
        thicknessMM: 50,
      },
    },

    networks: {
      risers: {
        // N/A For heating
        spareCapacityPCT: 0,
        velocityMS: 1.2,
        pressureDropKPAM: 0.4,
        material: "copperTypeB",
        minimumPipeSize: 15,
      },
      reticulations: {
        spareCapacityPCT: 0,
        velocityMS: 1.2,
        pressureDropKPAM: 0.4,
        material: "copperTypeB",
        minimumPipeSize: 15,
      },
    },

    // Radiator version at v1 while exports for v2 don't work - will switch to v2 after it is fully ready.
    radiatorVersion: "v1",
  },
];
