import { ILogger } from "../../../../common/src/lib/logger";
import { NullablePrimitive } from "../../../../common/src/lib/primitive";
import {
  isSentryError,
  SentryError,
} from "../../../../common/src/lib/sentry-error";
import { Sentry } from "./Sentry";

export class SentryLogger implements ILogger {
  log(_message?: any, ..._optionalParams: any[]) {
    // Noop, Console Logger will pick this up
  }

  warn(_message?: any, ..._optionalParams: any[]) {
    // Noop, Console Logger will pick this up
  }

  error(
    error: unknown,
    tags: Record<string, NullablePrimitive>,
    extras: Record<string, any>,
    cause?: unknown,
  ) {
    if (isSentryError(error) || error instanceof Error) {
      Sentry.captureException(error);
    } else if (typeof error === "string") {
      Sentry.captureException(new SentryError(error, tags, extras, cause));
    } else {
      Sentry.captureException(
        SentryError.wrapError("Unknown Error", error, tags, extras),
      );
    }
  }

  debug(_message?: any, ..._optionalParams: any[]) {
    // Noop, Console Logger will pick this up
  }
}
