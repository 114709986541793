<template>
  <div style="height: 100%">
    <MainNavBar></MainNavBar>
    <div style="overflow: auto; max-height: calc(100% - 61px)">
      <b-container class="home">
        <b-row>
          <b-col cols="12">
            <h1 class="title">Users</h1>
          </b-col>
          <b-col v-if="isSuperuser" cols="12">
            <b-card
              border-variant="info"
              title="Daily Active Users Graph"
              style="text-align: initial"
              class="mb-5 mt-5"
            >
              <div id="date-container">
                <b-form-datepicker
                  id="active-from"
                  v-model="reactiveDate.activeFrom"
                  placeholder="From"
                  hide-header
                  reset-button
                  today-button
                  :reset-value="initDate.activeFrom"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                  }"
                ></b-form-datepicker>
                <b-form-datepicker
                  id="active-to"
                  v-model="reactiveDate.activeTo"
                  class="ml-2"
                  placeholder="To"
                  hide-header
                  reset-button
                  today-button
                  :reset-value="initDate.activeTo"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                  }"
                ></b-form-datepicker>
              </div>
              <ApexChart
                v-if="isChartLoaded"
                type="bar"
                :options="options"
                :series="series"
                class="mt-4"
                height="400"
                @dataPointSelection="onDailyUsersGraphBarClicked"
              />
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-alert
              v-if="allUsers.length === 0 && isLoaded"
              variant="success"
              show
            >
              There are no users right now. Wait what? Then WHO ARE YOU.
            </b-alert>
          </b-col>
        </b-row>
        <b-row
          v-if="profile && profile.accessLevel <= AccessLevel.MANAGER"
          style="margin-top: 30px; margin-bottom: 30px"
        >
          <b-col>
            <b-button
              size="lg"
              variant="primary"
              to="/users/create"
              :disabled="reachedMaxAccountsNum"
              ><strong>Create User </strong>
            </b-button>
            &nbsp;
            <b-icon
              v-if="reachedMaxAccountsNum"
              v-b-popover.hover.top="
                'Your organization has reached the max accounts limit'
              "
              icon="exclamation-circle-fill"
              variant="warning"
              font-scale="2"
              shift-v="-3"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col class="flex flex-col items-end gap-4">
            <div class="flex justify-center items-center gap-2">
              <div>Activation status</div>
              <div>
                <b-form-select
                  v-model="shownActivationStatus"
                  class="text-center"
                  :options="shownActivationStatusOptions"
                  size="sm"
                >
                </b-form-select>
              </div>
            </div>
            <div
              v-if="activeUsersFilter"
              class="flex justify-center items-center gap-2"
            >
              <div>
                Active users on {{ activeUsersFilter.date.toDateString() }}
              </div>
              <b-button @click="clearDateFilter">Clear date filter</b-button>
            </div>
            <b-table
              style="background-color: white"
              :items="usersRendered"
              :fields="fields"
              selectable
              @row-clicked="userRowClicked"
            ></b-table>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script lang="ts">
import { subMonths as DateFnsSubMonths } from "date-fns";
import Vue, { defineComponent } from "vue";
import { AccessLevel, User } from "../../../common/src/models/User";
import MainNavBar from "../../src/components/MainNavBar.vue";
import { activeUsersOnDate, dailyActiveUsers, getUsers } from "../api/users";

// https://apexcharts.com/docs/vue-charts/
import { ApexOptions } from "apexcharts";
import VueApexCharts from "vue-apexcharts";
import { assertUnreachableAggressive } from "../../../common/src/lib/utils";

Vue.use(VueApexCharts);
Vue.component("ApexChart", VueApexCharts);

interface DateProps {
  activeFrom: Date;
  activeTo: Date;
}

enum ShownActivationStatus {
  ALL,
  ACTIVATED_ONLY,
  DEACTIVATED_ONLY,
}

export default defineComponent({
  components: {
    MainNavBar,
  },
  data() {
    const activeUsersFilter: { userNames: Set<string>; date: Date } | null =
      null;
    const reactiveDate: DateProps = {
      activeFrom: DateFnsSubMonths(new Date(), 1),
      activeTo: new Date(),
    };
    const initDate: DateProps = {
      activeFrom: DateFnsSubMonths(new Date(), 1),
      activeTo: new Date(),
    };
    const isChartLoaded: boolean = false;
    const isLoaded: boolean = false;
    const allUsers: User[] = [];
    const options: ApexOptions = {
      plotOptions: {
        bar: {
          dataLabels: {
            position: "center",
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter(val: number) {
          return Math.floor(val).toString();
        },
      },
      chart: {
        id: "users-chart",
      },
      xaxis: {
        /** dates eg. (["2024-02-01,"2024-03-10""]) */
        categories: [],
      },
    };
    const series: ApexAxisChartSeries = [
      {
        name: "users",
        data: [],
      },
    ];

    const urlParams = new URLSearchParams(window.location.search);
    return {
      series,
      options,
      allUsers,
      isLoaded,
      isChartLoaded,
      initDate,
      reactiveDate,
      urlParams: urlParams,
      orgId: urlParams.get("orgId"),
      activeUsersFilter: activeUsersFilter as {
        userNames: Set<string>;
        date: Date;
      } | null,
      shownActivationStatusOptions: [
        { value: ShownActivationStatus.ALL, text: "All" },
        { value: ShownActivationStatus.ACTIVATED_ONLY, text: "Activated" },
        { value: ShownActivationStatus.DEACTIVATED_ONLY, text: "Deactivated" },
      ] as const,
      shownActivationStatus: ShownActivationStatus.ACTIVATED_ONLY,
    };
  },
  computed: {
    usersRendered() {
      return this.allUsers
        .filter((u) => {
          if (!this.activeUsersFilter) return true;
          return this.activeUsersFilter.userNames.has(u.username);
        })
        .filter((u) => {
          if (!this.orgId) return true;
          return u.organization?.id === this.orgId;
        })
        .filter((u) => {
          switch (this.shownActivationStatus) {
            case ShownActivationStatus.ALL:
              return true;
            case ShownActivationStatus.ACTIVATED_ONLY:
              return u.activated;
            case ShownActivationStatus.DEACTIVATED_ONLY:
              return !u.activated;
            default:
              assertUnreachableAggressive(this.shownActivationStatus);
          }
        })
        .map((u) => {
          return {
            username: u.username,
            fullName: u.name + ((u.lastname && ` ${u.lastname}`) || ""),
            accessLevel: ["SUPERUSER", "ADMIN", "MANAGER", "USER"][
              u.accessLevel
            ],
            organization: u.organization ? u.organization.name : "",
            activated: u.activated,
            lastActivityOn: u.lastActivityOn ? u.lastActivityOn : "",
          };
        });
    },
    profile(): User {
      return this.$store.getters["profile/profile"];
    },
    fields() {
      const f = [
        { key: "username", sortable: true },
        { key: "fullName", sortable: true },
        { key: "accessLevel", sortable: true },
        { key: "organization", sortable: true },
        {
          key: "activated",
          sortable: true,
          formatter: (value: boolean) => {
            return value ? "✅" : "❌";
          },
        },
      ];
      if (this.profile && this.profile.accessLevel <= AccessLevel.ADMIN) {
        f.push({ key: "lastActivityOn", sortable: true });
      }
      return f;
    },
    AccessLevel() {
      return AccessLevel;
    },
    isSuperuser() {
      return this.profile.accessLevel === AccessLevel.SUPERUSER;
    },
    reachedMaxAccountsNum() {
      if (this.profile.organization == null) {
        return true;
      } else if (
        this.profile.organization.maxAccountsNum === null ||
        this.profile.accessLevel <= AccessLevel.ADMIN
      ) {
        return false;
      } else {
        return (
          this.profile.organization.maxAccountsNum <=
          this.allUsers.filter(
            (user) => user.organization?.id === this.profile.organization?.id,
          ).length
        );
      }
    },
  },
  watch: {
    reactiveDate: [{ immediate: true, deep: true, handler: "onChangeDate" }],
  },
  async mounted() {
    this.updateDailyUsersGraph(
      this.reactiveDate.activeFrom,
      this.reactiveDate.activeTo,
    );
    this.populateAllUsers();
  },
  methods: {
    async onDailyUsersGraphBarClicked(
      event: any,
      chartContext: any,
      config: any,
    ) {
      const index = config.dataPointIndex;
      const date = new Date(this.options.xaxis!.categories[index]);
      const res = await activeUsersOnDate(date);
      if (!res.success) {
        this.activeUsersFilter = null;
        return;
      }
      this.activeUsersFilter = {
        date,
        userNames: new Set(res.data.map((u) => u.userName)),
      };
    },
    userRowClicked(row: any) {
      this.$router.push({ name: "user", params: { id: row.username } });
    },
    clearDateFilter() {
      this.activeUsersFilter = null;
    },
    async updateDailyUsersGraph(from: Date, to: Date) {
      this.isChartLoaded = false;

      const res = await dailyActiveUsers(from, to);
      if (!res.success) {
        return this.$bvToast.toast(res.message, {
          title: "Error loading daily active users",
          variant: "danger",
        });
      }
      const data = res.data;
      if (data.length == 0)
        console.warn("Warning... no active user data retrieved.");
      const dateLabels = Object.keys(data);
      const nUsersPerDay = Object.values(data);

      this.series[0].data = nUsersPerDay;
      this.options.xaxis!.categories = dateLabels;
      this.isChartLoaded = true;
    },
    async populateAllUsers() {
      const res = await getUsers();
      if (!res.success) {
        return this.$bvToast.toast(res.message, {
          title: "Error loading users",
          variant: "danger",
        });
      }
      this.allUsers = res.data;
    },
    onChangeDate(
      value: { activeFrom: Date; activeTo: Date },
      _oldValue: { activeFrom: Date; activeTo: Date },
    ) {
      this.updateDailyUsersGraph(value.activeFrom, value.activeTo);
    },
  },
});
</script>

<style lang="less">
h1 {
  padding-top: 50px;
}

#date-container {
  display: flex;
  width: 500px;
  margin-left: auto;
  margin-top: -44px;
  justify-content: space-between;
}
</style>
