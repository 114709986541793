import { assertUnreachableAggressive } from "../../../../common/src/lib/utils";
import { ProblemSeverity } from "./types";

export enum ProblemCategory {
  Performance = "Boost performance",
  BrokenEntity = "Broken Entity",
  MissingValue = "Missing Value",
  InvalidPropertyValue = "Invalid Value",
}

export function getCategoryDescription(category: ProblemCategory): string {
  switch (category) {
    case ProblemCategory.Performance:
      return "Performance issue has been detected.";
    case ProblemCategory.BrokenEntity:
      return "An entity appears to be having issues. we recommend deleting and recreating it";
    case ProblemCategory.MissingValue:
      return "Some properties are missing fields";
    case ProblemCategory.InvalidPropertyValue:
      return "Some properties have invalid options";
    default:
      assertUnreachableAggressive(category);
  }
}

export function getCategorySeverity(
  category: ProblemCategory,
): ProblemSeverity {
  switch (category) {
    case ProblemCategory.Performance:
      return ProblemSeverity.Info;
    case ProblemCategory.BrokenEntity:
      return ProblemSeverity.Error;
    case ProblemCategory.MissingValue:
      return ProblemSeverity.Error;
    case ProblemCategory.InvalidPropertyValue:
      return ProblemSeverity.Error;
    default:
      assertUnreachableAggressive(category);
  }
}

export function getCategoryHelpArticle(
  category: ProblemCategory,
): string | undefined {
  switch (category) {
    case ProblemCategory.Performance:
      return ProblemSeverity.Info;
    case ProblemCategory.BrokenEntity:
    case ProblemCategory.MissingValue:
    case ProblemCategory.InvalidPropertyValue:
      return undefined;
    default:
      assertUnreachableAggressive(category);
  }
}
