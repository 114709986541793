export enum FieldType {
  Text = "text",
  TextArea = "textarea",
  Number = "number",
  Color = "color",
  Choice = "choice",
  FlowSystemChoice = "flow-system-choice",
  // Intended as a "Catalog or Marketplace" item, as in the future, everything in catalog will be in marketplace.
  MarketplaceChoice = "marketplace-choice",
  HeatLossMaterialChoice = "heatloss-material-choice",
  Rotation = "rotation",
  Boolean = "boolean",
  TwoPointScale = "two-point-scale",
  Title = "title",
  Image = "advert",
  Button = "button",
  Tabs = "tabs",
  Divider = "divider",
  SettingNumberFieldBind = "setting-number-bind",
  ChoiceSelect = "choice-select",
  FittingModel = "fittings-model",
  EntityPicker = "entity-picker",
  DisplayTextOnly = "display-text-only",

  // Customs must be overridden with a vue template, like in RiserProperties.vue
  Custom = "custom",
}
