import Flatten from "@flatten-js/core";
import * as TM from "transformation-matrix";
import { cloneSimple } from "../../lib/utils";
import { BackgroundEntity } from "../document/entities/background-entity";
import { EntityType } from "../document/entities/types";
import CoreBaseBackedObject from "./lib/coreBaseBackedObject";

export type CoreBackgroundImageTagMap = {
  background_image: CoreBackgroundImage;
};

export default class CoreBackgroundImage extends CoreBaseBackedObject<BackgroundEntity> {
  type: EntityType.BACKGROUND_IMAGE = EntityType.BACKGROUND_IMAGE;

  get refPath(): string {
    return `${this.entity.type}`;
  }

  getHash(): string {
    const key = this.entity.center.x + " " + this.entity.center.y;
    // const hash = createHash("sha256");
    // hash.update(key.toString());
    // return hash.digest("hex");
    return key;
  }

  get position() {
    return TM.transform(
      TM.translate(this.entity.center.x, this.entity.center.y),
      TM.rotateDEG(this.entity.rotation),
      TM.scale(this.entity.scaleFactor),
    );
  }

  getCoreNeighbours() {
    return [];
  }

  get boundary() {
    return this.entity.crop;
  }

  get filledEntity(): BackgroundEntity {
    return cloneSimple(this.entity);
  }

  get shape() {
    const a = this.toWorldCoord({ x: this.boundary.x, y: this.boundary.y });
    const b = this.toWorldCoord({
      x: this.boundary.x + this.boundary.w,
      y: this.boundary.y,
    });
    const c = this.toWorldCoord({
      x: this.boundary.x,
      y: this.boundary.y + this.boundary.h,
    });
    const d = this.toWorldCoord({
      x: this.boundary.x + this.boundary.w,
      y: this.boundary.y + this.boundary.h,
    });

    const shape = new Flatten.Polygon();
    shape.addFace([a, b, c, d].map((p) => Flatten.point(p.x, p.y)));
    return shape;
  }
}
