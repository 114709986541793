import { AxiosError, isAxiosError } from "axios";
import { Failure } from "../../../common/src/api/document/types";
import { Logger } from "../../../common/src/lib/logger";
import { NullablePrimitive } from "../../../common/src/lib/primitive";
import { SentryError } from "../../../common/src/lib/sentry-error";

const SENTRY_API_ERROR_TYPE = "sentry-api";

/**
 * A wrapper for axios errors to give sentry more context
 */
export class SentryApiError extends SentryError {
  errorType = SENTRY_API_ERROR_TYPE;

  constructor(
    message: string,
    error: AxiosError,
    tags: Record<string, NullablePrimitive> = {},
    extras: Record<string, any> = {},
  ) {
    super(
      `${message}: ${(error.response?.data as any)?.message ?? error.message}`,
      {
        ...tags,
        status: error.response?.status,
        url: error.request?.url,
        method: error.request?.method,
      },
      extras,
    );
  }
}

export function handleApiError(
  message: string,
  error: any,
  tags: Record<string, NullablePrimitive> = {},
  extras: Record<string, any> = {},
): Failure {
  if (isAxiosError(error)) {
    Logger.error(new SentryApiError(message, error, tags, extras));
    return { success: false, message: error.response?.data?.message };
  } else if (error instanceof Error) {
    Logger.error(SentryError.wrapError(message, error, tags, extras));
    return { success: false, message: error.message };
  } else if (typeof error === "string") {
    Logger.error(new SentryError(`${message}: ${error}`, tags, extras));
    return { success: false, message: error };
  } else {
    Logger.error(
      new SentryError(`${message}: Unknown`, tags, {
        ...extras,
        error: JSON.stringify(error),
      }),
    );
    return { success: false, message: error };
  }
}
