import { isNotNullAndUndefined } from "../../../../common/src/lib/utils";
import { getGPUProblem } from "./performance";
import { getValidationProblems } from "./property-validation";
import { Problem } from "./types";

/**
 * Calculates what the current problems with the project are
 *
 * Future thought: This might be better served in a service worker.
 */
export async function getProjectProblems(): Promise<Problem[]> {
  const asyncResults = await Promise.all([getGPUProblem()]);

  const syncResults = [...getValidationProblems()];

  return [...asyncResults, ...syncResults].filter(isNotNullAndUndefined);
}
