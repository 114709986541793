import { SubscriptionState } from "./types";

const state: SubscriptionState = {
  isLoaded: false,
  activeSeats: 0,
  autodeskSeats: 0,
  details: [],
};

export default state;
