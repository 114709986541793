import { FeatureAccess } from "../../../../models/FeatureAccess";
import { CoreContext } from "../../../calculations/types";
import { RoomRoomEntity } from "../rooms/room-entity";

export function isRoomLoopLayoutFrozen(
  entity: RoomRoomEntity,
  featureAccess: FeatureAccess,
): boolean {
  return (
    featureAccess.fullUnderfloorHeatingLoops &&
    entity.room.underfloorHeating.freezeLayout
  );
}

export function freezeRoomLayout(context: CoreContext, entity: RoomRoomEntity) {
  const calc = context.globalStore.getOrCreateLiveCalculation(entity);
  if (!calc) return;

  entity.room.underfloorHeating.freezeLayout = true;
  entity.room.underfloorHeating.frozenLoopsStats =
    calc.underfloorHeating.loopsStats;
}

export function freezeAllRoomLayouts(context: CoreContext) {
  if (!context.featureAccess.fullUnderfloorHeatingLoops) return;

  for (const o of context.globalStore.find("room.room")) {
    freezeRoomLayout(context, o.entity);
  }
}

export function unfreezeAllRoomLayouts(context: CoreContext) {
  for (const o of context.globalStore.find("room.room")) {
    unfreezeRoomLayout(o.entity);
  }
}

export function unfreezeRoomLayout(entity: RoomRoomEntity) {
  entity.room.underfloorHeating.freezeLayout = false;
}

/**
 * Checks if all rooms have a frozen layout.
 *
 * This function iterates through all available rooms and verifies if their layout is frozen.
 *
 * @returns {boolean}
 * - Returns `true` if all rooms have a frozen layout.
 * - Returns `false` if any room has a non-frozen layout or if no rooms exist.
 */
export function roomLayoutFreezeStatus(context: CoreContext) {
  let roomExists = false;
  for (const o of context.globalStore.find("room.room")) {
    roomExists = true;
    if (!isRoomLoopLayoutFrozen(o.entity, context.featureAccess)) {
      return false;
    }
  }
  return roomExists;
}
