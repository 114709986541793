import Flatten from "@flatten-js/core";
import { Logger } from "../../../../lib/logger";
import { isParallelRad } from "../../../../lib/mathUtils/mathutils";
import { RoomCalculation } from "../../../document/calculations-objects/room-calculation";
import { CoilCoord3D } from "../../underfloor-heating/coil-coords";
import { UFH_BELOW_FLOOR_HEIGHT_MM } from "../../underfloor-heating/consts";
import { LoopSite } from "../../underfloor-heating/underfloor-heating";
import {
  IntentionNode,
  LoopGenerator,
  foreachIntention,
} from "./loop-generator";
import { LoopObstacle } from "./loop-obstacles";
import {
  SpiralExtension,
  SpiralGenerator,
  serializeSpiralExtension,
} from "./spiral-generator";

const MAX_LINEAR_EXTENSION_ITERATIONS = 20;
const MIN_LINEAR_WALL_FOLLOW_LENGTH_RADII = 2;

export type LinearExtension = {
  type: "side";
  startCoord: Flatten.Point;
  direction: Flatten.Vector;
  intention: LinearIntentionNode;
  // How much closer we had to go within the obstacle radii
  // in order to make it.
  firstLength: number;
  intentionNumber: number;
  wallSide: "cw" | "ccw";
};

export type LinearIntentionNode = IntentionNode & {
  type: "vertical" | "ground";
  softPaddingPossible?: boolean;
};

export class LinearGenerator {
  static generateLinearLoop(
    obstacles: LoopObstacle[],
    site: LoopSite,
    transitSpacingMM: number,
    loopSpacingMM: number,
    normal: Flatten.Vector,
    center: Flatten.Point,
    fromManifold: CoilCoord3D[],
    toManifold: CoilCoord3D[],
    debugObj?: RoomCalculation["debug"],
  ) {
    LoopGenerator.heatedAreaEntranceHack(site, normal, center);

    const { intentionTree, debug } = this.generateLinearIntentions(
      obstacles,
      Flatten.point(center.x, center.y),
      Flatten.vector(normal.x, normal.y),
      loopSpacingMM,
      transitSpacingMM,
      site.underfloorHeating.loopDirectionDEG,
      debugObj,
    );

    let i = 0;
    foreachIntention(intentionTree, (from, to) => {
      i += 1;
      debugObj?.push({
        color: "#FF77" + ((i * 0x11) % 0xff).toString(16).padStart(2, "0"),
        chain: [from.coord, to.coord],
        text: i.toString(),
        dash: [1, i % 5],
        role: "loop",
      });
    });

    const incidentLines: Flatten.Line[] =
      LoopGenerator.getIncidentLines(intentionTree);

    // TODO: Abstract together these postprocessing steps across
    // the three loops which are relatively similar, but will do after
    // merging in case of conflicts with David's detailed loop changes.

    // Remove consecutive parallel lines
    for (let i = incidentLines.length - 1; i > 0; i--) {
      if (incidentLines[i].parallelTo(incidentLines[i - 1])) {
        incidentLines.splice(i, 1);
      }
    }

    let roomLoop: CoilCoord3D[] = [];

    for (let i = 0; i < incidentLines.length - 1; i++) {
      const intersection = incidentLines[i].intersect(incidentLines[i + 1]);

      if (intersection.length > 0) {
        roomLoop.push({
          x: intersection[0].x,
          y: intersection[0].y,
          z: UFH_BELOW_FLOOR_HEIGHT_MM,
          turnRadiusMM: loopSpacingMM / 2,
        });
      }
    }

    // remove duplicates
    for (let i = roomLoop.length - 1; i > 0; i--) {
      const curr = roomLoop[i];
      const prev = roomLoop[i - 1];
      const currP = Flatten.point(curr.x, curr.y);
      const prevP = Flatten.point(prev.x, prev.y);

      if (currP.distanceTo(prevP)[0] < 10) {
        roomLoop.splice(i, 1);
      }
      if (currP.distanceTo(prevP)[0] < 50) {
        curr.maxRadiusMM = 10;
        curr.turnRadiusMM = 10;
        prev.maxRadiusMM = 10;
        prev.turnRadiusMM = 10;
      }
    }

    return LoopGenerator.smoothOutWholeLoop(
      {
        roomLoop,
        toManifold,
        fromManifold,
      },
      {
        minRadiusMM: site.underfloorHeating.minBendRadiusMM!,
        avoidCenterBulb: true,
        loopSpacingMM,
        correctLongKnots: true,
      },
    );
  }

  static generateLinearIntentions(
    obstacles: LoopObstacle[],
    center: Flatten.Point,
    normal: Flatten.Vector,
    loopSpacingMM: number,
    transitSpacingMM: number,
    loopDirectionDEG: number | null,
    debugObj?: RoomCalculation["debug"],
  ): {
    intentionTree: IntentionNode;
    debug: {
      color: string;
      chain: Flatten.Point[];
    }[];
  } {
    // loopDirectionDEG: increase=clockwise, 90=270=horizontal, 0=180=360=vertical

    // Follow wall initially. Both directions need to be covered
    // so follow whichever one.

    // followWallBothSidesFromStart with condition "angle" is perfect for this.
    // Only follow the wall if the wall is on the "correct" side wrt directionVector

    let { directionVector, paths } = LoopGenerator.followWallBothSidesFromStart(
      obstacles,
      center,
      normal,
      loopSpacingMM,
      transitSpacingMM,
      loopDirectionDEG,
      "angle",
      debugObj,
    );

    if (directionVector.dot(normal) < 0) {
      directionVector = directionVector.multiply(-1);
    }

    // We want to span both ways

    const intentionTree: LinearIntentionNode = {
      coord: center,
      type: "ground",
      next: [],
      spacingMM: transitSpacingMM,
    };

    const filteredPaths = paths.filter((p) => p.path.length > 1);

    let horizontalPointList: LinearIntentionNode[] = [];

    if (
      filteredPaths.length === 2 &&
      Math.abs(
        filteredPaths[0].startOffsetMM - filteredPaths[1].startOffsetMM,
      ) >= 0.1
    ) {
      filteredPaths.sort((a, b) => a.startOffsetMM - b.startOffsetMM);
      // [1] is the longer one
      const cross1 = normal.cross(directionVector);
      const cross2 = Flatten.vector(
        filteredPaths[1].startPoint,
        filteredPaths[1].path[1],
      ).cross(directionVector);
      if (Math.sign(cross1) !== Math.sign(cross2)) {
        filteredPaths.pop();
      }
    }

    if (filteredPaths.length === 0) {
      let goodSide: "cw" | "ccw" | null = null;
      if (
        paths.length === 2 &&
        Math.abs(paths[0].startOffsetMM - paths[1].startOffsetMM) >= 0.1
      ) {
        paths.sort((a, b) => a.startOffsetMM - b.startOffsetMM);
        goodSide = paths[0].side;
      } else if (paths.length === 0) {
        // TODO: It is insane. Assume it doesn't happen.
        Logger.error("It is insane. Assume it doesn't happen.");
      } else if (paths.length === 1) {
        goodSide = paths[0].side;
      } else {
        const nextNode: LinearIntentionNode = {
          coord: paths[0].startPoint,
          next: [],
          type: "ground",
          spacingMM: loopSpacingMM,
        };
        intentionTree.next.push(nextNode);
        for (const [dirMul, type] of [
          [1, "vertical"],
          [-1, "ground"],
        ] as [number, "vertical" | "ground"][]) {
          // trying going both up and down along the vertical wall

          const tte = LoopGenerator.traceToEnd({
            curr: paths[0].startPoint,
            radius: loopSpacingMM,

            obstacles,
            direction: directionVector.multiply(dirMul),
            onlyBlocks: true,

            hitLenienceMM: loopSpacingMM * 0.51,

            minLengthMM: loopSpacingMM * 0.51,
          });

          if (tte && tte.type !== "miss") {
            nextNode.next.push({
              coord: tte.point,
              next: [],
              type,
              spacingMM: loopSpacingMM,
            });
            if (type === "ground") nextNode.type = "vertical";
          }
        }
      }
      if (goodSide != null) {
        directionVector =
          goodSide === "cw" ? normal.rotate90CCW() : normal.rotate90CW();
        const nextNode: LinearIntentionNode = {
          coord: paths[0].startPoint,
          next: [],
          type: "ground",
          spacingMM: loopSpacingMM,
        };
        intentionTree.next.push(nextNode);
        const tte = LoopGenerator.traceToEnd({
          curr: paths[0].startPoint,
          radius: loopSpacingMM,

          obstacles,
          direction: directionVector.multiply(-1),
          onlyBlocks: true,

          hitLenienceMM: loopSpacingMM * 0.51,

          minLengthMM: loopSpacingMM * 0.51,
        });
        if (tte && tte.type !== "miss") {
          nextNode.next.push({
            coord: tte.point,
            next: [],
            type: "ground",
            spacingMM: loopSpacingMM,
          });
          nextNode.type = "vertical";
        }
      }
    } else if (filteredPaths.length === 1) {
      let nextNode: LinearIntentionNode = {
        coord: filteredPaths[0].startPoint,
        next: [],
        type: "ground",
        spacingMM: loopSpacingMM,
      };
      horizontalPointList.push(nextNode);
      intentionTree.next.push(nextNode);
      for (let i = 1; i < filteredPaths[0].path.length; i++) {
        const newNode: LinearIntentionNode = {
          coord: filteredPaths[0].path[i],
          next: [],
          type: "ground",
          spacingMM: loopSpacingMM,
        };
        horizontalPointList.push(newNode);
        nextNode.next.push(newNode);
        nextNode = newNode;
      }
      if (filteredPaths[0].stopReason === "soft angle") {
        nextNode.softPaddingPossible = true;
        nextNode.color = "#00FFFF";
      }
      if (
        Flatten.vector(
          horizontalPointList[0].coord,
          horizontalPointList[1].coord,
        ).cross(directionVector) < 0
      ) {
        horizontalPointList.reverse();
      }
    } else {
      // length is 2.
      if (
        Math.abs(
          filteredPaths[0].startOffsetMM - filteredPaths[1].startOffsetMM,
        ) < 0.1
      ) {
        const sharedNode: LinearIntentionNode = {
          coord: filteredPaths[0].startPoint,
          next: [],
          type: "ground",
          spacingMM: loopSpacingMM,
        };
        intentionTree.next.push(sharedNode);
        const twoLists: LinearIntentionNode[][] = [[], []];
        for (let which = 0; which < 2; which++) {
          let nextNode: LinearIntentionNode = sharedNode;
          for (let i = 1; i < filteredPaths[which].path.length; i++) {
            const newNode: LinearIntentionNode = {
              coord: filteredPaths[which].path[i],
              next: [],
              type: "ground",
              spacingMM: loopSpacingMM,
            };
            twoLists[which].push(newNode);
            nextNode.next.push(newNode);
            nextNode = newNode;
          }
          if (filteredPaths[which].stopReason === "soft angle") {
            nextNode.softPaddingPossible = true;
            nextNode.color = "#00FFFF";
          }
        }
        horizontalPointList = twoLists[0]
          .reverse()
          .concat([sharedNode])
          .concat(twoLists[1]);
        if (
          Flatten.vector(
            horizontalPointList[0].coord,
            horizontalPointList[1].coord,
          ).cross(directionVector) < 0
        ) {
          horizontalPointList.reverse();
        }
      } else {
        const child: LinearIntentionNode = {
          coord: filteredPaths[0].startPoint,
          next: [],
          type: "ground",
          spacingMM: loopSpacingMM,
        };
        const grandChild: LinearIntentionNode = {
          coord: filteredPaths[1].startPoint,
          next: [],
          type: "ground",
          spacingMM: loopSpacingMM,
        };
        intentionTree.next.push(child);
        child.next.push(grandChild);
        const twoLists: LinearIntentionNode[][] = [[child], [grandChild]];
        for (const [which, node] of [
          [0, child],
          [1, grandChild],
        ] as [number, LinearIntentionNode][]) {
          let nextNode: LinearIntentionNode = node;
          for (let i = 1; i < filteredPaths[which].path.length; i++) {
            const newNode: LinearIntentionNode = {
              coord: filteredPaths[which].path[i],
              next: [],
              type: "ground",
              spacingMM: loopSpacingMM,
            };
            twoLists[which].push(newNode);
            nextNode.next.push(newNode);
            nextNode = newNode;
          }
          if (filteredPaths[which].stopReason === "soft angle") {
            nextNode.softPaddingPossible = true;
            nextNode.color = "#00FFFF";
          }
        }
        horizontalPointList = twoLists[0].reverse().concat(twoLists[1]);
        if (
          Flatten.vector(
            horizontalPointList[0].coord,
            horizontalPointList[1].coord,
          ).cross(directionVector) < 0
        ) {
          horizontalPointList.reverse();
        }
      }
    }

    if (horizontalPointList.length > 0) {
      const rayStartPoints: LinearIntentionNode[] = [];
      const firstStep: Flatten.Point = intentionTree.next[0].coord;
      for (let spacingOrder = -100; spacingOrder <= 100; spacingOrder++) {
        // The vertical line will generated in the order
        // 0 means middle.
        // negative means left.
        // positive means right.
        // They are all fixed gapping and can be directly calculated.
        const passThrough = firstStep.translate(
          directionVector
            .rotate90CCW()
            .multiply(spacingOrder * 2 * loopSpacingMM),
        );
        const currentLine = Flatten.line(
          passThrough,
          passThrough.translate(directionVector),
        );
        let found = false;
        for (const node of horizontalPointList) {
          if (currentLine.distanceTo(node.coord)[0] < 5) {
            rayStartPoints.push(node);
            found = true;
            break;
          }
        }
        if (!found) {
          for (let i = 0; i < horizontalPointList.length - 1; i++) {
            const seg = Flatten.segment(
              horizontalPointList[i].coord,
              horizontalPointList[i + 1].coord,
            );
            const intersect = currentLine.intersect(seg);
            if (intersect.length > 0) {
              const [parent, child] = horizontalPointList[i].next.includes(
                horizontalPointList[i + 1],
              )
                ? [horizontalPointList[i], horizontalPointList[i + 1]]
                : [horizontalPointList[i + 1], horizontalPointList[i]];
              const toInsert: LinearIntentionNode = {
                coord: intersect[0],
                next: [child],
                type: "ground",
                spacingMM: loopSpacingMM,
              };
              parent.next[parent.next.findIndex((n) => n === child)] = toInsert;
              found = true;
              rayStartPoints.push(toInsert);
              horizontalPointList.splice(i + 1, 0, toInsert);
              break;
            }
          }
        }
      }
      for (const node of rayStartPoints) {
        const tte = LoopGenerator.traceToEnd({
          curr: node.coord,
          radius: loopSpacingMM,
          obstacles,
          direction: directionVector,
          onlyBlocks: true,
          hitLenienceMM: loopSpacingMM * 0.51,
          minLengthMM: loopSpacingMM * 0.51,
        });
        if (tte && tte.type !== "miss") {
          node.next.push({
            coord: tte.point,
            next: [],
            type: "vertical",
            spacingMM: loopSpacingMM,
          });
        }
      }
    }

    foreachIntention(intentionTree, (from, to) => {
      if (to.softPaddingPossible) {
        to.softPaddingPossible = false; // We only try once, whether successful or not.
        const tte = LoopGenerator.traceToEnd({
          curr: from.coord,
          radius: loopSpacingMM,
          obstacles,
          direction: Flatten.vector(from.coord, to.coord).normalize(),
          onlyBlocks: true,
          hitLenienceMM: loopSpacingMM * 0.51,
          minLengthMM: loopSpacingMM * 0.51,
        });
        const plannedLength =
          ((loopSpacingMM * 2) /
            Math.abs(
              directionVector
                .rotate90CCW()
                .dot(Flatten.vector(from.coord, to.coord)),
            )) *
          from.coord.distanceTo(to.coord)[0];
        if (tte && tte.length >= plannedLength) {
          to.coord = from.coord.translate(
            Flatten.vector(from.coord, to.coord)
              .normalize()
              .multiply(plannedLength),
          );
          for (const [dirMul, type] of [
            [1, "vertical"],
            [-1, "ground"],
          ] as [number, "vertical" | "ground"][]) {
            const tte = LoopGenerator.traceToEnd({
              curr: to.coord,
              radius: loopSpacingMM,
              obstacles,
              direction: directionVector.multiply(dirMul),
              onlyBlocks: true,
              hitLenienceMM: loopSpacingMM * 0.51,
              minLengthMM: loopSpacingMM * 0.51,
            });
            if (tte && tte.type !== "miss") {
              to.next.push({
                coord: tte.point,
                next: [],
                type,
                spacingMM: loopSpacingMM,
              });
              if (type === "ground") to.type = "vertical";
            }
          }
        }
      }
    });

    foreachIntention(intentionTree, (from, to) => {
      obstacles.push({
        segment: Flatten.segment(from.coord, to.coord),
        radiusMM: loopSpacingMM,
      });
    });

    const seenExtensions = new Set<string>();

    const compareExtensions = (a: SpiralExtension, b: SpiralExtension) => {
      if (a.intentionNumber !== b.intentionNumber) {
        // Higher is better - we want to backtrack and start close to the end.
        return b.intentionNumber - a.intentionNumber;
      }
      if (a.firstLength !== b.firstLength) {
        // Longer travel is better.
        return b.firstLength - a.firstLength;
      }
      return 0;
    };

    for (let _ = 0; _ < MAX_LINEAR_EXTENSION_ITERATIONS; _++) {
      const extensions = this.getLinearExtensions(
        intentionTree,
        obstacles,
        loopSpacingMM,
        directionVector,
        seenExtensions,
      ).filter((ext) => {
        const serialized = serializeSpiralExtension(ext);
        if (seenExtensions.has(serialized)) {
          return false;
        }
        return true;
      });
      extensions.sort(compareExtensions);

      if (extensions.length === 0) {
        break;
      }

      for (const ext of extensions) {
        // The extensions after the `break` after a successful extension are not added to seenExtensions
        // This is of course intended, because we haven't even tried them yet.
        seenExtensions.add(serializeSpiralExtension(ext));
        const followWallResult = LoopGenerator.followWall(
          obstacles,
          ext.startCoord,
          ext.direction,
          loopSpacingMM,
          directionVector,
          ext.wallSide,
          "angle",
        );
        if (
          followWallResult.path.length > 0 &&
          Math.abs(
            Flatten.vector(
              ext.startCoord,
              followWallResult.path[followWallResult.path.length - 1],
            ).dot(directionVector.rotate90CCW()),
          ) >=
            loopSpacingMM * 2
        ) {
          const newIntention: LinearIntentionNode = {
            coord: ext.intention.coord,
            next: ext.intention.next,
            spacingMM: loopSpacingMM,
            type: "vertical",
          };
          ext.intention.next = [newIntention];
          ext.intention.coord = ext.startCoord;

          const horizontalPointList: LinearIntentionNode[] = [ext.intention];

          let lastIntention = ext.intention;
          for (const point of followWallResult.path) {
            const nextNode: LinearIntentionNode = {
              coord: point,
              next: [],
              spacingMM: loopSpacingMM,
              type: "ground",
            };
            horizontalPointList.push(nextNode);
            lastIntention.next.push(nextNode);
            lastIntention = nextNode;
          }
          if (followWallResult.stopReason === "soft angle") {
            lastIntention.softPaddingPossible = true;
            lastIntention.color = "#00FFFF";
          }

          let singleWidth = directionVector
            .rotate90CCW()
            .normalize()
            .multiply(2 * loopSpacingMM);

          if (singleWidth.dot(ext.direction) < 0) {
            singleWidth = singleWidth.multiply(-1);
          }

          const rayStartPoints: LinearIntentionNode[] = [];

          for (let spacingOrder = 1; spacingOrder <= 100; spacingOrder++) {
            // TODO: draw a vertical line
            // intersect the horizontal point list
            // go up!
            const passThrough: Flatten.Point = ext.startCoord.translate(
              singleWidth.multiply(spacingOrder),
            );
            const currentLine = Flatten.line(
              passThrough,
              passThrough.translate(directionVector),
            );
            let found = false;
            for (const node of horizontalPointList) {
              if (currentLine.distanceTo(node.coord)[0] < 5) {
                rayStartPoints.push(node);
                found = true;
                break;
              }
            }
            if (!found) {
              for (let i = 0; i < horizontalPointList.length - 1; i++) {
                const seg = Flatten.segment(
                  horizontalPointList[i].coord,
                  horizontalPointList[i + 1].coord,
                );
                const intersect = currentLine.intersect(seg);
                if (intersect.length > 0) {
                  const [parent, child] = horizontalPointList[i].next.includes(
                    horizontalPointList[i + 1],
                  )
                    ? [horizontalPointList[i], horizontalPointList[i + 1]]
                    : [horizontalPointList[i + 1], horizontalPointList[i]];
                  const toInsert: LinearIntentionNode = {
                    coord: intersect[0],
                    next: [child],
                    type: "ground",
                    spacingMM: loopSpacingMM,
                  };
                  parent.next[parent.next.findIndex((n) => n === child)] =
                    toInsert;
                  found = true;
                  rayStartPoints.push(toInsert);
                  horizontalPointList.splice(i + 1, 0, toInsert);
                  break;
                }
              }
            }
          }
          for (const node of rayStartPoints) {
            const tte = LoopGenerator.traceToEnd({
              curr: node.coord,
              radius: loopSpacingMM,
              obstacles,
              direction: directionVector,
              onlyBlocks: true,
              hitLenienceMM: loopSpacingMM * 0.51,
              minLengthMM: loopSpacingMM * 0.51,
            });
            if (tte && tte.type !== "miss") {
              node.next.push({
                coord: tte.point,
                next: [],
                type: "vertical",
                spacingMM: loopSpacingMM,
              });
            }
          }
          foreachIntention(ext.intention, (from, to) => {
            if (to.softPaddingPossible) {
              to.softPaddingPossible = false; // We only try once, whether successful or not.
              const tte = LoopGenerator.traceToEnd({
                curr: from.coord,
                radius: loopSpacingMM,
                obstacles,
                direction: Flatten.vector(from.coord, to.coord).normalize(),
                onlyBlocks: true,
                hitLenienceMM: loopSpacingMM * 0.51,
                minLengthMM: loopSpacingMM * 0.51,
              });
              const plannedLength =
                ((loopSpacingMM * 2) /
                  Math.abs(
                    directionVector
                      .rotate90CCW()
                      .dot(Flatten.vector(from.coord, to.coord)),
                  )) *
                from.coord.distanceTo(to.coord)[0];
              if (tte && tte.length >= plannedLength) {
                to.coord = from.coord.translate(
                  Flatten.vector(from.coord, to.coord)
                    .normalize()
                    .multiply(plannedLength),
                );
                for (const [dirMul, type] of [
                  [1, "vertical"],
                  [-1, "ground"],
                ] as [number, "vertical" | "ground"][]) {
                  const tte = LoopGenerator.traceToEnd({
                    curr: to.coord,
                    radius: loopSpacingMM,
                    obstacles,
                    direction: directionVector.multiply(dirMul),
                    onlyBlocks: true,
                    hitLenienceMM: loopSpacingMM * 0.51,
                    minLengthMM: loopSpacingMM * 0.51,
                  });
                  if (tte && tte.type !== "miss") {
                    to.next.push({
                      coord: tte.point,
                      next: [],
                      type,
                      spacingMM: loopSpacingMM,
                    });
                    if (type === "ground") to.type = "vertical";
                  }
                }
              }
            }
          });

          LoopGenerator.sanitizeIntentionTree(ext.intention);
          foreachIntention(ext.intention, (from, to) => {
            obstacles.push({
              segment: Flatten.segment(from.coord, to.coord),
              radiusMM: loopSpacingMM,
            });
          });
          seenExtensions.add(serializeSpiralExtension(ext));
          break;
        }
      }
    }

    for (let keepRemoving = true; keepRemoving; ) {
      // We have to use the ugly keepRemoving strategy here
      // because for loop of array breaks if element is deleted during iteration
      // Maybe editing `foreachIntention` to allow deletion would be better.
      keepRemoving = false;
      foreachIntention(intentionTree, (from, to) => {
        if (
          !keepRemoving &&
          to.type === "ground" &&
          to.next.length === 0 &&
          Math.abs(
            Flatten.vector(from.coord, to.coord).dot(
              directionVector.rotate90CCW().normalize(),
            ),
          ) <
            loopSpacingMM * 2 &&
          !isParallelRad(
            Flatten.vector(from.coord, to.coord).angleTo(directionVector),
            0,
            0.05,
          ) &&
          !intentionTree.next.includes(from)
        ) {
          keepRemoving = true;
          from.next = from.next.filter((n) => n !== to);
        }
      });
    }

    return {
      intentionTree: LoopGenerator.sanitizeIntentionTree(intentionTree),
      debug: [],
    };
  }

  static getLinearExtensions(
    intentionNode: LinearIntentionNode,
    obstacles: LoopObstacle[],
    loopSpacingMM: number,
    checkDirection: Flatten.Vector,
    seenExtensions: Set<string>,
  ): LinearExtension[] {
    const cwExtensions = SpiralGenerator.getSpiralExtensions(
      intentionNode,
      obstacles,
      loopSpacingMM,
      "cw",
      seenExtensions,
      false,
      checkDirection,
    );
    const ccwExtensions = SpiralGenerator.getSpiralExtensions(
      intentionNode,
      obstacles,
      loopSpacingMM,
      "ccw",
      seenExtensions,
      false,
      checkDirection,
    );

    return [
      // Reverse cw<=>ccw is not a mistake.
      ...cwExtensions.map((ext) => ({
        ...ext,
        intention: ext.intention as LinearIntentionNode,
        wallSide: "cw" as const,
      })),
      ...ccwExtensions.map((ext) => ({
        ...ext,
        intention: ext.intention as LinearIntentionNode,
        wallSide: "ccw" as const,
      })),
    ];
  }
}
