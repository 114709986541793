import { MutationTree } from "vuex";
import ProblemsState from "./types";

export const mutations: MutationTree<ProblemsState> = {
  toggle(state) {
    state.isProblemPanelOpen = !state.isProblemPanelOpen;
  },
  close(state) {
    state.isProblemPanelOpen = false;
  },
  setProblems(state, problems) {
    state.problems = problems;
  },
};
