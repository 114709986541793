import { Logger } from "../../lib/logger";
import {
  convertMeasurementSystem,
  Precision,
  Units,
  UnitsContext,
} from "../../lib/measurements";
import { SentryEntityError } from "../../lib/sentry-entity-error";
import { assertUnreachable } from "../../lib/utils";
import {
  PumpLookupTable,
  PumpTableData,
  PumpTankModelCapacityDependent,
  PumpTankModelCapacityIndependent,
} from "../catalog/types";
import { CoreObjectConcrete } from "../coreObjects";
import PlantCalculation from "../document/calculations-objects/plant-calculation";
import { addWarning } from "../document/calculations-objects/warnings";
import { UnitsParameters } from "../document/drawing";
import { fillPlantDefaults } from "../document/entities/plants/plant-defaults";
import PlantEntity, {
  PumpPlantEntity,
} from "../document/entities/plants/plant-entity";
import {
  PlantType,
  PumpConfiguration,
} from "../document/entities/plants/plant-types";
import {
  getPlantDatasheet,
  getPumpConfigurationName,
  isPlantPump,
  isPlantTank,
  isPumpPressureVariablyCalculated,
} from "../document/entities/plants/utils";
import { SystemNodeEntity } from "../document/entities/system-node-entity";
import { EntityType } from "../document/entities/types";
import CalculationEngine from "./calculation-engine";
import { TraceCalculation } from "./flight-data-recorder";
import { GlobalFDR } from "./global-fdr";
import {
  CoreContext,
  EdgeType,
  FlowNode,
  isPressureLossSuccess,
  PressureLossResult,
} from "./types";
import {
  findNextBestPumpConfig,
  getAcceptablePressureRange,
  IndexPath,
  trackBackPath,
} from "./utils";

export class PumpCalculations {
  @TraceCalculation("Sorting pumps into topological order for calculations")
  static topsortPumps(engine: CalculationEngine) {
    const childrenOf = new Map<string, Set<string>>();
    const numParents = new Map<string, number>();

    for (const o of engine.networkObjects()) {
      GlobalFDR.focusData([o.entity.uid]);
      if (o.entity.type !== EntityType.PLANT) {
        continue;
      }
      const entity = o.entity;

      if (!numParents.has(entity.uid)) {
        numParents.set(entity.uid, 0);
      }

      if (!isPumpPressureVariablyCalculated(entity)) {
        continue;
      }

      const outletNode = {
        connection: entity.uid,
        connectable: entity.plant.outletUid,
      };

      engine.flowGraph.dfs(outletNode, undefined, undefined, (e) => {
        if (e.value.type === EdgeType.PLANT_THROUGH) {
          // ignore other pumps and tanks that are themselves sized.
          const plant = engine.globalStore.getObjectOfTypeOrThrow(
            EntityType.PLANT,
            e.value.uid,
          );
          if (isPumpPressureVariablyCalculated(plant.entity)) {
            if (plant.entity.uid !== entity.uid) {
              if (!childrenOf.has(entity.uid)) {
                childrenOf.set(entity.uid, new Set());
              }
              childrenOf.get(entity.uid)!.add(plant.entity.uid);
              if (!numParents.has(plant.entity.uid)) {
                numParents.set(plant.entity.uid, 0);
              }
              numParents.set(
                plant.entity.uid,
                numParents.get(plant.entity.uid)! + 1,
              );

              return true;
            }
          }
        }
      });
    }

    return { childrenOf, numParents };
  }

  @TraceCalculation("Assigning pump duties")
  static assignPumpDuties(
    engine: CalculationEngine,
  ): Map<FlowNode, IndexPath[]> {
    const edge2PressureLoss = new Map<string, PressureLossResult>();
    const approximateNodePressureKPA = new Map<string, number>();
    const edgeFlowFrom = new Map<string, FlowNode>();
    engine.precomputePeakKPAPoints({
      ignoreCalculatedDefaults: true,
      allowApproximate: true,
      edge2PressureLoss: edge2PressureLoss,
      nodePressureKPA: approximateNodePressureKPA,
      edgeFlowFrom: edgeFlowFrom,
    });

    // To solve pumps that are nested within each other in the network, we need to
    // 1. Find all pumps and topsort them
    // 2. Assign duties to each pump in topsort order.
    //    a. Do not traverse down edges where flowing pressure is not causal (ie. tanks or static pressure items)
    //    b. Do not traverse down edges of pumps that are going to be calculated.
    const { childrenOf, numParents } = this.topsortPumps(engine);

    const workQueue: string[] = [];
    for (const [uid, _numParents] of numParents) {
      if (_numParents === 0) {
        workQueue.push(uid);
      }
    }

    interface PumpPotential {
      // Min pressure allowed
      minPressureKPA: number;
      maxPressureKPA: number;

      // The amount of pressure if the upstream calculated pump was set to 0,
      // and all static pressure restrictions were not present.
      // Invariant: it is always <= minPressureKPA, because if it was higher, then
      // minPressureKPA would be set to it.
      basePressureKPA: number;
      limitingEntityUid: string | null;
    }

    const pumpPotentials = new Map<string, PumpPotential>();
    const extraPressureAtPumpKPA = new Map<string, number>();
    let indexNodeCache: Map<FlowNode, IndexPath[]> = new Map();
    while (workQueue.length) {
      const frontUid = workQueue.shift()!;
      GlobalFDR.focusData([frontUid]);
      const o = engine.globalStore.getObjectOfType(EntityType.PLANT, frontUid);
      if (!o) {
        continue;
      }
      const entity = o.entity;

      let upstreamAmbiguous: string | null = null;
      let downstreamAmbiguous: string | null = null;

      for (const childUid of childrenOf.get(entity.uid) || []) {
        numParents.set(childUid, numParents.get(childUid)! - 1);
        if (numParents.get(childUid)! === 0) {
          workQueue.push(childUid);
        }
      }

      if (isPumpPressureVariablyCalculated(entity) && isPlantPump(entity)) {
        // We just need this for targetPressureKPA. Note that for this one the pressure values
        // will be incorrect because we are yet to calculate them.
        const beforeFilled = fillPlantDefaults(engine, entity) as typeof entity;

        let dutyNeededKPA: number | null = -Infinity;

        const outletNode = {
          connection: entity.uid,
          connectable: entity.plant.outletUid,
        };

        if (
          !upstreamAmbiguous &&
          !engine.isNodePressureValid.get(engine.serializeNode(outletNode))
        ) {
          upstreamAmbiguous = entity.uid;
        }

        if (isPlantTank(entity)) {
          pumpPotentials.set(engine.serializeNode(outletNode), {
            minPressureKPA: 0,
            maxPressureKPA: Infinity,
            basePressureKPA: 0,
            limitingEntityUid: null,
          });
        } else {
          const priorPumpPressure = extraPressureAtPumpKPA.get(entity.uid) || 0;
          const basePressureKPA =
            priorPumpPressure +
            (approximateNodePressureKPA.get(engine.serializeNode(outletNode)) ??
              0);

          pumpPotentials.set(engine.serializeNode(outletNode), {
            minPressureKPA:
              priorPumpPressure +
              (approximateNodePressureKPA.get(
                engine.serializeNode(outletNode),
              ) ?? 0),
            maxPressureKPA: Infinity,
            basePressureKPA,
            limitingEntityUid: null,
          });
        }

        const prevEntity = new Map<string, IndexPath>();
        const downstreamAmbiguousStack: string[] = [];

        engine.flowGraph.dfsRecursive(
          outletNode,
          (n) => {
            // Here, at each node, calculate and enforce the duty needed.
            const potential = pumpPotentials.get(engine.serializeNode(n))!;
            const o = engine.globalStore.get(
              n.connectable,
            ) as CoreObjectConcrete;

            const pointPressureKPA = approximateNodePressureKPA.get(
              engine.serializeNode(n),
            );

            const pressureRange = getAcceptablePressureRange({
              context: engine,
              entity: o.entity,
            });

            if (pressureRange) {
              if (downstreamAmbiguousStack.length && !downstreamAmbiguous) {
                downstreamAmbiguous = downstreamAmbiguousStack[0];
              }
              if (pointPressureKPA !== null) {
                if (pressureRange.minKPA > potential.maxPressureKPA) {
                  if (potential.limitingEntityUid) {
                    const o = engine.globalStore.ofTagOrThrow(
                      "calculatable",
                      potential.limitingEntityUid,
                    );
                    addWarning(
                      engine,
                      "PREVENTING_PUMP_FROM_REACHING_TARGET",
                      [o.entity],
                      {
                        replaceSameWarnings: true,
                      },
                    );
                    addWarning(
                      engine,
                      "PUMP_PREVENTED_FROM_REACHING_TARGET",
                      [entity],
                      {
                        replaceSameWarnings: true,
                        params: {
                          blockerUid: potential.limitingEntityUid,
                        },
                      },
                    );
                  }
                }

                if (pressureRange.minKPA > potential.minPressureKPA) {
                  const thisDutyNeededKPA = Math.max(
                    0,
                    Math.min(
                      pressureRange.minKPA +
                        beforeFilled.plant.targetPressureKPA!,
                      potential.maxPressureKPA,
                    ) - potential.basePressureKPA,
                  );

                  if (dutyNeededKPA !== null) {
                    if (thisDutyNeededKPA > dutyNeededKPA) {
                      const path: IndexPath[] = trackBackPath(
                        n,
                        prevEntity,
                        engine,
                      );

                      indexNodeCache.set(outletNode, path);
                    }
                    dutyNeededKPA = Math.max(dutyNeededKPA, thisDutyNeededKPA);
                  }
                }
              }
            }
          },
          undefined,
          (e) => {
            const expectedFrom = edgeFlowFrom.get(e.uid);
            if (
              !expectedFrom ||
              engine.serializeNode(e.from) !==
                engine.serializeNode(expectedFrom)
            ) {
              const o = engine.globalStore.get(e.from.connectable);
              if (!o || o.entity.type !== EntityType.SYSTEM_NODE) {
                return true;
              }
            }

            if (!engine.isEdgePressureLossValid.get(e.uid)) {
              downstreamAmbiguousStack.push(e.value.uid);
            }

            prevEntity.set(engine.flowGraph.sn(e.to), {
              node: e.from,
              value: e.value,
            });

            const edgePressureLoss = edge2PressureLoss.get(e.uid);
            if (!edgePressureLoss) {
              if (e.value.type === EdgeType.FITTING_FLOW) {
                // Fitting flow edge but we're traversing the wrong way.
                return true;
              }
            }

            const fromPotential = pumpPotentials.get(
              engine.serializeNode(e.from),
            )!;

            if (edgePressureLoss && isPressureLossSuccess(edgePressureLoss)) {
              const toPotential: PumpPotential = {
                minPressureKPA:
                  fromPotential.minPressureKPA -
                  edgePressureLoss.pressureLossKPA,
                maxPressureKPA:
                  fromPotential.maxPressureKPA -
                  edgePressureLoss.pressureLossKPA,
                basePressureKPA:
                  fromPotential.basePressureKPA -
                  edgePressureLoss.pressureLossKPA,
                limitingEntityUid: fromPotential.limitingEntityUid,
              };

              if (edgePressureLoss.minPressureKPA !== undefined) {
                if (
                  edgePressureLoss.minPressureKPA > toPotential.minPressureKPA
                ) {
                  toPotential.minPressureKPA = edgePressureLoss.minPressureKPA;
                }
              }

              if (edgePressureLoss.maxPressureKPA !== undefined) {
                if (
                  edgePressureLoss.maxPressureKPA < toPotential.maxPressureKPA
                ) {
                  toPotential.maxPressureKPA = edgePressureLoss.maxPressureKPA;
                  toPotential.limitingEntityUid = e.value.uid;
                }
              }

              pumpPotentials.set(engine.serializeNode(e.to), toPotential);
            } else {
              pumpPotentials.set(engine.serializeNode(e.to), fromPotential);
            }
            if (e.value.type === EdgeType.PLANT_THROUGH) {
              // ignore other pumps and tanks that are themselves sized.
              const plant = engine.globalStore.getObjectOfTypeOrThrow(
                EntityType.PLANT,
                e.value.uid,
              );
              if (isPumpPressureVariablyCalculated(plant.entity)) {
                return true;
              }
            }
          },
          (e) => {
            if (downstreamAmbiguousStack.at(-1) === e.value.uid) {
              downstreamAmbiguousStack.pop();
            }
          },
        );

        const pumpCalc = engine.globalStore.getOrCreateCalculation(entity);

        const filled = fillPlantDefaults(engine, entity) as typeof entity;

        if (dutyNeededKPA !== null) {
          pumpCalc.pumpDutyKPA = Math.max(0, dutyNeededKPA);
        }

        if (downstreamAmbiguous) {
          addWarning(engine, "PUMP_DOWNSTREAM_AMBIGUOUS", [entity], {
            params: {
              blockingUids: [downstreamAmbiguous as string],
            },
          });
        }

        if (upstreamAmbiguous) {
          addWarning(engine, "PUMP_UPSTREAM_AMBIGUOUS", [entity], {
            params: {
              blockingUids: [upstreamAmbiguous as string],
            },
          });
        }

        for (const child of childrenOf.get(entity.uid) ?? []) {
          const o = engine.globalStore.getObjectOfTypeOrThrow(
            EntityType.PLANT,
            child,
          );
          const pe = o.entity as PumpPlantEntity;
          const inletNode: FlowNode = {
            connectable: pe.inletUid!,
            connection: pe.uid,
          };
          const inletPotential = pumpPotentials.get(
            engine.serializeNode(inletNode),
          )!;

          const maxRealPressure = Math.min(
            inletPotential.maxPressureKPA,
            Math.max(
              inletPotential.minPressureKPA,
              inletPotential.basePressureKPA + (pumpCalc.pumpDutyKPA || 0),
            ),
          );
          extraPressureAtPumpKPA.set(
            child,
            maxRealPressure -
              (approximateNodePressureKPA.get(
                engine.serializeNode(inletNode),
              ) ?? 0),
          );
        }
      } else if (isPlantPump(entity)) {
        const pumpCalc = engine.globalStore.getOrCreateCalculation(entity);
        const filled = fillPlantDefaults(engine, entity) as typeof entity;
        switch (filled.plant.type) {
          case PlantType.PUMP:
            pumpCalc.pumpDutyKPA = filled.plant.pressureLoss.pumpPressureKPA;
            break;
          case PlantType.PUMP_TANK:
            pumpCalc.pumpDutyKPA = filled.plant.pressureLoss.staticPressureKPA;
            break;
          case PlantType.RO:
            break;
          default:
            assertUnreachable(filled.plant);
        }
      }
    }

    return indexNodeCache;
  }

  @TraceCalculation(
    "Setting pump duty fields based on calculated flow rates and pressures",
  )
  static setPumpDutyFields(
    engine: CalculationEngine,
    configuration: PumpConfiguration | null,
    pumpCalc: PlantCalculation,
    flowRateLS: number | null,
    hideExitPressure = false,
    model?: string,
  ) {
    pumpCalc.pumpFlowRateLS = flowRateLS;
    if (pumpCalc.pumpDutyKPA === null || flowRateLS === null) {
      return;
    }

    const [pressureUnits, duty] = convertMeasurementSystem(
      engine.drawing.metadata.units,
      Units.KiloPascals,
      pumpCalc.pumpDutyKPA,
      Precision.DISPLAY,
    );

    const [flowUnits, flowRate] = convertMeasurementSystem(
      engine.drawing.metadata.units,
      Units.LitersPerSecond,
      flowRateLS,
      Precision.DISPLAY,
    );

    const config = configuration
      ? `${getPumpConfigurationName(configuration)} - `
      : "";

    if (hideExitPressure) {
      pumpCalc.pumpDutyString = `${config}${flowRate} ${flowUnits} @ ${duty} ${pressureUnits}`;
    } else {
      const [, exitPressure] = convertMeasurementSystem(
        engine.drawing.metadata.units,
        Units.KiloPascals,
        pumpCalc.exitPressureKPA,
        Precision.DISPLAY,
      );

      pumpCalc.pumpDutyString = `${config}${flowRate} ${flowUnits} @ ${duty} ${pressureUnits} (${
        exitPressure == null ? "??" : exitPressure
      } ${pressureUnits} Outlet Pressure)`;
    }

    if (model) {
      pumpCalc.pumpDutyString = `${model} - ${pumpCalc.pumpDutyString}`;
    }
  }

  static getPumpDutyString(
    context: CoreContext,
    dutyKPA: number,
    flowRateLS: number,
  ) {
    const [pressureUnits, duty] = convertMeasurementSystem(
      context.drawing.metadata.units,
      Units.KiloPascals,
      dutyKPA,
      Precision.DISPLAY,
      UnitsContext.VENTILATION,
    );

    const [flowUnits, flowRate] = convertMeasurementSystem(
      context.drawing.metadata.units,
      Units.LitersPerSecond,
      flowRateLS,
      Precision.DISPLAY,
    );

    return `${flowRate} ${flowUnits} @ ${duty} ${pressureUnits}`;
  }

  @TraceCalculation("Determining pump models")
  static determinePumpModels(engine: CalculationEngine) {
    for (const o of engine.networkObjects()) {
      GlobalFDR.focusData([o.entity.uid]);
      if (o.entity.type !== EntityType.PLANT) {
        continue;
      }
      const entity = o.entity;

      const filled = fillPlantDefaults(engine, entity);

      if (filled.plant.type === PlantType.PUMP) {
        const pumpCalc = engine.globalStore.getOrCreateCalculation(entity);

        const outletCalc = engine.globalStore.getOrCreateCalculation(
          engine.globalStore.get(filled.plant.outletUid)
            .entity as SystemNodeEntity,
        );

        const flowRateLS = outletCalc.flowRateLS;
        this.setPumpDutyFields(
          engine,
          filled.plant.configuration!,
          pumpCalc,
          flowRateLS,
        );

        const datasheet = getPlantDatasheet(
          filled.plant,
          engine.catalog,
          true,
        )[0];

        if (!datasheet) {
          continue;
        }

        if (pumpCalc.pumpDutyKPA === null) {
          continue;
        }

        switch (datasheet.type) {
          case "dense-table":
          case "sparse-table":
            const pumpTable = datasheet.table[filled.plant.configuration!];
            if (!pumpTable || flowRateLS === null) {
              console.log(
                "No pump table for",
                filled.uid,
                filled.plant.manufacturer,
                filled.plant.configuration,
              );
              console.log(pumpTable, flowRateLS);
              console.log(outletCalc);
              continue;
            }

            const firstGoodPressureKPA = this.findFirstGoodPressureKPA(
              pumpTable,
              pumpCalc.pumpDutyKPA!,
            );

            if (firstGoodPressureKPA === undefined) {
              console.log(
                "No good pressure for",
                filled.uid,
                filled.plant.manufacturer,
                filled.plant.configuration,
                pumpCalc.pumpDutyKPA,
              );
              this.handlePumpWarning(
                engine,
                filled,
                filled.plant.configuration!,
                pumpCalc,
                datasheet,
                engine.drawing.metadata.units,
              );
              continue;
            }

            const { firstGoodFlowRate, row } = this.findFirstGoodFlowRate(
              pumpTable,
              firstGoodPressureKPA,
              flowRateLS,
            );

            if (firstGoodFlowRate === undefined) {
              console.log(
                "No good flow rate for",
                filled.uid,
                filled.plant.manufacturer,
                filled.plant.configuration,
                flowRateLS,
              );
              this.handlePumpWarning(
                engine,
                filled,
                filled.plant.configuration!,
                pumpCalc,
                datasheet,
                engine.drawing.metadata.units,
              );
              continue;
            }

            const modelId = row[firstGoodFlowRate];

            if (modelId && datasheet.sizes && datasheet.sizes[modelId]) {
              pumpCalc.widthMM = datasheet.sizes[modelId].widthMM;
              pumpCalc.depthMM = datasheet.sizes[modelId].heightMM;
            }

            console.log(
              "Pump model",
              modelId,
              filled.plant.manufacturer,
              filled.plant.configuration,
              pumpCalc.pressureDropKPA,
              flowRateLS,
            );
            console.log(row);
            pumpCalc.model = modelId || null;
            break;
          case "none":
            break;
          default:
            assertUnreachable(datasheet);
        }
      } else if (filled.plant.type === PlantType.PUMP_TANK) {
        const pumpCalc = engine.globalStore.getOrCreateCalculation(entity);
        const outletCalc = engine.globalStore.getOrCreateCalculation(
          engine.globalStore.get(filled.plant.outletUid)
            .entity as SystemNodeEntity,
        );
        const flowRateLS = outletCalc.flowRateLS;

        this.setPumpDutyFields(
          engine,
          filled.plant.configuration!,
          pumpCalc,
          flowRateLS,
        );

        const tankData =
          engine.catalog.pumpTank.datasheet[filled.plant.manufacturer!];
        if (!tankData) {
          console.log(
            "No tank data for",
            filled.uid,
            filled.plant.manufacturer,
          );
          continue;
        }
        if (flowRateLS === null) {
          console.log(
            "No flow rate for tank",
            filled.uid,
            filled.plant.manufacturer,
          );
          continue;
        }
        const capacityL =
          (entity.plant as typeof filled.plant).capacityL ??
          flowRateLS * filled.plant.peakFlowRateStorageMinutes! * 60;
        pumpCalc.capacityL = capacityL;

        switch (tankData.type) {
          case "model-capacity-dependent": {
            const record = tankData.configuration[filled.plant.configuration!];
            if (!record) {
              console.log(
                "No tank record for",
                filled.uid,
                filled.plant.manufacturer,
                filled.plant.configuration,
              );
              continue;
            }

            const firstGoodPressureKPA = this.findFirstGoodPressureKPA(
              record.referenceLookup,
              pumpCalc.pumpDutyKPA!,
            );

            if (firstGoodPressureKPA === undefined) {
              console.log(
                "No good pressure for",
                filled.uid,
                filled.plant.manufacturer,
                filled.plant.configuration,
                pumpCalc.pumpDutyKPA,
              );
              this.handlePumpWarning(
                engine,
                filled,
                filled.plant.configuration!,
                pumpCalc,
                tankData,
                engine.drawing.metadata.units,
              );
              continue;
            }

            const { firstGoodFlowRate, row } = this.findFirstGoodFlowRate(
              record.referenceLookup,
              firstGoodPressureKPA,
              flowRateLS,
            );

            if (firstGoodFlowRate === undefined) {
              console.log(
                "No good flow rate for",
                filled.uid,
                filled.plant.manufacturer,
                filled.plant.configuration,
                flowRateLS,
              );
              this.handlePumpWarning(
                engine,
                filled,
                filled.plant.configuration!,
                pumpCalc,
                tankData,
                engine.drawing.metadata.units,
              );
              continue;
            }

            const referenceId =
              record.referenceLookup[firstGoodPressureKPA][firstGoodFlowRate];
            console.log(
              "Tank reference",
              filled.uid,
              referenceId,
              firstGoodPressureKPA,
              firstGoodFlowRate,
            );

            const availableCapacitiesL = Object.keys(record.capacityLookup)
              .map((k) => parseFloat(k))
              .sort((a, b) => a - b);
            const firstGoodCapacityL = availableCapacitiesL.find(
              (p) => p >= capacityL,
            );

            pumpCalc.maxCapacityAvailableL =
              availableCapacitiesL[availableCapacitiesL.length - 1] ?? null;
            pumpCalc.modelReference = referenceId || null;
            if (firstGoodCapacityL === undefined) {
              console.log(
                "No good capacity for",
                filled.uid,
                filled.plant.manufacturer,
                filled.plant.configuration,
                flowRateLS,
              );
              continue;
            }

            const modelId =
              record.capacityLookup[firstGoodCapacityL][referenceId];

            console.log(
              "Tank model",
              modelId,
              filled.plant.manufacturer,
              firstGoodCapacityL,
              referenceId,
            );
            pumpCalc.model = modelId || null;
            break;
          }
          case "model-capacity-independent": {
            pumpCalc.maxCapacityAvailableL =
              Math.max(...Object.keys(tankData.sizes).map(Number)) ?? null;
            const record = tankData.configuration[filled.plant.configuration!];
            if (!record) {
              console.log(
                "No tank record for",
                filled.uid,
                filled.plant.manufacturer,
                filled.plant.configuration,
              );
              continue;
            }

            const firstGoodPressureKPA = this.findFirstGoodPressureKPA(
              record,
              pumpCalc.pumpDutyKPA!,
            );

            if (firstGoodPressureKPA === undefined) {
              console.log(
                "No good pressure for",
                filled.uid,
                filled.plant.manufacturer,
                filled.plant.configuration,
                pumpCalc.pumpDutyKPA,
              );
              this.handlePumpWarning(
                engine,
                filled,
                filled.plant.configuration!,
                pumpCalc,
                tankData,
                engine.drawing.metadata.units,
              );
              continue;
            }

            const { firstGoodFlowRate, row } = this.findFirstGoodFlowRate(
              record,
              firstGoodPressureKPA,
              flowRateLS,
            );

            if (firstGoodFlowRate === undefined) {
              console.log(
                "No good flow rate for",
                filled.uid,
                filled.plant.manufacturer,
                filled.plant.configuration,
                flowRateLS,
              );
              this.handlePumpWarning(
                engine,
                filled,
                filled.plant.configuration!,
                pumpCalc,
                tankData,
                engine.drawing.metadata.units,
              );
              continue;
            }

            const modelId = record[firstGoodPressureKPA][firstGoodFlowRate];

            pumpCalc.model = modelId || null;
            break;
          }
          case "none": {
            break;
          }
          default:
            assertUnreachable(tankData);
        }
      } else if (filled.plant.type === PlantType.TANK) {
        const pumpCalc = engine.globalStore.getOrCreateCalculation(entity);
        const outletCalc = engine.globalStore.getOrCreateCalculation(
          engine.globalStore.get(filled.plant.outletUid)
            .entity as SystemNodeEntity,
        );
        const flowRateLS = outletCalc.flowRateLS;

        if (flowRateLS === null) {
          continue;
        }

        const capacityL =
          (entity.plant as typeof filled.plant).capacityL ??
          flowRateLS * filled.plant.peakFlowRateStorageMinutes! * 60;
        pumpCalc.capacityL = capacityL;
      } else if (filled.plant.type === PlantType.RO) {
        const pumpCalc = engine.globalStore.getOrCreateCalculation(entity);

        const outletCalc = engine.globalStore.getOrCreateCalculation(
          engine.globalStore.get(filled.plant.outletUid)
            .entity as SystemNodeEntity,
        );

        const flowRateLS = outletCalc.flowRateLS;
        this.setPumpDutyFields(engine, null, pumpCalc, flowRateLS);

        const roData = getPlantDatasheet(filled.plant, engine.catalog, true);

        if (roData.length !== 1) {
          Logger.error(
            new SentryEntityError(
              "Failed to find suitable RO Plant",
              filled.uid,
            ),
          );
        }

        const roCalc = engine.globalStore.getOrCreateCalculation(entity);
        roCalc.model = roData[0].model ?? null;
        roCalc.depthMM = roData[0].depthMM;
        roCalc.widthMM = roData[0].widthMM;
      }
    }
  }

  static findFirstGoodFlowRate(
    pumpTable: PumpLookupTable,
    firstGoodPressureKPA: number,
    flowRateLS: number,
  ) {
    const row = pumpTable[firstGoodPressureKPA];
    const availableFlowRates = Object.keys(row)
      .map((k) => parseFloat(k))
      .sort((a, b) => a - b);

    const firstGoodFlowRate = availableFlowRates.find((p) => p >= flowRateLS);
    return { firstGoodFlowRate, row };
  }

  static findFirstGoodPressureKPA(
    pumpTable: PumpLookupTable,
    pumpDutyKPA: number,
  ) {
    const availablePressuresKPA = Object.keys(pumpTable)
      .map((k) => parseFloat(k))
      .sort((a, b) => a - b);
    const firstGoodPressureKPA = availablePressuresKPA.find(
      (p) => p >= pumpDutyKPA,
    );
    return firstGoodPressureKPA;
  }

  @TraceCalculation("Create pump warnings", (c, e, _1, _2, _3, _4) => [e.uid])
  static handlePumpWarning(
    context: CoreContext,
    entity: PlantEntity,
    pumpConfig: PumpConfiguration,
    pumpCalc: PlantCalculation,
    datasheet:
      | PumpTableData
      | PumpTankModelCapacityDependent
      | PumpTankModelCapacityIndependent,
    units: UnitsParameters,
  ) {
    // Find the next best suitable pump config
    // Sometimes a duty pump needs to be changed to duty assist assist
    // hence we need to check all configs for the best suitable one
    let nextBestConfig: PumpConfiguration | null = pumpConfig;
    let nextBestConfigAvailable = true;
    while (nextBestConfig) {
      nextBestConfig = findNextBestPumpConfig(nextBestConfig);
      if (!nextBestConfig) {
        break;
      }
      const pumpTable = this.getPumpTableByConfig(datasheet, nextBestConfig);
      if (pumpTable === undefined) {
        continue;
      }
      if (
        this.isNextConfigSuitable(
          pumpTable,
          pumpCalc.pumpDutyKPA!,
          pumpCalc.pumpFlowRateLS!,
        )
      ) {
        nextBestConfigAvailable = true;
        break;
      }
    }

    if (nextBestConfig) {
      addWarning(context, "PUMP_CONFIG_TOO_LOW", [entity], {
        params: {
          dutyKPA: pumpCalc.pumpDutyKPA!,
          flowRateLS: pumpCalc.pumpFlowRateLS!,
          suggestedConfig: nextBestConfig,
          plantUid: entity.uid,
        },
      });
    } else {
      addWarning(context, "NO_PUMP_CAN_SUPPLY", [entity], {
        params: {
          dutyKPA: pumpCalc.pumpDutyKPA!,
          flowRateLS: pumpCalc.pumpFlowRateLS!,
        },
      });
    }
  }

  static isNextConfigSuitable(
    pumpTable: PumpLookupTable,
    pumpDutyKPA: number,
    flowRateLS: number,
  ): boolean {
    const firstGoodPressureKPA = this.findFirstGoodPressureKPA(
      pumpTable,
      pumpDutyKPA,
    );
    if (firstGoodPressureKPA === undefined) {
      return false;
    }
    const { firstGoodFlowRate, row } = this.findFirstGoodFlowRate(
      pumpTable,
      firstGoodPressureKPA,
      flowRateLS,
    );
    if (firstGoodFlowRate === undefined) {
      return false;
    }

    return true;
  }

  @TraceCalculation("Retrieving pump specifications table from catalog")
  static getPumpTableByConfig(
    datasheet:
      | PumpTankModelCapacityDependent
      | PumpTankModelCapacityIndependent
      | PumpTableData,
    nextBestConfig: PumpConfiguration,
  ): PumpLookupTable | undefined {
    switch (datasheet.type) {
      case "model-capacity-dependent":
      case "model-capacity-independent":
        return datasheet.configuration[nextBestConfig];
      case "dense-table":
      case "sparse-table":
        return datasheet.table[nextBestConfig];
      default:
        assertUnreachable(datasheet);
    }
  }
}
