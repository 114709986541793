import { Module } from "vuex";
import { CORE_FEATURE_FLAG_DEFAULTS } from "../../../../common/src/lib/feature-flags";
import { RootState } from "../types";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import FeatureFlagState, { FrontendFeatureFlags } from "./types";

// Note: For now just supporting boolean flags cause its easy, let me (David) know
// if you need to support numeric and/or object flags and ill add support.
// If your flags aren't working, it's because you didn't select "Allow Client-side SDKs" in your LaunchDarkly Flag.
// We currently only have 2 environments: Prod and Test, we might make more later.
// https://h2xengineering.atlassian.net/wiki/spaces/ENG/pages/171081734/Launch+Darkly
export const FRONTEND_FEATURE_FLAG_DEFAULTS: FrontendFeatureFlags = {
  ...CORE_FEATURE_FLAG_DEFAULTS,
  "release-problem-panel": false,
  "release-frontend-filtering-in-manufacturer-project": false,
  "release-manufacturer-projects-address-filter-all-options": false,
  "release-project-page-maximum-projects-returned": 50,
  "release-manufacturer-dashboard-email-subscription": false,
  "release-design-seat-perms": false,
  "release-account-automation-user-management": false,
  "release-signup-flow-questionnaire": false,
  "release-new-organizations-list": false,
  "release-ipc-2018-combined": false,
};

export const state: FeatureFlagState = {
  launchDarklyClient: null,
  featureFlags: FRONTEND_FEATURE_FLAG_DEFAULTS,
};

const namespaced: boolean = true;

export const featureFlags: Module<FeatureFlagState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
