import { Rectangle } from "../../../../lib/rectangle";
import { CoreContext } from "../../../calculations/types";
import { FieldType } from "../field-type";
import { PropertyField } from "../property-field";
import { NamedEntity, PositionedEntity } from "../simple-entities";
import { EntityType } from "../types";
import { CustomCompound } from "./custom-compound";
import { MultiPumpCompound } from "./multi-pumps";

export type CompoundConcrete = MultiPumpCompound | CustomCompound;

export type CompoundType = "multi-pump" | "custom";

export interface CompoundBase {
  type: CompoundType;
}
export interface CompoundEntity extends PositionedEntity, NamedEntity {
  type: EntityType.COMPOUND;

  compound: CompoundConcrete;

  crop: Rectangle;
}

export function fillDefaultCompoundEntityFields(
  context: CoreContext,
  entity: CompoundEntity,
): CompoundEntity {
  return entity;
}

export function makeCompoundEntityFields(
  context: CoreContext,
  entity: CompoundEntity,
): PropertyField[] {
  const res: PropertyField[] = [];

  res.push({
    property: "rotation",
    title: "Rotation",
    hasDefault: false,
    isCalculated: false,
    type: FieldType.Rotation,
    params: {
      step: 45,
      disableFreeInput: false,
    },
    multiFieldId: "rotation",
  });

  return res;
}
