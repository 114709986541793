import { Primitive } from "../../../common/src/lib/primitive";

export class Counter<T extends Primitive> {
  constructor(readonly max: number) {}

  errorCounter = new Map<T, number>();

  get(key: T): number {
    const currentCount = this.errorCounter.get(key);
    if (currentCount) {
      return currentCount;
    }
    return 0;
  }

  has(key: T) {
    return this.errorCounter.has(key);
  }

  canIncrement(key: T): boolean {
    const currentCount = this.errorCounter.get(key);
    return !(currentCount && currentCount >= this.max);
  }

  increment(key: T): void {
    if (this.canIncrement(key)) {
      this.errorCounter.set(key, this.get(key) + 1);
    }
  }

  decrement(key: T): void {
    if (this.get(key) === 1) {
      this.errorCounter.delete(key);
    } else if (this.get(key) > 0) {
      this.errorCounter.set(key, this.get(key) - 1);
    }
  }

  clear(): void {
    this.errorCounter.clear();
  }
}
