import * as LDClient from "launchdarkly-js-client-sdk";
import { ActionTree } from "vuex";
import { Logger } from "../../../../common/src/lib/logger";
import { betterObjectEntries } from "../../../../common/src/lib/utils";
import { User } from "../../../../common/src/models/User";
import { Sentry } from "../../api/integrations/Sentry";
import { ENABLE_SENTRY, LAUNCH_DARKLY_PUBLIC_CLIENT_ID } from "../../config";
import { useProfile } from "../../lib/hooks/use-profile";
import { RootState } from "../types";
import FeatureFlagState from "./types";

export const actions: ActionTree<FeatureFlagState, RootState> = {
  async identifyUser({ state, commit }) {
    // Can be null if the user is already logged in (eg via a page refresh)
    if (!state.launchDarklyClient) {
      return;
    }

    // Can be null if user is not logged in when LD launches
    const profile = useProfile();
    if (!profile) {
      return;
    }
    const flags = await state.launchDarklyClient.identify({
      kind: "multi",
      user: getUserContext(profile),
      organization: getOrganizationContext(profile),
    });
    console.log(
      `Launch Darkly: User ${profile.username} identified successfully`,
    );
    betterObjectEntries<string, any>(flags).forEach(([key, value]) => {
      console.log(`Feature flag "${key}" initialized to ${value}`);
      commit("setFeatureFlag", { flag: key, value: value });
    });
  },
  async initialize({ commit, dispatch }) {
    const initialContext: LDClient.LDContext = {
      kind: "user",
      key: "not-logged-in",
    };

    const client = LDClient.initialize(
      LAUNCH_DARKLY_PUBLIC_CLIENT_ID,
      initialContext,
      {
        streaming: true,
        inspectors: ENABLE_SENTRY
          ? [Sentry.buildLaunchDarklyFlagUsedHandler()]
          : [],
      },
    );

    client.on("initialized", function () {
      commit("setClient", client);
      dispatch("identifyUser");
    });

    client.on("failed", function () {
      Logger.error("Launch Darkly SDK failed to initialize!");
    });

    client.on("change", function (changeSets) {
      betterObjectEntries<string, any>(changeSets).forEach(
        ([key, changeSet]) => {
          console.log(
            `Feature flag "${key}" changed from ${changeSet.previous} to ${changeSet.current}`,
          );
          commit("setFeatureFlag", { flag: key, value: changeSet.current });
          Sentry.updateFeatureFlags();
        },
      );
    });
  },
};

function getUserContext(profile: User) {
  return {
    key: profile.username,
    name: [profile.name, profile.lastname].filter((x) => !x).join(" "),
    email: profile.email,
    subscribed: profile.subscribed,
    role: profile.accessLevel,
  };
}

function getOrganizationContext(profile: User) {
  return {
    key: profile.organization?.id,
    name: profile.organization?.name ?? "",
    type: profile.organization?.type ?? "",
    subscriptionStatus: profile.organization?.subscriptionStatus ?? "",
    accountType:
      profile.organization?.name === "Free Account" ? "free" : "paid",
  };
}
