<template>
  <div id="app">
    <template v-if="upToDate">
      <router-view />
    </template>
    <template v-else>
      <b-spinner style="size: 2em" />
      Updating to latest version, please wait...
    </template>
    <version-number />
    <DocAndOperation />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";

import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";

import "prismjs/themes/prism-solarizedlight.css"; // import syntax highlighting styles

import axios from "axios";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere
import { CURRENT_VERSION } from "../../common/src/api/config";
import VersionNumber from "../src/components/VersionNumber.vue";
import DocAndOperation from "./components/DocAndOperation.vue";
import { MainEventBus } from "./store/main-event-bus";

const upToDate = ref(true);

onMounted(() => {
  (window as any).axios = axios;
  axios.defaults.headers.common["drawing-version"] = CURRENT_VERSION;

  document.onkeydown = (evt) => {
    if (
      document.activeElement === null ||
      document.activeElement.nodeName.toLowerCase() !== "input"
    ) {
      MainEventBus.$emit("keydown", evt);
    }

    let isEscape = false;
    if ("key" in evt) {
      isEscape = evt.key === "Escape" || evt.key === "Esc";
    }
    if (isEscape) {
      MainEventBus.$emit("escape-pressed-pass");
    }
    if (evt.key === "Delete" || evt.key === "Del" || evt.key === "Backspace") {
      if (
        document.activeElement === null ||
        (document.activeElement.nodeName.toLowerCase() !== "input" &&
          document.activeElement.nodeName.toLowerCase() !== "textarea")
      ) {
        MainEventBus.$emit("delete-pressed");
      }
    }
  };

  document.onkeyup = (evt) => {
    if (
      document.activeElement === null ||
      document.activeElement.nodeName.toLowerCase() !== "input"
    ) {
      MainEventBus.$emit("keyup", evt);
    }
  };

  MainEventBus.$on("update-available", () => {
    upToDate.value = false;
  });
});
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

#app {
  touch-action: none;
  font-family: "Poppins", "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* nukes all externally applied styling for an element. useful to escape bootstrap */
.no-style {
  all: unset;
}

/* applies default font, useful for restoring font after nuking styles via 'no-style' */
.default-font {
  font-family: "Poppins", "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 16px !important;
}

body {
  background-color: #f9f9fa !important;
}

.locale {
  margin-top: 9rem;
}

/** bootstrap 'light' button variant looks shit */
.btn-light {
  @apply border-neutral-500 bg-neutral-50 hover:bg-neutral-500 text-neutral-500 hover:text-white;
}
</style>
