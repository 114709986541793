import { isDrainage } from "../../../../common/src/api/config";
import CoreRiser from "../../../../common/src/api/coreObjects/coreRiser";
import { Level } from "../../../../common/src/api/document/drawing";
import { DrawableEntityConcrete } from "../../../../common/src/api/document/entities/concrete-entity";
import {
  PipeConduitEntity,
  isDuctEntity,
  isPipeEntity,
} from "../../../../common/src/api/document/entities/conduit-entity";
import RiserEntity, {
  DuctRiserEntity,
} from "../../../../common/src/api/document/entities/riser-entity";
import { EntityType } from "../../../../common/src/api/document/entities/types";
import {
  FlowSystem,
  flowSystemHasVent,
} from "../../../../common/src/api/document/flow-systems";
import { getFlowSystem } from "../../../../common/src/api/document/utils";
import { Color, lighten } from "../../../../common/src/lib/color";
import { Coord } from "../../../../common/src/lib/coord";
import { GlobalStore } from "../../../../common/src/lib/globalstore/global-store";
import { SentryEntityError } from "../../../../common/src/lib/sentry-entity-error";
import {
  assertType,
  assertUnreachable,
} from "../../../../common/src/lib/utils";
import { DEFAULT_FONT_NAME } from "../../config";
import { rgb2style } from "../../lib/utils";
import { getDragPriority } from "../../store/document";
import { DocumentState } from "../../store/document/types";
import { getGlobalContext } from "../../store/globalCoreContext";
import CanvasContext from "../lib/canvas-context";
import { EntityDrawingArgs } from "../lib/drawable-object";
import { paintVerticalDuctShape } from "../lib/drawing-helpers/ducts";
import {
  HeatmapMode,
  generateRiserHeatmap,
  isHeatmapEnabled,
} from "../lib/heatmap/heatmap";
import { Interaction } from "../lib/interaction";
import { AttachableObject } from "../lib/object-traits/attachable-object";
import { CalculatedObject } from "../lib/object-traits/calculated-object";
import { CenteredObjectNoParent } from "../lib/object-traits/centered-object";
import { ConnectableObject } from "../lib/object-traits/connectable";
import CoolDraggableObject from "../lib/object-traits/cool-draggable-object";
import { Core2Drawable } from "../lib/object-traits/core2drawable";
import { SelectableObject } from "../lib/object-traits/selectable";
import { SnappableObject } from "../lib/object-traits/snappable-object";
import {
  DrawingContext,
  ObjectConstructArgs,
  ValidationResult,
} from "../lib/types";
import { getHighlightColor } from "../lib/utils";
import { DrawingMode } from "../types";
import { DrawableObjectConcrete } from "./concrete-object";
import { MIN_PIPE_PIXEL_WIDTH } from "./drawableConduit";

const Base = CalculatedObject(
  SelectableObject(
    AttachableObject(
      CoolDraggableObject(
        ConnectableObject(
          CenteredObjectNoParent(SnappableObject(Core2Drawable(CoreRiser))),
        ),
      ),
    ),
  ),
);

export default class DrawableRiser extends Base {
  minimumConnections = 0;
  maximumConnections = null;
  attachmentOffset = 60;

  dragPriority = getDragPriority(EntityType.RISER);

  MINIMUM_RADIUS_PX: number = 3;
  lastDrawnWorldRadius: number = 0; // for bounds detection
  lastDrawnDiameterW: number = 100;

  // We could not add this to the base drawable class because
  // of some typescript thing so they have to be added at the concrete class.
  constructor(args: ObjectConstructArgs<RiserEntity>) {
    super(args.context, args.obj);
    this.onSelect = args.onSelect;
    this.onInteractionComplete = args.onInteractionComplete;
    this.document = args.document;
  }

  drawConnectable(
    context: DrawingContext,
    {
      selected,
      layerActive,
      hasOverriddenField,
      heatmapMode,
    }: EntityDrawingArgs,
  ): void {
    const { ctx, vp, doc } = context;
    this.lastDrawnWorldRadius = 0;

    const scale = vp.currToSurfaceScale(ctx);
    // Minimum screen size for them.

    const rawDiameter = 100;
    const screenMin = vp.surfaceToWorldLength(10);
    this.lastDrawnDiameterW = Math.max(rawDiameter, screenMin);

    const screenSize = vp.toSurfaceLength(this.lastDrawnDiameterW / 2);

    ctx.lineWidth = 0;

    if (
      (selected || hasOverriddenField) &&
      (heatmapMode === HeatmapMode.Off ||
        this.document.uiState.drawingMode !== DrawingMode.Calculations)
    ) {
      // we want to draw a pixel sized dark halo around a selected component
      const haloSize =
        (Math.max(this.MINIMUM_RADIUS_PX, screenSize) + 5) / scale;

      ctx.fillStyle = rgb2style(
        getHighlightColor(selected, hasOverriddenField ? [Color.YELLOW] : [], {
          hex: lighten(this.color(doc).hex, 50),
        }),
        0.5,
      );

      if (!this.isActive()) {
        ctx.fillStyle = "rgba(150, 150, 150, 0.65)";
      }

      ctx.beginPath();
      ctx.lineWidth = 0;
      ctx.globalAlpha = 1;
      ctx.arc(0, 0, haloSize, 0, Math.PI * 2);
      ctx.fill();

      this.lastDrawnWorldRadius = Math.max(this.lastDrawnWorldRadius, haloSize);
    }

    if (layerActive) {
      if (screenSize < this.MINIMUM_RADIUS_PX) {
        // Risers are very important and should be visible, even when zoomed out.

        ctx.fillStyle = this.color(doc).hex;
        ctx.globalAlpha = 0.5;
        ctx.beginPath();
        ctx.arc(0, 0, this.MINIMUM_RADIUS_PX / scale, 0, Math.PI * 2);
        ctx.fill();

        this.lastDrawnWorldRadius = Math.max(
          this.lastDrawnWorldRadius,
          this.MINIMUM_RADIUS_PX / scale,
        );
      }
    }

    ctx.fillStyle = this.baseDrawnColor(context).hex;
    if (!this.isActive()) {
      ctx.fillStyle = "rgba(150, 150, 150, 0.65)";
    }

    if (isHeatmapEnabled(this.document) && heatmapMode !== undefined) {
      const networkObj = context.globalStore.getOrCreateCalculation(
        this.entity,
      ).expandedEntities;

      const height0 =
        context.doc.drawing.levels[context.doc.uiState.levelUid!].floorHeightM;
      const levelHeights: number[] = [];
      Object.entries(context.doc.drawing.levels).forEach(([_uid, level]) => {
        levelHeights.push(level.floorHeightM);
      });
      levelHeights.sort((a, b) => a - b);
      const height1 = levelHeights[levelHeights.indexOf(height0) + 1];

      // TODO: enable non-pipe conduit riser heatmaps
      const pipes = networkObj!.filter((obj) => {
        return (
          (isPipeEntity(obj) || isDuctEntity(obj)) &&
          obj.heightAboveFloorM >= height0 &&
          obj.heightAboveFloorM < height1
        );
      });
      const color = generateRiserHeatmap(
        this,
        heatmapMode,
        pipes as PipeConduitEntity[],
        getGlobalContext(),
      );

      if (color) {
        ctx.fillStyle = color;
        ctx.strokeStyle = color;
        // ctx.shadowColor = color;
        // ctx.shadowBlur = 15;
      }
    }

    ctx.globalAlpha = 1;

    switch (this.entity.riserType) {
      case "pipe":
        this.drawPipeRiser(context);
        break;
      case "duct":
        this.drawDuctRiser(context);
        break;
      default:
        assertUnreachable(this.entity);
    }

    // Display Entity Name
    if (this.entity.entityName) {
      const name = this.entity.entityName;
      ctx.font = 70 + "pt " + DEFAULT_FONT_NAME;
      const nameWidth = ctx.measureText(name).width;
      const offsetx = -nameWidth / 2;
      ctx.fillStyle = "rgba(0, 255, 20, 0.13)";
      // the 70 represents the height of the font
      const textHight = 70;
      const offsetY = this.lastDrawnDiameterW / 2 + textHight;
      ctx.fillRect(offsetx, offsetY, nameWidth, 70);
      ctx.fillStyle = this.color(doc).hex;
      ctx.fillTextStable(name, offsetx, offsetY - 4, undefined, "top");
    }
    // ctx.shadowBlur = 0;
  }

  drawDuctRiser(context: DrawingContext) {
    if (context.doc.uiState.drawingMode !== DrawingMode.Calculations) {
      this.drawPipeRiser(context);
      return;
    }
    const { ctx, doc, graphics } = context;
    assertType<DuctRiserEntity>(this.entity);

    const calc = context.globalStore.getOrCreateCalculation(this.entity);

    const bottomLevel = calc.heights[doc.uiState.levelUid!];
    const upperLevelUid = Object.values(doc.drawing.levels)
      .sort((l1, l2) => l1.floorHeightM - l2.floorHeightM)
      .find(
        (l) =>
          l.floorHeightM >
          doc.drawing.levels[doc.uiState.levelUid!].floorHeightM,
      )?.uid;
    const upperLevel = upperLevelUid ? calc.heights[upperLevelUid] : null;

    const axialRotDEG = bottomLevel?.axialRotDEG ?? upperLevel?.axialRotDEG;

    if (axialRotDEG == undefined) {
      this.drawPipeRiser(context);
      return;
    }

    const s = graphics.worldToSurfaceScale;

    const targetWWidth = 15;
    const baseWidth = Math.max(
      MIN_PIPE_PIXEL_WIDTH / s,
      targetWWidth / graphics.unitWorldLength,
      (MIN_PIPE_PIXEL_WIDTH / s) * (5 + Math.log(s)),
    );
    ctx.lineWidth = Math.max(1 / s, baseWidth / 8);

    const baseColor = this.color(doc).hex;
    ctx.fillStyle = baseColor;
    for (const level of [bottomLevel, upperLevel]) {
      if (!level) continue;
      if (!level.shape) continue;
      paintVerticalDuctShape(context, {
        size:
          level.shape === "circular"
            ? {
                type: "circ",
                diameterMM: level.sizeMM ?? 0,
              }
            : {
                type: "rect",
                widthMM: level.widthMM ?? 0,
                heightMM: level.heightMM ?? 0,
              },
        axialRotDEG,
      });
    }
  }

  drawPipeRiser(context: DrawingContext) {
    const { ctx, vp, doc } = context;

    const scale = vp.currToSurfaceScale(ctx);
    ctx.beginPath();
    ctx.arc(
      0,
      0,
      this.toObjectLength(this.lastDrawnDiameterW / 2),
      0,
      Math.PI * 2,
    );
    this.lastDrawnWorldRadius = Math.max(
      this.lastDrawnWorldRadius,
      this.toObjectLength(this.lastDrawnDiameterW / 2),
    );
    ctx.fill();

    ctx.beginPath();
    ctx.fillStyle = "#000000";

    if (
      isDrainage(doc.drawing.metadata.flowSystems[this.entity.systemUid]) &&
      !this.isVent()
    ) {
      // stack. Draw upside down traingle.
      ctx.moveTo(0, this.lastDrawnDiameterW * 0.45);
      ctx.lineTo(
        this.lastDrawnDiameterW * 0.38,
        -this.lastDrawnDiameterW * 0.25,
      );
      ctx.lineTo(0, -this.lastDrawnDiameterW * 0.1);
      ctx.lineTo(
        -this.lastDrawnDiameterW * 0.38,
        -this.lastDrawnDiameterW * 0.25,
      );
      ctx.closePath();
      ctx.fill();

      const system = getFlowSystem(doc.drawing, this.entity.systemUid);
      if (system && flowSystemHasVent(system)) {
        if (system.stackDedicatedVent) {
          // draw dedicated vent.
          const ventColor = system.ventColor.hex;

          ctx.beginPath();
          ctx.fillStyle = ventColor;

          if (!this.isActive()) {
            ctx.fillStyle = "rgba(150, 150, 150, 0.65)";
          }

          const ventRadius = this.lastDrawnDiameterW / 4;
          const ventDiameter = ventRadius * 2;
          ctx.arc(this.lastDrawnDiameterW, 0, ventRadius, 0, Math.PI * 2);
          ctx.fill();

          ctx.beginPath();
          ctx.fillStyle = "#000000";
          ctx.moveTo(this.lastDrawnDiameterW, -ventDiameter * 0.45);
          ctx.lineTo(
            this.lastDrawnDiameterW + ventDiameter * 0.38,
            ventDiameter * 0.25,
          );
          ctx.lineTo(this.lastDrawnDiameterW, ventDiameter * 0.1);
          ctx.lineTo(
            this.lastDrawnDiameterW - ventDiameter * 0.38,
            ventDiameter * 0.25,
          );
          ctx.closePath();
          ctx.fill();
        }
      }
    } else {
      // riser. Draw normal triangle.
      ctx.moveTo(0, -this.lastDrawnDiameterW * 0.45);
      ctx.lineTo(
        this.lastDrawnDiameterW * 0.38,
        this.lastDrawnDiameterW * 0.25,
      );
      ctx.lineTo(0, this.lastDrawnDiameterW * 0.1);
      ctx.lineTo(
        -this.lastDrawnDiameterW * 0.38,
        this.lastDrawnDiameterW * 0.25,
      );
      ctx.closePath();
      ctx.fill();
    }

    // draw green circle if vent exit
    const liveCalcs = context.globalStore.getOrCreateLiveCalculation(
      this.entity,
    );
    const entry = liveCalcs.heights[context.doc.uiState.levelUid!];

    if (entry && entry.isVentExit) {
      ctx.beginPath();

      ctx.arc(0, 0, (this.lastDrawnDiameterW / 2) * 1.5, 0, 2 * Math.PI);
      ctx.strokeStyle = "rgba(0, 255, 0, 0.5)";
      ctx.lineWidth = 3 / scale;
      ctx.stroke();
    }
  }

  color(_doc: DocumentState) {
    const system = this.drawing.metadata.flowSystems[this.entity.systemUid];
    if (this.isVent() && flowSystemHasVent(system)) {
      return system.ventColor;
    }
    return this.entity.color == null ? system.color : this.entity.color;
  }

  system(doc: DocumentState): FlowSystem {
    const result = getFlowSystem(doc.drawing, this.entity.systemUid);
    if (result) {
      return result;
    } else {
      throw new SentryEntityError(
        "Flow system not found for flow source ",
        this.uid,
      );
    }
  }

  refreshObjectInternal(_obj: RiserEntity): void {
    //
  }

  inBounds(objectCoord: Coord, radius?: number) {
    if (!this.isActive()) {
      return false;
    }
    const dist = Math.sqrt(objectCoord.x ** 2 + objectCoord.y ** 2);
    return (
      dist <=
      this.toObjectLength(this.lastDrawnDiameterW / 2) + (radius ? radius : 0)
    );
  }

  prepareDelete(
    context: CanvasContext,
    _calleeEntityUid?: string,
  ): DrawableObjectConcrete[] {
    // Do not call super. Override the default straight-pipe restore behavior.
    const conns = context.globalStore.getConnections(this.uid);
    return [...conns.map((uid) => context.globalStore.get(uid)!), this];
  }

  offerInteraction(interaction: Interaction): DrawableEntityConcrete[] | null {
    return super.offerInteraction(interaction);
  }

  validate(context: CanvasContext, tryToFix: boolean): ValidationResult {
    const pres = super.validate(context, tryToFix);
    if (pres && !pres.success) {
      return pres;
    }

    // Send confirm response, to let user choose to keep pipe or not
    const sendConfirmChange = (title: string, message: string) => {
      return {
        success: false,
        modified: false,
        title: title,
        message: message,
        // Idealy I want this function generic but lets leave it hardcode for now
        // SEED-62: Improve Riser
        // Will return a list of id that need to be removed
        confirmAction: () => {
          return this.getInvalidConnections(context).removeList;
        },
        rejectAction: () => {
          // An empty function
        },
      };
    };

    // check the sanity of floors
    // let's exclude vent as it should not display on the level below lowest connected pipe's level
    if (this.entity.bottomFloorRef !== null && !this.isVent()) {
      if (this.entity.bottomFloorRef > this.minPipeFloorRef(context)) {
        if (tryToFix) {
          this.entity.bottomFloorRef = this.minPipeFloorRef(context);
        } else {
          return sendConfirmChange(
            `Warning: This change will disconnect and remove pipe connected to riser`,
            `Riser top can't be lower than our highest pipe. (` +
              this.entity.uid +
              ", " +
              this.entity.bottomFloorRef +
              " " +
              this.entity.topFloorRef +
              ", " +
              this.maxPipeFloorRef(context) +
              ")" +
              ". Please examine the following floor contains soon disconnected pipe: " +
              this.getInvalidConnections(context).hints.join(", "),
          );
        }
      }
    }
    if (this.entity.topFloorRef !== null) {
      if (this.entity.topFloorRef < this.maxPipeFloorRef(context)) {
        if (tryToFix) {
          this.entity.topFloorRef = this.maxPipeFloorRef(context);
        } else {
          let typeBase = "Riser";
          if (this.isVent()) {
            typeBase = "Vent";
          }
          return sendConfirmChange(
            `Warning: This change will disconnect and remove pipe connected to riser`,
            `${typeBase} top can't be lower than our highest pipe. (` +
              this.entity.uid +
              ", " +
              this.entity.bottomFloorRef +
              " " +
              this.entity.topFloorRef +
              ", " +
              this.maxPipeFloorRef(context) +
              ")" +
              "\n Please examine the following floor contains soon disconnected pipe: " +
              this.getInvalidConnections(context).hints.join(", "),
          );
        }
      }

      // This error does not take further action, leave as it is
      if (this.isVent() && !this.ventCheckTopFloorCond(context)) {
        return {
          success: false,
          message:
            "Vent top can't be higher than our lowest pipe (" +
            this.entity.uid +
            ", " +
            this.entity.bottomFloorRef +
            " " +
            this.entity.topFloorRef +
            ", " +
            this.minPipeFloorRef(context) +
            ")",
          modified: false,
        };
      }
    }

    return {
      success: true,
    };
  }

  minPipeFloorRef(context: CanvasContext): number {
    if (!(this.globalStore instanceof GlobalStore)) {
      throw new Error("minPipeFloorRef only works in the global context");
    }

    const connsRaw: (string | null | undefined)[] = this.globalStore
      .getConnections(this.uid)
      .map((entityUid) => {
        return this.globalStore.levelOfEntity.get(entityUid);
      });
    const conns: string[] = connsRaw.filter(
      (uid) => uid !== null && uid !== undefined,
    ) as string[];

    if (conns.length === 0) {
      return Infinity;
    }

    return Math.min(
      ...conns.map((uid) => {
        return (
          this.findIdxHelper(context, uid, true) -
          this.findIdxHelper(context, "ground", true)
        );
      }),
    );
  }

  maxPipeFloorRef(context: CanvasContext): number {
    if (!(this.globalStore instanceof GlobalStore)) {
      throw new Error("minPipeFloorRef only works in the global context");
    }

    const connsRaw: (string | null | undefined)[] = this.globalStore
      .getConnections(this.uid)
      .map((entityUid) => {
        return this.globalStore.levelOfEntity.get(entityUid);
      });
    const conns: string[] = connsRaw.filter(
      (uid) => uid !== null && uid !== undefined,
    ) as string[];

    if (conns.length === 0) {
      return -Infinity;
    }

    return Math.max(
      ...conns.map((uid) => {
        return (
          this.findIdxHelper(context, uid, true) -
          this.findIdxHelper(context, "ground", true)
        );
      }),
    );
  }

  // Return reference to the floor, argument is the id to this floor
  getFloorRef(context: CanvasContext, uid: string | null | undefined): number {
    // NaN will make all comparison to return false
    if (uid === null || uid === undefined) return NaN;
    return (
      this.findIdxHelper(context, uid, true) -
      this.findIdxHelper(context, "ground", true)
    );
  }

  ventCheckTopFloorCond(context: CanvasContext): boolean {
    const connsUidRaw = this.globalStore
      .getConnections(this.uid)
      .map((entityUid) => {
        return [entityUid, this.globalStore.levelOfEntity.get(entityUid)];
      });
    const connsUid = connsUidRaw.filter(
      (entityUid) => entityUid[1] !== undefined,
    );

    for (const [uid, levelUid] of connsUid) {
      if (uid === undefined || uid === null) continue;
      // Further inspect entiies at boundry point on top floor
      if (
        this.entity.topFloorRef ===
        this.getFloorRef(context, levelUid as string)
      ) {
        const obj = this.globalStore.getObjectOfType(EntityType.CONDUIT, uid);

        if (obj) {
          const objHeight = obj.entity.heightAboveFloorM;
          if (
            this.entity.riserType === "pipe" &&
            this.entity.riser.ventHeightM !== null &&
            this.entity.riser.ventHeightM < objHeight
          ) {
            return false;
          }
        }
      }
    }
    return true;
  }

  sortedLevels(context: CanvasContext, reversed: boolean): Level[] {
    if (reversed)
      return [...context.$store.getters["document/sortedLevels"]].reverse();
    return [...context.$store.getters["document/sortedLevels"]];
  }

  findIdxHelper(
    context: CanvasContext,
    uid: string,
    reversed: boolean,
  ): number {
    return this.sortedLevels(context, reversed).findIndex((level) => {
      return level.uid === uid;
    });
  }

  getInvalidConnections(context: CanvasContext) {
    // Get all connection's object id that not valid
    const connsEntityRaw = this.globalStore
      .getConnections(this.uid)
      .map((entityUid) => {
        return this.globalStore.get(entityUid)?.entity;
      });
    const floorsAcending = this.sortedLevels(context, true);
    const groundIdx = this.findIdxHelper(context, "ground", true);

    // Go through each and determine if it should be remove
    const removeList = [];
    const hintHelper = [];
    for (const entity of connsEntityRaw) {
      if (entity === undefined || entity.type !== EntityType.CONDUIT) continue;

      // Check this pipe's floor in range
      const floorId = this.globalStore.levelOfEntity.get(entity.uid);
      const floorRef = this.getFloorRef(context, floorId);

      if (
        this.entity.bottomFloorRef !== null &&
        floorRef < this.entity.bottomFloorRef
      ) {
        removeList.push(entity.uid);
        hintHelper.push(floorsAcending[groundIdx + floorRef]?.name);
      }
      if (
        this.entity.topFloorRef !== null &&
        floorRef > this.entity.topFloorRef
      ) {
        removeList.push(entity.uid);
        hintHelper.push(floorsAcending[groundIdx + floorRef]?.name);
      }
    }
    const hints = [...new Set(hintHelper.filter((obj) => obj !== undefined))];

    return {
      removeList,
      hints,
    };
  }

  getAttachCoords(): [Coord, Coord, Coord, Coord] {
    return [
      // left
      {
        x: -this.lastDrawnDiameterW / 2 - this.attachmentOffset,
        y: 0,
      },
      // right
      {
        x: this.lastDrawnDiameterW / 2 + this.attachmentOffset,
        y: 0,
      },
      // top
      {
        x: 0,
        y: -this.lastDrawnDiameterW / 2 - this.attachmentOffset,
      },
      // bottom
      {
        x: 0,
        y: this.lastDrawnDiameterW / 2 + this.attachmentOffset,
      },
    ];
  }
}
