<template>
  <a
    :href="href"
    :to="to"
    :exact="exact"
    :tabindex="tabindex"
    :target="target"
    @click="onClick"
    ><slot></slot
  ></a>
</template>

<script setup lang="ts">
import { trackEvent } from "../../api/mixpanel";

const props = withDefaults(
  defineProps<{
    testId: string;
    to?: any;
    href?: string;
    exact?: boolean;
    // See HButton For Details
    context: string;
    target?: "_blank" | "_self" | "_parent" | "_top";
    // See HButton For Details
    actionName: string;
    tabindex?: number;
  }>(),
  {
    to: undefined,
    exact: undefined,
    href: undefined,
    target: undefined,
    tabindex: 0,
  },
);

const emit = defineEmits<{
  (e: "click"): void;
}>();

function onClick() {
  trackEvent({
    type: "Button Click",
    props: {
      context: props.context,
      action: props.actionName,
    },
  });
  emit("click");
}
</script>
