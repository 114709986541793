import { Units, UnitsContext } from "../../../../lib/measurements";
import { CoreContext } from "../../../calculations/types";
import {
  getAllCoolingFlowSystems,
  getAllGasFlowSystems,
  getAllHeatingFlowSystems,
  isCooling,
  isHeatingPlantSystem,
} from "../../../config";
import { InletsOutletsSpec } from "../../../coreObjects/corePlant";
import { getInletsOutletSpecsInPlant } from "../../../coreObjects/utils";
import { getTooltip, getTooltipsEntityName } from "../../../tooltips/tooltips";
import { FlowSystem } from "../../flow-systems";
import { FieldType } from "../field-type";
import { PropertyField } from "../property-field";
import PlantEntity, {
  isRatingDerived,
  plantHasSingleRating,
} from "./plant-entity";
import { PlantType, ReturnSystemPlant } from "./plant-types";
import { PlantFieldHandlers } from "./types";
import { hasFixedGasRequirements, isHotWaterRheem } from "./utils";

export function createInletsTabFields(
  filled: PlantEntity,
  context: CoreContext,
  handlers?: PlantFieldHandlers,
): PropertyField[] {
  const drawing = context.drawing;
  const tooltipsEntityName = getTooltipsEntityName(filled, drawing);
  const isReturnSystem = filled.plant.type === PlantType.RETURN_SYSTEM;
  const isDrainagePit = filled.plant.type === PlantType.DRAINAGE_PIT;
  const isDrainageGreaseInterTrap =
    filled.plant.type === PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP;

  const isRheem =
    filled.plant.type === PlantType.RETURN_SYSTEM &&
    isHotWaterRheem(drawing, filled.plant.returnType);

  const hasSetGasRequirements = hasFixedGasRequirements(
    drawing,
    filled.plant as ReturnSystemPlant,
  );

  const addColdWaterInlet =
    !isReturnSystem ||
    (isReturnSystem &&
      (filled.plant as ReturnSystemPlant).addColdWaterInlet === true);

  const allowPreheats = isReturnSystem && !isRheem;
  const hasAPreheat =
    filled.plant.type === PlantType.RETURN_SYSTEM &&
    allowPreheats &&
    filled.plant.preheats.length > 0;
  const hasMultiplePreheats =
    filled.plant.type === PlantType.RETURN_SYSTEM &&
    allowPreheats &&
    filled.plant.preheats.length > 1;

  const gasFlowsystems = getAllGasFlowSystems(context);
  const heatingFlowsystems = getAllHeatingFlowSystems(context);
  const coolingFlowsystems = getAllCoolingFlowSystems(context);

  const isHeatingHeatSource =
    filled.plant.type === PlantType.RETURN_SYSTEM &&
    isHeatingPlantSystem(
      context.drawing.metadata.flowSystems[
        filled.plant.outlets[0].outletSystemUid
      ],
    );
  const isCoolingHeatSource =
    filled.plant.type === PlantType.RETURN_SYSTEM &&
    isCooling(
      context.drawing.metadata.flowSystems[
        filled.plant.outlets[0].outletSystemUid
      ],
    );
  const isCibseDiversified =
    filled.plant.type === PlantType.RETURN_SYSTEM &&
    (filled.plant as ReturnSystemPlant).isCibseDiversified === true;

  const isEnergyLoad =
    plantHasSingleRating(filled.plant) && filled.plant.rating.type === "energy";
  const isFlowRateLoad =
    plantHasSingleRating(filled.plant) &&
    filled.plant.rating.type === "flow-rate";

  const isHeatingLoadCalculated =
    filled.plant.type === PlantType.RETURN_SYSTEM &&
    isRatingDerived(context, filled.plant);

  const inletsOutletsSpecs: InletsOutletsSpec[] = getInletsOutletSpecsInPlant(
    context,
    filled,
  );

  const hasGasInlet = inletsOutletsSpecs.some(
    (spec) => spec.uid === (filled.plant as ReturnSystemPlant).gasNodeUid,
  );

  const inletSysRole = drawing.metadata.flowSystems[filled.inletSystemUid].role;
  const inletFlowSystems = drawing.metadata.flowSystemUidsInOrder
    .map((uid) => {
      const fs = drawing.metadata.flowSystems[uid];
      return fs.role === inletSysRole ? fs : null;
    })
    .filter(Boolean) as FlowSystem[];

  const res: PropertyField[] = [
    {
      property: "plant.addColdWaterInlet",
      title: "Is there a cold water inlet?",
      hint: getTooltip(tooltipsEntityName, "Is there a cold water inlet?"),
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Boolean,
      params: null,
      multiFieldId: "plant-addColdWaterInlet",
      isShown: isReturnSystem && !isRheem,
    },
    {
      property: "inletSystemUid",
      title: "Inlet Flow System",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.FlowSystemChoice,
      params: {
        systems: inletFlowSystems,
      },
      multiFieldId: "inletSystemUid",
      isShown: addColdWaterInlet,
    },
    {
      property: "inletHeightAboveFloorM",
      title: "Inlet Height Above Floor",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: null, max: null },
      multiFieldId: "inletHeightAboveFloorM",
      units: Units.Meters,
      isShown:
        !isDrainagePit && !isDrainageGreaseInterTrap && addColdWaterInlet,
    },
    {
      property: "plant.separator",
      title: "",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Divider,
      multiFieldId: "plant-separator",
      isShown: isReturnSystem && !isRheem,
    },
    {
      property: "plant.addGasInlet",
      title: "Is there a gas inlet?",
      hint: getTooltip(tooltipsEntityName, "Is there a gas inlet?"),
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Boolean,
      params: null,
      multiFieldId: "plant-addGasInlet",
      isShown: isReturnSystem && !isRheem && !isCoolingHeatSource,
    },
    {
      property: "plant.gasInletSystemUid",
      title: "Gas Inlet Flow System",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.FlowSystemChoice,
      params: { systems: gasFlowsystems },
      multiFieldId: "plant-gasInletSystemUid",
      isShown: hasGasInlet,
    },
    {
      property: "plant.gasConsumptionMJH",
      title: "Gas Consumption",
      hasDefault: !hasSetGasRequirements,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      readonly: hasSetGasRequirements,
      multiFieldId: "plant-gasConsumptionMJH",
      units: Units.MegajoulesPerHour,
      isShown: hasGasInlet,
      unitContext: UnitsContext.GAS_ENERGY_MEASUREMENT,
    },
    {
      property: "plant.diversity",
      title: "Diversity",
      hint: getTooltip(tooltipsEntityName, "Diversity"),
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "diversity",
      units: Units.Percent,
      isShown: hasGasInlet,
    },
    {
      property: "plant.gasPressureKPA",
      hint: hasSetGasRequirements
        ? "This value has been determined by the model."
        : "The default value may have been determined by an upstream gas pressure regulator.",
      title: "Gas Pressure",
      hasDefault: !hasSetGasRequirements,
      isCalculated: false,
      type: FieldType.Number,
      readonly: hasSetGasRequirements,
      params: { min: 0, max: null },
      multiFieldId: "plant-gasPressureKPA",
      units: Units.GasKiloPascals,
      isShown: hasGasInlet,
      unitContext: UnitsContext.GAS_ENERGY_MEASUREMENT,
    },
    {
      property: "plant.separator2",
      title: "",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Divider,
      multiFieldId: "plant-separator2",
      isShown: isReturnSystem && !isRheem && !isCoolingHeatSource,
    },
    {
      property: "plant.preheatSystemUid",
      title: "Pre-Heat Flow System",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.FlowSystemChoice,
      params: {
        systems: isHeatingHeatSource ? heatingFlowsystems : coolingFlowsystems,
      },
      multiFieldId: "plant-preheatSystemUid",
      isShown: hasAPreheat,
    },
    {
      property: "plant.domesticWaterLoadKW",
      title: "Domestic Water Load",
      hint: getTooltip(tooltipsEntityName, "Domestic Water Load"),
      hasDefault: false,
      isCalculated: false,
      requiresInput: true,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "plant-domesticWaterLoadKW",
      units: Units.KiloWatts,
      isShown: hasAPreheat && isCibseDiversified,
      unitContext: UnitsContext.MECHANICAL_ENERGY_MEASUREMENT,
    },
    {
      property: "plant.rating.type",
      type: FieldType.Choice,
      params: {
        choices: [
          { name: "Power", key: "energy" },
          { name: "Flow Rate", key: "flow-rate" },
        ],
      },
      hasDefault: false,
      isCalculated: false,
      multiFieldId: "plant-rating-type",
      title: "Rating Method",
      hint: getTooltip(tooltipsEntityName, "Rating Method"),
      isShown: hasAPreheat,
    },
    {
      property: "plant.rating.KW",
      title: isCibseDiversified ? "Heating Load" : "Rating",
      hint: isHeatingLoadCalculated
        ? "The heat load required by the preheat loop."
        : "The heat load required to heat the hot water, h2x does not calculate this automatically.",
      hasDefault: false,
      isCalculated: isHeatingLoadCalculated,
      requiresInput: !isHeatingLoadCalculated,
      type: FieldType.Number,
      params: {
        min: 0,
        max: null,
      },
      multiFieldId: "plant-ratingKW",
      units: Units.KiloWatts,
      isShown: isEnergyLoad && hasAPreheat,
      unitContext: UnitsContext.MECHANICAL_ENERGY_MEASUREMENT,
    },
    {
      property: "plant.rating.LS",
      title: isCibseDiversified ? "Flow Rate Heating Load" : "Flow Rate Rating",
      hint: isHeatingLoadCalculated
        ? "The heat load required by the preheat loop."
        : "The heat load required to heat the hot water, h2x does not calculate this automatically.",
      hasDefault: false,
      isCalculated: isHeatingLoadCalculated,
      requiresInput: !isHeatingLoadCalculated,
      type: FieldType.Number,
      params: {
        min: 0,
        max: null,
      },
      multiFieldId: "plant-ratingLS",
      units: Units.LitersPerSecond,
      isShown: isFlowRateLoad && hasAPreheat,
    },
  ];

  if (hasAPreheat && filled.plant.type === PlantType.RETURN_SYSTEM) {
    for (let i = 0; i < filled.plant.preheats.length; i++) {
      const preheat = filled.plant.preheats[i];
      preheat.explicitRating;

      const isPreheatRatingPercentage = preheat.ratingMode === "percentage";
      const isPreheatEnergyRating = preheat.explicitRating.type === "energy";
      res.push(
        {
          property: `plant.preheat-separator-${i}`,
          title: "",
          hasDefault: false,
          isCalculated: false,
          type: FieldType.Divider,
          multiFieldId: `plant-preheat-separator-${i}`,
          isShown: hasMultiplePreheats,
        },
        {
          property: `plant.preheat-group-${i}`,
          title: `Preheat ${i + 1}`,
          hasDefault: false,
          isCalculated: false,
          type: FieldType.Title,
          params: null,
          multiFieldId: `plant-preheat-group-${i}`,
          isShown: hasMultiplePreheats,
        },
        {
          property: `plant.preheats.${i}.volumeL`,
          title: "Volume",
          hint: getTooltip(tooltipsEntityName, "Volume"),
          hasDefault: false,
          isCalculated: false,
          requiresInput: true,
          type: FieldType.Number,
          params: { min: 0, max: null },
          multiFieldId: `plant-preheatVolume-${i}`,
          units: Units.Liters,
          isShown: hasAPreheat,
        },
        {
          property: `plant.preheats.${i}.heightAboveFloorM`,
          title: "Inlet Height Above Floor",
          hasDefault: true,
          isCalculated: false,
          type: FieldType.Number,
          params: { min: null, max: null },
          multiFieldId: "plant-preheatInletHeightAboveFloorM",
          units: Units.Meters,
        },
        {
          property: `plant.preheats.${i}.ratingMode`,
          title: "Rating Mode",
          hasDefault: false,
          isCalculated: false,
          type: FieldType.Choice,
          params: {
            choices: [
              { name: "Set Manually", key: "explicit" },
              { name: "Percentage", key: "percentage" },
            ],
          },
          multiFieldId: `plant-preheatRatingMode`,
          isShown: hasMultiplePreheats,
        },
        {
          property: `plant.preheats.${i}.ratingPCT`,
          title: "Rating Percentage",
          hint: "Percentage of remaining heat load (after subtracting manually set preheat loads) to be provided by this preheat loop.",
          hasDefault: true,
          isCalculated: false,
          type: FieldType.Number,
          params: { min: 0, max: null },
          multiFieldId: `plant-preheatRatingPCT`,
          units: Units.Percent,
          isShown: isPreheatRatingPercentage && hasMultiplePreheats,
        },
        {
          property: `plant.preheats.${i}.explicitRating.type`,
          type: FieldType.Choice,
          params: {
            choices: [
              { name: "Power", key: "energy" },
              { name: "Flow Rate", key: "flow-rate" },
            ],
          },
          hasDefault: false,
          isCalculated: false,
          multiFieldId: "plant-rating-type",
          title: `Preheat ${i + 1} Rating Method`,
          hint: getTooltip(tooltipsEntityName, "Rating Method"),
          isShown: !isPreheatRatingPercentage && hasMultiplePreheats,
        },
        {
          property: `plant.preheats.${i}.explicitRating.KW`,
          title: isCibseDiversified ? "Heating Load" : "Rating",
          hasDefault: false,
          isCalculated: false,
          requiresInput: true,
          type: FieldType.Number,
          params: {
            min: 0,
            max: null,
          },
          multiFieldId: "plant-ratingKW",
          units: Units.KiloWatts,
          isShown:
            !isPreheatRatingPercentage &&
            isPreheatEnergyRating &&
            hasMultiplePreheats,
          unitContext: UnitsContext.MECHANICAL_ENERGY_MEASUREMENT,
        },
        {
          property: `plant.preheats.${i}.explicitRating.LS`,
          title: isCibseDiversified
            ? "Flow Rate Heating Load"
            : "Flow Rate Rating",
          hasDefault: false,
          isCalculated: false,
          requiresInput: true,
          type: FieldType.Number,
          params: {
            min: 0,
            max: null,
          },
          multiFieldId: "plant-ratingLS",
          units: Units.LitersPerSecond,
          isShown:
            !isPreheatRatingPercentage &&
            !isPreheatEnergyRating &&
            hasMultiplePreheats,
        },
        {
          property: `plant.preheats.${i}.pressureDropKPA`,
          title: "Pressure Drop",
          hint: getTooltip(tooltipsEntityName, "Pressure Drop"),
          hasDefault: true,
          isCalculated: false,
          type: FieldType.Number,
          params: { min: 0, max: null },
          multiFieldId: "plant-pressureDropKPA",
          units: Units.KiloPascals,
        },
        {
          type: FieldType.Button,
          property: `removePlantOutlet${i}`,
          title: "Remove Preheat",
          hasDefault: false,
          isCalculated: false,
          multiFieldId: null,
          size: "sm",
          pill: true,
          variant: "outline-danger",
          params: {
            handler: async () =>
              handlers?.removePreheatHandler?.(context, filled.uid, i),
          },
          isShown: hasAPreheat,
        },
      );
    }
  }

  res.push({
    type: FieldType.Button,
    property: "addPlantOutlet",
    title: isCoolingHeatSource ? "+ Add Cooling Inlet" : "+ Add Preheat Inlet",
    hasDefault: false,
    isCalculated: false,
    multiFieldId: null,
    size: "sm",
    pill: true,
    variant: "outline-success",
    params: {
      handler: async () =>
        handlers?.addNewPreheatHandler?.(context, filled.uid),
    },
    isShown: isReturnSystem,
  });

  return res;
}
