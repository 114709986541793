import Flatten from "@flatten-js/core";
import { Logger } from "../../../../lib/logger";
import { Edge } from "../../graph";
import { LoopID } from "./transit-generator";
import {
  GraphEdge,
  GraphNode,
  GraphNodeEntryData,
  getEntryCableNormal,
} from "./utils";

export interface CableEdge {
  uid: string;
  start: Flatten.Point;
  end: Flatten.Point;
  normal: Flatten.Vector;

  // center is used for doors and manifold connections that have no walls, for example.
  // ccw is for edge runs along fixed walls.
  // Also, center is for edges connecting two heated areas (faux walls).
  // cw is not used for walls (that would make the points go into the wall)
  // but it is used for more precise alighment in doors/passthroughs.
  justification: "cw" | "ccw" | "center" | "cw-thru" | "ccw-thru";
}

// A normal edge but with extra info to help with correct
// cable alignment in tricky cases.
export interface CablePathEdge extends Edge<GraphNode, GraphEdge> {
  adjAlign: "ccw" | "cw" | "center" | "cw-thru" | "ccw-thru" | null;
  adjRawDir: "ccw" | "cw" | null;
  isInverted?: boolean;
}

export interface CableNode {
  uid: string;
  point: Flatten.Point;

  // Once this hits 0, process this node.
  unorderedFlowOuts: number;

  // Flow outs are manifold pipes going out of the node.
  // The graph is reconstructed backwards from the loops
  flowOuts: Array<{
    wallUid: string;
    edgeUid: string;
    vector: Flatten.Vector;
    targets: LoopID[]; // in CW order.
    terminal: boolean;
    wall: "cw" | "ccw";
  }>;
  // And so in this processing order, flowIns are this node's "parents".
  flowIns: Array<{
    edgeUid: string;
    nodeUid: string;
    vector: Flatten.Vector;
    wall: "cw" | "ccw";
  }>;
}

// Bit too intricate to use the standard graph class.
export class CableGraph {
  nodes: Map<string, CableNode>;
  edges: Map<string, CableEdge>;
  nextLoopID: number;
  pathsBySite: Map<
    string,
    { path: CablePathEdge[]; entry: GraphNodeEntryData }
  >;

  constructor() {
    this.nodes = new Map();
    this.edges = new Map();
    this.pathsBySite = new Map();
    this.nextLoopID = 0;
  }

  getOrCreateCableNode(node: GraphNode) {
    if (!this.nodes.has(node.uid)) {
      this.nodes.set(node.uid, {
        uid: node.uid,
        point: node.point,
        unorderedFlowOuts: 0,
        flowOuts: [],
        flowIns: [],
      });
    }
    return this.nodes.get(node.uid)!;
  }

  getOrCreateFlowOut(node: CableNode, edge: Edge<GraphNode, GraphEdge>) {
    const existing = node.flowOuts.find((f) => f.edgeUid === edge.uid);

    if (existing) {
      return existing;
    } else {
      const edgeVector = Flatten.vector(edge.from.point, edge.to.point);
      const cross = edgeVector.cross(edge.value.normal);
      node.flowOuts.push({
        wallUid: edge.from.uid,
        edgeUid: edge.uid,
        vector: edgeVector,
        targets: [],
        terminal: false,
        wall: cross > 0 ? "ccw" : "cw",
      });
      node.unorderedFlowOuts++;
      return node.flowOuts[node.flowOuts.length - 1];
    }
  }

  getOrCreateFlowIn(node: CableNode, edge: Edge<GraphNode, GraphEdge>) {
    const existing = node.flowIns.find((f) => f.edgeUid === edge.uid);

    if (existing) {
      return existing;
    } else {
      const edgeVector = Flatten.vector(edge.from.point, edge.to.point);
      if (edgeVector.length === 0) {
        Logger.error(
          "Zero length edge being created",
          {},
          {
            edge,
            node,
          },
        );
        // make breakpoint
      }
      const cross = edgeVector.cross(edge.value.normal);
      node.flowIns.push({
        edgeUid: edge.uid,
        nodeUid: edge.from.uid,
        vector: edgeVector.multiply(-1),
        wall: cross > 0 ? "ccw" : "cw",
      });
      return node.flowIns[node.flowIns.length - 1];
    }
  }

  ensureEdge(edge: Edge<GraphNode, GraphEdge>) {
    if (!this.edges.has(edge.uid)) {
      this.edges.set(edge.uid, {
        uid: edge.uid,
        start: edge.from.point,
        end: edge.to.point,
        normal: edge.value.normal,
        justification: edge.value.align,
      });
    }
  }

  // edges: list of edges from manifold to loop.
  // edges[edges.length - 1].to must be the terminal node.
  insertLoop(
    edges: CablePathEdge[],
    entry: GraphNodeEntryData,
    debugObj?: any,
  ) {
    this.pathsBySite.set(entry.entrySiteUid, { path: edges, entry });
    for (let i = 0; i < edges.length; i++) {
      const edge = edges[i];
      const fromNode = this.getOrCreateCableNode(edge.from);
      const toNode = this.getOrCreateCableNode(edge.to);
      this.ensureEdge(edge);
      const flowOut = this.getOrCreateFlowOut(fromNode, edge);
      flowOut.targets.push(entry.entrySiteUid);
      const flowIn = this.getOrCreateFlowIn(toNode, edge);

      if (i === edges.length - 1) {
        const terminalFlowOut = this.getOrCreateFlowOut(toNode, edge);
        terminalFlowOut.terminal = true;

        terminalFlowOut.vector = getEntryCableNormal(entry);
        terminalFlowOut.targets.push(entry.entrySiteUid);
        // terminal ones don't count as having dependencies
        if (terminalFlowOut.targets.length === 1) {
          toNode.unorderedFlowOuts--;
        }
      }
    }
  }

  solve() {
    // Sort all outflows by angles. "counterclockwise" mathematically.
    for (const node of this.nodes.values()) {
      const oracle = Flatten.vector(0, 1);
      node.flowOuts.sort((a, b) => {
        return b.vector.angleTo(oracle) - a.vector.angleTo(oracle);
      });
    }

    const queue: CableNode[] = [];
    for (const node of this.nodes.values()) {
      if (node.unorderedFlowOuts === 0) {
        queue.push(node);
      }
    }

    while (queue.length > 0) {
      const node = queue.pop()!;
      for (const flowIn of node.flowIns) {
        const fromNode = this.nodes.get(flowIn.nodeUid)!;
        fromNode.unorderedFlowOuts--;
        if (fromNode.unorderedFlowOuts === 0) {
          queue.push(fromNode);
        }

        const parentFlowOut = fromNode.flowOuts.find(
          (o) => o.edgeUid === flowIn.edgeUid,
        )!;
        const toSolve = parentFlowOut.targets;
        const correctOrder: LoopID[] = [];

        let startOutIndex = 0;
        let bestDiff = Infinity;
        for (let i = 0; i < node.flowOuts.length; i++) {
          const out = node.flowOuts[i];
          const angle = flowIn.vector.angleTo(out.vector);
          if (angle < bestDiff) {
            bestDiff = angle;
            startOutIndex = i;
          }
        }

        for (let i = 0; i < node.flowOuts.length; i++) {
          const checkIndex = (startOutIndex + i) % node.flowOuts.length;
          const checkOut = node.flowOuts[checkIndex];

          for (const loopId of checkOut.targets) {
            if (toSolve.includes(loopId)) {
              correctOrder.push(loopId);
            }
          }
        }

        // correctOrder.reverse();

        if (correctOrder.length !== toSolve.length) {
          console.warn("Unable to solve everything for", node.uid, ":", {
            correctOrder,
            parentFlowOut,
            toSolve,
            node,
          });
        }
        parentFlowOut.targets = correctOrder;
      }
    }
  }
}
