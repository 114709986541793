import * as TM from "transformation-matrix";
import {
  CalculationData,
  CalculationDataType,
} from "../../../../../common/src/api/document/calculations-objects/calculation-field";
import { Level } from "../../../../../common/src/api/document/drawing";
import BigValveEntity from "../../../../../common/src/api/document/entities/big-valve/big-valve-entity";
import {
  DrawableEntityConcrete,
  isCenteredEntity,
  isEdgeEntity,
} from "../../../../../common/src/api/document/entities/concrete-entity";
import ConduitEntity from "../../../../../common/src/api/document/entities/conduit-entity";
import DirectedValveEntity from "../../../../../common/src/api/document/entities/directed-valves/directed-valve-entity";
import FixtureEntity from "../../../../../common/src/api/document/entities/fixtures/fixture-entity";
import FlowSourceEntity from "../../../../../common/src/api/document/entities/flow-source-entity";
import GasApplianceEntity from "../../../../../common/src/api/document/entities/gas-appliance";
import LoadNodeEntity, {
  NodeType,
} from "../../../../../common/src/api/document/entities/load-node-entity";
import { MultiwayValveEntity } from "../../../../../common/src/api/document/entities/multiway-valves/multiway-valve-entity";
import PlantEntity, {
  isSpecifyRadiator,
  isV2RadiatorEntity,
} from "../../../../../common/src/api/document/entities/plants/plant-entity";
import RiserEntity from "../../../../../common/src/api/document/entities/riser-entity";
import { EntityType } from "../../../../../common/src/api/document/entities/types";
import { FlowSystem } from "../../../../../common/src/api/document/flow-systems";
import { fillEntityDefaults } from "../../../../../common/src/api/document/utils";
import { Coord } from "../../../../../common/src/lib/coord";
import {
  betterObjectValues,
  cloneSimple,
} from "../../../../../common/src/lib/utils";
import { trackEvent } from "../../../api/mixpanel";
import { getActiveWorkflowsList } from "../../../lib/workflows-utils";
import { DocumentState } from "../../../store/document/types";
import {
  CenteredObjectConcrete,
  DrawableCalculatedObjectConcrete,
} from "../../objects/concrete-object";
import {
  DrawableV2Radiator,
  DrawableV2RadiatorPlant,
} from "../../objects/v2-radiator/drawableV2RadiatorPlant";
import CanvasContext from "../canvas-context";

interface AutoCadJson {
  metadata: {
    flowSystems: Array<FlowSystem>;
    levels: Array<{
      uid: string;
      name: string;
      abbreviation: string;
      floorHeightM: number;
    }>;
  };
  entities: {
    [key in keyof AcceptedEntities]: Array<
      AcceptedEntities[key] & { levelUid: string | null }
    >;
  };
  resultLabels: ResultLabel[];
}

export type ConduitEntityWithEndpointCoords = ConduitEntity & {
  endpointCoords: [Coord, Coord];
};
type AcceptedEntities = {
  [EntityType.RISER]: RiserEntity;
  [EntityType.CONDUIT]: ConduitEntityWithEndpointCoords;
  [EntityType.BIG_VALVE]: BigValveEntity;
  [EntityType.FIXTURE]: FixtureEntity;
  [EntityType.DIRECTED_VALVE]: DirectedValveEntity;
  [EntityType.MULTIWAY_VALVE]: MultiwayValveEntity;
  [EntityType.PLANT]: PlantEntity;
  [EntityType.FLOW_SOURCE]: FlowSourceEntity;
  [EntityType.GAS_APPLIANCE]: GasApplianceEntity;
  [EntityType.LOAD_NODE]: LoadNodeEntity;
};

const ACCEPTED_ENTITIES: Record<
  keyof AcceptedEntities,
  boolean | ((entity: DrawableEntityConcrete) => boolean)
> = {
  [EntityType.RISER]: true,
  [EntityType.CONDUIT]: true,
  [EntityType.BIG_VALVE]: true,
  [EntityType.FIXTURE]: true,
  [EntityType.DIRECTED_VALVE]: true,
  [EntityType.MULTIWAY_VALVE]: true,
  [EntityType.PLANT]: true,
  [EntityType.FLOW_SOURCE]: true,
  [EntityType.GAS_APPLIANCE]: true,
  [EntityType.LOAD_NODE]: (ln) => {
    if (ln.type === EntityType.LOAD_NODE) {
      if (ln.node.type === NodeType.VENTILATION) {
        return true;
      }
    }
    return false;
  },
};

export type AutoCADExportEntity =
  AutoCadJson["entities"][keyof AutoCadJson["entities"]][0];

export type AutoCADExportConduitEntity = AutoCADExportEntity & {
  type: EntityType.CONDUIT;
};

interface ResultLabel {
  results: string[];
  coord: Coord;
  angleRad: number;
  systemUid: string;
  levelUid: string;
  entityUid: string;
}

const NULLABLE_FIELDS_TYPE: Record<string, "number" | "string"> = {
  levelUid: "string",
  entityName: "string",
  parentUid: "string",
  calculationHeightM: "number",
  latitude: "number",
  longitude: "number",
  dwellingMethod: "string",
  districtHeating: "string",
  gasCalcMethod: "string",
  selected: "string",
  pressureDropKPA: "number",
  pumpDutyKPA: "number",
  pumpDutyString: "string",
  pumpFlowRateLS: "number",
  capacityL: "number",
  maxCapacityAvailableL: "number",
  heatingRatingKW: "number",
  chilledRatingKW: "number",
  returnDeltaC: "number",
  returnAverageC: "number",
  heatingRatingAt50DtKW: "number",
  modelReference: "string",
  entryPressureKPA: "number",
  exitPressureKPA: "number",
  isInletHydrated: "string",
  gasConsumptionMJH: "number",
  gasFlowRateMJH: "number",
  gasPressureKPA: "number",
  totalSystemVolumeL: "number",
  totalPipeVolumeL: "number",
  totalHeatEmitterVolumeL: "number",
  totalPlantVolumeL: "number",
  totalHeatLoadKW: "number",
  size: "string",
  widthMM: "number",
  heightMM: "number",
  depthMM: "number",
  minPressureKPA: "number",
  maxPressureKPA: "number",
  indexCircuitLengthM: "number",
  intakeIndexCircuitPressureDropKPA: "number",
  exhaustIndexCircuitPressureDropKPA: "number",
  supplyIndexCircuitPressureDropKPA: "number",
  extractIndexCircuitPressureDropKPA: "number",
  supplyFlowRateLS: "number",
  extractFlowRateLS: "number",
  supplyFanDutyKPA: "number",
  extractFanDutyKPA: "number",
  supplyFanDutyString: "string",
  extractFanDutyString: "string",
  SCOP: "number",
  SPF: "number",
  volumeL: "number",
  kvValue: "number",
  manifoldId: "number",
  outletTemperatureC: "number",
  demandMJH: "number",
  flowRateLS: "number",
  sizeMM: "number",
  interiorPressureDropKPA: "number",
  exteriorPressureDropKPA: "number",
  fanDutyKPA: "number",
  returnLoopHeatLossKW: "number",
  returnHeatingSystemLoopHeatLossKW: "number",
  circulationPumpDutyString: "string",
  circulationFlowRateLS: "number",
  circulationPressureLoss: "number",
  circulatingPumpModel: "string",
  maxiumumSimutaneousNode: "number",
  heightM: "number",
  lengthM: "number",
  flowFrom: "string",
  totalPeakFlowRateLS: "number",
  PSDFlowRateLS: "number",
  noFlowAvailableReason: "string",
  rawPSDFlowRateLS: "number",
  rawReturnFlowRateLS: "number",
  returnFlowRateLS: "number",
  optimalInnerPipeDiameterMM: "number",
  realNominalPipeDiameterMM: "number",
  realInternalDiameterMM: "number",
  realOutsideDiameterMM: "number",
  pressureDropKPAPerMeter: "number",
  psdProfile: "string",
  configuration: "string",
  materialName: "string",
  velocityRealMS: "number",
  temperatureRange: "string",
  gradePCT: "number",
  gasMJH: "number",
  stackDedicatedVentSize: "number",
  ventRoot: "string",
  ventTooFarDist: "string",
  ventTooFarWC: "string",
  fallM: "number",
  isIndexCircult: "string",
  isIndexNodePath: "string",
  totalKW: "number",
  diversifiedKW: "number",
  closedKW: "number",
  domesticKW: "number",
  diversifiedTotalKW: "number",
  diversifiedClosedKW: "number",
  diversifiedDomesticKW: "number",
  fireFlowRateLS: "number",
  diameterMM: "number",
  crossSectionAreaM2: "number",
  rawFlowRateLS: "number",
  cycle: "number",
  unbalanced: "string",
  overlapped: "string",
  v: "string",
  value: "number",
  psdUnits: "string",
  connected: "string",
  cost: "string",
  pressureKPA: "number",
  staticPressureKPA: "number",
  solarWPerMin: "number",
  externalFacingDegree: "number",
  label: "string",
  isLeaderRoomPerFloor: "string",
  isZoneLeader: "string",
  zoneVentFlowRateLS: "number",
  zoneSupplyVentFlowRateAddressedLS: "number",
  zoneExtractVentFlowRateAddressedLS: "number",
  targetLoops: "number",
  heatLossKW: "number",
  heatContributionKW: "number",
  totalHeatLossWatt: "number",
  totalHeatGainWatt: "number",
  totalHeatLossAddressedWATT: "number",
  totalHeatGainAddressedWATT: "number",
  airChangeRatePerHour: "number",
  ventilationFlowRateLS: "number",
  floorHeadLoadInfo: "string",
  buildingHeatLoadInfo: "string",
  reference: "string",
  volumeM3: "number",
  color: "string",
  deadlegVolumeL: "number",
  deadlegLengthM: "number",
  deadlegWaitTimeS: "number",
  heightAboveGround: "number",
  ventSizeMM: "number",
  isVentExit: "string",
  axialRotDEG: "number",
  bottomHeightM: "number",
  topHeightM: "number",
  lvlAboveUid: "string",
  coldTemperatureC: "number",
  coldPressureKPA: "number",
  coldRawPeakFlowRate: "number",
  coldPeakFlowRate: "number",
  coldPsdUs: "string",
  hotTemperatureC: "number",
  hotPressureKPA: "number",
  hotRawPeakFlowRate: "number",
  hotPeakFlowRate: "number",
  hotPsdUs: "string",
  hotReturnFlowRateLS: "number",
  hotTotalFlowRateLS: "number",
  temperatureC: "number",
  mixingValveSizeMM: "number",
  wallMaterialUid: "string",
  uValueW_M2K: "number",
  externalTemperatureC: "number",
  wallType: "string",
  neighboringSpaceTemperatureC: "number",
  thicknessMM: "number",
  transitionAngle: "number",
  elbowVanes: "number",
  pieces: "number",
  smoothElbowRadiusRatio: "number",
  shoeLengthRatio: "number",
  loadingUnits: "number",
  designFlowRateLS: "number",
  continuousFlowLS: "number",
  asnzFixtureUnits: "number",
  enDischargeUnits: "number",
  upcFixtureUnits: "number",
  nbcIndiaFlushTankPrivateDFU: "number",
  nbcIndiaFlushTankPublicDFU: "number",
  nbcIndiaFlushValvePrivateDFU: "number",
  nbcIndiaFlushValvePublicDFU: "number",
  hcaaFixtureType: "string",
  diversity: "number",
  heightAboveFloorM: "number",
  systemUidOption: "string",
  linkedToUid: "string",
  uid: "string",
  inletPressureKPA: "number",
  flowRateMJH: "number",
  materialUid: "string",
  ventHeightM: "number",
  maximumVelocityMS: "number",
  maximumPressureDropRateKPAM: "number",
  material: "string",
  bottomFloorRef: "number",
  topFloorRef: "number",
  min: "number",
  max: "number",
  multiFieldId: "string",
  params: "string",
  url: "string",
  heightAboveGroundM: "number",
  configurationCosmetic: "string",
  sizingIncrementMM: "number",
  maxWidthMM: "number",
  maxHeightMM: "number",
  targetWHRatio: "number",
  angleDEG: "number",
  page: "string",
  offsetPosMM: "number",
  pointA: "string",
  pointB: "string",
  shape: "string",
  outletHeightAboveFloorM: "number",
  pressureLossKPA: "number",
  pumpPressureKPA: "number",
  manufacturer: "string",
  model: "string",
  peakFlowRateStorageMinutes: "number",
  targetPressureKPA: "number",
  KW: "number",
  LS: "number",
  supplyUid: "string",
  extractUid: "string",
  intakeUid: "string",
  exhaustUid: "string",
  heatRecoveryEfficiencyPct: "number",
  capacityRateLKW: "number",
  heatingInletUid: "string",
  heatingOutletUid: "string",
  heatingHeightAboveFloorM: "number",
  chilledInletUid: "string",
  chilledOutletUid: "string",
  chilledHeightAboveFloorM: "number",
  gasNodeUid: "string",
  newIoLayout: "string",
  outletUid: "string",
  outletReturnUid: "string",
  returnLimitTemperatureC: "number",
  returnVelocityMS: "number",
  ratingPCT: "number",
  rheemVariant: "string",
  rheemPeakHourCapacity: "number",
  rheemMinimumInitialDelivery: "number",
  rheemkWRating: "number",
  rheemStorageTankSize: "number",
  preheatInletHeightAboveFloorM: "number",
  domesticWaterLoadKW: "number",
  gasInletSystemUid: "string",
  preheatSystemUid: "string",
  noiseReportSetting: "string",
  dairyDomesticWaterLoadL: "number",
  legionellaPurgeTemperatureC: "number",
  MCSCertificateNumber: "string",
  lengthMM: "number",
  location: "string",
  position: "string",
  capacity: "string",
  brand: "string",
  soundPowerLevelDB: "string",
  directivity: "string",
  distanceMM: "number",
  barrier: "string",
  dBReductionDistanceDB: "string",
  dBReductionBarrierDB: "string",
  soundPressureLevelDB: "string",
  backgroundNoiseLevelDB: "string",
  diffBgPumpDB: "string",
  dBCorrectedDB: "string",
  permitted: "string",
  radSizes: "string",
  inletUid: "string",
  name: "string",
  inletHeightAboveFloorM: "number",
  valveSizeMM: "number",
  drawingLayout: "string",
  outletAboveFloorM: "number",
  warmTempC: "number",
  achACR: "number",
  lpsACR: "number",
  lpsm2ACR: "number",
  lpsPersonACR: "number",
  lpsShowerACR: "number",
  lpsBathACR: "number",
  lpsWCACR: "number",
  lpsUrinalACR: "number",
  lpsMachineACR: "number",
  cmsACR: "number",
  cfmft2ACR: "number",
  personCount: "number",
  showerCount: "number",
  bathCount: "number",
  wcCount: "number",
  urinalCount: "number",
  machineCount: "number",
  roomTemperatureC: "number",
  groundTemperatureC: "number",
  roomHeightM: "number",
  roofMaterialUid: "string",
  floorMaterialUid: "string",
  entryFenUid: "string",
  manifoldUid: "string",
  heatLoadKW: "number",
  pipeSpacingMM: "number",
  externalWallMaterialUid: "string",
  widthM: "number",
  raiseHeightM: "number",
  peakHeightM: "number",
  zeta: "number",
  outletPressureKPA: "number",
  downStreamPressureKPA: "number",
  kValue: "number",
  minInletPressureKPA: "number",
  maxInletPressureKPA: "number",
  maxHotColdPressureDifferentialPCT: "number",
  minFlowRateLS: "number",
  maxFlowRateLS: "number",
};

function isAcceptedEntity(
  entity: DrawableEntityConcrete,
): entity is AcceptedEntities[keyof AcceptedEntities] {
  return entity.type in ACCEPTED_ENTITIES;
}

export function getAllResultsLabels(context: CanvasContext): ResultLabel[] {
  const resultLabels: ResultLabel[] = [];

  for (const levelUid of Object.keys(context.drawing.levels)) {
    context.document.uiState.levelUid = levelUid;
    context.backgroundLayer.reloadLevel();
    context.hydraulicsLayer.reloadLevel();
    context.calculationLayer.reloadLevel();

    const labels = context.calculationLayer
      .getCurrentLayout(context.lastDrawingContext!, true)
      ?.getLabels();
    if (!labels) continue;

    for (const label of labels ?? []) {
      const resultLabel = getResultData(context, label);
      if (resultLabel) resultLabels.push(resultLabel);
    }
  }

  return resultLabels;
}

function getResultData(
  context: CanvasContext,
  label: [string, TM.Matrix, CalculationData[], boolean],
): ResultLabel | null {
  const o = context.globalStore.get<DrawableCalculatedObjectConcrete>(label[0]);
  let results: string[] = [];
  let systemUid = "";

  for (const datum of label[2]) {
    if (
      datum.type !== CalculationDataType.VALUE ||
      datum.value === null ||
      datum.value === undefined
    )
      continue;

    const text = o.makeDatumText(context.lastDrawingContext!, datum);
    results = Array.isArray(text) ? [...results, ...text] : [...results, text];
    systemUid = datum.systemUid ?? "";
  }

  if (!results.length) return null;

  const matrix = label[1];
  const coord: Coord = TM.applyToPoint(label[1], { x: 0, y: 0 });
  const angleRad = Math.atan2(matrix.b, matrix.a);

  return {
    results,
    coord,
    angleRad,
    systemUid,
    levelUid: context.document.uiState.levelUid!,
    entityUid: o.uid,
  };
}

export function jsonAutoCadDownload(context: CanvasContext) {
  const workingDrawing = cloneSimple(context.drawing);
  const jsonData: AutoCadJson = {
    metadata: {
      flowSystems: Object.values(workingDrawing.metadata.flowSystems),
      levels: [],
    },
    entities: {
      RISER: [],
      CONDUIT: [],
      BIG_VALVE: [],
      FIXTURE: [],
      DIRECTED_VALVE: [],
      MULTIWAY_VALVE: [],
      PLANT: [],
      FLOW_SOURCE: [],
      GAS_APPLIANCE: [],
      LOAD_NODE: [],
    },
    resultLabels: getAllResultsLabels(context),
  };

  const processLevelEntities = (
    entities: Level["entities"],
    levelUid: string | null,
  ) => {
    for (const [_entityKey, entityValue] of Object.entries(entities)) {
      if (!isAcceptedEntity(entityValue)) {
        continue;
      }
      const accept = ACCEPTED_ENTITIES[entityValue.type];
      if (!accept || (typeof accept === "function" && !accept(entityValue))) {
        continue;
      }

      const entitiesToPush: AutoCADExportEntity[] = (() => {
        if (isV2RadiatorEntity(entityValue)) {
          return DrawableV2Radiator.getAutoCADExportEntities(
            context.globalStore.get(entityValue.uid) as DrawableV2RadiatorPlant,
          );
        }

        const toPush = fillEntityDefaults(
          context,
          entityValue,
        ) as AutoCADExportEntity;
        toPush.levelUid = levelUid;
        if (isCenteredEntity(toPush)) {
          toPush.center = context.globalStore
            .get<CenteredObjectConcrete>(toPush.uid)!
            .toWorldCoord();
        } else if (isEdgeEntity(toPush)) {
          toPush.endpointCoords = [
            context.globalStore
              .get<CenteredObjectConcrete>(toPush.endpointUid[0])!
              .toWorldCoord(),
            context.globalStore
              .get<CenteredObjectConcrete>(toPush.endpointUid[1])!
              .toWorldCoord(),
          ];
        }

        flattenRadiatorDims(toPush);
        processRiserLevels(toPush);

        return [toPush];
      })();

      entitiesToPush.forEach((toPush) => {
        jsonData.entities[toPush.type].push({
          ...replaceNullWithDefaults(toPush),
          levelUid: levelUid,
        } as any);
      });
    }
  };

  for (const [_levelKey, levelValue] of Object.entries(workingDrawing.levels)) {
    jsonData.metadata.levels.push({
      floorHeightM: levelValue.floorHeightM,
      name: levelValue.name,
      abbreviation: levelValue.abbreviation,
      uid: levelValue.uid,
    });

    processLevelEntities(levelValue.entities, levelValue.uid);
  }
  processLevelEntities(workingDrawing.shared, null);

  // cull empty flow systems
  (() => {
    const flowSystemIsEmpty = (flowSystemUid: string) => {
      // we look for any entity that has systemUid equal to flowSystemUid
      for (const entities of betterObjectValues(jsonData.entities)) {
        for (const entity of entities) {
          const entityAsAny = entity as any;
          if (
            entityAsAny.systemUid &&
            entityAsAny.systemUid === flowSystemUid
          ) {
            return false; // system is not empty
          }
        }
      }
      return true; // no entity found, system is empty
    };
    jsonData.metadata.flowSystems = jsonData.metadata.flowSystems.filter(
      (system) => !flowSystemIsEmpty(system.uid),
    );
  })();

  const jsonString = JSON.stringify(jsonData, null, 2);
  const blob = new Blob([jsonString], { type: "application/json" });
  const link = document.createElement("a");
  const projectNumber = workingDrawing.metadata.generalInfo.projectNumber;
  const projectName = workingDrawing.metadata.generalInfo.title;
  if (projectNumber && projectName) {
    link.download = `${projectNumber} - ${projectName}.json`;
  } else if (projectNumber) {
    link.download = `${projectNumber}.json`;
  } else if (projectName) {
    link.download = `${projectName}.json`;
  } else {
    link.download = "h2x-export.json";
  }
  link.href = URL.createObjectURL(blob);
  link.click();
  URL.revokeObjectURL(link.href);
  sendAutocadAnalytics(context.document);
}

function sendAutocadAnalytics(doc: DocumentState) {
  trackEvent({
    type: "Autocad Exported",
    props: {
      workflows: getActiveWorkflowsList(doc.drawing.metadata.workflows),
    },
  });
}

export function flattenRadiatorDims(entity: DrawableEntityConcrete) {
  if (entity.type === EntityType.PLANT && isSpecifyRadiator(entity.plant)) {
    // @ts-ignore
    entity.plant.widthMM = entity.plant.widthMM.value || 0;
    // @ts-ignore
    entity.plant.heightMM = entity.plant.heightMM.value || 0;
  }
}

function processRiserLevels(entity: DrawableEntityConcrete) {
  if (entity.type === EntityType.RISER) {
    if (entity.bottomFloorRef === null) {
      entity.bottomFloorRef = -999;
    }
    if (entity.topFloorRef === null) {
      entity.topFloorRef = 999;
    }
  }
}

function replaceNullWithDefaults(obj: any, parentKey: string = ""): any {
  if (obj === null) {
    return NULLABLE_FIELDS_TYPE[parentKey] === "number" ? 0 : "";
  }
  if (Array.isArray(obj)) {
    return obj.map((item) => replaceNullWithDefaults(item, parentKey));
  }
  if (typeof obj === "object" && obj !== null) {
    const newObj: any = {};
    for (const [key, value] of Object.entries(obj)) {
      newObj[key] = replaceNullWithDefaults(value, key);
    }
    return newObj;
  }
  return obj;
}
