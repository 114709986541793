import CoreBaseBackedObject from "../../../../../common/src/api/coreObjects/lib/coreBaseBackedObject";
import { DrawableEntityConcrete } from "../../../../../common/src/api/document/entities/concrete-entity";
import CanvasContext from "../../../../src/htmlcanvas/lib/canvas-context";
import {
  DrawingMode,
  MouseMoveResult,
  UNHANDLED,
} from "../../../../src/htmlcanvas/types";
import { isHoverableObjectAny } from "../../objects/concrete-object";
import { IDrawableObject } from "./core2drawable";

export function SelectableObject<
  /// I extends DrawableEntityConcrete,
  T extends abstract new (
    ...args: any[]
  ) => CoreBaseBackedObject<DrawableEntityConcrete> & IDrawableObject,
>(constructor: T) {
  // @ts-ignore abstract class expression limitation in the language. In practice this is fine.
  return class extends constructor {
    selectable: true = true;

    override get tags() {
      const tags = super.tags;
      tags.add("selectable");
      return tags;
    }

    onMouseDown(event: MouseEvent, context: CanvasContext): boolean {
      const wc = context.viewPort.toWorldCoord({
        x: event.clientX,
        y: event.clientY,
      });
      const oc = this.toObjectCoord(wc);

      // Check bounds
      let result = false;
      // Shift key for remove select mode
      // Disable selection in calculation mode
      if (context.isSelected(this.uid) === event.shiftKey) {
        if (this.inBounds(oc)) {
          this.onSelect(event);

          result = true;
        }
      }

      // avoid running cool drag for calculations
      if (context.document.uiState.drawingMode === DrawingMode.Calculations) {
        return false;
      }

      return super.onMouseDown(event, context) || result;
    }

    onMouseMove(event: MouseEvent, context: CanvasContext): MouseMoveResult {
      // if (context.document.uiState.drawingMode === DrawingMode.Calculations) {
      //   return UNHANDLED;
      // }
      const result2: MouseMoveResult = super.onMouseMove(event, context);
      if (result2.handled) {
        return result2;
      }

      return UNHANDLED;
    }

    onMouseUp(event: MouseEvent, context: CanvasContext): boolean {
      const wc = context.viewPort.toWorldCoord({
        x: event.clientX,
        y: event.clientY,
      });
      if (this.entity) {
        const oc = this.toObjectCoord(wc);
        // Check bounds
        let result = false;
        if (
          this.inBounds(oc) ||
          (isHoverableObjectAny(this) && (this as any).isHovering)
        ) {
          if (isHoverableObjectAny(this) && (this as any).isHovering) {
            console.log("hovering");
          }
          result = true;
        }

        return super.onMouseUp(event, context) || result;
      } else {
        // we were deleted during this operation, probably drag
        return super.onMouseUp(event, context);
      }
    }
  };
}
