<template>
  <b-container>
    <b-row class="text-center">
      <b-col>
        <h1 class="text-center">Change password</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h5 class="text-center">
          For security reasons, please enter your current password
        </h5>
      </b-col>
    </b-row>
    <b-row style="margin-top: 50px">
      <b-col />
      <b-col cols="6">
        <b-form>
          <b-form-group :label-cols="4" label="Current Password">
            <b-form-input
              v-model="currentPassword"
              type="password"
              @keyup.enter="confirm"
            />
          </b-form-group>

          <b-form-group :label-cols="4" label="New Password">
            <b-form-input
              v-model="newPassword1"
              type="password"
              :state="
                isSubmitted
                  ? newPasswordValidationResult.success &&
                    !newPasswordSameAsCurrent
                  : null
              "
              @keyup.enter="confirm"
            />
            <b-form-text id="password-help-block">
              Your password must be at least
              {{ passwordRequirements.minLength }} characters long, contain at
              least {{ passwordRequirements.minNumbers }} number,
              {{ passwordRequirements.minLowercase }} lowercase letter,
              {{ passwordRequirements.minUppercase }} uppercase letter and
              {{ passwordRequirements.minSpecialChars }} special character.
            </b-form-text>
            <b-form-invalid-feedback>
              {{
                newPasswordValidationResult.success
                  ? ""
                  : newPasswordValidationResult.message + " "
              }}
              {{
                !newPasswordSameAsCurrent
                  ? ""
                  : "New password can't be the same as current password"
              }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group :label-cols="4" label="Confirm Password">
            <b-form-input
              v-model="newPassword2"
              type="password"
              :state="isSubmitted ? confirmPasswordSameAsNewPassword : null"
              @keyup.enter="confirm"
            />
            <b-form-invalid-feedback>
              {{
                confirmPasswordSameAsNewPassword
                  ? ""
                  : "Password and Confirm Password does not match"
              }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group>
            <b-button variant="primary" @click="confirm">
              <strong>Confirm</strong>
            </b-button>
          </b-form-group>
        </b-form>
      </b-col>
      <b-col />
    </b-row>
  </b-container>
</template>

<script lang="ts">
import { updatePassword } from "aws-amplify/auth";
import { defineComponent } from "vue";
import { Logger } from "../../../common/src/lib/logger";
import { SentryError } from "../../../common/src/lib/sentry-error";
import { ValidationResult } from "../../../common/src/lib/validation/validation-result";
import {
  defaultPasswordRequirements,
  PasswordRequirements,
} from "../../../common/src/models/Organization";
import { User } from "../../../common/src/models/User";
import { passwordValidation } from "../../../common/src/models/User.validation";
import router from "../../src/router";

export default defineComponent({
  data() {
    const isSubmitted: boolean = false;
    const newPassword1: string = "";
    const newPassword2: string = "";
    const currentPassword: string = "";

    return {
      currentPassword,
      newPassword1,
      newPassword2,
      isSubmitted,
    };
  },
  computed: {
    user(): User {
      return this.$store.getters["profile/profile"];
    },
    passwordRequirements(): PasswordRequirements {
      let passwordRequirements = defaultPasswordRequirements;
      if (this.user.organization) {
        passwordRequirements = this.user.organization.passwordRequirements;
      }
      return passwordRequirements;
    },
    newPasswordValidationResult(): ValidationResult {
      return passwordValidation(this.newPassword1, this.passwordRequirements);
    },
    newPasswordSameAsCurrent() {
      return this.currentPassword === this.newPassword1;
    },
    confirmPasswordSameAsNewPassword() {
      return this.newPassword1 === this.newPassword2;
    },
  },
  methods: {
    async confirm() {
      this.isSubmitted = true;
      if (
        this.newPasswordValidationResult.success &&
        !this.newPasswordSameAsCurrent &&
        this.confirmPasswordSameAsNewPassword
      ) {
        try {
          await updatePassword({
            oldPassword: this.currentPassword,
            newPassword: this.newPassword1,
          });

          if (this.$route.query.next) {
            router.push(this.$route.query.next as string).then(() =>
              (this as any).$bvToast.toast("Password change succeeded", {
                variant: "success",
              }),
            );
          } else {
            router.push({ name: "home" }).then(() =>
              (this as any).$bvToast.toast("Password change succeeded", {
                variant: "success",
              }),
            );
          }
        } catch (error: any) {
          Logger.error(SentryError.wrapError("Error Changing Password", error));
          (this as any).$bvToast.toast(error.message, {
            title: "Password Change Error",
            variant: "danger",
            solid: true,
          });
        }
      }
    },
  },
});
</script>
