// A return network that is solvable using the much faster

import _ from "lodash";
import { BBox } from "rbush";
import ExRBush from "../../lib/ExRBush";
import { Logger } from "../../lib/logger";
import { SentryEntityError } from "../../lib/sentry-entity-error";
import {
  assertType,
  assertUnreachable,
  assertUnreachableAggressive,
  interpolateTable,
  parseCatalogNumberExact,
} from "../../lib/utils";
import {
  RADIATOR_TYPE11_VOLUME_PER_M2,
  RADIATOR_TYPE21_VOLUME_PER_M2,
  RADIATOR_TYPE22_VOLUME_PER_M2,
  RADIATOR_TYPE33_VOLUME_PER_M2,
} from "../catalog/manufacturers/generic/generic-radiators";
import {
  Catalog,
  ManufacturerRadiatorData,
  RadiatorData,
} from "../catalog/types";
import {
  isChilledPlant,
  isCoolingPlantSystem,
  isHeating,
  isHeatingPlant,
} from "../config";
import { CoreObjectConcrete } from "../coreObjects";
import {
  determineConnectableSystemUid,
  getFloorHeight,
} from "../coreObjects/utils";
import { isCalculated } from "../document/calculations-objects";
import { isBalancingEntity } from "../document/calculations-objects/balancing-entity";
import ConduitCalculation from "../document/calculations-objects/conduit-calculations";
import PlantCalculation, {
  PlantLiveCalculation,
} from "../document/calculations-objects/plant-calculation";
import { getFlowSystemLayouts } from "../document/calculations-objects/utils";
import { addWarning } from "../document/calculations-objects/warnings";
import { DEFAULT_SCOP, RADIATOR_CAPACITY_LKW } from "../document/consts";
import { DEFAULT_SPF, DrawingState } from "../document/drawing";
import {
  fillDefaultConduitFields,
  isPipeEntity,
} from "../document/entities/conduit-entity";
import { isReturnBalancingValve } from "../document/entities/directed-valves/valve-types";
import { MultiwayValveEntity } from "../document/entities/multiway-valves/multiway-valve-entity";
import { isDiverterValve } from "../document/entities/multiway-valves/utils";
import { fillPlantDefaults } from "../document/entities/plants/plant-defaults";
import PlantEntity, {
  ReturnSystemPlantEntity,
  isHeatLoadPlant,
  isSpecifyRadiator,
  isV2RadiatorEntity,
  plantHasDualRating,
  plantHasSingleRating,
} from "../document/entities/plants/plant-entity";
import {
  DHWCylinderPlant,
  FixedRadiatorPlant,
  HeatLoadPlant,
  HeatPumpPlant,
  HeatPumpSCOPRating,
  HotWaterPlantGrundfosSettingsName,
  ManifoldPlant,
  PlantType,
  PressureMethod,
  RadiatorPlant,
  ReturnSystemPlant,
  ReturnSystemType,
  SpecifyRadiatorPlant,
  UnderfloorHeatingPlant,
} from "../document/entities/plants/plant-types";
import {
  getHotWaterOutletInfo,
  getPlantDatasheet,
  isDHWCylinderPlant,
  isHeatPumpPlant,
  isPlantReturnSystem,
  isRadiatorPlant,
} from "../document/entities/plants/utils";
import { RoomEntityConcrete } from "../document/entities/rooms/room-entity";
import { EntityType } from "../document/entities/types";
import {
  getF2Factor,
  getF3Factor,
  getF4Factor,
} from "../document/entities/utils";
import { FlowSystem } from "../document/flow-systems";
import { getFlowSystem } from "../document/utils";
import CalculationEngine from "./calculation-engine";
import { TraceCalculation } from "./flight-data-recorder";
import { GenericReturnCalculator } from "./generic-returns";
import { GlobalFDR } from "./global-fdr";
import Graph, { Edge, SubGraph, VISIT_RESULT_WRONG_WAY } from "./graph";
import { SPNode, SPTree, isSeriesParallel } from "./series-parallel";
import { SPReturnCalculator } from "./sp-returns";
import {
  ConnectableUid,
  CoreContext,
  EdgeType,
  EnergyEdgeType,
  FlowEdge,
  FlowNode,
  PipeConfiguration,
  PressureLossResult,
} from "./types";
import { IndexCircuitPath, getPlantRoom } from "./utils";

// series parallel method - because the graph was series parallel.
export interface SPReturnRecord {
  type: "sp";
  spTree: SPTree<Edge<ConnectableUid, FlowEdge>>;
  plant: ReturnSystemPlantEntity;
  outletUid: string;
  returnUid: string;
  erroneousPlant: boolean;
  isCooling: boolean; // if true, negate all KW values.
  pressurDropKPa: Map<string, number | null> | null;
}

export interface SPReturnRecordPressureLoss extends SPReturnRecord {
  pressureDropKPA: Map<string, number | null>;
}

export interface IterationResult {
  pipeSizesChanged: boolean;
  totalFlowRateLS: number;
  totalHeatLossKW?: number;
}

// A return network that is solvable using the slower hardy-cross
// method.
export interface GenericReturnRecord {
  type: "generic";
  biconnectedComponent: SubGraph<FlowNode, FlowEdge>;
  diverterValves: MultiwayValveEntity[];
  graph: Graph<FlowNode, FlowEdge> | null;
  plant: ReturnSystemPlantEntity;
  outletUid: string;
  returnUid: string;
  edgeFlowSource: Map<string, string> | null;
  error?: boolean;
  erroneousPlant: boolean;

  isCooling: boolean; // if true, negate all KW values.
}

export interface GenericReturnRecordPressureLoss extends GenericReturnRecord {
  // pressureTo => at a node toward a edge, the pressure drop on that
  // preesureFrom => at a node, the pressure drop came from an edge
  pressureFromKPA: Map<string, Map<string, number | null>> | null;
  pressureToKPA: Map<string, Map<string, number | null>> | null;
  maxPressuresKPA: Map<string, number | null> | null;
  isEdgePressureLossValid: Map<string, boolean> | null;
  edge2PressureLoss: Map<string, PressureLossResult> | null;
}

export type ReturnRecord = SPReturnRecord | GenericReturnRecord;

export type ReturnRecordPressureLoss =
  | SPReturnRecordPressureLoss
  | GenericReturnRecordPressureLoss;

export interface HeatLossResult {
  totalWATT: number; // total heat loss = closedWATT + domesticWATT + undiversifiedWATT
  closedWATT: number; // heat loss in heating systems
  domesticWATT: number; // heat loss in domestic hot water systems
  numberOfClosedAppliances: number; // how many HeatInterface Unit has heating systems
  numberOfDomesticAppliances: number; // how many HeatInterface Unit has domestic hot water systems
}

export interface HeatLossResultKW {
  totalKW: number; // total heat loss = closedKW + domesticKW + undiversifiedKW
  closedKW: number; // heat loss in heating systems
  domesticKW: number; // heat loss in domestic hot water systems
  numberOfClosedAppliances: number; // how many HeatInterface Unit has heating systems
  numberOfDomesticAppliances: number; // how many HeatInterface Unit has domestic hot water systems
}

export function heatLossResultKW2W(result: HeatLossResultKW): HeatLossResult {
  return {
    totalWATT: result.totalKW * 1000,
    closedWATT: result.closedKW * 1000,
    domesticWATT: result.domesticKW * 1000,
    numberOfClosedAppliances: result.numberOfClosedAppliances,
    numberOfDomesticAppliances: result.numberOfDomesticAppliances,
  };
}

export function heatLossResultW2KW(result: HeatLossResult): HeatLossResultKW {
  return {
    totalKW: result.totalWATT / 1000,
    closedKW: result.closedWATT / 1000,
    domesticKW: result.domesticWATT / 1000,
    numberOfClosedAppliances: result.numberOfClosedAppliances,
    numberOfDomesticAppliances: result.numberOfDomesticAppliances,
  };
}

export interface RadiatorRatingDataValue {
  deltaTempC: number;
  ratingKW: number;
}

export const DEFAULT_ROOM_TEMP_C = 21;
export const DEFAULT_HEATING_RETURN_DELTA_C = 5;
export const DEFAULT_RADIATOR_N_COEFFICIENT = 1.3;
export const DEFAULT_AVERAGE_RETURN_C = 37.5;

export class ReturnCalculations {
  @TraceCalculation("Generating return component", (e, c, p, o, r) => [
    p.uid,
    o,
    r,
  ])
  static getReturnComponent(
    engine: CalculationEngine,
    connectedGraph: Graph<FlowNode, FlowEdge>,
    plant: ReturnSystemPlantEntity,
    outletUid: string,
    returnUid: string,
  ): SubGraph<FlowNode, FlowEdge> | null {
    // add faux edge between source and sink of return pump because it was excluded in the original graph
    // but is needed here to extract the loops.
    connectedGraph.addDirectedEdge(
      {
        connectable: returnUid,
        connection: plant.uid,
      },
      {
        connectable: outletUid,
        connection: plant.uid,
      },
      {
        type: EdgeType.RETURN_PUMP,
        uid: plant.uid,
      },
    );

    const [bridges, biConnected] =
      connectedGraph.findBridgeSeparatedComponents();

    const returnComponent = biConnected.find(([nodes, edges]) => {
      return nodes.find(
        (n) =>
          engine.serializeNode(n) ===
          engine.serializeNode({
            connectable: outletUid,
            connection: plant.uid,
          }),
      );
    });

    if (!returnComponent) {
      throw new Error(
        "Graph algorithm error - no connected component contains the return node",
      );
    }

    if (
      !returnComponent[1].some((e) => e.value.type === EdgeType.RETURN_PUMP)
    ) {
      return null;
    }

    return returnComponent;
  }

  // we need to process returns in reverse topological order, because some returns may
  // have other returns as dependencies. Ie, a cooling tower return system feeds cold water
  // to a chiller return system and would depend on the rating of the chiller return system.
  @TraceCalculation("Figuring out the order to calculate returns", (e, r) =>
    r.map((r) => r.plant.uid),
  )
  static reverseTopsortReturns(
    engine: CalculationEngine,
    returns: ReturnRecord[],
  ) {
    const allNodes = new Set<string>(returns.map((r) => r.plant.uid));
    const childrenOf = new Map<string, string[]>();
    const parentsOf = new Map<string, ReturnRecord[]>();
    const queue: ReturnRecord[] = [];
    for (let i = 0; i < returns.length; i++) {
      const r = returns[i];
      GlobalFDR.focusData([r.plant.uid, r.outletUid, r.returnUid]);
      let children: string[] = [];
      switch (r.type) {
        case "generic": {
          children = GenericReturnCalculator.getDownstreamReturnsGeneric(
            engine,
            r,
          );
          break;
        }
        case "sp": {
          children = SPReturnCalculator.getDownstreamReturnsSP(engine, r);
          break;
        }
        default:
          assertUnreachable(r);
      }
      children = children.filter((c) => allNodes.has(c));

      childrenOf.set(r.plant.uid, children);
      for (const c of children) {
        const parents = parentsOf.get(c) || [];
        parents.push(r);
        parentsOf.set(c, parents);
      }
      if (children.length === 0) {
        queue.push(r);
      }
    }

    const result: ReturnRecord[] = [];

    while (queue.length > 0) {
      const r = queue.shift()!;
      GlobalFDR.focusData([r.plant.uid, r.outletUid, r.returnUid]);
      result.push(r);

      const parents = parentsOf.get(r.plant.uid);
      if (!parents) {
        continue;
      }

      for (const parent of parents) {
        const children = childrenOf.get(parent.plant.uid)!;
        const idx = children.indexOf(r.plant.uid);
        children.splice(idx, 1);
        if (children.length === 0) {
          queue.push(parent);
        }
      }
    }

    const missingNodes = Array.from(allNodes).filter(
      (n) => !result.find((r) => r.plant.uid === n),
    );

    // Missing plants are part of invalid pre-heat cycles.
    for (const n of missingNodes) {
      GlobalFDR.focusData([n]);
      const obj = engine.globalStore.getObjectOfType(EntityType.PLANT, n);
      if (!obj) {
        continue;
      }
      addWarning(engine, "RETURN_PREHEAT_LOOP", [obj.entity]);
    }

    return result;
  }

  static getRadiatorRatingbySpecs_KW(
    engine: CalculationEngine,
    radiator: SpecifyRadiatorPlant,
    returnAverageC: number,
    roomTempC: number | null,
  ): number {
    const widthMM = radiator.widthMM.value;
    const heightMM = radiator.heightMM.value;
    if (!widthMM || !heightMM) {
      return Infinity;
    }
    const area = (widthMM / 1000) * (heightMM / 1000);

    const model = getPlantDatasheet(radiator, engine.catalog, true, {
      rangeType: radiator.rangeType,
    })![0];

    return this.getRadiatorDataRating_KW(
      model,
      returnAverageC - (roomTempC ?? DEFAULT_ROOM_TEMP_C),
      area,
    );
  }

  static f2ToF4Factors(radiator: RadiatorPlant) {
    return (
      getF2Factor(radiator) * getF3Factor(radiator) * getF4Factor(radiator)
    );
  }

  static getRadiatorDataRating_KW(
    radiatorRatingData: RadiatorData,
    deltaTC: number,
    areaM2: number,
  ): number {
    switch (radiatorRatingData.ratingDataType) {
      case "KWperM2": {
        areaM2 = areaM2 ?? 1;
        return (
          (interpolateTable(radiatorRatingData.KWperM2ByDeltaT, deltaTC) ?? 0) *
          areaM2
        );
      }
      case "correction-factor": {
        return (
          (interpolateTable(
            radiatorRatingData.correctionFactorByDeltaT,
            deltaTC,
          ) ?? 0) * radiatorRatingData.nominalDeltaT50KW
        );
      }
      case "kW": {
        return interpolateTable(radiatorRatingData.kWByDeltaT, deltaTC) ?? 0;
      }
      case "n-coefficient": {
        return (
          radiatorRatingData.nominalDeltaT50KW /
          Math.pow(50 / deltaTC, radiatorRatingData.nCoefficient)
        );
      }
      default:
        assertUnreachableAggressive(radiatorRatingData);
    }
  }

  static getPlantThroughHeatLoss(
    context: CoreContext,
    plantUid: string,
    systemNodeUid: string,
  ): HeatLossResult | null {
    const o = context.globalStore.getObjectOfTypeOrThrow(
      EntityType.PLANT,
      plantUid,
    );

    const ns = context.globalStore.getObjectOfTypeOrThrow(
      EntityType.SYSTEM_NODE,
      systemNodeUid,
    );

    if (isHeatLoadPlant(o.entity.plant)) {
      if (o.entity.plant.type !== PlantType.RETURN_SYSTEM) {
        let losspack = o.getHeatLossKW(ns.entity.systemUid)!;
        let res: HeatLossResult = {
          totalWATT: (losspack.totalKW || 0) * 1000,
          closedWATT: (losspack.closedKW || 0) * 1000,
          domesticWATT: (losspack.domesticKW || 0) * 1000,
          numberOfClosedAppliances: losspack.numberOfClosedAppliances,
          numberOfDomesticAppliances: losspack.numberOfDomesticAppliances,
        };

        if (res === null || res === undefined) {
          return null;
        } else {
          return res;
        }
      }
    }

    if (o.entity.plant.type === PlantType.VOLUMISER) {
      return {
        totalWATT: 0,
        closedWATT: 0,
        domesticWATT: 0,
        numberOfClosedAppliances: 0,
        numberOfDomesticAppliances: 0,
      };
    }

    return null;
  }

  //Helper function for calculate diversified heat loss
  static diversifyDistrictW(heatLoss: HeatLossResult): number {
    if (
      // No diversification needed
      heatLoss.numberOfClosedAppliances === 0 &&
      heatLoss.numberOfDomesticAppliances === 0
    ) {
      return heatLoss.totalWATT;
    }

    let ans = heatLoss.totalWATT - heatLoss.closedWATT - heatLoss.domesticWATT;
    if (heatLoss.numberOfClosedAppliances) {
      // Formula for Heating Systems
      ans += this.diversifyDistrictClosed(heatLoss);
    }
    if (heatLoss.numberOfDomesticAppliances) {
      // Formula for Domestic Hot Water
      ans += this.diversifyDistrictDomestic(heatLoss);
    }
    return ans;
  }

  static diversifyDistrictClosed(heatLoss: HeatLossResult): number {
    if (heatLoss.numberOfClosedAppliances === 0) {
      return 0;
    }
    return (
      heatLoss.closedWATT * (0.62 + 0.38 / heatLoss.numberOfClosedAppliances)
    );
  }

  static diversifyDistrictDomestic(heatLoss: HeatLossResult): number {
    if (heatLoss.numberOfDomesticAppliances === 0) {
      return 0;
    }
    return (
      heatLoss.domesticWATT *
      (this.lookupDiversificationTablePCT(heatLoss.numberOfDomesticAppliances) /
        100)
    );
  }

  // Domestic Hot Water diversified table
  // [number of appliances, % of total heat loss]
  // See https://www.heatweb.co.uk/w/index.php?title=Diversity
  static districtDiversifiedTable = [
    [1, 100.0],
    [2, 61.94],
    [3, 47.65],
    [4, 39.88],
    [5, 34.9],
    [6, 31.39],
    [7, 28.76],
    [8, 26.7],
    [9, 25.04],
    [10, 23.66],
    [11, 22.5],
    [12, 21.51],
    [13, 20.64],
    [14, 19.88],
    [15, 19.2],
    [16, 18.6],
    [17, 18.05],
    [18, 17.56],
    [19, 17.1],
    [20, 16.69],
    [25, 15.04],
    [30, 13.86],
    [35, 12.96],
    [40, 12.24],
    [45, 11.66],
    [50, 11.18],
    [60, 10.4],
    [70, 9.81],
    [85, 9.14],
    [90, 8.96],
    [100, 8.64],
    [110, 8.36],
    [120, 8.12],
    [130, 7.91],
    [147, 7.61],
    [160, 7.41],
    [175, 7.21],
    [200, 6.94],
    [250, 6.52],
    [300, 6.21],
    [400, 5.78],
    [600, 5.29],
    [850, 4.94],
    [10000, 3.67],
  ];
  // Search for the correct value in the diversified table
  static lookupDiversificationTablePCT(n: number): number {
    let ans = 0;
    for (let i = 0; i < this.districtDiversifiedTable.length; i++) {
      if (n >= this.districtDiversifiedTable[i][0]) {
        ans = this.districtDiversifiedTable[i][1];
      }
    }
    return ans;
  }

  static getPlantPreheatHeatLoss(
    context: CoreContext,
    plantUid: string,
    systemNodeUid: string,
    tempC: number,
    returnTempC: number,
  ): HeatLossResult | null {
    const p = context.globalStore.getObjectOfTypeOrThrow(
      EntityType.PLANT,
      plantUid,
    );
    const nss = context.globalStore.getObjectOfTypeOrThrow(
      EntityType.SYSTEM_NODE,
      systemNodeUid,
    );

    if (isPlantReturnSystem(p.entity)) {
      let losspack = p.getHeatLossKW(nss.entity.systemUid)!;
      const filled = fillPlantDefaults(context, p.entity);

      // For district heating, closedWATT and domesticWATT affects flow rates linearly
      // (diversification is only in appliances) so it's safe to just split via percentages
      // here.

      const totalKW =
        this.diversifyDistrictW(heatLossResultKW2W(losspack)) / 1000;
      let totalDynamicKW = totalKW;

      for (const preheat of filled.plant.preheats) {
        if (preheat.ratingMode === "explicit") {
          const explicitKW =
            preheat.explicitRating.type === "energy"
              ? preheat.explicitRating.KW
              : ReturnCalculations.LS2KW(
                  context,
                  nss.entity.systemUid,
                  preheat.explicitRating.LS,
                  tempC - returnTempC,
                  tempC,
                );
          totalDynamicKW -= explicitKW;
        }
      }

      for (const preheat of filled.plant.preheats) {
        if (
          preheat.inletUid === systemNodeUid ||
          preheat.returnUid === systemNodeUid
        ) {
          let contribution = 0;
          switch (preheat.ratingMode) {
            case "percentage":
              contribution = preheat.ratingPCT! / 100;
              break;
            case "explicit": {
              const totalKW =
                this.diversifyDistrictW(heatLossResultKW2W(losspack)) / 1000;
              const explicitKW =
                preheat.explicitRating.type === "energy"
                  ? preheat.explicitRating.KW
                  : ReturnCalculations.LS2KW(
                      context,
                      nss.entity.systemUid,
                      preheat.explicitRating.LS,
                      tempC - returnTempC,
                      tempC,
                    );
              contribution = explicitKW / totalKW;
              break;
            }
            default:
              assertUnreachable(preheat.ratingMode);
          }

          contribution = Math.max(0, contribution);

          // special case - for single preheat, we can just use the totalKW
          // by design - the controls for preheat split loads are hidden when
          // there's only one preheat.
          if (filled.plant.preheats.length === 1) {
            contribution = 1;
          }

          let res: HeatLossResult = {
            totalWATT: (losspack.totalKW || 0) * 1000 * contribution,
            closedWATT: (losspack.closedKW || 0) * 1000 * contribution,
            domesticWATT: (losspack.domesticKW || 0) * 1000 * contribution,
            numberOfClosedAppliances: losspack.numberOfClosedAppliances,
            numberOfDomesticAppliances: losspack.numberOfDomesticAppliances,
          };
          if (res === null || res === undefined) {
            return null;
          } else {
            return res;
          }
        }
      }
    }
    return null;
  }

  // Identifies and returns the components in each return loop
  // Sets the ratingKW calculation of heat loads that have flow rate load
  // setting.
  // Is very fast and suitable for fast calculations, needed by heat load
  // calcs.
  static identifyReturnLoopsOnly(engine: CalculationEngine): {
    plant: ReturnSystemPlantEntity;
    outletUid: string;
    returnUid: string;
    returnComponent: SubGraph<FlowNode, FlowEdge>;
  }[] {
    const result: {
      plant: ReturnSystemPlantEntity;
      outletUid: string;
      returnUid: string;
      returnComponent: SubGraph<FlowNode, FlowEdge>;
    }[] = [];

    const returnByLevel: Map<
      string,
      ExRBush<{ uid: string; outletIndex: number } & BBox>
    > = new Map();

    for (const o of engine.networkObjects()) {
      GlobalFDR.focusData([o.uid]);
      if (o.entity.type === EntityType.PLANT && isPlantReturnSystem(o.entity)) {
        const filled = fillPlantDefaults(engine, o.entity, true);
        const levelUid = engine.globalStore.levelOfEntity.get(o.uid)!;
        if (!returnByLevel.has(levelUid)) {
          returnByLevel.set(
            levelUid,
            new ExRBush<{ uid: string; outletIndex: number } & BBox>(),
          );
        }
        const box = o.shape?.box!;
        if (filled.plant.outlets.length > 0) {
          const outlet = filled.plant.outlets[0];
          if (
            !(
              !outlet.outletReturnUid ||
              !outlet.outletUid ||
              (outlet.outletSystemUid && outlet.outletSystemUid !== "heating")
            )
          ) {
            returnByLevel.get(levelUid)!.insert({
              uid: o.uid,
              outletIndex: 0,
              maxX: box.xmax,
              maxY: box.ymax,
              minX: box.xmin,
              minY: box.ymin,
            });
          }
        }

        for (let i = 0; i < filled.plant.outlets.length; i++) {
          const outlet = filled.plant.outlets[i];
          if (!outlet.outletReturnUid || !outlet.outletUid) {
            continue;
          }

          const connsOutlet = engine.globalStore.getConnections(
            outlet.outletUid!,
          );
          const connsReturn = engine.globalStore.getConnections(
            outlet.outletReturnUid,
          );
          if (connsOutlet.length !== 1) {
            continue;
          }
          if (connsReturn.length !== 1) {
            continue;
          }

          const thisNode = {
            connectable: outlet.outletUid,
            connection: o.entity.uid,
          };
          const component = engine.flowGraph.getConnectedComponent(thisNode);

          const newGraph = Graph.fromSubgraph(component, engine.serializeNode);

          const returnComponent = this.getReturnComponent(
            engine,
            newGraph,
            o.entity,
            outlet.outletUid,
            outlet.outletReturnUid,
          );

          if (!returnComponent) {
            continue;
          }

          for (const e of returnComponent[1]) {
            let entity: PlantEntity | null = null;
            let plant: HeatLoadPlant | null = null;
            if (e.value.type === EdgeType.PLANT_PREHEAT) {
              const o = engine.globalStore.getObjectOfTypeOrThrow(
                EntityType.PLANT,
                e.value.uid,
              );
              entity = o.entity;
              plant = (o.entity as ReturnSystemPlantEntity).plant;
            } else if (e.value.type === EdgeType.PLANT_THROUGH) {
              const o = engine.globalStore.getObjectOfTypeOrThrow(
                EntityType.PLANT,
                e.value.uid,
              );

              if (isHeatLoadPlant(o.entity.plant)) {
                entity = o.entity;
                plant = o.entity.plant;
              }
            } else if (e.value.type === EdgeType.CONDUIT) {
              const levelUid = engine.globalStore.levelOfEntity.get(
                e.value.uid,
              );
              if (levelUid) {
                if (!returnByLevel.has(levelUid)) {
                  returnByLevel.set(
                    levelUid,
                    new ExRBush<{ uid: string; outletIndex: number } & BBox>(),
                  );
                }
                const po = engine.globalStore.getObjectOfTypeOrThrow(
                  EntityType.CONDUIT,
                  e.value.uid,
                );
                const pbox = po.shape.box;
                // for connected pipes
                if (
                  outlet.outletSystemUid &&
                  outlet.outletSystemUid === "heating"
                ) {
                  returnByLevel.get(levelUid)!.insert({
                    uid: o.uid,
                    outletIndex: i,
                    maxX: pbox.xmax,
                    maxY: pbox.ymax,
                    minX: pbox.xmin,
                    minY: pbox.ymin,
                  });
                }
              }
            }

            if (entity && plant) {
              this.assignHeatSourceRating(
                engine,
                entity,
                plant,
                entity.inletSystemUid,
                outlet.outletTemperatureC!,
                outlet.returnLimitTemperatureC!,
              );
            }
          }

          result.push({
            plant: o.entity,
            outletUid: outlet.outletUid,
            returnUid: outlet.outletReturnUid,
            returnComponent,
          });
        }
      }
    }

    this.calculateDisconnectedHeatSourceRatings(engine, returnByLevel);

    return result;
  }

  // Assigns the ratingKW to the heat source elements (radiators, FCU etc.)
  // This happens before dynamic ratings to assign the rating of heat sources
  // for heat source elements whose rating can be calculated without assigning dynamic ratings
  static assignHeatSourceRating(
    engine: CalculationEngine,
    entity: PlantEntity,
    plant: HeatLoadPlant,
    systemUid: string,
    outletTemperatureC: number,
    returnLimitTemperatureC: number,
  ) {
    const calc = engine.globalStore.getOrCreateCalculation(entity);
    calc.returnDeltaC = Math.abs(
      outletTemperatureC! - returnLimitTemperatureC!,
    );

    calc.returnAverageC = returnLimitTemperatureC! + calc.returnDeltaC / 2;

    if (plantHasSingleRating(plant)) {
      switch (plant.rating.type) {
        case "energy":
          switch (plant.type) {
            case PlantType.RETURN_SYSTEM:
              if (isHeatingPlant(plant, engine.drawing.metadata.flowSystems)) {
                calc.heatingRatingKW = plant.rating.KW;
              }
              if (isChilledPlant(plant, engine.drawing.metadata.flowSystems)) {
                calc.chilledRatingKW = plant.rating.KW;
              }
              break;
            case PlantType.RADIATOR:
              switch (plant.radiatorType) {
                case "fixed": {
                  const filledRaf = fillPlantDefaults(engine, entity, true)
                    .plant as FixedRadiatorPlant;
                  if (plant.rating.KW !== null) {
                    calc.heatingRatingKW =
                      plant.rating.KW * this.f2ToF4Factors(plant);
                    calc.heatingRatingAt50DtKW =
                      plant.rating.KW * this.f2ToF4Factors(plant);
                  }
                  calc.heightMM = filledRaf.heightMM;
                  calc.widthMM = filledRaf.widthMM;
                  calc.depthMM = filledRaf.depthMM;
                  break;
                }
                case "specify":
                  let ratingKW = plant.rating.KW
                    ? plant.rating.KW * this.f2ToF4Factors(plant)
                    : null;
                  let ratingKW50 = plant.rating.KW
                    ? plant.rating.KW * this.f2ToF4Factors(plant)
                    : null;
                  const filledRad = fillPlantDefaults(engine, entity, true)
                    .plant as SpecifyRadiatorPlant;
                  let heightMM = filledRad.heightMM.value!;
                  let widthMM = filledRad.widthMM.value!;
                  let depthMM = filledRad.depthMM!;

                  if (ratingKW === null) {
                    const room = getPlantRoom(engine, entity)
                      ?.room as RoomEntityConcrete;
                    const system = getFlowSystem(
                      engine.drawing.metadata.flowSystems,
                      entity.inletSystemUid,
                    )!;
                    if (
                      plant.manufacturer === "generic" &&
                      plant.widthMM.type === "exact"
                    ) {
                      ratingKW =
                        this.getRadiatorRatingbySpecs_KW(
                          engine,
                          plant,
                          calc.returnAverageC ||
                            system.temperatureC -
                              DEFAULT_HEATING_RETURN_DELTA_C / 2,
                          room?.roomTemperatureC || null,
                        ) * this.f2ToF4Factors(plant);
                      ratingKW50 =
                        this.getRadiatorRatingbySpecs_KW(engine, plant, 50, 0) *
                        this.f2ToF4Factors(plant);
                    } else if (
                      plant.manufacturer !== "generic" &&
                      plant.model
                    ) {
                      const model = getPlantDatasheet(
                        plant,
                        engine.catalog,
                        false,
                        { model: plant.model },
                      )[0] as ManufacturerRadiatorData;

                      const kv =
                        plant.pressureLoss.pressureMethod ===
                        PressureMethod.KV_PRESSURE_LOSS
                          ? (plant.pressureLoss.kvValue ?? model.kvValue ?? 0)
                          : null;

                      heightMM = model.heightMM;
                      widthMM = model.widthMM;
                      depthMM = model.depthMM;
                      calc.model = model.model;
                      calc.kvValue = kv;
                      calc.volumeL = model.volumeL ?? 0;
                      calc.internalVolumeL = calc.volumeL;
                      const area =
                        (model.widthMM / 1000) * (model.heightMM / 1000);
                      ratingKW =
                        this.getRadiatorDataRating_KW(
                          model,
                          (calc.returnAverageC ??
                            system.temperatureC -
                              DEFAULT_HEATING_RETURN_DELTA_C / 2) -
                            (room?.roomTemperatureC ?? DEFAULT_ROOM_TEMP_C),
                          area,
                        ) * this.f2ToF4Factors(plant);
                      ratingKW50 =
                        this.getRadiatorDataRating_KW(model, 50, area) *
                        this.f2ToF4Factors(plant);
                    }
                  }
                  calc.heightMM = heightMM;
                  calc.widthMM = widthMM;
                  calc.depthMM = depthMM;
                  calc.heatingRatingKW = ratingKW;
                  calc.heatingRatingAt50DtKW = ratingKW50;
                  break;
                default:
                  assertUnreachable(plant);
              }
              break;
            case PlantType.MANIFOLD:
            case PlantType.UFH:
              const filled = fillPlantDefaults(engine, entity).plant as
                | ManifoldPlant
                | UnderfloorHeatingPlant;
              if (plant.rating.KW !== null) {
                calc.heatingRatingKW = plant.rating.KW;
              }
              calc.widthMM = filled.widthMM;
              calc.depthMM = filled.depthMM;
              break;
            default:
              assertUnreachable(plant);
          }
          break;
        case "flow-rate":
          if (plant.rating.LS !== null) {
            if (isHeatingPlant(plant, engine.drawing.metadata.flowSystems)) {
              let heatingRatingKW = this.LS2KW(
                engine,
                systemUid,
                plant.rating.LS,
                outletTemperatureC - returnLimitTemperatureC,
                outletTemperatureC,
              );
              let heatingRatingAt50DtKW = this.LS2KW(
                engine,
                systemUid,
                plant.rating.LS,
                50,
                outletTemperatureC,
              );
              if (isRadiatorPlant(plant)) {
                heatingRatingKW *= this.f2ToF4Factors(plant);
                heatingRatingAt50DtKW *= this.f2ToF4Factors(plant);
              }
              calc.heatingRatingKW = heatingRatingKW;
              calc.heatingRatingAt50DtKW = heatingRatingAt50DtKW;
            } else {
              calc.chilledRatingKW = this.LS2KW(
                engine,
                systemUid,
                plant.rating.LS,
                outletTemperatureC - returnLimitTemperatureC,
                outletTemperatureC,
              );
            }
          }
          break;
        default:
          assertUnreachable(plant.rating);
      }
      if (isRadiatorPlant(plant)) {
        const { volumeL, internalVolumeL } =
          this.calculateRadiatorEffectiveVolume(engine, entity);
        calc.volumeL = volumeL;
        calc.internalVolumeL = internalVolumeL;
      }
    } else if (plantHasDualRating(plant)) {
      // heating rating
      switch (plant.heatingRating.type) {
        case "energy":
          calc.heatingRatingKW = plant.heatingRating.KW;
          break;
        case "flow-rate":
          if (plant.heatingRating.LS !== null) {
            calc.heatingRatingKW = this.LS2KW(
              engine,
              systemUid,
              plant.heatingRating.LS,
              outletTemperatureC - returnLimitTemperatureC,
              outletTemperatureC,
            );
          }
          break;
        default:
          assertUnreachable(plant.heatingRating);
      }

      // chilled rating
      switch (plant.chilledRating.type) {
        case "energy":
          calc.chilledRatingKW = plant.chilledRating.KW;
          break;
        case "flow-rate":
          if (plant.chilledRating.LS !== null) {
            calc.chilledRatingKW = this.LS2KW(
              engine,
              systemUid,
              plant.chilledRating.LS,
              outletTemperatureC! - returnLimitTemperatureC!,
              outletTemperatureC!,
            );
          }
          break;
        default:
          assertUnreachable(plant.chilledRating);
      }
    } else {
      // ahu's TODO
    }
  }

  static calculateDisconnectedHeatSourceRatings(
    engine: CalculationEngine,
    trees: Map<string, ExRBush<{ uid: string; outletIndex: number } & BBox>>,
  ) {
    const rads = engine.globalStore.find("plant.radiator");
    for (const obj of rads) {
      const liveCalc: PlantLiveCalculation =
        engine.globalStore.getOrCreateLiveCalculation(obj.entity);
      if (liveCalc.connected) continue;
      const { plant } = obj.entity;
      const outletSystem = plant.outletSystemUid;
      let flowTemperatureC =
        engine.drawing.metadata.flowSystems[outletSystem].temperatureC;
      let returnTemperatureC =
        flowTemperatureC - DEFAULT_HEATING_RETURN_DELTA_C; // TODO: change to flow system return temperatureC

      let searchClosestHotWaterOutlet = true;

      if (plant.heatSourceOutletUid) {
        let heatSource = engine.globalStore.getObjectOfType(
          EntityType.SYSTEM_NODE,
          plant.heatSourceOutletUid,
        );
        if (heatSource) {
          searchClosestHotWaterOutlet = false;
          const [outlet] = getHotWaterOutletInfo(engine, heatSource);
          if (outlet) {
            flowTemperatureC = outlet.outletTemperatureC ?? flowTemperatureC;
            returnTemperatureC =
              outlet.returnLimitTemperatureC ?? returnTemperatureC;
            liveCalc.heatSourceOutletUid = outlet.outletUid;
          }
        }
      }

      if (searchClosestHotWaterOutlet) {
        const levelUid = engine.globalStore.levelOfEntity.get(obj.uid);
        const tree = trees.get(levelUid!);
        let result = tree?.closestManhattan(obj.toWorldCoord());

        if (!result) {
          // try all other levels instead
          for (const tree of trees.values()) {
            result = tree.closestManhattan(obj.toWorldCoord());
            if (result) {
              break;
            }
          }
        }

        if (result) {
          const returnSystem = engine.globalStore.getObjectOfTypeOrThrow(
            EntityType.PLANT,
            result.uid,
          );
          const filled = fillPlantDefaults(engine, returnSystem.entity, true);
          assertType<ReturnSystemPlant>(filled.plant);
          const outlet = filled.plant.outlets[result.outletIndex];
          flowTemperatureC = outlet.outletTemperatureC!;
          returnTemperatureC = outlet.returnLimitTemperatureC!;
          liveCalc.heatSourceOutletUid = outlet.outletUid;
        }
      }

      this.assignHeatSourceRating(
        engine,
        obj.entity,
        plant,
        obj.entity.inletSystemUid,
        flowTemperatureC,
        returnTemperatureC,
      );
    }

    const fcus = engine.globalStore.find("plant.fcu");
    for (const fcu of fcus) {
      const calc = engine.globalStore.getOrCreateCalculation(fcu.entity);
      if (fcu.entity.plant.heatingRating.type === "energy") {
        calc.heatingRatingKW = fcu.entity.plant.heatingRating.KW;
      }
      if (fcu.entity.plant.chilledRating.type === "energy") {
        calc.chilledRatingKW = fcu.entity.plant.chilledRating.KW;
      }
    }
  }

  static getSpecificHeatTable(context: CoreContext, system: FlowSystem) {
    const fluid = context.catalog.fluids[system.fluid];
    if (
      context.drawing.metadata.calculationParams.specificHeatMethod ===
        "isochoric" &&
      fluid.specificHeatByTemperatureIsochoricKJ_KGK
    ) {
      return fluid.specificHeatByTemperatureIsochoricKJ_KGK;
    }
    return fluid.specificHeatByTemperatureIsobaricKJ_KGK;
  }

  static heatLoadToFlowRateLS(
    context: CoreContext,
    systemUid: string,
    flowTempC: number,
    returnTempC: number,

    // Signed - use negative for gain.
    heatLossKW: number,
  ) {
    const system = getFlowSystem(context.drawing, systemUid)!;
    const fluid = context.catalog.fluids[system.fluid];

    const specificHeatKJ_KGK = interpolateTable(
      this.getSpecificHeatTable(context, system),
      flowTempC,
    );

    const totalFlowRateKG_S =
      heatLossKW / (specificHeatKJ_KGK! * (flowTempC - returnTempC));
    const densityKGM3 = parseCatalogNumberExact(fluid.densityKGM3)!;

    const totalFlowRateM3_S = totalFlowRateKG_S / densityKGM3;
    return totalFlowRateM3_S * 1000;
  }

  static flowRateToHeatLoadKW(
    context: CoreContext,
    systemUid: string,
    flowTempC: number,
    returnTempC: number,
    flowRateLS: number,
  ) {
    const system = getFlowSystem(context.drawing, systemUid)!;
    const fluid = context.catalog.fluids[system.fluid];

    const specificHeatKJ_KGK = interpolateTable(
      this.getSpecificHeatTable(context, system),
      flowTempC,
    );

    const densityKGM3 = parseCatalogNumberExact(fluid.densityKGM3)!;

    const totalFlowRateKG_S = (flowRateLS / 1000) * densityKGM3;
    const heatLossKW =
      totalFlowRateKG_S * specificHeatKJ_KGK! * (flowTempC - returnTempC);
    return heatLossKW;
  }

  @TraceCalculation("Identifying returns")
  static identifyReturns(engine: CalculationEngine): ReturnRecord[] {
    const records: ReturnRecord[] = [];
    const loops = this.identifyReturnLoopsOnly(engine);
    for (const { outletUid, returnUid, plant, returnComponent } of loops) {
      // construct a simple graph for series-parallel analysis
      const simpleGraph = new Graph<string, FlowEdge>((n) => n);
      let erroneousPlant = false;
      for (const e of returnComponent[1]) {
        switch (e.value.type) {
          case EdgeType.CONDUIT:
          case EdgeType.BIG_VALVE_HOT_HOT:
          case EdgeType.BIG_VALVE_HOT_WARM:
          case EdgeType.BIG_VALVE_COLD_WARM:
          case EdgeType.BIG_VALVE_COLD_COLD:
          case EdgeType.PLANT_PREHEAT:
            simpleGraph.addEdge(
              e.from.connectable,
              e.to.connectable,
              e.value,
              e.uid,
            );
            break;
          case EdgeType.BALANCING_THROUGH:
          case EdgeType.FITTING_FLOW:
          case EdgeType.FLOW_SOURCE_EDGE:
          case EdgeType.CHECK_THROUGH:
          case EdgeType.ISOLATION_THROUGH:
            // an extrapolated edge which will interfere with series parallel analysis.
            break;
          case EdgeType.PLANT_THROUGH:
            const o = engine.globalStore.getObjectOfTypeOrThrow(
              EntityType.PLANT,
              e.value.uid,
            );
            // TODO: only allow radiators on closed loop heating plants.
            switch (o.entity.plant.type) {
              case PlantType.RADIATOR:
              case PlantType.MANIFOLD:
              case PlantType.UFH:
              case PlantType.AHU:
              case PlantType.AHU_VENT:
              case PlantType.FCU:
              case PlantType.DUCT_MANIFOLD:
              case PlantType.VOLUMISER:
                simpleGraph.addEdge(
                  e.from.connectable,
                  e.to.connectable,
                  e.value,
                  e.uid,
                );
                break;
              case PlantType.CUSTOM:
              case PlantType.RETURN_SYSTEM:
              case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
              case PlantType.DRAINAGE_PIT:
              case PlantType.PUMP:
              case PlantType.PUMP_TANK:
              case PlantType.TANK:
              case PlantType.FILTER:
              case PlantType.RO:
                erroneousPlant = true;
                break;
              default:
                assertUnreachable(o.entity.plant);
            }
            break;
          case EdgeType.RETURN_PUMP:
            // DO nothing. The edges related to the pump are done on the pipe.
            break;
          default:
            assertUnreachable(e.value.type);
        }
      }

      const res = isSeriesParallel(simpleGraph, outletUid, returnUid);

      if (erroneousPlant) {
        for (const e of returnComponent[1]) {
          if (e.value.type === EdgeType.CONDUIT) {
            const pipe = engine.globalStore.getObjectOfTypeOrThrow(
              EntityType.CONDUIT,
              e.value.uid,
            );
            const calc = engine.globalStore.getOrCreateCalculation(pipe.entity);

            addWarning(engine, "REMOVE_PLANT_FROM_FLOW_AND_RETURN_PIPEWORK", [
              pipe.entity,
            ]);
          }
        }
      }

      if (res) {
        const [orderLookup, spTree] = res;
        records.push({
          spTree,
          plant,
          type: "sp",
          erroneousPlant,
          outletUid: outletUid,
          returnUid: returnUid,
          isCooling: isCoolingPlantSystem(
            engine.drawing.metadata.flowSystems[
              plant.plant.outlets[0].outletSystemUid
            ],
          ),
          pressurDropKPa: new Map<string, number | null>(),
        });
        // we are good.
        for (const e of returnComponent[1]) {
          if (e.value.type === EdgeType.CONDUIT) {
            const pipeD = engine.getCalcByPipeId(e.value.uid);
            if (!pipeD) {
              throw new Error(
                "non-pipe conduit in return system " + e.value.uid,
              );
            }
            pipeD.pCalc.configuration = PipeConfiguration.RETURN_OUT;
            pipeD.pCalc.flowFrom = orderLookup.get(e.uid)!;
          }
        }
      } else {
        const diverterValves = _.uniq(
          returnComponent[0]
            .map(
              (n) =>
                engine.globalStore.get(n.connectable) as CoreObjectConcrete,
            )
            .filter((o) => {
              const entity = o?.entity;
              return (
                entity &&
                isDiverterValve(entity) &&
                // If a diverter valve only has one connection, it forces no choices and
                // can be ignored.
                engine.globalStore.getConnections(entity.uid).length > 2
              );
            })
            .map((o) => o.entity as MultiwayValveEntity),
        );

        if (diverterValves.length > 1) {
          erroneousPlant = true;
          for (const e of diverterValves) {
            const flowSystem =
              determineConnectableSystemUid(engine.globalStore, e) || null;

            const { layouts } = getFlowSystemLayouts(
              engine.drawing.metadata.flowSystems[flowSystem!],
            );

            addWarning(engine, "TOO_MANY_DIVERTER_VALVES", [e], {
              mode: layouts,
            });
          }
        }

        const record: GenericReturnRecord = {
          biconnectedComponent: returnComponent,
          plant,
          type: "generic",
          edgeFlowSource: null,
          graph: GenericReturnCalculator.makeReturnGraph(
            engine,
            returnComponent,
          ),
          diverterValves,
          erroneousPlant,
          outletUid: outletUid,
          returnUid: returnUid,
          isCooling: isCoolingPlantSystem(
            engine.drawing.metadata.flowSystems[
              plant.plant.outlets[0].outletSystemUid
            ],
          ),
        };
        records.push(record);

        for (const e of returnComponent[1]) {
          if (e.value.type === EdgeType.CONDUIT) {
            const pipeD = engine.getCalcByPipeId(e.value.uid);
            if (!pipeD) {
              throw new Error(
                "non-pipe conduit in return system " + e.value.uid,
              );
            }

            pipeD.pCalc.configuration = PipeConfiguration.RETURN_OUT;
          }
        }

        GenericReturnCalculator.determineFlowDirections(engine, record);
      }
    }

    return this.reverseTopsortReturns(engine, records);
  }

  @TraceCalculation("Detecting misplaced fixtures")
  static detectMisplacedFixturesNodes(
    engine: CalculationEngine,
    records: ReturnRecord[],
  ) {
    for (const r of records) {
      // connectable UIDs with balancing valves in their upstream
      const balancingValveUpstream = new Set<string>();

      engine.flowGraph.dfs(
        {
          connectable: r.outletUid,
          connection: r.plant.uid,
        },
        (node) => {
          if (!balancingValveUpstream.has(node.connectable)) {
            return;
          }

          const e = engine.globalStore.ofTagOrThrow(
            "calculatable",
            node.connectable,
          );
          if (e.type === EntityType.SYSTEM_NODE) {
            const p = engine.globalStore.ofTagOrThrow(
              "calculatable",
              e.entity.parentUid!,
            );
            if (p.type === EntityType.FIXTURE) {
              const calc = engine.globalStore.getOrCreateCalculation(p.entity);
              addWarning(
                engine,
                "FIXTURE_DOWNSTREAM_OF_BALANCING_VALVE",
                [p.entity],
                {
                  replaceSameWarnings: true,
                },
              );
            }
          }
          if (e.type === EntityType.LOAD_NODE) {
            const calc = engine.globalStore.getOrCreateCalculation(e.entity);
            addWarning(
              engine,
              "NODE_DOWNSTREAM_OF_BALANCING_VALVE",
              [e.entity],
              {
                replaceSameWarnings: true,
              },
            );
          }
        },
        undefined,
        (edge) => {
          // make sure we are traversing the return loop in the flow direction only
          if (edge.value.type === EdgeType.CONDUIT) {
            const pipe = engine.globalStore.getObjectOfTypeOrThrow(
              EntityType.CONDUIT,
              edge.value.uid,
            );
            const pipeCalc = engine.globalStore.getOrCreateCalculation(
              pipe.entity,
            ) as ConduitCalculation;
            if (pipeCalc.flowFrom !== edge.from.connectable) {
              return VISIT_RESULT_WRONG_WAY;
            }
          }

          if (
            balancingValveUpstream.has(edge.from.connectable) ||
            edge.value.type === EdgeType.BALANCING_THROUGH
          ) {
            balancingValveUpstream.add(edge.to.connectable);
          }
        },
      );
    }
  }

  @TraceCalculation("Calculating return flow rates")
  static returnFlowRates(engine: CalculationEngine, returns: ReturnRecord[]) {
    for (const r of returns) {
      switch (r.type) {
        case "sp": {
          SPReturnCalculator.SPReturnFlowRates(engine, r);
          break;
        }
        case "generic": {
          GenericReturnCalculator.genericReturnFlowRates(engine, r);
          break;
        }
        default:
          assertUnreachable(r);
      }
      // After computing the flow rates and thus heat losses, the heat loss result of the return plant
      // itself needs to be revised.
      if (r.plant.plant.type === PlantType.RETURN_SYSTEM) {
        const calc = engine.globalStore.getOrCreateCalculation(r.plant);
        if (r.plant.plant.rating.type === "energy") {
          if (r.plant.plant.rating.KW !== null) {
            // when an override is provided
            if (r.isCooling) {
              calc.chilledRatingKW = r.plant.plant.rating.KW;
            } else {
              calc.heatingRatingKW = r.plant.plant.rating.KW;
            }
          } else {
            // otherwise we need to calculate it based on heat load of return loops
            calc.heatingRatingKW = 0;
            calc.chilledRatingKW = 0;
            for (const vv of calc.returnLoopHeatLossKW) {
              const v = Number(vv);
              if (v > 0) {
                calc.heatingRatingKW += +v;
              } else {
                calc.chilledRatingKW += Math.abs(v);
              }
            }
          }
        }
      }
    }
  }

  /**
   * During identifying returns phase, inlets and outlets of v2 rads have
   * an assumed height. After flow direction has been determined, the
   * correct height is applied here.
   * TODO (Hoang): This mutation might create 0-length pipes.
   * @param engine
   * @param returns
   */
  static applyCorrectBidirectionalRadIOHeight(
    engine: CalculationEngine,
    returns: ReturnRecord[],
  ) {
    const store = engine.globalStore;
    const maybeApplyCorrectHeight = (entityUid: string) => {
      const entity = store.get(entityUid)?.entity;
      if (!entity || !isCalculated(entity) || !isV2RadiatorEntity(entity))
        return;

      const filledRad = fillPlantDefaults(engine, entity);
      const inletUid = filledRad.inletUid;
      const outletUid = filledRad.plant.outletUid;
      if (!inletUid) {
        return;
      }

      for (const nodeUid of [inletUid, outletUid]) {
        const connections = store.getConnections(nodeUid);
        if (connections.length === 0) {
          continue;
        }
        const pipeUid = connections[0];
        const { pCalc } = engine.getCalcByPipeIdOrThrow(pipeUid);

        const trueHeightAboveFloorM =
          pCalc.flowFrom === nodeUid
            ? filledRad.plant.outletHeightAboveFloorM
            : filledRad.inletHeightAboveFloorM;

        const trueHeightAboveGroundM =
          trueHeightAboveFloorM! +
          getFloorHeight(engine.globalStore, engine.drawing, filledRad);

        store.ofTagOrThrow("connectable", nodeUid).entity.calculationHeightM =
          trueHeightAboveGroundM;
      }
    };
    returns.forEach((r) => {
      switch (r.type) {
        case "generic":
          r.biconnectedComponent[1].forEach((edge) =>
            maybeApplyCorrectHeight(edge.value.uid),
          );
          return;
        case "sp":
          const recurse = (node: SPNode<Edge<string, FlowEdge>>) => {
            if (node.type === "leaf") {
              maybeApplyCorrectHeight(node.edgeConcrete.value.uid);
            } else if (node.type === "series") {
              node.children.forEach(recurse);
            } else {
              node.siblings.forEach(recurse);
            }
          };
          r.spTree.siblings.forEach(recurse);
      }
    });
  }

  @TraceCalculation("Balancing the balancing valves")
  static returnBalanceValves(
    engine: CalculationEngine,
    returns: ReturnRecord[],
  ): asserts returns is ReturnRecordPressureLoss[] {
    for (let i = 0; i < returns.length; i++) {
      const r = returns[i];
      switch (r.type) {
        case "sp": {
          returns[i] = SPReturnCalculator.SPReturnBalanceValves(engine, r);
          break;
        }
        case "generic": {
          returns[i] = GenericReturnCalculator.genericReturnBalanceValves(
            engine,
            r,
          );
          break;
        }
        default:
          assertUnreachable(r);
      }
    }
  }

  @TraceCalculation(
    "Checking Maximum Recirculation Pump Duty and Maximum Recirculation Pump Head",
  )
  static checkHeatPumpMaximumRecirculation(
    engine: CalculationEngine,
    returns: ReturnRecord[],
  ) {
    for (const record of returns) {
      if (isHeatPumpPlant(record.plant.plant)) {
        const plant = record.plant.plant as HeatPumpPlant;
        const selectedOutletIndex = plant.outlets.findIndex(
          (outlet) => outlet.outletUid === record.outletUid,
        );
        const plantCalculation = engine.globalStore.getOrCreateCalculation(
          record.plant,
        );
        if (plant.maximumRecirculationPumpFlowLPS !== null) {
          const calculatedFlowRateLS =
            plantCalculation.circulationFlowRateLS[selectedOutletIndex];
          if (
            calculatedFlowRateLS &&
            calculatedFlowRateLS > plant.maximumRecirculationPumpFlowLPS
          ) {
            addWarning(engine, "HEAT_PUMP_FLOW_EXCEEDED", [record.plant], {
              mode: "mechanical",
              replaceSameWarnings: true,
            });
          }
        }

        if (plant.maximumRecirculationPumpPressureKPA !== null) {
          const calculatedHeadKPA =
            plantCalculation.circulationPressureLoss[selectedOutletIndex];
          if (
            calculatedHeadKPA &&
            calculatedHeadKPA > plant.maximumRecirculationPumpPressureKPA
          ) {
            addWarning(engine, "HEAT_PUMP_PRESSURE_EXCEEDED", [record.plant], {
              mode: "mechanical",
              replaceSameWarnings: true,
            });
          }
        }
      }
    }
  }

  // Mark the pipes leading into the return that don't lead into any fixtures or
  // radiators as "return".
  @TraceCalculation("Marking return pipes as return")
  static returnDetermineIns(engine: CalculationEngine) {
    // we can't use precomputed return list, because the return list contains
    // only returns with a completed loop, while we want to fill in
    // incomplete loops for heating systems. It is fine to include incomplete
    // hot-water loops here, logic still works out.
    for (const e of engine.networkObjects()) {
      if (
        e.entity.type !== EntityType.PLANT ||
        e.entity.plant.type !== PlantType.RETURN_SYSTEM
      ) {
        continue;
      }

      GlobalFDR.focusData([e.uid]);

      const returnEntity = e.entity;
      const returnPlant = returnEntity.plant as ReturnSystemPlant;

      for (const outlet of returnPlant.outlets) {
        if (!outlet.outletUid || !outlet.outletReturnUid) {
          continue;
        }
        const returnO = engine.globalStore.getObjectOfTypeOrThrow(
          EntityType.SYSTEM_NODE,
          outlet.outletUid,
        );

        if (
          isHeating(
            engine.drawing.metadata.flowSystems[returnO.entity.systemUid],
          )
        ) {
          // fill in the return_out and return_in aggressively - even without
          // complete loops and cover all pipes - since heating doesn't have
          // pipes branching out of the return.

          // Fill out return_out first. Smash through all balancing valves -
          // the return_in assignment will fix it right after.
          engine.flowGraph.dfs(
            {
              connectable: outlet.outletUid,
              connection: returnEntity.uid,
            },
            undefined,
            undefined,
            (edge) => {
              if (edge.value.type === EdgeType.CONDUIT) {
                const pipe = engine.globalStore.get(edge.value.uid);
                if (isPipeEntity(pipe.entity)) {
                  const pCalc = engine.globalStore.getOrCreateCalculation(
                    pipe.entity,
                  );
                  pCalc.configuration = PipeConfiguration.RETURN_OUT;
                }
              }
            },
          );

          engine.flowGraph.dfs(
            {
              connectable: outlet.outletReturnUid,
              connection: returnEntity.uid,
            },
            (node) => {
              const entity = engine.globalStore.ofTag(
                "calculatable",
                node.connectable,
              )?.entity;
              if (!entity) {
                return true;
              }
              if (isBalancingEntity(entity)) {
                return true;
              }
              return false;
            },
            undefined,
            (edge) => {
              if (edge.value.type === EdgeType.CONDUIT) {
                const pipe = engine.globalStore.get(edge.value.uid);
                if (isPipeEntity(pipe.entity)) {
                  const pCalc = engine.globalStore.getOrCreateCalculation(
                    pipe.entity,
                  );
                  pCalc.configuration = PipeConfiguration.RETURN_IN;
                }
              } else if (edge.value.type === EdgeType.PLANT_THROUGH) {
                // Except volumizer
                const plant = engine.globalStore.getObjectOfTypeOrThrow(
                  EntityType.PLANT,
                  edge.value.uid,
                );
                if (plant.entity.plant.type !== PlantType.VOLUMISER) {
                  return true;
                }
                // return true;
              }
            },
            undefined,
            undefined,
            undefined,
            undefined,
            true,
          );
        } else {
          // Fill in return_in conservatively - only on complete loops. RETURN_OUT is
          // filled in already.
          engine.flowGraph.dfs(
            {
              connectable: outlet.outletReturnUid,
              connection: returnEntity.uid,
            },
            (node) => {
              const o = engine.globalStore.getObjectOfType(
                EntityType.DIRECTED_VALVE,
                node.connectable,
              );
              if (o) {
                if (isReturnBalancingValve(o.entity.valve)) {
                  return true;
                }
              }
            },
            undefined,
            (edge) => {
              if (edge.value.type === EdgeType.CONDUIT) {
                const pipe = engine.globalStore.get(edge.value.uid);
                if (isPipeEntity(pipe.entity)) {
                  const pCalc = engine.globalStore.getOrCreateCalculation(
                    pipe.entity,
                  );
                  if (pCalc.configuration === PipeConfiguration.RETURN_OUT) {
                    pCalc.configuration = PipeConfiguration.RETURN_IN;
                  }
                }
              } else if (edge.value.type === EdgeType.PLANT_THROUGH) {
                return true;
              }
            },
            undefined,
            undefined,
            undefined,
            undefined,
            true,
          );
        }
      }
    }
  }

  @TraceCalculation("Adjusting plant pressure drop by manufacturer")
  static adjustPlantPressureDropByManufacturer(props: {
    plant: ReturnSystemPlantEntity;
    pCalc: PlantCalculation;
    catalog: Catalog;
    drawing: DrawingState;
    pressureDropKPA: number;
  }): { totalKPA: number; manufacturer: string | null } {
    const returnFlow = props.pCalc.circulationFlowRateLS;
    let totalIncease = 0;
    let settingManufacturerName = null;

    if (
      props.plant.plant.type === PlantType.RETURN_SYSTEM &&
      returnFlow !== null &&
      returnFlow.length > 0 &&
      returnFlow[0] !== null
    ) {
      GlobalFDR.focusData([props.plant.uid]);
      const manufacturer =
        props.drawing.metadata.catalog.hotWaterPlant[0]?.manufacturer;
      const catalogHotWaterPlant = props.catalog.hotWaterPlant;

      if (manufacturer === "grundfos") {
        let stop = false;
        for (let [settings, data] of Object.entries(
          catalogHotWaterPlant.grundfosPressureDrop,
        )) {
          settingManufacturerName = (
            HotWaterPlantGrundfosSettingsName as { [key: string]: string }
          )[settings];
          let settingsData = Object.entries(data);

          settingsData.find(([Q, H], i) => {
            // assuming grundfos only has one return system
            if (Number(Q) > returnFlow[0]!) {
              const pressure1 = Number(settingsData[i > 0 ? i - 1 : 0][1]);
              const pressure2 = Number(H);

              // check if pressureLoss is below the current settings pressure
              // so that we can adjust the pressuLoss up until it meets the current settings pressure
              if (
                pressure1 > props.pressureDropKPA &&
                pressure2 > props.pressureDropKPA
              ) {
                totalIncease =
                  (pressure1 + pressure2) / 2 - props.pressureDropKPA;
                stop = true;
              }

              return true;
            }
          });

          if (stop) {
            break;
          }
        }
      }
    }

    return {
      totalKPA: totalIncease,
      manufacturer: settingManufacturerName,
    };
  }

  @TraceCalculation("Detecting misplaced heat emitters")
  static detectMisplacedHeatEmitters(
    engine: CalculationEngine,
    returnRecords: ReturnRecord[],
  ) {
    for (const ret of returnRecords) {
      switch (ret.type) {
        case "sp":
          SPReturnCalculator.detectMisplacedHeatEmittersSP(engine, ret.spTree);
          break;
        case "generic":
          GenericReturnCalculator.detectMisplacedHeatEmittersGeneric(
            engine,
            ret,
          );
          break;
        default:
          assertUnreachable(ret);
      }
    }
  }

  @TraceCalculation("Calculate Radiator Effective Volume")
  static calculateRadiatorEffectiveVolume(
    engine: CalculationEngine,
    entity: PlantEntity,
  ): { volumeL: number | null; internalVolumeL: number | null } {
    if (
      !plantHasSingleRating(entity.plant) ||
      entity.plant.type === PlantType.RETURN_SYSTEM
    ) {
      Logger.error(
        new SentryEntityError(
          "Wrong plant in calculateRadiatorEffectiveVolume",
          entity.uid,
        ),
      );
      return { volumeL: 0, internalVolumeL: 0 };
    }

    if (entity.plant.volumeL != null) {
      return {
        volumeL: entity.plant.volumeL,
        internalVolumeL:
          "internalVolumeL" in entity.plant
            ? (entity.plant.internalVolumeL ?? entity.plant.volumeL)
            : entity.plant.volumeL,
      };
    }

    const calc = engine.globalStore.getOrCreateCalculation(entity);

    if (calc.volumeL != null) {
      // a previous step already calculated the volume
      return {
        volumeL: calc.volumeL,
        internalVolumeL: calc.internalVolumeL ?? calc.volumeL,
      };
    }

    if (isSpecifyRadiator(entity.plant) && calc.widthMM && calc.heightMM) {
      const areaM2 = (calc.widthMM / 1000) * (calc.heightMM / 1000);
      const radType = entity.plant.rangeType;
      const volumeL = (() => {
        switch (radType) {
          case "11":
            return RADIATOR_TYPE11_VOLUME_PER_M2 * areaM2;
          case "21":
            return RADIATOR_TYPE21_VOLUME_PER_M2 * areaM2;
          case "22":
            return RADIATOR_TYPE22_VOLUME_PER_M2 * areaM2;
          case "33":
            return RADIATOR_TYPE33_VOLUME_PER_M2 * areaM2;
        }
        return 0;
      })();
      return { volumeL, internalVolumeL: volumeL };
    }

    if (calc.heatingRatingAt50DtKW != null) {
      const volumeL =
        (entity.plant.capacityRateLKW ?? RADIATOR_CAPACITY_LKW) *
        calc.heatingRatingAt50DtKW;
      return { volumeL, internalVolumeL: volumeL };
    }

    if (calc.heatingRatingKW != null) {
      const volumeL =
        (entity.plant.capacityRateLKW ?? RADIATOR_CAPACITY_LKW) *
        calc.heatingRatingKW;
      return { volumeL, internalVolumeL: volumeL };
    }

    return { volumeL: null, internalVolumeL: null };
  }

  @TraceCalculation("Calculating return total volume")
  static returnTotalVolume(
    engine: CalculationEngine,
    returnRecords: ReturnRecord[],
  ) {
    for (const ret of returnRecords) {
      let totalPipeVolumeL = 0;
      let totalPlantVolumeL = 0;
      let totalHeatEmitterVolumeL = 0;

      switch (ret.type) {
        case "sp":
          ({ totalPipeVolumeL, totalPlantVolumeL, totalHeatEmitterVolumeL } =
            SPReturnCalculator.returnTotalVolumeSP(engine, ret.spTree));
          break;
        case "generic":
          ({ totalPipeVolumeL, totalPlantVolumeL, totalHeatEmitterVolumeL } =
            GenericReturnCalculator.returnTotalVolumeGeneric(engine, ret));
          break;
        default:
          assertUnreachable(ret);
      }

      const totalSystemVolumeL =
        totalPipeVolumeL + totalPlantVolumeL + totalHeatEmitterVolumeL;

      if (isHeatPumpPlant(ret.plant.plant)) {
        if (
          ret.plant.plant.minimumSystemVolumeL !== null &&
          totalSystemVolumeL < ret.plant.plant.minimumSystemVolumeL
        ) {
          addWarning(engine, "HEAT_PUMP_SYSTEM_VOLUME_NOT_MET", [ret.plant], {
            mode: "mechanical",
            replaceSameWarnings: true,
          });
        }
      }

      const plantCalc = engine.globalStore.getOrCreateCalculation(ret.plant);
      plantCalc.totalSystemVolumeL = totalSystemVolumeL;
      plantCalc.totalPipeVolumeL = totalPipeVolumeL;
      plantCalc.totalPlantVolumeL = totalPlantVolumeL;
      plantCalc.totalHeatEmitterVolumeL = totalHeatEmitterVolumeL;
    }
  }

  @TraceCalculation("Calculating return index circuit path")
  static returnIndexCircuitPath(
    engine: CalculationEngine,
    returnRecords: ReturnRecordPressureLoss[],
  ) {
    for (const ret of returnRecords) {
      let indexCircuitPath: IndexCircuitPath | undefined;
      switch (ret.type) {
        case "sp":
          indexCircuitPath = SPReturnCalculator.returnIndexCircultSP(
            engine,
            ret,
          );
          break;
        case "generic":
          indexCircuitPath = GenericReturnCalculator.returnIndexCircultGeneric(
            engine,
            ret,
          );
          break;
        default:
          assertUnreachable(ret);
      }
      if (indexCircuitPath !== undefined) {
        const plantCalc = engine.globalStore.getOrCreateCalculation(ret.plant);
        plantCalc.indexCircuitLengthM = indexCircuitPath.lengthM;

        // Scan each pipe
        for (const node of indexCircuitPath.path) {
          if (node.value.type === EdgeType.CONDUIT) {
            const pipeD = engine.getCalcByPipeId(node.value.uid);
            if (!pipeD) {
              throw new Error("non-pipe conduit in return system " + node.uid);
            }
            pipeD.pCalc.isIndexCircult = true;
          }
        }
      }
    }
  }

  static getEdgeLengthM(
    context: CoreContext,
    edge: Edge<unknown, FlowEdge>,
  ): number {
    if (edge.value.type === EdgeType.CONDUIT) {
      let pipeObject = context.globalStore.getObjectOfTypeOrThrow(
        EntityType.CONDUIT,
        edge.value.uid,
      );

      let filledEntity = fillDefaultConduitFields(context, pipeObject.entity);
      return filledEntity.lengthM ? filledEntity.lengthM : 0;
    } else if (edge.value.type === EdgeType.PLANT_THROUGH) {
      const o = context.globalStore.getObjectOfType(
        EntityType.PLANT,
        edge.value.uid,
      );
      return o ? o.getLengthThroughPlantM() : 0;
    }
    return 0;
  }

  static linkDHWReferenceToHeatPumpNewHelper(
    engine: CalculationEngine,
    heatPumpEntity: ReturnSystemPlantEntity,
  ) {
    if (!isHeatLoadPlant(heatPumpEntity.plant)) return;

    const heatPumpCalc =
      engine.globalStore.getOrCreateCalculation(heatPumpEntity);

    if (heatPumpCalc.DHWCylinderInfo === null) {
      heatPumpCalc.DHWCylinderInfo = [];
    }

    const filledPlant = fillPlantDefaults(engine, heatPumpEntity);

    for (const outlet of filledPlant.plant.outlets) {
      let energyRatio = 1;

      engine.energyGraph.dfsRecursive(
        {
          connectable: outlet.outletUid!,
          connection: heatPumpEntity.uid,
        },
        undefined,
        undefined,
        (edge) => {
          energyRatio *= edge.value.energyRatio ?? 1;

          if (edge.value.type === EnergyEdgeType.PLANT) {
            const plant = engine.globalStore.getObjectOfTypeOrThrow(
              EntityType.PLANT,
              edge.value.uid,
            );

            if (isDHWCylinderPlant(plant.entity.plant)) {
              const filledDHWPlantEntity = fillPlantDefaults(
                engine,
                plant.entity,
              );
              const DHWPlantCalculation =
                engine.globalStore.getOrCreateCalculation(filledDHWPlantEntity);
              assertType<DHWCylinderPlant>(filledDHWPlantEntity.plant);

              if (
                heatPumpCalc.DHWCylinderInfo!.some(
                  (d) => d.DHWReference === DHWPlantCalculation.reference,
                )
              ) {
                return;
              }

              heatPumpCalc.DHWCylinderInfo!.push({
                DHWCylinderPlant: filledDHWPlantEntity.plant,
                DHWSPF: Number(DHWPlantCalculation.SPF),
                DHWTotalHeatLoadKW: Number(
                  DHWPlantCalculation.totalHeatingLoadKW,
                ),
                DHWReference: DHWPlantCalculation.reference!,
                heatPumpReference: heatPumpCalc.reference!,
                outletTemperatureC: outlet?.outletTemperatureC ?? null,
                heatPumpSuppliedEnergyRatio: energyRatio,
              });
            }
          }
        },
      );
    }
  }

  static linkDHWReferenceToHeatPump(
    engine: CalculationEngine,
    returnRecords: ReturnRecord[],
  ) {
    for (const ret of returnRecords) {
      const heatPump = engine.globalStore.getObjectOfType(
        EntityType.PLANT,
        ret.plant.uid,
      );

      if (heatPump && isHeatPumpPlant(heatPump.entity.plant)) {
        assertType<ReturnSystemPlantEntity>(heatPump.entity);
        this.linkDHWReferenceToHeatPumpNewHelper(engine, heatPump.entity);
      }
    }
  }

  static LS2KW(
    context: CoreContext,
    systemUid: string,
    ls: number,
    deltaC: number,
    averageC: number,
  ) {
    const system = getFlowSystem(
      context.drawing.metadata.flowSystems,
      systemUid,
    );
    if (!system) {
      throw new Error("System not found for uid " + systemUid);
    }

    const specificHeat = interpolateTable(
      this.getSpecificHeatTable(context, system),
      averageC,
    );

    if (specificHeat == null) {
      throw new Error("Specific heat not found for fluid" + system.fluid);
    }

    return ls * specificHeat * deltaC;
  }

  static KW2LS(
    context: CoreContext,
    systemUid: string,
    kw: number,
    deltaC: number,
    averageC: number,
  ) {
    const system = getFlowSystem(
      context.drawing.metadata.flowSystems,
      systemUid,
    );
    if (!system) {
      throw new Error("System not found for uid " + systemUid);
    }

    const specificHeat = interpolateTable(
      this.getSpecificHeatTable(context, system),
      averageC,
    );

    if (specificHeat == null) {
      throw new Error("Specific heat not found for fluid" + system.fluid);
    }

    return kw / (specificHeat * deltaC);
  }

  static rating2KW(
    context: CoreContext,
    plant: HeatLoadPlant,
    returnSystem: ReturnSystemPlantEntity,
    outletUid: string,
    // this is used for dual system heat load plants
    systemContext: "heating" | "chilled",
  ): number | null {
    let ratingLS = 0;

    if (plantHasSingleRating(plant)) {
      if (plant.rating.type === "energy") {
        return plant.rating.KW;
      } else if (plant.rating.type === "flow-rate") {
        if (plant.rating.LS == null) {
          return null;
        }
        ratingLS = plant.rating.LS;
      } else {
        // room - todo
        throw new Error("Room rating not implemented");
      }
    } else {
      if (systemContext === "heating") {
        if (plant.heatingRating.type === "energy") {
          return plant.heatingRating.KW;
        } else if (plant.heatingRating.LS === null) {
          return null;
        } else {
          ratingLS = plant.heatingRating.LS;
        }
      } else {
        if (plant.chilledRating.type === "energy") {
          return plant.chilledRating.KW;
        } else if (plant.chilledRating.LS === null) {
          return null;
        } else {
          ratingLS = plant.chilledRating.LS;
        }
      }
    }

    const outlet = returnSystem.plant.outlets.find(
      (o) => o.outletUid === outletUid,
    )!;
    return this.LS2KW(
      context,
      returnSystem.inletSystemUid,
      ratingLS,
      outlet.outletTemperatureC! - outlet.returnLimitTemperatureC!,
      outlet.outletTemperatureC!,
    );
  }

  @TraceCalculation("calculate SCOP and SPF")
  static calculateSCOPandSPF(
    engine: CalculationEngine,
    calculate: { heatPump: boolean; dhw: boolean } = {
      heatPump: true,
      dhw: true,
    },
  ) {
    for (const o of engine.networkObjects()) {
      if (
        o.type === EntityType.PLANT &&
        o.entity.plant.type === PlantType.RETURN_SYSTEM
      ) {
        if (calculate.heatPump && isHeatPumpPlant(o.entity.plant)) {
          const calc = engine.globalStore.getOrCreateCalculation(o.entity);
          const filled = fillPlantDefaults(
            engine,
            o.entity,
          ) as ReturnSystemPlantEntity;
          calc.SCOP =
            o.entity.plant.outlets.length > 0
              ? this.calculateHeatPumpSCOP(
                  o.entity.plant.SCOPRatings,
                  filled.plant.outlets[0].outletTemperatureC!,
                )
              : DEFAULT_SCOP;
        }

        if (calculate.dhw && isDHWCylinderPlant(o.entity.plant)) {
          const dhwPlant = o.entity.plant;
          for (const preheat of dhwPlant.preheats) {
            let heatPumpUid = "";
            engine.flowGraph.dfs(
              {
                connectable: preheat.inletUid,
                connection: o.uid,
              },
              (node) => {
                if (heatPumpUid !== "") {
                  return true;
                }
                const currentPlant = engine.globalStore.getObjectOfType(
                  EntityType.PLANT,
                  node.connection,
                );
                if (currentPlant) {
                  const plant = currentPlant.entity.plant;
                  if (
                    plant.type === PlantType.RETURN_SYSTEM &&
                    isHeatPumpPlant(currentPlant.entity.plant)
                  ) {
                    heatPumpUid = currentPlant.uid;
                    return true;
                  }
                }
              },
            );

            const calc: PlantCalculation =
              engine.globalStore.getOrCreateCalculation(o.entity);
            if (heatPumpUid !== "") {
              const heatPumpPlant = engine.globalStore.getObjectOfTypeOrThrow(
                EntityType.PLANT,
                heatPumpUid,
              );
              if (isHeatPumpPlant(heatPumpPlant.entity.plant)) {
                const heatPumpCalc = engine.globalStore.getOrCreateCalculation(
                  heatPumpPlant.entity,
                );
                const returnSystemType =
                  heatPumpPlant.entity.plant.returnSystemType;
                calc.SPF = this.calculateDHWCylinderSPF(returnSystemType);
              } else {
                calc.SPF = DEFAULT_SPF;
              }
            } else {
              calc.SPF = DEFAULT_SPF;
            }
            calc.capacityL = dhwPlant.capacityL;
          }
        }
      }
    }
  }

  static calculateHeatPumpSCOP(
    temperatureScopMapping: HeatPumpSCOPRating[],
    outletTemperatureC: number,
  ): number {
    let lowerBound = temperatureScopMapping[0];
    let upperBound = temperatureScopMapping[temperatureScopMapping.length - 1];
    if (outletTemperatureC <= lowerBound.temperatureC) return lowerBound.SCOP;
    if (outletTemperatureC >= upperBound.temperatureC) return 0;

    for (const rating of temperatureScopMapping) {
      if (rating.temperatureC >= outletTemperatureC) {
        upperBound = rating;
        break;
      }
      lowerBound = rating;
    }

    const range = upperBound.temperatureC - lowerBound.temperatureC;
    const interpolant = (outletTemperatureC - lowerBound.temperatureC) / range;
    const interpolatedSCOP =
      lowerBound.SCOP + interpolant * (upperBound.SCOP - lowerBound.SCOP);

    return interpolatedSCOP;
  }

  static calculateHeatPumpElectricityConsumption(
    temperatureScopMapping: HeatPumpSCOPRating[],
    energyRequiredKWH: number,
  ): {
    temperatureC: number[];
    electricityConsumptionKWH: number[];
  } {
    const temperatureC: number[] = [];
    const electricityConsumptionKWH: number[] = [];

    for (const SCOPRating of temperatureScopMapping) {
      temperatureC.push(SCOPRating.temperatureC);
      electricityConsumptionKWH.push(
        SCOPRating.SCOP === 0 ? 0 : energyRequiredKWH / SCOPRating.SCOP,
      );
    }

    return { temperatureC, electricityConsumptionKWH };
  }
  static calculateDHWCylinderSPF(
    returnSystemType:
      | ReturnSystemType.AIR_SOURCE_HEAT_PUMP
      | ReturnSystemType.GROUND_SOURCE_HEAT_PUMP,
  ): number {
    switch (returnSystemType) {
      case ReturnSystemType.AIR_SOURCE_HEAT_PUMP:
        return 1.75;
      case ReturnSystemType.GROUND_SOURCE_HEAT_PUMP:
        return 2.24;
      default:
        assertUnreachable(returnSystemType);
    }
    // DEFAULT_SPF = 4
    return DEFAULT_SPF;
  }
}

export const MINIMUM_BALANCING_VALVE_PRESSURE_DROP_KPA = 10;
