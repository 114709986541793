import axios from "axios";
import {
  AllOrgProperties,
  SignUpFlowData,
} from "../../../common/src/api/allOrgProperties";
import { APIResult } from "./../../../common/src/api/document/types";
import { handleApiError } from "./sentry-api-error";

/**
 * Get organization properties
 *
 * @param fields - Optional array of property keys to retrieve. If not provided, all properties will be returned.
 * @param organizationId - Optional organization ID (defaults to current user's organization)
 * @returns Object containing the requested properties
 *
 * Note: Array properties will be returned as arrays
 */
export async function getOrgProperties<
  T extends keyof AllOrgProperties = keyof AllOrgProperties,
>(
  fields?: T[],
  organizationId?: string,
): Promise<APIResult<Partial<{ [key in T]: AllOrgProperties[key] }>>> {
  const endpoint = organizationId
    ? `/api/orgProperties/byOrg/${organizationId}`
    : "/api/orgProperties/byOrg";

  return axios
    .get(endpoint, {
      params: fields ? { key: fields } : undefined,
    })
    .then((response) => response.data)
    .catch((e) => handleApiError("Failed to get organization properties", e));
}

/**
 * Set organization properties
 *
 * @param properties - Object containing properties to set
 * @param organizationId - Optional organization ID (defaults to current user's organization)
 * @returns Success status
 *
 * Note: when updating an array property, the entire array will be replaced.
 */
export async function setOrgProperties(
  properties: Partial<AllOrgProperties>,
  organizationId?: string,
): Promise<APIResult<void>> {
  const endpoint = organizationId
    ? `/api/orgProperties/byOrg/${organizationId}`
    : "/api/orgProperties/byOrg";

  return axios
    .post(endpoint, properties)
    .then((response) => response.data)
    .catch((e) => handleApiError("Failed to set organization properties", e));
}

/**
 * Submit signup flow data
 *
 * @param data - Signup flow data
 * @returns Success status
 */
export async function submitSignUpFlowData(
  data: SignUpFlowData,
): Promise<APIResult<void>> {
  return axios
    .post("/api/orgProperties/signup-flow", data)
    .then((response) => response.data)
    .catch((e) => handleApiError("Failed to submit signup flow data", e));
}
