import { Logger } from "../../../lib/logger";
import { assertType, assertUnreachable } from "../../../lib/utils";
import { Catalog } from "../../catalog/types";
import {
  CircularConvergingTeeSpec,
  CircularDivergingTeeSpec,
  CircularSymmetricalConvergingTeeSpec,
  CircularSymmetricalDivergingTeeSpec,
  CoefficientData,
  DuctConvergingTee,
  DuctConvergingTeeSpec,
  DuctDivergingTee,
  DuctDivergingTeeSpec,
  DuctTransition,
  DuctTransitionSpec,
  RectangularConvergingTeeSpec,
  RectangularDivergingTeeSpec,
  RectangularSymmetricalConvergingTeeSpec,
  RectangularSymmetricalDivergingTeeSpec,
  SymmetricConvergingTeeSpec,
  SymmetricDivergingTeeSpec,
} from "../../catalog/ventilation/duct-fittings";
import {
  DuctPressureLossPrimitive,
  DuctSize,
  PLEndpointEq,
  PressureLossEndpoint,
  ductSizeAreaMM2,
} from "./ductFittingPrimitives";

export function getEndpoints(
  spec: DuctPressureLossPrimitive,
): PressureLossEndpoint[] {
  switch (spec.type) {
    case "elbow":
      return [...spec.eps];
    case "tee":
      return [...spec.mains, ...spec.branches.map((b) => b.endpoint)];
    case "nipple":
      return [...spec.eps];
    case "transition":
      return [...spec.eps];
    case "y":
      return [spec.main, ...spec.branches.map((b) => b.endpoint)];
  }
  assertUnreachable(spec);
}

interface PrimitiveFlow {
  prim: DuctPressureLossPrimitive;
  from: PressureLossEndpoint;
  to: PressureLossEndpoint;
}

type SerializedEndpoint = string;

function serializeEndpoint(ep: PressureLossEndpoint): SerializedEndpoint {
  switch (ep.type) {
    case "external":
      return `e:${ep.connection}`;
    case "internal":
      return `i:${ep.id}`;
  }
  assertUnreachable(ep);
}

// This graph search only works for a tree. If there are cycles, it will
// not necessarily create a valid fluid flow path.
function findPath(
  primitives: DuctPressureLossPrimitive[],
  fromUid: string,
  toUid: string,
): PrimitiveFlow[] | null {
  const startNodeIdx = primitives.findIndex((p) =>
    getEndpoints(p).some(
      (ep) => ep.type === "external" && ep.connection === fromUid,
    ),
  );

  if (startNodeIdx === -1) {
    return null;
  }

  const startNode = primitives[startNodeIdx];
  const startEndpoint = getEndpoints(startNode).find(
    (ep) => ep.type === "external" && ep.connection === fromUid,
  )!;

  const queue: {
    prim: DuctPressureLossPrimitive;
    from: PressureLossEndpoint;
  }[] = [
    {
      prim: startNode,
      from: startEndpoint,
    },
  ];
  const seenIndices = new Set<number>([startNodeIdx]);
  const prev: Map<SerializedEndpoint, PrimitiveFlow> = new Map();
  let destEps: PressureLossEndpoint | undefined = undefined;

  while (true) {
    const top = queue.pop();
    if (top == null) {
      return null;
    }
    const eps = getEndpoints(top.prim);
    destEps = eps.find(
      (ep) => ep.type === "external" && ep.connection === toUid,
    );
    if (destEps) {
      prev.set(serializeEndpoint(destEps), {
        prim: top.prim,
        from: top.from,
        to: destEps,
      });
      break;
    }

    const internalIds = new Set(
      eps
        .filter((ep) => ep.type === "internal")
        .map((ep) => ep.type === "internal" && ep.id),
    );

    for (let i = 0; i < primitives.length; i++) {
      if (seenIndices.has(i)) {
        continue;
      }

      const p = primitives[i];
      for (const nep of getEndpoints(p)) {
        if (nep.type === "internal" && internalIds.has(nep.id)) {
          queue.push({
            prim: p,
            from: nep,
          });
          seenIndices.add(i);
          prev.set(serializeEndpoint(nep), {
            prim: top.prim,
            from: top.from,
            to: nep,
          });
        }
      }
    }
  }

  // Reconstruct path
  const path: PrimitiveFlow[] = [];
  let cur = destEps;
  while (cur) {
    const prevFlow = prev.get(serializeEndpoint(cur));
    if (!prevFlow) {
      break;
    }
    path.push(prevFlow);
    cur = prevFlow.from;
  }

  return path;
}

export function getCrossSectionAreaM2(ductSize: DuctSize): number {
  switch (ductSize.type) {
    case "circ":
      return Math.PI * Math.pow(ductSize.diameterMM / 2000, 2);
    case "rect":
      return (ductSize.widthMM / 1000) * (ductSize.heightMM / 1000);
  }
}
export function getVelocityPressureKPA(
  flowrateLS: number,
  crossSectionAreaM2: number,
  densityKgM3: number,
): number {
  const flowRateM2S = flowrateLS / 1000;
  const velocityMS = flowRateM2S / crossSectionAreaM2;
  return (0.5 * densityKgM3 * Math.pow(velocityMS, 2)) / 1000;
}

// Returns pressure loss and overall zeta of this fitting.
// If the fitting is made of multiple pressure loss primitives with their
// own raw zeta values, this fitting recalculates a new zeta representing
// the effective zeta of all the fittings combined.
export function calculateDuctFittingPressureLoss(
  spec: DuctPressureLossPrimitive[],
  densityKgM3: number,
  catalog: Catalog,
  fromUid: string,
  toUid: string,
  zeta?: number,
): {
  zeta: number;
  referenceVelocityPressureKPA: number;
  pressureLossKPA: number;
  zetaByConnection: { [key: string]: number };
} | null {
  const path = findPath(spec, fromUid, toUid);
  if (path == null) {
    return null;
  }
  const referenceVelocityPressureKPA = getVelocityPressureKPA(
    path[0].from.flowRateLS,
    getCrossSectionAreaM2(path[0].from.size),
    densityKgM3,
  );
  let zetaByConnection: { [key: string]: number } = {};
  if (zeta == null) {
    let cumulKPA = 0;
    for (let i = 0; i < path.length; i++) {
      const { from, to, prim } = path[i];

      switch (prim.type) {
        case "nipple":
          // Nipples have no effect on pressure drop.
          break;
        case "elbow": {
          let bestDuctElbow = null;
          let bestCost = Infinity;

          for (const [id, elbow] of Object.entries(
            catalog.ductFittings.elbows,
          )) {
            let thisCost = 0;
            if (elbow.shape !== prim.idealSpec.shape) {
              thisCost += 10000;
            }
            if (elbow.jointType !== prim.idealSpec.jointType) {
              thisCost += 1000;
            }
            if (
              elbow.shape === "rectangular" &&
              prim.idealSpec.shape === "rectangular"
            ) {
              if (elbow.vanes !== prim.idealSpec.vanes) {
                thisCost += 100;
              }
            }

            if (thisCost < bestCost) {
              bestCost = thisCost;
              bestDuctElbow = elbow;
            }
          }

          if (bestDuctElbow == null) {
            Logger.error(
              "No best duct elbow found",
              {},
              {
                prim,
              },
            );
            return null;
          }

          const thisZeta =
            lookupZeta(bestDuctElbow, {
              angleDEG: prim.angleDEG,
              heightByWidth:
                from.size.type === "rect"
                  ? from.size.heightMM / from.size.widthMM
                  : undefined,
              radiusByWidth:
                from.size.type === "circ"
                  ? prim.turnRadiusMM / from.size.diameterMM
                  : prim.turnRadiusMM / from.size.widthMM,
            }) ?? undefined;
          zetaByConnection[toUid] = thisZeta ?? 0;
          const thisReferenceVelocityPressureKPA = getVelocityPressureKPA(
            from.flowRateLS,
            getCrossSectionAreaM2(path[0].from.size),
            densityKgM3,
          );

          if (thisZeta == null) {
            return null;
          }
          cumulKPA += thisZeta * thisReferenceVelocityPressureKPA;
          break;
        }
        case "tee": {
          const isMain =
            prim.mains.some((p) => PLEndpointEq(p, from)) &&
            prim.mains.some((p) => PLEndpointEq(p, to));
          let branch: (typeof prim.branches)[0];
          if (isMain) {
            branch = prim.branches[0];
          } else {
            branch = prim.branches.find(
              (b) =>
                PLEndpointEq(b.endpoint, to) || PLEndpointEq(b.endpoint, from),
            )!;
          }

          if (branch == null) {
            Logger.error(
              "Branch not found",
              {},
              {
                prim,
                from,
                to,
                isMain,
              },
            );
            return null;
          }

          const main = prim.mains.find(
            (m) => PLEndpointEq(m, from) || PLEndpointEq(m, to),
          )!;

          if (isSpecConverging(branch.idealSpec)) {
            const bestSpec = getBestConvergingTee(catalog, branch.idealSpec);

            if (bestSpec == null) {
              Logger.error(
                "No best spec found",
                {},
                {
                  branch,
                },
              );
              return null;
            }

            const table = isMain ? bestSpec.mainC : bestSpec.branchC;

            const thisZeta = lookupZeta(table, {
              areaBranchByMain:
                ductSizeAreaMM2(branch.endpoint.size) /
                ductSizeAreaMM2(main.size),
              flowrateBranchByMain:
                branch.endpoint.flowRateLS / main.flowRateLS,
              angleDEG: branch.angleDEG,
              velocityPressureBranchByMain:
                getVelocityPressureKPA(
                  branch.endpoint.flowRateLS,
                  getCrossSectionAreaM2(branch.endpoint.size),
                  densityKgM3,
                ) /
                getVelocityPressureKPA(
                  main.flowRateLS,
                  getCrossSectionAreaM2(main.size),
                  densityKgM3,
                ),
            });

            if (thisZeta == null) {
              return null;
            }
            zetaByConnection[toUid] = thisZeta;
            const referenceVelocityPressureKPA = getVelocityPressureKPA(
              main.flowRateLS,
              getCrossSectionAreaM2(main.size),
              densityKgM3,
            );

            cumulKPA += thisZeta * referenceVelocityPressureKPA;
          } else {
            const bestSpec = getBestDivertingTee(catalog, branch.idealSpec);

            if (bestSpec == null) {
              Logger.error(
                "No best spec found",
                {},
                {
                  branch,
                },
              );
              return null;
            }

            const table = isMain ? bestSpec.mainC : bestSpec.branchC;

            const thisZeta = lookupZeta(table, {
              areaBranchByMain:
                ductSizeAreaMM2(branch.endpoint.size) /
                ductSizeAreaMM2(main.size),
              flowrateBranchByMain:
                branch.endpoint.flowRateLS / main.flowRateLS,
              angleDEG: branch.angleDEG,
              velocityPressureBranchByMain:
                getVelocityPressureKPA(
                  branch.endpoint.flowRateLS,
                  getCrossSectionAreaM2(branch.endpoint.size),
                  densityKgM3,
                ) /
                getVelocityPressureKPA(
                  main.flowRateLS,
                  getCrossSectionAreaM2(main.size),
                  densityKgM3,
                ),
            });

            if (thisZeta == null) {
              return null;
            }
            zetaByConnection[toUid] = thisZeta;
            const referenceVelocityPressureKPA = getVelocityPressureKPA(
              main.flowRateLS,
              getCrossSectionAreaM2(main.size),
              densityKgM3,
            );

            cumulKPA += thisZeta * referenceVelocityPressureKPA;
          }

          break;
        }
        case "y": {
          if (prim.main !== from && prim.main !== to) {
            Logger.error(
              "Main not found",
              {},
              {
                prim,
                from,
                to,
              },
            );
            return null;
          }
          const branch = prim.branches.find(
            (b) => b.endpoint === from || b.endpoint === to,
          )!;

          const bestSpec = isSpecConverging(branch.idealSpec)
            ? getBestSymmetricalConvergingTee(catalog, branch.idealSpec)
            : getBestSymmetricalDivergingTee(catalog, branch.idealSpec);

          if (bestSpec == null) {
            Logger.error(
              "No best spec found",
              {},
              {
                branch,
              },
            );
            return null;
          }

          const thisZeta = lookupZeta(bestSpec.branchC, {
            areaBranchByMain:
              ductSizeAreaMM2(branch.endpoint.size) /
              ductSizeAreaMM2(prim.main.size),
            flowrateBranchByMain:
              branch.endpoint.flowRateLS / prim.main.flowRateLS,
            angleDEG: branch.angleDEG,
            velocityPressureBranchByMain:
              getVelocityPressureKPA(
                branch.endpoint.flowRateLS,
                getCrossSectionAreaM2(branch.endpoint.size),
                densityKgM3,
              ) /
              getVelocityPressureKPA(
                prim.main.flowRateLS,
                getCrossSectionAreaM2(prim.main.size),
                densityKgM3,
              ),
            heightByWidth:
              branch.endpoint.size.type === "rect"
                ? branch.endpoint.size.heightMM / branch.endpoint.size.widthMM
                : undefined,
          });

          if (thisZeta == null) {
            return null;
          }

          zetaByConnection[toUid] = thisZeta;

          const referenceVelocityPressureKPA = getVelocityPressureKPA(
            prim.main.flowRateLS,
            getCrossSectionAreaM2(prim.main.size),
            densityKgM3,
          );

          cumulKPA += thisZeta * referenceVelocityPressureKPA;
          break;
        }
        case "transition":
          const bestTransition = getBestTransition(catalog, prim.idealSpec);
          if (bestTransition == null) {
            Logger.error(
              "No best transition found",
              {},
              {
                prim,
              },
            );
            return null;
          }

          const thisZeta = lookupZeta(bestTransition.data, {
            areaLargeBySmall:
              prim.idealSpec.direction === "converging"
                ? ductSizeAreaMM2(from.size) / ductSizeAreaMM2(to.size)
                : ductSizeAreaMM2(to.size) / ductSizeAreaMM2(from.size),
            angleDEG: prim.taperAngleDEG,
          });

          if (thisZeta == null) {
            return null;
          }
          zetaByConnection[toUid] = thisZeta;

          const referenceVelocityPressureKPA = getVelocityPressureKPA(
            from.flowRateLS,
            getCrossSectionAreaM2(from.size),
            densityKgM3,
          );

          cumulKPA += thisZeta * referenceVelocityPressureKPA;
          break;
        default:
          assertUnreachable(prim);
      }
    }
    zeta = cumulKPA / referenceVelocityPressureKPA;
  }
  if (zeta != null && referenceVelocityPressureKPA != null) {
    const result = {
      zeta,
      referenceVelocityPressureKPA,
      pressureLossKPA: zeta * referenceVelocityPressureKPA,
      zetaByConnection,
    };

    return result;
  }
  return null;
}

function isSpecConverging(
  spec: DuctConvergingTeeSpec | DuctDivergingTeeSpec,
): spec is DuctConvergingTeeSpec {
  switch (spec.jointType) {
    case "circular-converging-tee":
    case "rectangular-converging-tee":
    case "rectangular-symmetrical-converging-tee":
    case "circular-symmetrical-converging-tee":
      return true;
    case "circular-diverging-tee":
    case "rectangular-diverging-tee":
    case "rectangular-symmetrical-diverging-tee":
    case "circular-symmetrical-diverging-tee":
      return false;
  }
  assertUnreachable(spec);
}

function compareCircularConvergingTee(
  a: CircularConvergingTeeSpec,
  b: DuctConvergingTee,
): number {
  assertType<"circular-converging-tee">(b.jointType);
  let cost = 0;
  if (a.jointType !== b.jointType) {
    cost += 10000;
  }
  if (a.branchShape !== b.branchShape) {
    cost += 1000;
  }
  if (a.mainInlet !== b.mainInlet) {
    cost += 100;
  }
  if (a.mainOutlet !== b.mainOutlet) {
    cost += 100;
  }

  if (a.branchAngle !== b.branchAngle) {
    cost += Math.abs(a.branchAngle - b.branchAngle);
  }

  return cost;
}

function compareRectangularConvergingTee(
  a: RectangularConvergingTeeSpec,
  b: DuctConvergingTee,
): number {
  assertType<"rectangular-converging-tee">(b.jointType);
  let cost = 0;
  if (a.jointType !== b.jointType) {
    cost += 10000;
  }
  if (a.branchShape !== b.branchShape) {
    cost += 1000;
  }
  if (a.branchJoint !== b.branchJoint) {
    cost += 1000;
  }
  if (a.mainInlet !== b.mainInlet) {
    cost += 100;
  }
  if (a.mainOutlet !== b.mainOutlet) {
    cost += 100;
  }

  return cost;
}

function compareRectangularSymmetricalConvergingTee(
  a: RectangularSymmetricalConvergingTeeSpec,
  b: DuctConvergingTee,
): number {
  assertType<"rectangular-symmetrical-converging-tee">(b.jointType);
  let cost = 0;
  if (a.jointType !== b.jointType) {
    cost += 10000;
  }
  if (a.branchShape !== b.branchShape) {
    cost += 1000;
  }
  if (a.branchJoint !== b.branchJoint) {
    cost += 1000;
  }
  if (a.mainInlet !== b.mainInlet) {
    cost += 100;
  }
  if (a.mainOutlet !== b.mainOutlet) {
    cost += 100;
  }

  return cost;
}

function compareCircularSymmetricalConvergingTee(
  a: CircularSymmetricalConvergingTeeSpec,
  b: DuctConvergingTee,
): number {
  assertType<"circular-symmetrical-converging-tee">(b.jointType);
  let cost = 0;
  if (a.jointType !== b.jointType) {
    cost += 10000;
  }
  if (a.branchShape !== b.branchShape) {
    cost += 1000;
  }
  if (a.mainInlet !== b.mainInlet) {
    cost += 100;
  }
  if (a.mainOutlet !== b.mainOutlet) {
    cost += 100;
  }

  return cost;
}

function getBestConvergingTee(
  catalog: Catalog,
  spec: DuctConvergingTeeSpec,
): DuctConvergingTee | null {
  let bestSpec: DuctConvergingTee | null = null;
  let bestCost = Infinity;

  for (const [id, tee] of Object.entries(catalog.ductFittings.convergingTees)) {
    let thisCost = 0;
    if (tee.jointType !== spec.jointType) {
      thisCost += 10000;
      if (spec.branchShape !== tee.branchShape) {
        thisCost += 1000;
      }
      if (spec.mainInlet !== tee.mainInlet) {
        thisCost += 100;
      }
      if (spec.mainOutlet !== tee.mainOutlet) {
        thisCost += 10;
      }
    } else {
      switch (spec.jointType) {
        case "circular-converging-tee":
          thisCost += compareCircularConvergingTee(spec, tee);
          break;
        case "rectangular-converging-tee":
          thisCost += compareRectangularConvergingTee(spec, tee);
          break;
        case "rectangular-symmetrical-converging-tee":
          thisCost += compareRectangularSymmetricalConvergingTee(spec, tee);
          break;
        case "circular-symmetrical-converging-tee":
          thisCost += compareCircularSymmetricalConvergingTee(spec, tee);
          break;
        default:
          assertUnreachable(spec);
      }
    }

    if (thisCost < bestCost) {
      bestCost = thisCost;
      bestSpec = tee;
    }
  }

  return bestSpec;
}

function compareCircularDivergingTee(
  a: CircularDivergingTeeSpec,
  b: DuctDivergingTee,
): number {
  assertType<"circular-diverging-tee">(b.jointType);
  let cost = 0;
  if (a.jointType !== b.jointType) {
    cost += 10000;
  }
  if (a.branchShape !== b.branchShape) {
    cost += 1000;
  }
  if (a.mainInlet !== b.mainInlet) {
    cost += 100;
  }
  if (a.mainOutlet !== b.mainOutlet) {
    cost += 100;
  }

  if (a.branchAngle !== b.branchAngle) {
    cost += Math.abs(a.branchAngle - b.branchAngle);
  }

  return cost;
}

function compareRectangularDivergingTee(
  a: RectangularDivergingTeeSpec,
  b: DuctDivergingTee,
): number {
  assertType<"rectangular-diverging-tee">(b.jointType);
  let cost = 0;
  if (a.jointType !== b.jointType) {
    cost += 10000;
  }
  if (a.branchShape !== b.branchShape) {
    cost += 1000;
  }
  if (a.branchJoint !== b.branchJoint) {
    cost += 1000;
  }
  if (a.mainInlet !== b.mainInlet) {
    cost += 100;
  }
  if (a.mainOutlet !== b.mainOutlet) {
    cost += 100;
  }

  return cost;
}

function compareRectangularSymmetricalDivergingTee(
  a: RectangularSymmetricalDivergingTeeSpec,
  b: DuctDivergingTee,
): number {
  assertType<"rectangular-symmetrical-diverging-tee">(b.jointType);
  let cost = 0;
  if (a.jointType !== b.jointType) {
    cost += 10000;
  }
  if (a.branchShape !== b.branchShape) {
    cost += 1000;
  }
  if (a.branchJoint !== b.branchJoint) {
    cost += 1000;
  }
  if (a.mainInlet !== b.mainInlet) {
    cost += 100;
  }
  if (a.mainOutlet !== b.mainOutlet) {
    cost += 100;
  }

  return cost;
}

function compareCircularSymmetricalDivergingTee(
  a: CircularSymmetricalDivergingTeeSpec,
  b: DuctDivergingTee,
): number {
  assertType<"circular-symmetrical-diverging-tee">(b.jointType);
  let cost = 0;
  if (a.jointType !== b.jointType) {
    cost += 10000;
  }
  if (a.branchShape !== b.branchShape) {
    cost += 1000;
  }
  if (a.mainInlet !== b.mainInlet) {
    cost += 100;
  }
  if (a.mainOutlet !== b.mainOutlet) {
    cost += 100;
  }

  return cost;
}
function getBestDivertingTee(
  catalog: Catalog,
  spec: DuctDivergingTeeSpec,
): DuctDivergingTee | null {
  let bestSpec: DuctDivergingTee | null = null;
  let bestCost = Infinity;

  for (const [id, tee] of Object.entries(catalog.ductFittings.divergingTees)) {
    let thisCost = 0;
    if (tee.jointType !== spec.jointType) {
      thisCost += 10000;
      if (spec.branchShape !== tee.branchShape) {
        thisCost += 1000;
      }
      if (spec.mainInlet !== tee.mainInlet) {
        thisCost += 100;
      }
      if (spec.mainOutlet !== tee.mainOutlet) {
        thisCost += 10;
      }
    } else {
      switch (spec.jointType) {
        case "circular-diverging-tee":
          thisCost += compareCircularDivergingTee(spec, tee);
          break;
        case "rectangular-diverging-tee":
          thisCost += compareRectangularDivergingTee(spec, tee);
          break;
        case "rectangular-symmetrical-diverging-tee":
          thisCost += compareRectangularSymmetricalDivergingTee(spec, tee);
          break;
        case "circular-symmetrical-diverging-tee":
          thisCost += compareCircularSymmetricalDivergingTee(spec, tee);
          break;
        default:
          assertUnreachable(spec);
      }
    }

    if (thisCost < bestCost) {
      bestCost = thisCost;
      bestSpec = tee;
    }
  }

  return bestSpec;
}

function getBestSymmetricalConvergingTee(
  catalog: Catalog,
  spec: SymmetricConvergingTeeSpec,
): DuctConvergingTee | null {
  let bestSpec: DuctConvergingTee | null = null;
  let bestCost = Infinity;

  for (const [id, tee] of Object.entries(catalog.ductFittings.convergingTees)) {
    let thisCost = 0;
    if (tee.jointType !== spec.jointType) {
      thisCost += 10000;
      if (spec.branchShape !== tee.branchShape) {
        thisCost += 1000;
      }
      if (spec.mainInlet !== tee.mainInlet) {
        thisCost += 100;
      }
      if (spec.mainOutlet !== tee.mainOutlet) {
        thisCost += 10;
      }
    } else {
      switch (spec.jointType) {
        case "circular-symmetrical-converging-tee":
          thisCost += compareCircularSymmetricalConvergingTee(spec, tee);
          break;
        case "rectangular-symmetrical-converging-tee":
          thisCost += compareRectangularSymmetricalConvergingTee(spec, tee);
          break;
        default:
          assertUnreachable(spec);
      }
    }

    if (thisCost < bestCost) {
      bestCost = thisCost;
      bestSpec = tee;
    }
  }

  return bestSpec;
}

function getBestSymmetricalDivergingTee(
  catalog: Catalog,
  spec: SymmetricDivergingTeeSpec,
): DuctDivergingTee | null {
  let bestSpec: DuctDivergingTee | null = null;
  let bestCost = Infinity;

  for (const [id, tee] of Object.entries(catalog.ductFittings.divergingTees)) {
    let thisCost = 0;
    if (tee.jointType !== spec.jointType) {
      thisCost += 10000;
      if (spec.branchShape !== tee.branchShape) {
        thisCost += 1000;
      }
      if (spec.mainInlet !== tee.mainInlet) {
        thisCost += 100;
      }
      if (spec.mainOutlet !== tee.mainOutlet) {
        thisCost += 10;
      }
    } else {
      switch (spec.jointType) {
        case "circular-symmetrical-diverging-tee":
          thisCost += compareCircularSymmetricalDivergingTee(spec, tee);
          break;
        case "rectangular-symmetrical-diverging-tee":
          thisCost += compareRectangularSymmetricalDivergingTee(spec, tee);
          break;
        default:
          assertUnreachable(spec);
      }
    }

    if (thisCost < bestCost) {
      bestCost = thisCost;
      bestSpec = tee;
    }
  }

  return bestSpec;
}

function getBestTransition(catalog: Catalog, spec: DuctTransitionSpec) {
  let bestSpec: DuctTransition | null = null;
  let bestCost = Infinity;

  for (const [id, transition] of Object.entries(
    catalog.ductFittings.transitions,
  )) {
    let thisCost = 0;
    if (transition.direction !== spec.direction) {
      thisCost = Infinity;
    }
    if (transition.inletShape !== spec.inletShape) {
      thisCost += 1000;
    }
    if (transition.outletShape !== spec.outletShape) {
      thisCost += 1000;
    }

    if (thisCost < bestCost) {
      bestCost = thisCost;
      bestSpec = transition;
    }
  }

  return bestSpec;
}

function closestNumber(n: number, arr: number[]): number {
  let curr = arr[0];
  let diff = Math.abs(n - curr);

  for (let val = 0; val < arr.length; val++) {
    const newdiff = Math.abs(n - arr[val]);
    if (newdiff < diff) {
      diff = newdiff;
      curr = arr[val];
    }
  }

  return curr;
}

// Thanks copilot
export function lookupZeta(
  table: CoefficientData,
  data: {
    areaLargeBySmall?: number;
    areaBranchByMain?: number;
    flowrateBranchByMain?: number;
    angleDEG?: number;
    heightByWidth?: number;
    radiusByWidth?: number;
    velocityPressureBranchByMain?: number;
  },
): number | null {
  switch (table.type) {
    case "airflow-area-table": {
      // TODO: interpolation. For now, just use the closest value.
      const { flowrateBranchByMain, areaBranchByMain } = data;
      if (flowrateBranchByMain == null || areaBranchByMain == null) {
        Logger.error("Missing data", {
          type: table.type,
          flowrateBranchByMain,
          areaBranchByMain,
        });
        return null;
      }
      const Qs = Object.keys(table.QATable).map((k) => parseFloat(k));
      let closestQ = closestNumber(flowrateBranchByMain, Qs);
      const As = Object.keys(table.QATable[closestQ]).map((k) => parseFloat(k));
      let closestA = closestNumber(areaBranchByMain, As);
      return table.QATable[closestQ][closestA];
    }
    case "airflow-table": {
      // TODO: interpolation. For now, just use the closest value.
      const { flowrateBranchByMain } = data;
      if (flowrateBranchByMain == null) {
        Logger.error("Missing data", {
          type: table.type,
          flowrateBranchByMain,
        });
        return null;
      }
      const Qs = Object.keys(table.QTable).map((k) => parseFloat(k));
      let closestQ = closestNumber(flowrateBranchByMain, Qs);
      return table.QTable[closestQ];
    }
    case "angle": {
      const { angleDEG } = data;
      if (angleDEG == null) {
        Logger.error("Missing data", {
          type: table.type,
          angleDEG,
        });
        return null;
      }
      const As = Object.keys(table.AnTable).map((k) => parseFloat(k));
      let closestA = closestNumber(angleDEG, As);
      return table.AnTable[closestA];
    }
    case "angle-dim-ratio": {
      const { angleDEG, heightByWidth } = data;
      if (angleDEG == null || heightByWidth == null) {
        Logger.error("Missing data", {
          type: table.type,
          angleDEG,
          heightByWidth,
        });
        return null;
      }
      const As = Object.keys(table.AnDrTable).map((k) => parseFloat(k));
      let closestA = closestNumber(angleDEG, As);
      const Hs = Object.keys(table.AnDrTable[closestA]).map((k) =>
        parseFloat(k),
      );
      let closestH = closestNumber(heightByWidth, Hs);
      return table.AnDrTable[closestA][closestH];
    }
    case "angle-radius": {
      const { angleDEG, radiusByWidth } = data;
      if (angleDEG == null || radiusByWidth == null) {
        Logger.error("Missing data", {
          type: table.type,
          angleDEG,
          radiusByWidth,
        });
        return null;
      }
      const As = Object.keys(table.AnRTable).map((k) => parseFloat(k));
      let closestA = closestNumber(angleDEG, As);
      const Rs = Object.keys(table.AnRTable[closestA]).map((k) =>
        parseFloat(k),
      );
      let closestR = closestNumber(radiusByWidth, Rs);
      return table.AnRTable[closestA][closestR];
    }
    case "radius-dim-ratio": {
      const { radiusByWidth, heightByWidth } = data;
      if (radiusByWidth == null || heightByWidth == null) {
        Logger.error("Missing data", {
          type: table.type,
          radiusByWidth,
          heightByWidth,
        });
        return null;
      }
      const Rs = Object.keys(table.RDrTable).map((k) => parseFloat(k));
      let closestR = closestNumber(radiusByWidth, Rs);
      const Hs = Object.keys(table.RDrTable[closestR]).map((k) =>
        parseFloat(k),
      );
      let closestH = closestNumber(heightByWidth, Hs);
      return table.RDrTable[closestR][closestH];
    }
    case "velocity-pressure-airflow": {
      const { velocityPressureBranchByMain, flowrateBranchByMain } = data;
      if (
        velocityPressureBranchByMain == null ||
        flowrateBranchByMain == null
      ) {
        Logger.error("Missing data", {
          type: table.type,
          velocityPressureBranchByMain,
          flowrateBranchByMain,
        });
        return null;
      }
      const VPs = Object.keys(table.VPQTable).map((k) => parseFloat(k));
      let closestVP = closestNumber(velocityPressureBranchByMain, VPs);
      const Qs = Object.keys(table.VPQTable[closestVP]).map((k) =>
        parseFloat(k),
      );
      let closestQ = closestNumber(flowrateBranchByMain, Qs);
      return table.VPQTable[closestVP][closestQ];
    }
    case "velocity-pressure-area": {
      const { velocityPressureBranchByMain, areaBranchByMain } = data;
      if (velocityPressureBranchByMain == null || areaBranchByMain == null) {
        Logger.error("Missing data", {
          type: table.type,
          velocityPressureBranchByMain,
          areaBranchByMain,
        });
        return null;
      }
      const VPs = Object.keys(table.VPATable).map((k) => parseFloat(k));
      let closestVP = closestNumber(velocityPressureBranchByMain, VPs);
      const As = Object.keys(table.VPATable[closestVP]).map((k) =>
        parseFloat(k),
      );
      let closestA = closestNumber(areaBranchByMain, As);
      return table.VPATable[closestVP][closestA];
    }
    case "velocity-pressure-main": {
      const { velocityPressureBranchByMain } = data;
      if (velocityPressureBranchByMain == null) {
        Logger.error("Missing data", {
          type: table.type,
          velocityPressureBranchByMain,
        });
        return null;
      }
      const VPs = Object.keys(table.VPMainTable).map((k) => parseFloat(k));
      let closestVP = closestNumber(velocityPressureBranchByMain, VPs);
      return table.VPMainTable[closestVP];
    }
    case "area-airflow-table": {
      const { areaBranchByMain, flowrateBranchByMain } = data;
      if (areaBranchByMain == null || flowrateBranchByMain == null) {
        Logger.error("Missing data", {
          type: table.type,
          areaBranchByMain,
          flowrateBranchByMain,
        });
        return null;
      }
      const As = Object.keys(table.AQTable).map((k) => parseFloat(k));
      let closestA = closestNumber(areaBranchByMain, As);
      const Qs = Object.keys(table.AQTable[closestA]).map((k) => parseFloat(k));
      let closestQ = closestNumber(flowrateBranchByMain, Qs);
      return table.AQTable[closestA][closestQ];
    }
    case "angle-area-big-small": {
      const { angleDEG, areaLargeBySmall } = data;
      if (angleDEG == null || areaLargeBySmall == null) {
        Logger.error("Missing data", {
          type: table.type,
          angleDEG,
          areaLargeBySmall,
        });
        return null;
      }
      const Ans = Object.keys(table.AnATable).map((k) => parseFloat(k));
      let closestA = closestNumber(angleDEG, Ans);
      const As = Object.keys(table.AnATable[closestA]).map((k) =>
        parseFloat(k),
      );
      let closestA2 = closestNumber(areaLargeBySmall, As);
      return table.AnATable[closestA][closestA2];
    }
  }
  assertUnreachable(table);
}
