<template>
  <div class="flex items-center justify-center">
    <img
      v-svg-inline
      :src="iconSrc"
      :width="iconSize"
      :height="iconSize"
      :alt="`Icon ${name}`"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { Icon, IconSize, iconSizeToPx } from "./icons";

const props = withDefaults(
  defineProps<{
    size?: IconSize;
    name: Icon;
  }>(),
  {
    size: "xs",
  },
);

const iconSrc = computed(() => require(`../../assets/icons/${props.name}.svg`));
const iconSize = computed(() => iconSizeToPx(props.size));
</script>
