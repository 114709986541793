import { Catalog, FilterData } from "../../catalog/types";
import CorePlant from "../../coreObjects/corePlant";
import CoreSystemNode from "../../coreObjects/coreSystemNode";
import { PipeCalculation } from "../../document/calculations-objects/conduit-calculations";
import { fillPlantDefaults } from "../../document/entities/plants/plant-defaults";
import { FilterPlantEntity } from "../../document/entities/plants/plant-entity";
import {
  FilterPlant,
  PlantType,
} from "../../document/entities/plants/plant-types";
import { getPlantDatasheet } from "../../document/entities/plants/utils";
import { EntityType } from "../../document/entities/types";
import { getFlowSystem } from "../../document/utils";
import CalculationEngine from "../calculation-engine";
import { TraceCalculation } from "../flight-data-recorder";
import { GlobalFDR } from "../global-fdr";
import { CoreContext } from "../types";

export const DEFAULT_FILTER_PRESSURE_DROP = 0;

export class FilterCalculations {
  @TraceCalculation("Calculating Filter Pressure Drop")
  static getFilterPressureDropKPA(
    context: CoreContext,
    filter: FilterPlantEntity,
  ): number {
    const flowRateLS = this.findFilterFlowRate(context, filter);

    if (!flowRateLS) {
      return 0;
    }

    const optimal = this.findOptimalFilterModel(
      context.catalog,
      filter.plant,
      flowRateLS,
    );

    if (optimal) {
      return this.calculateFilterPressureDrop(optimal, flowRateLS);
    }

    return DEFAULT_FILTER_PRESSURE_DROP;
  }

  @TraceCalculation("Finding optimal filter model")
  static findOptimalFilterModel(
    catalog: Catalog,
    filterPlant: FilterPlant,
    flowRateLS: number,
  ): FilterData | null {
    const datasheet = getPlantDatasheet(filterPlant, catalog, true);

    if (datasheet.length === 0) {
      return null;
    }

    const sorted = datasheet.sort((a, b) => a.minFlowRateLS - b.minFlowRateLS);

    for (const d of sorted) {
      if (flowRateLS <= d.maxFlowRateLS) {
        return d;
      }
    }

    return sorted.at(-1)!;
  }

  private static calculateFilterPressureDrop(
    data: FilterData,
    flowRateLS: number,
  ) {
    const ratio =
      (flowRateLS - data.minFlowRateLS) /
      (data.maxFlowRateLS - data.minFlowRateLS);
    const pDropKPA =
      data.minFlowPressureDropKPA +
      ratio * (data.maxFlowPressureDropKPA - data.minFlowPressureDropKPA);

    return pDropKPA;
  }

  @TraceCalculation("Calculating Filter Flow Rate")
  static findFilterFlowRate(context: CoreContext, filter: FilterPlantEntity) {
    const inlet = context.globalStore.get(filter.inletUid!) as CoreSystemNode;
    const o = context.globalStore.get(filter.uid) as CorePlant;

    const inletFS = getFlowSystem(context.drawing, inlet.entity.systemUid);
    const pipe = CorePlant.getConnectedConduit(
      o.context.globalStore,
      inlet.uid,
      inletFS!,
    );
    if (pipe) {
      const pipeCalc = context.globalStore.getOrCreateCalculation(pipe.entity);
      return (pipeCalc as PipeCalculation).PSDFlowRateLS;
    }

    return null;
  }

  @TraceCalculation("Determining Filter Models")
  static determineFilterModels(engine: CalculationEngine) {
    for (const o of engine.networkObjects()) {
      GlobalFDR.focusData([o.entity.uid]);
      if (o.entity.type !== EntityType.PLANT) {
        continue;
      }

      if (o.entity.plant.type !== PlantType.FILTER) {
        continue;
      }

      const flowRateLS = this.findFilterFlowRate(
        engine,
        o.entity as FilterPlantEntity,
      );

      if (!flowRateLS) {
        continue;
      }

      const filled = fillPlantDefaults(engine, o.entity, true);
      if (filled.plant.type !== PlantType.FILTER) {
        continue;
      }

      const optimal = this.findOptimalFilterModel(
        engine.catalog,
        filled.plant,
        flowRateLS,
      );

      if (!optimal) {
        continue;
      }

      const calc = engine.globalStore.getOrCreateCalculation(o.entity);
      calc.model = optimal.model!;
      calc.depthMM = optimal.depthMM;
      calc.widthMM = optimal.widthMM;
      calc.minPressureKPA = optimal.minPressureKPA;
      calc.maxPressureKPA = optimal.maxPressureKPA;
    }
  }
}
