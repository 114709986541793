import DrawablePlant from "../drawablePlant";

import { zipWith } from "lodash";
import { CoreV2Radiator } from "../../../../../common/src/api/coreObjects/v2-radiators/coreV2Radiator";
import {
  V2RadiatorEntity,
  isV2RadiatorEntity,
} from "../../../../../common/src/api/document/entities/plants/plant-entity";
import { topDownLeftRightIOData } from "../../../../../common/src/api/document/entities/plants/v2-radiator/ios-data-in-order";
import { ConnectionMode } from "../../../../../common/src/api/document/entities/plants/v2-radiator/radiator-layout";
import { Coord, coordDist2 } from "../../../../../common/src/lib/coord";
import { MainEventBus } from "../../../store/main-event-bus";
import { AutoCADExportEntity } from "../../lib/autocad-export/jsonExport";
import { EntityPopupContent } from "../../lib/entity-popups/types";
import { Interaction } from "../../lib/interaction";
import { generateAutoCADEntitiesForV2Radiator } from "./autoCAD";

export type DrawableV2RadiatorPlant = DrawablePlant & {
  entity: V2RadiatorEntity;
};

export function isDrawableV2Radiator(
  plant: DrawablePlant,
): plant is DrawableV2RadiatorPlant {
  return isV2RadiatorEntity(plant.entity);
}

export class DrawableV2Radiator {
  /**
   * @param radiator
   * @returns The position of the drawn system node, as opposed to CoreV2Radiator.getInletsOutletsPositions
   * which returns the generated system node
   */
  static getInletsOutletsPositions(
    radiator: DrawableV2RadiatorPlant,
  ): Record<string, { center: Coord; systemUid: string }> {
    const nodesData = topDownLeftRightIOData(radiator.entity);

    const routingGuides = CoreV2Radiator.getRoutingGuides(radiator);
    const ret = zipWith(nodesData, routingGuides, (nodeData, routingGuide) => {
      const { uid: systemNodeUid, systemUid } = nodeData;
      return [
        systemNodeUid,
        {
          systemUid,
          center:
            routingGuide?.drawnSystemNode ??
            CoreV2Radiator.getGeneratedSystemNodeObjCoord(
              radiator,
              systemNodeUid,
            ),
        },
      ];
    });

    return Object.fromEntries(ret);
  }

  static getPopupContent(
    radiator: DrawableV2RadiatorPlant,
    objCoord: Coord,
  ): EntityPopupContent[] {
    const result: EntityPopupContent[] = [];
    if (radiator.inBounds(objCoord)) {
      result.push(...radiator.maybeGetLiveCalcWarnings());
    }
    if (radiator.isHovering) {
      result.push({
        title: "Generated pipes and lockshield valves are not editable",
        description: `Highlighted pipes and lockshield valves are generated and not editable.`,
      });
    }
    if (
      radiator.isHovering &&
      radiator.entity.plant.connectionsLayout.connectionMode ===
        ConnectionMode.GenerateRightAnglePipes
    ) {
      result.push({
        title:
          "Automatic 90° angle pipe generation is turned on for this radiator.",
        description: "",
        actions: [
          {
            name: "Show me where to change this property",
            action: () =>
              MainEventBus.$emit("select", {
                uid: radiator.uid,
                property: "plant.connectionsLayout.connectionMode",
              }),
          },
        ],
      });
    }
    return result;
  }

  static offerJoiningInteraction(
    radiator: DrawableV2RadiatorPlant,
    interaction: Interaction,
  ) {
    const inouts = radiator.getInletsOutlets();
    const candidates = inouts
      .sort((a, b) => {
        const awc = a.toWorldCoord();
        const bwc = b.toWorldCoord();
        return (
          coordDist2(awc, interaction.worldCoord) -
          coordDist2(bwc, interaction.worldCoord)
        );
      })
      .filter((sys) => sys.offerInteraction(interaction));

    if (candidates.length > 0) {
      return [...candidates.map((c) => c.entity), radiator.entity];
    }
    return null;
  }

  static getAutoCADExportEntities(
    radiator: DrawableV2RadiatorPlant,
  ): AutoCADExportEntity[] {
    return generateAutoCADEntitiesForV2Radiator(radiator);
  }

  static inBounds(radiator: DrawableV2RadiatorPlant, objCoord: Coord): boolean {
    const entitywidthMM = radiator.widthMM;
    const entityHeightMM = radiator.heightMM;

    const l = -entitywidthMM / 2;
    const r = entitywidthMM / 2;
    const b = entityHeightMM / 2;
    const t = -entityHeightMM / 2;
    if (
      objCoord.x >= l &&
      objCoord.y >= t &&
      objCoord.x <= r &&
      objCoord.y <= b
    ) {
      return true;
    }
    return false;
  }
}
