import { GetterTree } from "vuex";
import { SubscriptionSummary } from "../../../../common/src/api/subscriptions/subscription";
import { Subscription } from "../../../../common/src/models/Subscription";
import { RootState } from "../types";
import { SubscriptionState } from "./types";

export const getters: GetterTree<SubscriptionState, RootState> = {
  activeSeats(state: SubscriptionState): number {
    return state.activeSeats;
  },
  autodeskSeats(state: SubscriptionState): number {
    return state.autodeskSeats;
  },
  details(state: SubscriptionState): SubscriptionSummary["details"] {
    return state.details;
  },
  pastDueSubscription(state: SubscriptionState): Subscription | undefined {
    return state.details.find(
      (detail) => detail.subscription.state === "past_due",
    )?.subscription;
  },
};
