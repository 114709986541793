import { Coord3D, coord3DAdd } from "../../../lib/coord";
import { canonizeAngleDeg } from "../../../lib/mathUtils/mathutils";
import {
  Precision,
  Units,
  UnitsContext,
  convertMeasurementSystem,
} from "../../../lib/measurements";
import {
  EPS,
  assertType,
  assertUnreachable,
  cloneSimple,
} from "../../../lib/utils";
import { Vector3 } from "../../../lib/vector3";
import { CoreContext } from "../../calculations/types";
import {
  DuctConvergingTeeSpec,
  DuctDivergingTeeSpec,
  DuctElbowSpec,
  DuctTransitionSpec,
  SymmetricConvergingTeeSpec,
  SymmetricDivergingTeeSpec,
} from "../../catalog/ventilation/duct-fittings";
import { DuctPhysicalMaterial, isFlowReversed } from "../../config";
import { UnitsParameters } from "../../document/drawing";
import {
  DuctConduitEntity,
  fillDefaultConduitFields,
} from "../../document/entities/conduit-entity";
import {
  DuctFittingEntity,
  fillFittingDefaultFields,
} from "../../document/entities/fitting-entity";
import { EntityType } from "../../document/entities/types";
import CoreFitting from "../coreFitting";
import { determineConnectableModelConduit } from "../utils";

const FITTING_STRAIGHT_ANGLE_DEG = 20;
const FITTING_DEADBEEF_LS = 88.880042069;

// Rect largest, then compare (diameters for circles) or (area for rects)
function ductSizeCmp(a: DuctSize, b: DuctSize): number {
  if (a.type === "rect" && b.type === "rect") {
    return a.widthMM * a.heightMM - b.widthMM * b.heightMM;
  } else if (a.type === "rect") {
    return 1;
  } else if (b.type === "rect") {
    return -1;
  } else {
    // both circle
    return a.diameterMM - b.diameterMM;
  }
  return 0;
}

export function ductSizeAreaCmp(a: DuctSize, b: DuctSize): number {
  return ductSizeAreaMM2(a) - ductSizeAreaMM2(b);
}

export function ductSizeAreaMM2(a: DuctSize): number {
  if (a.type === "rect") {
    return a.widthMM * a.heightMM;
  } else {
    return Math.PI * (a.diameterMM / 2) ** 2;
  }
}

export function chooseYSize(
  main: DuctSize,
  a: DuctSize,
  b: DuctSize,
): DuctSize {
  if (main.type === a.type && main.type === b.type) {
    // based on largest now
    if (ductSizeAreaCmp(a, b) > 0) {
      return a;
    } else {
      return b;
    }
  } else if (main.type === a.type) {
    if (ductSizeAreaCmp(a, b) < 0) {
      // b is larger, so if we sized it to a, it will be too small.
      return main;
    } else {
      // if we size it to a, b will be fine.
      return a;
    }
  } else if (main.type === b.type) {
    if (ductSizeAreaCmp(b, a) < 0) {
      return main;
    } else {
      return b;
    }
  } else {
    return main;
  }
}

export function getPhysicalEndpoints(
  primitive: DuctworkPrimitive,
): PrimitiveEndpoint[] {
  switch (primitive.type) {
    case "nipple":
      return primitive.eps;
    case "elbow":
      return primitive.eps;
    case "takeoff":
      return [primitive.main, primitive.branch];
    case "conduit":
      return primitive.eps;
    case "transition":
      return primitive.eps;
    case "y":
      return [primitive.main, ...primitive.branches];
  }
}

export function getDuctSizeStr(
  size: DuctSize,
  unitsParams: UnitsParameters,
): string {
  switch (size.type) {
    case "circ":
      const [dU, dV] = convertMeasurementSystem(
        unitsParams,
        Units.Millimeters,
        size.diameterMM,
        Precision.DISPLAY_SHORT,
        UnitsContext.NONE,
      );
      return `${dV} ${dU}`;
    case "rect":
      const [wU, wV] = convertMeasurementSystem(
        unitsParams,
        Units.Millimeters,
        size.widthMM,
        Precision.DISPLAY_SHORT,
        UnitsContext.NONE,
      );
      const [hU, hV] = convertMeasurementSystem(
        unitsParams,
        Units.Millimeters,
        size.heightMM,
        Precision.DISPLAY_SHORT,
        UnitsContext.NONE,
      );
      return `${wV}${wU} x ${hV}${hU}`;
  }
}

function ductSizeDiff(
  a: DuctSize,
  b: DuctSize,
  perspective: "width" | "height" = "width",
): number {
  if (a.type === "rect" && b.type === "rect") {
    if (perspective === "width") {
      return a.widthMM - b.widthMM;
    } else {
      return a.heightMM - b.heightMM;
    }
  } else if (a.type === "rect") {
    assertType<"circ">(b.type);
    if (perspective === "width") {
      return a.widthMM - b.diameterMM;
    } else {
      return a.heightMM - b.diameterMM;
    }
  } else if (b.type === "rect") {
    assertType<"circ">(a.type);
    if (perspective === "width") {
      return a.diameterMM - b.widthMM;
    } else {
      return a.diameterMM - b.heightMM;
    }
  } else {
    // both circle
    return a.diameterMM - b.diameterMM;
  }
  return 0;
}

export function getDuctWidthMM(ductSize: DuctSize): number {
  switch (ductSize.type) {
    case "circ":
      return ductSize.diameterMM;
    case "rect":
      return ductSize.widthMM;
  }
}

interface CircularSize {
  type: "circ";
  diameterMM: number;
}

interface RectangularSize {
  type: "rect";
  widthMM: number;
  heightMM: number;
}

export type DuctSize = CircularSize | RectangularSize;

interface ExternalEndpoint {
  type: "external";
  coord: Coord3D;
  size: DuctSize;
  connection: string;
  isDestEndpoint: boolean;
}

interface InternalEndpoint {
  type: "internal";
  coord: Coord3D;
  size: DuctSize;
  id: string;
}

export type PrimitiveEndpoint = ExternalEndpoint | InternalEndpoint;

export function primitiveEndpointEq(
  a: PrimitiveEndpoint,
  b: PrimitiveEndpoint,
) {
  if (a.type !== b.type) {
    return false;
  }
  if (a.type === "external") {
    return b.type === "external" && a.connection === b.connection;
  } else {
    return b.type === "internal" && a.id === b.id;
  }
}

export interface ExternalPLEndpoint {
  type: "external";
  connection: string;
  size: DuctSize;
  // Flow going into the fitting is positive, out is negative.
  flowRateLS: number;
}

export interface InternalPLEndpoint {
  type: "internal";
  id: string;
  size: DuctSize;
  // Flow going into the fitting is positive, out is negative.
  flowRateLS: number;
}

export type PressureLossEndpoint = ExternalPLEndpoint | InternalPLEndpoint;

export function PLEndpointEq(a: PressureLossEndpoint, b: PressureLossEndpoint) {
  if (a.type !== b.type) {
    return false;
  }
  if (a.type === "external") {
    return b.type === "external" && a.connection === b.connection;
  } else {
    return b.type === "internal" && a.id === b.id;
  }
}

interface NipplePrimitive {
  type: "nipple"; // straight "no-op"
  eps: [PrimitiveEndpoint, PrimitiveEndpoint];
  material: DuctPhysicalMaterial;
}

interface ElbowPrimitive {
  type: "elbow";
  angleDEG: number;
  center: Coord3D;
  jointType:
    | "square"
    | "square-vanes"
    | "smooth"
    | "multi-piece"
    | "smooth-vanes";
  vanes: number | null;
  pieces: number | null;
  eps: [PrimitiveEndpoint, PrimitiveEndpoint];
  material: DuctPhysicalMaterial;
}

interface TakeoffPrimitive {
  type: "takeoff";
  center: Coord3D;
  main: PrimitiveEndpoint;
  mainNormal: Vector3; // normal of the main trunk itself, not normal of the takeoff cross section
  branch: PrimitiveEndpoint;
  branchNormal: Vector3;
  style: "square" | "shoe" | "bell";

  // This is a helper record for rendering, not necessarily used in calculations.
  shoes: Array<{
    coord: Coord3D;
    mainNormal: Vector3;
    lengthMM: number;
  }>;
  material: DuctPhysicalMaterial;
}

interface ConduitPrimitive {
  type: "conduit";
  eps: [PrimitiveEndpoint, PrimitiveEndpoint];
  material: DuctPhysicalMaterial;
}

interface YPrimitive {
  type: "y";
  branches: [PrimitiveEndpoint, PrimitiveEndpoint];
  main: PrimitiveEndpoint;
  mainNormal: Vector3;
  branchNormals: [Vector3, Vector3];
  center: Coord3D;
  style: "breech" | "y-piece" | "square";
  material: DuctPhysicalMaterial;
}

interface TransitionPrimitive {
  type: "transition";
  eps: [PrimitiveEndpoint, PrimitiveEndpoint];
  material: DuctPhysicalMaterial;
}

export type DuctworkPrimitive =
  | NipplePrimitive
  | ElbowPrimitive
  | TakeoffPrimitive
  | ConduitPrimitive
  | TransitionPrimitive
  | YPrimitive;

// Both should be identical size
interface NipplePressureLossPrimitive {
  type: "nipple";
  eps: [PressureLossEndpoint, PressureLossEndpoint];
}

interface TransitionPressureLossPrimitive {
  type: "transition";
  taperAngleDEG: number;
  direction: "converging" | "diverging";
  eps: [PressureLossEndpoint, PressureLossEndpoint];
  idealSpec: DuctTransitionSpec;
}

// direction doesn't matter as we expect the same size on both sides
interface ElbowPressureLossPrimitive {
  type: "elbow";
  idealSpec: DuctElbowSpec;
  angleDEG: number;
  // only used if spec is a smooth elbow
  turnRadiusMM: number;
  eps: [PressureLossEndpoint, PressureLossEndpoint];
}

// Mains should be same size.
interface TeePressureLossPrimitive {
  type: "tee";
  // Converging means all branches flow inwards, diverging means all branches flow outwards.
  // If branches are mixed, the fitting is invalid.
  mains: [PressureLossEndpoint, PressureLossEndpoint];
  branches: Array<{
    idealSpec: DuctConvergingTeeSpec | DuctDivergingTeeSpec;
    endpoint: PressureLossEndpoint;
    angleDEG: number; // angle between direction of main flow and branch. 0 means straight in same direction as flow.
  }>;
}

interface YPressureLossPrimitive {
  type: "y";
  main: PressureLossEndpoint;
  branches: Array<{
    // TODO: input symmetrical tee data in the catalog.
    idealSpec: SymmetricConvergingTeeSpec | SymmetricDivergingTeeSpec;
    endpoint: PressureLossEndpoint;
    angleDEG: number; // angle between direction of main flow and branch. 0 means straight in same direction as flow.
  }>;
}

export type DuctPressureLossPrimitive =
  | NipplePressureLossPrimitive
  | TransitionPressureLossPrimitive
  | ElbowPressureLossPrimitive
  | TeePressureLossPrimitive
  | YPressureLossPrimitive;

interface DuctFittingGenContext {
  connections: string[];
  vectors: Vector3[];
  sizes: DuctSize[];
  myCenter: Coord3D;
  flowRateLSs: number[];
  inlets: string[];
  outlets: string[];
  material: DuctPhysicalMaterial;
}

function circCircTee(options: {
  dfgContext: DuctFittingGenContext;
  filled: DuctFittingEntity;
  branchIdx: number;
  main1Idx: number;
  main2Idx: number;
  mainTeeSize: CircularSize;
  branchSize: CircularSize;
  isConverging: boolean;
  main1EP: PrimitiveEndpoint;
  main2EP: PrimitiveEndpoint;
}):
  | {
      success: true;
      I1: number;
      I2: number;
      takeoffDuctwork: TakeoffPrimitive;
      teePressureLoss: TeePressureLossPrimitive;
    }
  | {
      success: false;
      fatal: boolean;
      reason: string;
    } {
  const {
    dfgContext,
    filled,
    branchIdx,
    main1Idx,
    main2Idx,
    isConverging,
    mainTeeSize,
    branchSize,
    main1EP,
    main2EP,
  } = options;
  const {
    connections,
    vectors,
    sizes,
    myCenter,
    flowRateLSs,
    inlets,
    outlets,
  } = dfgContext;
  const main1 = connections[main1Idx];
  const main2 = connections[main2Idx];
  const branch = connections[branchIdx];
  let I2 = 0;
  let I1 = 0;
  let shoe1 = false;
  let shoe2 = false;
  switch (filled.fitting.circCircTee) {
    case "square": {
      break;
    }
    case "shoe": {
      // One shoe - depends on the flow direction.
      if (isConverging === inlets.includes(main1)) {
        shoe2 = true;
      } else {
        shoe1 = true;
      }
      break;
    }
    case "bell": {
      shoe1 = true;
      shoe2 = true;
      break;
    }
    case null:
      throw new Error("circCircTee is null");
    default:
      assertUnreachable(filled.fitting.circCircTee);
  }
  const angleRAD = vectors[branchIdx].angleTo(vectors[main1Idx]);
  const angleDEG = (angleRAD * 180) / Math.PI;
  const off90RAD = Math.PI / 2 - angleRAD;

  const mainSurfaceOffset = -(mainTeeSize.diameterMM / 2) / Math.tan(angleRAD);
  const branchSurfaceOffset = branchSize.diameterMM / 2 / Math.sin(angleRAD);

  I2 = mainSurfaceOffset + branchSurfaceOffset;
  I1 = mainSurfaceOffset - branchSurfaceOffset;

  const shoeSize = filled.fitting.shoeLengthRatio! * mainTeeSize.diameterMM;

  const shoes: TakeoffPrimitive["shoes"] = [];

  const takeoffMainOffset = mainTeeSize.diameterMM / 2 / Math.cos(off90RAD);
  const takeoffBranchOffsetWithoutShoe =
    takeoffMainOffset +
    (branchSize.diameterMM / 2) * Math.tan(Math.abs(off90RAD));
  const takeoffBranchOffsetWithShoe = takeoffBranchOffsetWithoutShoe + shoeSize;

  const mainBase = coord3DAdd(
    myCenter,
    vectors[branchIdx].mul(takeoffMainOffset),
  );

  if (shoe1) {
    I1 -= shoeSize;
    shoes.push({
      coord: coord3DAdd(
        mainBase,
        vectors[main1Idx].mul(
          branchSize.diameterMM / 2 / Math.cos(Math.abs(off90RAD)),
        ),
      ),
      mainNormal: vectors[main1Idx],
      lengthMM: shoeSize,
    });
  }
  if (shoe2) {
    I2 += shoeSize;
    shoes.push({
      coord: coord3DAdd(
        mainBase,
        vectors[main2Idx].mul(
          branchSize.diameterMM / 2 / Math.cos(Math.abs(off90RAD)),
        ),
      ),
      mainNormal: vectors[main2Idx],
      lengthMM: shoeSize,
    });
  }

  return {
    success: true,
    I1,
    I2,
    takeoffDuctwork: {
      type: "takeoff",
      center: myCenter,
      main: {
        type: "external",
        connection: main1,
        coord: mainBase,
        size: sizes[main1Idx],
        isDestEndpoint: false,
      },
      branch: {
        type: "external",
        connection: branch,
        coord: coord3DAdd(
          myCenter,
          vectors[branchIdx].mul(
            shoe1 || shoe2
              ? takeoffBranchOffsetWithShoe
              : takeoffBranchOffsetWithoutShoe,
          ),
        ),
        size: sizes[branchIdx],
        isDestEndpoint: true,
      },
      branchNormal: vectors[branchIdx],
      style: filled.fitting.circCircTee,
      mainNormal: vectors[main1Idx],
      shoes,
      material: dfgContext.material,
    },
    teePressureLoss: {
      type: "tee",
      mains: [
        {
          ...main1EP,
          flowRateLS: inlets.includes(main1)
            ? flowRateLSs[main1Idx]
            : -flowRateLSs[main1Idx],
        },
        {
          ...main2EP,
          flowRateLS: inlets.includes(main2)
            ? flowRateLSs[main2Idx]
            : -flowRateLSs[main2Idx],
        },
      ],
      branches: [
        {
          idealSpec: {
            jointType: isConverging
              ? "circular-converging-tee"
              : "circular-diverging-tee",
            branchShape: "circular",
            mainInlet: "circular",
            mainOutlet: "circular",
            branchAngle: angleDEG,
          },
          angleDEG,
          endpoint: {
            type: "external",
            connection: branch,
            size: sizes[branchIdx],
            flowRateLS: inlets.includes(branch)
              ? flowRateLSs[branchIdx]
              : -flowRateLSs[branchIdx],
          },
        },
      ],
    },
  };
}

function rectRectTee(options: {
  dfgContext: DuctFittingGenContext;
  filled: DuctFittingEntity;
  branchIdx: number;
  main1Idx: number;
  main2Idx: number;
  mainTeeSize: RectangularSize;
  branchSize: RectangularSize;
  isConverging: boolean;
  main1EP: PrimitiveEndpoint;
  main2EP: PrimitiveEndpoint;
}):
  | {
      success: true;
      I1: number;
      I2: number;
      takeoffDuctwork: TakeoffPrimitive;
      teePressureLoss: TeePressureLossPrimitive;
    }
  | {
      success: false;
      fatal: boolean;
      reason: string;
    } {
  const {
    dfgContext,
    filled,
    branchIdx,
    main1Idx,
    main2Idx,
    isConverging,
    mainTeeSize,
    branchSize,
    main1EP,
    main2EP,
  } = options;
  const {
    connections,
    vectors,
    sizes,
    myCenter,
    flowRateLSs,
    inlets,
    outlets,
  } = dfgContext;
  const main1 = connections[main1Idx];
  const main2 = connections[main2Idx];
  const branch = connections[branchIdx];

  let shoe1 = false;
  let shoe2 = false;
  switch (filled.fitting.rectRectTee) {
    case "square": {
      break;
    }
    case "shoe": {
      // One shoe - depends on the flow direction.
      if (isConverging === inlets.includes(main1)) {
        shoe2 = true;
      } else {
        shoe1 = true;
      }
      break;
    }
    case "bell": {
      shoe1 = true;
      shoe2 = true;
      break;
    }
    case null:
      throw new Error("rectRectTee is null");
    default:
      assertUnreachable(filled.fitting.rectRectTee);
  }

  const angleRAD = vectors[branchIdx].angleTo(vectors[main1Idx]);
  const angleDEG = (angleRAD * 180) / Math.PI;
  const off90RAD = angleRAD - Math.PI / 2;

  const mainSurfaceOffset = -(mainTeeSize.widthMM / 2) / Math.tan(angleRAD);
  const branchSurfaceOffset = branchSize.widthMM / 2 / Math.sin(angleRAD);

  const takeoffMainOffset = mainTeeSize.widthMM / 2 / Math.cos(off90RAD);
  const takeoffBranchOffset =
    takeoffMainOffset + (branchSize.widthMM / 2) * Math.tan(Math.abs(off90RAD));

  let I2 = mainSurfaceOffset + branchSurfaceOffset;
  let I1 = mainSurfaceOffset - branchSurfaceOffset;

  const shoeSize = filled.fitting.shoeLengthRatio! * mainTeeSize.widthMM;

  const shoes: TakeoffPrimitive["shoes"] = [];

  const mainBase = coord3DAdd(
    myCenter,
    vectors[branchIdx].mul(takeoffMainOffset),
  );

  if (shoe1) {
    I1 -= shoeSize;
    shoes.push({
      coord: coord3DAdd(
        mainBase,
        vectors[main1Idx].mul(
          branchSize.widthMM / 2 / Math.cos(Math.abs(off90RAD)),
        ),
      ),
      mainNormal: vectors[main1Idx],
      lengthMM: shoeSize,
    });
  }
  if (shoe2) {
    I2 += shoeSize;
    shoes.push({
      coord: coord3DAdd(
        mainBase,
        vectors[main2Idx].mul(
          branchSize.widthMM / 2 / Math.cos(Math.abs(off90RAD)),
        ),
      ),
      mainNormal: vectors[main2Idx],
      lengthMM: shoeSize,
    });
  }

  return {
    success: true,
    I1,
    I2,

    takeoffDuctwork: {
      type: "takeoff",
      center: myCenter,
      main: {
        type: "external",
        connection: main1,
        coord: mainBase,
        size: sizes[main1Idx],
        isDestEndpoint: false,
      },
      branch: {
        type: "external",
        connection: branch,
        coord: coord3DAdd(
          myCenter,
          vectors[branchIdx].mul(
            shoe1 || shoe2
              ? takeoffBranchOffset + shoeSize
              : takeoffBranchOffset,
          ),
        ),
        size: sizes[branchIdx],
        isDestEndpoint: true,
      },
      branchNormal: vectors[branchIdx],
      style: filled.fitting.rectRectTee,
      mainNormal: vectors[main1Idx],
      shoes,
      material: dfgContext.material,
    },
    teePressureLoss: {
      type: "tee",
      mains: [
        {
          ...main1EP,
          flowRateLS: inlets.includes(main1)
            ? flowRateLSs[main1Idx]
            : -flowRateLSs[main1Idx],
        },
        {
          ...main2EP,
          flowRateLS: inlets.includes(main2)
            ? flowRateLSs[main2Idx]
            : -flowRateLSs[main2Idx],
        },
      ],
      branches: [
        {
          idealSpec: {
            jointType: isConverging
              ? "rectangular-converging-tee"
              : "rectangular-diverging-tee",
            branchShape: "rectangular",
            mainInlet: "rectangular",
            mainOutlet: "rectangular",
            branchJoint: "square",
          },
          angleDEG,
          endpoint: {
            type: "external",
            connection: branch,
            size: sizes[branchIdx],
            flowRateLS: inlets.includes(branch)
              ? flowRateLSs[branchIdx]
              : -flowRateLSs[branchIdx],
          },
        },
      ],
    },
  };
}

function rectCircTee(options: {
  dfgContext: DuctFittingGenContext;
  filled: DuctFittingEntity;
  branchIdx: number;
  main1Idx: number;
  main2Idx: number;
  mainTeeSize: RectangularSize;
  branchSize: CircularSize;
  isConverging: boolean;
  main1EP: PrimitiveEndpoint;
  main2EP: PrimitiveEndpoint;
}):
  | {
      success: true;
      I1: number;
      I2: number;
      takeoffDuctwork: TakeoffPrimitive;
      teePressureLoss: TeePressureLossPrimitive;
    }
  | {
      success: false;
      fatal: boolean;
      reason: string;
    } {
  const {
    dfgContext,
    filled,
    branchIdx,
    main1Idx,
    main2Idx,
    isConverging,
    mainTeeSize,
    branchSize,
    main1EP,
    main2EP,
  } = options;
  const {
    connections,
    vectors,
    sizes,
    myCenter,
    flowRateLSs,
    inlets,
    outlets,
  } = dfgContext;
  const main1 = connections[main1Idx];
  const main2 = connections[main2Idx];
  const branch = connections[branchIdx];

  let I2 = 0;
  let I1 = 0;
  let shoe1 = false;
  let shoe2 = false;
  switch (filled.fitting.rectCircTee) {
    case "square": {
      break;
    }
    case "shoe": {
      // One shoe - depends on the flow direction.
      if (isConverging === inlets.includes(main1)) {
        shoe2 = true;
      } else {
        shoe1 = true;
      }
      break;
    }
    case "bell": {
      shoe1 = true;
      shoe2 = true;
      break;
    }
    case null:
      throw new Error("circCircTee is null");
    default:
      assertUnreachable(filled.fitting.rectCircTee);
  }
  const angleRAD = vectors[branchIdx].angleTo(vectors[main1Idx]);
  const angleDEG = (angleRAD * 180) / Math.PI;
  const off90RAD = angleRAD - Math.PI / 2;

  const mainSurfaceOffset = -(mainTeeSize.widthMM / 2) / Math.tan(angleRAD);
  const branchSurfaceOffset = branchSize.diameterMM / 2 / Math.sin(angleRAD);

  const takeoffMainOffset = mainTeeSize.widthMM / 2 / Math.cos(off90RAD);
  const takeoffBranchOffset =
    takeoffMainOffset +
    (branchSize.diameterMM / 2) * Math.tan(Math.abs(off90RAD));

  I2 = mainSurfaceOffset + branchSurfaceOffset;
  I1 = mainSurfaceOffset - branchSurfaceOffset;

  const shoeSize = filled.fitting.shoeLengthRatio! * mainTeeSize.widthMM;

  const shoes: TakeoffPrimitive["shoes"] = [];

  const mainBase = coord3DAdd(
    myCenter,
    vectors[branchIdx].mul(takeoffMainOffset),
  );

  if (shoe1) {
    I1 -= shoeSize;
    shoes.push({
      coord: coord3DAdd(
        mainBase,
        vectors[main1Idx].mul(
          branchSize.diameterMM / 2 / Math.cos(Math.abs(off90RAD)),
        ),
      ),
      mainNormal: vectors[main1Idx],
      lengthMM: shoeSize,
    });
  }
  if (shoe2) {
    I2 += shoeSize;
    shoes.push({
      coord: coord3DAdd(
        mainBase,
        vectors[main2Idx].mul(
          branchSize.diameterMM / 2 / Math.cos(Math.abs(off90RAD)),
        ),
      ),
      mainNormal: vectors[main2Idx],
      lengthMM: shoeSize,
    });
  }

  return {
    success: true,
    I2,
    I1,

    takeoffDuctwork: {
      type: "takeoff",
      center: myCenter,
      main: {
        type: "external",
        connection: main1,
        coord: mainBase,
        size: sizes[main1Idx],
        isDestEndpoint: false,
      },
      branch: {
        type: "external",
        connection: branch,
        coord: coord3DAdd(
          myCenter,
          vectors[branchIdx].mul(
            shoe1 || shoe2
              ? takeoffBranchOffset + shoeSize
              : takeoffBranchOffset,
          ),
        ),
        size: sizes[branchIdx],
        isDestEndpoint: true,
      },
      branchNormal: vectors[branchIdx],
      style: filled.fitting.rectCircTee,
      mainNormal: vectors[main1Idx],
      shoes,
      material: dfgContext.material,
    },

    teePressureLoss: {
      type: "tee",
      mains: [
        {
          ...main1EP,
          flowRateLS: inlets.includes(main1)
            ? flowRateLSs[main1Idx]
            : -flowRateLSs[main1Idx],
        },
        {
          ...main2EP,
          flowRateLS: inlets.includes(main2)
            ? flowRateLSs[main2Idx]
            : -flowRateLSs[main2Idx],
        },
      ],
      branches: [
        {
          idealSpec: {
            jointType: isConverging
              ? "rectangular-converging-tee"
              : "rectangular-diverging-tee",
            branchShape: "circular",
            mainInlet: "rectangular",
            mainOutlet: "rectangular",
            branchJoint: "square",
          },
          angleDEG,
          endpoint: {
            type: "external",
            connection: branch,
            size: sizes[branchIdx],
            flowRateLS: inlets.includes(branch)
              ? flowRateLSs[branchIdx]
              : -flowRateLSs[branchIdx],
          },
        },
      ],
    },
  };
}

// Used by graphics and bill of materials, and maybe pressure drops
// To use for pressure drop, find any fitting spec that includes both endpoints.

// 1. Detect the type of DuctFitting
// 2. Extrapolate the drawable DuctFitting entity to network entities including reducers, transitions, implicit conduits, etc.
export function getDuctFittingPrimitives(
  context: CoreContext,
  fitting: CoreFitting,
):
  | {
      success: true;
      // Specs is a breakdown of the fitting into pressure loss primitives - single components that can be looked up
      // in the catalog to determine pressure loss. To find the pressure loss, do a DFS from one endpoint to another
      // and sum the pressure losses of each primitive. The configuration is guaranteed to be a tree.
      pressureLoss: DuctPressureLossPrimitive[];

      // These primitives are true primitives.
      physical: DuctworkPrimitive[];
    }
  | {
      success: false;
      fatal: boolean;
      reason: string;
    } {
  // case by case <3
  const filled = fillFittingDefaultFields(
    context,
    fitting.entity,
  ) as DuctFittingEntity;

  const connections = context.globalStore.getConnections(fitting.entity.uid);
  const vectors: Vector3[] = [];
  const sizes: DuctSize[] = [];
  const myCenter: Coord3D = {
    ...fitting.toWorldCoord(),
    z: (fitting.entity.calculationHeightM ?? 0) * 1000,
  };

  const inlets: string[] = [];
  const outlets: string[] = [];
  const flowRateLSs: number[] = [];
  const system = context.drawing.metadata.flowSystems[filled.systemUid];
  const isSystemReversed = isFlowReversed(system);
  for (const c of connections) {
    const cond = context.globalStore.getObjectOfTypeOrThrow(
      EntityType.CONDUIT,
      c,
    );
    if (cond.entity.conduitType !== "duct") {
      console.warn("Duct fitting has non-duct conduit", cond);
      return {
        success: false,
        fatal: true,
        reason: "Duct fitting has non-duct conduit",
      };
    }
    const filledCond = fillDefaultConduitFields<DuctConduitEntity>(
      context,
      cond.entity,
    );
    const liveCalc = context.globalStore.getOrCreateLiveCalculation(
      cond.entity as DuctConduitEntity,
    );
    const calc = context.globalStore.getOrCreateCalculation(
      cond.entity as DuctConduitEntity,
    );

    const flowFrom = liveCalc.flowFrom ?? calc.flowFrom ?? null;
    // TODO: total peak flow rate in live calculation for vents
    const flowRateLS = calc.totalPeakFlowRateLS ?? FITTING_DEADBEEF_LS;
    if (flowFrom) {
      if ((flowFrom === filled.uid) !== isSystemReversed) {
        outlets.push(c);
      } else {
        inlets.push(c);
      }
      flowRateLSs.push(flowRateLS);
    } else {
      // not connected, cannot calculate :/

      return {
        success: false,
        fatal: false,
        reason: "Not connected",
      };
    }

    if (cond) {
      const other = cond.entity.endpointUid.filter(
        (e) => e !== fitting.entity.uid,
      )[0];
      const otherObj = context.globalStore.ofTagOrThrow("connectable", other);
      const otherCoord = {
        ...otherObj.toWorldCoord(),
        z: (otherObj.entity.calculationHeightM ?? 0) * 1000,
      };

      vectors.push(
        new Vector3(
          otherCoord.x - myCenter.x,
          otherCoord.y - myCenter.y,
          otherCoord.z - myCenter.z,
        ).unit,
      );
    } else {
      console.warn(
        "Could not find conduit while calculating duct primitive",
        c,
      );
      return {
        success: false,
        fatal: true,
        reason: "A connected conduit is missing. The drawing may be corrupted.",
      };
    }

    switch (filledCond.conduit.shape) {
      case "circular":
        if (filledCond.conduit.diameterMM == null) {
          console.warn("Duct fitting has null diameter", filledCond);
          return {
            success: false,
            fatal: true,
            reason: "Duct fitting has null diameter",
          };
        }
        sizes.push({ type: "circ", diameterMM: filledCond.conduit.diameterMM });
        break;
      case "rectangular":
        if (
          filledCond.conduit.widthMM == null ||
          filledCond.conduit.heightMM == null
        ) {
          console.warn("Duct fitting has null dimensions", filledCond);
          return {
            success: false,
            fatal: true,
            reason: "Duct fitting has null dimensions",
          };
        }
        sizes.push({
          type: "rect",
          widthMM: filledCond.conduit.widthMM,
          heightMM: filledCond.conduit.heightMM,
        });
        break;
      case null:
        return {
          success: false,
          fatal: true,
          reason: "Duct fitting has null shape",
        };

      default:
        assertUnreachable(filledCond.conduit.shape);
    }
  }

  const modelConduit = determineConnectableModelConduit(
    context.globalStore,
    filled,
  );

  if (connections.length <= 1) {
    // deadleg or invalid.
    return {
      success: false,
      fatal: false,
      reason: "Deadleg",
    };
  }

  if (!modelConduit || modelConduit.conduitType !== "duct") {
    throw new Error("Model conduit could not be determined");
  }

  const filledConduit = fillDefaultConduitFields<DuctConduitEntity>(
    context,
    modelConduit,
  );

  const dfgContext: DuctFittingGenContext = {
    connections,
    vectors,
    sizes,
    myCenter,
    flowRateLSs,
    inlets,
    outlets,
    material: filledConduit.conduit.material!,
  };

  if (connections.length === 2) {
    // Elbow.
    const angleRAD = vectors[0].angleTo(vectors[1]);
    const angleDEG = (angleRAD * 180) / Math.PI;

    // Determine if transition is needed. If so, place on larger side.
    const cmp = ductSizeCmp(sizes[0], sizes[1]);

    if (cmp === 0) {
      // Same size
      if (angleDEG < FITTING_STRAIGHT_ANGLE_DEG) {
        // straight
        return {
          success: true,
          physical: [
            {
              type: "nipple",
              eps: [
                {
                  type: "external",
                  connection: connections[0],
                  coord: myCenter,
                  size: sizes[0],
                  isDestEndpoint: true,
                },
                {
                  type: "external",
                  connection: connections[1],
                  coord: myCenter,
                  size: sizes[1],
                  isDestEndpoint: true,
                },
              ],
              material: dfgContext.material,
            },
          ],
          pressureLoss: [
            {
              type: "nipple",
              eps: [
                {
                  type: "external",
                  connection: connections[0],
                  size: sizes[0],
                  flowRateLS: inlets.includes(connections[0])
                    ? flowRateLSs[0]
                    : -flowRateLSs[0],
                },
                {
                  type: "external",
                  connection: connections[1],
                  size: sizes[1],
                  flowRateLS: inlets.includes(connections[1])
                    ? flowRateLSs[1]
                    : -flowRateLSs[1],
                },
              ],
            },
          ],
        };
      } else {
        // elbow.
        const idealSpec = getElbowCatalogSpec(filled, sizes[0]);

        let turnRadiusMM = 0;
        const angleDEG = vectors[0].angleTo(vectors[1]) * (180 / Math.PI);
        switch (idealSpec.jointType) {
          case "square":
          case "square-vanes": {
            turnRadiusMM = getDuctWidthMM(sizes[0]) / 2;
            break;
          }
          case "smooth":
          case "smooth-vanes":
          case "multi-piece": {
            turnRadiusMM =
              getDuctWidthMM(sizes[0]) * filled.fitting.smoothElbowRadiusRatio!;
            break;
          }
          default:
            assertUnreachable(idealSpec);
        }

        const vanes = filled.fitting.elbowVanes;
        const pieces = filled.fitting.pieces;

        const offset = turnRadiusMM / Math.tan(angleRAD / 2);

        return {
          success: true,
          physical: [
            {
              type: "elbow",
              angleDEG,
              center: myCenter,
              jointType: idealSpec.jointType,
              vanes,
              pieces,
              eps: [
                {
                  type: "external",
                  connection: connections[0],
                  coord: coord3DAdd(myCenter, vectors[0].mul(offset)),
                  size: sizes[0],
                  isDestEndpoint: true,
                },
                {
                  type: "external",
                  connection: connections[1],
                  coord: coord3DAdd(myCenter, vectors[1].mul(offset)),
                  size: sizes[1],
                  isDestEndpoint: true,
                },
              ],
              material: dfgContext.material,
            },
          ],
          pressureLoss: [
            {
              type: "elbow",
              // convention is that straight = 0, acute = 180
              angleDEG: 180 - canonizeAngleDeg(angleDEG),
              idealSpec,
              turnRadiusMM:
                filled.fitting.smoothElbowRadiusRatio! *
                getDuctWidthMM(sizes[0]),
              eps: [
                {
                  type: "external",
                  connection: connections[0],
                  size: sizes[0],
                  flowRateLS: inlets.includes(connections[0])
                    ? flowRateLSs[0]
                    : -flowRateLSs[0],
                },
                {
                  type: "external",
                  connection: connections[1],
                  size: sizes[1],
                  flowRateLS: inlets.includes(connections[1])
                    ? flowRateLSs[1]
                    : -flowRateLSs[1],
                },
              ],
            },
          ],
        };
      }
    } else {
      const isFrom0 = inlets.includes(connections[0]);
      const isBiggest0 = cmp > 0;
      const isConverging = isFrom0 === isBiggest0;

      if (angleDEG < FITTING_STRAIGHT_ANGLE_DEG) {
        // single transition
        const areaCmp = ductSizeAreaCmp(sizes[0], sizes[1]);
        const isBiggestArea0 = areaCmp > 0;
        const isConvergingArea = isFrom0 === isBiggestArea0;

        const taperAngleDEG = filled.fitting.transitionAngle!;

        const taperWidthDiffMM = ductSizeDiff(sizes[0], sizes[1]);
        const taperLength =
          Math.sin(taperAngleDEG * (Math.PI / 180)) * taperWidthDiffMM;

        return {
          success: true,
          physical: [
            {
              type: "transition",
              eps: [
                {
                  type: "external",
                  connection: connections[0],
                  coord: coord3DAdd(myCenter, vectors[0].mul(taperLength / 2)),
                  size: sizes[0],
                  isDestEndpoint: true,
                },
                {
                  type: "external",
                  connection: connections[1],
                  coord: coord3DAdd(myCenter, vectors[1].mul(taperLength / 2)),
                  size: sizes[1],
                  isDestEndpoint: true,
                },
              ],
              material: dfgContext.material,
            },
          ],
          pressureLoss: [
            {
              type: "transition",
              // Data that we get - angle is entire side-to-side.
              // Flat is 0, square is 180 (not 90)
              taperAngleDEG: filled.fitting.transitionAngle! * 2,
              direction: isConvergingArea ? "converging" : "diverging",
              idealSpec: {
                direction: isConvergingArea ? "converging" : "diverging",
                inletShape:
                  (isFrom0 ? sizes[0].type : sizes[1].type) === "circ"
                    ? "circular"
                    : "rectangular",
                outletShape:
                  (isFrom0 ? sizes[1].type : sizes[0].type) === "circ"
                    ? "circular"
                    : "rectangular",
                taperAngleDEG: filled.fitting.transitionAngle!,
              },
              eps: [
                {
                  type: "external",
                  connection: connections[0],
                  size: sizes[0],
                  flowRateLS: inlets.includes(connections[0])
                    ? flowRateLSs[0]
                    : -flowRateLSs[0],
                },
                {
                  type: "external",
                  connection: connections[1],
                  size: sizes[1],
                  flowRateLS: inlets.includes(connections[1])
                    ? flowRateLSs[1]
                    : -flowRateLSs[1],
                },
              ],
            },
          ],
        };
      } else {
        // Elbow with transition.
        let transition: TransitionPrimitive;
        let transitionPD: TransitionPressureLossPrimitive;

        const areaCmp = ductSizeAreaCmp(sizes[0], sizes[1]);
        const isBiggestArea0 = areaCmp > 0;
        const isConvergingArea = isFrom0 === isBiggestArea0;

        const transitionIdx = isBiggest0 ? 0 : 1;
        const elbowIdx = isBiggest0 ? 1 : 0;
        const isFlowTransition = isFrom0 === isBiggest0;

        const idealSpec = getElbowCatalogSpec(filled, sizes[elbowIdx]);

        const taperAngleDEG = filled.fitting.transitionAngle!;

        const taperWidthDiffMM = Math.abs(ductSizeDiff(sizes[0], sizes[1]));
        const taperLength =
          Math.sin(taperAngleDEG * (Math.PI / 180)) * taperWidthDiffMM;

        let turnRadiusMM = 0;
        const angleDEG = vectors[0].angleTo(vectors[1]) * (180 / Math.PI);
        switch (idealSpec.jointType) {
          case "square":
          case "square-vanes": {
            turnRadiusMM = getDuctWidthMM(sizes[elbowIdx]) / 2;
            break;
          }
          case "smooth":
          case "smooth-vanes":
          case "multi-piece": {
            turnRadiusMM =
              getDuctWidthMM(sizes[0]) * filled.fitting.smoothElbowRadiusRatio!;
            break;
          }
          default:
            assertUnreachable(idealSpec);
        }

        const taperOffset = turnRadiusMM / Math.tan(angleRAD / 2);
        const elbowSideCoord = coord3DAdd(
          myCenter,
          vectors[elbowIdx].mul(taperOffset),
        );
        const transitionSideCoord = coord3DAdd(
          myCenter,
          vectors[transitionIdx].mul(taperOffset),
        );
        const vanes = filled.fitting.elbowVanes;
        const pieces = filled.fitting.pieces;
        return {
          success: true,
          physical: [
            {
              type: "transition",
              eps: [
                {
                  type: "external",
                  connection: connections[transitionIdx],
                  coord: coord3DAdd(
                    transitionSideCoord,
                    vectors[transitionIdx].mul(taperLength),
                  ),
                  size: sizes[transitionIdx],
                  isDestEndpoint: true,
                },
                {
                  type: "internal",
                  id: "transition-elbow",
                  coord: transitionSideCoord,
                  size: sizes[elbowIdx],
                },
              ],
              material: dfgContext.material,
            },
            {
              type: "elbow",
              angleDEG,
              center: myCenter,
              jointType: idealSpec.jointType,
              vanes,
              pieces,
              eps: [
                {
                  type: "external",
                  connection: connections[elbowIdx],
                  coord: elbowSideCoord,
                  size: sizes[elbowIdx],
                  isDestEndpoint: true,
                },
                {
                  type: "internal",
                  id: "transition-elbow",
                  coord: transitionSideCoord,
                  size: sizes[elbowIdx],
                },
              ],
              material: dfgContext.material,
            },
          ],
          pressureLoss: [
            {
              type: "transition",
              direction: isConverging ? "converging" : "diverging",
              taperAngleDEG: filled.fitting.transitionAngle!,
              idealSpec: {
                direction: isConvergingArea ? "converging" : "diverging",
                inletShape:
                  (isFrom0 ? sizes[0].type : sizes[1].type) === "circ"
                    ? "circular"
                    : "rectangular",
                outletShape:
                  (isFrom0 ? sizes[1].type : sizes[0].type) === "circ"
                    ? "circular"
                    : "rectangular",
                taperAngleDEG: filled.fitting.transitionAngle!,
              },
              eps: [
                {
                  type: "external",
                  connection: connections[transitionIdx],
                  size: sizes[transitionIdx],
                  flowRateLS: isFlowTransition
                    ? flowRateLSs[transitionIdx]
                    : -flowRateLSs[transitionIdx],
                },
                {
                  type: "internal",
                  id: "transition-elbow",
                  size: sizes[elbowIdx],
                  flowRateLS: isFlowTransition
                    ? -flowRateLSs[transitionIdx]
                    : flowRateLSs[transitionIdx],
                },
              ],
            },
            {
              type: "elbow",
              angleDEG,
              idealSpec,
              turnRadiusMM:
                filled.fitting.smoothElbowRadiusRatio! *
                getDuctWidthMM(sizes[elbowIdx]),
              eps: [
                {
                  type: "external",
                  connection: connections[elbowIdx],
                  size: sizes[elbowIdx],
                  flowRateLS: isFlowTransition
                    ? -flowRateLSs[elbowIdx]
                    : flowRateLSs[elbowIdx],
                },
                {
                  type: "internal",
                  id: "transition-elbow",
                  size: sizes[elbowIdx],
                  flowRateLS: isFlowTransition
                    ? flowRateLSs[elbowIdx]
                    : -flowRateLSs[elbowIdx],
                },
              ],
            },
          ],
        };
      }
    }
  } else {
    /*
     * Identify mains. The mains are always 180 degrees from each other. With the exception of wyes, there is one inlet and
     * one outlet main. To identify, find the single inlet/single outlet, then find the other main that is 180 degrees from
     * the first.
     *
     * To identify the wyes though, we find an absense of the 180 degree relationship. If there is no 180 degree relationship,
     * and there are 2 other connections, then it is a wye.
     */

    if (inlets.length !== 1 && outlets.length !== 1) {
      console.warn(
        filled.uid,
        "Fitting with more than 2 incoming and outgoing TODO",
        { inlets, outlets },
      );
      return {
        success: false,
        fatal: true,
        reason:
          "Fitting with more than 2 connections going in and out - main not identified",
      };
    }

    let main1 = inlets.length === 1 ? inlets[0] : outlets[0];

    let main1Idx = connections.indexOf(main1);
    let main2: string | null = null;
    let angleToMain1 = Infinity;
    for (let i = 0; i < connections.length; i++) {
      if (i !== main1Idx) {
        const thisAng = vectors[i].angleTo(vectors[main1Idx].mul(-1));
        if (thisAng < angleToMain1) {
          angleToMain1 = thisAng;
          main2 = connections[i];
        }
      }
    }

    const ductworks: DuctworkPrimitive[] = [];
    const pressureLosses: DuctPressureLossPrimitive[] = [];

    if (main2 == null) {
      console.warn("Could not find second main", filled.uid);

      return {
        success: false,
        fatal: true,
        reason:
          "Could not identify the mains. Mains must be straight from each other",
      };
    }

    if (angleToMain1 > FITTING_STRAIGHT_ANGLE_DEG * (Math.PI / 180)) {
      // test for symmetrical
      const branch1Index = main1Idx === 0 ? 1 : 0;
      const branch2Index = main1Idx <= 1 ? 2 : 1;
      const branch1 = connections[branch1Index];
      const branch2 = connections[branch2Index];

      const branch1Vec = vectors[branch1Index];
      const branch2Vec = vectors[branch2Index];

      const branch1AngleDEG = branch1Vec.angleTo(branch2Vec) * (180 / Math.PI);
      const branch2AngleDEG = branch2Vec.angleTo(branch1Vec) * (180 / Math.PI);

      const isRight =
        Math.abs(branch1AngleDEG - 90) < FITTING_STRAIGHT_ANGLE_DEG &&
        Math.abs(branch2AngleDEG - 90) < FITTING_STRAIGHT_ANGLE_DEG;

      const isSymmetrical =
        Math.abs(branch1AngleDEG - branch2AngleDEG) <
        FITTING_STRAIGHT_ANGLE_DEG;

      if (isSymmetrical) {
        const mainSize = sizes[main1Idx];
        // tee will follow main size
        /* V1       V2
                    
                     
           E1 \   \/    / E2
               \       /
                \_____/ EM
                 |   |
                 |   |
        
        ~Note: there are no transitions necessary here.~
        Actually, there will be transitions. The symmetrical part will have same size both sides.

        Refer to here for spacings for round Y piece and Y breech:
        https://spiralduct.com.au/products/round-fittings/
         */
        const E1: PrimitiveEndpoint = {
          type: "external",
          connection: branch1,
          coord: myCenter,
          size: sizes[branch1Index],
          isDestEndpoint: true,
        };

        const E2: PrimitiveEndpoint = {
          type: "external",
          connection: branch2,
          coord: myCenter,
          size: sizes[branch2Index],
          isDestEndpoint: true,
        };

        const EM: PrimitiveEndpoint = {
          type: "external",
          connection: main1,
          coord: myCenter,
          size: sizes[main1Idx],
          isDestEndpoint: true,
        };

        let style =
          mainSize.type === "circ"
            ? filled.fitting.circSymmetrical!
            : filled.fitting.rectSymmetrical!;
        const isConverging = outlets.includes(main1);

        const yPLPrimitive: YPressureLossPrimitive = {
          type: "y",
          main: {
            type: "external",
            connection: main1,
            size: sizes[main1Idx],
            flowRateLS: flowRateLSs[main1Idx],
          },
          branches: [
            {
              angleDEG: branch1AngleDEG,
              endpoint: {
                type: "external",
                connection: branch1,
                size: sizes[branch1Index],
                flowRateLS: flowRateLSs[branch1Index],
              },
              // TODO: wyes in catalog
              idealSpec:
                mainSize.type === "circ"
                  ? {
                      jointType: isConverging
                        ? "circular-symmetrical-converging-tee"
                        : "circular-symmetrical-diverging-tee",
                      branchJoint:
                        filled.fitting.circCircTee === "square"
                          ? "square"
                          : "45",
                      branchShape: "circular",
                      mainInlet: "circular",
                      mainOutlet: "circular",
                    }
                  : {
                      jointType: isConverging
                        ? "rectangular-symmetrical-converging-tee"
                        : "rectangular-symmetrical-diverging-tee",
                      branchJoint:
                        filled.fitting.rectRectTee === "square"
                          ? "square"
                          : "45",
                      branchShape: "rectangular",
                      mainInlet: "rectangular",
                      mainOutlet: "rectangular",
                    },
            },
            {
              angleDEG: branch2AngleDEG,
              endpoint: {
                type: "external",
                connection: branch2,
                size: sizes[branch2Index],
                flowRateLS: flowRateLSs[branch2Index],
              },
              idealSpec:
                mainSize.type === "circ"
                  ? {
                      jointType: isConverging
                        ? "circular-symmetrical-converging-tee"
                        : "circular-symmetrical-diverging-tee",
                      branchJoint:
                        filled.fitting.circCircTee === "square"
                          ? "square"
                          : "45",
                      branchShape: "circular",
                      mainInlet: "circular",
                      mainOutlet: "circular",
                    }
                  : {
                      jointType: isConverging
                        ? "rectangular-symmetrical-converging-tee"
                        : "rectangular-symmetrical-diverging-tee",
                      branchJoint:
                        filled.fitting.rectRectTee === "square"
                          ? "square"
                          : "45",
                      branchShape: "rectangular",
                      mainInlet: "rectangular",
                      mainOutlet: "rectangular",
                    },
            },
          ],
        };
        pressureLosses.push(yPLPrimitive);

        const thisY: YPrimitive = {
          type: "y",
          branches: [E1, E2],
          main: EM,
          mainNormal: vectors[main1Idx],
          branchNormals: [vectors[branch1Index], vectors[branch2Index]],
          center: myCenter,
          style,
          material: dfgContext.material,
        };
        ductworks.push(thisY);

        switch (style) {
          case "breech": {
            // https://spiralduct.com.au/wpsda/wp-content/uploads/2021/08/8-1.jpg
            EM.coord = coord3DAdd(
              myCenter,
              vectors[main1Idx].mul(getDuctWidthMM(sizes[main1Idx]) * 1.5),
            );
            E1.coord = coord3DAdd(
              myCenter,
              vectors[branch1Index].mul(getDuctWidthMM(sizes[main1Idx]) * 1.5),
            );
            E2.coord = coord3DAdd(
              myCenter,
              vectors[branch2Index].mul(getDuctWidthMM(sizes[main1Idx]) * 1.5),
            );
            break;
          }
          case "y-piece": {
            // https://spiralduct.com.au/wpsda/wp-content/uploads/2021/08/7-4.jpg for the branches,
            // and heuristically take the max of the main taper start when positioning the main endpoint
            // Actually, nah just triangle it.
            const maxWidth = Math.max(
              getDuctWidthMM(sizes[branch1Index]),
              getDuctWidthMM(sizes[branch2Index]),
              getDuctWidthMM(sizes[main1Idx]),
            );

            E1.coord = coord3DAdd(
              myCenter,
              vectors[branch1Index].mul(maxWidth),
            );
            E2.coord = coord3DAdd(
              myCenter,
              vectors[branch2Index].mul(maxWidth),
            );
            EM.coord = coord3DAdd(myCenter, vectors[main1Idx].mul(maxWidth));
            break;
          }
          case "square": {
            const a1 = vectors[branch1Index].angleTo(vectors[main1Idx]);
            const a2 = vectors[branch2Index].angleTo(vectors[main1Idx]);
            const ab = vectors[branch1Index].angleTo(vectors[branch2Index]);
            const d1 = getDuctWidthMM(sizes[branch1Index]);
            const d2 = getDuctWidthMM(sizes[branch2Index]);
            const dm = getDuctWidthMM(sizes[main1Idx]);
            const mOff1 =
              Math.abs(Math.PI - a1) < EPS
                ? 0
                : d1 / Math.sin(Math.PI - a1) / 2 -
                  dm / Math.tan(Math.PI - a1) / 2;
            const mOff2 =
              Math.abs(Math.PI - a2) < EPS
                ? 0
                : d2 / Math.sin(Math.PI - a2) / 2 -
                  dm / Math.tan(Math.PI - a2) / 2;
            const b1Offm =
              Math.abs(Math.PI - a1) < EPS
                ? 0
                : dm / Math.sin(Math.PI - a1) / 2 -
                  d1 / Math.tan(Math.PI - a1) / 2;
            const b1Off2 =
              Math.abs(Math.PI - ab) < EPS
                ? 0
                : d2 / Math.sin(Math.PI - ab) / 2 -
                  d1 / Math.tan(Math.PI - ab) / 2;
            const b2Offm =
              Math.abs(Math.PI - a2) < EPS
                ? 0
                : dm / Math.sin(Math.PI - a2) / 2 -
                  d2 / Math.tan(Math.PI - a2) / 2;
            const b2Off1 =
              Math.abs(Math.PI - ab) < EPS
                ? 0
                : d1 / Math.sin(Math.PI - ab) / 2 -
                  d2 / Math.tan(Math.PI - ab) / 2;

            EM.coord = coord3DAdd(
              myCenter,
              vectors[main1Idx].mul(Math.max(mOff1, mOff2)),
            );

            E1.coord = coord3DAdd(
              myCenter,
              vectors[branch1Index].mul(Math.max(b1Offm, b1Off2)),
            );

            E2.coord = coord3DAdd(
              myCenter,
              vectors[branch2Index].mul(Math.max(b2Offm, b2Off1)),
            );

            break;
          }
          default:
            assertUnreachable(style);
        }

        const ySize = chooseYSize(
          sizes[main1Idx],
          sizes[branch1Index],
          sizes[branch2Index],
        );

        if (ductSizeCmp(ySize, sizes[branch1Index]) !== 0) {
          // add transition to E1
          const taperAngleDEG = filled.fitting.transitionAngle!;
          const taperWidthDiffMM = ductSizeDiff(E2.size, E1.size);
          const taperLength =
            // we need abs in the case that the smaller area circle has actually larger
            // diameter than the larger area square's side length.
            Math.abs(
              Math.sin(taperAngleDEG * (Math.PI / 180)) * taperWidthDiffMM,
            );

          // Physical duct primitives
          const I1: InternalEndpoint = {
            type: "internal",
            coord: cloneSimple(E1.coord),
            id: "I1",
            size: ySize,
          };

          ductworks.push({
            type: "transition",
            eps: [I1, E1],
            material: dfgContext.material,
          });

          thisY.branches[0] = I1;

          E1.coord = coord3DAdd(
            E1.coord,
            vectors[branch1Index].mul(taperLength),
          );

          // Pressure loss primitives
          const PLI1: InternalPLEndpoint = {
            type: "internal",
            size: ySize,
            flowRateLS: flowRateLSs[branch1Index],
            id: "I1",
          };
          const externalPLE = yPLPrimitive.branches[0].endpoint;
          yPLPrimitive.branches[0].endpoint = PLI1;

          const srcIdx = isConverging ? branch1Index : main1Idx;
          const dstIdx = isConverging ? main1Idx : branch1Index;

          const areaCmp = ductSizeAreaCmp(sizes[srcIdx], sizes[dstIdx]);
          const isAreaConverging = areaCmp > 0;

          pressureLosses.push({
            type: "transition",
            taperAngleDEG: filled.fitting.transitionAngle!,
            direction: isAreaConverging ? "converging" : "diverging",
            idealSpec: {
              direction: isAreaConverging ? "converging" : "diverging",
              inletShape:
                sizes[srcIdx].type === "circ" ? "circular" : "rectangular",
              outletShape:
                sizes[dstIdx].type === "circ" ? "circular" : "rectangular",
              taperAngleDEG: filled.fitting.transitionAngle!,
            },
            eps: [PLI1, externalPLE],
          });
        }

        if (ductSizeCmp(ySize, sizes[branch2Index]) !== 0) {
          // add transition to E2
          const taperAngleDEG = filled.fitting.transitionAngle!;
          const taperWidthDiffMM = ductSizeDiff(E1.size, E2.size);
          const taperLength = Math.abs(
            Math.sin(taperAngleDEG * (Math.PI / 180)) * taperWidthDiffMM,
          );

          // Pressure loss primitives
          const I2: InternalEndpoint = {
            type: "internal",
            coord: cloneSimple(E2.coord),
            id: "I2",
            size: ySize,
          };

          ductworks.push({
            type: "transition",
            eps: [I2, E2],
            material: dfgContext.material,
          });

          thisY.branches[1] = I2;

          E2.coord = coord3DAdd(
            E2.coord,
            vectors[branch2Index].mul(taperLength),
          );

          // Pressure loss primitives
          const PLI2: InternalPLEndpoint = {
            type: "internal",
            size: ySize,
            flowRateLS: flowRateLSs[branch2Index],
            id: "I2",
          };
          const externalPLE = yPLPrimitive.branches[1].endpoint;
          yPLPrimitive.branches[1].endpoint = PLI2;

          const srcIdx = isConverging ? branch2Index : main1Idx;
          const dstIdx = isConverging ? main1Idx : branch2Index;

          const areaCmp = ductSizeAreaCmp(sizes[srcIdx], sizes[dstIdx]);
          const isAreaConverging = areaCmp > 0;

          pressureLosses.push({
            type: "transition",
            taperAngleDEG: filled.fitting.transitionAngle!,
            direction: isAreaConverging ? "converging" : "diverging",
            idealSpec: {
              direction: isAreaConverging ? "converging" : "diverging",
              inletShape:
                sizes[srcIdx].type === "circ" ? "circular" : "rectangular",
              outletShape:
                sizes[dstIdx].type === "circ" ? "circular" : "rectangular",
              taperAngleDEG: filled.fitting.transitionAngle!,
            },
            eps: [PLI2, externalPLE],
          });
        }

        return {
          success: true,
          physical: ductworks,
          // TODO: pressure loss for symmetrical
          pressureLoss: pressureLosses,
        };
      }

      // wyes
      return {
        success: false,
        fatal: true,
        reason:
          "Could not identify the mains. Mains must be straight from each other.",
      };
    }

    let main2Idx = connections.indexOf(main2);

    // Swap so that main1 is the inlet.
    if (inlets.includes(main2)) {
      const tmp = main1;
      main1 = main2;
      main2 = tmp;
      main2Idx = connections.indexOf(main2);
      main1Idx = connections.indexOf(main1);
    }

    /* We will construct the fitting like so eg:
     * \ I1            I2/E2
     *  \         | b2| /
     *   |-------------|
     *   |    ->    Z  |
     *   |-------------|
     *  /  \ b1 \       \
     * /     \    \      \
     * E1
     *
     * Here, Z is the center of the fitting.
     * Each branch has an offset and affects the "size" of the tee. The tee's most
     * upstream point is I1 and I2, and the most downstream point is I2.
     *
     * If there are transitions on either side, they are placed on the larger side,
     * and affect the entry or exit (E1 or E2) location which would otherwise be the
     * same as I1 and I2. The pipes are connected to the E1 and E2 locations.
     */

    /*
     *
     * Actually, the tee should be like this
     *
     *   I1            I2/E2
     *            | b2|
     *   |-------------|\__
     *   |    ->    Z  | __
     *   |-------------|/
     *     \ b1 \
     *       \    \
     * E1
     *
     *
     * As in, transition is actually after the tee, not before.
     *
     */

    // I1 should be negative (if to the left) and I2 should be positive (if to the right)
    let I1: number | null = null;
    let I2: number | null = null;

    const mainTeeSize =
      ductSizeCmp(sizes[main1Idx], sizes[main2Idx]) > 0
        ? sizes[main1Idx]
        : sizes[main2Idx];

    let I1ep: PrimitiveEndpoint = {
      type: "external",
      connection: main1,
      // coord: coord3DAdd(myCenter, vectors[main1Idx].mul(-I1)),
      coord: myCenter,
      size: sizes[main1Idx],
      isDestEndpoint: true,
    };
    let I2ep: PrimitiveEndpoint = {
      type: "external",
      connection: main2,
      // coord: coord3DAdd(myCenter, vectors[main2Idx].mul(I2)),
      coord: myCenter,
      size: sizes[main2Idx],
      isDestEndpoint: true,
    };

    let E1ep = I1ep;
    let E2ep = I2ep;

    let E1dist = 0;
    let E2dist = 0;
    // 1. Let's check for transitions now so that we have the endpoints to work with.

    const mainCmp = ductSizeCmp(sizes[main1Idx], sizes[main2Idx]);

    if (mainCmp !== 0) {
      const transitionAngle = filled.fitting.transitionAngle!;

      const sizeDifference = ductSizeDiff(sizes[main1Idx], sizes[main2Idx]);
      const transitionLength =
        Math.abs(sizeDifference) / Math.tan(transitionAngle * (Math.PI / 180));

      const areaCmp = ductSizeAreaCmp(sizes[main1Idx], sizes[main2Idx]);
      const isConvergingArea = areaCmp > 0;
      if (mainCmp > 0) {
        E2dist = transitionLength;

        // Will be rewritten later
        // E1ep.coord = coord3DAdd(myCenter, vectors[main1Idx].mul(-E1));

        I2ep = {
          type: "internal",
          id: "I2",
          // coord: coord3DAdd(myCenter, vectors[main1Idx].mul(-I1)),
          coord: myCenter,
          size: sizes[main1Idx],
        };

        ductworks.push({
          type: "transition",
          eps: [I2ep, E2ep],
          material: dfgContext.material,
        });

        pressureLosses.push({
          type: "transition",
          direction: isConvergingArea ? "converging" : "diverging",
          idealSpec: {
            direction: isConvergingArea ? "converging" : "diverging",
            inletShape:
              sizes[main1Idx].type === "circ" ? "circular" : "rectangular",
            outletShape:
              sizes[main2Idx].type === "circ" ? "circular" : "rectangular",
            taperAngleDEG: filled.fitting.transitionAngle!,
          },
          eps: [
            // main2's flow rates are already negative so that's why this looks backwards
            { ...I2ep, flowRateLS: flowRateLSs[main2Idx] },
            { ...E2ep, flowRateLS: -flowRateLSs[main2Idx] },
          ],
          taperAngleDEG: transitionAngle,
        });
      } else {
        E1dist = -transitionLength;
        // E2ep.coord = coord3DAdd(myCenter, vectors[main2Idx].mul(E2));
        I1ep = {
          type: "internal",
          id: "I1",
          // coord: coord3DAdd(myCenter, vectors[main2Idx].mul(I2)),
          coord: myCenter,
          size: sizes[main2Idx],
        };

        ductworks.push({
          type: "transition",
          eps: [I1ep, E1ep],
          material: dfgContext.material,
        });
        pressureLosses.push({
          type: "transition",
          direction: isConvergingArea ? "converging" : "diverging",
          idealSpec: {
            direction: isConvergingArea ? "converging" : "diverging",
            inletShape:
              sizes[main1Idx].type === "circ" ? "circular" : "rectangular",
            outletShape:
              sizes[main2Idx].type === "circ" ? "circular" : "rectangular",
            taperAngleDEG: filled.fitting.transitionAngle!,
          },
          eps: [
            { ...I1ep, flowRateLS: -flowRateLSs[main1Idx] },
            { ...E1ep, flowRateLS: flowRateLSs[main1Idx] },
          ],
          taperAngleDEG: transitionAngle,
        });
      }
    }

    // 2. Build the takeoffs and find the I1 and I2 locations.
    for (let i = 0; i < connections.length; i++) {
      const branch = connections[i];
      if (branch === main1 || branch === main2) {
        continue;
      }

      const isConverging = flowRateLSs[i] < 0;

      const branchSize = sizes[i];
      let thisI1: number | null = null;
      let thisI2: number | null = null;
      if (branchSize.type === "circ") {
        if (mainTeeSize.type === "rect") {
          const result = rectCircTee({
            dfgContext,
            filled,
            branchIdx: i,
            main1Idx,
            main2Idx,
            mainTeeSize,
            branchSize,
            isConverging,
            main1EP: I1ep,
            main2EP: I2ep,
          });
          if (result.success === false) {
            return result;
          }
          thisI1 = result.I1;
          thisI2 = result.I2;
          pressureLosses.push(result.teePressureLoss);
          ductworks.push(result.takeoffDuctwork);
        } else {
          const result = circCircTee({
            dfgContext,
            filled,
            branchIdx: i,
            main1Idx,
            main2Idx,
            mainTeeSize,
            branchSize,
            isConverging,
            main1EP: I1ep,
            main2EP: I2ep,
          });
          if (result.success === false) {
            return result;
          }
          thisI1 = result.I1;
          thisI2 = result.I2;
          pressureLosses.push(result.teePressureLoss);
          ductworks.push(result.takeoffDuctwork);
        }
      } else {
        if (mainTeeSize.type === "circ") {
          // Normally, circular mains to rectangular branches are not supported.
          // Here we will create a circular-circular tee and then add a transition to the branch.
          const result = circCircTee({
            dfgContext,
            filled,
            branchIdx: i,
            main1Idx,
            main2Idx,
            mainTeeSize,
            branchSize: mainTeeSize,
            isConverging,
            main1EP: I1ep,
            main2EP: I2ep,
          });
          if (result.success === false) {
            return result;
          }
          thisI1 = result.I1;
          thisI2 = result.I2;
          pressureLosses.push(result.teePressureLoss);
          ductworks.push(result.takeoffDuctwork);

          const taperAngleDEG = filled.fitting.transitionAngle!;
          const taperWidthDiffMM = ductSizeDiff(sizes[main1Idx], sizes[i]);
          const taperLength = Math.abs(
            Math.sin(taperAngleDEG * (Math.PI / 180)) * taperWidthDiffMM,
          );

          // Adjust and add transition to physical prims
          const branchEP = result.takeoffDuctwork.branch;

          // The main we choose to
          const primaryMainIdx = mainCmp > 0 ? main1Idx : main2Idx;

          const I1: InternalEndpoint = {
            type: "internal",
            coord: cloneSimple(branchEP.coord),
            id: "B_I1",
            size: sizes[primaryMainIdx],
          };
          result.takeoffDuctwork.branch = I1;
          branchEP.coord = coord3DAdd(
            branchEP.coord,
            vectors[i].mul(taperLength),
          );
          ductworks.push({
            type: "transition",
            eps: [I1, branchEP],
            material: dfgContext.material,
          });

          // Adjust and add transition to pressure losses

          const plBranchEP = result.teePressureLoss.branches[0].endpoint;
          const plI1: InternalPLEndpoint = {
            type: "internal",
            id: "B_I1",
            flowRateLS: plBranchEP.flowRateLS,
            size: sizes[primaryMainIdx],
          };
          result.teePressureLoss.branches[0].endpoint = plI1;

          const srcIdx = isConverging ? i : primaryMainIdx;
          const dstIdx = isConverging ? primaryMainIdx : i;
          const areaCmp = ductSizeAreaCmp(sizes[srcIdx], sizes[dstIdx]);
          const isConvergingArea = areaCmp > 0;

          pressureLosses.push({
            type: "transition",
            direction: isConvergingArea ? "converging" : "diverging",
            idealSpec: {
              direction: isConvergingArea ? "converging" : "diverging",
              inletShape:
                sizes[srcIdx].type === "circ" ? "circular" : "rectangular",
              outletShape:
                sizes[dstIdx].type === "circ" ? "circular" : "rectangular",
              taperAngleDEG: filled.fitting.transitionAngle!,
            },
            eps: [plI1, plBranchEP],
            taperAngleDEG: filled.fitting.transitionAngle!,
          });
        } else {
          const result = rectRectTee({
            dfgContext,
            filled,
            branchIdx: i,
            main1Idx,
            main2Idx,
            mainTeeSize,
            branchSize,
            isConverging,
            main1EP: I1ep,
            main2EP: I2ep,
          });
          if (result.success === false) {
            return result;
          }
          thisI1 = result.I1;
          thisI2 = result.I2;
          pressureLosses.push(result.teePressureLoss);
          ductworks.push(result.takeoffDuctwork);
        }
      }

      if (I1 == null || thisI1! < I1) {
        I1 = thisI1;
      }
      if (I2 == null || thisI2! > I2) {
        I2 = thisI2;
      }
    }

    if (I1 === null || I2 === null) {
      console.warn("Could not find I1 or I2", filled.uid);
      return {
        success: false,
        fatal: true,
        reason:
          "Could not calculate main endpoints. The drawing may be corrupted.",
      };
    }

    I1ep.coord = coord3DAdd(myCenter, vectors[main1Idx].mul(-I1));
    I2ep.coord = coord3DAdd(myCenter, vectors[main2Idx].mul(I2));

    // If E is the same as I, then Edist is 0 so this is fine.
    E1ep.coord = coord3DAdd(myCenter, vectors[main1Idx].mul(-(I1 + E1dist)));
    E2ep.coord = coord3DAdd(myCenter, vectors[main2Idx].mul(I2 + E2dist));
    if (I1ep === E1ep && E1dist !== 0) {
      console.warn("E1 is the same as I1", filled.uid);
      throw new Error("E1 is the same as I1");
    }
    if (I2ep === E2ep && E2dist !== 0) {
      console.warn("E2 is the same as I2", filled.uid);
      throw new Error("E2 is the same as I2");
    }

    if (I1ep === E1ep) {
      I1ep.coord = cloneSimple(I2ep.coord);
      E1ep.coord = cloneSimple(I2ep.coord);
    } else if (I2ep === E2ep) {
      I2ep.coord = cloneSimple(I1ep.coord);
      E2ep.coord = cloneSimple(I1ep.coord);
    } else {
      // physical tees now need a main
    }
    ductworks.push({
      type: "conduit",
      eps: [I1ep, I2ep],
      material: dfgContext.material,
    });

    return {
      success: true,
      physical: ductworks,
      pressureLoss: pressureLosses,
    };
  }
}

function getElbowCatalogSpec(
  filled: DuctFittingEntity,
  size: DuctSize,
): DuctElbowSpec {
  switch (size.type) {
    case "circ":
      return {
        shape: "circular",
        jointType: filled.fitting.circularElbow!,
      };
    case "rect": {
      const jointType = filled.fitting.rectangularElbow!;
      switch (jointType) {
        case "smooth":
        case "square":
          return {
            shape: "rectangular",
            jointType,
          };
        case "smooth-vanes":
        case "square-vanes":
          return {
            shape: "rectangular",
            jointType,
            vanes: filled.fitting.elbowVanes!,
          };
      }
      assertUnreachable(jointType);

      // For some reason, typescript let the next o solve this without it
      throw new Error("Unreachable");
    }
  }

  assertUnreachable(size);
}
