import { RoofSegment } from "./RoofSegmentation";
import { TriangleSegment } from "./TriangleSegment";

export class TrapezoidHippingSegment implements RoofSegment {
  private shortBaseM: number;
  private longBaseM: number;
  private trapezoidHeightM: number;
  private roofHeightM: number;
  private raisedHeightM: number;

  private trianglePart: TriangleSegment;

  constructor(
    shortBaseM: number,
    longBaseM: number,
    trapezoidHeightM: number,
    roofHeightM: number,
    raisedHeightM: number,
  ) {
    this.shortBaseM = shortBaseM;
    this.longBaseM = longBaseM;
    this.trapezoidHeightM = trapezoidHeightM;
    this.roofHeightM = roofHeightM;
    this.raisedHeightM = raisedHeightM;

    this.trianglePart = new TriangleSegment(
      Math.max((this.longBaseM - this.shortBaseM) / 2, 0),
      this.trapezoidHeightM,
      this.roofHeightM,
      raisedHeightM,
    );
  }

  get areaM2(): number {
    return ((this.shortBaseM + this.longBaseM) * this.trapezoidHeightM) / 2;
  }

  get volumeM3(): number {
    return (
      this.trianglePart.volumeM3 * 2 +
      (Math.max(this.shortBaseM, 0) *
        this.trapezoidHeightM *
        this.roofHeightM) /
        2 +
      this.areaM2 * this.raisedHeightM
    );
  }

  get slopeDeg(): number {
    return (
      Math.atan(this.roofHeightM / this.trapezoidHeightM) * (180 / Math.PI)
    );
  }
}
