export type Mapped<T extends unknown[], R> = { [I in keyof T]: R };

/**
 * There's some improvement space for higher kinded type here
 */
export function tupleMap<T extends E[], E, R>(
  tuple: T,
  fn: (e: E, index: number) => R,
): Mapped<T, R> {
  return tuple.map(fn) as Mapped<T, R>;
}
