import { noop } from "lodash";
import { collect } from "../../../../lib/array-utils";
import { Units } from "../../../../lib/measurements";
import { betterObjectValues } from "../../../../lib/utils";
import { CoreContext } from "../../../calculations/types";
import { isHeating } from "../../../config";
import { FieldType } from "../field-type";
import { PropertyField } from "../property-field";
import { getF2Factor, shouldApplyCorrectionFactors } from "../utils";
import { V2RadiatorEntity } from "./plant-entity";
import { PlantFieldHandlers } from "./types";
import {
  ENCLOSURE_FACTORS_TABLE,
  FINISH_FACTORS_TABLE,
} from "./v2-radiator/correction-factors";
import {
  ConnectionMode,
  LayoutType,
  getFriendlyConnectionModeName,
  getFriendlyLayoutTypeName,
  isLayoutSameEnd,
} from "./v2-radiator/radiator-layout";

export function createV2RadConnectionsTabFields(
  context: CoreContext,
  filled: V2RadiatorEntity,
  handlers?: PlantFieldHandlers,
): PropertyField[] {
  const connectionsLayout = filled.plant.connectionsLayout;
  const drawing = context.drawing;
  const flowSystems = collect(drawing.metadata.flowSystemUidsInOrder, (uid) => {
    const fs = drawing.metadata.flowSystems[uid];
    return isHeating(fs) ? fs : null;
  });
  const currentSystemUid =
    filled.inletSystemUid === filled.plant.outletSystemUid
      ? filled.inletSystemUid
      : "(mixed)";
  const fields: PropertyField[] = [
    {
      property: "plant.connectionsLayout.connectionMode",
      title: "Connection Mode",
      type: FieldType.Choice,
      multiFieldId: "plant.connectionsLayout.connectionMode",
      isCalculated: false,
      hasDefault: false,
      params: {
        choices: betterObjectValues(ConnectionMode).map((mode) => ({
          key: mode,
          name: getFriendlyConnectionModeName(mode),
        })),
      },
    },
    {
      property: "plant.connectionsLayout.layoutType",
      title: "Connections Layout",
      type: FieldType.Choice,
      multiFieldId: "plant.connectionsLayout.layoutType",
      isCalculated: false,
      hasDefault: false,
      settingPropertyParam: {
        updateValue: handlers?.setV2RadIOLayoutType ?? noop,
      },
      params: {
        choices: betterObjectValues(LayoutType).map((layoutType) => ({
          key: layoutType,
          name: getFriendlyLayoutTypeName(layoutType),
        })),
      },
    },
    {
      property: "plant.connetionsLayout.flipPosition", // ghost property
      title: "Flip Side",
      type: FieldType.Button,
      multiFieldId: "connectionsLayout.flipPosition",
      isCalculated: false,
      hasDefault: false,
      params: {
        handler: async () => handlers?.flipRadSameEndPosition(),
      },
      isShown: isLayoutSameEnd(connectionsLayout),
    },
    {
      property: "inletHeightAboveFloorM",
      title: "Inlet Height Above Floor",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: null, max: null },
      multiFieldId: "inletHeightAboveFloorM",
      units: Units.Meters,
    },
    {
      property: "plant.outletHeightAboveFloorM",
      title: "Outlet Height Above Floor",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: null, max: null },
      multiFieldId: "outletHeightAboveFloorM",
      units: Units.Meters,
    },
    {
      property: "plant.inletoutletFS", // ghost property
      title: "Flow System of Inlet and Outlet",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.FlowSystemChoice,
      multiFieldId: "inletoutletFS",
      settingPropertyParam: {
        fetchValue: () => currentSystemUid,
        updateValue: handlers?.setV2RadInletOutletSystemUid ?? noop,
      },
      params: {
        systems: flowSystems,
      },
    },
  ];

  return fields;
}

export function createV2RadcorrectionFactorsTabFields(
  entity: V2RadiatorEntity,
): PropertyField[] {
  const shouldCorrectionFactorsBeReadonly = !shouldApplyCorrectionFactors(
    entity.plant,
  );
  const hint = shouldCorrectionFactorsBeReadonly
    ? "Correction factors are disabled for Generics radiators"
    : undefined;
  return [
    {
      property: "plant.correctionFactors.connectionsFactor", // ghost property
      title: "Pipe Connections Factor (F2)",
      type: FieldType.Number,
      multiFieldId: "plant.correctionFactors.connectionsFactor",
      isCalculated: true,
      hasDefault: false,
      readonly: true, // F2 correction factor is always derived
      settingPropertyParam: {
        fetchValue: () => getF2Factor(entity.plant),
      },
      params: {
        min: null,
        max: null,
      },
      hint,
    },
    {
      property: "plant.correctionFactors.enclosureFactor",
      title: "Enclosure Factor (F3)",
      type: FieldType.Choice,
      multiFieldId: "plant.correctionFactors.enclosureFactor",
      isCalculated: false,
      hasDefault: false,
      readonly: shouldCorrectionFactorsBeReadonly,
      params: {
        choices: Object.entries(ENCLOSURE_FACTORS_TABLE).map(
          ([value, { description }]) => ({
            key: value,
            name: `${value} (${description})`,
          }),
        ),
      },
      hint,
    },
    {
      property: "plant.correctionFactors.finishFactor",
      title: "Finish Factor (F4)",
      type: FieldType.Choice,
      multiFieldId: "plant.correctionFactors.finishFactor",
      isCalculated: false,
      hasDefault: false,
      readonly: shouldCorrectionFactorsBeReadonly,
      params: {
        choices: Object.entries(FINISH_FACTORS_TABLE).map(
          ([value, { description }]) => ({
            key: value,
            name: `${value} (${description})`,
          }),
        ),
      },
      hint,
    },
  ];
}
