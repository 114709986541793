export const ENCLOSURE_FACTORS_TABLE = {
  "1.00": {
    description: "Fixed on plain surface",
  },
  "0.95": {
    description: "Shelf over radiator",
  },
  "0.90": {
    description: "Fixed in open recess",
  },
  "0.80": {
    description: "Encased in cabinet with front or top grille",
  },
  "0.70": {
    description: "Encased in cabinet with front or top grille",
  },
} as const;

export type EnclosureFactor = keyof typeof ENCLOSURE_FACTORS_TABLE;

export const FINISH_FACTORS_TABLE = {
  "1.00": {
    description: "Oil- or water-based paint",
  },
  "0.85": {
    description: "Metallic based paint",
  },
} as const;

export type FinishFactor = keyof typeof FINISH_FACTORS_TABLE;

export interface CorrectionFactors {
  enclosureFactor: EnclosureFactor;
  finishFactor: FinishFactor;
}

export function makeDefaultCorrectionFactors(): CorrectionFactors {
  return {
    enclosureFactor: "1.00",
    finishFactor: "1.00",
  };
}
