import { GetterTree } from "vuex";
import { getCategorySeverity } from "../../lib/problems/problem-category";
import { Problem, ProblemSeverity } from "../../lib/problems/types";
import { RootState } from "../types";
import ProblemsState from "./types";

export const getters: GetterTree<ProblemsState, RootState> = {
  isOpen(state: ProblemsState) {
    return state.isProblemPanelOpen;
  },

  severity(state: ProblemsState): ProblemSeverity | null {
    if (!state.problems.length) {
      return null;
    }
    return state.problems.reduce<ProblemSeverity>((acc, problem) => {
      if (
        acc === ProblemSeverity.Error ||
        getCategorySeverity(problem.category) === ProblemSeverity.Error
      ) {
        return ProblemSeverity.Error;
      } else if (
        acc === ProblemSeverity.Warning ||
        getCategorySeverity(problem.category) === ProblemSeverity.Warning
      ) {
        return ProblemSeverity.Warning;
      }
      return acc;
    }, ProblemSeverity.Info);
  },

  all(state): Problem[] {
    return state.problems;
  },
};
