import { Primitive } from "./primitive";

/**
 * A simple implementation of a SetMultiMap.
 **
 * MultiMaps always return a value for `get` calls, even if there are no entries for a given key.
 *
 * The difference between this and `MultiMap` is that the values are stored as a Set instead of an Array.
 */
export class SetMultiMap<K extends Primitive, V> {
  static invert<K extends Primitive, V extends Primitive>(
    map: SetMultiMap<K, V>,
  ): SetMultiMap<V, K> {
    map.trim();
    const inverted = new SetMultiMap<V, K>();
    map.forEach((key, values) => {
      for (const value of values) {
        inverted.add(value, key);
      }
    });
    return inverted;
  }

  private map: Map<K, Set<V>> = new Map();

  get(key: K): Set<V> {
    const current = this.map.get(key);
    if (!current) {
      const newValue: Set<V> = new Set();
      this.map.set(key, newValue);
      return newValue;
    }
    return current;
  }

  has(key: K): boolean {
    return this.map.has(key);
  }

  forEach(fn: (key: K, value: Set<V>) => void) {
    this.map.forEach((value, key) => fn(key, value));
  }

  hasValue(key: K, value: V): boolean {
    if (!this.has(key)) {
      return false;
    }
    return this.get(key).has(value);
  }

  add(key: K, ...values: V[]): void {
    if (values.length) {
      const valueSet = this.get(key);
      for (const value of values) {
        valueSet.add(value);
      }
    }
  }

  delete(key: K): void {
    this.map.delete(key);
  }

  deleteValue(key: K, value: V): void {
    this.get(key).delete(value);
    // If the set is empty, get rid of it, there's no need to store empty sets
    if (this.map.has(key) && this.map.get(key)!.size === 0) {
      this.map.delete(key);
    }
  }

  /**
   * Removes any keys with empty values.
   *
   * This can happen if you call get without ever setting a value.
   *
   * You shouldn't need to call this, its only really needed for
   * checking equality after inverting.
   */
  trim() {
    for (const [key, valueSet] of this.map.entries()) {
      if (valueSet.size === 0) {
        this.map.delete(key);
      }
    }
  }

  /**
   * Important: This is number of entries, not the total number of values.
   */
  length(): number {
    return this.map.size;
  }

  entries(): IterableIterator<[K, Set<V>]> {
    return this.map.entries();
  }

  values(): IterableIterator<Set<V>> {
    return this.map.values();
  }

  keys(): IterableIterator<K> {
    return this.map.keys();
  }

  clear(): void {
    this.map.clear();
  }
}
