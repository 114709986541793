// in hindsight, cool drag should have been a tool. But it's not, and this
// tool is just to display the keyboard bindings on the bottom left.

import { isCoreEdgeObject } from "../../../../../common/src/api/coreObjects";
import { determineConnectableSystemUid } from "../../../../../common/src/api/coreObjects/utils";
import {
  CoolDragEntityConcrete,
  DrawableEntityConcrete,
} from "../../../../../common/src/api/document/entities/concrete-entity";
import { EntityType } from "../../../../../common/src/api/document/entities/types";
import { Coord, coordAdd, coordSub } from "../../../../../common/src/lib/coord";
import { assertUnreachable } from "../../../../../common/src/lib/utils";
import { MainEventBus } from "../../../store/main-event-bus";
import { CoolDragGrabState } from "../../layers/layer";
import {
  PolygonObjectConcrete,
  isConnectableObject,
} from "../../objects/concrete-object";
import SnappingTool from "../../tools/snapping-insert-tool";
import { KeyCode } from "../../utils";
import CanvasContext from "../canvas-context";
import { DrawingContext } from "../types";
import { generateCoolDragEntities, performCoolDrag } from "./cool-drag";

export function launchCoolDragTool({
  context,
  entities,
  world,
  subject,
  event,
}: {
  context: CanvasContext;
  entities: CoolDragEntityConcrete[];
  world: Coord;
  subject: DrawableEntityConcrete | null;
  event: MouseEvent;
}) {
  MainEventBus.$emit(
    "set-tool-handler",
    new CoolDragTool({ context, entities, world, subject, event }),
  );
}

function multiDragOrder(entity: DrawableEntityConcrete): number {
  switch (entity.type) {
    case EntityType.BACKGROUND_IMAGE:
      return 0;
    case EntityType.FIXTURE:
    case EntityType.GAS_APPLIANCE:
    case EntityType.LOAD_NODE:
    case EntityType.PLANT:
    case EntityType.FITTING:
    case EntityType.RISER:
    case EntityType.FLOW_SOURCE:
    case EntityType.DIRECTED_VALVE:
    case EntityType.MULTIWAY_VALVE:
    case EntityType.BIG_VALVE:
    case EntityType.COMPOUND:
    case EntityType.VERTEX:
      return 1;
    case EntityType.CONDUIT:
    case EntityType.EDGE:
    case EntityType.ROOM:
    case EntityType.WALL:
    case EntityType.FENESTRATION:
    case EntityType.LINE:
    case EntityType.ANNOTATION:
    case EntityType.ARCHITECTURE_ELEMENT:
    case EntityType.DAMPER:
    case EntityType.AREA_SEGMENT:
      return 2;
    case EntityType.SYSTEM_NODE:
      throw new Error("cannot handle entities of this magnitude");
  }
  assertUnreachable(entity);
}

export class CoolDragTool extends SnappingTool {
  coolDragGrabState: CoolDragGrabState;
  lastSuccessfulCoolDragWC: Coord | null = null;
  subjectOffset: Coord;

  constructor(options: {
    context: CanvasContext;
    entities: CoolDragEntityConcrete[];
    world: Coord;
    subject: DrawableEntityConcrete | null;
    event: MouseEvent;
  }) {
    const pointerWorld = options.context.viewPort.toWorldCoord({
      x: options.event.clientX,
      y: options.event.clientY,
    });

    const subjectObj =
      options.subject &&
      options.context.globalStore.getSafe(options.subject.uid);
    let subjectOffset = { x: 0, y: 0 };
    if (subjectObj && isConnectableObject(subjectObj)) {
      subjectOffset = coordSub(pointerWorld, subjectObj.toWorldCoord());
    } else if (subjectObj && isCoreEdgeObject(subjectObj)) {
      // get projection from pipe to pointer
      const endpoints = subjectObj.worldEndpoints();
      const a = endpoints[0];
      const b = endpoints[1];
      const ab = coordSub(b, a);
      const ap = coordSub(pointerWorld, a);
      const abDotAp = ab.x * ap.x + ab.y * ap.y;
      const abDotAb = ab.x * ab.x + ab.y * ab.y;
      const t = abDotAp / abDotAb;
      const projection = coordAdd(a, { x: ab.x * t, y: ab.y * t });
      subjectOffset = coordSub(pointerWorld, projection);
    }

    const toMoveEntities: CoolDragEntityConcrete[] = options.entities;

    toMoveEntities.sort((a, b) => {
      return multiDragOrder(a) - multiDragOrder(b);
    });

    const initialObjectCoords = new Map<string, Coord>();
    toMoveEntities.forEach((entity) => {
      const o = options.context.globalStore.ofTagOrThrow(
        "cool-draggable",
        entity.uid,
      );
      initialObjectCoords.set(entity.uid, o.toObjectCoord(options.world));
    });

    super({
      name: "Cool Drag",
      context: options.context,
      onFinish: (i, d) => {
        this.finish(options.context, i, d);
      },
      getTitleCallback: () => {
        return "Dragging";
      },
      getSnapIntentions: () => {
        if (!subjectObj) {
          return [];
        }
        switch (subjectObj.type) {
          case EntityType.BACKGROUND_IMAGE:
            return [];
          case EntityType.FIXTURE:
          case EntityType.GAS_APPLIANCE:
          case EntityType.PLANT:
          case EntityType.BIG_VALVE:
          case EntityType.ANNOTATION:
            return [
              {
                accept: "block",
                systemUid: null,
                offset: { x: -subjectOffset.x, y: -subjectOffset.y },
              },
            ];
          case EntityType.LOAD_NODE:
          case EntityType.FITTING:
          case EntityType.RISER:
          case EntityType.FLOW_SOURCE:
          case EntityType.DIRECTED_VALVE:
          case EntityType.MULTIWAY_VALVE:
          case EntityType.VERTEX:
            return [
              {
                accept: "point",
                systemUid:
                  determineConnectableSystemUid(
                    options.context.globalStore,
                    subjectObj.entity,
                  ) || null,
                offset: { x: 0, y: 0 }, // offset is zero because cool-drag will center these object to the mouse when dragging.
              },
            ];
          case EntityType.CONDUIT:
          case EntityType.EDGE:
          case EntityType.WALL:
          case EntityType.FENESTRATION:
          case EntityType.ROOM:
          case EntityType.LINE:
          case EntityType.ARCHITECTURE_ELEMENT:
          case EntityType.AREA_SEGMENT:
            return [
              {
                accept: "point",
                systemUid: null,
                offset: { x: 0, y: 0 },
              },
            ];
          case EntityType.SYSTEM_NODE:
          case EntityType.COMPOUND:
          case EntityType.DAMPER:
            return [];
        }
        assertUnreachable(subjectObj);
      },
      onMove: (_wc, _event) => {},
      onPointChosen: () => {},
      clickActionName: "Drag",
      keyHandlers: [
        [
          [[KeyCode.CONTROL]],
          {
            name: "Free form move",
            fn: () => {},
          },
        ],
      ],
      getInfoText: () => {
        return ["Click and drag to move objects", "Shift: free form move"];
      },
      onDraw: () => {},
      chainingObjectUids: [],
    });
    this.lastSuccessfulCoolDragWC = pointerWorld;
    this.subjectOffset = subjectOffset;

    this.coolDragGrabState = {
      initialObjectCoords,
      initialWC: options.world,
      subject: options.subject,
      entities: toMoveEntities,
    };
  }

  get config() {
    return {
      name: "cool-drag",
      defaultCursor: "Crosshair",
      focusSelectedObject: true,
      icon: "",
      modesEnabled: false,
      modesVisible: false,
      escapeVisible: false,
      text: "Drag objects around",
      tooltip: "",
      propertiesEnabled: false,
      propertiesVisible: false,
      toolbarEnabled: false,
      toolbarVisible: false,
    };
  }
  onMouseDown(_event: MouseEvent, _context: CanvasContext) {
    // This should have already happened.

    return false;
  }

  onMouseMove(event: MouseEvent, context: CanvasContext) {
    // We need to revert here so that the snap detection works on existing entities
    // before they are potentially deleted or modified by cool drag.
    context.$store.dispatch("document/revert", false);

    const { uidMoves, wobblyConnectables } = generateCoolDragEntities(
      this.context,
      this.coolDragGrabState.entities,
      this.coolDragGrabState.subject,
      event,
    );

    const dontSnapToUids: string[] = [];
    for (const uid of Object.keys(uidMoves)) {
      const o = this.context.globalStore.getOrThrow(uid);
      dontSnapToUids.push(o.uid);
      if (isConnectableObject(o)) {
        dontSnapToUids.push(...this.context.globalStore.getConnections(o.uid));
      }
    }

    this.setDontSnapToUids(dontSnapToUids);

    // Setting base snap hovers resets the snap hovers, so we should do it only
    // if the base snap hovers have changed.
    if (
      wobblyConnectables.sort().toString() !==
      this.baseSnapHovers.sort().toString()
    ) {
      this.setBaseSnapHovers(wobblyConnectables);
    }

    super.onMouseMove(event, context);

    this.context.isLayerDragging = true;

    this.context.$store.dispatch("document/revert", false);

    // The wc is the snapped position of the pointer. We need to
    // move the actual object into that place instead.
    const mouseWC = context.viewPort.toWorldCoord({
      x: event.clientX,
      y: event.clientY,
    });

    const snapWC = this.snapWorldCoord(mouseWC);

    const world = coordAdd(snapWC, this.subjectOffset);

    let result = performCoolDrag({
      context: this.context,
      entities: this.coolDragGrabState.entities,
      fromWc: this.coolDragGrabState.initialWC,
      toWc: world,
      subject: this.coolDragGrabState.subject,
      event,
    });
    let overlap = false;
    for (let i = 0; i < this.coolDragGrabState.entities.length; i++) {
      if (overlap) break;
      if (this.coolDragGrabState.entities[i].type === EntityType.VERTEX) {
        const polygonUids = this.context.globalStore.getPolygonsByVertex(
          this.coolDragGrabState.entities[i].uid,
        );
        const polygons = polygonUids.map((uid) =>
          this.context.globalStore.get<PolygonObjectConcrete>(uid),
        );
        polygons.forEach((polygon) => {
          if (polygon && !polygon.isValid()) {
            overlap = true;
          }
        });
      }
    }
    if (overlap) {
      result = false;
    }
    if (result) {
      this.lastSuccessfulCoolDragWC = world;
    } else {
      if (this.lastSuccessfulCoolDragWC) {
        context.$store.dispatch("document/revert", false);
        performCoolDrag({
          context: this.context,
          entities: this.coolDragGrabState.entities,
          fromWc: this.coolDragGrabState.initialWC,
          toWc: this.lastSuccessfulCoolDragWC,
          subject: this.coolDragGrabState.subject,
          event,
        });
      }
    }

    this.context.scheduleDraw();

    return {
      handled: true,
      cursor: "grabbing",
    };
  }

  onMouseUp(_event: MouseEvent, context: CanvasContext) {
    this.finish(context, false, false);
    return false;
  }

  onMouseScroll(_event: MouseEvent, _context: CanvasContext) {
    return false;
  }

  finish(context: CanvasContext, interrupted: boolean, displaced: boolean) {
    this.context.isLayerDragging = false;
    this.context.$store.dispatch("document/validateAndCommit");
    MainEventBus.$emit("set-tool-handler", null);
    super.finish(context, interrupted, displaced);
  }
  dispose() {}

  beforeDraw(_context: DrawingContext) {}
}
