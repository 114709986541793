import { MutationTree } from "vuex";
import { AllOrgProperties } from "../../../../common/src/api/allOrgProperties";
import OrganizationState from "./types";

export const mutations: MutationTree<OrganizationState> = {
  setProperties(state, properties: Partial<AllOrgProperties>) {
    state.properties = {
      ...state.properties,
      ...properties,
    };
  },

  clearProperties(state) {
    state.properties = {};
  },
};
