import axios, { AxiosRequestConfig } from "axios";
import { APIResult } from "../../../common/src/api/document/types";
import { handleApiError } from "./sentry-api-error";

export class ApiClient {
  /**
   * @param basePath The base path of this api client
   */
  constructor(readonly basePath: string) {}

  get<T>(url?: string, config?: AxiosRequestConfig<T>): Promise<APIResult<T>> {
    const fullUrl = this.buildUrl(url);
    return axios
      .get<APIResult<T>>(fullUrl, config)
      .then((x) => x.data)
      .catch((e) => handleApiError(`GET Request Failed: ${url}`, e));
  }

  delete<T>(
    url?: string,
    config?: AxiosRequestConfig<T>,
  ): Promise<APIResult<T>> {
    const fullUrl = this.buildUrl(url);
    return axios
      .delete<APIResult<T>>(fullUrl, config)
      .then((x) => x.data)
      .catch((e) => handleApiError(`DELETE Request Failed: ${url}`, e));
  }

  post<T, D extends Record<string, any>>(
    url?: string,
    body?: D,
    config?: AxiosRequestConfig<T>,
  ): Promise<APIResult<T>> {
    const fullUrl = this.buildUrl(url);
    return axios
      .post<APIResult<T>>(fullUrl, body, config)
      .then((x) => x.data)
      .catch((e) => handleApiError(`POST Request Failed: ${url}`, e, {}, body));
  }

  put<T, D extends Record<string, any>>(
    url?: string,
    body?: D,
    config?: AxiosRequestConfig<T>,
  ): Promise<APIResult<T>> {
    const fullUrl = this.buildUrl(url);
    return axios
      .put<APIResult<T>>(fullUrl, body, config)
      .then((x) => x.data)
      .catch((e) => handleApiError(`PUT Request Failed: ${url}`, e, {}, body));
  }

  private buildUrl(path?: string): string {
    return `${this.basePath}/${path ?? ""}`;
  }
}
