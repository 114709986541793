import CoreBaseBackedObject from "../../../../../common/src/api/coreObjects/lib/coreBaseBackedObject";
import {
  CoolDragEntityConcrete,
  DrawableEntityConcrete,
} from "../../../../../common/src/api/document/entities/concrete-entity";
import { Coord } from "../../../../../common/src/lib/coord";
import CanvasContext from "../../../../src/htmlcanvas/lib/canvas-context";
import {
  DraggableObject,
  IDraggableObject,
} from "../../../../src/htmlcanvas/lib/object-traits/draggable-object";
import { DrawableObjectConcrete } from "../../objects/concrete-object";
import { MoveIntent } from "../black-magic/cool-drag";
import { IDrawableObject } from "./core2drawable";

export interface ICoolDraggableObject extends IDraggableObject {
  getCoolDragCorrelations(
    _myMove: MoveIntent,
    _from?: DrawableObjectConcrete | undefined,
  ): { object: DrawableObjectConcrete; move: MoveIntent }[];
}

export default function CoolDraggableObject<
  // Use WITH @DraggableObject to mark entities that are part of the cool drag family.
  // I extends DrawableEntityConcrete,
  T extends abstract new (
    ...args: any[]
  ) => // Should be CoolDragEntityConcrete instead of DrawableEntityConcrete, but that causes explosion of
  // unions for some types.
  CoreBaseBackedObject<DrawableEntityConcrete> & IDrawableObject,
>(Base: T) {
  const DraggableBase = DraggableObject(Base);
  abstract class Generated
    extends DraggableBase
    implements ICoolDraggableObject
  {
    toDelete: DrawableObjectConcrete | null = null;

    // @ts-ignore
    override get tags() {
      const tags = super.tags;
      tags.add("cool-draggable");
      return tags;
    }

    onDrag(
      _event: MouseEvent,
      _grabbedObjectCoord: Coord,
      _eventObjectCoord: Coord,
      _grabState: any,
      _context: CanvasContext,
      _isMulti: boolean,
    ): void {
      // throw new Error("Should not be here");
      // // Shoud not be happening. Cool drag events gets controlled by the layer.
      // return context.activeLayer.onCoolDrag(
      //   event,
      //   this.toWorldCoord(eventObjectCoord),
      //   context
      // );
    }

    onDragFinish(_event: MouseEvent, _context: CanvasContext): void {
      // throw new Error("Should not be here");
      // return context.activeLayer.onCoolDragFinish(event, context);
    }

    onDragStart(
      event: MouseEvent,
      objectCoord: Coord,
      context: CanvasContext,
      _isMultiDrag: boolean,
    ): any {
      this.grabbedObjectCoord = null;

      // Remember that this function is called for single drag.
      return context.activeLayer.onCoolDragStart(
        event,
        this.toWorldCoord(objectCoord),
        context,
        [this.entity as CoolDragEntityConcrete],
        this.entity as CoolDragEntityConcrete,
      );
    }

    // This one default as well to no extra correlations for most
    // entities.
    getCoolDragCorrelations(
      _myMove: MoveIntent,
      _from?: DrawableObjectConcrete | undefined,
    ): { object: DrawableObjectConcrete; move: MoveIntent }[] {
      return [];
    }
  }

  return Generated;
}
