import { MutationTree } from "vuex";
import { SubscriptionSummary } from "../../../../common/src/api/subscriptions/subscription";
import { SubscriptionState } from "./types";

export const mutations: MutationTree<SubscriptionState> = {
  setSubscriptionSummary(state, summary: SubscriptionSummary) {
    state.activeSeats = summary.activeSeats;
    state.autodeskSeats = summary.autodeskSeats;
    state.details = summary.details;
    state.isLoaded = true;
  },
};
