import { kpa2bar, ls2m3h } from "../../../lib/measurements";
import CalculationEngine from "../../calculations/calculation-engine";
import { CalculatableEntityConcrete } from "../entities/concrete-entity";
import {
  BalancingValveEntity,
  LSVEntity,
  isBalancingValveEntity,
  isLSVEntity,
} from "../entities/directed-valves/directed-valve-entity";
import {
  V2RadiatorEntity,
  isV2RadiatorEntity,
} from "../entities/plants/plant-entity";
import { EntityType } from "../entities/types";

type BalancingEntityConcrete =
  | BalancingValveEntity
  | LSVEntity
  | V2RadiatorEntity;

export function isBalancingEntity(
  entity: CalculatableEntityConcrete,
): entity is BalancingEntityConcrete {
  return (
    isV2RadiatorEntity(entity) ||
    isLSVEntity(entity) ||
    isBalancingValveEntity(entity)
  );
}

export function setBalancingPressureDropKPA(
  context: CalculationEngine,
  entity: BalancingEntityConcrete,
  pressureDropKPA: number,
) {
  const getKVValue = (flowLS: number) => {
    const bar = kpa2bar(pressureDropKPA);
    return ls2m3h(flowLS) * Math.sqrt(1 / bar);
  };

  switch (entity.type) {
    case EntityType.DIRECTED_VALVE: {
      const vCalc = context.globalStore.getOrCreateCalculation(entity);

      vCalc.pressureDropKPA = pressureDropKPA;

      const conns = context.globalStore.getConnections(entity.uid);
      const pipeD = context.getCalcByPipeId(conns[0]);
      if (!pipeD) {
        throw new Error("non-pipe conduit in return graph " + conns[0]);
      }

      vCalc.kvValue = getKVValue(pipeD.pCalc.totalPeakFlowRateLS!);
      break;
    }
    case EntityType.PLANT:
      const vCalc = context.globalStore.getOrCreateCalculation(entity);
      const inletUid = entity.inletUid!;
      const conns = context.globalStore.getConnections(inletUid);
      const pipeD = context.getCalcByPipeId(conns[0]);
      if (!pipeD) {
        throw new Error("non-pipe conduit in return graph " + conns[0]);
      }

      vCalc.lsvCalcs.pressureDropKPA = pressureDropKPA;
      vCalc.lsvCalcs.kvValue = getKVValue(pipeD.pCalc.totalPeakFlowRateLS!);
  }
}
