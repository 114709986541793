import { collect } from "../array-utils";
import { assertType } from "../utils";
import {
  AggregatedValidationResult,
  Failure,
  ValidationResult,
} from "./validation-result";

const EMAIL_REGEX =
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+[^<>()\.,;:\s@\"]{2,})$/;

export function validateEmail(email: string): ValidationResult {
  return validate(EMAIL_REGEX.test(email), "Email is invalid");
}

export function validateString(
  min?: number,
  max?: number,
): (s: string) => ValidationResult {
  return (s: string) => {
    if (min && s.length < min) {
      return { success: false, message: `Must be at least ${min} characters` };
    }
    if (max && s.length > max) {
      return {
        success: false,
        message: `Must be less than or equal to ${max} characters`,
      };
    }
    return { success: true };
  };
}

export function aggregate(
  results: ValidationResult[],
): AggregatedValidationResult {
  const allSuccess = results.every((x) => x.success);
  if (allSuccess) {
    return {
      success: true,
    };
  }

  return {
    success: false,
    message: collect(results, (r) => {
      if (r.success) {
        return undefined;
      }
      assertType<Failure>(r);
      return r.message;
    }),
  };
}

export function validate(
  valid: boolean,
  invalidMessage: string,
): ValidationResult {
  if (valid) {
    return { success: true };
  } else {
    return { success: false, message: invalidMessage };
  }
}
