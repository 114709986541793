import {
  convertMeasurementSystemNonNull,
  Units,
  UnitsContext,
} from "../../../lib/measurements";
import { HeatLoadItem } from "../../catalog/heatload/types";
import { UnitsParameters } from "../../document/drawing";
import { RoomEntity } from "../../document/entities/rooms/room-entity";
import { CalculateRoofComponentConcrete } from "./roof-calculation/roof-types";

export enum HeatLoadComponentType {
  INTERNAL_WALL = "Internal Wall",
  EXTERNAL_WALL = "External Wall",
  PARTY_WALL = "Party Wall",
  INTERNAL_DOOR = "Internal Door",
  EXTERNAL_DOOR = "External Door",
  CEILING = "Ceiling",
  ROOF = "Roof",
  FLOOR = "Floor",
  WINDOW = "Window",
  VENTILATION = "Ventilation",
  THERMAL_BRIDGING = "ThermalBridging",
  INTERNAL_SOURCES = "InternalSources",
  SOLAR_GAIN = "SolarGain",
  SPARE_LOSS = "SpareLoss",
  SPARE_GAIN = "SpareGain",
}
export const HEATLOAD_COMPONENT_TYPE_SET: HeatLoadComponentType[] =
  Object.values(HeatLoadComponentType);

export const HEATLOAD_MATERIAL_TYPE_SET: HeatLoadComponentType[] = [
  HeatLoadComponentType.INTERNAL_WALL,
  HeatLoadComponentType.EXTERNAL_WALL,
  HeatLoadComponentType.PARTY_WALL,
  HeatLoadComponentType.INTERNAL_DOOR,
  HeatLoadComponentType.EXTERNAL_DOOR,
  HeatLoadComponentType.CEILING,
  HeatLoadComponentType.ROOF,
  HeatLoadComponentType.FLOOR,
  HeatLoadComponentType.WINDOW,
];

export const HEATLOSS_TYPE_SET: HeatLoadComponentType[] = [
  HeatLoadComponentType.INTERNAL_WALL,
  HeatLoadComponentType.EXTERNAL_WALL,
  HeatLoadComponentType.PARTY_WALL,
  HeatLoadComponentType.INTERNAL_DOOR,
  HeatLoadComponentType.EXTERNAL_DOOR,
  HeatLoadComponentType.CEILING,
  HeatLoadComponentType.ROOF,
  HeatLoadComponentType.FLOOR,
  HeatLoadComponentType.WINDOW,
  HeatLoadComponentType.VENTILATION,
  HeatLoadComponentType.THERMAL_BRIDGING,
  HeatLoadComponentType.SPARE_LOSS,
];

export const HEATGAIN_TYPE_SET: HeatLoadComponentType[] = [
  ...HEATLOSS_TYPE_SET,
  HeatLoadComponentType.INTERNAL_SOURCES,
  HeatLoadComponentType.SOLAR_GAIN,
  HeatLoadComponentType.SPARE_GAIN,
];

export const HEATLOAD_OTHER_TYPE_SET: HeatLoadComponentType[] = [
  HeatLoadComponentType.VENTILATION,
  HeatLoadComponentType.THERMAL_BRIDGING,
  HeatLoadComponentType.INTERNAL_SOURCES,
  HeatLoadComponentType.SOLAR_GAIN,
];

/**
 * Used inside Room calculation
 */
export interface HeatLoadComponentCalculationBase {
  headLoadType: HeatLoadComponentType;
  heatLossThroughComponentWatt: number;
  heatGainThroughComponentWatt: number;
}

export interface HeatLoadComponentCalculationWithArea
  extends HeatLoadComponentCalculationBase {
  headLoadType:
    | HeatLoadComponentType.CEILING
    | HeatLoadComponentType.INTERNAL_WALL
    | HeatLoadComponentType.EXTERNAL_WALL
    | HeatLoadComponentType.PARTY_WALL
    | HeatLoadComponentType.ROOF
    | HeatLoadComponentType.FLOOR
    | HeatLoadComponentType.INTERNAL_DOOR
    | HeatLoadComponentType.EXTERNAL_DOOR
    | HeatLoadComponentType.WINDOW;
  areaM2: number;
}

export type RoomHeatLoadResult = {
  [HeatLoadComponentType.INTERNAL_WALL]: HeatLoadComponentCalculationWithArea;
  [HeatLoadComponentType.EXTERNAL_WALL]: HeatLoadComponentCalculationWithArea;
  [HeatLoadComponentType.PARTY_WALL]: HeatLoadComponentCalculationWithArea;
  [HeatLoadComponentType.CEILING]: HeatLoadComponentCalculationWithArea;
  [HeatLoadComponentType.ROOF]: HeatLoadComponentCalculationWithArea;
  [HeatLoadComponentType.FLOOR]: HeatLoadComponentCalculationWithArea;
  [HeatLoadComponentType.INTERNAL_DOOR]: HeatLoadComponentCalculationWithArea;
  [HeatLoadComponentType.EXTERNAL_DOOR]: HeatLoadComponentCalculationWithArea;
  [HeatLoadComponentType.WINDOW]: HeatLoadComponentCalculationWithArea;
  [HeatLoadComponentType.VENTILATION]: HeatLoadComponentCalculationBase;
  [HeatLoadComponentType.THERMAL_BRIDGING]: HeatLoadComponentCalculationBase;
  [HeatLoadComponentType.INTERNAL_SOURCES]: HeatLoadComponentCalculationBase;
  [HeatLoadComponentType.SOLAR_GAIN]: HeatLoadComponentCalculationBase;
  [HeatLoadComponentType.SPARE_LOSS]: HeatLoadComponentCalculationBase;
  [HeatLoadComponentType.SPARE_GAIN]: HeatLoadComponentCalculationBase;
};

export type RoofHeatLoadResult = {
  [HeatLoadComponentType.EXTERNAL_WALL]: HeatLoadComponentCalculationWithArea;
  [HeatLoadComponentType.ROOF]: HeatLoadComponentCalculationWithArea;
  [HeatLoadComponentType.WINDOW]: HeatLoadComponentCalculationWithArea;
};

/**
 * Used for fenestration, wall
 * heat load entity with own calculation
 */
export interface EntityHeatLoadCalculation {
  heatLossThroughComponentWatt: number | null;
  heatGainThroughComponentWatt: number | null;
  uValueW_M2K: number | null;
  areaM2: number | null;
  lengthM: number | null;
  heightM: number | null;

  internalTemperatureC: number | null;
  externalWinterTemperatureC: number | null;
  externalSummerTemperatureC: number | null;
}

export type GlobalHeatLoadInfo = {
  heatLossWatt: number;
  heatGainWatt: number;
  areaM2: number;
  volumeM3: number;
};

export type HeatEmitterSpecType = "RAD" | "UFH" | "AHU" | "FCU";

export type HeatEmitterSpecs = {
  uid: string;
  name: string;
  width: string;
  height: string;
  type: HeatEmitterSpecType;
  returnAverage: string;
  returnDelta: string;
  heatingRating: string;
  flowRate: string;
  fiftyDelta: string;
  demandMet: string;
  outletUid: string;
  loopId: string | undefined;
  heatingAreaM2: number | null;

  // Only for internal calculations
  _heatingRatingWatt: number | null;
  _flowRateLS: number | null;
  _fiftyDeltaWatt: number | null;
};
export interface RoomHeatLoadCalculation {
  volumeM3: number | null;
  areaM2: number | null;
  perimeterM: number | null;
  totalHeatLossWatt: number | null;
  totalHeatGainWatt: number | null;

  totalHeatLossAddressedWATT: number | null;
  totalHeatGainAddressedWATT: number | null;

  heatLoadResult: RoomHeatLoadResult;
  ventAirChangeRatePerHour: number | null;
  ventilationFlowRateLS: number | null;
  heatingAirChangeRatePerHour: number | null;
  heatingFlowRateLS: number | null;
  thermalBridgingCoefficient: number | null;
  materials: {
    [key in HeatLoadItem]: string[];
  };
  roomsBelow: {
    [key: string]: RoomEntity;
  };
  roomsAbove: {
    [key: string]: RoomEntity;
  };
  roomsAdjacent: {
    [key: string]: RoomEntity;
  };

  floorHeadLoadInfo: GlobalHeatLoadInfo | null;
  buildingHeatLoadInfo: GlobalHeatLoadInfo | null;
  heatEmittersStats: {
    [key: string]: HeatEmitterSpecs[];
  };
}

export interface RoofHeatLoadCalculation {
  areaM2: number | null;
  roofAreaM2: number | null;
  externalWallAreaM2: number | null;
  windowAreaM2: number;
  volumeM3: number | null;

  roofComponents: CalculateRoofComponentConcrete[];
  roofHeatLoad: RoofHeatLoadResult;
}

export interface EnergyPerformanceCertificateInformation {
  isDraft: boolean;
  epcNumber: string;
}

export enum RenewableSystemType {
  HEATING_AND_HOT_WATER = "Heating and Hot Water",
  HEATING_ONLY = "Heating Only",
  HOT_WATER_ONLY = "Hot Water Only",
  HYBRID_CURRENT_BOILER = "Hybrid - Using Current Boiler",
  HYBRID_NEW_BOILER = "Hybrid - Using New Boiler",
}

export enum HeatingSystemType {
  ELECTRIC = "Electric",
  GAS = "Gas",
  LPG = "LPG",
  OIL = "Oil",
  AIR_SOURCE_HEAT_PUMP = "Air Source Heat Pump",
  GROUND_SOURCE_HEAT_PUMP = "Ground Source Heat Pump",
  SMOKELESS_FUEL = "Smokeless Fuel",
  COAL = "Coal",
  SEASONED_WOOD = "Seasoned Wood",
  PELLETS = "Pellets",
}

export enum HeatingSystemAgeType {
  PRE_1994 = "Pre-1994",
  "1994_2007" = "1994-2007",
  POST_2007 = "Post-2007",
  NEW_BOILER = "New boiler",
}

export interface ExistingHeatingSystem {
  fuelHeatingSystemType: HeatingSystemType;
  hotWaterHeatingSystemType: HeatingSystemType;
  heatingSystemAge: HeatingSystemAgeType;
}

export function createEntryWithUnit(
  units: UnitsParameters,
  value: number | null,
  unit: Units,
  precision: number | null = 0,
  unitContext: UnitsContext = UnitsContext.NONE,
  nullValuePlaceholder?: string,
): string {
  if (precision === null) precision = 2;
  let convert = convertMeasurementSystemNonNull(
    units,
    unit,
    value || 0,
    unitContext,
  );

  if (value === null) {
    return nullValuePlaceholder
      ? `${nullValuePlaceholder} ${convert[0]}`
      : `${convert[0]}`;
  }
  return `${Number(convert[1]).toFixed(precision)} ${convert[0]}`;
}
