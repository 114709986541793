export const v2RadiatorCalcUid = (radiatorUid: string) =>
  radiatorUid + ".calculation";

export const v2RadiatorSystemNodeCalcUid = (systemNodeUid: string) =>
  systemNodeUid + ".calculation";

export const v2RadiatorFittingUid = (args: {
  systemNodeUid: string;
  fittingIndex: number;
}) => `${args.systemNodeUid}.fittings.${args.fittingIndex}`;

export const v2RadiatorPipeUid = (args: {
  systemNodeUid: string;
  pipeIndex: number;
}) => `${args.systemNodeUid}.pipes.${args.pipeIndex}`;
