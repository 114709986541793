/* Routing constants */
export const GAP_BETWEEN_PIPES_MM = 50;

/**
 * The minimum distance between the left/right border of the radiator and
 * any routed pipes.
 */
export const GAP_BETWEEN_SIDE_AND_PIPE_MM = 80;

/**
 * When the distance between y coordinate of the fitting and the top/bottom border
 * of the radiator is below this threshold, a horizontal pipe will be drawn from
 * the fitting. Above this threshold, a vertical pipe will be drawn.
 *
 * The threshold needs to be sufficiently large for two horizontal pipes to fit.
 */
export const HORIZONTAL_PIPE_FROM_WING_THRESHOLD_MM =
  2 * GAP_BETWEEN_PIPES_MM + 10;
