import { CoreObjectConcrete } from "../../../../common/src/api/coreObjects";
import { CoreTagMap } from "../../../../common/src/api/coreObjects/lib/coreTagMap";
import {
  CalculationForEntity,
  LiveCalculationForEntity,
} from "../../../../common/src/api/document/calculations-objects/calculation-concrete";
import { CalculatableEntityConcrete } from "../../../../common/src/api/document/entities/concrete-entity";
import { EntityType } from "../../../../common/src/api/document/entities/types";
import { GlobalStore } from "../../../../common/src/lib/globalstore/global-store";
import store from "../../store/store";
import { DrawableObjectConcrete } from "../objects/concrete-object";
import { DrawableEntityTypeMap } from "../objects/drawable-entity-map";
import { DrawableTag, DrawableTagMap } from "./drawable-tag-map";

export default class DrawableStore extends GlobalStore {
  searchObj<T = DrawableObjectConcrete>(prefix: string): T[];
  searchObj<T>(prefix: string): T[] {
    return super.searchObj(prefix);
  }

  /**
   * @deprecated prefer safer alternatives:
   *
   * getSafe: If you need to get a generic CoreObject
   * getObjectOfType: If you know what type of CoreObject you're looking for
   * getObjectOfTypeOrThrow: If you know the CoreObject and it is guaranteed to exist in the store.
   * Check out other getXOfType methods for things like Calculatables and Connectables.
   */
  get<T = DrawableObjectConcrete>(uid: string): T {
    return super.get(uid) as T;
  }

  getSafe(uid: string): DrawableObjectConcrete | undefined {
    return super.getSafe(uid) as DrawableObjectConcrete | undefined;
  }

  getOrThrow(key: string): DrawableObjectConcrete {
    return super.getOrThrow(key) as DrawableObjectConcrete;
  }

  getObjectOfType<T extends EntityType>(
    type: T,
    uid: string,
  ): DrawableEntityTypeMap[T] | null {
    return super.getObjectOfType(type, uid) as DrawableEntityTypeMap[T] | null;
  }

  getObjectOfTypeOrThrow<T extends EntityType>(
    type: T,
    uid: string,
  ): DrawableEntityTypeMap[T] {
    return super.getObjectOfTypeOrThrow(type, uid) as DrawableEntityTypeMap[T];
  }

  hasTag<T extends DrawableTag>(
    tag: T,
    obj: { uid: string },
    // @ts-ignore
  ): obj is DrawableTagMap[T] {
    return super.hasTag(tag, obj) as any;
  }

  hasAnyTag<T extends DrawableTag>(
    obj: DrawableObjectConcrete,
    ...tags: T[]
    // @ts-ignore
  ): boolean {
    return tags.some((t) => this.hasTag(t, obj));
  }

  ofTag<T extends DrawableTag>(tag: T, uid: string): DrawableTagMap[T] | null {
    return super.ofTag(tag, uid) as any;
  }

  ofTagOrThrow<T extends DrawableTag>(tag: T, uid: string): DrawableTagMap[T] {
    return super.ofTagOrThrow(tag, uid) as any;
  }

  *find<T extends DrawableTag>(
    tag: T,
    pred?: (obj: DrawableTagMap[T]) => boolean,
  ): IterableIterator<DrawableTagMap[T]> {
    for (const item of super.find(
      tag,
      pred as (obj: CoreTagMap[T]) => boolean,
    ) as any) {
      yield item;
    }
  }

  forEach<T = DrawableObjectConcrete>(
    callbackfn: (value: T, key: string, map: Map<string, T>) => void,
    thisArg?: any,
  ): void;
  forEach(
    callbackfn: (
      value: CoreObjectConcrete,
      key: string,
      map: Map<string, CoreObjectConcrete>,
    ) => void,
    thisArg?: any,
  ): void {
    return super.forEach(callbackfn, thisArg);
  }

  values<T = DrawableObjectConcrete>(): IterableIterator<T>;
  values(): IterableIterator<CoreObjectConcrete> {
    return super.values();
  }

  getOrCreateLiveCalculation<T extends CalculatableEntityConcrete>(
    entity: T,
  ): LiveCalculationForEntity<T> {
    // access liveCalculationRenderCounter to trigger re-rendering variables which depends on liveCalcs
    store.getters["document/liveCalculationRenderCounter"];
    return super.getOrCreateLiveCalculation(entity);
  }

  getLiveCalculation<T extends CalculatableEntityConcrete>(
    entity: T,
  ): LiveCalculationForEntity<T> | undefined {
    // access liveCalculationRenderCounter to trigger re-rendering variables which depends on liveCalcs
    store.getters["document/liveCalculationRenderCounter"];
    return super.getLiveCalculation(entity);
  }

  getOrCreateCalculation<T extends CalculatableEntityConcrete>(
    entity: T,
  ): CalculationForEntity<T> {
    // access liveCalculationRenderCounter to trigger re-rendering variables which depends on liveCalcs
    store.getters["document/liveCalculationRenderCounter"];
    return super.getOrCreateCalculation(entity);
  }

  getCalculation<T extends CalculatableEntityConcrete>(
    entity: T,
  ): CalculationForEntity<T> | undefined {
    // access liveCalculationRenderCounter to trigger re-rendering variables which depends on liveCalcs
    store.getters["document/liveCalculationRenderCounter"];
    return super.getCalculation(entity);
  }
}
