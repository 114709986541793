import Flatten from "@flatten-js/core";
import { Coord, areCoordsInLine } from "../../../../lib/coord";
import {
  getAreaM2,
  polygonClipping,
} from "../../../../lib/mathUtils/mathutils";
import CoreRoom from "../../../coreObjects/coreRoom";
import { RoofEntityConcrete } from "../../../document/entities/rooms/room-entity";
import {
  breakRoofRectangleAssumption,
  breakToSlopeSectionWithRectangle,
} from "./roof-calculation";
import { PolygonSegmentAfterAdjustment } from "./roof-types";
import { calculateCentroidFlatten, rotatePolygonCW } from "./utils";

export interface RoofSegment {
  areaM2: number;
  volumeM3: number;
  slopeDeg: number;
}

export default class RoofSegmentation {
  private room: CoreRoom;
  private roofEntityConcrete: RoofEntityConcrete;
  private centroid: Flatten.Point;

  constructor(room: CoreRoom, roofEntityConcrete: RoofEntityConcrete) {
    this.room = room;
    this.roofEntityConcrete = roofEntityConcrete;
    this.centroid = calculateCentroidFlatten(this.flattenCoordCw);
  }

  get coordsCw(): Coord[] {
    return this.room.collectVerticesInOrder().map((v) => {
      return v.toWorldCoord();
    });
  }

  get flattenCoordCw(): Flatten.Point[] {
    return this.coordsCw.map((coord) => new Flatten.Point(coord.x, coord.y));
  }

  breakRoofToPolygonSegments() {
    let coordsCw: Coord[] = this.room.collectVerticesInOrder().map((v) => {
      return v.toWorldCoord();
    });

    if (coordsCw.length < 3) {
      return [];
    }

    if (areCoordsInLine(coordsCw)) {
      return [];
    }

    let coordsCwWithRotation: Coord[] = rotatePolygonCW(
      coordsCw,
      this.centroid,
      -this.roofEntityConcrete.rotation,
    );

    let rectangleShape = breakRoofRectangleAssumption(coordsCwWithRotation);
    let polygons = breakToSlopeSectionWithRectangle(
      this.roofEntityConcrete,
      rectangleShape,
    );

    let newPolygons: PolygonSegmentAfterAdjustment[] = [];
    polygons.forEach((poly) => {
      let clippedPolygons = polygonClipping(
        poly.polygonCw,
        coordsCwWithRotation,
      );

      clippedPolygons.forEach((clippedPoly) => {
        let polyAreaM2 = getAreaM2(clippedPoly);
        let areaM2 = poly.slopeAreaConversionRatio * polyAreaM2;
        let externalWallAreaM2 = poly.externalWallConversionRatio * polyAreaM2;
        newPolygons.push({
          ...poly,
          polygonCw: clippedPoly,
          roofAreaM2: areaM2,
          externalWallAreaM2: externalWallAreaM2,
          volumeM3: poly.volumeConversionRatio * polyAreaM2,
        });
      });
    });

    newPolygons.forEach((poly) => {
      poly.polygonCw = rotatePolygonCW(
        poly.polygonCw,
        this.centroid,
        this.roofEntityConcrete.rotation,
      );
      poly.slopeDirectionDegCW =
        (poly.slopeDirectionDegCW + this.roofEntityConcrete.rotation) % 360;
    });

    console.log("newPolygons 2", newPolygons);

    return newPolygons;
  }
}
