import Flatten from "@flatten-js/core";
import { coord3DDist } from "../../../../lib/coord";
import { Logger } from "../../../../lib/logger";
import { isParallelRad } from "../../../../lib/mathUtils/mathutils";
import { EPS } from "../../../../lib/utils";
import { RoomCalculation } from "../../../document/calculations-objects/room-calculation";
import { EntityType } from "../../../document/entities/types";
import { CoreContext } from "../../types";
import { CoilCoord3D } from "../../underfloor-heating/coil-coords";
import { LoopSite } from "../../underfloor-heating/underfloor-heating";
import { LoopObstacle } from "./loop-obstacles";

export interface IntentionNode {
  coord: Flatten.Point;
  next: this[];
  color?: string;
  spacingMM: number;
}

export const BACKTRAC_LOOP_SPACING_MUL = 2;

export const LOOP_WALL_SPACING_MULT = 0.75;

export interface LoopGenerationResult {
  fromManifold: CoilCoord3D[];
  toManifold: CoilCoord3D[];
  roomLoop: CoilCoord3D[];
}

export interface MissTraceResult {
  type: "miss";
  length: number;
}

export interface HitTraceResult {
  type: "hit";
  hitType: "lateral" | "head-on";
  hit: LoopObstacle;
  point: Flatten.Point;
}

export interface SoftTraceResult {
  type: "junction";
  adjacent: LoopObstacle | null;
  vector: Flatten.Vector;
  point: Flatten.Point;
}

export type TraceResult = (
  | MissTraceResult
  | HitTraceResult
  | SoftTraceResult
) & {
  length: number;
};

export type FollowWallStopReason =
  | "100 iterations run out"
  | "soft angle"
  | "hard angle"
  | "tte miss"
  | "furthest"
  | "vertical angle start";

// >0 if a is longer, <0 if b is longer, 0 if equal.
export function traceResultCmp(a: TraceResult | null, b: TraceResult | null) {
  if (a == null) {
    return b == null ? 0 : -1;
  }
  if (b == null) {
    return 1;
  }
  return a.length - b.length;
}

export const IGNORE_COLLISION_EPS = 10;

export function foreachIntention<T extends IntentionNode>(
  curr: T | null,
  callback: (from: T, to: T) => void,
) {
  const fromToPairs: [T, T][] = [];
  const listSuchPairs = (p: T | null) => {
    if (p) {
      for (const q of p.next) {
        fromToPairs.push([p, q]);
        listSuchPairs(q);
      }
    }
  };
  listSuchPairs(curr);
  for (const [from, to] of fromToPairs) {
    callback(from, to);
  }
}

interface CorrectTurnRadiiOptions {
  minRadiusMM: number;
  loopSpacingMM: number;
  avoidCenterBulb: boolean;
  expandRadiusWhenPossible?: boolean;
  flattenInitial?: number;
  correctLongKnots?: boolean;
}

type CorrectTurnRadiiCoord3D = CoilCoord3D & { type: "transit" | "loop" };

interface IntentionWalkResult {
  next: Flatten.Point;
  spacingMM: number;
  type: "forward" | "leaf" | "backtrack";
}

export class LoopGenerator {
  static getDFSPointList(
    node: IntentionNode,
    parent: IntentionNode | null,
    resultList: IntentionWalkResult[] = [],
  ) {
    // gets euler tour
    //      1
    //     / \
    //    2   3
    //   / \
    //  4   5
    // 1 2 4 2 5 2 1 3 1
    if (parent != null) {
      const vp = Flatten.vector(node.coord, parent.coord);
      if (vp.length < EPS) {
        console.warn("Zero length vector in getDFSPointList", {
          node,
          parent,
        });
      }
      node.next.sort(
        (p1, p2) =>
          -vp.angleTo(Flatten.vector(node.coord, p1.coord)) +
          vp.angleTo(Flatten.vector(node.coord, p2.coord)),
      ); // TODO: if sorted in wrong order, come back later and reverse the order. I don't know which order is correct atm.
    }
    resultList.push({
      next: node.coord,
      spacingMM: node.spacingMM,
      type: node.next.length === 0 ? "leaf" : "forward",
    });

    for (const next of node.next) {
      this.getDFSPointList(next, node, resultList);
      resultList.push({
        next: node.coord,
        spacingMM: next.spacingMM,
        type: "backtrack",
      });
    }
  }

  static getIncidentLines(intentionRoot: IntentionNode): Flatten.Line[] {
    const intentions: IntentionWalkResult[] = [];
    this.getDFSPointList(intentionRoot, null, intentions);
    const incidentLines: Flatten.Line[] = [];
    for (let i = 1; i < intentions.length; i++) {
      const prevInt = intentions[i - 1];
      const prevPoint = intentions[i - 1].next;
      const thisInt = intentions[i];
      const thisPoint = intentions[i].next;
      const v = Flatten.vector(prevPoint, thisPoint);
      const n = v
        .rotate90CCW()
        .normalize()
        .multiply(intentions[i].spacingMM / 2);
      const newLine = Flatten.line(
        prevPoint.translate(n),
        thisPoint.translate(n),
      );

      if (incidentLines.length) {
        const lastLine = incidentLines[incidentLines.length - 1];
        const angle = lastLine.norm.angleTo(newLine.norm);
        if (isParallelRad(0, angle, Math.PI * 0.05)) {
          // If parallel, we want to ease in/out the lines
          // with a 45 degree transition. Otherwise they will explode.
          if (prevInt.spacingMM > thisInt.spacingMM) {
            // diagonal inwards - cw.
            incidentLines.push(
              Flatten.line(
                prevPoint.translate(n),
                prevPoint.translate(n).translate(v.rotate(-Math.PI * 0.25)),
              ),
            );
          } else if (prevInt.spacingMM < thisInt.spacingMM) {
            // diagonal outwards.
            const startPoint = prevPoint
              .translate(n)
              .translate(
                v
                  .normalize()
                  .multiply((thisInt.spacingMM - prevInt.spacingMM) / 2),
              );
            incidentLines.push(
              Flatten.line(
                startPoint,
                startPoint.translate(v.rotate(Math.PI * 0.25)),
              ),
            );
          } else {
            // No-op needed. Later steps deduplicate this.
          }
        }
      }
      incidentLines.push(newLine);

      if (intentions[i].type === "leaf") {
        // This is a u-turn. We need to add a u-turn line.
        const spacingMM = intentions[i].spacingMM;
        const v = Flatten.vector(intentions[i - 1].next, intentions[i].next);
        const extend = v.normalize().multiply(spacingMM / 2);
        const n = v
          .rotate90CCW()
          .normalize()
          .multiply(spacingMM / 2);
        const p1 = thisPoint.translate(extend).translate(n);
        const p2 = thisPoint.translate(extend).translate(n.multiply(-1));
        incidentLines.push(Flatten.line(p1, p2));
      }
    }
    return incidentLines;
  }

  static removeZeroLengthCoils3D(loop: CoilCoord3D[]) {
    let removed = 0;
    for (let i = 0; i < loop.length - 1; i++) {
      if (coord3DDist(loop[i], loop[i + 1]) < IGNORE_COLLISION_EPS) {
        loop.splice(i, 1);
        removed++;
        i--;
      }
    }
    return removed;
  }

  static deduplicateParallelCoils(loop: CoilCoord3D[]) {
    let removed = 0;
    for (let i = 1; i < loop.length - 1; i++) {
      // This happens in practice only with the end points of the loop where
      // it connects back to the manifold, and can be ignored by skipping the whole
      // thing like this.
      if (Math.abs(loop[i].z - loop[i - 1].z) > EPS) {
        continue;
      }
      if (Math.abs(loop[i].z - loop[i + 1].z) > EPS) {
        continue;
      }
      const prevP = Flatten.point(loop[i - 1].x, loop[i - 1].y);
      const p = Flatten.point(loop[i].x, loop[i].y);
      const nextP = Flatten.point(loop[i + 1].x, loop[i + 1].y);
      const prevV = Flatten.vector(prevP, p).normalize();
      const nextV = Flatten.vector(p, nextP).normalize();

      if (isParallelRad(0, prevV.angleTo(nextV), 0.01)) {
        loop.splice(i, 1);
        removed++;
        i--;
      }
    }
    return removed;
  }

  // Must be in-place, fixes loop with turns too tight.
  // Sometimes, adds extra nodes that are not in the original loop.
  static correctTurnRadii(
    loop: Array<CorrectTurnRadiiCoord3D>,
    options: CorrectTurnRadiiOptions,
  ) {
    this.removeZeroLengthCoils3D(loop);
    this.deduplicateParallelCoils(loop);
    for (let rep = 0; rep < 10; rep++) {
      // adjusting the loops will cause more adjustments to be needed so simply repeat this a couple of times, easier to code,
      // instead of 1 shotting it.
      this.correctKnotsFull(loop, options);
      this.expandTightTurnsIteration(loop, options);
    }
  }

  static smoothOutWholeLoop(
    result: LoopGenerationResult,
    options: CorrectTurnRadiiOptions,
  ) {
    // must be in place
    const fromManifold = result.fromManifold.map((c) => ({
      ...c,
      type: "transit" as const,
    }));
    const toManifold = result.toManifold.map((c) => ({
      ...c,
      type: "transit" as const,
    }));
    const roomLoop = result.roomLoop.map((c) => ({
      ...c,
      type: "loop" as const,
    }));
    const loop: CorrectTurnRadiiCoord3D[] = [
      ...fromManifold,
      ...roomLoop,
      ...toManifold,
    ];
    this.correctTurnRadii(loop, options);
    const origLoops: CorrectTurnRadiiCoord3D[][] = [
      fromManifold,
      roomLoop,
      toManifold,
    ];
    const newLoops = [[], [], []] as CoilCoord3D[][];
    let i = 0;
    for (const part of loop) {
      for (let j = i; j < origLoops.length; j++) {
        if (origLoops[j].includes(part)) {
          i = j;
          break;
        }
      }
      newLoops[i].push(part);
    }

    return {
      fromManifold: newLoops[0],
      roomLoop: newLoops[1],
      toManifold: newLoops[2],
    };
  }

  // Returns the change in the number of loop entries
  // Knots are loops that have crossed over themselves. We just "pinch" and take them out.
  static correctKnots(loop: CoilCoord3D[], index: number): number {
    const p = loop[index];
    if (!p) {
      return 0;
    }

    const next = loop[index + 1];
    const next2 = loop[index + 2];
    const prev = loop[index - 1];
    const p_p = Flatten.point(p.x, p.y);

    if (!next) {
      return 0;
    }
    const next_p = Flatten.point(next.x, next.y);

    if (p_p.distanceTo(next_p)[0] < EPS) {
      loop.splice(index, 1);
      return -1;
    }

    if (!next2 || !prev) {
      return 0;
    }
    const prev_p = Flatten.point(prev.x, prev.y);
    const next2_p = Flatten.point(next2.x, next2.y);

    const prevSegment = Flatten.segment(prev_p, p_p);
    const nextSegment = Flatten.segment(next_p, next2_p);

    const ix = prevSegment.intersect(nextSegment);
    if (!ix || ix.length === 0) {
      return 0;
    }

    const ixPoint = ix[0];
    loop[index].x = ixPoint.x;
    loop[index].y = ixPoint.y;

    loop.splice(index + 1, 1);
    return -1;
  }

  // N^2 full pass of unexpected knots. For expected knots use correctKnots and the known
  // index of the knot.
  static correctKnotsFull(
    loop: Array<CorrectTurnRadiiCoord3D>,
    options: CorrectTurnRadiiOptions,
  ) {
    let intersection: {
      startI: number;
      endI: number;
      ix: Flatten.Point;
    } | null = null;
    let bestDistI = 10;

    for (let a = 0; a < loop.length - 1; a++) {
      const loopA = Flatten.segment(
        Flatten.point(loop[a].x, loop[a].y),
        Flatten.point(loop[a + 1].x, loop[a + 1].y),
      );
      const shouldFixKnotA =
        loop[a].type === "transit" || options.correctLongKnots;
      const shouldFixKnotB =
        loop[a + 1].type === "transit" || options.correctLongKnots;
      if (!shouldFixKnotA || !shouldFixKnotB) {
        continue;
      }

      for (let b = a + 2; b < Math.min(loop.length - 1, a + bestDistI); b++) {
        const shouldFixKnotA =
          loop[b].type === "transit" || options.correctLongKnots;
        const shouldFixKnotB =
          loop[b + 1].type === "transit" || options.correctLongKnots;
        if (!shouldFixKnotA || !shouldFixKnotB) {
          break;
        }
        const loopB = Flatten.segment(
          Flatten.point(loop[b].x, loop[b].y),
          Flatten.point(loop[b + 1].x, loop[b + 1].y),
        );
        const ix = loopA.intersect(loopB);
        if (ix && ix.length > 0) {
          const thisDist = b - a;
          if (thisDist < bestDistI) {
            // should be guaranteed but just in case
            bestDistI = thisDist;
            intersection = { startI: a, endI: b, ix: ix[0] };
          }
        }
      }
    }

    if (intersection) {
      loop[intersection.startI + 1].x = intersection.ix.x;
      loop[intersection.startI + 1].y = intersection.ix.y;
      loop.splice(
        intersection.startI + 2,
        intersection.endI - intersection.startI - 1,
      );
      return bestDistI;
    } else {
      return 0;
    }
  }

  static expandTightTurnsIteration(
    loop: CorrectTurnRadiiCoord3D[],
    options: CorrectTurnRadiiOptions,
  ) {
    const { minRadiusMM } = options;
    const expandRadiusWhenPossible = options.expandRadiusWhenPossible ?? true;

    const flattenInitial = options.flattenInitial ?? 2;

    for (let i = 1; i < loop.length - 2; i++) {
      const p = loop[i];
      const next = loop[i + 1];
      const next2 = loop[i + 2];
      const prev = loop[i - 1];

      const isTransit =
        p.type === "transit" ||
        next.type === "transit" ||
        next2.type === "transit";

      // TODO (Later), this only works for loops on a horizontal plane
      if (![next, next2].every((c) => Math.abs(c.z - p.z) < EPS)) {
        continue;
      }

      const v = Flatten.vector(next.x - p.x, next.y - p.y);
      const prevV = Flatten.vector(p.x - prev.x, p.y - prev.y);
      const nextV = Flatten.vector(next2.x - next.x, next2.y - next.y);

      if (
        Flatten.Utils.EQ_0(v.length) ||
        Flatten.Utils.EQ_0(prevV.length) ||
        Flatten.Utils.EQ_0(nextV.length)
      ) {
        // we need prior and next points and expand about the normal.
        // Hard, we have to know direction of previous segment and next segment. Ceebs.
        continue;
      }
      const angle1 = v.angleTo(prevV.multiply(-1));
      const angle2 = v.angleTo(nextV.multiply(-1));

      if (!isTransit) {
        p.turnRadiusMM = Math.max(p.turnRadiusMM, minRadiusMM);
        next.turnRadiusMM = Math.max(next.turnRadiusMM, minRadiusMM);
      }
      const lengthNeeded1 = Math.abs(p.turnRadiusMM / Math.tan(angle1 / 2));
      const lengthNeeded2 = Math.abs(next.turnRadiusMM / Math.tan(angle2 / 2));

      const ta = Math.abs(Math.tan(angle1 / 2));
      const tb = Math.abs(Math.tan(angle2 / 2));
      const neededRadius = (v.length * ta * tb) / (ta + tb);

      if (lengthNeeded1 + lengthNeeded2 > v.length) {
        if (isTransit) {
          // Just shrink the radius.
          p.turnRadiusMM = Math.min(p.turnRadiusMM, neededRadius);
          next.turnRadiusMM = Math.min(next.turnRadiusMM, neededRadius);
        }
        if (prevV.dot(nextV) > -EPS) {
          // _|‾ shaped, not appropriate for bulbing pattern. Make it
          // ___/‾‾‾ until the angles fit.

          // 1. Expand out
          const p_p = Flatten.point(p.x, p.y);
          const prev_p = Flatten.point(prev.x, prev.y);
          const next_p = Flatten.point(next.x, next.y);
          const center = p_p.translate(v.multiply(0.5));

          const prevLine = Flatten.line(prev_p, prev_p.translate(prevV));
          const nextLine = Flatten.line(next_p, next_p.translate(nextV));
          const prevProj = center.distanceTo(prevLine)[1];
          const nextProj = center.distanceTo(nextLine)[1];

          // Used as a heuristic to determine how much to push back.
          const totalDist = prevProj.length + nextProj.length;
          const pushBack = totalDist / 4;

          const newP = p_p.translate(prevV.normalize().multiply(-1 * pushBack));
          const newNext = next_p.translate(
            nextV.normalize().multiply(pushBack),
          );
          p.x = newP.x;
          p.y = newP.y;
          next.x = newNext.x;
          next.y = newNext.y;

          // 2. Correct "knots".
          this.correctKnots(loop, i + 1);
          i += this.correctKnots(loop, i - 2);

          continue;
        } else {
          // Two cases. min radiusMM is enough to make the turn, or it is not.
          if (lengthNeeded1 + lengthNeeded2 > v.length + minRadiusMM) {
            // We shouldn't make super long extensions just to make this turn. Give up.
            p.maxRadiusMM = minRadiusMM;
            next.maxRadiusMM = minRadiusMM;
            continue;
          }

          // U shaped. Bulb it out to /__\ shape.
          if (neededRadius < minRadiusMM) {
            p.maxRadiusMM = minRadiusMM;
            next.maxRadiusMM = minRadiusMM;
            const norm = v.normalize();
            const deficit = lengthNeeded1 + lengthNeeded2 - v.length;

            const shuffleShortSide = () => {
              if (Math.abs(prevV.length - nextV.length) < minRadiusMM) {
                const adjust = deficit / 2;
                p.x -= norm.x * adjust;
                p.y -= norm.y * adjust;
                next.x += norm.x * adjust;
                next.y += norm.y * adjust;
              } else if (prevV.length < nextV.length) {
                p.x -= norm.x * deficit;
                p.y -= norm.y * deficit;
              } else {
                next.x += norm.x * deficit;
                next.y += norm.y * deficit;
              }
            };

            if (
              p.counterflowCenterDist != undefined &&
              p.counterflowCenterDist <= 3
            ) {
              // Move the shorter end. That's typically the inner turn. Unless the difference is by less than a radius.
              shuffleShortSide();
            } else {
              // We are not in the center.
              if (
                prevV.length > minRadiusMM * 4 &&
                nextV.length > minRadiusMM * 4
              ) {
                // Let's bulb it out.
                const prevNorm = prevV.normalize();
                const nextNorm = nextV.normalize();
                loop.splice(i, 0, {
                  x: p.x - prevNorm.x * minRadiusMM * 3,
                  y: p.y - prevNorm.y * minRadiusMM * 3,
                  z: p.z,
                  turnRadiusMM: minRadiusMM,
                  counterflowCenterDist: p.counterflowCenterDist,
                  type: p.type,
                });

                loop.splice(i + 3, 0, {
                  x: next.x + nextNorm.x * minRadiusMM * 3,
                  y: next.y + nextNorm.y * minRadiusMM * 3,
                  z: p.z,
                  turnRadiusMM: minRadiusMM,
                  counterflowCenterDist: next.counterflowCenterDist,
                  type: next.type,
                });
              } else {
                // One of the lengths are not long enough to perform the bulb manouver. But it should
                // then be short enough to shuffle without looking bad.
                shuffleShortSide();
              }
            }
          } else {
            // We can make the turn with the needed radius.
            p.maxRadiusMM = neededRadius;
            next.maxRadiusMM = neededRadius;
          }
        }
      } else if (!isTransit) {
        // actually things can be enlarged.
        const newRadius = Math.min(neededRadius, options.loopSpacingMM);
        p.suggestedMinRadiusMM = Math.min(
          p.suggestedMinRadiusMM ?? Infinity,
          newRadius,
        );
        next.suggestedMinRadiusMM = Math.min(
          next.suggestedMinRadiusMM ?? Infinity,
          newRadius,
        );
      }
    }

    for (let i = 1; i < loop.length - 2; i++) {
      const p = loop[i];
      if (p.suggestedMinRadiusMM !== undefined && expandRadiusWhenPossible) {
        p.turnRadiusMM = Math.max(p.turnRadiusMM, p.suggestedMinRadiusMM);
      }
      if (p.maxRadiusMM !== undefined) {
        p.turnRadiusMM = Math.min(p.turnRadiusMM, p.maxRadiusMM);
      }
    }

    for (let i = 0; i < Math.min(flattenInitial, loop.length); i++) {
      loop[i].turnRadiusMM = Math.min(loop[i].turnRadiusMM, minRadiusMM);
      loop[loop.length - 1 - i].turnRadiusMM = Math.min(
        loop[loop.length - 1 - i].turnRadiusMM,
        minRadiusMM,
      );
    }
  }

  // Main functionality is to remove 0 length intentions.
  static sanitizeIntentionTree(intentionTree: IntentionNode) {
    for (let i = 0; i < intentionTree.next.length; i++) {
      const next = intentionTree.next[i];
      if (next.coord.distanceTo(intentionTree.coord)[0] < EPS) {
        const spacingMM = Math.max(next.spacingMM, intentionTree.spacingMM);
        intentionTree.next.splice(i, 1, ...next.next);
        intentionTree.spacingMM = spacingMM;
        i--;
      } else {
        this.sanitizeIntentionTree(next);
      }
    }
    return intentionTree;
  }

  static getEntranceOffsetsMM(
    obstacles: LoopObstacle[],
    center: Flatten.Point,
    normal: Flatten.Vector,
    loopSpacingMM: number,
    transitSpacingMM: number,
    print = false,
  ): {
    cw: number | null;
    ccw: number | null;
  } {
    const result: {
      cw: number | null;
      ccw: number | null;
    } = {
      cw: null,
      ccw: null,
    };

    for (const side of ["cw", "ccw"] as const) {
      const tr = this.traceToEnd({
        curr: center,
        radius: transitSpacingMM,
        nextRadius: loopSpacingMM,
        obstacles,
        direction: normal,
        hitLenienceMM: transitSpacingMM / 2,
        side,
        minLengthMM: loopSpacingMM * LOOP_WALL_SPACING_MULT,
        avoidLeafJunctions: true,
      });

      if (tr && tr.type !== "miss") {
        if (tr.type === "hit" || (tr.type === "junction" && !tr.adjacent)) {
          // we don't have to go all the way to the obstacle, just go enough to turn.
          result[side] = Math.min(
            tr.length,
            loopSpacingMM * LOOP_WALL_SPACING_MULT,
          );
        } else if (tr.type === "junction") {
          result[side] = tr.length;
        }
      }

      if (print) {
        console.log("Entrance offset", side, tr, result[side]);
      }
    }

    return result;
  }

  static followWall(
    obstacles: LoopObstacle[],
    start: Flatten.Point,
    startDirec: Flatten.Vector,
    loopSpacingMM: number,
    cutoffDirection: Flatten.Vector,
    wallSide: "cw" | "ccw",
    stopCondition: "furtherest" | "angle",
    debugObj?: RoomCalculation["debug"],
  ): {
    lengthMM: number;
    path: Flatten.Point[];
    sweepedSide: "cw" | "ccw" | null;
    sweepedLengthMM: number;
    stopReason: FollowWallStopReason;
  } {
    startDirec = startDirec.normalize();
    cutoffDirection = cutoffDirection.normalize();

    debugObj?.push({
      color: "#7777FF",
      chain: [start, start.translate(startDirec.multiply(200))],
      role: "wall-follow",
    });

    if (stopCondition === "angle") {
      if (isParallelRad(0, startDirec.angleTo(cutoffDirection), 0.01)) {
        // We are already pointing into the cutoff direction and
        return {
          lengthMM: 0,
          path: [] as Flatten.Point[],
          sweepedSide: null,
          sweepedLengthMM: 0,
          stopReason: "vertical angle start",
        };
      }
    }

    let lengthMM = 0;
    const path: Flatten.Point[] = [];

    debugObj?.push({
      color: "#FF7777",
      chain: [start, start.translate(cutoffDirection.multiply(600))],
      role: "wall-follow",
    });

    let furtherestProj = -Infinity;
    let furtherestDist = -Infinity;

    // find futherest reachable distance by this loop so we know when to stop
    let currPos = start;
    let currDirec = startDirec;

    const seen: { pos: Flatten.Point; direc: Flatten.Vector }[] = [];
    const next: { pos: Flatten.Point; direc: Flatten.Vector }[] = [
      { pos: currPos, direc: currDirec },
    ];

    let cutoffPerp = cutoffDirection.rotate90CCW();
    let sweepedSide: "cw" | "ccw" = "ccw";

    if (startDirec.dot(cutoffPerp) < 0) {
      cutoffPerp = cutoffPerp.multiply(-1);
      sweepedSide = "cw";
    }

    if (stopCondition === "furtherest") {
      let i = 0;
      while (next.length > 0 && i < 100) {
        i++;
        const { pos, direc } = next.pop()!;
        let isDuplicate = false;
        for (const { pos: seenPos, direc: seenDirec } of seen) {
          if (
            Math.abs(seenPos.x - pos.x) < IGNORE_COLLISION_EPS &&
            Math.abs(seenPos.y - pos.y) < IGNORE_COLLISION_EPS &&
            Math.abs(seenDirec.x - direc.x) < EPS &&
            Math.abs(seenDirec.y - direc.y) < EPS
          ) {
            isDuplicate = true;
          }
        }
        if (isDuplicate) continue;
        seen.push({ pos, direc });
        const result = this.traceToEnd({
          curr: pos,
          radius: loopSpacingMM!,
          obstacles,
          direction: direc,
          hitLenienceMM: loopSpacingMM! * 0.52,
          minLengthMM: loopSpacingMM * 0.5,
          side: wallSide,
        });
        if (!result) {
          continue;
        }
        const displace = Flatten.vector(start, pos);
        const thisProj = displace.dot(cutoffPerp);
        const thisDist = displace.length;
        furtherestProj = Math.max(furtherestProj, thisProj);
        furtherestDist = Math.max(furtherestDist, thisDist);
        if (result.type === "miss") {
          continue;
        } else if (result.type === "hit") {
          const seg = result.hit.segment;
          const hitVector = Flatten.vector(seg.start, seg.end).normalize();
          next.push({ pos: result.point, direc: hitVector });
          next.push({ pos: result.point, direc: hitVector.multiply(-1) });
        } else {
          next.push({ pos: result.point, direc: result.vector });
          next.push({ pos: result.point, direc: direc });
        }
      }

      if (i > 99) {
        console.warn("Infinite loop possible in wall tracing for", {
          start,
          startDirec,
          wallSide,
          furtherestDist,
          furtherestProj,

          loopSpacingMM,
        });
      }

      if (furtherestProj) {
        debugObj?.push({
          color: "#4444AA",
          chain: [start, start.translate(cutoffPerp.multiply(furtherestProj))],
          role: "wall-follow",
        });
      }
    }

    currPos = start;

    // TODO: see if this is still needed.
    // currPos = currPos.translate(startDirec.multiply(loopSpacingMM!));
    // path.push(currPos);

    currDirec = startDirec;

    let actualFurtherestProj = -Infinity;

    let stopReason: FollowWallStopReason = "100 iterations run out";

    let lastTteType: "hit" | "junction" | "miss" | null = null;
    // Limit to 1000 just to avoid infinite loops.
    for (let i = 0; i < 100; i++) {
      const result = this.traceToEnd({
        curr: currPos,
        radius: loopSpacingMM!,
        obstacles,
        direction: currDirec,
        hitLenienceMM: loopSpacingMM! * 0.52,
        minLengthMM: loopSpacingMM * 0.5,
        side: wallSide,
        atMostVerticalForJunction:
          stopCondition === "angle" ? cutoffDirection : undefined,
        maxAllowedDistanceFromSoftGuide:
          i === 0 && stopCondition === "angle"
            ? loopSpacingMM! * 3.5
            : undefined,
      });
      if (!result || result.type === "miss") {
        stopReason = lastTteType === "hit" ? "hard angle" : "soft angle";
        break;
      }

      if (stopCondition === "angle") {
        const currVector = Flatten.vector(currPos, result.point);
        const currProj = currVector.dot(cutoffPerp);
        if (currProj < 0) {
          debugObj?.push({
            color: "#777777",
            chain: [currPos, result.point],
            role: "wall-follow",
          });
          debugObj?.push({
            color: "#444444",
            chain: [currPos, currPos.translate(cutoffPerp.multiply(200))],
            role: "wall-follow",
          });
          // Going backwards compared to the cutoff direction.
          stopReason = lastTteType === "hit" ? "hard angle" : "soft angle";
          break;
        }
        const currAngle = currVector.angleTo(cutoffDirection);
        if (isParallelRad(0, currAngle, 0.01)) {
          // We are pointing into the cutoff direction.
          stopReason = lastTteType === "hit" ? "hard angle" : "soft angle";
          break;
        }
      }

      lengthMM += result.length;
      path.push(result.point);
      currPos = result.point;

      const displacement = Flatten.vector(start, currPos);
      const displacementProj = displacement.dot(cutoffPerp);
      actualFurtherestProj = Math.max(actualFurtherestProj, displacementProj);

      if (stopCondition === "furtherest") {
        // Accept the furtherest point, but prevent doubling back.

        if (displacementProj > furtherestProj - loopSpacingMM! * 2) {
          // we have reached the end of the line
          stopReason = "furthest";
          break;
        }
      }
      if (result.type === "hit") {
        // this is a good sign. follow either way
        const seg = result.hit.segment;
        let bestNextDist = -Infinity;
        let bestNextDirec: Flatten.Vector | null = null;
        for (let nextDirec of [-1, 1]) {
          const nextDirVec = Flatten.vector(seg.start, seg.end)
            .normalize()
            .multiply(nextDirec);
          const nextResult = this.traceToEnd({
            curr: result.point,
            radius: loopSpacingMM!,
            obstacles,
            direction: nextDirVec,
            hitLenienceMM: loopSpacingMM! * 0.5,
            minLengthMM: loopSpacingMM * 0.5,
          });
          if (nextResult && nextResult.type !== "miss") {
            const nextDist = nextResult.point.distanceTo(result.point)[0];
            if (nextDist > bestNextDist) {
              bestNextDirec = nextDirVec;
              bestNextDist = nextDist;
            }
          }
        }
        if (!bestNextDirec) {
          // there will not be enough space to execute a loop anyway, so this should
          // be an error. Give up.
          stopReason = "tte miss";
          // It is not tte miss for now. It will be a tte miss in the next iteration. But treated the same.
          break;
        }
        currDirec = bestNextDirec;
      } else {
        // we have to explore the 3 other directions.
        const v1 = result.vector;
        const v2 = currDirec;
        let found = false;
        for (const v of [v1, v2]) {
          const nextResult = this.traceToEnd({
            curr: result.point,
            radius: loopSpacingMM!,
            obstacles,
            direction: v,
            hitLenienceMM: loopSpacingMM! * 0.5,
            minLengthMM: loopSpacingMM * 0.5,
          });
          if (nextResult) {
            currDirec = v;
            found = true;
            break;
          }
        }

        if (!found) {
          // there will not be enough space to execute a loop anyway, so this should
          // be an error. Give up.
          stopReason = "tte miss";
          break;
        }
      }
      lastTteType = result.type;
    }

    return {
      path,
      lengthMM,
      sweepedSide,
      sweepedLengthMM: actualFurtherestProj,
      stopReason,
    };
  }

  static followWallBothSidesFromStart(
    obstacles: LoopObstacle[],
    center: Flatten.Point,
    normal: Flatten.Vector,
    loopSpacingMM: number,
    transitSpacingMM: number,
    loopDirectionDEG: number | null,
    mode: "furtherest" | "angle",
    debugObj?: RoomCalculation["debug"],
  ) {
    const startResult = this.getEntranceOffsetsMM(
      obstacles,
      center,
      normal,
      loopSpacingMM,
      transitSpacingMM,
    );

    // In our coordinate space, angle 0 is up, and angle 90 is right.
    const normalAngleRAD = normal.angleTo(Flatten.vector(0, 1));
    const directionDEG = loopDirectionDEG ?? (normalAngleRAD * 180) / Math.PI;
    const directionRAD = (directionDEG * Math.PI) / 180;
    let directionVector = Flatten.vector(
      Math.sin(directionRAD),
      -Math.cos(directionRAD),
    );

    // 1. Find closest path to either left or right-most point.
    // While we are dealing with right angles, this is just first vertical wall.

    const result: {
      path: Flatten.Point[];
      side: "cw" | "ccw";
      sweepedSide: "cw" | "ccw" | null;
      startOffsetMM: number;
      lengthMM: number;
      sweepedLengthMM: number;
      startPoint: Flatten.Point;
      stopReason: FollowWallStopReason;
    }[] = [];

    // for (let direc of ["left", "right"] as const) {
    for (const turn of ["cw", "ccw"] as const) {
      // TODO: better starting location.

      const direc = turn === "cw" ? normal.rotate90CW() : normal.rotate90CCW();

      if (startResult[turn] != null) {
        const startPoint = center.translate(
          normal.multiply(startResult[turn]!),
        );
        const wallResult = this.followWall(
          obstacles,
          startPoint,
          direc,
          loopSpacingMM!,
          directionVector,
          turn,
          mode,
          debugObj,
        );

        const path = [startPoint].concat(wallResult.path);

        result.push({
          path,
          lengthMM: wallResult.lengthMM,
          sweepedSide: wallResult.sweepedSide,
          side: turn,
          sweepedLengthMM: wallResult.sweepedLengthMM,
          startOffsetMM: startResult[turn]!,
          startPoint,
          stopReason: wallResult.stopReason,
        });
      }
    }

    return { paths: result, directionVector };
  }

  // Due to a quirk in how heated areas are presented,
  // we need this hack to make sure the entrance not squigly.
  // TODO: do heated area entrances properly and get this removed
  static heatedAreaEntranceHack(
    site: LoopSite,
    normal: Flatten.Vector,
    center: Flatten.Point,
  ) {
    if (!center || !normal) {
      return;
    }

    const type = site.entity.type === EntityType.ROOM ? "room" : "heated-area";
    if (type === "heated-area") {
      center = Flatten.point(center.x, center.y).translate(
        normal.normalize().multiply(100),
      );
    }
  }

  static findAdjacentObstacle(
    obstacles: LoopObstacle[],
    about: Flatten.Point,
    excluded: LoopObstacle[],

    // Direction we are looking at. The sweep will start at opposite of
    // direction.
    forward: Flatten.Vector,
    direction: "cw" | "ccw",
    distanceToleranceMM: number = IGNORE_COLLISION_EPS,
    minAngle: number = Math.PI / 4,
    print?: boolean,
  ): { obstacle: LoopObstacle; vector: Flatten.Vector } | null {
    const startVector = forward.multiply(-1);
    let bestAngle = Infinity;
    let bestObstacle: LoopObstacle | null = null;
    let bestVector: Flatten.Vector | null = null;
    const doWithTolerance = (tol: number, hateTransit: boolean): void => {
      for (const obs of obstacles) {
        if (excluded.includes(obs)) {
          continue;
        }
        if (obs.segment.length < 5 || (hateTransit && obs.radiusMM < 30)) {
          // < 30 means transit.
          continue;
        }

        const pA = obs.segment.start;
        const pB = obs.segment.end;
        let vector: Flatten.Vector | null = null;
        if (pA.distanceTo(about)[0] <= tol) {
          vector = Flatten.vector(pA, pB);
        }
        if (pB.distanceTo(about)[0] <= tol) {
          vector = Flatten.vector(pB, pA);
        }

        if (!vector) {
          continue;
        }

        const angle =
          direction === "ccw"
            ? startVector.angleTo(vector)
            : vector.angleTo(startVector);

        if (angle < bestAngle && angle > minAngle) {
          bestAngle = angle;
          bestObstacle = obs;
          bestVector = vector;
        }
      }
    };

    doWithTolerance(IGNORE_COLLISION_EPS, false);
    if (bestObstacle && bestVector) {
      return { obstacle: bestObstacle, vector: bestVector };
    }
    doWithTolerance(distanceToleranceMM, true);
    if (bestObstacle && bestVector) {
      return { obstacle: bestObstacle, vector: bestVector };
    }
    return null;
  }

  // UFH loop generation helper functions
  // traceToEnd moves the point forward to the next critical point, either an interesting edge
  // or until it hits an object.
  static traceToEnd(options: {
    context?: CoreContext;
    curr: Flatten.Point;
    // radius is the current segment for collision detection.
    // nextRadius is radius of follow up segment (that could be different to
    // radius) for more accurate positioning of the next point.
    radius: number;
    nextRadius?: number;

    obstacles: LoopObstacle[];
    direction: Flatten.Vector;
    onlyBlocks?: boolean;

    hitLenienceMM?: number;

    // Margin is extra space needed left or right side to avoid obstacles.
    maxLengthMM?: number;
    minLengthMM?: number;
    dontLikeHeadOnHit?: boolean;
    avoidLeafJunctions?: boolean;
    atMostVerticalForJunction?: Flatten.Vector; // The "vertical" direction
    side?: "cw" | "ccw";

    print?: boolean;
    maxAllowedDistanceFromSoftGuide?: number;
  }): TraceResult | null {
    const {
      context,
      curr,
      radius,
      obstacles,
      direction,
      maxLengthMM,
      minLengthMM: _minLengthMM,
      side,
      dontLikeHeadOnHit,

      // Avoids soft critical points going around a "leaf" intention - avoid bending around a "needle"
      avoidLeafJunctions,
      atMostVerticalForJunction,
    } = options;

    if (
      atMostVerticalForJunction !== undefined &&
      isParallelRad(direction.angleTo(atMostVerticalForJunction), 0, 0.001)
    )
      return null;

    const dirMul = side === "cw" ? -1 : side === "ccw" ? 1 : undefined;

    const nextRadius = options.nextRadius ?? radius;
    const maxAllowedDistanceFromSoftGuide =
      options.maxAllowedDistanceFromSoftGuide ??
      radius * 2 + IGNORE_COLLISION_EPS;

    let bestHit: TraceResult | null = null;
    const print = options.print ?? false;

    if (print) {
      console.log("Trace to end with debug print", {
        curr,
        radius,
        direction,
        maxLengthMM,
        minLengthMM: _minLengthMM,
        dontLikeHeadOnHit,
      });
    }

    const minLengthMM = _minLengthMM ?? radius;
    const hitLenienceMM = options.hitLenienceMM ?? IGNORE_COLLISION_EPS;

    const bestObstacle: LoopObstacle | null = null;

    const myLine = Flatten.line(curr, curr.translate(direction));

    // with marginLeft and marginRight, it is effectively a thicker, shifted
    // line for the purposes of collision detection.
    const directionCW = direction.rotate90CW().normalize();

    // on the left side, how far do we go if there's an obstacle left of us?
    let cwBest: TraceResult | null = null;
    // on the right side, how far do we go if there's an obstacle right of us?
    let ccwBest: TraceResult | null = null;

    const updateTraceResult = (
      curr: TraceResult | null,
      mode: "min" | "max",
      result: TraceResult,
      // If optional, then if shorter than min dist this one is ignored.
      optional: boolean = false,
    ) => {
      if (
        avoidLeafJunctions &&
        (curr?.type === "junction" && curr.adjacent === null) !==
          (result.type === "junction" && result.adjacent === null)
      ) {
        if (curr && result.type === "junction" && result.adjacent === null) {
          return curr;
        }
        if (curr && curr.type === "junction" && curr.adjacent === null) {
          return result;
        }
      }
      if (!curr) {
        return result;
      }
      if (mode === "min") {
        if (optional && result.length < minLengthMM) {
          return curr;
        }
        if (result.length < curr.length) {
          return result;
        }
      } else {
        if (result.length > curr.length) {
          return result;
        }
      }
      return curr;
    };

    // This is for the outOfAngleRange check,
    // which helps to ignore all obstacles that are entirely out of the adjacent angle range.
    // Imagine, an obstacle is completely behind another obstacle that doesn't disturb us,
    // then that obstacle shouldn't disturb us either.
    // adjacentAngleRange = [l, r] in radius means,
    // say the `direction` is angle 0,
    // range is from -l to r.
    let adjacentAngleRange: [number, number] | null = null;
    for (const obs of obstacles) {
      const foundAngle = (v: Flatten.Vector): void => {
        const l = v.angleTo(direction);
        const r = direction.angleTo(v);
        if (adjacentAngleRange == null) {
          adjacentAngleRange = [
            Math.min(l, Math.PI / 2),
            Math.min(r, Math.PI / 2),
          ];
        } else {
          adjacentAngleRange[0] = Math.min(adjacentAngleRange[0], l);
          adjacentAngleRange[1] = Math.min(adjacentAngleRange[1], r);
        }
      };
      if (curr.distanceTo(obs.segment)[0] < IGNORE_COLLISION_EPS) {
        if (curr.distanceTo(obs.segment.ps)[0] > IGNORE_COLLISION_EPS) {
          foundAngle(Flatten.vector(obs.segment.pe, obs.segment.ps));
        }
        if (curr.distanceTo(obs.segment.pe)[0] > IGNORE_COLLISION_EPS) {
          foundAngle(Flatten.vector(obs.segment.ps, obs.segment.pe));
        }
      }
    }

    if (
      adjacentAngleRange != null &&
      (adjacentAngleRange[0] < Math.PI * 0.05 ||
        adjacentAngleRange[1] < Math.PI * 0.05)
    ) {
      if (print) {
        console.log("Collision with parallel", {
          curr,
          adjacentAngleRange,
        });
      }
      return null;
    }

    const outOfAngleRange = (point: Flatten.Point): boolean => {
      if (adjacentAngleRange == null) return false;
      if (point.distanceTo(curr)[0] < IGNORE_COLLISION_EPS) return true;
      const v = Flatten.vector(curr, point);
      const l = v.angleTo(direction);
      const r = direction.angleTo(v);
      return l > adjacentAngleRange[0] - EPS && r > adjacentAngleRange[1] - EPS;
    };

    for (const obs of obstacles) {
      const obsA = obs.segment.vertices[0];
      const obsB = obs.segment.vertices[1];
      const obsVec = Flatten.vector(obsA, obsB);
      if (obsVec.length < EPS) {
        continue;
      }
      const angle = obsVec.angleTo(direction);
      const currToObsSeg = curr.distanceTo(obs.segment);
      if (currToObsSeg[0] < IGNORE_COLLISION_EPS) continue;

      // Find junction critical points.
      // It doesn't have to be parallel!
      if (isParallelRad(angle, 0, Math.PI * 0.02) && !options.onlyBlocks) {
        const v0 = Flatten.vector(curr, obsA);
        const v1 = Flatten.vector(curr, obsB);
        const togoA = direction.dot(v0);
        const togoB = direction.dot(v1);
        const obsLine = Flatten.line(obsA, obsB);
        const obsSeg = Flatten.segment(obsA, obsB);
        const [projDist, distSeg] = curr.distanceTo(obsLine);
        const absDist = curr.distanceTo(obsSeg)[0];
        const distVector = Flatten.vector(curr, obsB);

        const isCW = distVector.dot(directionCW) > 0;

        const about = togoA > togoB ? obsA : obsB;
        const forward =
          togoA > togoB
            ? Flatten.vector(obsB, obsA)
            : Flatten.vector(obsA, obsB);

        const adjacent = this.findAdjacentObstacle(
          obstacles,
          about,
          [obs],
          forward,
          isCW ? "cw" : "ccw",
          nextRadius * 2 - hitLenienceMM,
          Math.PI * 0.99,
          print,
        );

        if (
          projDist < radius * 2 + obs.radiusMM &&
          (togoA > -radius * 2 || togoB > -radius * 2) &&
          (togoA < maxAllowedDistanceFromSoftGuide + obs.radiusMM ||
            togoB < maxAllowedDistanceFromSoftGuide + obs.radiusMM)
        ) {
          const adjacentAngle = adjacent
            ? forward.angleTo(adjacent.vector)
            : isCW
              ? -Math.PI / 2
              : Math.PI / 2;
          const adjacentObstacle = adjacent ? adjacent.obstacle : null;
          const adjacentVector = adjacent
            ? adjacent.vector.normalize()
            : (isCW ? forward.rotate90CW() : forward.rotate90CCW()).normalize();

          const firstDist =
            nextRadius + (adjacentObstacle?.radiusMM ?? nextRadius);

          const tangentVector = forward
            .rotate(adjacentAngle)
            .rotate(isCW ? Math.PI / 2 : -Math.PI / 2)
            .normalize()
            .multiply(firstDist);
          const tangentAbout = about.translate(tangentVector);
          const tangentLine = Flatten.line(
            tangentAbout,
            tangentAbout.translate(adjacentVector),
          );

          let extendPoint = isParallelRad(
            adjacentVector.angleTo(direction),
            0,
            Math.PI * 0.05,
          )
            ? undefined
            : tangentLine.intersect(myLine)[0];
          if (!extendPoint) {
            // Lines parallel edge case. Extend just to the end of the line.
            extendPoint = curr.translate(
              direction.normalize().multiply(Math.max(0, togoA, togoB)),
            );
          }
          const togoExtend = direction.dot(Flatten.vector(curr, extendPoint));

          // This is a soft critical point so should be skipped if it results
          // in too short a distance.
          if (print) {
            console.log("Adding junction", {
              firstDist,
              extendPoint,
              tangentAbout,
              adjacentObstacle,
              togoExtend,
              adjacentVector,
              isCW,
              obs,
              togoA,
              togoB,
              projDist,
            });
          }

          let preferVertical = false;
          if (togoExtend > 0 && atMostVerticalForJunction !== undefined) {
            let shiftDir = atMostVerticalForJunction.rotate90CCW();
            if (shiftDir.dot(direction) < 0) {
              shiftDir = shiftDir.multiply(-1);
            }
            const shiftedObsPoint = about.translate(
              shiftDir.multiply(firstDist),
            );
            const intersectLine = Flatten.line(
              shiftedObsPoint,
              shiftedObsPoint.translate(atMostVerticalForJunction),
            );
            const intersect2 = myLine.intersect(intersectLine);
            if (intersect2.length === 0)
              Logger.error(
                "No intersection. The original direction was already wrong in followWall",
                {},
                {
                  curr,
                  direction,
                  myLine,
                  intersectLine,
                },
              );
            const length2 = direction.dot(Flatten.vector(curr, intersect2[0]));
            if (length2 < togoExtend) {
              preferVertical = true;
              if (isCW) {
                cwBest = updateTraceResult(cwBest, "max", {
                  type: "junction",
                  adjacent: adjacentObstacle,
                  length: length2,
                  point: intersect2[0],
                  vector: atMostVerticalForJunction.multiply(-1),
                });
              } else {
                ccwBest = updateTraceResult(ccwBest, "max", {
                  type: "junction",
                  adjacent: adjacentObstacle,
                  length: length2,
                  point: intersect2[0],
                  vector: atMostVerticalForJunction.multiply(-1),
                });
              }
            }
          }
          if (!preferVertical)
            if (isCW) {
              cwBest = updateTraceResult(cwBest, "max", {
                type: "junction",
                adjacent: adjacentObstacle,
                length: togoExtend,
                point: extendPoint,
                vector: adjacentVector,
              });
            } else {
              ccwBest = updateTraceResult(ccwBest, "max", {
                type: "junction",
                adjacent: adjacentObstacle,
                length: togoExtend,
                point: extendPoint,
                vector: adjacentVector,
              });
            }
        }

        if (togoA > IGNORE_COLLISION_EPS || togoB > IGNORE_COLLISION_EPS) {
          // check for collision with sides.
          // But give lots of leeway
          const collisionDist = (obs.radiusMM + radius) / 2;

          if (absDist < collisionDist - EPS) {
            if (print) {
              console.log("Collision with side", { curr, obsA, obsB, obs });
            }
            return null;
          }
        }
      }

      if (outOfAngleRange(obs.segment.ps) && outOfAngleRange(obs.segment.pe)) {
        // ignorable, only if not blocking in front of you.
        const pl = myLine.intersect(obs.segment);
        if (pl.length === 0) continue;
        const dot = direction.dot(Flatten.vector(curr, pl[0]));
        if (dot <= EPS) continue;
      }

      // now check for blocks
      const segLine = Flatten.line(obsA, obsB);
      if (currToObsSeg[0] < radius + obs.radiusMM - hitLenienceMM) {
        // Inside object. Reject only if the object is in front of us.
        const proj = obs.segment.distanceTo(myLine)[1];
        /*
        if (proj.length > radius + obs.radiusMM) {
          // Not really possible but anywho
          console.log({
            msg: "This never happens.#1",
            curr,
            direction,
            obsSegment: obs.segment,
            myLine,
            currDis: currToObsSeg[0],
            projLen: proj.length,
          });
          continue;
        }
        if (proj.length > radius + obs.radiusMM - hitLenienceMM) {
          // Not close enough to be considered a collision.
          console.log({
            msg: "This never happens. #2",
            curr,
            direction,
            obsSegment: obs.segment,
            myLine,
            currDis: currToObsSeg[0],
            projLen: proj.length,
          });
          continue;
        }
          */
        const togoA = direction
          .normalize()
          .dot(Flatten.vector(curr, obs.segment.start));
        const togoB = direction
          .normalize()
          .dot(Flatten.vector(curr, obs.segment.end));

        if (togoA < IGNORE_COLLISION_EPS && togoB < IGNORE_COLLISION_EPS) {
          // All behind, no collision
          continue;
        }

        // If parallel, we can't start a thing along it.
        if (isParallelRad(angle, 0, Math.PI * 0.002)) {
          if (print) {
            console.log("Collision with parallel while inside", {
              curr,
              obsA,
              obsB,
              obs,
            });
          }
          return null;
        }
        // If we are "heading into" it, it's a collision.
        const ixs = obs.segment.intersect(myLine);
        if (ixs.length > 0) {
          const ix = ixs[0];
          const togo = direction.dot(Flatten.vector(curr, ix));
          // current problem: if the intersection ix is out of the segment, we shouldn't kill it.
          if (togo > EPS) {
            if (print) {
              console.log("Collision with head-on into lateral", {
                obs,
                togo,
                dist: proj.length,
                direction,
                curr,
                ix,
                threshold: radius + obs.radiusMM - hitLenienceMM,
              });
            }
            return null;
          }
        }
      } else if (isParallelRad(angle, 0, Math.PI * 0.01)) {
        const distance = curr.distanceTo(segLine)[0];
        if (distance > radius + obs.radiusMM - hitLenienceMM) {
          continue;
        }

        const projA = obs.segment.start.distanceTo(myLine)[1];
        const projB = obs.segment.end.distanceTo(myLine)[1];

        const aVec = Flatten.vector(curr, projA.end);
        const bVec = Flatten.vector(curr, projB.end);
        const togoA = direction.normalize().dot(aVec);
        const togoB = direction.normalize().dot(bVec);
        if (
          Math.abs(togoA) <= IGNORE_COLLISION_EPS &&
          Math.abs(togoB) <= IGNORE_COLLISION_EPS
        ) {
          continue;
        } else if (togoA > IGNORE_COLLISION_EPS && togoB < 0) {
          if (print) {
            console.log("Collision with parallel - already in", {
              obs,
            });
          }
          return null;
        } else if (togoB > IGNORE_COLLISION_EPS && togoA < 0) {
          if (print) {
            console.log("Collision with parallel - already in", {
              obs,
            });
          }
          return null;
        } else if (
          togoA < IGNORE_COLLISION_EPS &&
          togoB < IGNORE_COLLISION_EPS
        ) {
          // All behind, no collision
          continue;
        } else {
          // Object is in front.
          const thisDist =
            Math.min(Math.abs(togoA), Math.abs(togoB)) -
            Math.max(
              Math.sqrt((nextRadius + obs.radiusMM) ** 2 - distance ** 2),
              (nextRadius + obs.radiusMM) / 2,
            );
          if (thisDist <= 0) {
            if (print) {
              console.log("Collision with parallel in front", { obs });
            }
            return null;
          }
          bestHit = updateTraceResult(bestHit, "min", {
            type: "hit",
            hit: obs,
            hitType: "head-on",
            point: curr.translate(direction.multiply(thisDist)),
            length: thisDist + (dontLikeHeadOnHit === true ? radius : 0),
          });
        }
      } else {
        if (
          currToObsSeg[0] <
            (radius + obs.radiusMM) * 1.2 + IGNORE_COLLISION_EPS &&
          !options.onlyBlocks &&
          dirMul !== undefined
        ) {
          const dotA = obsVec.dot(Flatten.vector(curr, obsA));
          const dotB = obsVec.dot(Flatten.vector(curr, obsB));

          if ((dotA > 0 && dotB > 0) || (dotA < 0 && dotB < 0)) {
            const [obsCloser, obsFurther] =
              Math.abs(dotA) < Math.abs(dotB) ? [obsA, obsB] : [obsB, obsA];
            const obsDir = Flatten.vector(obsCloser, obsFurther).normalize();
            const desiredNorm =
              dirMul === 1 ? obsDir.rotate90CW() : obsDir.rotate90CCW();
            const shiftedObs = Flatten.line(
              obsCloser.translate(
                desiredNorm.multiply(nextRadius + obs.radiusMM),
              ),
              obsFurther.translate(
                desiredNorm.multiply(nextRadius + obs.radiusMM),
              ),
            );
            const intersect = myLine.intersect(shiftedObs)[0];
            const length = direction.dot(Flatten.vector(curr, intersect));
            let preferVertical = false;
            if (length > 0) {
              if (atMostVerticalForJunction !== undefined) {
                let shiftDir = atMostVerticalForJunction.rotate90CCW();
                if (shiftDir.dot(direction) < 0) {
                  shiftDir = shiftDir.multiply(-1);
                }
                const shiftedObsPoint = obsCloser.translate(
                  shiftDir.multiply(nextRadius + obs.radiusMM),
                );
                const intersectLine = Flatten.line(
                  shiftedObsPoint,
                  shiftedObsPoint.translate(atMostVerticalForJunction),
                );
                const intersect2 = myLine.intersect(intersectLine);
                if (intersect2.length === 0)
                  Logger.error(
                    "No intersection. The original direction was already wrong in followWall",
                    {},
                    {
                      myLine,
                      intersectLine,
                    },
                  );
                const length2 = direction.dot(
                  Flatten.vector(curr, intersect2[0]),
                );
                if (length2 < length) {
                  preferVertical = true;
                  if (dirMul === 1) {
                    ccwBest = updateTraceResult(ccwBest, "max", {
                      type: "junction",
                      adjacent: obs,
                      length: length2,
                      point: intersect2[0],
                      vector: atMostVerticalForJunction,
                    });
                  } else {
                    cwBest = updateTraceResult(cwBest, "max", {
                      type: "junction",
                      adjacent: obs,
                      length: length2,
                      point: intersect2[0],
                      vector: atMostVerticalForJunction,
                    });
                  }
                }
              }
              if (!preferVertical)
                if (dirMul === 1) {
                  ccwBest = updateTraceResult(ccwBest, "max", {
                    type: "junction",
                    adjacent: obs,
                    length,
                    point: intersect,
                    vector: obsDir,
                  });
                } else {
                  cwBest = updateTraceResult(cwBest, "max", {
                    type: "junction",
                    adjacent: obs,
                    length,
                    point: intersect,
                    vector: obsDir,
                  });
                }
            }
          }
        }

        // check if we collide with them at all
        const proj = obs.segment.distanceTo(
          Flatten.segment(
            curr,
            curr.translate(direction.normalize().multiply(1000000)),
          ),
        )[1];
        // Ray instead of line, to avoid conflict behind us, which we shouldn't care about.
        // Why is there no Flatten.Ray???
        if (proj.length > radius + obs.radiusMM - hitLenienceMM) {
          continue;
        }

        // We do collide.
        const endVec = Flatten.vector(curr, myLine.intersect(segLine)[0]);
        const togo = direction.normalize().dot(endVec);
        if (togo < IGNORE_COLLISION_EPS) {
          // obstacle is behind - ignore
          continue;
        }

        // By default, assume that the line passes in front of us and we have
        // this angled slice through a rectangle length to measure.
        let thisDist =
          togo - (nextRadius + obs.radiusMM) / Math.abs(Math.sin(angle));
        if (
          obs.segment.distanceTo(
            Flatten.segment(
              curr,
              curr.translate(
                direction.normalize().multiply(Math.max(0, thisDist)),
              ),
            ),
          )[0] >
          nextRadius + obs.radiusMM + EPS
        ) {
          // maybe consider head-on collision.
          let l =
              obs.segment.distanceTo(
                Flatten.segment(
                  curr,
                  curr.translate(direction.normalize().multiply(thisDist)),
                ),
              )[0] -
              nextRadius -
              obs.radiusMM,
            r = (nextRadius + obs.radiusMM) * 100;
          while (r - l > EPS) {
            const m = (l + r) / 2;
            if (
              obs.segment.distanceTo(
                Flatten.segment(
                  curr,
                  curr.translate(direction.normalize().multiply(thisDist + m)),
                ),
              )[0] <
              nextRadius + obs.radiusMM - 10
            ) {
              r = m;
            } else {
              l = m;
            }
          }
          if (l > (dontLikeHeadOnHit ? radius : 0)) {
            thisDist += l;
            bestHit = updateTraceResult(bestHit, "min", {
              type: "hit",
              hit: obs,
              hitType: "head-on",
              point: curr.translate(direction.multiply(thisDist)),
              length: thisDist + (dontLikeHeadOnHit ? radius : 0),
            });
            continue;
          }
        }
        if (thisDist <= 0) {
          if (print) {
            console.log("Collision with side error", {
              curr,
              obsA,
              obsB,
              obs,
            });
          }
          return null;
        }

        if (print) {
          console.log("Hit lateral", { obs, length: thisDist });
        }
        bestHit = updateTraceResult(bestHit, "min", {
          type: "hit",
          hit: obs,
          hitType: "lateral",
          point: curr.translate(direction.multiply(thisDist)),
          length: thisDist,
        });
      }
    }

    let bestResult: TraceResult = {
      type: "miss",
      length: Infinity,
    };

    if (cwBest && (side == undefined || side === "cw")) {
      bestResult = updateTraceResult(bestResult, "min", cwBest, true);
    }
    if (ccwBest && (side == undefined || side === "ccw")) {
      bestResult = updateTraceResult(bestResult, "min", ccwBest, true);
    }
    if (bestHit) {
      bestResult = updateTraceResult(bestResult, "min", bestHit);
    }

    if (print) {
      console.log({ cwBest, ccwBest, bestHit });
    }

    if (bestResult.length < minLengthMM) {
      if (print) {
        console.log("Too short", bestResult);
      }
      return null;
    }

    if (print) {
      console.log("Best hit was", { bestHit, cwBest, ccwBest });
      console.log("Going with", { direction, bestObstacle, bestResult });
    }

    if (
      bestResult.type === "hit" &&
      bestResult.hitType === "head-on" &&
      dontLikeHeadOnHit
    ) {
      // We didn't mean to actually apply this length
      // We just added radius to radius to make it less favorable.
      bestResult.length -= radius;
    }
    return bestResult;
  }
  // End of UFH loop generation helper functions
}
