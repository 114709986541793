import { ActionTree } from "vuex";
import { Logger } from "../../../../common/src/lib/logger";
import { getSubscriptionSummary } from "../../api/subscription";
import { RootState } from "../types";
import { SubscriptionState } from "./types";

export const actions: ActionTree<SubscriptionState, RootState> = {
  async fetchMySubscriptionSummary({ commit, rootGetters }) {
    const orgId = rootGetters["profile/profile"]?.organization?.id;

    if (!orgId) {
      Logger.error(
        "OrgId is undefined when fetching subscription summary",
        {},
        {
          profile: rootGetters["profile/profile"],
        },
      );
      return;
    }

    const response = await getSubscriptionSummary(orgId);
    if (response.success) {
      commit("setSubscriptionSummary", response.data);
    }
  },
};
