import { v4 } from "uuid";
import { Color } from "../../../../lib/color";
import { Coord } from "../../../../lib/coord";
import {
  chooseByUnitsMetric,
  convertPipeDiameterFromMetric,
  friendlyNumberMetric,
  Units,
  UnitsContext,
} from "../../../../lib/measurements";
import {
  assertUnreachable,
  Choice,
  cloneSimple,
  parseCatalogNumberExact,
  parseCatalogNumberOrMin,
} from "../../../../lib/utils";
import { CoreContext } from "../../../calculations/types";
import { isGas, isVentilation, StandardFlowSystemUids } from "../../../config";
import {
  determineConnectableSystemUid,
  isDirectedValveFlowSystemValid,
} from "../../../coreObjects/utils";
import { getTooltip } from "../../../tooltips/tooltips";
import { SelectedMaterialManufacturer, UnitsParameters } from "../../drawing";
import { getFlowSystem } from "../../utils";
import { DrawableEntityConcrete } from "../concrete-entity";
import { FieldType } from "../field-type";
import { MultiwayValveEntity } from "../multiway-valves/multiway-valve-entity";
import { PropertyField } from "../property-field";
import { ConnectableEntity, NamedEntity } from "../simple-entities";
import { EntityType } from "../types";
import {
  Balancing,
  DirectedValveConcrete,
  Fan,
  InsertDirectedValveSpec,
  InsertMultiwayValveSpec,
  InsertValveSpec,
  isVentsValve,
  LSV,
  ValveType,
} from "./valve-types";

export default interface DirectedValveEntity
  extends ConnectableEntity,
    NamedEntity {
  type: EntityType.DIRECTED_VALVE;
  center: Coord;
  systemUidOption: string | null;
  color: Color | null;

  sourceUid: string | null;

  valve: DirectedValveConcrete;
  valveSizeMM?: number;
}
export interface FanEntity extends DirectedValveEntity {
  valve: Fan;
}

export interface BalancingValveEntity extends DirectedValveEntity {
  valve: Balancing;
}

export interface LSVEntity extends DirectedValveEntity {
  valve: LSV;
}

export function isFanEntity(
  entity: DrawableEntityConcrete,
): entity is FanEntity {
  return (
    entity.type === EntityType.DIRECTED_VALVE &&
    entity.valve.type === ValveType.FAN
  );
}

export function isBalancingValveEntity(
  entity: DrawableEntityConcrete,
): entity is BalancingValveEntity {
  return (
    entity.type === EntityType.DIRECTED_VALVE &&
    entity.valve.type === ValveType.BALANCING
  );
}

export function isLSVEntity(
  entity: DrawableEntityConcrete,
): entity is BalancingValveEntity {
  return (
    entity.type === EntityType.DIRECTED_VALVE &&
    entity.valve.type === ValveType.LSV
  );
}

const DEFAULT_SMOKE_DAMPER_ZETA = 3.5;
const DEFAULT_FIRE_DAMPER_ZETA = 3.5;
const DEFAULT_VOLUME_CONTROL_DAMPER_ZETA = 3.5;
const DEFAULT_ATTENUATOR_ZETA = 0.4;

export function makeDirectedValveFields(
  context: CoreContext,
  entity: DirectedValveEntity,
): PropertyField[] {
  const fields: PropertyField[] = [];
  const { catalog, globalStore, drawing } = context;
  const systemUid = determineConnectableSystemUid(globalStore, entity);
  const systems = drawing.metadata.flowSystemUidsInOrder.map(
    (uid) => drawing.metadata.flowSystems[uid],
  );
  const availableSystems = systems.filter((s) =>
    isDirectedValveFlowSystemValid(entity, s),
  );
  const iAmVentilation =
    !!systemUid && isVentilation(drawing.metadata.flowSystems[systemUid]);

  fields.push(
    {
      property: "entityName",
      title: "Name",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Text,
      params: null,
      multiFieldId: "entityName",
    },
    {
      property: "valveSizeMM",
      title: "Size",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Number,
      // min and step are weird number, to maintain existing project's valve sizes. The legacy
      // feature had this at a weird off-by-one increment but it is not worth
      // changing existing docs to fix.
      params: {
        min: 31.11111111111,
        max: 70,
        step: 3.8888888888888888888,
        displayMin: 0,
        displayMax: 100,
      },
      multiFieldId: "valveSizeMM",
      units: Units.Percent,
    },
    {
      property: "systemUidOption",
      title: "Flow System",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.FlowSystemChoice,
      params: {
        systems: availableSystems,
      },
      multiFieldId: "systemUid",
    },
    {
      property: "color",
      title: "Color",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Color,
      params: null,
      multiFieldId: "color",
    },
  );

  switch (entity.valve.type) {
    case ValveType.CHECK_VALVE:
      break;
    case ValveType.ISOLATION_VALVE:
      /* This will be a custom part of the property box
            fields.push(
                { property: 'valve.isClosed', title: 'Is Closed:', hasDefault: false, isCalculated: false,
                    type: FieldType.Boolean, params: null,  multiFieldId: 'isClosed' },
            );*/
      fields.push({
        property: "valve.makeIsolationCaseOnRingMains",
        title: "Make Isolation Case on Ring Mains:",
        hasDefault: false,
        isCalculated: false,
        type: FieldType.Boolean,
        params: null,
        multiFieldId: "makeIsolationCaseOnRingMains",
      });
      break;
    case ValveType.RPZD_DOUBLE_ISOLATED:
    case ValveType.RPZD_DOUBLE_SHARED:
    case ValveType.RPZD_SINGLE: {
      const manufacturer =
        drawing.metadata.catalog.backflowValves.find(
          (material: SelectedMaterialManufacturer) =>
            material.uid === entity.valve.catalogId,
        )?.manufacturer || "generic";
      const sizes = Object.keys(
        catalog.backflowValves[entity.valve.catalogId].valvesBySize[
          manufacturer
        ],
      ).map((s) => {
        const val = convertPipeDiameterFromMetric(
          drawing.metadata.units,
          parseCatalogNumberExact(s),
        );
        const c: Choice = {
          disabled: false,
          key: parseCatalogNumberOrMin(s),
          name: val[1] + val[0],
        };
        return c;
      });
      fields.push({
        property: "valve.sizeMM",
        title: "Size",
        hasDefault: false,
        isCalculated: true,
        isShown: false,
        type: FieldType.Choice,
        params: { choices: sizes, initialValue: sizes[0].key },
        multiFieldId: "diameterMM",
        requiresInput: false,
        units: Units.Millimeters,
      });
      if (entity.valve.type === ValveType.RPZD_DOUBLE_ISOLATED) {
        fields.push({
          property: "valve.isolateOneWhenCalculatingHeadLoss",
          title: "Isolate When Calculation Head Loss?",
          hasDefault: false,
          isCalculated: false,
          params: null,
          type: FieldType.Boolean,
          multiFieldId: "isolateOneWhenCalculatingHeadLoss",
        });
      }
      break;
    }
    case ValveType.BALANCING:
    case ValveType.LSV:
    case ValveType.PICV:
      break;
    case ValveType.STRAINER:
    case ValveType.TRV:
    case ValveType.RV:
      break;
    case ValveType.PRV_SINGLE:
    case ValveType.PRV_DOUBLE:
    case ValveType.PRV_TRIPLE: {
      const sizes = Object.keys(catalog.prv).map((s) => {
        const val = convertPipeDiameterFromMetric(
          drawing.metadata.units,
          parseCatalogNumberExact(s),
        );
        const c: Choice = {
          disabled: false,
          key: parseCatalogNumberOrMin(s),
          name: val[1] + val[0],
        };
        return c;
      });
      fields.push({
        property: "valve.sizeMM",
        title: "Size",
        hasDefault: false,
        isCalculated: true,
        isShown: false,
        type: FieldType.Choice,
        params: { choices: sizes, initialValue: sizes[0].key },
        multiFieldId: "diameterMM",
        requiresInput: false,
        units: Units.Millimeters,
      });
      fields.push({
        property: "valve.targetPressureKPA",
        title: "Target Pressure",
        hasDefault: false,
        isCalculated: false,
        type: FieldType.Number,
        params: { min: 0, max: null },
        multiFieldId: "targetPressure",
        requiresInput: true,
        units: Units.KiloPascals,
      });
      if (
        entity.valve.type === ValveType.PRV_DOUBLE ||
        entity.valve.type === ValveType.PRV_TRIPLE
      ) {
        fields.push({
          property: "valve.isolateOneWhenCalculatingHeadLoss",
          title: "Isolate When Calculation Head Loss?",
          hasDefault: false,
          isCalculated: false,
          params: null,
          type: FieldType.Boolean,
          multiFieldId: "isolateOneWhenCalculatingHeadLoss",
        });
      }
      break;
    }
    case ValveType.GAS_REGULATOR: {
      fields.push(
        {
          property: "valve.outletPressureKPA",
          title: "Regulator Outlet Pressure",
          hint: getTooltip("GasRegulator", "Regulator Outlet Pressure"),
          hasDefault: false,
          isCalculated: false,
          type: FieldType.Number,
          params: { min: 0, max: null },
          multiFieldId: "outletPressureKPA",
          requiresInput: true,
          units: Units.GasKiloPascals,
          unitContext: UnitsContext.GAS_ENERGY_MEASUREMENT,
        },
        {
          property: "valve.downStreamPressureKPA",
          title: "Downstream Pressure",
          hint: "Default pressure of appliances, nodes and other gas entities downstream of this regulator. (Will be applied after clicking 'Results')",
          hasDefault: false,
          isCalculated: false,
          type: FieldType.Number,
          params: { min: 0, max: null },
          multiFieldId: "downStreamPressureKPA",
          requiresInput: true,
          units: Units.GasKiloPascals,
          unitContext: UnitsContext.GAS_ENERGY_MEASUREMENT,
        },
      );
      break;
    }
    case ValveType.WATER_METER:
    case ValveType.CSV:
    case ValveType.FILTER: {
      fields.push({
        property: "valve.pressureDropKPA",
        title: "Pressure Drop",
        hasDefault: false,
        isCalculated: false,
        type: FieldType.Number,
        params: { min: 0, max: null },
        multiFieldId: "pressureDrop",
        requiresInput: true,
        units: isGas(drawing.metadata.flowSystems[systemUid!])
          ? Units.GasKiloPascals
          : Units.KiloPascals,
        unitContext: isGas(drawing.metadata.flowSystems[systemUid!])
          ? UnitsContext.GAS_ENERGY_MEASUREMENT
          : UnitsContext.NONE,
      });
      break;
    }
    case ValveType.FAN:
      fields.push({
        property: "valve.pressureDropKPA",
        title: "Pressure Drop (PD)",
        hasDefault: true,
        isCalculated: false,
        type: FieldType.Number,
        params: { min: 0, max: null },
        multiFieldId: "pressureDrop",
        unitContext: UnitsContext.VENTILATION,
        units: Units.KiloPascals,
      });
      break;
    case ValveType.FLOOR_WASTE:
      const manufacturer =
        drawing.metadata.catalog.floorWaste[0]?.manufacturer || "generic";

      if (manufacturer === "blucher") {
        fields.push({
          property: "valve.variant",
          title: "Variant",
          hasDefault: false,
          isCalculated: false,
          type: FieldType.Choice,
          params: {
            choices: [
              { name: "Normal", key: "normal" },
              { name: "Bucket Trap", key: "bucketTrap" },
            ],
          },
          multiFieldId: null,
        });

        if (entity.valve.variant === "bucketTrap") {
          fields.push({
            property: "valve.bucketTrapSize",
            title: "Size",
            hasDefault: false,
            isCalculated: false,
            type: FieldType.Choice,
            params: {
              choices: [
                { name: "Regular", key: "regular" },
                { name: "Large", key: "large" },
              ],
            },
            multiFieldId: null,
          });
        }

        break;
      }
    case ValveType.INSPECTION_OPENING:
    case ValveType.REFLUX_VALVE:
      break;
    case ValveType.SMOKE_DAMPER:
    case ValveType.FIRE_DAMPER:
    case ValveType.VOLUME_CONTROL_DAMPER:
    case ValveType.ATTENUATOR:
      fields.push({
        property: "valve.zeta",
        title: "Zeta Coefficient",
        hasDefault: true,
        isCalculated: false,
        type: FieldType.Number,
        params: {
          min: 0,
          max: null,
        },
        multiFieldId: "valve-zeta",
      });
      break;
    case ValveType.CUSTOM_VALVE:
      fields.push({
        property: "valve.pressureDropType",
        title: "Pressure Drop Method",
        hint: getTooltip("CustomValve", "Pressure Drop Method"),
        hasDefault: false,
        isCalculated: false,
        type: FieldType.Choice,
        params: {
          choices: [
            {
              name: "Fixed",
              key: "fixed",
            },
            {
              name: "K Value",
              key: "kvalue",
            },
          ],
        },
        multiFieldId: "valve-pressureDropType",
        requiresInput: false,
      });

      switch (entity.valve.pressureDropType) {
        case "fixed":
          fields.push({
            property: "valve.pressureDropKPA",
            title: "Pressure Drop",
            hasDefault: false,
            isCalculated: false,
            type: FieldType.Number,
            params: { min: 0, max: null },
            multiFieldId: "valve-pressureDropKPA",
            requiresInput: false,
            unitContext: iAmVentilation
              ? UnitsContext.VENTILATION
              : UnitsContext.NONE,
            units: Units.KiloPascals,
          });
          break;
        case "kvalue":
          fields.push({
            property: "valve.kValue",
            title: "K Value",
            hint: "K Value used to calculate the pressure drop",
            hasDefault: false,
            isCalculated: false,
            type: FieldType.Number,
            params: { min: 0, max: null },
            multiFieldId: "valve-kValue",
            requiresInput: false,
            units: Units.None,
          });
          break;
        default:
          assertUnreachable(entity.valve.pressureDropType);
      }

      break;
    default:
      assertUnreachable(entity.valve);
  }

  return fields;
}

export function fillDirectedValveFields(
  context: CoreContext,
  entity: DirectedValveEntity,
) {
  const { drawing, globalStore } = context;

  const result = cloneSimple(entity);
  const systemUid = determineConnectableSystemUid(globalStore, entity);
  const system = getFlowSystem(drawing, systemUid);

  result.systemUidOption = system ? system.uid : null;

  if (!!system) {
    if (result.color == null) {
      result.color = system.color;
    }

    let manufacturer = "generic";
    switch (result.valve.type) {
      case ValveType.FLOOR_WASTE:
        manufacturer =
          drawing.metadata.catalog.floorWaste[0]?.manufacturer || manufacturer;

        if (manufacturer === "blucher") {
          if (!result.valve.variant) {
            result.valve.variant = "normal";

            if (
              [
                StandardFlowSystemUids.GreaseWaste,
                StandardFlowSystemUids.TradeWaste,
              ].includes(systemUid!)
            ) {
              result.valve.variant = "bucketTrap";
            }
          }

          if (!result.valve.bucketTrapSize) {
            result.valve.bucketTrapSize =
              result.valve.variant === "normal" ? "large" : "regular";
          }
        }
        break;
      case ValveType.VOLUME_CONTROL_DAMPER:
      case ValveType.ATTENUATOR:
      case ValveType.GAS_REGULATOR:
      case ValveType.INSPECTION_OPENING:
      case ValveType.ISOLATION_VALVE:
      case ValveType.RV:
      case ValveType.PRV_DOUBLE:
      case ValveType.PRV_SINGLE:
      case ValveType.PRV_TRIPLE:
      case ValveType.REFLUX_VALVE:
      case ValveType.RPZD_DOUBLE_ISOLATED:
      case ValveType.RPZD_DOUBLE_SHARED:
      case ValveType.RPZD_SINGLE:
      case ValveType.STRAINER:
      case ValveType.WATER_METER:
      case ValveType.CSV:
      case ValveType.BALANCING:
      case ValveType.LSV:
      case ValveType.PICV:
      case ValveType.TRV:
      case ValveType.CHECK_VALVE:
      case ValveType.FILTER:
      case ValveType.CUSTOM_VALVE:
      case ValveType.SMOKE_DAMPER:
      case ValveType.FIRE_DAMPER:
      case ValveType.VOLUME_CONTROL_DAMPER:
      case ValveType.ATTENUATOR:
      case ValveType.FAN:
        if (!result.valveSizeMM) {
          result.valveSizeMM = friendlyNumberMetric(
            drawing.metadata.units,
            Units.Millimeters,
            70,
            UnitsContext.NONE,
          )[1];
        }
        break;
      default:
        assertUnreachable(result.valve);
    }
  } else {
    if (result.color == null) {
      result.color = { hex: "#888888" };
    }
  }

  if (isVentsValve(result.valve)) {
    switch (result.valve.type) {
      case ValveType.SMOKE_DAMPER:
        if (result.valve.zeta === null) {
          result.valve.zeta = DEFAULT_SMOKE_DAMPER_ZETA;
        }
        break;
      case ValveType.FIRE_DAMPER:
        if (result.valve.zeta === null) {
          result.valve.zeta = DEFAULT_FIRE_DAMPER_ZETA;
        }
        break;
      case ValveType.VOLUME_CONTROL_DAMPER:
        if (result.valve.zeta === null) {
          result.valve.zeta = DEFAULT_VOLUME_CONTROL_DAMPER_ZETA;
        }
        break;
      case ValveType.ATTENUATOR:
        if (result.valve.zeta === null) {
          result.valve.zeta = DEFAULT_ATTENUATOR_ZETA;
        }
        break;
      case ValveType.FAN:
        const calc = context.globalStore.getOrCreateCalculation(entity);
        if (!result.valve.pressureDropKPA) {
          result.valve.pressureDropKPA = calc.fanDutyKPA ? -calc.fanDutyKPA : 0;
        }
        break;
      default:
        assertUnreachable(result.valve);
    }
  }

  return result;
}

export const DEFAULT_VALVE_SIZE = 50.555555555555555555;

export function createBareValveEntity(
  spec: InsertDirectedValveSpec,
  wc: Coord,
  unitsParams: UnitsParameters,
): DirectedValveEntity;
export function createBareValveEntity(
  spec: InsertMultiwayValveSpec,
  wc: Coord,
  unitsParams: UnitsParameters,
): MultiwayValveEntity;
export function createBareValveEntity(
  spec: InsertValveSpec,
  wc: Coord,
  unitsParams: UnitsParameters,
): DirectedValveEntity | MultiwayValveEntity;
export function createBareValveEntity(
  spec: InsertValveSpec,
  wc: Coord,
  unitsParams: UnitsParameters,
): DirectedValveEntity | MultiwayValveEntity {
  switch (spec.entityType) {
    case EntityType.DIRECTED_VALVE:
      return {
        center: cloneSimple(wc),
        color: null,
        parentUid: null,
        sourceUid: "",
        systemUidOption: spec.systemUid || null,
        type: EntityType.DIRECTED_VALVE,
        calculationHeightM: null,
        uid: v4(),
        valve: createBareDirectedValve(
          spec.valveType,
          spec.catalogId,
          unitsParams,
        ),
        entityName: null,

        // this is a weird number, because this is 50% on the legacy range. The legacy
        // feature had this at a weird off-by-one increment but it is not worth
        // changing existing docs to fix.
        valveSizeMM: spec.valveSizeMM ? spec.valveSizeMM : DEFAULT_VALVE_SIZE,
      };
    case EntityType.MULTIWAY_VALVE:
      return {
        type: EntityType.MULTIWAY_VALVE,
        center: cloneSimple(wc),
        color: null,
        parentUid: null,
        systemUidOption: spec.systemUid || null,
        valve: {
          type: spec.valveType,
        },
        valveSizeMM: spec.valveSizeMM ? spec.valveSizeMM : DEFAULT_VALVE_SIZE,
        uid: v4(),
        calculationHeightM: null,
        entityName: null,
      };
  }
  assertUnreachable(spec);
}

function createBareDirectedValve(
  type: ValveType,
  catalogId: string,
  unitsParams: UnitsParameters,
): DirectedValveConcrete {
  switch (type) {
    case ValveType.WATER_METER:
      return {
        pressureDropKPA: 0,
        catalogId: catalogId as any,
        type,
      };
    case ValveType.CSV:
      return {
        pressureDropKPA: friendlyNumberMetric(
          unitsParams,
          Units.KiloPascals,
          10,
          UnitsContext.NONE,
        )[1],
        catalogId: "csv",
        type,
      };
    case ValveType.CHECK_VALVE:
      return {
        catalogId: catalogId as any,
        type,
      };
    case ValveType.STRAINER:
      return {
        catalogId: catalogId as any,
        type,
      };
    case ValveType.ISOLATION_VALVE:
      return {
        isClosed: false,
        catalogId: catalogId as any,
        makeIsolationCaseOnRingMains: true,
        type,
      };
    case ValveType.RV:
      return {
        catalogId: catalogId as any,
        type,
      };
    case ValveType.PRV_SINGLE:
      return {
        targetPressureKPA: chooseByUnitsMetric(
          unitsParams,
          {
            [Units.KiloPascals]: 350,
            [Units.Psi]: 50,
            [Units.Bar]: 3.5,
            [Units.Mbar]: 350,
            [Units.GasKiloPascals]: 350,
            [Units.Pascals]: 350,
          },
          UnitsContext.NONE,
        )[1],
        catalogId: catalogId as any,
        type,
        sizeMM: null,
      };
    case ValveType.PRV_DOUBLE:
    case ValveType.PRV_TRIPLE:
      return {
        targetPressureKPA: null,
        catalogId: catalogId as any,
        type,
        sizeMM: null,
        isolateOneWhenCalculatingHeadLoss: false,
      };
    case ValveType.RPZD_DOUBLE_ISOLATED:
    case ValveType.RPZD_SINGLE:
    case ValveType.RPZD_DOUBLE_SHARED:
      return {
        type,
        catalogId: catalogId as any,
        sizeMM: null,
        isolateOneWhenCalculatingHeadLoss: true,
      };
    case ValveType.TRV: {
      return {
        type,
        catalogId: catalogId as any,
      };
    }
    case ValveType.BALANCING:
    case ValveType.LSV:
      return {
        type,
        catalogId: catalogId as any,
      };
    case ValveType.PICV:
      return {
        type,
        catalogId: "picv",
      };
    case ValveType.GAS_REGULATOR:
      return {
        type,
        catalogId: "gasRegulator",
        outletPressureKPA: null,
        downStreamPressureKPA: null,
      };
    case ValveType.FILTER:
      return {
        type,
        catalogId: "filter",
        pressureDropKPA: 0,
      };
    case ValveType.FLOOR_WASTE:
      return {
        type,
        catalogId: "floorWaste",
        variant: null,
        bucketTrapSize: null,
      };
    case ValveType.INSPECTION_OPENING:
      return {
        type,
        catalogId: "inspectionOpening",
      };
    case ValveType.REFLUX_VALVE:
      return {
        type,
        catalogId: "refluxValve",
      };
    case ValveType.CUSTOM_VALVE:
      return {
        type,
        catalogId: "customValve",
        kValue: null,
        pressureDropKPA: null,
        pressureDropType: "fixed",
      };
    case ValveType.SMOKE_DAMPER:
      return {
        type,
        zeta: null,
        catalogId: "smokeDamper",
      };
    case ValveType.FIRE_DAMPER:
      return {
        type,
        zeta: null,
        catalogId: "fireDamper",
      };
    case ValveType.VOLUME_CONTROL_DAMPER:
      return {
        type,
        zeta: null,
        catalogId: "volumeControlDamper",
      };
    case ValveType.ATTENUATOR:
      return {
        type,
        zeta: null,
        catalogId: "attenuator",
      };
    case ValveType.FAN:
      return {
        type,
        catalogId: "fan",
        pressureDropKPA: null,
        heightMM: null,
        widthMM: null,
      };
  }
  assertUnreachable(type);
}
