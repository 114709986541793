import { Module } from "vuex";
import { RootState } from "../types";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import OrganizationState from "./types";

export const state: OrganizationState = {
  properties: {},
  inFlightProperties: {},
};

const namespaced = true;

export const organization: Module<OrganizationState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
