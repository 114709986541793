import assert from "assert";
import { isNull } from "lodash";
import { EPS, betterObjectValues, cloneSimple } from "../../../../lib/utils";
import { CoreContext } from "../../../calculations/types";
import { ConduitConnectableEntityConcrete } from "../../../document/entities/concrete-entity";
import { PipeConduitEntity } from "../../../document/entities/conduit-entity";
import { PipeFittingEntity } from "../../../document/entities/fitting-entity";
import { V2RadiatorEntity } from "../../../document/entities/plants/plant-entity";
import { topDownLeftRightIOData } from "../../../document/entities/plants/v2-radiator/ios-data-in-order";
import { SystemNodeEntity } from "../../../document/entities/system-node-entity";
import { EntityType } from "../../../document/entities/types";
import CorePlant from "../../corePlant";
import { getFloorHeight, getIdentityCalculationEntityUid } from "../../utils";
import { CoreV2Radiator, CoreV2RadiatorPlant } from "../coreV2Radiator";
import {
  RoutingGuide,
  fromGeneratedToDrawn,
} from "../pipe-routing/pipe-routing";
import {
  v2RadiatorCalcUid,
  v2RadiatorFittingUid,
  v2RadiatorPipeUid,
  v2RadiatorSystemNodeCalcUid,
} from "./uids";

/**
 * If the system node is not connected to any pipes, pipesAndAfterFittings will
 * be empty.
 * pipesAndAfterFittings[0][0] connects system node and pipesAndAfterFittings[0][1],
 * pipesAndAfterFittings[1][0] connects pipesAndAfterFittings[0][1] and pipesAndAfterFittings[1][1],
 * In general, pipesAndAfterFittings[i + 1][0] (which is a pipe) connects pipesAndAfterFittings[i][1] and
 * pipesAndAfterFittings[i+1][1] (which are fittings)
 * So pipesAndAfterFittings.at(-1)![1] is the fitting that will be connected
 * to the real pipe.
 */
interface SingleSystemNodeGeneration {
  systemNode: SystemNodeEntity;
  pipesAndAfterFittings: [PipeConduitEntity, PipeFittingEntity][];
}
function singleSystemNodeGenerationToArray(
  generatedCalcEntities: SingleSystemNodeGeneration,
): GeneratedCalcEntity[] {
  const { systemNode, pipesAndAfterFittings } = generatedCalcEntities;
  return [systemNode, ...pipesAndAfterFittings.flat()];
}

export const ASSUMED_V2_RAD_SYSTEM_NODE_HEIGHT_ABOVE_FLOOR_M = 0.01;
const MINIMUM_HEIGHT_DIFFERENCE_M = 0.001;

/**
 * @param args Set args.options to null if the system node is not connected
 * @returns The generated entity for the system node.
 * Generated pipes and fittings if the system node is connected
 */
export function generateCalcEntitiesForSingleSystemNode(args: {
  radiator: CoreV2RadiatorPlant;
  systemNode: SystemNodeEntity;
  calcSystemNodeHeightM: number;
  options: {
    connectedPipe: PipeConduitEntity;
    routingGuide: RoutingGuide;
  } | null;
}): SingleSystemNodeGeneration {
  const { radiator, systemNode, options } = args;

  const { connectedPipe = null, routingGuide = null } = options ?? {};

  let { calcSystemNodeHeightM } = args;
  let connectedPipeHeightAboveGroundM: number | null = null;

  if (connectedPipe) {
    connectedPipeHeightAboveGroundM =
      connectedPipe.heightAboveFloorM +
      getFloorHeight(
        radiator.context.globalStore,
        radiator.context.drawing,
        connectedPipe,
      );

    if (
      Math.abs(calcSystemNodeHeightM - connectedPipeHeightAboveGroundM) < EPS
    ) {
      calcSystemNodeHeightM =
        connectedPipeHeightAboveGroundM + MINIMUM_HEIGHT_DIFFERENCE_M;
    }
  }

  const systemNodeCoord = CoreV2Radiator.getGeneratedSystemNodeObjCoord(
    radiator,
    systemNode.uid,
  );

  const calcSystemNode: SystemNodeEntity = {
    type: EntityType.SYSTEM_NODE,
    parentUid: v2RadiatorCalcUid(radiator.uid),
    uid: v2RadiatorSystemNodeCalcUid(systemNode.uid),
    systemUid: systemNode.systemUid,
    allowAllSystems: systemNode.allowAllSystems,
    configuration: systemNode.configuration,
    calculationHeightM: calcSystemNodeHeightM,
    center: systemNodeCoord,
  };

  if (!routingGuide || !connectedPipe) {
    // Still needs to generate the system node itself.
    return {
      systemNode: calcSystemNode,
      pipesAndAfterFittings: [],
    };
  }

  const calcFittings: PipeFittingEntity[] = fromGeneratedToDrawn(
    routingGuide,
  ).map((coord, fittingIndex) => ({
    type: EntityType.FITTING,
    fittingType: "pipe",
    uid: v2RadiatorFittingUid({
      systemNodeUid: systemNode.uid,
      fittingIndex,
    }),
    parentUid: null,
    entityName: null,
    calculationHeightM: connectedPipeHeightAboveGroundM,
    center: radiator.toWorldCoord(coord),
    systemUid: connectedPipe.systemUid,
    color: null,
    fitting: {},
  }));

  const makePipe = (
    uid: string,
    firstEp: ConduitConnectableEntityConcrete,
    secondEp: ConduitConnectableEntityConcrete,
  ): PipeConduitEntity => ({
    parentUid: null,
    type: EntityType.CONDUIT,
    uid,
    conduitType: "pipe",
    systemUid: connectedPipe.systemUid,
    lengthM: null,
    heightAboveFloorM: connectedPipe.heightAboveFloorM, // Used for cost breakdown
    color: connectedPipe.color,
    entityName: null,
    endpointUid: [firstEp.uid, secondEp.uid],
    conduit: {
      diameterMM: null,
      material: connectedPipe.conduit.material,
      maximumVelocityMS: connectedPipe.conduit.maximumVelocityMS,
      maximumPressureDropRateKPAM:
        connectedPipe.conduit.maximumPressureDropRateKPAM,
      network: connectedPipe.conduit.network,
      gradePCT: connectedPipe.conduit.gradePCT,
      configurationCosmetic: null,
    },
  });

  const calcPipesAndAfterFittings: [PipeConduitEntity, PipeFittingEntity][] =
    calcFittings.map((calcConnectable, pipeIndex) => {
      const prevCalcConnectable =
        pipeIndex === 0 ? calcSystemNode : calcFittings[pipeIndex - 1];
      const calcPipe = makePipe(
        v2RadiatorPipeUid({ systemNodeUid: systemNode.uid, pipeIndex }),
        prevCalcConnectable,
        calcConnectable,
      );
      return [calcPipe, calcConnectable];
    });

  return {
    systemNode: calcSystemNode,
    pipesAndAfterFittings: calcPipesAndAfterFittings,
  };
}

type GeneratedConnectableEntity = SystemNodeEntity | PipeFittingEntity;
type GeneratedCalcEntity = GeneratedConnectableEntity | PipeConduitEntity;

type SystemNodeUid = string;
export interface RadiatorCalcEntities {
  calcRadiatorEntity: V2RadiatorEntity;
  systemNodeUid2CalcEntities: Record<SystemNodeUid, SingleSystemNodeGeneration>;
}
export function flattenV2RadiatorCalcEntities(
  calcEntities: RadiatorCalcEntities,
): (GeneratedCalcEntity | V2RadiatorEntity)[] {
  const { calcRadiatorEntity, systemNodeUid2CalcEntities } = calcEntities;
  return [
    calcRadiatorEntity,
    ...betterObjectValues(systemNodeUid2CalcEntities).flatMap(
      singleSystemNodeGenerationToArray,
    ),
  ];
}

export function generateCalcEntities(args: {
  calcContext: CoreContext;
  radiator: CoreV2RadiatorPlant;
}): RadiatorCalcEntities {
  const { radiator, calcContext } = args;
  const coreContext = radiator.context;

  const systemNodesInOrder = topDownLeftRightIOData(radiator.entity);

  const routingGuides = CoreV2Radiator.getRoutingGuides(radiator);

  const systemNodeUid2CalcEntities: Record<
    SystemNodeUid,
    SingleSystemNodeGeneration
  > = {};

  systemNodesInOrder.forEach((nodeData, index) => {
    const { uid: systemNodeUid, systemUid } = nodeData;

    const routingGuide = routingGuides[index];
    const systemNode = coreContext.globalStore.getObjectOfTypeOrThrow(
      EntityType.SYSTEM_NODE,
      systemNodeUid,
    ).entity;

    const floorHeight = getFloorHeight(
      radiator.context.globalStore,
      radiator.context.drawing,
      radiator.entity,
    );
    const calcSystemNodeHeightM =
      ASSUMED_V2_RAD_SYSTEM_NODE_HEIGHT_ABOVE_FLOOR_M + floorHeight;

    if (!routingGuide) {
      systemNodeUid2CalcEntities[systemNodeUid] =
        generateCalcEntitiesForSingleSystemNode({
          radiator,
          systemNode,
          calcSystemNodeHeightM,
          options: null,
        });
      return;
    }

    const connectedEntities = CorePlant.getConnectedEntities({
      context: radiator.context,
      systemNodeUid,
      systemUid,
    });
    assert(!isNull(connectedEntities));
    const connectedPipe = connectedEntities.conduit.entity as PipeConduitEntity;
    systemNodeUid2CalcEntities[systemNodeUid] =
      generateCalcEntitiesForSingleSystemNode({
        radiator,
        systemNode,
        calcSystemNodeHeightM,
        options: {
          connectedPipe,
          routingGuide,
        },
      });
  });

  const calcRadiatorEntity: V2RadiatorEntity = {
    ...cloneSimple(radiator.entity),
    uid: v2RadiatorCalcUid(radiator.uid),
    parentUid: getIdentityCalculationEntityUid(
      calcContext,
      radiator.entity.parentUid,
    ),
    inletUid: v2RadiatorSystemNodeCalcUid(radiator.entity.inletUid!),
  };

  calcRadiatorEntity.plant.outletUid = v2RadiatorSystemNodeCalcUid(
    radiator.entity.plant.outletUid,
  );

  return {
    calcRadiatorEntity,
    systemNodeUid2CalcEntities,
  };
}
