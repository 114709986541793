// Stores auxillary data and objects for the entire document across all levels.

import { CalculatableEntityConcrete } from "../../api/document/entities/concrete-entity";
// tslint:disable-next-line:max-line-length
// tslint:disable-next-line:max-line-length
import {
  makeEmptyCalculation,
  makeEmptyLiveCalculation,
} from "../../api/calculations/utils";

import {
  CalculationConcrete,
  CalculationForEntity,
  LiveCalculationConcrete,
  LiveCalculationForEntity,
} from "../../api/document/calculations-objects/calculation-concrete";

import RBush from "rbush";
import { AutomatedTestGenerator } from "../../../test/helpers/AutomatedTestGenerator";
import CoreBaseBackedObject from "../../api/coreObjects/lib/coreBaseBackedObject";
import { CoreTagMap, Tag } from "../../api/coreObjects/lib/coreTagMap";
import {
  WIID,
  WarningInstance,
} from "../../api/document/calculations-objects/warnings";
import { DrawingState } from "../../api/document/drawing";
import { WithID } from "../../api/document/entities/simple-entities";
import { SpatialIndex } from "../../api/types";
import { BiSetMultiMap } from "../bi-set-multi-map";
import { SentryEntityError } from "../sentry-entity-error";
import { SentryError } from "../sentry-error";
import { SetMultiMap } from "../set-multi-map";
import { ObjectStore } from "./object-store";
import { updateSpatialIndex } from "./utils";

export class GlobalStore extends ObjectStore {
  entitiesInLevel = new Map<string | null, Set<string>>();
  levelOfEntity = new Map<string, string | null>();
  spatialIndex = new Map<string, RBush<SpatialIndex>>();
  spatialIndexObjects = new Map<string, SpatialIndex>();
  spatialIndexUpdateQueue = new Set<string>();

  calculationStore = new Map<string, CalculationConcrete>();
  liveCalculationStore = new Map<string, LiveCalculationConcrete>();
  liveCalculationWarnings = new Map<WIID, WarningInstance>();
  calculationWarnings = new Map<WIID, WarningInstance>();

  // Live calculation caches.
  objectsInCycle = new SetMultiMap<number, string>();

  drawingDependencies = new BiSetMultiMap<string>();

  /**
   * The Reference Graph for the whole project.
   *
   * Which entities are connected to which other entities.
   *
   * This is specifically focused around the references in "unfilled" entities.
   */
  references = new BiSetMultiMap<string>();

  /**
   * Stores parent/child relationships
   */
  ancestry = new BiSetMultiMap<string>();

  nextWarningInstanceId = 0;

  clear() {
    super.clear();
    this.references.clear();
    this.ancestry.clear();
    this.entitiesInLevel.clear();
    this.levelOfEntity.clear();
    this.calculationStore.clear();
    this.liveCalculationStore.clear();
    this.liveCalculationWarnings.clear();
    this.spatialIndex.clear();
    this.spatialIndexObjects.clear();
    this.spatialIndexUpdateQueue.clear();
  }

  set(
    key: string,
    value: CoreBaseBackedObject,
    levelUid?: string | null,
  ): this {
    if (levelUid === undefined) {
      throw new SentryError("Need a level to set in global store.");
    }
    if (!this.entitiesInLevel.has(levelUid)) {
      this.entitiesInLevel.set(levelUid, new Set());
    }
    this.entitiesInLevel.get(levelUid)!.add(value.entity.uid);
    this.levelOfEntity.set(value.entity.uid, levelUid);

    this.drawingDependencies.addAll(value.entity.uid, value.getVisualDeps());
    this.spatialIndexUpdateQueue.add(key);

    if (value.entity.parentUid) {
      this.ancestry.replace(key, [value.entity.parentUid]);
    }
    this.references.replace(
      key,
      value.references.map((x) => x.reference),
    );
    return super.set(key, value);
  }

  onDocumentLoaded() {
    super.onDocumentLoaded();

    this.spatialIndexUpdateQueue = new Set([...super.keys()]);
    updateSpatialIndex(this);
  }

  delete(key: string): boolean {
    if (this.has(key)) {
      const lvl = this.levelOfEntity.get(key)!;
      this.levelOfEntity.delete(key);
      this.entitiesInLevel.get(lvl)!.delete(key);
      this.drawingDependencies.delete(key);
      this.references.delete(key);

      const children = this.ancestry.getInverted(key);

      for (const uid of children) {
        const o = this.get(uid);
        if (o?.entity?.parentUid === key) {
          o.entity.parentUid = null;
        }
      }

      this.ancestry.delete(key);

      this.spatialIndexUpdateQueue.add(key);
      return super.delete(key);
    }

    return false;
  }

  getContentHashMap(drawing: DrawingState): Array<Record<string, WithID>> {
    return AutomatedTestGenerator.buildResultMaps(drawing, this);
  }

  onEntityChange(uid: string): void {
    super.onEntityChange(uid);
    const o = this.get(uid);
    if (o.entity.parentUid) {
      this.ancestry.replace(o.uid, [o.entity.parentUid]);
    }

    this.references.replace(
      o.uid,
      o.references.map((x) => x.reference),
    );

    this.updateVisualDependencies(uid);
  }

  updateVisualDependencies(uid: string) {
    const coreObjectConcrete = this.get(uid)!;

    this.drawingDependencies.replace(uid, coreObjectConcrete.getVisualDeps());

    // Update visual dependencies of all dependents.
    const visited = new Set<string>();
    const queue = [uid];
    while (queue.length) {
      const uid = queue.shift()!;
      if (visited.has(uid)) {
        continue;
      }
      visited.add(uid);
      queue.push(...this.drawingDependencies.getInverted(uid));
    }

    // visited is the list of entities we must update positioning of.
    // practically, for drawing, we must update the dependencies of each
    // visited too - ie parent of a system node for the system node caps,
    // and connections of a pipe for the fitting connector graphics.
    const visuallyUpdated = new Set<string>();
    for (const uid of visited) {
      this.onGeometryUpdate(uid);
      if (!visuallyUpdated.has(uid)) {
        this.onVisualUpdate(uid);
        visuallyUpdated.add(uid);
      }
      for (const dep of this.drawingDependencies.get(uid)) {
        if (!visuallyUpdated.has(dep)) {
          this.onVisualUpdate(dep);
          visuallyUpdated.add(dep);
        }
      }
    }
  }

  *forReferencedByTag<T extends Tag>(
    uid: string,
    tag: T,
  ): IterableIterator<CoreTagMap[T]> {
    for (const referencedBy in this.references.getInverted(uid)) {
      const res = this.ofTag(tag, referencedBy);
      if (res) {
        yield res;
      }
    }
  }

  onGeometryUpdate(uid: string) {
    this.spatialIndexUpdateQueue.add(uid);
  }

  onVisualUpdate(uid: string) {
    // console.log("Visual update", uid);
    // Update intermediate layer for graphics here.
    this.get(uid)?.onRedrawNeeded();
  }

  forEach<T = CoreBaseBackedObject>(
    callbackfn: (value: T, key: string, map: Map<string, T>) => void,
    thisArg?: any,
  ): void;
  forEach(
    callbackfn: (
      value: CoreBaseBackedObject,
      key: string,
      map: Map<string, CoreBaseBackedObject>,
    ) => void,
    thisArg?: any,
  ): void {
    super.forEach(callbackfn, thisArg);
  }

  values<T = CoreBaseBackedObject>(): IterableIterator<T>;
  values(): IterableIterator<CoreBaseBackedObject> {
    return super.values();
  }

  getOrCreateCalculation<T extends CalculatableEntityConcrete>(
    entity: T,
  ): CalculationForEntity<T> {
    if (!this.calculationStore.has(entity.uid)) {
      this.calculationStore.set(entity.uid, makeEmptyCalculation(entity));
    }

    return this.calculationStore.get(entity.uid) as CalculationForEntity<T>;
  }

  getCalculation<T extends CalculatableEntityConcrete>(
    entity: T,
  ): CalculationForEntity<T> | undefined {
    return this.calculationStore.get(entity.uid) as CalculationForEntity<T>;
  }

  getLiveCalculation<T extends CalculatableEntityConcrete>(
    entity: T,
  ): LiveCalculationForEntity<T> | undefined {
    return this.liveCalculationStore.get(
      entity.uid,
    ) as LiveCalculationForEntity<T>;
  }

  getOrCreateLiveCalculation<T extends CalculatableEntityConcrete>(
    entity: T,
  ): LiveCalculationForEntity<T> {
    if (!this.liveCalculationStore.has(entity.uid)) {
      this.liveCalculationStore.set(
        entity.uid,
        makeEmptyLiveCalculation(entity),
      );
    }

    return this.liveCalculationStore.get(
      entity.uid,
    )! as LiveCalculationForEntity<T>;
  }

  getCalculations() {
    return this.calculationStore;
  }

  setCalculation(uid: string, calculation: CalculationConcrete) {
    this.calculationStore.set(uid, calculation);
  }

  setLiveCalculation(uid: string, calculation: LiveCalculationConcrete) {
    this.liveCalculationStore.set(uid, calculation);
  }

  clearCalculations() {
    this.calculationStore.clear();
    this.liveCalculationStore.clear();
  }

  onLevelDelete(levelUid: string) {
    if (this.entitiesInLevel.get(levelUid)) {
      this.entitiesInLevel.get(levelUid)!.forEach((euid) => {
        this.delete(euid);
      });
    }
    this.entitiesInLevel.delete(levelUid);
  }

  sanityCheck(drawing: DrawingState) {
    // test that there is an exact bijection from document to things.

    // 1. Everything in doc must be in us.
    Object.values(drawing.levels).forEach((l) => {
      Object.values(l.entities).forEach((e) => {
        if (!this.has(e.uid)) {
          throw new SentryEntityError(
            "entity in document not found here",
            e.uid,
            {},
            { e },
          );
        }

        if (this.getOrThrow(e.uid).entity !== e) {
          throw new SentryEntityError(
            "entity in document not in sync",
            e.uid,
            {},
            { e },
          );
        }
      });
    });

    Object.values(drawing.shared).forEach((e) => {
      if (!this.has(e.uid)) {
        throw new SentryEntityError(
          "entity in document not found here",
          e.uid,
          {},
          { e },
        );
      }

      if (this.getOrThrow(e.uid).entity !== e) {
        throw new SentryEntityError(
          "entity in document not in sync",
          e.uid,
          {},
          { e },
        );
      }
    });

    // 2. Everything in us must be in doc.
    this.forEach((o, k) => {
      if (o.entity === undefined) {
        throw new SentryEntityError(
          "object deleted in document but still here",
          k,
        );
      }
      const lvlUid = this.levelOfEntity.get(o.uid)!;

      if (lvlUid === null) {
        if (!(o.uid in drawing.shared)) {
          throw new SentryEntityError("object not in document", o.uid);
        }
      } else {
        if (!(lvlUid in drawing.levels)) {
          throw new SentryEntityError(
            "level we have doesnt exist in document",
            o.uid,
            {},
            { lvlUid },
          );
        }

        if (!(o.uid in drawing.levels[lvlUid].entities)) {
          throw new SentryEntityError("entity not in document", o.uid);
        }
      }
    });

    this.entitiesInLevel.forEach((es, lvlUid) => {
      if (lvlUid === null) {
        return;
      }

      if (!(lvlUid in drawing.levels)) {
        throw new SentryError("Level we have doesnt exist on document", {
          lvlUid,
        });
      }

      es.forEach((e) => {
        if (!(e in drawing.levels[lvlUid].entities)) {
          throw new SentryEntityError("entity not in document", e);
        }
      });
    });

    // 3. Connections must be accurate
    this.connections.forEach((euid, cons) => {
      cons.forEach((c) => {
        const p = this.ofTagOrThrow("edge", c);
        if (!p.entity.endpointUid.includes(euid)) {
          throw new SentryEntityError(
            "connection inconsistency in connectable",
            euid,
            {},
            { tp: p.uid },
          );
        }
      });
    });

    for (const edge of this.find("edge")) {
      edge.entity.endpointUid.forEach((euid) => {
        if (this.connections.get(euid).size === 0) {
          throw new SentryEntityError("connection not found", euid);
        }
        if (!this.connections.hasValue(euid, edge.uid)) {
          throw new SentryEntityError(
            "connection inconsistency in edge",
            euid,
            {},
            { conns: this.connections.get(euid) },
          );
        }
      });
    }
  }
}
