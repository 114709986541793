import { ILogger } from "./logger";
import { NullablePrimitive } from "./primitive";
import { isSentryError } from "./sentry-error";

export class ConsoleLogger implements ILogger {
  log(message?: any, ...optionalParams: any[]) {
    console.log(message, ...optionalParams);
  }

  warn(message?: any, ...optionalParams: any[]) {
    console.warn(message, ...optionalParams);
  }

  error(
    error: unknown,
    tags: Record<string, NullablePrimitive>,
    extras: Record<string, any>,
    cause?: unknown,
  ) {
    if (isSentryError(error)) {
      console.error(
        error,
        { ...tags, ...error.tags },
        { ...extras, ...error.extras },
        cause,
      );
    }
    console.error(error, tags, extras, cause);
  }

  debug(message?: any, ...optionalParams: any[]) {
    console.debug(message, ...optionalParams);
  }
}
