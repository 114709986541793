import { Coord } from "../../../lib/coord";
import { Rectangle } from "../../../lib/rectangle";
import { CoreContext } from "../../calculations/types";
import { PaperSize } from "../../paper-config";
import { FieldType } from "./field-type";
import { PropertyField, RotationField, TextField } from "./property-field";
import { DrawableEntity, PositionedEntity } from "./simple-entities";
import { EntityType } from "./types";

export interface BackgroundEntity extends DrawableEntity, PositionedEntity {
  type: EntityType.BACKGROUND_IMAGE;
  scaleName: string;
  targetScaleName: string;
  scaleFactor: number;
  key: string;
  filename: string;
  crop: Rectangle;
  paperSize: PaperSize;
  targetPaperSize: PaperSize;

  // For scaling:
  pointA: Coord | null;
  pointB: Coord | null;

  // For replacing pdfs that need adjustments later:
  offset: Coord;

  // Backgrounds are part of PDFs and so may have many pages.
  page: number;
  totalPages: number;
}

export function makeBackgroundFields(
  context: CoreContext,
  entity: BackgroundEntity,
): PropertyField[] {
  return [
    {
      property: "filename",
      title: "Title",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Text,
      params: null,
      readonly: true,
      multiFieldId: "filename",
    } satisfies TextField,
    {
      property: "rotation",
      title: "Rotation",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Rotation,
      params: {
        step: 45,
        disableFreeInput: false,
      },
      multiFieldId: "rotation",
    } satisfies RotationField,
  ];
}
