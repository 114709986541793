import { SentryEntityError } from "../sentry-entity-error";

export class IncorrectEntityTypeError extends SentryEntityError {
  constructor(expected: string, actual: string, uid: string) {
    super(
      `Entity was of Invalid Entity Type`,
      uid,
      {
        expected,
      },
      {
        actual: [...actual].join(", "),
      },
    );
  }
}
