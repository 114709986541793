import * as TM from "transformation-matrix";
import { collect } from "../../lib/array-utils";
import { Coord } from "../../lib/coord";
import { cloneNaive } from "../../lib/utils";
import { GetPressureLossOptions } from "../calculations/entity-pressure-drops";
import {
  CoreContext,
  CostBreakdown,
  PressureLossResult,
} from "../calculations/types";
import AreaSegmentCalculation, {
  AreaSegmentLiveCalculation,
} from "../document/calculations-objects/area-segment-calculation";
import AreaSegmentEntity, {
  HeatedAreaSegmentEntity,
  UnheatedAreaSegmentEntity,
  fillDefaultAreaSegmentFields,
} from "../document/entities/area-segment-entity";
import { EntityType } from "../document/entities/types";
import CoreCentered from "./core-traits/coreCentered";
import { CorePolygon } from "./core-traits/corePolygon";
import { CoreCalculatableObject } from "./lib/CoreCalculatableObject";
import CoreBaseBackedObject from "./lib/coreBaseBackedObject";

const Base = CoreCentered(
  CorePolygon(CoreCalculatableObject<AreaSegmentEntity>),
);

export type CoreAreaSegmentTagMap = {
  area_segment: CoreAreaSegment;
  "area_segment.heated": CoreAreaSegment & { entity: HeatedAreaSegmentEntity };
  "area_segment.unheated": CoreAreaSegment & {
    entity: UnheatedAreaSegmentEntity;
  };
};

export default class CoreAreaSegment extends Base {
  type: EntityType.AREA_SEGMENT = EntityType.AREA_SEGMENT;

  get refPath(): string {
    return `${this.entity.type}.${this.entity.areaType}`;
  }

  get filledEntity(): AreaSegmentEntity {
    return fillDefaultAreaSegmentFields(this.context, this.entity);
  }

  // @ts-ignore 2611
  get position() {
    const scale = 1 / this.fromParentToWorldLength(1);
    return TM.transform(
      TM.translate(this.entity.center.x, this.entity.center.y),
      TM.scale(scale, scale),
    );
  }

  getCoreNeighbours(): CoreBaseBackedObject[] {
    return this.entity.edgeUid
      .map((uid) => {
        const poly = this.context.globalStore.getSafe(uid);
        if (!poly) {
          return [];
        }

        return [poly, ...poly.getCoreNeighbours()];
      })
      .reduce((a, b) => a.concat(b), [])
      .concat(
        collect(
          this.context.globalStore.getArcsByPolygon(this.entity.uid),
          (x) => this.context.globalStore.getSafe(x),
        ),
      );
  }

  getCorrectPositionOfChild(childUid: string): Coord | null {
    throw new Error("Method not implemented.");
  }

  getFrictionPressureLossKPA(
    options: GetPressureLossOptions,
  ): PressureLossResult {
    throw new Error("Method not implemented.");
  }

  getCalculationEntities(context: CoreContext): AreaSegmentEntity[] {
    let res = cloneNaive(this.entity);
    res.uid = this.getCalculationUid(context);
    (res.edgeUid as any) = [];
    for (const uid of this.entity.edgeUid) {
      const edge = context.globalStore.getObjectOfTypeOrThrow(
        EntityType.EDGE,
        uid,
      );
      res.edgeUid.push(edge.getCalculationUid(context));
    }

    if (res.areaType === "heated-area") {
      if (
        res.underfloorHeating.manifoldUid &&
        context.globalStore.has(res.underfloorHeating.manifoldUid)
      ) {
        res.underfloorHeating.manifoldUid = context.globalStore
          .getObjectOfTypeOrThrow(
            EntityType.PLANT,
            res.underfloorHeating.manifoldUid,
          )
          .getCalculationUid(context);
      }
    }

    return [res];
  }

  collectCalculations(context: CoreContext): AreaSegmentCalculation {
    return context.globalStore.getOrCreateCalculation(
      this.getCalculationEntities(context)[0],
    );
  }

  collectLiveCalculations(context: CoreContext): AreaSegmentLiveCalculation {
    const liveCalcs = context.globalStore.getOrCreateLiveCalculation(
      this.getCalculationEntities(context)[0],
    );

    const fullCalcs = this.collectCalculations(context);

    liveCalcs.areaM2 = fullCalcs.areaM2;
    liveCalcs.roomUid = fullCalcs.roomUid;
    liveCalcs.loopsStats = fullCalcs.loopsStats;

    return liveCalcs;
  }

  costBreakdown(context: CoreContext): CostBreakdown | null {
    return null;
  }
}
