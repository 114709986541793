<template>
  <div>
    <MainNavBar />
    <div
      style="overflow-y: auto; overflow-x: hidden; height: calc(100vh - 70px)"
    >
      <b-container>
        <b-row>
          <b-col>
            <b-button class="float-left" :to="backlink"> Back </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h2>View / Edit Profile</h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <template v-if="user">
              <b-form>
                <b-form-group :label-cols="2" label="Username" :disabled="true">
                  <b-form-input
                    v-model="user.username"
                    plaintext
                    class="plaintextbox"
                  />
                </b-form-group>

                <b-form-group :label-cols="2" label="Full Name">
                  <b-form-input v-model="user.name" />
                </b-form-group>

                <b-form-group :label-cols="2" label="Email">
                  <b-form-input
                    v-model="user.email"
                    :required="false"
                    type="email"
                    :state="userEmailVerified"
                  />
                  <b-form-valid-feedback
                    v-if="profile.accessLevel < AccessLevel.MANAGER"
                  >
                    Email verified by user at
                    {{ user.email_verified_at }}
                  </b-form-valid-feedback>
                  <b-form-valid-feedback v-else>
                    Verified email
                  </b-form-valid-feedback>
                  <b-form-invalid-feedback>
                    Unverified email address
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  :label-cols="2"
                  label="Organization Name"
                  class="linkbox"
                >
                  <b-link :to="'/organizations/id/' + organization?.id">
                    {{ organization?.name }}
                  </b-link>
                </b-form-group>

                <b-form-group
                  v-if="profile && profile.accessLevel <= AccessLevel.ADMIN"
                  :label-cols="2"
                  label="Change Organization To"
                >
                  <MultiSelect
                    v-model="organization"
                    placeholder="Search..."
                    :options="organizations"
                    label="name"
                    track-by="id"
                    :custom-label="customOrgsSelectLabel"
                  />
                </b-form-group>

                <b-form-group
                  :label-cols="2"
                  label="Access Level"
                  class="linkbox"
                >
                  <b-form-radio-group
                    v-model="user.accessLevel"
                    :options="levels"
                    value-field="level"
                    text-field="name"
                    disabled-field="disabled"
                  >
                    <b-form-radio :disabled="editingMyself" value="2">
                      Manager
                      <b-icon
                        v-b-popover.hover.top="
                          'Managers can create and deactivate user in their organization'
                        "
                        icon="exclamation-circle"
                      />
                    </b-form-radio>
                  </b-form-radio-group>
                </b-form-group>

                <b-form-group
                  :label-cols="2"
                  label="Activation Status"
                  class="linkbox"
                >
                  <b>
                    <b-form-group
                      :label-cols="2"
                      :label="user.activated ? 'Activated' : 'Deactivated'"
                      class="linkbox"
                    />
                  </b>
                </b-form-group>

                <b-form-group
                  :label-cols="2"
                  label="Magicplan Integration"
                  class="linkbox"
                >
                  <div class="d-flex">
                    <b-button
                      size="sm"
                      @click="$bvModal.show('magicplan-token-modal')"
                    >
                      Manage Magicplan Credentials
                    </b-button>
                    <b-button
                      class="ml-1"
                      variant="outline-danger"
                      size="sm"
                      @click="clearMagicplanTokens"
                    >
                      Clear Tokens
                    </b-button>
                    <MagicplanTokenModal />
                  </div>
                </b-form-group>

                <b-form-group
                  :label-cols="2"
                  label="Multi-factor Authentication"
                  class="linkbox"
                >
                  <MFA
                    :editing-myself="editingMyself"
                    :username="user.username"
                    :mfa-status="user.mfaStatus"
                    @updateUserMFA="updateUserMFA"
                  />
                </b-form-group>

                <template v-if="user.activated">
                  <div v-if="canBlockUser">
                    <b-form-group :label-cols="2" label="Deactivate Reason">
                      <b-input-group>
                        <b-form-input
                          v-model="deactivateReason"
                          placeholder="Reason for deactivating user. This will be shown when they try to log in."
                        />
                        <b-input-group-append>
                          <b-button
                            variant="outline-danger"
                            @click="handleBlockClick"
                          >
                            Deactivate
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </div>
                </template>

                <template v-else>
                  <b-form-group
                    v-if="canBlockUser"
                    :label-cols="2"
                    label="Account blocks"
                  >
                    <b-input-group
                      v-for="(block, i) of blocks"
                      :key="i"
                      :prepend="'By ' + block.blockedBy"
                    >
                      <b-form-input
                        v-model="block.blockReason"
                        :readonly="true"
                        placeholder="No reason given"
                      />
                      <b-input-group-append>
                        <b-button
                          variant="outline-primary"
                          :disabled="!canRemoveBlock(block)"
                          @click="handleUnblockClick(block)"
                        >
                          Unblock
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </template>
              </b-form>

              <b-form-group
                v-if="canBlockUser"
                :label-cols="2"
                label="Reset password"
              >
                Users can reset their own password on the Login page by clicking
                on Reset Password.
                <div v-if="profile && profile.accessLevel <= AccessLevel.ADMIN">
                  <b-button size="sm" @click="adminResetUserPassword">
                    Admin password reset
                  </b-button>
                </div>
              </b-form-group>
              &nbsp;
              <b-button
                variant="primary"
                style="margin-top: 50px"
                @click="save"
              >
                <strong>Save Changes</strong>
              </b-button>
            </template>
            <b-alert v-else variant="primary" show>
              <strong>Loading...</strong>
            </b-alert>
          </b-col>
        </b-row>

        <template v-if="profile && profile.accessLevel <= AccessLevel.ADMIN">
          <b-row style="margin-top: 50px">
            <b-button-group>
              <b-button :disabled="page <= 0" @click="changePage(page - 1)">
                &lt;&lt;
              </b-button>
              <b-input
                v-model="page"
                type="number"
                @input="changePage($event)"
              />
              <b-button @click="changePage(page + 1)"> &gt;&gt; </b-button>
            </b-button-group>
          </b-row>
          <b-row>
            <b-col>
              <b-list-group size="sm">
                <b-list-group-item
                  v-for="item in accessItems"
                  :key="item.id"
                  v-b-tooltip.hover="{ title: JSON.stringify(item) }"
                  size="sm"
                  style="padding: 0"
                >
                  <b-row>
                    <b-col cols="3">
                      {{ new Date(item.dateTime).toLocaleString() }}
                    </b-col>
                    <b-col cols="2">
                      {{ item.type }}
                    </b-col>
                    <b-col cols="5">
                      {{ item.url }}
                    </b-col>
                    <b-col cols="2">
                      {{ item.success }}
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
        </template>
      </b-container>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { AccessEvents } from "../../../common/src/models/AccessEvents";
import {
  customOrgsLabel,
  Organization,
} from "../../../common/src/models/Organization";
import {
  AccessLevel,
  canBlockUser,
  canRemoveUserBlock,
  User as IUser,
  UserWithMFA,
} from "../../../common/src/models/User";
import { UserBlock } from "../../../common/src/models/UserBlocks";
import { getAccessEvents } from "../api/access-events";
import { getOrganizations } from "../api/organizations";
import {
  adminResetUserPassword,
  blockUser,
  getUser,
  getUserBlocks,
  unblockUser,
  updateUser,
} from "../api/users";
import MagicplanTokenModal from "../components/magic-plan/MagicplanTokenModal.vue";
import MainNavBar from "../components/MainNavBar.vue";
import MFA from "../components/MFA.vue";
import store from "../store/store";

export default defineComponent({
  components: { MainNavBar, MFA, MagicplanTokenModal },
  data() {
    const blocks: UserBlock[] = [];
    const organizations: Organization[] = [];
    const organization: Organization | null = null;
    const accessItems: AccessEvents[] = [];
    const user = null;

    return {
      user: user as UserWithMFA | null,
      page: 0,
      accessItems,
      organization: organization as Organization | null,
      deactivateReason: "",
      organizations,
      blocks,
    };
  },
  computed: {
    customOrgsSelectLabel() {
      return customOrgsLabel;
    },
    userEmailVerified() {
      return this.profile?.email_verified_at != null;
    },
    profile(): IUser {
      return this.$store.getters["profile/profile"];
    },
    editingMyself(): boolean {
      return (
        this.profile != null &&
        this.user != null &&
        this.profile.username === this.user.username
      );
    },
    levels() {
      if (this.profile) {
        if (this.profile.accessLevel <= AccessLevel.ADMIN) {
          return [
            {
              name: "Super User",
              level: AccessLevel.SUPERUSER,
              disabled:
                this.profile.accessLevel > AccessLevel.SUPERUSER ||
                this.editingMyself,
            },
            {
              name: "Admin",
              level: AccessLevel.ADMIN,
              disabled:
                this.profile.accessLevel >= AccessLevel.ADMIN ||
                this.editingMyself,
            },
            {
              name: "User",
              level: AccessLevel.USER,
              disabled:
                this.profile.accessLevel > AccessLevel.ADMIN ||
                this.editingMyself,
            },
          ];
        } else {
          return [
            {
              name: "User",
              level: AccessLevel.USER,
              disabled:
                this.profile.accessLevel > AccessLevel.MANAGER ||
                this.editingMyself,
            },
          ];
        }
      } else {
        return [];
      }
    },
    AccessLevel() {
      return AccessLevel;
    },
    backlink() {
      if (!this.profile || this.profile.accessLevel >= AccessLevel.USER) {
        return "/";
      } else {
        return "/users";
      }
    },
    canBlockUser() {
      return canBlockUser(this.user!, this.profile);
    },
  },
  watch: {
    $route(to) {
      this.updateUsername(to.params.id);
    },
  },
  mounted() {
    this.updateUsername(this.$route.params.id);
    if (this.profile && this.profile.accessLevel < AccessLevel.MANAGER) {
      getOrganizations().then((res) => {
        if (res.success) {
          this.organizations.splice(0, this.organizations.length, ...res.data);
        } else {
          this.$bvToast.toast(res.message, {
            variant: "danger",
            title: "Error retrieving org list",
          });
        }
      });
    }
  },
  methods: {
    updateUsername(username: string) {
      this.user = null;
      this.accessItems = [];
      this.page = 0;
      getUser(username).then((res) => {
        if (res.success) {
          this.user = res.data;
          this.organization = null;
          if (res.data.organization) {
            this.organization = res.data.organization;
          }
          if (canBlockUser(this.user, this.profile)) {
            getUserBlocks(this.user.username).then((res) => {
              if (res.success) {
                this.blocks = res.data;
              } else {
                this.$bvToast.toast(res.message, {
                  title: "Error retrieving user blocks",
                  variant: "danger",
                });
              }
            });
          }
        } else {
          this.$bvToast.toast(res.message, {
            title: "Error retrieving user data",
            variant: "danger",
          });
        }
        this.changePage(0);
      });
    },
    updateUserMFA(enabled: boolean) {
      if (this.user) {
        this.user.mfaStatus = enabled;
      }
    },
    async changePage(page: number) {
      if (!this.profile || this.profile.accessLevel >= AccessLevel.MANAGER) {
        return;
      }
      this.page = page;
      if (this.user) {
        const result = await getAccessEvents(
          this.user.username,
          this.page * 50,
          50,
        );
        if (result.success) {
          this.accessItems = result.data;
        } else {
          this.$bvToast.toast(result.message, {
            variant: "danger",
            title: "Error Retrieving Access Events",
          });
        }
      }
    },
    save() {
      if (this.user) {
        updateUser(
          this.user.username,
          this.user.name,
          this.user?.email?.toLocaleLowerCase(),
          this.user.subscribed,
          this.user.accessLevel,
          this.user.activated,
          this.organization?.id || undefined,
        ).then((res) => {
          if (res.success) {
            this.$router.push(this.backlink);

            if (this.user!.username === this.profile.username) {
              this.$store.dispatch("profile/setProfile", res.data);
            }
          } else {
            this.$bvToast.toast(res.message, {
              title: "Error saving organization",
              variant: "danger",
            });
          }
        });
      }
    },
    email() {
      throw new Error("Method not implemented.");
    },
    username() {
      throw new Error("Method not implemented.");
    },
    canRemoveBlock(block: UserBlock) {
      console.log(
        "Checking if can remove block",
        this.user,
        block,
        this.profile,
      );
      return canRemoveUserBlock(this.user!, block, this.profile);
    },
    handleBlockClick() {
      if (this.user) {
        this.$bvModal
          .msgBoxConfirm(
            "Are you sure you want to block " +
              this.user.username +
              "? Reason: " +
              this.deactivateReason,
            {
              title: "Deactivate User",
              size: "sm",
              buttonSize: "sm",
              okVariant: "danger",
              okTitle: "Deactivate",
              cancelTitle: "Cancel",
              footerClass: "p-2",
              hideHeaderClose: false,
              centered: true,
            },
          )
          .then(async (value) => {
            if (value) {
              const result = await blockUser(
                this.user!.username,
                this.deactivateReason,
              );
              if (result.success) {
                Object.assign(this.user!, result.data.user);
                this.blocks.push(result.data.block);
                this.$bvToast.toast("User deactivated", {
                  title: "Success",
                  variant: "success",
                });
              } else {
                this.$bvToast.toast(result.message, {
                  title: "Error blocking user",
                  variant: "danger",
                });
              }
            }
          });
      }
    },
    handleUnblockClick(block: UserBlock) {
      if (this.user) {
        this.$bvModal
          .msgBoxConfirm(
            "Are you sure you want to remove this block for " +
              this.user.username +
              "?" +
              (this.user.numBlocks === 1
                ? " This will reactivate the user."
                : ""),
            {
              title: "Remove Block",
              size: "sm",
              buttonSize: "sm",
              okVariant: "success",
              okTitle: "Remove block",
              cancelTitle: "Cancel",
              footerClass: "p-2",
              hideHeaderClose: false,
              centered: true,
            },
          )
          .then(async (value) => {
            if (value) {
              const result = await unblockUser(
                this.user!.username,
                block.blockReason,
              );

              console.log("Unblock result", result);

              if (result.success) {
                console.log("here");
                Object.assign(this.user!, result.data);
                console.log("here2");
                this.blocks.splice(this.blocks.indexOf(block), 1);
                console.log("here3");
                this.$bvToast.toast("User block removed", {
                  title: "Success",
                  variant: "success",
                });
              } else {
                this.$bvToast.toast(result.message, {
                  title: "Error unblocking user",
                  variant: "danger",
                });
              }
            }
          });
      }
    },
    adminResetUserPassword() {
      if (this.user) {
        this.$bvModal
          .msgBoxConfirm(
            "Are you sure you want to reset the password for " +
              this.user.username +
              "? The user will no longer be able to login with their old password",
            {
              title: "Reset Password",
              size: "sm",
              buttonSize: "sm",
              okVariant: "danger",
              okTitle: "Reset Password",
              cancelTitle: "Cancel",
              footerClass: "p-2",
              hideHeaderClose: false,
              centered: true,
            },
          )
          .then(async (value) => {
            if (value) {
              const result = await adminResetUserPassword(this.user!.username);
              console.log("result", result);
              if (result.success) {
                await this.$bvModal.msgBoxOk(
                  "The user's password has been reset to: " + result.data,
                  {
                    title: "Reset password successfully!",
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "success",
                    okTitle: "OK",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                    noCloseOnBackdrop: true,
                    noCloseOnEsc: true,
                  },
                );
              } else {
                this.$bvToast.toast(result.message, {
                  title: "Error resetting password",
                  variant: "danger",
                });
              }
            }
          });
      }
    },
    clearMagicplanTokens() {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to clear your Magicplan tokens?",
          {
            okTitle: "Yes",
          },
        )
        .then(async (res) => {
          if (res) {
            await store.dispatch("profile/setProperties", {
              magicplanCustomerId: "",
              magicplanSecretKey: "",
            });
            this.$bvToast.toast("Magicplan credentials cleared", {
              title: "Success",
              variant: "success",
            });
          }
        });
    },
  },
});
</script>

<style scoped>
.linkbox > div > :nth-child(2) {
  align-items: center;
  display: flex;
}
.linkbox > div > :nth-child(2) > a {
  padding: 0.375rem 0.75rem;
}

.plaintextbox {
  padding: 0.375rem 0.75rem;
}
</style>
