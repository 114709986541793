// record visible objects only.
import { uniq } from "lodash";
import {
  CoreConnectableObjectConcrete,
  CoreObjectConcrete,
  isArchitectureElement,
  isCoreEdgeObject,
  isPolygonObject,
  isVirtualEdgeObject,
} from "../../api/coreObjects";
import CoreBaseBackedObject from "../../api/coreObjects/lib/coreBaseBackedObject";
import { CoreTagMap, Tag } from "../../api/coreObjects/lib/coreTagMap";
import { ArchitectureElementEntity } from "../../api/document/entities/architectureElement-entity";
import {
  EdgeEntityConcrete,
  PolygonEntityConcrete,
  VirtualEdgeEntityConcrete,
  isArchitectureElementEntity,
  isEdgeEntity,
  isPolygonEntity,
  isTerminusEntity,
  isVirtualEdgeEntity,
} from "../../api/document/entities/concrete-entity";
import { CoreEntityTypeMap } from "../../api/document/entities/core-entity-map";
import { EntityType } from "../../api/document/entities/types";
import { collect } from "../array-utils";
import { BiSetMultiMap } from "../bi-set-multi-map";
import Trie from "../prefix-trie";
import { SentryEntityError } from "../sentry-entity-error";
import { SentryError } from "../sentry-error";
import { SetMultiMap } from "../set-multi-map";
import { assertUnreachable, cloneNaive } from "../utils";
import { IncorrectEntityTagError } from "./incorrect-entity-tag-error";
import { IncorrectEntityTypeError } from "./incorrect-entity-type-error";
import { MissingEntityError } from "./missing-entity-error";

export class ObjectStore extends Map<string, CoreBaseBackedObject> {
  dependsOn = new Map<string, Map<string, Set<string>>>();
  dependedBy = new Map<string, Map<string, Set<string>>>();
  protected connections = new SetMultiMap<string, string>();

  protected oldVertices = new Map<string, string[]>(); // Map from edge uid or polygon uid to list of vertex uids
  protected verticesToEdge = new Map<string, string>(); // Map of vertices uid to edge uid

  protected oldEdges = new Map<string, string[]>(); // Map from polygon uid to list of edge uids
  protected edgeToPolygons = new Map<string, string[]>(); // Map of edge uid to polygon uids

  protected oldWallToRoomEdge = new Map<string, string[]>(); // Map of wall uid to room edge uid
  protected roomEdgeToWall = new Map<string, string[]>(); // Map of room edge uid to wall uid

  protected oldFenToRoomEdge = new Map<string, string[]>(); // Map of wall uid to room edge uid
  protected roomEdgeToFen = new Map<string, string[]>(); // Map of room edge uid to fen uid

  protected oldArcToPolygon = new Map<string, string>(); // Map of arc uid to polygon uid
  protected polygonToArcs = new Map<string, string[]>(); // Map of polygon uid to arc uid

  protected oldTerminusToEdge = new Map<string, string>(); // Map of terminus uid to edge uid
  protected edgeToTerminus = new Map<string, string[]>(); // Map of edge uid to terminus uids

  /**
   * A BisetMultiMap that stores Tag => Set<UID> and UID => Set<Tag>
   *
   * This results in efficient lookup of both directions.
   *
   * - For iterating through entities with a specific tag without going through all entities.
   * - For finding if an arbitrary uid has a specific tag
   * - Retrieving a tagged entity with correct typing (eg "connectable" => CoreConnectable)
   *
   * @see this.findTag
   * @see this.ofTag
   * @see this.ofTagOrThrow
   * @see this.hasTag
   *
   * Note: This is really Uid <=> Tag, not string <=> string
   *
   * tagStore.get(uid) => Set<Tag>
   * tagStore.getInverted(tag) => Set<Uid>
   */
  protected tagStore = new BiSetMultiMap<string>(); // Bidirectional map of tags to objects

  keyPrefixTrie = new Trie();

  // A flag that, if set true, allows the document mutator to apply state directly to entities
  // without triggering side effects that change the state. For example, the directed valve object
  // maintains the sourceUid value in the properties, but must be disabled for the mutator to apply
  // diffs sensibly.
  suppressSideEffects: boolean = false;

  constructor() {
    super();
  }

  clear() {
    super.clear();
    this.tagStore.clear();
    this.dependsOn.clear();
    this.dependedBy.clear();
    this.connections.clear();
    this.oldVertices.clear();
    this.keyPrefixTrie.clear();
    this.verticesToEdge.clear();
    this.edgeToPolygons.clear();
    this.edgeToTerminus.clear();
  }

  protected pairHash(uid1: string, uid2: string): string {
    return uid1 < uid2 ? uid1 + uid2 : uid2 + uid1;
  }

  getEdgeByVertices(uid1: string, uid2: string): string | null {
    return this.verticesToEdge.get(this.pairHash(uid1, uid2)) || null;
  }

  getTerminiByEdge(uid: string): string[] {
    return this.edgeToTerminus.get(uid) || [];
  }

  getPolygonsByVertex(uid: string): string[] {
    const conns = this.connections.get(uid);
    return uniq(
      [...conns].flatMap((edgeUid) => this.edgeToPolygons.get(edgeUid) || []),
    );
  }

  getPolygonsByEdge(uid: string): string[] {
    return uniq(this.edgeToPolygons.get(uid) || []);
  }
  getWallsByRoomEdge(uid: string): string[] {
    return this.roomEdgeToWall.get(uid) || [];
  }

  getFensByRoomEdge(uid: string): string[] {
    return this.roomEdgeToFen.get(uid) || [];
  }
  getAllRoomEdges(): string[] {
    return Array.from(this.roomEdgeToWall.keys()).filter((uid) =>
      this.has(uid),
    );
  }
  getAllFens(): string[] {
    return Array.from(
      new Set(
        Array.from(this.roomEdgeToFen.values()).reduce(
          (acc, cur) => acc.concat(cur),
          [],
        ),
      ),
    );
  }
  getArcsByPolygon(uid: string): string[] {
    return this.polygonToArcs.get(uid) || [];
  }

  getConnections(uid: string): string[] {
    return [...this.connections.get(uid)] || [];
  }

  /**
   * Checks if a specific object has a tag, and returns the object in the correct type if it does.
   *
   * eg.
   * if (this.hasTag("connectable", obj)) {
   *   // obj is CoreConnectable
   * }
   *
   */
  hasTag<T extends Tag>(tag: T, obj: { uid: string }): obj is CoreTagMap[T] {
    return this.tagStore.get(obj.uid).has(tag);
  }

  /**
   * Returns a set of all tags that exist in this object store.
   *
   * This is NOT a list of all possible tags, just tags that current objects have.
   */
  allTags(): Set<Tag> {
    return this.tagStore.valueset() as Set<Tag>;
  }

  /**
   * Get a set of tags for a given entity
   */
  getTags(uid: string): Set<Tag> {
    return this.tagStore.get(uid) as Set<Tag>;
  }

  /**
   * Find an object with a tag
   *
   * For what tags are supported.
   * @see Tag
   */
  ofTag<T extends Tag>(tag: T, uid: string): CoreTagMap[T] | null {
    if (this.tagStore.get(uid).has(tag)) {
      return (this.getSafe(uid) ?? null) as any;
    }
    return null;
  }

  /**
   * Find an object with a tag or throw
   *
   * For what tags are supported.
   * @see Tag
   */
  ofTagOrThrow<T extends Tag>(tag: T, uid: string): CoreTagMap[T] {
    // The "Missing Entity" error is more helpful than IncorrectType in this case.
    const entity = this.getOrThrow(uid);

    // Typechecking Performance: Even though this doesnt need 'any'.
    // It takes a while for the typechecker to figure that out.
    // Adding 'as any' drops the typechecking time by ~40s
    // This is because `T` can be any of 100+ tags, so hasTag ends up as a large union.
    // Using hasTag with a static string is perfectly fine (eg hasTag('connectable', entity))
    if (this.hasTag(tag, entity) as any) {
      return entity as any;
    }
    throw new IncorrectEntityTagError(tag.toString(), this.getTags(uid), uid);
  }

  /**
   * Returns an iterator for all objects with a given tag.
   */
  *find<T extends Tag>(
    tag: T,
    pred?: (obj: CoreTagMap[T]) => boolean,
  ): IterableIterator<CoreTagMap[T]> {
    for (const uid of this.getTagged(tag)) {
      const obj = this.ofTagOrThrow(tag, uid);
      if (pred === undefined) {
        yield obj;
      } else if (pred(obj)) {
        yield obj;
      }
    }
  }

  getTagged<T extends Tag>(tag: T) {
    return this.tagStore.getInverted(tag);
  }

  isInSync() {
    return this.tagStore.isInSync();
  }

  findFirst<T extends Tag>(
    tag: T,
    pred?: (obj: CoreTagMap[T]) => boolean,
  ): CoreTagMap[T] | null {
    for (const obj of this.find(tag, pred)) {
      return obj;
    }
    return null;
  }

  /**
   * @deprecated prefer safer alternatives:
   *
   * getSafe: If you need to get a generic CoreObject
   * getObjectOfType: If you know what type of CoreObject you're looking for
   * getObjectOfTypeOrThrow: If you know the CoreObject and it is guaranteed to exist in the store.
   * Check out other getXOfType methods for things like Calculatables and Connectables.
   */
  get(key: string): CoreBaseBackedObject {
    return super.get(key)!;
  }

  getOrThrow(key: string): CoreBaseBackedObject {
    const res = super.get(key);
    if (res) {
      return res;
    }
    throw new SentryEntityError(`Entity not found`, key);
  }

  getSafe(key: string): CoreBaseBackedObject | undefined {
    return super.get(key);
  }

  watchDependencies(uid: string, prop: string, deps: Set<string>) {
    if (!this.dependsOn.has(uid)) {
      this.dependsOn.set(uid, new Map<string, Set<string>>());
    }
    const depends = this.dependsOn.get(uid)!;
    if (!depends.has(prop)) {
      depends.set(prop, deps);
    } else {
      console.warn(
        "Property " + uid + " " + prop + " already has deps registered.",
      );
      return;
    }

    deps.forEach((dep) => {
      if (!this.dependedBy.has(dep)) {
        this.dependedBy.set(dep, new Map<string, Set<string>>());
      }
      const depended = this.dependedBy.get(dep)!;
      if (!depended.has(uid)) {
        depended.set(uid, new Set<string>());
      }
      if (!depended.get(uid)!.has(prop)) {
        depended.get(uid)!.add(prop);
      } else {
        throw new SentryEntityError(
          "Dependency already has prop registered",
          uid,
          { prop },
          { dep },
        );
      }
    });
  }

  onDocumentLoaded() {
    this.forEach((o) => o.onUpdate());
  }

  bustDependencies(uid: string) {
    if (this.dependedBy.has(uid)) {
      this.dependedBy.get(uid)!.forEach((props, target) => {
        if (this.has(target)) {
          const o = this.getSafe(target)!;
          props.forEach((prop) => {
            o.cache.delete(prop);
          });
        }

        const tprops = this.dependsOn.get(target)!;
        Array.from(props).forEach((prop) => {
          const deps = tprops.get(prop)!;
          deps.forEach((dep) => {
            this.dependedBy.get(dep)!.get(target)!.delete(prop);
            if (!this.dependedBy.get(dep)!.get(target)!.size) {
              this.dependedBy.get(dep)!.delete(target);
            }
            if (!this.dependedBy.get(dep)!.size) {
              this.dependedBy.delete(dep);
            }
          });

          if (!this.dependsOn.get(target)!.delete(prop)) {
            throw new SentryError("dependency graph inconsistency");
          }
          if (this.dependsOn.get(target)!.size === 0) {
            this.dependsOn.delete(target);
          }
        });
      });
    }
  }

  onEntityChange(uid: string) {
    this.bustDependencies(uid);
    const o = this.get(uid)!;
    o.onUpdate();
    if (
      isCoreEdgeObject(o) ||
      isPolygonObject(o) ||
      isVirtualEdgeObject(o) ||
      isArchitectureElement(o)
    ) {
      this.updateVertices(uid);
    }
  }

  delete(key: string) {
    this.tagStore.delete(key);
    this.bustDependencies(key);
    const val = this.getSafe(key);

    if (
      this.oldVertices.has(key) ||
      this.oldEdges.has(key) ||
      this.oldArcToPolygon.has(key)
    ) {
      this.detatchOldVertices(key);
    }

    if (this.has(key)) {
      const val = this.getSafe(key);
      if (val && isVirtualEdgeObject(val)) {
        if (this.oldFenToRoomEdge.has(key) || this.oldWallToRoomEdge.has(key)) {
          this.detatchOldVertices(key);
        }
      }
    }

    if (val && isCoreEdgeObject(val)) {
      this.edgeToTerminus.delete(val.entity.uid);
    }

    this.keyPrefixTrie.remove(key);

    return super.delete(key);
  }

  getObjectOfType<T extends EntityType>(
    type: T,
    uid: string,
  ): CoreEntityTypeMap[T] | null {
    const coreObject = this.getSafe(uid);
    if (!coreObject) {
      return null;
    }

    // This might be perfectly okay, for example,
    // if you are trying to get the parent/edge of an object, when it's a specific type
    if (coreObject.type !== type) {
      return null;
    }

    return coreObject as CoreEntityTypeMap[T];
  }

  /**
   * Use with caution. This cannot return null, and instead with throw if the entity is missing.
   *
   * Rather than undefined behaviour, with "cannot read x of undefined" somewhere in the application.
   * This forces this issue in one place, so its clear what the problem is.
   *
   * @param type What type of entity you are searching for.
   * @param uid Its full uid
   *
   * @throws MissingEntityError when the entity is not found or of the wrong type.
   */
  getObjectOfTypeOrThrow<T extends EntityType>(
    type: T,
    uid: string,
  ): CoreEntityTypeMap[T] {
    const coreObject = this.getSafe(uid);

    if (!coreObject) {
      throw new MissingEntityError(type, uid);
    }

    if (coreObject.type !== type) {
      throw new IncorrectEntityTypeError(type, coreObject.type, uid);
    }

    return coreObject as CoreEntityTypeMap[T];
  }

  set(key: string, value: CoreBaseBackedObject) {
    this.tagStore.replace(key, [...value.tags]);
    this.keyPrefixTrie.add(key);
    this.bustDependencies(key);

    if (this.has(key)) {
      const val = this.getSafe(key);
      if (
        val &&
        (isCoreEdgeObject(val) ||
          isPolygonObject(val) ||
          isVirtualEdgeObject(val) ||
          isArchitectureElement(val))
      ) {
        this.detatchOldVertices(key);
      }

      if (val && isTerminusEntity(val.entity)) {
        const oldEdge = this.oldTerminusToEdge.get(key);
        if (oldEdge) {
          this.edgeToTerminus
            .get(oldEdge)
            ?.splice(this.edgeToTerminus.get(oldEdge)?.indexOf(key)!, 1);
        }
      }
    }

    if (
      isCoreEdgeObject(value) ||
      isPolygonObject(value) ||
      isVirtualEdgeObject(value) ||
      isArchitectureElement(value)
    ) {
      this.attachVertices(value.entity);
    }

    if (isTerminusEntity(value.entity)) {
      if (!this.edgeToTerminus.has(value.entity.edgeUid)) {
        this.edgeToTerminus.set(value.entity.edgeUid, []);
      }
      this.edgeToTerminus.get(value.entity.edgeUid)!.push(value.uid);
      this.oldTerminusToEdge.set(value.uid, value.entity.edgeUid);
    }

    super.set(key, value);

    return this;
  }

  search(prefix: string): string[] {
    return this.keyPrefixTrie.search(prefix);
  }

  searchObj<T = CoreObjectConcrete>(prefix: string): T[] {
    return collect(this.search(prefix), (k) => this.getSafe(k)) as T[];
  }

  // undefined values only for deleting values. So hacky but we need to hack now.
  updateVertices(key: string) {
    let obj = this.getSafe(key);

    if (!obj) {
      return;
    }

    if (isCoreEdgeObject(obj)) {
      this.bustDependencies(key);
      const e = this.ofTagOrThrow("edge", key).entity;
      this.detatchOldVertices(key);
      this.attachVertices(e);
    } else if (isPolygonObject(obj)) {
      this.bustDependencies(key);
      const e = this.ofTagOrThrow("polygon", key).entity;
      this.detatchOldVertices(key);
      this.attachVertices(e);
    } else if (isVirtualEdgeObject(obj)) {
      this.bustDependencies(key);
      const e = this.ofTagOrThrow("virtual-edge", key).entity;
      this.detatchOldVertices(key);
      this.attachVertices(e);
    } else if (isArchitectureElement(obj)) {
      this.bustDependencies(key);
      const e = this.getObjectOfTypeOrThrow(
        EntityType.ARCHITECTURE_ELEMENT,
        key,
      ).entity;
      this.detatchOldVertices(key);
      this.attachVertices(e);
    }
  }

  private detatchOldVertices(uid: string) {
    //uid is the edge uid or polygon uid
    let object = this.getOrThrow(uid);
    if (isVirtualEdgeObject(object)) {
      switch (object.entity.type) {
        case EntityType.WALL:
          this.oldWallToRoomEdge.get(uid)?.forEach((edgeUid) => {
            if (edgeUid === undefined) {
              return;
            }

            this.bustDependencies(edgeUid);

            let ix = this.roomEdgeToWall.get(edgeUid)?.indexOf(uid);
            if (ix !== undefined && ix !== -1) {
              this.roomEdgeToWall.get(edgeUid)?.splice(ix, 1);
              if (this.roomEdgeToWall.get(edgeUid)?.length === 0) {
                this.roomEdgeToWall.delete(edgeUid);
              }
            } else {
              throw new SentryEntityError(
                "edge not found in roomEdgeToWall",
                uid,
              );
            }
          });

          this.oldVertices.delete(uid);
          break;
        case EntityType.FENESTRATION:
          this.oldFenToRoomEdge.get(uid)?.forEach((edgeUid) => {
            if (edgeUid === undefined) {
              return;
            }

            this.bustDependencies(edgeUid);

            let ix = this.roomEdgeToFen.get(edgeUid)?.indexOf(uid);
            if (ix !== undefined && ix !== -1) {
              this.roomEdgeToFen.get(edgeUid)?.splice(ix, 1);
              if (this.roomEdgeToFen.get(edgeUid)?.length === 0) {
                this.roomEdgeToFen.delete(edgeUid);
              }
            } else {
              throw new SentryEntityError(
                "edge not found in roomEdgeToFen",
                uid,
              );
            }
          });

          this.oldVertices.delete(uid);
          break;
        default:
          assertUnreachable(object.entity);
      }
    } else if (isCoreEdgeObject(object)) {
      this.oldVertices.get(uid)!.forEach((oldVal) => {
        if (oldVal === undefined) {
          return;
        }
        this.bustDependencies(oldVal);
        const co = this.ofTag("connectable", oldVal);
        if (co) {
          co.onDisconnect(uid);
        }
        this.connections.deleteValue(oldVal, uid);
      });
      this.verticesToEdge.delete(
        this.pairHash(
          this.oldVertices.get(uid)![0],
          this.oldVertices.get(uid)![1],
        ),
      );
      this.oldVertices.delete(uid);
    } else if (isPolygonObject(object)) {
      this.oldEdges.get(uid)!.forEach((e) => {
        if (!this.edgeToPolygons.get(e)) {
          return;
        }
        let ix = this.edgeToPolygons.get(e)!.indexOf(uid);

        if (ix === -1) {
          throw new SentryEntityError("polygons are in an invalid state", uid);
        }
        this.edgeToPolygons.get(e)!.splice(ix, 1);
      });
      this.oldEdges.delete(uid);
    } else if (isArchitectureElement(object)) {
      let e = object.entity.parentUid!;
      let ix = this.polygonToArcs.get(e)!.indexOf(uid);

      if (ix === undefined) {
        throw new SentryEntityError("Arc are in an invalid state", uid);
      }
      this.polygonToArcs.get(e)!.splice(ix, 1);
      if (this.polygonToArcs.get(e)!.length === 0) this.polygonToArcs.delete(e);
      this.oldArcToPolygon.delete(uid);
    } else throw new SentryEntityError("Invalid entity type", uid);
  }
  private attachVertices(
    entity:
      | EdgeEntityConcrete
      | PolygonEntityConcrete
      | VirtualEdgeEntityConcrete
      | ArchitectureElementEntity,
  ) {
    if (isVirtualEdgeEntity(entity)) {
      if (!entity.polygonEdgeUid) return;
      switch (entity.type) {
        case EntityType.WALL:
          entity.polygonEdgeUid.forEach((newVal) => {
            if (!this.roomEdgeToWall.has(newVal)) {
              this.roomEdgeToWall.set(newVal, []);
            }
            this.roomEdgeToWall.get(newVal)!.push(entity.uid);
          });
          this.oldWallToRoomEdge.set(
            entity.uid,
            cloneNaive(entity.polygonEdgeUid),
          );
          break;
        case EntityType.FENESTRATION:
          entity.polygonEdgeUid.forEach((newVal) => {
            if (!this.roomEdgeToFen.has(newVal)) {
              this.roomEdgeToFen.set(newVal, []);
            }
            this.roomEdgeToFen.get(newVal)!.push(entity.uid);
          });
          this.oldFenToRoomEdge.set(
            entity.uid,
            cloneNaive(entity.polygonEdgeUid),
          );
          break;
        default:
          assertUnreachable(entity);
      }
    } else if (isEdgeEntity(entity)) {
      entity.endpointUid.forEach((newVal) => {
        if (newVal === undefined) {
          return;
        }
        this.bustDependencies(newVal);
        if (this.getSafe(newVal)) {
          (this.getSafe(newVal) as CoreConnectableObjectConcrete).onConnect(
            entity.uid,
          );
        }
        this.connections.add(newVal, entity.uid);
      });

      this.verticesToEdge.set(
        this.pairHash(entity.endpointUid[0], entity.endpointUid[1]),
        entity.uid,
      );
      this.oldVertices.set(entity.uid, [
        entity.endpointUid[0],
        entity.endpointUid[1],
      ]);
    } else if (isPolygonEntity(entity)) {
      entity.edgeUid.forEach((newVal) => {
        if (newVal === undefined) {
          return;
        }
        this.bustDependencies(newVal);
        if (!this.edgeToPolygons.has(newVal)) {
          this.edgeToPolygons.set(newVal, []);
        }
        this.oldEdges.set(entity.uid, cloneNaive(entity.edgeUid));
        this.edgeToPolygons.get(newVal)!.push(entity.uid);
      });
    } else if (isArchitectureElementEntity(entity)) {
      let e = entity.parentUid!;
      let a = entity.uid;
      if (!this.polygonToArcs.has(e)) {
        this.polygonToArcs.set(e, []);
      }
      this.polygonToArcs.get(e)!.push(a);
      this.oldArcToPolygon.set(a, e);
    } else throw new SentryError("Invalid entity type");
  }
}
