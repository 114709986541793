import Flatten from "@flatten-js/core";
import assert from "assert";
import { min, minBy, zipWith } from "lodash";
import * as TM from "transformation-matrix";
import { tupleMap } from "../../../../lib/tuple-utils";
import { EPS } from "../../../../lib/utils";
import {
  ConnectionMode,
  ConnectionsLayout,
  isLayoutSameEnd,
} from "../../../document/entities/plants/v2-radiator/radiator-layout";
import { CoreV2RadiatorPlant } from "../coreV2Radiator";
import {
  GAP_BETWEEN_PIPES_MM,
  GAP_BETWEEN_SIDE_AND_PIPE_MM,
  HORIZONTAL_PIPE_FROM_WING_THRESHOLD_MM,
} from "./constants";
import { getSameEndYCoords } from "./io-positions";

interface Dimensions {
  width: number;
  height: number;
}

const leftwardUnitVector = () => Flatten.vector(-1, 0);
const rightwardUnitVector = () => Flatten.vector(1, 0);
const downwardUnitVector = () => Flatten.vector(0, -1);
const upwardUnitVector = () => Flatten.vector(0, 1);
const horizontalLine = (y: number) =>
  Flatten.line(Flatten.point(0, y), upwardUnitVector() /* Normal vector */);

interface Box {
  left: number;
  right: number;
  top: number;
  bottom: number;
}
interface BoxFlipArgs {
  vertical: boolean;
  horizontal: boolean;
}
function flipBox(box: Box, args: BoxFlipArgs) {
  let { top, left, right, bottom } = box;
  const { vertical, horizontal } = args;
  if (vertical) {
    [top, bottom] = [-bottom, -top];
  }
  if (horizontal) {
    [left, right] = [-right, -left];
  }
  return {
    top,
    left,
    right,
    bottom,
  };
}

interface RoutingParams {
  horizontalPipeFromWingThreshold: number;
  frontCenterThreshold: number;
  rearCenterThreshold: number;

  gapBetweenPipes: number;
  gapBetweenSideAndPipe: number;
}
export function getRoutingParams(radiator: CoreV2RadiatorPlant): RoutingParams {
  return {
    gapBetweenPipes: radiator.toObjectLength(GAP_BETWEEN_PIPES_MM),
    horizontalPipeFromWingThreshold: radiator.toObjectLength(
      HORIZONTAL_PIPE_FROM_WING_THRESHOLD_MM,
    ),
    gapBetweenSideAndPipe: radiator.toObjectLength(
      GAP_BETWEEN_SIDE_AND_PIPE_MM,
    ),
    frontCenterThreshold: radiator.toObjectLength(100),
    rearCenterThreshold: radiator.toObjectLength(100),
  };
}

/**
 * @param first
 * @param second
 * @returns The smallest angle between first and second, regardless of direction
 */
function smallestAngleBetween(first: Flatten.Vector, second: Flatten.Vector) {
  const ang = first.angleTo(second);
  if (ang > Math.PI) {
    return 2 * Math.PI - ang;
  }
  return ang;
}

export function getClosestVector(
  targets: Flatten.Vector[],
  candidates: Flatten.Vector[],
): Flatten.Vector | undefined {
  return minBy(candidates, (candidate) =>
    min(targets.map((target) => smallestAngleBetween(candidate, target))),
  );
}

type Case =
  | {
      type: "direct";
    }
  | {
      type: "front";
    }
  | {
      type: "rear";
    }
  | {
      type: "wing-front";
      subcase:
        | {
            type: "land-on-side" | "land-on-front";
            x: number;
          }
        | {
            type: "land-on-vertical";
            y: number;
          };
    }
  | {
      type: "wing-side-or-rear";
      subcase:
        | {
            type: "land-on-side";
            x: number;
          }
        | {
            type: "land-on-vertical";
            y: number;
          };
    };

/**
 * The priority of a request as a sequence of number.
 * The smaller the sequence alphabetically, the higher the priority.
 */
type Priority = number[];
function comparePriority(priorityA: Priority, priorityB: Priority) {
  for (let i = 0; i < Math.min(priorityA.length, priorityB.length); i++) {
    if (priorityA[i] !== priorityB[i]) {
      return priorityA[i] - priorityB[i];
    }
  }
  return priorityA.length - priorityB.length;
}
/**
 * @param routingCase
 * @returns The priority as a sequence of number.
 */
function caseToPriority(routingCase: Case): Priority {
  switch (routingCase.type) {
    case "rear":
      return [0];
    case "wing-side-or-rear": {
      const { subcase } = routingCase;
      switch (subcase.type) {
        case "land-on-side":
          return [1, 0, subcase.x];
        case "land-on-vertical":
          return [1, 1, subcase.y];
      }
    }
    case "wing-front": {
      const { subcase } = routingCase;
      switch (subcase.type) {
        case "land-on-side":
          return [2, 0, subcase.x];
        case "land-on-vertical":
          return [2, 1, -subcase.y];
        case "land-on-front":
          return [2, 2];
      }
    }
    case "front":
    case "direct":
      return [3];
  }
}

const applyToPoint = (matrix: TM.Matrix) => (p: Flatten.Point) => {
  const res = TM.applyToPoint(matrix, p);
  return Flatten.point(res.x, res.y);
};

type NormalizationResults = {
  // The request parameters after normalization
  source: Source;
  radiatorBox: Box;
  noPipeZone: Box;
  targetY: number;

  // Parameters to restore the original coordinates system
  inverse: TM.Matrix;
  inverseBoxFlipArgs: BoxFlipArgs;
};
/**
 * Normalize this request. If target side is left, flip the whole coordinate system
 * horizontally. If the source is lower than the x-axis, flip the whole coordinate system
 * vertically. After the transformation, the target side should be right, and the source
 * should be above the x-axis.
 * @param args
 * @returns The request parameters after normalization, and the needed parameters
 * to restore the original coordinate system.
 */
function normalize(args: {
  source: Source;
  targetSide: "left" | "right";
  radiatorBox: Box;
  noPipeZone: Box;
  targetY: number;
}): NormalizationResults {
  const { source, targetSide, radiatorBox, noPipeZone } = args;
  let { targetY } = args;
  const transformations: TM.Matrix[] = [TM.identity()];
  const boxFlipArgs: BoxFlipArgs = {
    vertical: false,
    horizontal: false,
  };
  if (targetSide === "left") {
    transformations.push(TM.flipY());
    boxFlipArgs.horizontal = true;
  }
  const { objCoord, otherEndpoints } = source;
  if (objCoord.y < 0) {
    transformations.push(TM.flipX());
    boxFlipArgs.vertical = true;
    targetY *= -1;
  }
  const combined = TM.compose(transformations);
  const inverse = TM.inverse(combined);

  return {
    source: {
      objCoord: applyToPoint(combined)(objCoord),
      otherEndpoints: otherEndpoints.map(applyToPoint(combined)),
    },
    radiatorBox: flipBox(radiatorBox, boxFlipArgs),
    noPipeZone: flipBox(noPipeZone, boxFlipArgs),
    inverse,
    inverseBoxFlipArgs: boxFlipArgs, // the args is the same as the inverse
    targetY,
  };
}

interface Beam {
  source: Flatten.Point;
  base: Flatten.Vector;
}
function isOnBeam(beam: Beam, point: Flatten.Point) {
  const beanSource2Point = new Flatten.Vector(beam.source, point);
  if (beanSource2Point.length < EPS) {
    return true;
  }
  return beam.base.dot(beanSource2Point) >= 0;
}
function intersectBeams(first: Beam, second: Beam): Flatten.Point[] {
  const firstLine = new Flatten.Line(first.source, first.base.rotate90CW());
  const secondLine = new Flatten.Line(second.source, second.base.rotate90CW());

  const intersections = firstLine.intersect(secondLine);

  if (intersections.length === 0) {
    return [];
  }

  return intersections.filter(
    (intersection) =>
      isOnBeam(first, intersection) && isOnBeam(second, intersection),
  );
}

function intersectBeamAndLine(beam: Beam, line: Flatten.Line) {
  const beamLine = new Flatten.Line(beam.source, beam.base.rotate90CW());

  return beamLine
    .intersect(line)
    .filter((intersection) => isOnBeam(beam, intersection));
}

export function normalizeAndDetermineCase(args: {
  source: Source;
  targetSide: "left" | "right";
  radiatorBox: Box;
  noPipeZone: Box;
  params: RoutingParams;
  targetY: number;
  connectDirectly: boolean;
}): {
  routingCase: Case;
  normalizationResults: NormalizationResults;
} {
  const normalizationResults = normalize({
    source: args.source,
    noPipeZone: args.noPipeZone,
    radiatorBox: args.radiatorBox,
    targetSide: args.targetSide,
    targetY: args.targetY,
  });

  const { connectDirectly, params } = args;

  const routingCase = ((): Case => {
    const { source, radiatorBox, noPipeZone, targetY } = normalizationResults;
    const { objCoord } = source;
    const target = Flatten.point(noPipeZone.right, targetY);

    if (connectDirectly) {
      return {
        type: "direct",
      };
    }

    if (
      objCoord.x > noPipeZone.right &&
      objCoord.y < radiatorBox.top &&
      objCoord.y > radiatorBox.bottom
    ) {
      return {
        type: "front",
      };
    }

    if (
      objCoord.x < noPipeZone.left &&
      objCoord.y < noPipeZone.top &&
      objCoord.y > noPipeZone.bottom
    ) {
      return {
        type: "rear",
      };
    }

    // The fitting is definitely on the top wing or the bottom wing.
    const targetVector = Flatten.vector(objCoord, target);
    if (objCoord.x > noPipeZone.right) {
      // This fitting is at the front.
      // The "front" means the half plane on the right of the radiator if the target side is right,
      // and the half plane on the left of the radiator if the target side is left.
      // The "rear" means the half plane on the left of the radiator if the target side is right,
      // and the half plane on the right of the radiator if the target side is left.
      const base =
        getClosestVector(
          [targetVector],
          generateCandidateBaseVectorsFromWing(
            source,
            radiatorBox,
            "front",
            params,
          ),
        ) ?? upwardUnitVector();
      const intersectionsWithFront = intersectBeams(
        {
          source: objCoord,
          base,
        },
        {
          source: Flatten.point(noPipeZone.right, targetY),
          base: rightwardUnitVector(),
        },
      );
      if (intersectionsWithFront.length === 1) {
        // Ignore if there are 0 or 2 intersections. 2 intersections means overlapping,
        // we'll intersects it with the vertical beam instead
        return {
          type: "wing-front",
          subcase: {
            type: "land-on-front",
            x: intersectionsWithFront[0].x,
          },
        };
      }
      const intersectionsWithVertical = intersectBeams(
        {
          source: objCoord,
          base,
        },
        {
          source: Flatten.point(noPipeZone.right, targetY),
          base: upwardUnitVector(),
        },
      );
      if (intersectionsWithVertical.length === 1) {
        return {
          type: "wing-front",
          subcase: {
            type: "land-on-vertical",
            y: intersectionsWithVertical[0].y,
          },
        };
      }
      const intersectionsWithSide = intersectBeams(
        {
          source: objCoord,
          base,
        },
        {
          source: Flatten.point(noPipeZone.right, noPipeZone.top),
          base: leftwardUnitVector(),
        },
      );
      if (intersectionsWithSide.length === 1) {
        // Ignore if there are 0 or 2 intersections. 2 intersections means overlapping,
        // we'll intersect it with the vertical beam instead
        return {
          type: "wing-front",
          subcase: {
            type: "land-on-side",
            x: intersectionsWithSide[0].x,
          },
        };
      }
      // Shouldn't reach here really
      return {
        type: "direct",
      };
    } else {
      // This fitting is at the back
      const base =
        getClosestVector(
          [targetVector],
          generateCandidateBaseVectorsFromWing(
            source,
            radiatorBox,
            "rear",
            params,
          ),
        ) ?? upwardUnitVector();

      const intersectionsWithVertical = intersectBeams(
        {
          source: objCoord,
          base,
        },
        {
          source: Flatten.point(noPipeZone.right, targetY),
          base: upwardUnitVector(),
        },
      );
      if (intersectionsWithVertical.length === 1) {
        return {
          type: "wing-side-or-rear",
          subcase: {
            type: "land-on-vertical",
            y: intersectionsWithVertical[0].y,
          },
        };
      }

      const intersectionsWithSide = intersectBeamAndLine(
        {
          source: objCoord,
          base,
        },
        horizontalLine(noPipeZone.top - EPS),
      );
      if (intersectionsWithSide.length === 1) {
        return {
          type: "wing-side-or-rear",
          subcase: {
            type: "land-on-side",
            x: intersectionsWithSide[0].x,
          },
        };
      }
      // Shouldn't reach here. Don't know what to do in this case.
      return {
        type: "direct",
      };
    }
  })();

  return {
    routingCase,
    normalizationResults,
  };
}

const MAYBE_STRAIGHT_PAIR_OF_PIPES_THRESHOLD_RAD = (3 * Math.PI) / 4;
/**
 * Assumes we're already in the normalized coordinate system
 */
function generateCandidateBaseVectorsFromWing(
  source: Source,
  radiatorBox: Box,
  frontOrRear: "front" | "rear",
  params: RoutingParams,
): Flatten.Vector[] {
  const { objCoord, otherEndpoints } = source;
  const { horizontalPipeFromWingThreshold } = params;

  const vecs = otherEndpoints.map((ep) => Flatten.vector(objCoord, ep));

  const maybeStraightPairs: [Flatten.Vector, Flatten.Vector][] = [];
  vecs.forEach((first) =>
    vecs.forEach((second) => {
      if (
        smallestAngleBetween(first, second) >
        MAYBE_STRAIGHT_PAIR_OF_PIPES_THRESHOLD_RAD
      )
        maybeStraightPairs.push([first, second]);
    }),
  );

  if (maybeStraightPairs.length > 0) {
    // At least one pair of pipes almost form a straight line
    // We prioritize deriving the base vector from straight lines
    const rightAngleVectors = [
      leftwardUnitVector(),
      rightwardUnitVector(),
      upwardUnitVector(),
      downwardUnitVector(),
    ];
    const candidates = maybeStraightPairs.flatMap((pair) => {
      // If two vectors almost form a straight line, choose the one that
      // is closest to a right-angled vector
      const preferredOne = minBy(pair, (vec) =>
        min(
          rightAngleVectors.map((rightAngleVector) =>
            smallestAngleBetween(rightAngleVector, vec),
          ),
        ),
      );
      assert(preferredOne); // for type checking
      return [preferredOne.rotate90CW(), preferredOne.rotate90CCW()];
    });
    return candidates;
  }

  const ideallyHorizontal =
    objCoord.y > radiatorBox.bottom - horizontalPipeFromWingThreshold &&
    objCoord.y < radiatorBox.top + horizontalPipeFromWingThreshold;

  const idealVectors = ideallyHorizontal
    ? frontOrRear === "front"
      ? [leftwardUnitVector()]
      : [rightwardUnitVector()]
    : [downwardUnitVector()];

  if (vecs.length > 0) {
    // Connected to at least one pipe
    return vecs.map(
      (vec) =>
        getClosestVector(idealVectors, [
          vec.rotate90CW(),
          vec.rotate90CCW(),
          vec.multiply(-1),
        ])!,
    );
  }

  // Not connected to any pipe. We'll use the ideal vectors.
  return idealVectors;
}

interface PathFindingResults {
  routingGuide: RoutingGuide;
  newNoPipeZone: Box;
}
function findPath(args: {
  source: Source;
  targetSide: "left" | "right";
  targetY: number;
  radiatorBox: Box;
  noPipeZone: Box;
  params: RoutingParams;
  connectDirectly: boolean;
}): PathFindingResults {
  const { connectDirectly } = args;
  const { routingCase, normalizationResults } = normalizeAndDetermineCase({
    source: args.source,
    targetSide: args.targetSide,
    targetY: args.targetY,
    radiatorBox: args.radiatorBox,
    noPipeZone: args.noPipeZone,
    params: args.params,
    connectDirectly,
  });

  const { params } = args;

  const { gapBetweenPipes } = params;

  const normalisedPathFindingResults: PathFindingResults = (() => {
    const { radiatorBox, noPipeZone, targetY, source } = normalizationResults;
    const { objCoord } = source;
    const generatedSystemNode = Flatten.point(radiatorBox.right, targetY);
    switch (routingCase.type) {
      case "front":
      case "direct":
        return {
          routingGuide: {
            drawnSystemNode: Flatten.point(noPipeZone.right, targetY),
            intermediatePoints: [],
            generatedSystemNode,
          },
          newNoPipeZone: {
            ...noPipeZone,
          },
        };
      case "rear":
        return {
          routingGuide: {
            drawnSystemNode: Flatten.point(noPipeZone.left, objCoord.y),
            intermediatePoints: [
              Flatten.point(noPipeZone.left, noPipeZone.top),
              Flatten.point(noPipeZone.right, noPipeZone.top),
              Flatten.point(noPipeZone.right, targetY),
            ],
            generatedSystemNode,
          },
          newNoPipeZone: {
            left: noPipeZone.left - gapBetweenPipes,
            right: noPipeZone.right + gapBetweenPipes,
            top: noPipeZone.top + gapBetweenPipes,
            bottom: noPipeZone.bottom,
          },
        };
      case "wing-front": {
        const subcase = routingCase.subcase;
        switch (subcase.type) {
          case "land-on-front":
            return {
              routingGuide: {
                drawnSystemNode: Flatten.point(subcase.x, targetY),
                intermediatePoints: [Flatten.point(noPipeZone.right, targetY)],
                generatedSystemNode,
              },
              newNoPipeZone: {
                ...noPipeZone,
              },
            };
          case "land-on-vertical":
            return {
              routingGuide: {
                drawnSystemNode: Flatten.point(noPipeZone.right, subcase.y),
                intermediatePoints: [Flatten.point(noPipeZone.right, targetY)],
                generatedSystemNode,
              },
              newNoPipeZone: {
                ...noPipeZone,
                right: noPipeZone.right + gapBetweenPipes,
              },
            };
          case "land-on-side":
            return {
              routingGuide: {
                drawnSystemNode: Flatten.point(subcase.x, noPipeZone.top),
                intermediatePoints: [
                  Flatten.point(noPipeZone.right, noPipeZone.top),
                  Flatten.point(noPipeZone.right, targetY),
                ],
                generatedSystemNode,
              },
              newNoPipeZone: {
                ...noPipeZone,
                right: noPipeZone.right + gapBetweenPipes,
                top: noPipeZone.top + gapBetweenPipes,
              },
            };
        }
      }
      case "wing-side-or-rear": {
        const subcase = routingCase.subcase;
        switch (subcase.type) {
          case "land-on-side":
            return {
              routingGuide: {
                drawnSystemNode: Flatten.point(subcase.x, noPipeZone.top),
                intermediatePoints: [
                  Flatten.point(noPipeZone.right, noPipeZone.top),
                  Flatten.point(noPipeZone.right, targetY),
                ],
                generatedSystemNode,
              },
              newNoPipeZone: {
                ...noPipeZone,
                right: noPipeZone.right + gapBetweenPipes,
                top: noPipeZone.top + gapBetweenPipes,
              },
            };
          case "land-on-vertical":
            return {
              routingGuide: {
                drawnSystemNode: Flatten.point(noPipeZone.right, subcase.y),
                intermediatePoints: [Flatten.point(noPipeZone.right, targetY)],
                generatedSystemNode,
              },
              newNoPipeZone: {
                ...noPipeZone,
                right: noPipeZone.right + gapBetweenPipes,
              },
            };
        }
      }
    }
  })();

  const { inverse, inverseBoxFlipArgs } = normalizationResults;

  const {
    routingGuide: normalizedRoutingGuide,
    newNoPipeZone: normalizedNewNoPipeZone,
  } = normalisedPathFindingResults;
  return {
    routingGuide: {
      drawnSystemNode: applyToPoint(inverse)(
        normalizedRoutingGuide.drawnSystemNode,
      ),
      intermediatePoints: normalizedRoutingGuide.intermediatePoints.map(
        applyToPoint(inverse),
      ),
      generatedSystemNode: applyToPoint(inverse)(
        normalizedRoutingGuide.generatedSystemNode,
      ),
    },
    newNoPipeZone: flipBox(normalizedNewNoPipeZone, inverseBoxFlipArgs),
  };
}

interface SideKnownRequest {
  source: Source;
  targetY: number;
}
interface SameSideRequestGroup {
  targetSide: "left" | "right";
  requests: SideKnownRequest[];
}
export function getRoutingGuidesForOneSide(args: {
  dimensions: Dimensions;
  requestGroup: SameSideRequestGroup;
  params: RoutingParams;
  connectDirectly: boolean;
}): RoutingGuide[] {
  const { dimensions, requestGroup, params, connectDirectly } = args;
  const { width, height } = dimensions;
  const { gapBetweenPipes, gapBetweenSideAndPipe } = params;
  const { requests, targetSide } = requestGroup;

  const bottom = -height / 2;
  const top = +height / 2;
  const left = -width / 2;
  const right = +width / 2;

  const radiatorBox: Box = {
    top,
    left,
    bottom,
    right,
  };

  let noPipeZone: Box = {
    top: radiatorBox.top + gapBetweenPipes,
    bottom: radiatorBox.bottom - gapBetweenPipes,
    left: Math.min(
      radiatorBox.left - gapBetweenPipes,
      radiatorBox.left - gapBetweenSideAndPipe,
    ),
    right: Math.max(
      radiatorBox.right + gapBetweenPipes,
      radiatorBox.right + gapBetweenSideAndPipe,
    ),
  };

  const index2Guide = new Map<number, RoutingGuide>();

  const requestsWithMetaData = requests.map((request, index) => ({
    ...request,
    index,
    priority: caseToPriority(
      normalizeAndDetermineCase({
        source: request.source,
        targetSide,
        radiatorBox,
        noPipeZone,
        params,
        targetY: request.targetY,
        connectDirectly,
      }).routingCase,
    ),
  }));

  const requestsByPriority = requestsWithMetaData
    .slice()
    .sort((a, b) => comparePriority(a.priority, b.priority));

  for (const { index, source, targetY } of requestsByPriority) {
    const { routingGuide, newNoPipeZone } = findPath({
      source,
      targetY,
      targetSide,
      radiatorBox,
      noPipeZone,
      params,
      connectDirectly,
    });

    index2Guide.set(index, routingGuide);

    noPipeZone = newNoPipeZone;
  }

  const res: RoutingGuide[] = [];
  for (let index = 0; index < requests.length; index++) {
    res.push(index2Guide.get(index)!);
  }
  return res;
}

export interface Source {
  // The coordinates of the connected connectable in the radiator's coordinate system
  objCoord: Flatten.Point;
  // The coordinates of the connectables connected to the above connectable
  // in the radiator's coordinate systems. (Level 2 nodes in a BFS graph)
  otherEndpoints: Flatten.Point[];
}
/**
 * The coordinates of the drawn system node, the intermediate points from the
 * drawn system node to the generated system node for calculation, and the
 * generated system node. All the coordinates are in the radiator's coordinate
 * system
 */
export interface RoutingGuide {
  drawnSystemNode: Flatten.Point;
  intermediatePoints: Flatten.Point[];
  generatedSystemNode: Flatten.Point;
}
export function fromDrawnToGenerated(guide: RoutingGuide): Flatten.Point[] {
  const { drawnSystemNode, intermediatePoints, generatedSystemNode } = guide;
  return [drawnSystemNode, ...intermediatePoints, generatedSystemNode];
}
export function fromGeneratedToDrawn(guide: RoutingGuide): Flatten.Point[] {
  return fromDrawnToGenerated(guide).reverse();
}
export function getRoutingGuides(args: {
  dimensions: Dimensions;
  connectionLayout: ConnectionsLayout;
  sourcesInOrder: [Source | null, Source | null];
  params: RoutingParams;
}): [RoutingGuide | null, RoutingGuide | null] {
  const { dimensions, connectionLayout, sourcesInOrder, params } = args;
  const connectDirectly =
    connectionLayout.connectionMode === ConnectionMode.Custom;

  if (isLayoutSameEnd(connectionLayout)) {
    const targetYs = getSameEndYCoords(dimensions.height);
    const requests = zipWith(
      sourcesInOrder,
      targetYs,
      (source, targetY): SideKnownRequest | null =>
        source
          ? {
              source,
              targetY,
            }
          : null,
    ).filter((x): x is SideKnownRequest => x !== null);

    const guides = getRoutingGuidesForOneSide({
      dimensions,
      requestGroup: {
        targetSide: connectionLayout.side,
        requests,
      },
      connectDirectly,
      params,
    });

    let index = 0;
    return tupleMap(sourcesInOrder, (source: Source | null) =>
      source ? guides[index++] : null,
    );
  } else {
    const getRoutingGuideSingle = (source: Source | null, index: number) =>
      !source
        ? null
        : getRoutingGuidesForOneSide({
            dimensions,
            requestGroup: {
              targetSide: index === 0 ? "left" : "right",
              requests: [
                {
                  source,
                  targetY: 0,
                },
              ],
            },
            connectDirectly,
            params,
          })[0];

    return tupleMap(sourcesInOrder, getRoutingGuideSingle);
  }
}
