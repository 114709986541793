import { assertUnreachable } from "../../lib/utils";
import { isGas } from "../config";
import { DrawingState } from "../document/drawing";
import { DrawableEntityConcrete } from "../document/entities/concrete-entity";
import { ValveType } from "../document/entities/directed-valves/valve-types";
import {
  PlantType,
  ReturnSystemType,
} from "../document/entities/plants/plant-types";
import { EntityType } from "../document/entities/types";
import {
  TooltipsEntityName,
  TooltipsFieldName,
  TooltipsSpec,
  TooltipsSpecialSpec,
} from "./types";

const TooltipsTable: TooltipsSpec = {
  "Right to Left?": {
    entities: [
      "HeatPump",
      "GasBoiler",
      "DHWCylinder",
      "DHWCylinderWStorage",
      "HeatInterfaceUnit",
      "Header",
      "BufferTank",
      "Volumiser",
      "HotWaterPlantWReturn",
    ],
    text: "This is the direction of flow through the piece of equipment. The arrow in the centre of the equipment points in the direction of flow. ",
    specialSpec: [],
  },
  "Able to heat to DHW temp": {
    entities: ["HeatPump"],
    text: "Heat pump is able to heat connected DHW cylinders to the hot water temperature. The hot water temperature is defined on the DHW cylinder.",
    specialSpec: [],
  },
  "Able to purge legionella": {
    entities: ["HeatPump"],
    text: "Heat pump can heat connected DHW cylinders to legionella purge temperature. The purge temperature is defined on the DHW cylinder.",
    specialSpec: [],
  },
  SCOP: {
    entities: ["HeatPump"],
    text: "This number is used to calculate the Heat Pump's energy usage. The result is provided in the 'Heat Loss Report' export.",
    specialSpec: [],
  },
  "Is there a cold water inlet?": {
    entities: [
      "HeatPump",
      "GasBoiler",
      "DHWCylinder",
      "DHWCylinderWStorage",
      "HeatInterfaceUnit",
      "Header",
      "BufferTank",
      "HotWaterPlantWReturn",
    ],
    text: "This should say ' Yes ' if you want to connect a cold water plumbing pipe.",
    specialSpec: [],
  },
  "Is there a gas inlet?": {
    entities: [
      "HeatPump",
      "GasBoiler",
      "DHWCylinder",
      "DHWCylinderWStorage",
      "HeatInterfaceUnit",
      "Header",
      "BufferTank",
      "HotWaterPlantWReturn",
    ],
    text: "This should say ' Yes ' if you want to connect a gas pipe.",
    specialSpec: [],
  },
  Diversity: {
    entities: [
      "HeatPump",
      "GasBoiler",
      "DHWCylinder",
      "DHWCylinderWStorage",
      "HeatInterfaceUnit",
      "Header",
      "BufferTank",
      "HotWaterPlantWReturn",
      "GasAppliance",
      "GasLoadNode",
    ],
    text: "If you want the gas consumption to be reduced in common pipes, you can choose how much you want to reduce it here.",
    specialSpec: [],
  },
  "Is there a pre-heat?": {
    entities: [
      "HeatPump",
      "GasBoiler",
      "DHWCylinder",
      "DHWCylinderWStorage",
      "HeatInterfaceUnit",
      "Header",
      "BufferTank",
      "HotWaterPlantWReturn",
    ],
    text: "This should say ' Yes ' if you want to connect a heating system.",
    specialSpec: [],
  },
  "Rating Method": {
    entities: [
      "HeatPump",
      "GasBoiler",
      "DHWCylinder",
      "DHWCylinderWStorage",
      "HeatInterfaceUnit",
      "Header",
      "BufferTank",
      "HotWaterPlantWReturn",
      "Radiator",
      "Manifold",
      "AHU",
      "FCU",
    ],
    text: "There are two options that contribute to the pipe sizing calculation:\nPower: This rating, along with the system's delta T, will be included in the flow rate calculations.\nFlow Rate: This flow rate will be directly added to the flow rate calculations. The delta T of the system will not affect the flow rate.",
    specialSpec: [],
  },
  Volume: {
    entities: [
      "HeatPump",
      "GasBoiler",
      "DHWCylinder",
      "DHWCylinderWStorage",
      "HeatInterfaceUnit",
      "Header",
      "BufferTank",
      "HotWaterPlantWReturn",
      "AHU",
      "FCU",
    ],
    text: "This amount of water will be included in the pre-heat system's volume calculation. \nThis is not the volume of the whole piece of equipment, just the volume that relates to the pre-heat system e.g. the volume in the coil. ",
    specialSpec: [
      {
        entity: "Volumiser",
        text: "This amount of water will be included in the pre-heat system's volume calculation. ",
      },
      {
        entity: "Radiator",
        text: "The radiator volume is calculated based on the radiator type and its dimensions. If you are using a Marketplace item, the volume is coming form the datasheet.",
      },
      {
        entity: "Manifold",
        text: "Total water volume in the manifold, including the loops",
      },
    ],
  },
  "Internal Volume": {
    entities: ["Manifold"],
    text: "Water volume in the manifold itself, excluding the loops. (Redundant if 'Volume' is overridden.)",
    specialSpec: [],
  },
  "Capacity Delta T50": {
    entities: ["Radiator", "Manifold"],
    text: "Capacity Rating at Delta T50. When the Delta T deviates from this standard, a correction factor is utilized to maintain a consistent volume, despite the variations in kW rating caused by differing Delta Ts.",
    specialSpec: [],
  },
  "Pressure Drop": {
    entities: [
      "HeatPump",
      "GasBoiler",
      "DHWCylinder",
      "DHWCylinderWStorage",
      "HeatInterfaceUnit",
      "Header",
      "BufferTank",
      "Volumiser",
      "HotWaterPlantWReturn",
      "Radiator",
      "Manifold",
      "AHU",
      "FCU",
    ],
    text: "This pressure drop will be included in the pre-heat system's pressure calculations.\nIf this is the index circuit, the pressure drop will be included in the recirculation pump's duty.",
    specialSpec: [],
  },
  "Fan Pressure Drop": {
    entities: ["AHU", "FCU"],
    text: "This pressure drop will be included in the system's pressure calculations.\nIf this is the index circuit, the pressure drop will be included in the fan's duty.",
    specialSpec: [],
  },
  "Outlet Temperature": {
    entities: [
      "HeatPump",
      "GasBoiler",
      "DHWCylinder",
      "DHWCylinderWStorage",
      "HeatInterfaceUnit",
      "Header",
      "BufferTank",
      "HotWaterPlantWReturn",
    ],
    text: "This is the temperature of the water as it leaves the equipment.\nThis number is used in the calculation for recirculation systems (delta T). ",
    specialSpec: [],
  },
  "Is there a recirculation system": {
    entities: [
      "HeatPump",
      "GasBoiler",
      "DHWCylinder",
      "DHWCylinderWStorage",
      "HeatInterfaceUnit",
      "Header",
      "BufferTank",
      "HotWaterPlantWReturn",
    ],
    text: "If you want a recirculation system, it should say 'Yes'.\nIt should say ' No ' if no recirculation is required, such as a dead-leg system.",
    specialSpec: [],
  },
  "Pump on the return or feed": {
    entities: [
      "HeatPump",
      "GasBoiler",
      "DHWCylinder",
      "DHWCylinderWStorage",
      "HeatInterfaceUnit",
      "Header",
      "BufferTank",
      "HotWaterPlantWReturn",
    ],
    text: "If you want the recirculation pump placed on the return, it should say 'Yes'.\nIt should say ' No ' if the pump should be positioned on the feed.",
    specialSpec: [],
  },

  "Should include pipe heat load": {
    entities: [
      "HeatPump",
      "GasBoiler",
      "DHWCylinder",
      "DHWCylinderWStorage",
      "HeatInterfaceUnit",
      "Header",
      "BufferTank",
      "HotWaterPlantWReturn",
    ],
    text: "If you want the pipe heat load to be included in the calculation, it should say 'Yes'.\nIt should say ' No ' if the pipe heat load should not be included.",
    specialSpec: [],
  },
  "Minimum Return Temperature": {
    entities: [
      "HeatPump",
      "GasBoiler",
      "DHWCylinder",
      "DHWCylinderWStorage",
      "HeatInterfaceUnit",
      "Header",
      "BufferTank",
      "HotWaterPlantWReturn",
    ],
    text: "This is the temperature of the water as it returns to the equipment.This number is used in the calculation for recirculation systems (delta T). ",
    specialSpec: [],
  },
  "Internal Pressure Drop": {
    entities: [
      "HeatPump",
      "GasBoiler",
      "DHWCylinder",
      "DHWCylinderWStorage",
      "HeatInterfaceUnit",
      "Header",
      "BufferTank",
      "HotWaterPlantWReturn",
    ],
    text: "This pressure drop (through the equipment) will be included in the index circuit and the recirculation pump's duty.",
    specialSpec: [],
  },
  "Add Return to PSD Flow Rate": {
    entities: [
      "HeatPump",
      "GasBoiler",
      "DHWCylinder",
      "DHWCylinderWStorage",
      "HeatInterfaceUnit",
      "Header",
      "BufferTank",
      "HotWaterPlantWReturn",
    ],
    text: "This affects domestic water recirculation systems only.\nIf the recirculation pump is fixed speed, you should consider choosing 'Yes' because the pump could run at the same time as the peak flow rate, meaning your pipes will be sized for both flow rates combined. \nIf you choose no, the pipes will only be sized for the peak or recirculation flow rates.",
    specialSpec: [],
  },
  "Daily Domestic Hot Water Load": {
    entities: ["DHWCylinder", "DHWCylinderWStorage"],
    text: "Enter the amount of Hot Water this cylinder will supply each day.\nThis number is used to calculate the system's energy usage. \nThe result is provided in the 'Heat Loss Report' export.",
    specialSpec: [],
  },
  SPF: {
    entities: ["DHWCylinder", "DHWCylinderWStorage"],
    text: "This number is used to calculate the system's energy usage. \nThe result is provided in the 'Heat Loss Report' export.",
    specialSpec: [],
  },
  "Legionella Purge Temperature": {
    entities: ["DHWCylinder", "DHWCylinderWStorage"],
    text: "For hygiene purposes, the system will heat sanitise. Please specify the temperature this will occur at. \nThe more frequently this occurs, the more energy the system will use.\nThis number is used to calculate the system's energy usage. \nThe result is provided in the 'Heat Loss Report' export.",
    specialSpec: [],
  },
  "Legionella Purge Frequency": {
    entities: ["DHWCylinder", "DHWCylinderWStorage"],
    text: "How frequently will the system heat sanitise? The more frequently this occurs, the more energy the system will use.\nThis number is used to calculate the system's energy usage. \nThe result is provided in the 'Heat Loss Report' export.",
    specialSpec: [],
  },
  "Domestic Water Load": {
    entities: ["HeatInterfaceUnit"],
    text: "This number is used to calculate the flow rate using the CIBSE District Heating formula.",
    specialSpec: [],
  },

  "Residual Pressure": {
    entities: ["FlowSource"],
    text: "This is the lowest pressure you expect to receive at your connection point.\nThis pressure will be used for calculations relating to the peak demand.",
    specialSpec: [],
  },
  "Static Pressure": {
    entities: ["FlowSource"],
    text: "This is the maximum pressure you expect to receive at your connection point.\nThis pressure will be used for calculations relating to the static demand.",
    specialSpec: [],
  },
  "Top Floor": {
    entities: ["Riser"],
    text: "This is the floor/level that the riser will show up to.\nIt will not show on levels above. ",
    specialSpec: [],
  },
  "Bottom Floor": {
    entities: ["Riser"],
    text: "This is the floor/level that the riser will show down to.\nIt will not show on levels below. ",
    specialSpec: [],
  },
  "Maximum Velocity": {
    entities: ["Pipe", "Riser"],
    text: "This number is the default in the Settings (Flow Systems). \nYou can change the default in the Settings or choose to override it for this specific pipe.",
    specialSpec: [],
  },
  "Maximum Pressure Drop": {
    entities: ["Pipe", "Riser"],
    text: "This number is the default in the Settings (Flow Systems). \nYou can change the default in the Settings or choose to override it for this specific pipe.",
    specialSpec: [],
  },
  Diameter: {
    entities: ["Pipe", "Riser"],
    text: "This is the size of the pipe. \nIf you do not want the software to calculate the size, you can override it and set it yourself.",
    specialSpec: [],
  },
  "Network Type": {
    entities: ["Pipe"],
    text: "The defaults of this pipe come from the Settings of the Network Type.\nYou can choose the defaults in the Settings, or change the Network Type here to use another Network Type's defaults.",
    specialSpec: [],
  },
  "Pressure Drop Method": {
    entities: ["CustomValve"],
    text: "Choose to define a fixed pressure drop or enter a kV value.\nThe kV value will calculate the pressure drop result based on the velocity of the fluid passing through it.",
    specialSpec: [],
  },
  "KV Value": {
    entities: ["Radiator"],
    text: "This is the kV value of the radiator. \nThis number is used to calculate the pressure drop based on the flow rate through the radiator.",
    specialSpec: [],
  },
  "Regulator Outlet Pressure": {
    entities: ["GasRegulator"],
    text: "This is the pressure that is directly on the outlet of the regulator.",
    specialSpec: [],
  },
  "Radiator Type": {
    entities: ["Radiator", "Manifold", "AHU", "FCU"],
    text: "This is whether you want to choose a radiator with a 'Fixed kW', which does not take information from the dimensions.\nOr if you want to calculate the heat supplied from the specified dimensions, you can choose 'Specify'.",
    specialSpec: [],
  },
  "Heat Source Outlet No Selection": {
    entities: ["Radiator"],
    text: "Insert heat source equipments for selection",
    specialSpec: [],
  },
  "Heat Source Outlet Selection": {
    entities: ["Radiator"],
    text: "Default to the closest heat source",
    specialSpec: [],
  },
};

export function getTooltipsEntityName(
  entity: DrawableEntityConcrete,
  drawing: DrawingState,
): TooltipsEntityName | null {
  switch (entity.type) {
    case EntityType.FLOW_SOURCE:
      return "FlowSource";
    case EntityType.CONDUIT:
      return entity.conduitType === "pipe" ? "Pipe" : null;
    case EntityType.RISER:
      return "Riser";
    case EntityType.DIRECTED_VALVE:
      if (entity.valve.type === ValveType.CUSTOM_VALVE) return "CustomValve";
      if (entity.valve.type === ValveType.GAS_REGULATOR) return "GasRegulator";
      return null;
    case EntityType.GAS_APPLIANCE:
      return "GasAppliance";
    case EntityType.LOAD_NODE:
      if (entity.systemUidOption === null) return null;
      const flowsystem = drawing.metadata.flowSystems[entity.systemUidOption];
      return isGas(flowsystem) ? "GasLoadNode" : null;
    case EntityType.PLANT:
      switch (entity.plant.type) {
        case PlantType.RETURN_SYSTEM:
          switch (entity.plant.returnSystemType) {
            case ReturnSystemType.AIR_SOURCE_HEAT_PUMP:
            case ReturnSystemType.GROUND_SOURCE_HEAT_PUMP:
              return "HeatPump";
            case ReturnSystemType.GAS_BOILER:
              return "GasBoiler";
            case ReturnSystemType.DHW_CYLINDER:
              return "DHWCylinder";
            case ReturnSystemType.DHW_CYLINDER_W_STORAGE:
              return "DHWCylinderWStorage";
            case ReturnSystemType.HEADER:
              return "Header";
            case ReturnSystemType.BUFFER_TANK:
              return "BufferTank";
            case ReturnSystemType.HOT_WATER_PLANT_W_RETURN:
              return "HotWaterPlantWReturn";
            case ReturnSystemType.CUSTOM:
              // TODO: find a better way to identify HeatInterfaceUnit
              if (entity.name && entity.name.includes("Heat Interface Unit"))
                return "HeatInterfaceUnit";
            case ReturnSystemType.HEAT_SOURCE:
            case ReturnSystemType.CHILLER:
            case ReturnSystemType.COOLING_TOWER:
              return null;
          }

        case PlantType.VOLUMISER:
          return "Volumiser";
        case PlantType.RADIATOR:
          return "Radiator";
        case PlantType.MANIFOLD:
          return "Manifold";
        case PlantType.AHU:
        case PlantType.AHU_VENT:
          return "AHU";
        case PlantType.FCU:
          return "FCU";

        case PlantType.UFH:
        case PlantType.PUMP:
        case PlantType.TANK:
        case PlantType.PUMP_TANK:
        case PlantType.DRAINAGE_PIT:
        case PlantType.CUSTOM:
        case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
        case PlantType.FILTER:
        case PlantType.RO:
        case PlantType.DUCT_MANIFOLD:
          return null;
        default:
          assertUnreachable(entity.plant);
          return null;
      }

    case EntityType.BACKGROUND_IMAGE:
    case EntityType.FITTING:
    case EntityType.LINE:
    case EntityType.ANNOTATION:
    case EntityType.EDGE:
    case EntityType.WALL:
    case EntityType.VERTEX:
    case EntityType.FENESTRATION:
    case EntityType.ROOM:
    case EntityType.BIG_VALVE:
    case EntityType.SYSTEM_NODE:
    case EntityType.FIXTURE:
    case EntityType.MULTIWAY_VALVE:
    case EntityType.COMPOUND:
    case EntityType.ARCHITECTURE_ELEMENT:
    case EntityType.DAMPER:
    case EntityType.AREA_SEGMENT:
      return null;
    default:
      assertUnreachable(entity);
      return null;
  }
}

function searchSpecialSpec(
  specialSpecs: TooltipsSpecialSpec[],
  entityName: TooltipsEntityName,
): string | null {
  for (const spec of specialSpecs) {
    if (spec.entity === entityName) {
      return spec.text;
    }
  }
  return null;
}

export function getTooltip(
  entityName: TooltipsEntityName | null,
  fieldName: TooltipsFieldName,
): string | undefined {
  if (entityName === null) return undefined;
  if (fieldName in TooltipsTable) {
    const specialRes = searchSpecialSpec(
      TooltipsTable[fieldName].specialSpec,
      entityName,
    );
    if (specialRes) return specialRes;

    const fieldSpec = TooltipsTable[fieldName];
    if (fieldSpec.entities.includes(entityName)) {
      return fieldSpec.text;
    } else return undefined;
  }
  return undefined;
}
