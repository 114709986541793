import { getGPUTier } from "detect-gpu";
import { PERFORMANCE_ARTICLE } from "./help-articles";
import { ProblemCategory } from "./problem-category";
import { Problem } from "./types";

export async function getGPUProblem(): Promise<Problem | undefined> {
  const gpuTierResult = await getGPUTier();
  if (gpuTierResult.tier <= 2) {
    return {
      category: ProblemCategory.Performance,
      details: `
        We have detected that you are using an integrated graphics card. If your
        computer has dedicated graphics card we suggest that you change your
        computer settings to make use of it.
      `,
      helpArticleUrl: PERFORMANCE_ARTICLE,
    };
  }
  return undefined;
}
