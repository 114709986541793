<template>
  <div
    class="inline-flex items-center justify-between px-3 py-1.5 w-fit rounded-lg"
    :class="[...variantClasses]"
  >
    <div class="flex items-center gap-1.5">
      <HIcon v-if="leftIcon" :name="leftIcon" :size="iconSize" />
      <span class="text-sm font-light">
        <slot></slot>
      </span>
    </div>
    <div class="flex items-center gap-1.5">
      <HAnchor
        v-if="action"
        :test-id="`${testId}-action`"
        :context="context"
        :action-name="actionName"
        class="text-sm font-light underline cursor-pointer ml-12"
        :class="actionFontColor"
        @click="$emit('click')"
      >
        {{ action }}
      </HAnchor>
      <HIcon v-if="rightIcon" :name="rightIcon" :size="iconSize" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { Logger } from "../../../../common/src/lib/logger";
import HAnchor from "./HAnchor.vue";
import HIcon from "./HIcon.vue";
import { BannerColor, BannerVariant } from "./banners";
import { Icon, IconSize } from "./icons";

const props = withDefaults(
  defineProps<{
    testId: string;
    variant: BannerVariant;
    color: BannerColor;
    leftIcon?: Icon;
    rightIcon?: Icon;
    iconSize?: IconSize;
    action?: string;
    // See HButton For Details
    context: string;
    // See HButton For Details
    actionName: string;
  }>(),
  {
    variant: "default",
    color: "primary",
    iconSize: "xs",
    leftIcon: undefined,
    rightIcon: undefined,
    action: undefined,
  },
);

if (props.action && (!props.context || !props.actionName)) {
  Logger.error(
    "If 'action' is provided, 'context' and 'actionName' must also be provided.",
  );
}

defineEmits<{
  (e: "click"): void;
}>();

const actionFontColor = computed(() => {
  if (props.variant === "primary") {
    return "text-white";
  }

  switch (props.color) {
    case "default":
      return "text-blue-600";
    case "warning":
      return "text-orange-600";
    case "danger":
      return "text-red-600";
    default:
      return "";
  }
});

const variantClasses = computed(() => {
  const classes: string[] = [];

  switch (props.variant) {
    case "primary":
      switch (props.color) {
        case "default":
          classes.push("bg-blue-600", "fill-white", "text-white");
          break;
        case "warning":
          classes.push("bg-orange-600", "fill-white", "text-white");
          break;
        case "danger":
          classes.push("bg-red-600", "fill-white", "text-white");
          break;
      }
      break;

    case "secondary":
      classes.push("bg-white", "border");
      switch (props.color) {
        case "default":
          classes.push("text-slate-900", "fill-slate-900", "border-slate-300");
          break;
        case "warning":
          classes.push(
            "text-orange-700",
            "fill-orange-700",
            "border-orange-500",
          );
          break;
        case "danger":
          classes.push("text-red-700", "fill-red-700", "border-red-500");
          break;
      }
      break;

    case "soft":
      switch (props.color) {
        case "default":
          classes.push("bg-blue-50", "fill-blue-600", "text-slate-900");
          break;
        case "warning":
          classes.push("bg-orange-50", "fill-orange-600", "text-slate-900");
          break;
        case "danger":
          classes.push("bg-red-50", "fill-red-600", "text-slate-900");
          break;
      }
      break;
  }

  return classes;
});
</script>
