import { Units } from "../../../../lib/measurements";
import { assertUnreachable } from "../../../../lib/utils";
import { MetadataCatalog } from "../../drawing";
import { FieldType } from "../field-type";
import { PropertyField } from "../property-field";
import { PlantConcrete, PlantType } from "./plant-types";

/*
 * CustomManufFields are custom fields that are specific to a manufacturer
 * This gives us flexibility to add new manufacturer fields to a plant entity without
 * having to define a new types and write new code for picking the correct model from the catalog
 *
 * The field uid is used to identify the field in the plant entity and catalog
 * the comparisonFn is used to compare the datasheet value with the chosen plant value
 */

export interface CustomManufField {
  uid: string;
  name: string;
  units: Units;
  type: FieldType.Choice;
  options: { title: string; value: string }[];
  defaultValue: string;
  // function used to compare datasheet value with chosen plant value
  // this is necessary as the datasheet representation of some fields is a comma separated string
  comparisonFn: (catalogValue: any, plantValue: string) => boolean;
}

export function getCustomManufFields(
  plant: PlantConcrete,
  catalog?: MetadataCatalog,
): CustomManufField[] {
  switch (plant.type) {
    case PlantType.FILTER:
      switch (plant.filterType) {
        case "backwash":
          if (plant.manufacturer === "southland") {
            return [
              {
                uid: "screenSizeMicron",
                name: "Screen Size",
                type: FieldType.Choice,
                units: Units.Micron,
                options: [
                  { title: "1", value: "1" },
                  { title: "5", value: "5" },
                  { title: "10", value: "10" },
                  { title: "20", value: "20" },
                  { title: "30", value: "30" },
                  { title: "50", value: "50" },
                  { title: "100", value: "100" },
                  { title: "320", value: "320" },
                  { title: "500", value: "500" },
                ],
                defaultValue: "100",
                comparisonFn: (catalogValue: string, plantValue: string) => {
                  const options = catalogValue.split(",");
                  return options.includes(plantValue);
                },
              },
            ];
          }
          return [];
        case "backwash-rainwater":
          if (plant.manufacturer === "southland") {
            return [
              {
                uid: "operationMode",
                name: "Operation Mode",
                type: FieldType.Choice,
                units: Units.None,
                options: [
                  { title: "Automatic", value: "automatic" },
                  { title: "Manual", value: "manual" },
                ],
                defaultValue: "automatic",
                comparisonFn: (catalogValue: string, plantValue: string) => {
                  return catalogValue === plantValue;
                },
              },
            ];
          }
          return [];
        case "cartridge":
        case "softener":
        case "uv":
          return [];
        default:
          assertUnreachable(plant);
      }
    case PlantType.RADIATOR:
      return [];
    case PlantType.RO:
      if (plant.manufacturer === "southland") {
        return [
          {
            uid: "useCase",
            name: "Use Case",
            type: FieldType.Choice,
            units: Units.None,
            options: [
              {
                title: "4500 L/hour - Renal Dialysis",
                value: "renalDialysis4500L",
              },
              {
                title: "600 L/hour - CSSD/Endoscopy",
                value: "cssdEndoscopy600L",
              },
              {
                title: "900 L/hour - CSSD/Endoscopy",
                value: "cssdEndoscopy900L",
              },
              {
                title: "1500 L/hour - CSSD/Endoscopy",
                value: "cssdEndoscopy1500L",
              },
              {
                title: "2 L/hour - Laboratory/Pathology",
                value: "labPathology2L",
              },
              {
                title: "45 L/hour - Laboratory/Pathology",
                value: "labPathology45L",
              },
              {
                title: "85 L/hour - Laboratory/Pathology",
                value: "labPathology85L",
              },
              {
                title: "200 L/hour - Laboratory/Pathology",
                value: "labPathology200L",
              },
              {
                title: "1500 L/hour - Laboratory/Pathology",
                value: "labPathology1500L",
              },
            ],
            defaultValue: "renalDialysis4500L",
            comparisonFn: (catalogValue: string, plantValue: string) => {
              return catalogValue === plantValue;
            },
          },
        ];
      }
      return [];
    case PlantType.PUMP:
      let manufacturer = plant.manufacturer;
      if (!manufacturer && catalog && catalog.pump.length > 0) {
        manufacturer = catalog.pump[0].manufacturer;
      }
      if (manufacturer === "dutypoint") {
        return [
          {
            uid: "variant",
            name: "Variant",
            type: FieldType.Choice,
            units: Units.None,
            options: [
              { title: "Standard", value: "standard" },
              { title: "Premium", value: "premium" },
            ],
            defaultValue: "premium",
            comparisonFn: (catalogValue: string, plantValue: string) => {
              return catalogValue === plantValue;
            },
          },
        ];
      }
      return [];
    case PlantType.AHU:
    case PlantType.AHU_VENT:
    case PlantType.FCU:
    case PlantType.UFH:
    case PlantType.MANIFOLD:
    case PlantType.RETURN_SYSTEM:
    case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP:
    case PlantType.DRAINAGE_PIT:

    case PlantType.PUMP_TANK:
    case PlantType.TANK:
    case PlantType.VOLUMISER:
    case PlantType.CUSTOM:
    case PlantType.DUCT_MANIFOLD:
      return [];
  }
  assertUnreachable(plant);
}

export function getCustomManufPropertyFields(
  plant: PlantConcrete,
  readOnly: boolean,
): PropertyField[] {
  const manufFields = getCustomManufFields(plant);

  return manufFields.map((field) => {
    switch (field.type) {
      case FieldType.Choice:
        return {
          property: "plant.customManufFields." + field.uid,
          title: field.name,
          hasDefault: !readOnly,
          isCalculated: readOnly,
          type: FieldType.Choice,
          units: field.units,
          params: {
            choices: field.options.map((option) => {
              return {
                name: option.title,
                key: option.value,
              };
            }),
          },
          multiFieldId: "plant-" + field.uid,
          readonly: readOnly,
        };
    }
    assertUnreachable(field.type);
  });
}
