import Vue from "vue";
import { Color } from "../../../common/src/lib/color";
import { SentryError } from "../../../common/src/lib/sentry-error";

export function cssColor2rgba(input: string) {
  const regexRGB = /^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/;
  const regexRGBA = /^rgba\((\d+),\s*(\d+),\s*(\d+),\s*(\d*(?:\.\d+)?)\)$/;
  const regexHex6 = /^#([a-fA-F0-9]{2})([a-fA-F0-9]{2})([a-fA-F0-9]{2})$/;
  const regexHex3 = /^#([a-fA-F0-9])([a-fA-F0-9])([a-fA-F0-9])$/;
  let result,
    r,
    g,
    b,
    a = 1;

  if ((result = regexRGB.exec(input))) {
    r = parseInt(result[1], 10);
    g = parseInt(result[2], 10);
    b = parseInt(result[3], 10);
  } else if ((result = regexRGBA.exec(input))) {
    r = parseInt(result[1], 10);
    g = parseInt(result[2], 10);
    b = parseInt(result[3], 10);
    a = parseFloat(result[4]);
  } else if ((result = regexHex6.exec(input))) {
    r = parseInt(result[1], 16);
    g = parseInt(result[2], 16);
    b = parseInt(result[3], 16);
  } else if ((result = regexHex3.exec(input))) {
    r = parseInt(result[1] + result[1], 16);
    g = parseInt(result[2] + result[2], 16);
    b = parseInt(result[3] + result[3], 16);
  } else {
    // handle named colors, HSL, and other formats
    throw new SentryError("Invalid color format: ", { input });
  }

  return { r: r, g: g, b: b, a: a };
}

export function color2rgb(color: Color): { r: number; g: number; b: number } {
  const num = parseInt(color.hex.substr(1), 16);
  return {
    r: (num >> 16) & 0xff,
    g: (num >> 8) & 0xff,
    b: (num >> 0) & 0xff,
  };
}

export function rgb2style(
  rgb: { r: number; g: number; b: number },
  a?: number,
): string {
  if (a === undefined || a === 1) {
    const str = ((rgb.r << 16) | (rgb.g << 8) | (rgb.b << 0)).toString(16);
    return "#" + str;
  } else {
    return "rgba(" + rgb.r + ", " + rgb.g + ", " + rgb.b + ", " + a + ")";
  }
}

export function lerpColor(a: string, b: string, amount: number) {
  const ah = +a.replace("#", "0x"),
    ar = ah >> 16,
    ag = (ah >> 8) & 0xff,
    ab = ah & 0xff,
    bh = +("0x" + b.substring(1)),
    br = bh >> 16,
    bg = (bh >> 8) & 0xff,
    bb = bh & 0xff,
    rr = ar + amount * (br - ar),
    rg = ag + amount * (bg - ag),
    rb = ab + amount * (bb - ab);

  return (
    "#" + (((1 << 24) + (rr << 16) + (rg << 8) + rb) | 0).toString(16).slice(1)
  );
}

export function lerpCSSColor(a: string, b: string, amount: number) {
  const rgbA = cssColor2rgba(a);
  const rgbB = cssColor2rgba(b);
  const rgb = {
    r: rgbA.r + amount * (rgbB.r - rgbA.r),
    g: rgbA.g + amount * (rgbB.g - rgbA.g),
    b: rgbA.b + amount * (rgbB.b - rgbA.b),
  };
  return rgb2style(rgb, rgbA.a + amount * (rgbB.a - rgbA.a));
}

export function grayscale(col: string) {
  const num = parseInt(col.substr(1), 16);

  let b = num & 0xff;
  let g = (num >> 8) & 0xff;
  let r = (num >> 16) & 0xff;

  const avg = (b + g + r) / 3;

  b = g = r = avg;
  let str = ((r << 16) | (g << 8) | (b << 0)).toString(16);
  while (str.length < 6) {
    str = "0" + str;
  }
  return "#" + str;
}

export const mixColors = (col1: string, col2: string, percent: number) => {
  const num1 = parseInt(col1.substr(1), 16);
  const num2 = parseInt(col2.substr(1), 16);

  const b1 = num1 & 0xff;
  const g1 = (num1 >> 8) & 0xff;
  const r1 = (num1 >> 16) & 0xff;

  const b2 = num2 & 0xff;
  const g2 = (num2 >> 8) & 0xff;
  const r2 = (num2 >> 16) & 0xff;

  const b = Math.min(Math.round(b1 + ((b2 - b1) * percent) / 100), 255);
  const g = Math.min(Math.round(g1 + ((g2 - g1) * percent) / 100), 255);
  const r = Math.min(Math.round(r1 + ((r2 - r1) * percent) / 100), 255);

  let str = ((r << 16) | (g << 8) | (b << 0)).toString(16);
  while (str.length < 6) {
    str = "0" + str;
  }

  return "#" + str;
};

export function getPropertyByString<T extends { [key: string]: any }>(
  obj: T,
  key: Extract<keyof T, string> | string,
  existential: boolean = false,
) {
  if (typeof key !== "string") {
    return obj[key];
  }

  const splitProps = key.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  const strippedLeadingDot = splitProps.replace(/^\./, ""); // strip a leading dot
  const keyList = strippedLeadingDot.split(".");
  for (let i = 0, n = keyList.length; i < n; ++i) {
    const k = keyList[i];
    if (existential) {
      if (obj) {
        obj = obj[k];
      }
    } else {
      if (!obj) {
        throw new SentryError(
          `Property ${k} does not exist in ${obj}`,
          { k },
          { obj: JSON.stringify(obj) },
        );
      }
      obj = obj[k]; // ensure an error is thrown
    }
  }
  return obj;
}

export function setPropertyByStringVue(obj: any, s: string, val: any) {
  s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  s = s.replace(/^\./, ""); // strip a leading dot
  const a = s.split(".");
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i];
    if (i === a.length - 1) {
      Vue.set(obj, k, val);
    } else {
      let newObj = obj[k];
      if (newObj == null) {
        newObj = {};
        Vue.set(obj, k, newObj);
      }
      obj = newObj;
    }
  }
}

export function getNextPipeSize(
  currentSize: number,
  pipeSizes: number[],
): number {
  pipeSizes.sort((a, b) => a - b); // To make sure the sizes are sorted from low to high

  let newPipeSize = 0;
  for (const size of pipeSizes) {
    if (size > currentSize) {
      newPipeSize = size;
      break;
    }
  }

  return newPipeSize;
}

export function getFixedStringValue(
  value: string | number | null,
  fixed: number = 3,
): string {
  if (value == null) {
    return "";
  }
  return parseFloat(Number(value).toFixed(fixed)).toString();
}

export function getCertainDecimalNumber(value: number): number {
  // return +(value).toFixed(12);
  return Math.round(value * 1e12) / 1e12; // support IE
}

export function truncate(item: string, length: number = 16): string {
  if (!item) return item;
  const descLength = item.length;
  if (descLength > length) {
    return item.slice(0, length) + "...";
  }
  return item;
}

export function filterArray(
  array: string[],
  condition: string,
  contain: boolean = false,
): string[] {
  return array.filter((item: string) => {
    return (
      item.toLowerCase().indexOf(condition.toLowerCase()) != -1 &&
      (item.length == condition.length || contain)
    );
  });
}
