import { CoreContext } from "../../calculations/types";
import { FieldType } from "./field-type";
import { TextField } from "./property-field";
import { BaseEdgeEntity, DrawableEntity, NamedEntity } from "./simple-entities";
import { EntityType } from "./types";

export type EdgeContext = "room" | "unheated-area" | "heated-area";

export interface EdgeEntity
  extends DrawableEntity,
    NamedEntity,
    BaseEdgeEntity {
  type: EntityType.EDGE;
  edgeContext: EdgeContext;
}

export function fillDefaultRoomEdgeFields(
  context: CoreContext,
  entity: EdgeEntity,
) {
  return entity;
}

export const ROOM_EDGE_FIELDS = [
  {
    property: "entityName",
    title: "Name",
    hasDefault: false,
    isCalculated: false,
    type: FieldType.Text,
    params: null,
    multiFieldId: "entityName",
  } satisfies TextField,
];
