import mixpanel from "mixpanel-browser";
import { CURRENT_VERSION } from "../../../common/src/api/config";
import {
  AnalyticsPayload,
  getTrackingCooldownMS,
} from "../../../common/src/api/document/analytics/analytics";
import { Logger } from "../../../common/src/lib/logger";
import { Primitive } from "../../../common/src/lib/primitive";
import { SentryError } from "../../../common/src/lib/sentry-error";
import { ENABLE_MIXPANEL } from "../config";
import { useDocument } from "../lib/hooks/use-document";

const lastTracked: Map<AnalyticsPayload["type"], Date> = new Map();

export function trackEvent(payload: AnalyticsPayload) {
  const canRateLimit = getTrackingCooldownMS(payload.type) > 0;

  if (canRateLimit && lastTracked.has(payload.type)) {
    const msSinceEpoch = lastTracked.get(payload.type)?.getTime();
    const canTrackAfterTime = msSinceEpoch
      ? msSinceEpoch + getTrackingCooldownMS(payload.type)
      : undefined;
    if (canTrackAfterTime && canTrackAfterTime > Date.now()) {
      console.log("Tracking Skipped (Rate Limited):", payload.type);
      return;
    }
  }

  const properties = {
    ...getUiStateProps(),
    ...("props" in payload ? payload.props : {}),
  };

  try {
    if (ENABLE_MIXPANEL) {
      mixpanel.track(payload.type, properties);
    } else {
      console.log("Tracking Skipped (Mixpanel Not Enabled)");
    }
    console.debug("Tracking Payload", payload.type, properties);
    lastTracked.set(payload.type, new Date());
  } catch (error) {
    Logger.error(
      SentryError.wrapError("Tracking Failed:", error, { type: payload.type }),
    );
  }
}

export function getUiStateProps(): Record<string, Primitive | undefined> {
  const document = useDocument();

  return {
    documentId: String(document.documentId),
    documentTitle: document.drawing.metadata.generalInfo.title,
    activeFlowSystemUid: document.activeflowSystemUid,
    historyIndex: document.uiState.historyIndex,
    tool: document.uiState.toolHandlerName ?? undefined,
    drawingLayout: document.uiState.drawingLayout,
    drawingMode: document.uiState.drawingMode,
    level: document.uiState.levelUid ?? undefined,
    opId: document.nextId - 1 + document.optimisticHistory.length,
    documentVersion: CURRENT_VERSION,
  };
}
