import { NullablePrimitive } from "./primitive";
import { SentryError } from "./sentry-error";

const SENTRY_ENTITY_ERROR_TYPE = "sentry-entity";

/**
 * Extend this Error class to ensure good sentry error reporting for Errors.
 *
 * @param message The Title of the error in sentry
 * @param entityUid The unique identifier of the entity this error is related to. We will add extra context from global store to this error.
 * @param tags Sentry tags, these are meant to be short, and are indexed, and searchable in sentry,
 *     that we may want so see what percentage are caused by x. Think project id, entity type etc.
 * @param extras Sentry extras, these are more of the nitty gritty details about the error, that are more nuanced.
 */
export class SentryEntityError extends SentryError {
  errorType = SENTRY_ENTITY_ERROR_TYPE;

  constructor(
    msg: string,
    readonly entityUid: string,
    tags: Record<string, NullablePrimitive> = {},
    extras: Record<string, any> = {},
    cause?: unknown,
  ) {
    super(msg, tags, extras, cause);
  }
}

export function isSentryEntityError(error: any): error is SentryEntityError {
  if (!error) {
    return false;
  }

  return (
    typeof error === "object" &&
    "errorType" in error &&
    (error["errorType"] as string).includes(SENTRY_ENTITY_ERROR_TYPE)
  );
}
