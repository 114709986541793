import { Units, UnitsContext } from "../../../lib/measurements";
import { cloneSimple } from "../../../lib/utils";
import { CoreContext } from "../../calculations/types";
import { FieldType } from "./field-type";
import { PropertyField } from "./property-field";
import { DrawableEntity, NamedEntity, TerminusEntity } from "./simple-entities";
import { EntityType } from "./types";

export const MINIMUM_DAMPER_PRESSURE_DROP_KPA = 0.035;

export default interface DamperEntity
  extends DrawableEntity,
    NamedEntity,
    TerminusEntity {
  type: EntityType.DAMPER;
  minPressureDropKPA: number | null;
}

export function makeDamperFields(
  context: CoreContext,
  entity: DamperEntity,
): PropertyField[] {
  const res: PropertyField[] = [
    {
      property: "entityName",
      title: "Name",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Text,
      params: null,
      multiFieldId: "name",
    },
    {
      property: "minPressureDropKPA",
      title: "Min. Pressure Drop",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: "minPressureDropKPA",
      unitContext: UnitsContext.VENTILATION,
      units: Units.KiloPascals,
    },
  ];

  return res;
}

export function fillDefaultDamperFields(
  context: CoreContext,
  entity: DamperEntity,
): DamperEntity {
  const result = cloneSimple(entity);

  if (result.minPressureDropKPA === null) {
    result.minPressureDropKPA = MINIMUM_DAMPER_PRESSURE_DROP_KPA;
  }

  return result;
}
