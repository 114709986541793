import { AuthSession, fetchAuthSession } from "aws-amplify/auth";
import axios from "axios";
import Vue from "vue";
import Router, { NavigationGuard, RawLocation } from "vue-router";
import { AllOrgProperties } from "../../common/src/api/allOrgProperties";
import { AllUserProperties } from "../../common/src/api/allUserProperties";
import { OrganizationType } from "../../common/src/models/Organization";
import {
  AccessLevel,
  User as IUser,
  LATEST_EULA_VERSION,
} from "../../common/src/models/User";
import { getSession } from "../src/api/logins";
import ChangePassword from "../src/views/ChangePassword.vue";
import LoadProfile from "../src/views/LoadProfile.vue";
import { getAutoRefreshedAccessToken } from "./auth/auth-utils";
import {
  isFeatureFlagDisabled,
  isFeatureFlagEnabled,
} from "./lib/hooks/use-feature-flag";
import { FrontendFeatureFlags } from "./store/feature-flags/types";
import store from "./store/store";
import ActiveUsers from "./views/ActiveUsers.vue";
import CreateOrganization from "./views/CreateOrganization.vue";
import CreateUser from "./views/CreateUser.vue";
import Login from "./views/Login.vue";
import Organization from "./views/Organization.vue";
import ResetPassword from "./views/ResetPassword.vue";
import SignUp from "./views/SignUp.vue";
import User from "./views/User.vue";
import Users from "./views/Users.vue";
import AdminSubscriptions from "./views/admin/AdminSubscriptions.vue";
Vue.use(Router);

export type ProfileWithMFA = IUser & { mfaStatus: boolean };

function getProfile(): ProfileWithMFA | undefined {
  return store.getters["profile/profile"];
}

const HOME_ROUTES: Record<
  OrganizationType,
  Record<AccessLevel, RawLocation>
> = {
  [OrganizationType.MANUFACTURER]: {
    [AccessLevel.ADMIN]: { name: "home" },
    [AccessLevel.SUPERUSER]: { name: "home" },
    [AccessLevel.MANAGER]: { name: "manufacturer/dashboard" },
    [AccessLevel.USER]: { name: "manufacturer/dashboard" },
  },

  [OrganizationType.BUILDING_SERVICES]: {
    [AccessLevel.ADMIN]: { name: "home" },
    [AccessLevel.SUPERUSER]: { name: "home" },
    [AccessLevel.MANAGER]: { name: "home" },
    [AccessLevel.USER]: { name: "home" },
  },
};

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/integrations",
      name: "integrations",
      component() {
        return import(
          /* webpackChunkName: "layout-base" */ "./views/layout/Base.vue"
        );
      },
      children: [
        {
          path: "canny",
          name: "canny",
          component: () =>
            import(
              /* webpackChunkName: "integrations/canny" */ "./views/integrations/CannySSO.vue"
            ),
          meta: {
            auth: true,
            title: "Canny | h2x",
          },
        },
      ],
    },
    {
      path: "/clone-project",
      name: "clone-project",
      component: () =>
        import(
          /* webpackChunkName: "clone-project" */ "./components/clone-remote/RemoteCloneForm.vue"
        ),
      meta: {
        auth: true,
        roles: [OrganizationType.BUILDING_SERVICES],
        title: "Clone Project | h2x",
      },
    },
    {
      path: "/onboard",
      name: "onboard",
      meta: {
        auth: true,
        roles: [
          OrganizationType.BUILDING_SERVICES,
          OrganizationType.MANUFACTURER,
        ],
        title: "Welcome | h2x",
      },
      component: () =>
        import(/* webpackChunkName: "onboard" */ "./views/OnboardLanding.vue"),
    },
    {
      path: "/aha-moment",
      name: "Aha Moment",
      meta: {
        title: "Aha Moment | h2x",
      },
      component: () =>
        import(/* webpackChunkName: "aha-moment" */ "./views/AhaMoment.vue"),
    },
    {
      path: "/aha-moment-pro",
      name: "Aha Moment Pro",
      meta: {
        title: "Aha Moment Pro | h2x",
      },
      component: () =>
        import(/* webpackChunkName: "aha-moment" */ "./views/AhaMomentPro.vue"),
    },
    {
      path: "/",
      name: "home",
      component: () =>
        import(/* webpackChunkName: "home" */ "./views/Home.vue"),
      meta: {
        auth: true,
        roles: [OrganizationType.BUILDING_SERVICES],
        title: "Home | h2x",
      },
      props: (route) => ({
        samples: route.query.samples,
        learn: route.query.learn,
      }),
    },
    {
      path: "/signup-flow",
      name: "signup-flow",
      component: () =>
        import(
          /* webpackChunkName: "signup-flow" */ "./views/signup-flow/SignUpFlowContainer.vue"
        ),
      meta: {
        auth: true,
        title: "Signup Flow | h2x",
      },
    },
    {
      name: "document",
      path: "/document/:id/:mode?",
      // props: true,
      props(route) {
        const id = Number.parseInt(route.params.id, 10) || 0;
        return { id };
      },
      component: () =>
        import(/* webpackChunkName: "document" */ "./views/Document.vue"),

      children: [
        {
          path: "",
          name: "drawing",
          component: () =>
            import(/* webpackChunkName: "drawing" */ "./views/Canvas.vue"),
          meta: {
            title: null,
          },
        },
        {
          path: "settings2",
          name: "settings2",
          component: () =>
            import(
              /* webpackChunkName: "ProjectSettings2" */ "./views/settings2/ProjectSettings2.vue"
            ),
          meta: {
            auth: true,
            title: "Setting | h2x",
          },
          children: [
            {
              path: "workflows",
              name: "settings2/workflows",
              component: () =>
                import(
                  /* webpackChunkName: "WorkflowsSettings2" */ "./views/settings2/WorkflowsSettings2.vue"
                ),
            },
            {
              path: "info",
              name: "settings2/info",
              component: () =>
                import(
                  /* webpackChunkName: "InfoSettings2" */ "./views/settings2/InfoSettings2.vue"
                ),
            },
            {
              path: "units",
              name: "settings2/units",
              component: () =>
                import(
                  /* webpackChunkName: "UnitsSettings2" */ "./views/settings2/UnitsSettings2.vue"
                ),
            },
            {
              path: "methods",
              name: "settings2/methods",
              component: () =>
                import(
                  /* webpackChunkName: "MethodsSettings2" */ "./views/settings2/MethodsSettings2.vue"
                ),
            },
            {
              path: "parameters/:activeSystem?",
              name: "settings2/parameters",
              props: true,
              component: () =>
                import(
                  /* webpackChunkName: "ParametersSettings2" */ "./views/settings2/ParametersSettings2.vue"
                ),
            },
            {
              path: "heatload",
              name: "settings2/heatload",
              component: () =>
                import(
                  /* webpackChunkName: "HeatLoadSettings2" */ "./views/settings2/HeatLoadSettings2.vue"
                ),
              children: [
                {
                  path: "general",
                  name: "settings2/heatload/general",
                  component: () =>
                    import(
                      /* webpackChunkName: "HeatLoadGeneralSettings2" */ "./views/settings2/heatLoad/HeatLoadGeneralSettings2.vue"
                    ),
                  children: [],
                },
                {
                  path: "energyusage",
                  name: "settings2/heatload/energyusage",
                  component: () =>
                    import(
                      /* webpackChunkName: "HeatLoadEnergyUsageSettings2" */ "./views/settings2/heatLoad/HeatLoadEnergyUsageSettings2.vue"
                    ),
                  children: [],
                },
                {
                  path: "materials/:role?/:name?",
                  name: "settings2/heatload/materials",
                  props: true,
                  component: () =>
                    import(
                      /* webpackChunkName: "HeatLoadMaterialSettings2" */ "./views/settings2/heatLoad/HeatLoadMaterialSettings2.vue"
                    ),
                  children: [],
                },
                {
                  path: "rooms",
                  name: "settings2/heatload/rooms",
                  component: () =>
                    import(
                      /* webpackChunkName: "HeatLoadRoomSettings2" */ "./views/settings2/heatLoad/HeatLoadRoomSettings2.vue"
                    ),
                  children: [],
                },
                {
                  path: "vaultedceilings",
                  name: "settings2/heatload/vaultedceilings",
                  component: () =>
                    import(
                      /* webpackChunkName: "HeatLoadRoofSettings2" */ "./views/settings2/heatLoad/HeatLoadRoofSettings2.vue"
                    ),
                  children: [],
                },
                {
                  path: "internalheatsources",
                  name: "settings2/heatload/internalheatsources",
                  component: () =>
                    import(
                      /* webpackChunkName: "HeatLoadInternalHeatSourcesSettings2" */ "./views/settings2/heatLoad/HeatLoadInternalHeatSourcesSettings2.vue"
                    ),
                  children: [],
                },
                {
                  path: "solargain",
                  name: "settings2/heatload/solargain",
                  component: () =>
                    import(
                      /* webpackChunkName: "HeatLoadSolarGainSettings2" */ "./views/settings2/heatLoad/HeatLoadSolarGainSettings2.vue"
                    ),
                  children: [],
                },
              ],
            },
            {
              path: "nodes",
              name: "settings2/nodes",
              component: () =>
                import(
                  /* webpackChunkName: "NodesSettings2" */ "./views/settings2/NodesSettings2.vue"
                ),
              children: [
                {
                  path: "pressure",
                  name: "settings2/nodes/pressure",
                  component: () =>
                    import(
                      /* webpackChunkName: "PressureNodes" */ "./views/settings/nodes/PressureNodes.vue"
                    ),
                },
                {
                  path: "mechanical",
                  name: "settings2/nodes/mechanical",
                  component: () =>
                    import(
                      /* webpackChunkName: "MechanicalNodes" */ "./views/settings/nodes/MechanicalNodes.vue"
                    ),
                },
                {
                  path: "fire",
                  name: "settings2/nodes/fire",
                  component: () =>
                    import(
                      /* webpackChunkName: "FireNodes" */ "./views/settings/nodes/FireNodes.vue"
                    ),
                },
              ],
            },
            {
              path: "hotkeys",
              name: "settings2/hotkeys",
              component: () =>
                import(
                  /* webpackChunkName: "HotKeysSettings2" */ "./views/settings2/HotKeysSettings2.vue"
                ),
            },
            {
              path: "metapdf",
              name: "settings2/metapdf",
              component: () =>
                import(
                  /* webpackChunkName: "MetaPDFSettings2" */ "./views/settings2/meta-pdf/MetaPDFSettings2.vue"
                ),
            },
            {
              path: "catalog/:prop?",
              name: "settings2/catalog",
              component: () =>
                import(
                  /* webpackChunkName: "CatalogView" */ "./views/catalog/CatalogView.vue"
                ),
            },
            {
              path: "marketplace/create",
              name: "settings2/marketplace/create",
              component: () =>
                import(
                  /* webpackChunkName: "marketplace" */ "./views/settings/marketplace/MarketplaceCreate.vue"
                ),
              meta: {
                auth: true,
                minAccessLevel: AccessLevel.USER,
                title: "Marketplace | h2x",
              },
            },
            {
              path: "marketplace/:view?",
              name: "settings2/marketplace",
              component: () =>
                import(
                  /* webpackChunkName: "marketplace" */ "./views/settings/marketplace/Marketplace.vue"
                ),
              meta: {
                auth: true,
                minAccessLevel: AccessLevel.USER,
                title: "Marketplace | h2x",
              },
            },
            {
              path: "marketplace/item/:itemVersionId",
              name: "settings2/marketplace/item",
              component: () =>
                import(
                  /* webpackChunkName: "marketplace" */ "./views/settings/marketplace/MarketplaceItem.vue"
                ),
              meta: {
                auth: true,
                minAccessLevel: AccessLevel.USER,
                title: "Marketplace | h2x",
              },
            },
            {
              path: "marketplace/item/:itemVersionId/new-version",
              name: "settings2/marketplace/item/new-version",
              component: () =>
                import(
                  /* webpackChunkName: "marketplace" */ "./views/settings/marketplace/MarketplaceNewVersion.vue"
                ),
              meta: {
                auth: true,
                minAccessLevel: AccessLevel.USER,
                title: "Marketplace | h2x",
              },
            },
            {
              path: "budget/:prop?",
              name: "settings2/budget",
              component: () =>
                import(
                  /* webpackChunkName: "document" */ "./views/catalog/PriceTableView.vue"
                ),
            },
            {
              path: "import",
              name: "settings2/import",
              component: () =>
                import(
                  /* webpackChunkName: "ImportSettings2" */ "./views/settings2/ImportSettings2.vue"
                ),
            },
            {
              path: "linetypes",
              name: "settings2/linetypes",
              component: () =>
                import(
                  /* webpackChunkName: "LineTypeSettings2" */ "./views/settings2/LineTypeSettings2.vue"
                ),
            },
            {
              path: "debug",
              name: "settings2/debug",
              component: () =>
                import(
                  /* webpackChunkName: "Debug" */ "./views/settings/Debug.vue"
                ),
            },
          ],
        },
      ],

      meta: {
        auth: true,
        roles: [OrganizationType.BUILDING_SERVICES],
        title: "Document | h2x",
      },
    },
    {
      path: "/admin",
      name: "admin",
      component: () =>
        import(/* webpackChunkName: "admin" */ "./views/layout/Base.vue"),
      children: [
        {
          path: "manufacturer-associations",
          name: "admin/manufacturer-associations",
          component: () =>
            import(
              /* webpackChunkName: "manufacturer-associations" */ "./views/admin/ManufacturerAssociations.vue"
            ),
        },
        {
          path: "subscriptions",
          name: "admin/subscriptions",
          component: AdminSubscriptions,
        },
        {
          path: "tools",
          name: "admin/tools",
          component: () =>
            import(
              /* webpackChunkName: "admin-tools" */ "./views/admin/AdminTools.vue"
            ),
        },
      ],
      meta: {
        title: "admin | h2x",
        minAccessLevel: AccessLevel.ADMIN,
        auth: true,
      },
    },
    {
      path: "/organizations",
      name: "organizations",
      component: () => {
        if (isFeatureFlagEnabled("release-new-organizations-list")) {
          return import(
            /* webpackChunkName: "Organizations" */ "./views/Organizations.vue"
          );
        }
        return import(
          /* webpackChunkName: "OrganizationsLegacy" */ "./views/OrganizationsLegacy.vue"
        );
      },
      meta: {
        auth: true,
        minAccessLevel: AccessLevel.ADMIN,
        title: "Organizations | h2x",
      },
    },
    {
      path: "/organizations/create",
      name: "createOrganization",
      component: CreateOrganization,

      meta: {
        auth: true,
        minAccessLevel: AccessLevel.ADMIN,
        title: "Organizations | h2x",
      },
    },
    {
      path: "/organizations/id/:id",
      name: "organization",
      component: Organization,

      meta: {
        auth: true,
        title: "Organizations | h2x",
      },
    },
    {
      path: "/organizations/id/:id/subscription",
      name: "organization-subscription",
      component: () =>
        import(
          /* webpackChunkName: "organization-subscription" */ "./views/subscriptions/ViewSubscription.vue"
        ),

      meta: {
        auth: true,
        title: "Subscription | h2x",
      },
    },
    {
      path: "/subscribe",
      name: "subscribe",
      component: () =>
        import(
          /* webpackChunkName: "pricing" */ "./views/subscriptions/Pricing.vue"
        ),
      meta: {
        auth: true,
        title: "Subscribe | h2x",
      },
    },
    {
      path: "/users",
      name: "users",
      component: Users,

      meta: {
        auth: true,
        minAccessLevel: AccessLevel.MANAGER,
        title: "Admin | h2x",
      },
    },
    {
      path: "/user-management",
      name: "user-management",
      component: () =>
        import(
          /* webpackChunkName: "user-management" */ "./views/UserManagement.vue"
        ),
      meta: {
        auth: true,
        minAccessLevel: AccessLevel.MANAGER,
        title: "User Management | h2x",
        requiresFeatureFlag: "release-account-automation-user-management",
      },
    },
    {
      path: "/active-users",
      name: "activeUsers",
      component: ActiveUsers,

      meta: {
        auth: true,
        minAccessLevel: AccessLevel.ADMIN,
        title: "Active Users | Admin | h2x",
      },
    },
    {
      path: "/users/create",
      name: "createUsers",
      component: CreateUser,

      meta: {
        auth: true,
        minAccessLevel: AccessLevel.MANAGER,
        title: "Create Users | h2x",
      },
    },
    {
      path: "/users/username/:id",
      name: "user",
      component: User,
      meta: {
        auth: true,
        needsMFA: false,
        minAccessLevel: AccessLevel.USER,
        title: "User | h2x",
      },
    },
    {
      path: "/singUp",
      redirect: { name: "signUp" },
    },
    {
      path: "/signUp",
      name: "signUp",
      component: SignUp,
      meta: {
        title: "Sign Up | h2x",
      },
    },
    {
      path: "/resetPassword",
      name: "resetPassword",
      component: ResetPassword,
      meta: {
        title: "Reset Password | h2x",
      },
    },
    {
      path: "/login",
      name: "login",
      //@ts-ignore
      component: Login,
      meta: {
        title: "Log in | h2x",
      },
      props: (route) => ({
        next: route.query.next,
      }),
    },
    {
      path: "/login/complete",
      name: "loginComplete",
      component: LoadProfile,
      meta: {
        title: "Profile | h2x",
      },
    },
    {
      path: "/changePassword",
      name: "changePassword",
      component: ChangePassword,
      meta: {
        auth: true,
        needsEula: false,
        title: "Change password | h2x",
      },
    },
    {
      path: "/eula",
      name: "eula",
      component: () =>
        import(/* webpackChunkName: "eula" */ "./views/Eula.vue"),
      meta: {
        auth: true,
        needsEula: false,
        needsMFA: false,
        title: "License Agreement | h2x",
      },
    },
    {
      path: "/mfa",
      name: "mfa",
      component: () =>
        import(/* webpackChunkName: "enforceMfa" */ "./views/EnforceMFA.vue"),
      meta: {
        auth: true,
        needsMFA: false,
        title: "License Agreement | h2x",
      },
    },
    {
      path: "/:shareToken",
      props: true,
      meta: {
        auth: "optional",
      },
      component: () =>
        import(/* webpackChunkName: "document" */ "./views/Document.vue"),
      children: [
        {
          path: "",
          name: "sharedDocument",
          component: () =>
            import(/* webpackChunkName: "drawing" */ "./views/Canvas.vue"),
        },
      ],
    },
    {
      path: "/sandbox/:shareToken",
      props: (route) => ({
        shareToken: route.params.shareToken,
        sandbox: true,
      }),
      meta: {
        auth: "optional",
      },
      component: () =>
        import(/* webpackChunkName: "document" */ "./views/Document.vue"),
      children: [
        {
          path: "",
          name: "sharedDocument2",
          component: () =>
            import(/* webpackChunkName: "drawing" */ "./views/Canvas.vue"),
          meta: {
            title: null,
          },
        },
      ],
    },
    {
      path: "/manufacturer",
      name: "manufacturer",
      component() {
        return import(
          /* webpackChunkName: "layout-base" */ "./views/layout/Base.vue"
        );
      },
      children: [
        {
          path: "dashboard",
          name: "manufacturer/dashboard",
          component() {
            return import(
              /* webpackChunkName: "manufacturer-dashboard" */ "./views/manufacturer/Dashboard.vue"
            );
          },
          meta: {
            title: "Dashboard | h2x",
          },
        },

        {
          path: "projects",
          name: "manufacturer/projects",
          component() {
            return import(
              /* webpackChunkName: "manufacturer-projects" */ "./views/manufacturer/Projects.vue"
            );
          },
          meta: {
            title: "Specified Projects | h2x",
          },
        },
        {
          path: "projects2",
          name: "manufacturer/projects2",
          component() {
            return import(
              /* webpackChunkName: "manufacturer-projects" */ "./views/manufacturer/Projects2.vue"
            );
          },
        },
      ],
      meta: {
        auth: true,
        roles: [OrganizationType.MANUFACTURER],
      },
    },
    {
      path: "/new-project",
      name: "new-project",
      component: () =>
        import(/* webpackChunkName: "new-project" */ "./views/NewProject.vue"),
      meta: {
        auth: true,
        roles: [OrganizationType.BUILDING_SERVICES],
        title: "Project Set Up | h2x",
      },
      children: [
        {
          path: "/new-project/general",
          name: "new-project/general",
          component: () =>
            import(
              /* webpackChunkName: "new-project-general" */ "./views/new-project/NewProjectGeneral.vue"
            ),
        },
        {
          path: "/new-project/settings",
          name: "new-project/settings",
          component: () =>
            import(
              /* webpackChunkName: "new-project-settings" */ "./views/new-project/NewProjectSettings.vue"
            ),
        },
        {
          path: "/new-project/workflows",
          name: "new-project/workflows",
          component: () =>
            import(
              /* webpackChunkName: "new-project-workflows" */ "./views/new-project/NewProjectWorkflows.vue"
            ),
        },
        {
          path: "/new-project/levels",
          name: "new-project/levels",
          component: () =>
            import(
              /* webpackChunkName: "new-project-levels" */ "./views/new-project/NewProjectLevels.vue"
            ),
        },
        {
          path: "/new-project/pdfs",
          name: "new-project/pdfs",
          component: () =>
            import(
              /* webpackChunkName: "new-project-pdfs" */ "./views/new-project/NewProjectPDFs.vue"
            ),
        },
      ],
    },
  ],
});

const featureFlagGuard: NavigationGuard = (to, from, next) => {
  // Check if the route requires a feature flag
  const matchedRecord = to.matched.find(
    (record) => record.meta.requiresFeatureFlag,
  );

  if (matchedRecord) {
    const flagName = matchedRecord.meta
      .requiresFeatureFlag as keyof FrontendFeatureFlags;

    if (flagName) {
      if (isFeatureFlagDisabled(flagName)) {
        next({ name: "home" });
        return;
      }
    }
  }

  next();
};

const beforeEachGuard: NavigationGuard = async (to, from, next) => {
  const handleNewProjectRouting = (() => {
    const reset = () => store.commit("projectSetup/reset");
    const isFromNewProject = from.name?.startsWith("new-project");
    const isToNewProject = to.name?.startsWith("new-project");
    const isToDocument = to.name?.startsWith("drawing");
    if (isToNewProject && !isFromNewProject) {
      // if routing to new project wizard from OUTSIDE the new project wizard, always land on the first sub-route of the wizard
      if (to.name !== "new-project/general") {
        return next({ name: "new-project/general", hash: undefined }); // Use next to redirect
      }
      return true;
    }
    if (!isFromNewProject) return true; // defacto allow any routing not to do with new-project
    if (isToNewProject) return true; // defacto allow any routing into new-project
    if (isToDocument) {
      reset();
      return true; // allow routing to any document... assuming that the document is newly created here. edge case if user somehow teleports to an existing one
    }
    // route is fromNewProject and !toNewProject, ie. we are leaving the new-project flow
    const confirmed = confirm(
      "It looks like you are leaving the project setup form. Are you sure you want to leave? Any data you have entered will be lost.",
    );
    if (confirmed) {
      reset();
    }
    return confirmed;
  })();
  if (!handleNewProjectRouting) return; // deny proposed route change

  if (to.matched.some((record) => record.meta.auth)) {
    const isAuthOptional = to.matched.every(
      (record) => !record.meta.auth || record.meta.auth === "optional",
    );

    let session: AuthSession | null = null;
    try {
      // check to see if user is logged in
      session = await fetchAuthSession();
      axios.interceptors.request.use(async (config) => {
        const accessToken = await getAutoRefreshedAccessToken();
        if (accessToken) {
          config.headers["access-token"] = accessToken;
        }
        return config;
      });
    } catch (e) {
      console.log("no session", e);
    }
    const sessionEffectivelyNull =
      session == null ||
      (!session.credentials &&
        !session.identityId &&
        !session.tokens &&
        !session.userSub);
    if (sessionEffectivelyNull) {
      // If auth is optional, just go to the page.
      if (isAuthOptional) {
        next();
      } else {
        if (to.fullPath && to.fullPath != "/") {
          next({
            path: "/login",
            query: { next: to.fullPath },
          });
        } else {
          next("/login");
        }
      }
    } else {
      const levels = to.matched
        .filter((r) => "minAccessLevel" in r.meta)
        .map((r) => r.meta.minAccessLevel);
      const requiredAccess = Math.min(...levels);
      const rolesAllowed: Record<OrganizationType, boolean> = {
        [OrganizationType.MANUFACTURER]: true,
        [OrganizationType.BUILDING_SERVICES]: true,
      };
      to.matched.forEach((r) => {
        if ("roles" in r.meta) {
          for (const key of Object.keys(
            rolesAllowed,
          ) as any as OrganizationType[]) {
            rolesAllowed[key] =
              rolesAllowed[key] && r.meta.roles.includes(Number(key));
          }
        }
      });

      const profile = getProfile();

      if (profile == null) {
        getSession().then((res) => {
          if (res.success === true) {
            const result = res.data as IUser;
            store.dispatch("profile/setProfile", result).then(() => {
              if (
                !isAuthOptional &&
                requiredAccess !== undefined &&
                store.getters["profile/profile"].accessLevel > requiredAccess
              ) {
                router.push("/login");
              } else {
                beforeEachGuard(to, from, next); // we  can't use push becuase it breaks browser navigation in new router v
                // router.push(to as RawLocation); // use push.to to go through navigation guards again.
              }
            });
          } else {
            Vue.$cookies.remove("session-id");
            if (isAuthOptional) {
              next();
            } else {
              next("/login");
            }
          }
        });
      } else {
        const needsEula =
          to.matched.filter(
            (r) =>
              r.meta &&
              r.meta.auth &&
              (r.meta.needsEula === undefined || r.meta.needsEula),
          ).length > 0;

        if (needsEula && profile.eulaAcceptedVersion !== LATEST_EULA_VERSION) {
          router.push({ name: "eula" });
          return;
        }

        const needsMFA =
          to.matched.filter(
            (r) =>
              r.meta &&
              r.meta.auth &&
              (r.meta.needsMFA === undefined || r.meta.needsMFA),
          ).length > 0;
        if (
          needsMFA &&
          profile.organization?.enforceMFA &&
          profile.mfaStatus !== true
        ) {
          router.push({ name: "mfa" });
          return;
        }

        const needsToRouteToSignUpFlow = await (async () => {
          if (to.name !== "home") return false; // // defensive, only capture on home (otherwise may interfere with login, signup etc)
          if (isFeatureFlagDisabled("release-signup-flow-questionnaire"))
            return false;
          const properties: Partial<AllOrgProperties> = await store.dispatch(
            "organization/fetchProperties",
            ["signUpFlowCompleted"],
          );
          return !properties.signUpFlowCompleted;
        })();

        if (needsToRouteToSignUpFlow) {
          return next({ name: "signup-flow" });
        }

        const needToRerouteToOnboarding = await (async () => {
          if (to.name !== "home") return false; // defensive, only capture on home (otherwise may interfere with login, signup etc)
          const properties: Partial<AllUserProperties> = await store.dispatch(
            "profile/getProperties",
            ["onboardingActive"],
          );
          return properties.onboardingActive === true;
        })();

        if (needToRerouteToOnboarding) {
          return next({ name: "onboard" });
        }

        if (
          requiredAccess !== undefined &&
          profile.accessLevel > requiredAccess
        ) {
          router.push("/login");
          return;
        }

        if (
          profile.organization &&
          !rolesAllowed[profile.organization?.type] &&
          profile.accessLevel > AccessLevel.ADMIN
        ) {
          next(HOME_ROUTES[profile.organization.type][profile.accessLevel]);

          return;
        }

        next();
      }
    }
  } else {
    next();
  }
};

router.beforeEach(beforeEachGuard);
router.beforeEach(featureFlagGuard);

router.beforeEach((to, from, next) => {
  // Get the page title from the route meta data that we have defined
  if (to.meta && to.meta.title === null) {
    next();
    return;
  }
  const ob = to.matched.filter((record) => {
    return record && record.meta && record.meta.title;
  });
  if (ob && ob.length !== 0) {
    document.title = ob[ob.length - 1].meta.title;
  } else {
    document.title = "h2x Exponential Engineering";
  }
  // If the route has a title, set it as the page title of the document/page
  // Continue resolving the route
  next();
});

export default router;
