import CoreBaseBackedObject from "../../../../common/src/api/coreObjects/lib/coreBaseBackedObject";
import { isCalculated } from "../../../../common/src/api/document/calculations-objects";
import { FieldType } from "../../../../common/src/api/document/entities/field-type";
import {
  FlatPropertyFields,
  isChoiceFieldConcrete,
} from "../../../../common/src/api/document/entities/property-field";
import {
  isFieldEmpty,
  isInvalidChoice,
  isInvalidFlowSystemChoice,
} from "../../../../common/src/api/document/entities/property-field-validation";
import { DrawableEntity } from "../../../../common/src/api/document/entities/simple-entities";
import { getPropertyByString } from "../../../../common/src/lib/utils";
import { globalStore } from "../../store/globalCoreContext";
import { ProblemCategory } from "./problem-category";
import { EntityProblem, Problem } from "./types";

export function getValidationProblems(): Problem[] {
  return [...globalStore.values()].flatMap((obj) => validateCoreObj(obj));
}

export function validateCoreObj(obj: CoreBaseBackedObject): EntityProblem[] {
  // TODO: Catch when calcs are set to the empty default via getOrCreateLiveCalculation
  // This only really matters when entity filling depends on live calcs, but we are being overly cautious here
  if (
    isCalculated(obj.entity) &&
    obj.context.globalStore.getLiveCalculation(obj.entity) === undefined
  ) {
    return [];
  }

  try {
    const props = obj.flatProperties;
    return validateFields(props, obj, obj.filledEntity);
  } catch (e) {
    return [
      {
        details: "Cannot Read Properties, or Fill Defaults for entity",
        developerDetails: (e as Error).message,
        obj: obj,
        category: ProblemCategory.BrokenEntity,
      },
    ];
  }
}

export function validateFields(
  fields: FlatPropertyFields[],
  obj: CoreBaseBackedObject,
  filled: DrawableEntity,
): EntityProblem[] {
  return fields
    .filter((x) => x.isShown !== false)
    .flatMap((field) => validate(field, obj, filled));
}

export function validate(
  field: FlatPropertyFields,
  obj: CoreBaseBackedObject,
  filled: DrawableEntity,
): EntityProblem[] {
  const problems: EntityProblem[] = [];

  if (field.requiresInput && isFieldEmpty(field, obj)) {
    problems.push({
      category: ProblemCategory.MissingValue,
      details: `${field.title} is a required field. Please provide a value.`,
      property: field,
      obj: obj,
    });
  }

  if (field?.validationRules) {
    const value = getPropertyByString(filled, field.property);
    problems.push(
      ...field.validationRules
        .filter((rule) => !rule.predicate(value))
        .map((rule) => ({
          details: `${field.title} failed validation: ${rule.warning}.`,
          property: field,
          obj: obj,
          category: ProblemCategory.InvalidPropertyValue,
        })),
    );
  }

  if (isChoiceFieldConcrete(field) && isInvalidChoice(field, obj, filled)) {
    problems.push({
      details: `Please select a valid option for ${field.title}.`,
      property: field,
      obj: obj,
      category: ProblemCategory.InvalidPropertyValue,
    });
  }

  if (
    field.type == FieldType.FlowSystemChoice &&
    isInvalidFlowSystemChoice(field, filled)
  ) {
    problems.push({
      details: `Please select a valid option for ${field.title}.`,
      property: field,
      obj: obj,
      category: ProblemCategory.InvalidPropertyValue,
    });
  }

  return problems;
}
