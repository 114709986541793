import { CoreContext } from "../api/calculations/types";
import { assertUnreachable } from "./utils";

export enum Currency {
  AUD = "AUD",
  GBP = "GBP",
  USD = "USD",
}

export enum CurrencySymbol {
  DOLLARS = "$",
  POUNDS = "£",
}

export function convertCurrencyByContext(
  context: CoreContext,
  price: { value: number; currency: Currency },
): number {
  const ratio =
    (context.drawing.metadata.units.currency.multiplierPct ?? 100) / 100;
  return (
    ratio *
    convertCurrency(
      price.value,
      price.currency,
      currencySymbolToCurrency(context.drawing.metadata.units.currency.symbol),
    )
  );
}

export function formatCurrency(amount: number, currency: Currency): string {
  const symbol = currencyToCurrencySymbol(currency);
  return `${symbol}${amount.toFixed(2)}`;
}

export function currencyToCurrencySymbol(currency: Currency): CurrencySymbol {
  switch (currency) {
    case Currency.USD:
      return CurrencySymbol.DOLLARS;
    case Currency.GBP:
      return CurrencySymbol.POUNDS;
    case Currency.AUD:
      return CurrencySymbol.DOLLARS;
  }
  assertUnreachable(currency);
}

export function currencySymbolToCurrency(symbol: CurrencySymbol): Currency {
  switch (symbol) {
    case CurrencySymbol.DOLLARS:
      return Currency.USD;
    case CurrencySymbol.POUNDS:
      return Currency.GBP;
  }
  assertUnreachable(symbol);
}

export function convertCurrency(
  value: number,
  from: Currency,
  to: Currency,
): number {
  if (from === to) {
    return value;
  }

  const conversionRate = getConversionRate(from, to);
  return value * conversionRate;
}

export function getConversionRate(from: Currency, to: Currency): number {
  switch (from) {
    case Currency.AUD:
      switch (to) {
        case Currency.AUD:
          return 1;
        case Currency.GBP:
          return 0.51;
        case Currency.USD:
          return 0.67;
      }
      assertUnreachable(to);
    case Currency.GBP:
      switch (to) {
        case Currency.GBP:
          return 1;
        case Currency.AUD:
          return 1 / 0.51;
        case Currency.USD:
          return 1.31;
      }
      assertUnreachable(to);
    case Currency.USD:
      switch (to) {
        case Currency.AUD:
          return 1 / 0.67;
        case Currency.GBP:
          return 1 / 1.31;
        case Currency.USD:
          return 1;
      }
      assertUnreachable(to);
  }
  assertUnreachable(to);
  throw new Error(`Unknown currency pair: ${from} to ${to}`);
}
