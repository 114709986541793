// If your flags aren't working, it's because you didn't select "Allow Client-side SDKs" in your LaunchDarkly Flag.
// We currently only have 2 environments: Prod and Test, we might make more later.
// https://h2xengineering.atlassian.net/wiki/spaces/ENG/pages/171081734/Launch+Darkly
export const CORE_FEATURE_FLAG_DEFAULTS: CoreFeatureFlags = {
  "debug-loops": false,
  "release-global-scop-reports": false,
  "release-mcs-v-4-heat-load-report-option": false,
  "release-refactored-heat-loss-reports": "Old",
};

type BooleanKeys<T> = {
  [k in keyof T]: T[k] extends boolean ? k : never;
}[keyof T];

export type BooleanCoreFeatureFlags = BooleanKeys<CoreFeatureFlags>;

export interface CoreFeatureFlags {
  "debug-loops": boolean;
  "release-global-scop-reports": boolean;
  "release-mcs-v-4-heat-load-report-option": boolean;
  "release-refactored-heat-loss-reports": "Old" | "Both" | "New";
}

export type CoreFeatureFlag = keyof CoreFeatureFlags;
