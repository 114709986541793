import { Units } from "../../../../lib/measurements";
import { assertUnreachable, cloneSimple } from "../../../../lib/utils";
import { CoreContext } from "../../../calculations/types";
import { CIBSEGuideGLevels } from "../../../catalog/types";
import {
  DrainageMethods,
  PSDStandards,
  isEquationPSDStandards,
  isLUStandard,
  isPressure,
  isTankValveStandard,
} from "../../../config";
import { I18N } from "../../../locale/values";
import { getFlowSystem } from "../../utils";
import { FieldType } from "../field-type";
import { PropertyField } from "../property-field";
import {
  DrawableEntity,
  NamedEntity,
  PositionedEntity,
} from "../simple-entities";
import { EntityType } from "../types";

export interface RoughInRecord {
  uid: string;
  minPressureKPA: number | null;
  maxPressureKPA: number | null;
  loadingUnits: number | null;
  designFlowRateLS: number | null;
  continuousFlowLS: number | null;
  allowAllSystems: boolean;
}

export default interface FixtureEntity
  extends DrawableEntity,
    NamedEntity,
    PositionedEntity {
  type: EntityType.FIXTURE;
  name: string;
  abbreviation: string;

  roughIns: {
    [key: string]: RoughInRecord;
  };
  roughInsInOrder: string[];

  pipeDistanceMM: number;
  outletAboveFloorM: number | null;
  warmTempC: number | null;

  drainageFixtureUnits: number | null;

  loadingUnitVariant?: CIBSEGuideGLevels | null;
}

export function makeFixtureFields(
  context: CoreContext,
  entity: FixtureEntity,
): PropertyField[] {
  const { locale, drawing } = context;

  const res: PropertyField[] = [
    {
      property: "entityName",
      title: "Name",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Text,
      params: null,
      multiFieldId: "entityName",
    },
    {
      property: "pipeDistanceMM",
      title: "Size",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: 100, max: 200, step: 10 },
      multiFieldId: "pipeDistanceMM",
      units: Units.Percent,
    },
    {
      property: "rotation",
      title: "Rotation: (Degrees)",
      hasDefault: false,
      isCalculated: false,
      type: FieldType.Rotation,
      params: {
        step: 45,
        disableFreeInput: false,
      },
      multiFieldId: null,
    },
    {
      property: "outletAboveFloorM",
      title: "Height Above Floor",
      hasDefault: true,
      highlightOnOverride: true,
      isCalculated: false,
      type: FieldType.Number,
      params: { min: null, max: null },
      multiFieldId: "outletAboveFloorM",
      units: Units.Meters,
    },
  ];

  const psdStrategy = drawing
    ? drawing.metadata.calculationParams.psdMethod
    : PSDStandards.as35002021LoadingUnits;

  const tabContainer = createFixtureTabs(context, entity);
  res.push(tabContainer);

  if (psdStrategy === PSDStandards.cibseGuideG) {
    res.splice(0, 0, {
      property: "loadingUnitVariant",
      title: "Loading Unit Variant",
      hasDefault: true,
      isCalculated: false,
      type: FieldType.Choice,
      params: {
        choices: [
          { name: "Low", key: "low" },
          { name: "Medium", key: "medium" },
          { name: "High", key: "high" },
        ],
      },
      multiFieldId: null,
    });
  }

  return res;
}

export function createFixtureTabs(
  context: CoreContext,
  entity: FixtureEntity,
): PropertyField {
  const { drawing, locale } = context;
  const psdStrategy = drawing
    ? drawing.metadata.calculationParams.psdMethod
    : PSDStandards.as35002021LoadingUnits;

  const tabContainer: PropertyField = {
    type: FieldType.Tabs,
    id: "fixture-tabs",
    tabs: [],
  };

  // create non-drainage tabs
  for (const suid of Object.keys(entity.roughIns)) {
    if (isPressure(drawing.metadata.flowSystems[suid])) {
      const system = getFlowSystem(drawing, suid)!;

      tabContainer.tabs.push({
        tabId: suid + "-tab",
        tabName: system.name,
        fields: [
          {
            property: "roughIns." + suid + ".allowAllSystems",
            title: "Allow Other Systems to Connect?",
            hasDefault: false,
            isCalculated: false,
            type: FieldType.Boolean,
            params: null,
            multiFieldId: suid + "-allowAllSystems",
          },

          {
            property: "roughIns." + suid + ".designFlowRateLS",
            title: "Full Flow Rate",
            hasDefault: true,
            highlightOnOverride: true,
            isCalculated: false,
            type: FieldType.Number,
            params: { min: 0, max: null },
            multiFieldId: suid + "-designFlowRateLS",
            units: Units.LitersPerSecond,
            isShown: !isLUStandard(psdStrategy),
          },

          {
            property: "roughIns." + suid + ".continuousFlowLS",
            title: "Continuous Flow",
            hasDefault: true,
            highlightOnOverride: true,
            isCalculated: false,
            type: FieldType.Number,
            params: { min: 0, max: null },
            multiFieldId: suid + "-continuousFlowLS",
            units: Units.LitersPerSecond,
          },
          {
            property: "roughIns." + suid + ".loadingUnits",
            title: I18N.loadingUnits[locale],
            hasDefault: true,
            highlightOnOverride: true,
            isCalculated: false,
            type: FieldType.Number,
            params: { min: 0, max: null },
            multiFieldId: suid + "-loadingUnits",
            isShown: isLUStandard(psdStrategy),
          },
          {
            property: "roughIns." + suid + ".minPressureKPA",
            title: "Min. Inlet Pressure",
            hasDefault: true,
            highlightOnOverride: true,
            isCalculated: false,
            type: FieldType.Number,
            params: { min: 0, max: null },
            multiFieldId: suid + "-minPressureKPA",
            units: Units.KiloPascals,
          },
          {
            property: "roughIns." + suid + ".maxPressureKPA",
            title: "Max. Inlet Pressure",
            hasDefault: true,
            highlightOnOverride: true,
            isCalculated: false,
            type: FieldType.Number,
            params: { min: 0, max: null },
            multiFieldId: suid + "-maxPressureKPA",
            units: Units.KiloPascals,
          },
        ],
      });
    }
  }

  // drainage
  tabContainer.tabs.push({
    tabId: "drainage-tab",
    tabName: "Drainage",
    fields: [
      {
        property: "drainageFixtureUnits",
        title: "Drainage Fixture Unit",
        hasDefault: true,
        highlightOnOverride: true,
        isCalculated: false,
        type: FieldType.Number,
        params: { min: 0, max: null },
        multiFieldId: "asnzFixtureUnits",
      },
    ],
  });

  return tabContainer;
}

export function fillFixtureFields(
  context: CoreContext,
  entity: FixtureEntity,
): FixtureEntity {
  const { drawing, catalog } = context;
  const result = cloneSimple(entity);

  if (result.warmTempC === null) {
    result.warmTempC = catalog.fixtures[result.name]["warmTempC"];
  }

  if (result.outletAboveFloorM === null) {
    result.outletAboveFloorM =
      catalog.fixtures[result.name]["outletAboveFloorM"];
  }

  if (result.drainageFixtureUnits === null) {
    result.drainageFixtureUnits = getCatalogFixtureDrainageUnits({
      context,
      fixtureName: result.name,
    });
  }

  const psdStrategy = drawing
    ? drawing.metadata.calculationParams.psdMethod
    : PSDStandards.as35002021LoadingUnits;

  if (
    result.loadingUnitVariant === undefined ||
    result.loadingUnitVariant === null
  ) {
    result.loadingUnitVariant =
      drawing?.metadata.calculationParams.loadingUnitVariant || "low";
  }

  for (const systemUid of Object.keys(result.roughIns)) {
    if (isPressure(drawing.metadata.flowSystems[systemUid])) {
      const target = result.roughIns[systemUid];
      if (target.minPressureKPA === null) {
        target.minPressureKPA =
          catalog.fixtures[result.name].minInletPressureKPA;
      }
      if (target.maxPressureKPA === null) {
        target.maxPressureKPA =
          catalog.fixtures[result.name].maxInletPressureKPA;
      }

      if (target.designFlowRateLS === null) {
        target.designFlowRateLS = getFixtureDesignFlowRate({
          context,
          fixtureName: result.name,
          systemUid,
        });
      }

      if (isLUStandard(psdStrategy)) {
        if (target.loadingUnits === null) {
          target.loadingUnits = getFixtureLoadingUnits({
            context,
            fixtureName: result.name,
            systemUid,
            psdStrategy,
            loadingUnitVariant: result.loadingUnitVariant,
          });
        }
      }

      if (target.continuousFlowLS === null) {
        target.continuousFlowLS = getFixtureContinuousFlow({
          context,
          fixtureName: result.name,
          systemUid,
        });
      }
    }
  }

  return result;
}

export function getFixtureDemandTypes(context: CoreContext, systemUid: string) {
  const system = getFlowSystem(context.drawing, systemUid);
  if (!system) {
    return { demandType: null, fallbackDemandType: null };
  }

  let demandType: "cold-water" | "hot-water" | "warm-water" | null = null;
  if (system.role === "coldwater") {
    demandType = "cold-water";
  } else if (system.role === "hotwater") {
    demandType = "hot-water";
  } else if (system.role === "warmwater") {
    demandType = "warm-water";
  }
  if (!demandType) {
    return { demandType: null, fallbackDemandType: null };
  }

  const fallbackDemandType =
    demandType === "hot-water" ? "warm-water" : undefined;

  return { demandType, fallbackDemandType } as const;
}

export function getFixtureLoadingUnits(options: {
  context: CoreContext;
  fixtureName: string;
  systemUid: string;
  psdStrategy: PSDStandards;
  loadingUnitVariant: CIBSEGuideGLevels;
}): number | null {
  const { fixtureName, psdStrategy, loadingUnitVariant, systemUid, context } =
    options;

  const { demandType, fallbackDemandType } = getFixtureDemandTypes(
    context,
    systemUid,
  );
  if (!demandType) {
    return null;
  }

  if (isLUStandard(psdStrategy)) {
    if (psdStrategy === PSDStandards.cibseGuideG) {
      const LUs =
        context.catalog.fixtures[fixtureName].loadingUnits[psdStrategy];
      if (LUs[demandType]) {
        return LUs[loadingUnitVariant];
      } else if (fallbackDemandType && LUs[fallbackDemandType]) {
        return LUs[loadingUnitVariant];
      }
    } else {
      const LUs =
        context.catalog.fixtures[fixtureName].loadingUnits[psdStrategy];
      if (LUs[demandType] !== undefined) {
        return LUs[demandType]!;
      } else if (fallbackDemandType && LUs[fallbackDemandType] != undefined) {
        return LUs[fallbackDemandType]!;
      }
    }
  } else if (isTankValveStandard(psdStrategy)) {
    // TODO SEED-2179
    throw new Error("Not implemented yet");
  }
  return null;
}

export function getFixtureCalculationUnits(options: {
  context: CoreContext;
  fixtureName: string;
  systemUid: string;
  psdStrategy: PSDStandards;
  loadingUnitVariant: CIBSEGuideGLevels;
}) {
  const { psdStrategy } = options;
  if (isEquationPSDStandards(psdStrategy)) {
    return getFixtureDesignFlowRate(options);
  } else {
    return getFixtureLoadingUnits(options);
  }
}

export function getFixtureDesignFlowRate(options: {
  context: CoreContext;
  fixtureName: string;
  systemUid: string;
}): number | null {
  const { context, fixtureName, systemUid } = options;
  const selectedMaterialManufacturer =
    context.drawing?.metadata?.catalog?.fixtures?.find(
      (obj) => obj.uid === fixtureName,
    );
  const manufacturer = selectedMaterialManufacturer?.manufacturer || "generic";
  const selectedOption = selectedMaterialManufacturer?.selected || "default";

  const { demandType, fallbackDemandType } = getFixtureDemandTypes(
    context,
    systemUid,
  );

  if (!demandType) {
    return null;
  }

  const qLS =
    context.catalog.fixtures[fixtureName].qLS[manufacturer][selectedOption];
  if (qLS[demandType] !== undefined) {
    return qLS[demandType]!;
  } else if (fallbackDemandType && qLS[fallbackDemandType] !== undefined) {
    return qLS[fallbackDemandType]!;
  }
  return null;
}

export function getFixtureContinuousFlow(options: {
  context: CoreContext;
  fixtureName: string;
  systemUid: string;
}): number | null {
  const { fixtureName, context, systemUid } = options;
  const continuousFlowLS =
    context.catalog.fixtures[fixtureName].continuousFlowLS;

  const { demandType, fallbackDemandType } = getFixtureDemandTypes(
    context,
    systemUid,
  );
  if (!demandType) {
    return null;
  }

  if (continuousFlowLS) {
    if (continuousFlowLS[demandType] !== undefined) {
      return continuousFlowLS[demandType]!;
    } else if (
      fallbackDemandType &&
      continuousFlowLS[fallbackDemandType] !== null
    ) {
      return continuousFlowLS[fallbackDemandType]!;
    }
  }
  return 0;
}

export function getCatalogFixtureDrainageUnits(options: {
  context: CoreContext;
  fixtureName: string;
}): number | null {
  const {
    fixtureName,
    context: { drawing, catalog },
  } = options;
  switch (drawing.metadata.calculationParams.drainageMethod) {
    case DrainageMethods.AS2021FixtureUnits:
      return catalog.fixtures[fixtureName].asnzFixtureUnits;
    case DrainageMethods.EN1205622000DischargeUnits:
      return catalog.fixtures[fixtureName].enDrainageSystem[
        drawing.metadata.calculationParams.drainageSystem
      ]!;
    case DrainageMethods.UPC2018DrainageFixtureUnits:
      return catalog.fixtures[fixtureName].upcFixtureUnits;
    case DrainageMethods.NCBIndia2016FlushTankPrivate:
      return catalog.fixtures[fixtureName].nbcIndiaFlushTankPrivateDFU;
    case DrainageMethods.NCBIndia2016FlushTankPublic:
      return catalog.fixtures[fixtureName].nbcIndiaFlushTankPublicDFU;
    case DrainageMethods.NBCIndia2016FlushValvePrivate:
      return catalog.fixtures[fixtureName].nbcIndiaFlushValvePrivateDFU;
    case DrainageMethods.NBCIndia2016FlushValvePublic:
      return catalog.fixtures[fixtureName].nbcIndiaFlushValvePublicDFU;
  }
  assertUnreachable(drawing.metadata.calculationParams.drainageMethod);
}
