import Flatten from "@flatten-js/core";
import CoreFlowSource from "../../../../common/src/api/coreObjects/coreFlowSource";
import FlowSourceEntity from "../../../../common/src/api/document/entities/flow-source-entity";
import RiserEntity from "../../../../common/src/api/document/entities/riser-entity";
import { EntityType } from "../../../../common/src/api/document/entities/types";
import { FlowSystem } from "../../../../common/src/api/document/flow-systems";
import { getFlowSystem } from "../../../../common/src/api/document/utils";
import { Color } from "../../../../common/src/lib/color";
import { Coord } from "../../../../common/src/lib/coord";
import { SentryEntityError } from "../../../../common/src/lib/sentry-entity-error";
import { DEFAULT_FONT_NAME } from "../../config";
import { rgb2style } from "../../lib/utils";
import { getDragPriority } from "../../store/document";
import { DocumentState } from "../../store/document/types";
import { EntityDrawingArgs } from "../lib/drawable-object";
import { EntityPopupContent } from "../lib/entity-popups/types";
import { HeatmapMode, isHeatmapEnabled } from "../lib/heatmap/heatmap";
import { AttachableObject } from "../lib/object-traits/attachable-object";
import { CalculatedObject } from "../lib/object-traits/calculated-object";
import { CenteredObject } from "../lib/object-traits/centered-object";
import { ConnectableObject } from "../lib/object-traits/connectable";
import CoolDraggableObject from "../lib/object-traits/cool-draggable-object";
import { Core2Drawable } from "../lib/object-traits/core2drawable";
import {
  HoverSiblingResult,
  HoverableObject,
} from "../lib/object-traits/hoverable-object";
import { SelectableObject } from "../lib/object-traits/selectable";
import { SnappableObject } from "../lib/object-traits/snappable-object";
import { DrawingContext, ObjectConstructArgs } from "../lib/types";
import { getHighlightColor } from "../lib/utils";
import { DrawingMode } from "../types";
import { applyHoverEffects } from "./utils";

const Base = CalculatedObject(
  SelectableObject(
    CoolDraggableObject(
      AttachableObject(
        HoverableObject(
          ConnectableObject(
            CenteredObject(SnappableObject(Core2Drawable(CoreFlowSource))),
          ),
        ),
      ),
    ),
  ),
);
export default class DrawableFlowSource extends Base {
  minimumConnections = 0;
  maximumConnections = null;

  dragPriority = getDragPriority(EntityType.RISER);

  MINIMUM_RADIUS_PX: number = 3;
  lastDrawnWorldRadius: number = 0; // for bounds detection
  lastDrawnDiameterW: number = 100;

  // We could not add this to the base drawable class because
  // of some typescript thing so they have to be added at the concrete class.
  constructor(args: ObjectConstructArgs<FlowSourceEntity>) {
    super(args.context, args.obj);
    this.onSelect = args.onSelect;
    this.onInteractionComplete = args.onInteractionComplete;
    this.document = args.document;
  }

  drawConnectable(
    context: DrawingContext,
    {
      layerActive,
      selected,
      hasOverriddenField,
      heatmapMode,
    }: EntityDrawingArgs,
  ): void {
    applyHoverEffects(context, this);
    const { ctx, doc, vp } = context;

    if (isHeatmapEnabled(this.document) && heatmapMode !== undefined) {
      return;
    }

    this.lastDrawnWorldRadius = 0;

    const scale = vp.currToSurfaceScale(ctx);
    // Minimum screen size for them.

    const rawDiameter = 300;
    const screenMin = vp.surfaceToWorldLength(10);
    this.lastDrawnDiameterW = Math.max(rawDiameter, screenMin);

    const screenSize = vp.toSurfaceLength(this.lastDrawnDiameterW / 2);

    ctx.lineWidth = 0;

    if (
      (selected || hasOverriddenField) &&
      (heatmapMode === HeatmapMode.Off ||
        this.document.uiState.drawingMode !== DrawingMode.Calculations)
    ) {
      // we want to draw a pixel sized dark halo around a selected component
      const haloSize =
        (Math.max(this.MINIMUM_RADIUS_PX, screenSize) + 5) / scale;

      ctx.fillStyle = rgb2style(
        getHighlightColor(
          selected,
          hasOverriddenField ? [Color.YELLOW] : [],
          this.color(doc),
        ),
        0.5,
      );
      if (!this.isActive()) {
        ctx.fillStyle = "rgba(150, 150, 150, 0.65)";
      }

      ctx.beginPath();
      ctx.lineWidth = 0;
      ctx.arc(0, 0, haloSize, 0, Math.PI * 2);
      ctx.fill();

      this.lastDrawnWorldRadius = Math.max(this.lastDrawnWorldRadius, haloSize);
    }

    if (layerActive) {
      if (screenSize < this.MINIMUM_RADIUS_PX) {
        // Flow sources are very important and should be visible, even when zoomed out.

        ctx.fillStyle = this.color(doc).hex;
        ctx.globalAlpha = 0.5;
        ctx.beginPath();
        ctx.arc(0, 0, this.MINIMUM_RADIUS_PX / scale, 0, Math.PI * 2);
        ctx.fill();

        this.lastDrawnWorldRadius = Math.max(
          this.lastDrawnWorldRadius,
          this.MINIMUM_RADIUS_PX / scale,
        );
      }
    }

    ctx.fillStyle = this.color(doc).hex;

    ctx.globalAlpha = 1;
    applyHoverEffects(context, this);
    ctx.beginPath();
    ctx.arc(
      0,
      0,
      this.toObjectLength(this.lastDrawnDiameterW / 2),
      0,
      Math.PI * 2,
    );
    this.lastDrawnWorldRadius = Math.max(
      this.lastDrawnWorldRadius,
      this.toObjectLength(this.lastDrawnDiameterW / 2),
    );
    ctx.fill();

    // draw white yin
    ctx.beginPath();
    ctx.fillStyle = "#FFFFFF";

    ctx.moveTo(0, -this.lastDrawnDiameterW * 0.45);
    ctx.arc(
      0,
      0,
      this.lastDrawnDiameterW * 0.45,
      -Math.PI / 2,
      Math.PI / 2,
      true,
    );

    ctx.arc(
      -this.lastDrawnDiameterW * Math.sin(Math.PI / 3) * 0.45,
      +this.lastDrawnDiameterW * Math.cos(Math.PI / 3) * 0.45,
      this.lastDrawnDiameterW * 0.45,
      (Math.PI * 2) / 3 - Math.PI / 2,
      Math.PI / 3 - Math.PI / 2,
      true,
    );

    ctx.arc(
      +this.lastDrawnDiameterW * Math.sin(Math.PI / 3) * 0.45,
      -this.lastDrawnDiameterW * Math.cos(Math.PI / 3) * 0.45,
      this.lastDrawnDiameterW * 0.45,
      Math.PI / 3 + Math.PI - Math.PI / 2,
      (Math.PI * 2) / 3 + Math.PI - Math.PI / 2,
      false,
    );
    ctx.closePath();
    ctx.fill();

    // Display Entity Name
    if (this.entity.entityName) {
      const name = this.entity.entityName;
      ctx.font = 70 + "pt " + DEFAULT_FONT_NAME;
      const nameWidth = ctx.measureText(name).width;
      const offsetx = -nameWidth / 2;
      ctx.fillStyle = "rgba(0, 255, 20, 0.13)";
      // the 70 represents the height of the font
      const textHight = 70;
      const offsetY = this.lastDrawnDiameterW - textHight / 2;
      ctx.fillRect(offsetx, offsetY, nameWidth, 70);
      ctx.fillStyle = this.color(doc).hex;
      ctx.fillTextStable(name, offsetx, offsetY - 4, undefined, "top");
    }
  }

  color(doc: DocumentState) {
    if (!this.isActive()) {
      return { hex: "rgba(150, 150, 150, 0.65)" };
    }
    return this.entity.color == null
      ? this.system(doc).color
      : this.entity.color;
  }

  system(doc: DocumentState): FlowSystem {
    const result = getFlowSystem(doc.drawing, this.entity.systemUid);
    if (result) {
      return result;
    } else {
      throw new SentryEntityError(
        "Flow system not found for flow source ",
        this.uid,
      );
    }
  }

  refreshObjectInternal(_obj: RiserEntity): void {
    //
  }

  inBounds(objectCoord: Coord, radius?: number) {
    if (!this.isActive()) {
      return false;
    }
    const dist = Math.sqrt(objectCoord.x ** 2 + objectCoord.y ** 2);
    return (
      dist <=
      this.toObjectLength(this.lastDrawnDiameterW / 2) + (radius ? radius : 0)
    );
  }

  getPopupContent(): EntityPopupContent[] {
    return [];
  }

  getHoverSiblings(): HoverSiblingResult[] {
    return [];
  }

  getAttachCoords(): [Coord, Coord, Coord, Coord] {
    return [
      // left
      {
        x: -this.lastDrawnDiameterW / 2 - this.attachmentOffset,
        y: 0,
      },
      // right
      {
        x: this.lastDrawnDiameterW / 2 + this.attachmentOffset,
        y: 0,
      },
      // top
      {
        x: 0,
        y: -this.lastDrawnDiameterW / 2 - this.attachmentOffset,
      },
      // bottom
      {
        x: 0,
        y: this.lastDrawnDiameterW / 2 + this.attachmentOffset,
      },
    ];
  }

  // @ts-ignore
  get shape() {
    const point = this.toWorldCoord({ x: 0, y: 0 });
    return Flatten.circle(
      Flatten.point(point.x, point.y),
      this.lastDrawnDiameterW / 2 + this.attachmentOffset,
    );
  }
}
