import { V2RadiatorEntity } from "../plant-entity";

export interface SystemNodeData {
  uid: string;
  systemUid: string;
}

/**
 * @param radiatorEntity
 * @returns The uid and the system uid of system nodes, in decreasing y and
 * increasing x order (x and y are objectCoords, not worldCoords)
 */
export function topDownLeftRightIOData(
  radiatorEntity: V2RadiatorEntity,
): [SystemNodeData, SystemNodeData] {
  const layout = radiatorEntity.plant.connectionsLayout;
  const inlet: SystemNodeData = {
    uid: radiatorEntity.inletUid!,
    systemUid: radiatorEntity.inletSystemUid,
  };
  const outlet: SystemNodeData = {
    uid: radiatorEntity.plant.outletUid,
    systemUid: radiatorEntity.plant.outletSystemUid,
  };

  return layout.swapNormalPositions ? [outlet, inlet] : [inlet, outlet];
}
