import { Color } from "../../../../lib/color";
import { cloneSimple } from "../../../../lib/utils";
import { FieldType } from "../field-type";
import { PropertyField } from "../property-field";
import { BaseEdgeEntity, DrawableEntity } from "../simple-entities";
import { EntityType } from "../types";
import { AnnotationLineEntity, LineConcrete, LineType } from "./line-types";

export interface LineEntityBase extends DrawableEntity, BaseEdgeEntity {
  type: EntityType.LINE;
  color: Color | null;
  thicknessMM: number | null;

  lineType: LineType;
  line: LineConcrete;
}

export type LineEntity = AnnotationLineEntity;

export function fillDefaultLineFields(entity: LineEntity) {
  const result = cloneSimple(entity);
  if (result.color === null) {
    result.color = { hex: "#000" };
  }
  if (!result.thicknessMM) {
    result.thicknessMM = 1;
  }
  return result;
}

export const LINE_FIELDS: PropertyField[] = [
  {
    property: "color",
    title: "Color",
    hasDefault: false,
    isCalculated: false,
    type: FieldType.Color,
    params: null,
    multiFieldId: "color",
  },
];
