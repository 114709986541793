import * as TM from "transformation-matrix";
import { Coord } from "../../../lib/coord";
import { GlobalStore } from "../../../lib/globalstore/global-store";
import { Logger } from "../../../lib/logger";
import { SentryEntityError } from "../../../lib/sentry-entity-error";
import { isNotNullAndUndefined } from "../../../lib/utils";
import { CoreContext } from "../../calculations/types";
import { DrawingState } from "../../document/drawing";
import { FlatPropertyFields } from "../../document/entities/property-field";
import { DrawableEntity } from "../../document/entities/simple-entities";
import { EntityType } from "../../document/entities/types";
import { flattenTabFields } from "../../document/entities/utils";
import { makeInertEntityFieldsTracked } from "../../document/utils";
import { Tag } from "./coreTagMap";
import CoreWorldObject from "./coreWorldObject";
import { PropertyReference, findReferences } from "./property-reference";

export default abstract class CoreBaseBackedObject<
  T extends DrawableEntity = DrawableEntity,
> extends CoreWorldObject {
  context: CoreContext;
  entityBacked: T;

  protected filled: T | null = null;

  cache = new Map<string, any>();

  /**
   * Generate tags to use for this entity.
   *
   * Please use `ObjectStore.getTags` instead of this method, as it is cached.
   */
  get tags(): Set<Tag> {
    const typeL = this.type.toLowerCase();
    const subtypeL = this.subtype?.toLowerCase();
    const tertiaryTypeL = this.tertiaryType?.toLowerCase();
    return new Set(
      [
        typeL,
        subtypeL ? `${typeL}.${subtypeL}` : undefined,
        tertiaryTypeL ? `${typeL}.${subtypeL}.${tertiaryTypeL}` : undefined,
      ].filter(isNotNullAndUndefined),
    ) as Set<Tag>;
  }

  get drawing(): DrawingState {
    return this.context.drawing;
  }

  get globalStore(): GlobalStore {
    return this.context.globalStore;
  }

  get children(): CoreBaseBackedObject[] {
    const children = this.globalStore.ancestry.getInverted(this.uid);
    return [...children].map((uid) => this.globalStore.get(uid));
  }

  get entity(): T {
    return this.entityBacked as T;
  }

  abstract get filledEntity(): T;

  get uid(): string {
    return this.entity.uid;
  }

  /**
   * TODO(SEED-2044): Migrate this to be based on reference
   */
  abstract type: EntityType;

  get flatProperties(): FlatPropertyFields[] {
    return flattenTabFields(
      makeInertEntityFieldsTracked(this.context, this.entity),
    );
  }

  /**
   * Warning (SEED-2044): Do not rely on this yet. prefer `getReferences` for now.
   *
   * This returns things like marketplace manufacturers etc. that do not exist in global store.
   */
  get references(): PropertyReference[] {
    return findReferences(this.entity);
  }

  /**
   * Full Reference Path. This is the unique path to a concrete type of entity.
   *
   * Eg. `ROOM.ROOF` or `PLANT.MANIFOLD`
   */
  abstract get refPath(): string;

  /**
   * TODO(SEED-2044): Migrate UID to this property
   *
   * This is the unique path to a specific entity, application wide
   * there will only ever be one match.
   */
  get reference(): string {
    return `entity:${this.refPath}:${this.uid}`;
  }

  get subtype(): string | undefined {
    return this.refPath.split(".").at(1);
  }

  /**
   * Very occasionally, we have entities which have 3 levels of typing.
   * These are usually plants
   */
  get tertiaryType(): string | undefined {
    return this.refPath.split(".").at(2);
  }

  abstract getHash(): string;

  get parent(): CoreBaseBackedObject | null {
    if (this.entity.parentUid === null) {
      return null;
    } else {
      const result = this.globalStore.getSafe(this.entity.parentUid);
      if (result) {
        return result;
      }
      Logger.error(
        new SentryEntityError(
          "Parent object not created",
          this.entity.uid,
          {},
          {
            parentUid: this.entity.parentUid,
          },
        ),
      );
      return null;
    }
  }

  getParentChain(): CoreBaseBackedObject[] {
    if (this.parent) {
      const res = this.parent.getParentChain();
      res.push(this);
      return res;
    } else {
      return [this];
    }
  }

  // Override this to give positions to children that respect delegated positions.
  // Return the correct object coord of the child, or null if unknown.
  getCorrectPositionOfChild(childUid: string): Coord | null {
    return null;
  }

  constructor(context: CoreContext, obj: T) {
    super(null);
    this.entityBacked = obj;
    this.context = context;
  }

  // Could be abstract, but entities usually have no need to update.
  onUpdate() {
    this.filled = null;
  }

  // Called by the engine when the object needs to be redrawn, due to changes in itself
  // or other entities it depends on for its own visuals.
  onRedrawNeeded() {
    //
  }

  // The entities which, if changed, would cause this entity's graphics to be invalidated, including
  // shape, position, or colour, etc.
  // This list should be stable as long as the entity itself is not changed.
  getVisualDeps(): string[] {
    if (this.entity.parentUid) {
      return [this.entity.parentUid];
    }
    return [];
  }

  abstract get position(): TM.Matrix;

  abstract getCoreNeighbours(): CoreBaseBackedObject[];
}
