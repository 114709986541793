import { Context, ErrorEvent, EventHint } from "@sentry/types";
import * as SentryClient from "@sentry/vue";
import Vue from "vue";
import Router from "vue-router";
import { CURRENT_VERSION } from "../../../../common/src/api/config";
import { getEntityAnalyticProperties } from "../../../../common/src/api/document/analytics/utils";
import { isCalculated } from "../../../../common/src/api/document/calculations-objects";
import { Logger } from "../../../../common/src/lib/logger";
import { isSentryEntityError } from "../../../../common/src/lib/sentry-entity-error";
import { isSentryError } from "../../../../common/src/lib/sentry-error";
import { FeatureAccess } from "../../../../common/src/models/FeatureAccess";
import { User } from "../../../../common/src/models/User";
import { ENABLE_SENTRY, environment, isTestOrDev } from "../../config";
import { Counter } from "../../lib/counter";
import { useAppVersion } from "../../lib/hooks/use-app-version";
import { useDocument } from "../../lib/hooks/use-document";
import { useFeatureFlags } from "../../lib/hooks/use-feature-flags";
import { globalStore } from "../../store/globalCoreContext";
import { getUiStateProps } from "../mixpanel";
import { SentryLogger } from "./SentryLogger";

const RATE_LIMIT_PER_ERROR_TITLE = 5;
/**
 * Very naive rate limiting, just ban more that 5 of each error type in the same session.
 *
 * What we really care about, is number of users affected by the same issue. Not necessarily the same issue.
 * This will absolutely hide errors (eg. if 20 entities all fail to render, we will only see 5 before rate limiting kicks in).
 *
 * 2 smarter alternatives
 * - Count by time, so perhaps its 5 per hour per user.
 * - Hash the contents of tags/extras and never send the same event more than once.
 *
 * A page refresh will clear this, but im expecting us to see less "Spikes" where 10000 of the
 * same error occurs within a few minutes.
 */
export const errorCounter = new Counter<string>(RATE_LIMIT_PER_ERROR_TITLE);

export class Sentry {
  static updateUser(profile: User, featureAccess: FeatureAccess) {
    SentryClient.setUser({
      username: profile.username,
      email: profile.email ?? undefined,
      accessLevel: profile.accessLevel ?? undefined,
    });
    SentryClient.setExtra("featureAccess", featureAccess);
  }

  static updateDocument() {
    const document = useDocument();
    // Note: Tags are indexed and searchable, context is not
    SentryClient.setTags({
      documentId: document.documentId,
      documentVersion: CURRENT_VERSION,
    });
  }

  static updateFeatureFlags() {
    const featureFlags = useFeatureFlags();
    SentryClient.setExtra("featureFlags", featureFlags);
  }

  static buildLaunchDarklyFlagUsedHandler() {
    return SentryClient.buildLaunchDarklyFlagUsedHandler();
  }

  static captureException(error: Error) {
    try {
      if (ENABLE_SENTRY) {
        if (isSentryError(error)) {
          SentryClient.captureException(error, {
            tags: error.tags,
            extra: error.extras,
          });
        } else {
          SentryClient.captureException(error);
        }
      } else {
        if (isTestOrDev) {
          console.info("[Sentry]: Skipping Error handling, Sentry not enabled");
        }
      }
    } catch (e) {
      console.error("[Sentry]: Failed to capture exception: ", e);
    }
  }

  static init(router: Router) {
    if (ENABLE_SENTRY) {
      Logger.addLogger(new SentryLogger());
      SentryClient.init({
        Vue,
        dsn: "https://429531c63738445f907ccef3558e792e@o1351079.ingest.sentry.io/6631360",
        integrations: [
          SentryClient.launchDarklyIntegration(),
          SentryClient.browserTracingIntegration({
            // CAUTION: IF THIS IS TRUE IT BREAKS HUBSPOT CORS REQUESTS (baggage and sentry-trace headers)
            // See https://github.com/getsentry/sentry-javascript/issues/6077
            traceXHR: false,
            router,
          }),
        ],
        // @ts-ignore
        beforeSend: processEvent,
        environment: environment(),
        release: useAppVersion(),
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        // Allows us to log things like Entity without the nested data becoming [Object object]
        normalizeDepth: 5,
        tracePropagationTargets: ["localhost", /.*h2xengineering\.com/, /^\//],
      });
    }
  }
}
function processEvent(
  ev: ErrorEvent,
  hint: EventHint,
): ErrorEvent | PromiseLike<ErrorEvent | null> | null {
  const originalException = hint.originalException as any;
  const cacheKey = originalException.message;
  if (!cacheKey) {
    return withExtraData(ev, hint);
  }

  if (errorCounter.canIncrement(cacheKey)) {
    errorCounter.increment(cacheKey);
    return withExtraData(ev, hint);
  }
  console.error("[Sentry]: Rate Limited: ", cacheKey);

  // Rate limit
  return null;
}

function withExtraData(event: ErrorEvent, hint: EventHint): ErrorEvent {
  const exception = hint.originalException;

  event.tags = {
    ...event.tags,
    ...getUiStateProps(),
  };

  if (isSentryEntityError(exception)) {
    const obj = globalStore.getSafe(exception.entityUid);
    if (obj) {
      event.tags = {
        ...event.tags,
        ...getEntityAnalyticProperties(globalStore, obj.entity),
      };

      const calculated = isCalculated(obj.entity);
      event.contexts = {
        ...event.contexts,
        entity: toContext(obj.entity),
        calculations: calculated
          ? toContext(globalStore.getCalculation(obj.entity) ?? {})
          : {},
        liveCalculations: calculated
          ? toContext(globalStore.getLiveCalculation(obj.entity) ?? {})
          : {},
      };
    }
  }

  if (isSentryError(exception)) {
    event.tags = {
      ...event.tags,
      ...exception.tags,
    };
    event.extra = {
      ...event.extra,
      ...exception.extras,
    };
  }
  return event;
}

function toContext(o: object): Context {
  return Object.fromEntries(Object.entries(o));
}
