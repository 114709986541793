import CoreBaseBackedObject from "../../../../../common/src/api/coreObjects/lib/coreBaseBackedObject";
import {
  AttachableBackgroundEntityConcrete,
  CenteredEntityConcrete,
} from "../../../../../common/src/api/document/entities/concrete-entity";
import { EntityType } from "../../../../../common/src/api/document/entities/types";
import { Coord } from "../../../../../common/src/lib/coord";
import { Logger } from "../../../../../common/src/lib/logger";
import { SentryEntityError } from "../../../../../common/src/lib/sentry-entity-error";
import CanvasContext from "../../../../src/htmlcanvas/lib/canvas-context";

export default interface ICenteredObject {
  debase(context: CanvasContext): void;

  rebase(context: CanvasContext): void;

  repositionCenterToWC(coordWc: Coord): void;

  translateWC(vector: Coord): void;
}

export function CenteredObject<
  // I extends CenteredEntityConcrete,
  T extends abstract new (
    ...args: any[]
  ) => CoreBaseBackedObject<CenteredEntityConcrete>,
>(constructor: T) {
  abstract class Generated extends constructor implements ICenteredObject {
    shouldRebase: boolean = true;
    centered: true = true;
    debasedParentUid: string | null = null;

    override get tags() {
      const tags = super.tags;
      tags.add("centered");
      return tags;
    }

    debase(context: CanvasContext): void {
      if (this.entity.type === EntityType.SYSTEM_NODE) {
        Logger.error(
          new SentryEntityError("System node cannot be debased", this.uid),
        );
      }
      if (
        context.document.uiState.levelUid !==
        context.globalStore.levelOfEntity.get(this.uid)
      ) {
        return;
      }
      const wc = this.toWorldCoord({ x: 0, y: 0 });
      const angle = this.toWorldAngleDeg(0);

      this.debasedParentUid = this.entity.parentUid;
      this.entity.parentUid = null;
      this.entity.center.x = wc.x;
      this.entity.center.y = wc.y;

      if ("rotation" in this.entity) {
        this.entity.rotation = angle;
      }
    }

    rebase(context: CanvasContext) {
      if (
        context.document.uiState.levelUid !==
        context.globalStore.levelOfEntity.get(this.uid)
      ) {
        return;
      }
      if (this.entity.parentUid !== null) {
        throw new SentryEntityError(
          "Entity must be orphan before reparenting.",
          this.uid,
        );
      }
      // const [par, oc] = getInsertCoordsAt(context, this.entity.center);
      const wc = this.toWorldCoord({ x: 0, y: 0 });
      this.entity.parentUid = this.debasedParentUid;
      if (this.entity.parentUid === undefined) {
        throw new SentryEntityError("Parent uid is undefined.", this.uid);
      }

      this.debasedParentUid = null;
      this.repositionCenterToWC(wc);

      if (this.entity.parentUid && "rotation" in this.entity) {
        const parent = context.globalStore.get(this.entity.parentUid)!;
        this.entity.rotation -= parent.toWorldAngleDeg(0);
      }
    }

    repositionCenterToWC(coordWc: Partial<Coord>, translate?: boolean) {
      const wc = this.toWorldCoord();
      const objectCoord = translate
        ? this.toObjectCoord({
            x: wc.x + (coordWc.x || 0),
            y: wc.y + (coordWc.y || 0),
          })
        : this.toObjectCoord({
            x: coordWc.x ?? wc.x,
            y: coordWc.y ?? wc.y,
          });
      const parentCoord = this.toParentCoord(objectCoord);

      this.entity.center.x = parentCoord.x;
      this.entity.center.y = parentCoord.y;
    }

    translateWC(vector: Coord): void {
      this.repositionCenterToWC(vector, true);
    }

    canAcceptBackground(_entity: AttachableBackgroundEntityConcrete) {
      return true;
    }
  }

  return Generated;
}

export function CenteredObjectNoParent<
  T extends abstract new (
    ...args: any[]
  ) => CoreBaseBackedObject<CenteredEntityConcrete>,
>(constructor: T) {
  abstract class Generated extends CenteredObject(constructor) {
    shouldRebase: false = false;
    centered: true = true;

    debase(_context: CanvasContext): void {
      //
    }

    rebase(_context: CanvasContext) {
      //
    }
  }
  return Generated;
}
