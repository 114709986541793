import { Units } from "../../../../lib/measurements";
import { CoreContext } from "../../../calculations/types";
import { getTooltip } from "../../../tooltips/tooltips";
import { DEFAULT_SCOP } from "../../consts";
import { FieldType } from "../field-type";
import { PropertyField } from "../property-field";
import { BarrierLevel, Directivity } from "./plant-types";
import { shouldShowSCOPFields } from "./utils";

export function createHeatPumpNoiseTabFields(): PropertyField[] {
  return [
    {
      property: "plant.noiseReportSetting.soundPowerLevelDB",
      title: "Sound Power Level",
      hasDefault: false,
      isCalculated: false,
      requiresInput: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: null,
      units: Units.Decibel,
    },
    {
      property: "plant.noiseReportSetting.directivity",
      title: "Number of Reflecting Surfaces(Directivity)",
      hasDefault: false,
      isCalculated: false,
      requiresInput: false,
      type: FieldType.Choice,
      params: {
        choices: [
          { name: "1(Q2)", key: Directivity.Q2 },
          { name: "2(Q4)", key: Directivity.Q4 },
          { name: "3(Q8)", key: Directivity.Q8 },
        ],
      },
      multiFieldId: null,
    },
    {
      property: "plant.noiseReportSetting.distanceMM",
      title: "Distance to Assessment Point",
      hasDefault: false,
      isCalculated: false,
      requiresInput: false,
      type: FieldType.Number,
      params: { min: 0, max: null },
      multiFieldId: null,
      units: Units.Millimeters,
    },
    {
      property: "plant.noiseReportSetting.barrier",
      title: "Block Level",
      hasDefault: false,
      isCalculated: false,
      requiresInput: false,
      type: FieldType.Choice,
      params: {
        choices: [
          { name: "Complete blocked", key: BarrierLevel.Complete },
          { name: "Part blocked", key: BarrierLevel.Part },
          { name: "Not blocked", key: BarrierLevel.None },
        ],
      },
      multiFieldId: null,
    },
  ];
}

export function createHeatPumpEnergyTabFields(
  context: CoreContext,
): PropertyField[] {
  const res: PropertyField[] = [];
  res.push({
    property: "plant.canHeatToDHWTemp",
    title: "Able to heat to DHW temp",
    hint: getTooltip("HeatPump", "Able to heat to DHW temp"),
    hasDefault: true,
    isCalculated: false,
    type: FieldType.Boolean,
    params: null,
    isShown:
      context.featureFlags["release-refactored-heat-loss-reports"] !== "Old",
    multiFieldId: null,
    units: Units.None,
  });
  res.push({
    property: "plant.canPurgeLegionella",
    title: "Able to purge legionella",
    hint: getTooltip("HeatPump", "Able to purge legionella"),
    hasDefault: true,
    isCalculated: false,
    type: FieldType.Boolean,
    params: null,
    isShown:
      context.featureFlags["release-refactored-heat-loss-reports"] !== "Old",
    multiFieldId: null,
    units: Units.None,
  });

  res.push({
    property: "plant.SCOP",
    title: "SCOP",
    hint: getTooltip("HeatPump", "SCOP"),
    hasDefault: false,
    isCalculated: true,
    type: FieldType.Number,
    params: { min: 0, max: null, initialValue: DEFAULT_SCOP },
    isShown: shouldShowSCOPFields(context),
    multiFieldId: null,
    units: Units.None,
  });
  res.push({
    property: "plant.SCOPRatings",
    title: "SCOP Rating Table",
    hasDefault: false,
    isCalculated: false,
    type: FieldType.Divider,
    units: Units.None,
    isShown: shouldShowSCOPFields(context),
    multiFieldId: null,
    slot: true,
    slotButton: true,
  });
  return res;
}
