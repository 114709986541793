import { RoofSegment } from "./RoofSegmentation";

export class TriangleSegment implements RoofSegment {
  private baseLengthM: number;
  private triangleHeightM: number;
  private prismHeightM: number;
  private raisedHeightM: number;

  constructor(
    baseLengthM: number,
    triangleHeightM: number,
    prismHeightM: number,
    raisedHeightM: number,
  ) {
    this.baseLengthM = baseLengthM;
    this.triangleHeightM = triangleHeightM;
    this.prismHeightM = prismHeightM;
    this.raisedHeightM = raisedHeightM;
  }

  get areaM2(): number {
    return (this.baseLengthM * this.triangleHeightM) / 2;
  }

  get volumeM3(): number {
    return (
      (this.areaM2 * this.prismHeightM) / 3 + this.raisedHeightM * this.areaM2
    );
  }

  get slopeDeg(): number {
    return (
      Math.atan(this.prismHeightM / this.triangleHeightM) * (180 / Math.PI)
    );
  }
}
