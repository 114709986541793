import CoreBaseBackedObject from "../../../../common/src/api/coreObjects/lib/coreBaseBackedObject";
import { DrawableEntityConcrete } from "../../../../common/src/api/document/entities/concrete-entity";
import { PropertyFieldBase } from "../../../../common/src/api/document/entities/property-field";
import { getEntityIndividualName } from "../../../../common/src/api/document/entities/types";
import { PossibleFix } from "../../../../common/src/lib/possible-fix";
import { ProblemCategory } from "./problem-category";

/**
 * At this stage severities have been defined as the standard severity levels.
 *
 * We can always change this if needed.
 */
export enum ProblemSeverity {
  // Does not alert user, will only show in the problem panel
  Info = "info",
  // Warns the user, shows orange in the header
  Warning = "warning",
  // Warns the user of an error, shows red in the header
  // These should be reserved for serious issues that require immediate user action
  Error = "error",
}

export interface Problem {
  category: ProblemCategory;
  details: string;
  developerDetails?: string;
  possibleFixes?: PossibleFix[];
  helpArticleUrl?: string;
}

export type EntityProblem = Problem & {
  obj: CoreBaseBackedObject;
  property?: PropertyFieldBase;
};

export function isEntityProblem(problem: Problem): problem is EntityProblem {
  return "obj" in problem;
}

export function getProblemIndividualName(problem: Problem): string {
  if (isEntityProblem(problem)) {
    const entityName =
      getEntityIndividualName(
        problem.obj.filledEntity as DrawableEntityConcrete,
      ) ?? "Unnamed";

    if (problem.property) {
      return `${entityName} (${problem.property.title})`;
    }
    return entityName;
  }

  return problem.category.toString();
}
