import { assertUnreachableAggressive } from "../../../../../lib/utils";

export enum LayoutType {
  TopBottomSameEnd = "TopBottomSameEnd",
  TopBottomOppEnds = "TopBottomOppEnds",
  BottomOppEnds = "BottomOppEnds",
}

export type SameEndLayoutType = LayoutType.TopBottomSameEnd;
export type OppositeEndsLayoutType =
  | LayoutType.TopBottomOppEnds
  | LayoutType.BottomOppEnds;
export function isSameEnd(
  layoutType: LayoutType,
): layoutType is SameEndLayoutType {
  return layoutType === LayoutType.TopBottomSameEnd;
}
export function isOppositeEnds(
  layoutType: LayoutType,
): layoutType is OppositeEndsLayoutType {
  return !isSameEnd(layoutType);
}

export function getFriendlyLayoutTypeName(layoutType: LayoutType) {
  switch (layoutType) {
    case LayoutType.TopBottomSameEnd:
      return "Top and bottom same end";
    case LayoutType.TopBottomOppEnds:
      return "Top and bottom opposite ends";
    case LayoutType.BottomOppEnds:
      return "Bottom opposite ends";
    default:
      assertUnreachableAggressive(layoutType);
  }
}

export enum ConnectionMode {
  GenerateRightAnglePipes = "generate-right-angle-pipes",
  Custom = "custom",
}
export function getFriendlyConnectionModeName(mode: ConnectionMode): string {
  switch (mode) {
    case ConnectionMode.Custom:
      return "Custom";
    case ConnectionMode.GenerateRightAnglePipes:
      return "Generate 90° angle pipes";
  }
}

interface LayoutBase {
  layoutType: LayoutType;
  swapNormalPositions: boolean;
  connectionMode: ConnectionMode;
}

export interface SameEndLayout extends LayoutBase {
  layoutType: SameEndLayoutType;
  side: "left" | "right";
}

export interface OppositeEndsLayout extends LayoutBase {
  layoutType: OppositeEndsLayoutType;
}

export type ConnectionsLayout = SameEndLayout | OppositeEndsLayout;

export function isLayoutSameEnd(
  layout: ConnectionsLayout,
): layout is SameEndLayout {
  return isSameEnd(layout.layoutType);
}

export function isLayoutOppositeEnds(
  layout: ConnectionsLayout,
): layout is OppositeEndsLayout {
  return !isLayoutSameEnd(layout);
}

export function makeOppositeEndsLayout(
  options?: Partial<OppositeEndsLayout>,
): OppositeEndsLayout {
  const { connectionMode, layoutType, swapNormalPositions } = options ?? {};
  return {
    layoutType: layoutType ?? LayoutType.BottomOppEnds,
    connectionMode: connectionMode ?? ConnectionMode.GenerateRightAnglePipes,
    swapNormalPositions: swapNormalPositions ?? false,
  };
}

export function makeSameEndLayout(
  options?: Partial<SameEndLayout>,
): SameEndLayout {
  const { connectionMode, swapNormalPositions, side } = options ?? {};
  return {
    layoutType: LayoutType.TopBottomSameEnd,
    connectionMode: connectionMode ?? ConnectionMode.GenerateRightAnglePipes,
    swapNormalPositions: swapNormalPositions ?? false,
    side: side ?? "left",
  };
}
