import Flatten from "@flatten-js/core";
import * as TM from "transformation-matrix";
import { findFireSubGroup } from "../../../../common/src/api/calculations/utils";
import {
  isDrainage,
  isGas,
  isPressure,
  isStormwater,
  isVentilation,
  StandardFlowSystemUids,
} from "../../../../common/src/api/config";
import CoreLoadNode from "../../../../common/src/api/coreObjects/coreLoadNode";
import {
  determineConnectableSystemUid,
  validGrillTypes,
} from "../../../../common/src/api/coreObjects/utils";
import {
  DrawableEntityConcrete,
  hasExplicitSystemUid,
} from "../../../../common/src/api/document/entities/concrete-entity";
import LoadNodeEntity, {
  fillDefaultLoadNodeFields,
  NodeType,
  VentilationNode,
} from "../../../../common/src/api/document/entities/load-node-entity";
import { EntityType } from "../../../../common/src/api/document/entities/types";
import { Color, lighten } from "../../../../common/src/lib/color";
import { Coord } from "../../../../common/src/lib/coord";
import {
  convertMeasurementSystemNonNull,
  Units,
  UnitsContext,
} from "../../../../common/src/lib/measurements";
import {
  assertType,
  assertUnreachable,
} from "../../../../common/src/lib/utils";
import { DEFAULT_FONT_NAME } from "../../config";
import { rgb2style } from "../../lib/utils";
import { getDragPriority } from "../../store/document";
import { EntityDrawingArgs } from "../lib/drawable-object";
import { EntityPopupContent } from "../lib/entity-popups/types";
import {
  generateLoadNodeHeatmap,
  HeatmapMode,
  isHeatmapEnabled,
} from "../lib/heatmap/heatmap";
import { Interaction, InteractionType } from "../lib/interaction";
import { AttachableObject } from "../lib/object-traits/attachable-object";
import { CalculatedObject } from "../lib/object-traits/calculated-object";
import { CenteredObject } from "../lib/object-traits/centered-object";
import { ConnectableObject } from "../lib/object-traits/connectable";
import CoolDraggableObject from "../lib/object-traits/cool-draggable-object";
import { Core2Drawable } from "../lib/object-traits/core2drawable";
import {
  HoverableObject,
  HoverSiblingResult,
} from "../lib/object-traits/hoverable-object";
import { SelectableObject } from "../lib/object-traits/selectable";
import { SnappableObject } from "../lib/object-traits/snappable-object";
import { SVG_PATH, SvgPathLoader } from "../lib/svg-path-loader";
import { DrawingContext, ObjectConstructArgs } from "../lib/types";
import { getHighlightColor } from "../lib/utils";
import { DrawingMode } from "../types";
import { ConnectableObjectConcrete } from "./concrete-object";
import { applyHoverEffects, isFlowSystemActive, isLayoutActive } from "./utils";

const Base = CalculatedObject(
  SelectableObject(
    CoolDraggableObject(
      HoverableObject(
        AttachableObject(
          ConnectableObject(
            CenteredObject(SnappableObject(Core2Drawable(CoreLoadNode))),
          ),
        ),
      ),
    ),
  ),
);

enum DIR {
  UP = -Math.PI / 2,
  DOWN = Math.PI / 2,
  LEFT = -Math.PI,
  RIGHT = 0,
}

export default class DrawableLoadNode extends Base {
  customCopyObjects = true;

  // We could not add this to the base drawable class because
  // of some typescript thing so they have to be added at the concrete class.
  constructor(args: ObjectConstructArgs<LoadNodeEntity>) {
    super(args.context, args.obj);
    this.onSelect = args.onSelect;
    this.onInteractionComplete = args.onInteractionComplete;
    this.document = args.document;
  }

  get systemUid(): StandardFlowSystemUids | undefined {
    return determineConnectableSystemUid(this.globalStore, this.entity);
  }

  get maximumConnections(): number | null {
    switch (this.entity.node.type) {
      case NodeType.LOAD_NODE:
        return 2;
      case NodeType.DWELLING:
      case NodeType.FIRE:
        return null;
      case NodeType.VENTILATION:
        return 1;
      default:
        assertUnreachable(this.entity.node);
        return null;
    }
  }

  get position(): TM.Matrix {
    const scale = 1 / this.fromParentToWorldLength(1);
    return TM.transform(
      TM.translate(this.entity.center.x, this.entity.center.y),
      TM.scale(scale, scale),
    );
  }

  dragPriority = getDragPriority(EntityType.LOAD_NODE);
  minimumConnections = 0;

  getRadius(context: DrawingContext): number {
    const { vp } = context;
    const radius = Math.max(
      this.baseRadius,
      vp.surfaceToWorldLength(this.baseRadius / 50),
    );
    return radius;
  }

  isActive(): boolean {
    const systemUid = determineConnectableSystemUid(
      this.globalStore,
      this.entity,
    );
    if (!systemUid) {
      return true;
    }
    // Pressure node active under both Pressure mode and Drainage mode
    if (
      isLayoutActive(this.context, this.document.uiState, "drainage") &&
      isPressure(this.document.drawing.metadata.flowSystems[systemUid])
    ) {
      return true;
    }

    return isFlowSystemActive(this.context, this.document.uiState, systemUid);
  }

  baseWidth(context: DrawingContext) {
    const s = context.vp.currToSurfaceScale(context.ctx);
    const baseWidth = Math.max(2.0 / s, 10 / this.toWorldLength(1));
    return baseWidth;
  }

  drawConnectable(context: DrawingContext, args: EntityDrawingArgs): void {
    const { ctx, vp } = context;
    const { heatmapMode } = args;
    const baseRadius = this.baseRadius;
    const radius = this.getRadius(context);

    const filled = fillDefaultLoadNodeFields(context, this.entity);

    const thisIsGas = isGas(
      context.doc.drawing.metadata.flowSystems[filled.systemUidOption!],
    );

    ctx.lineWidth = this.baseWidth(context);

    // TODO: need better color management
    if (
      (args.selected || args.hasOverriddenField) &&
      (heatmapMode === HeatmapMode.Off ||
        this.document.uiState.drawingMode !== DrawingMode.Calculations) &&
      this.entity.node.type !== NodeType.VENTILATION
    ) {
      const sr = Math.max(
        baseRadius + 20,
        vp.surfaceToWorldLength(baseRadius / 50 + 2),
      );

      ctx.fillStyle = ctx.strokeStyle = rgb2style(
        getHighlightColor(
          args.selected,
          args.hasOverriddenField ? [Color.YELLOW] : [],
          {
            hex: lighten(filled.color!.hex, 50),
          },
        ),
      );

      ctx.beginPath();

      this.strokeShape(context, sr);
      ctx.fill();
    }

    ctx.fillStyle = filled.color!.hex;
    ctx.strokeStyle = lighten(filled.color!.hex, -10);

    if (args.withCalculation) {
      const calculation = this.globalStore.getOrCreateCalculation(this.entity);
      if (!calculation.pressureKPA && !thisIsGas && !calculation.psdUnits) {
        ctx.fillStyle = "#888888";
      }
      if (isHeatmapEnabled(this.document)) {
        if (heatmapMode !== undefined && calculation !== undefined) {
          const color = generateLoadNodeHeatmap(
            this,
            heatmapMode,
            calculation,
            filled,
          );
          if (color !== undefined) {
            ctx.fillStyle = color;
            ctx.strokeStyle = color;
            // ctx.shadowColor = color;
            // ctx.shadowBlur = 15;
          } else {
            return;
          }
        }
      }
    }

    if (!this.isActive()) {
      ctx.fillStyle = ctx.strokeStyle = "#AAAAAA";
    }

    ctx.beginPath();
    this.strokeShape(context, radius);
    ctx.fill();

    if (this.entity.linkedToUid && !args.forExport) {
      // draw chain link
      const other = this.globalStore.get(this.entity.linkedToUid);
      if (other) {
        const lineWidth = Math.max(vp.surfaceToWorldLength(2), 20);
        const otherLoc = this.toObjectCoord(other.toWorldCoord());

        ctx.strokeStyle = "#000000";
        ctx.lineWidth = lineWidth;
        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(otherLoc.x, otherLoc.y);
        ctx.stroke();
      }
    }

    const currentLoc = this.toObjectCoord(
      this.globalStore.get(this.entity.uid)!.toWorldCoord(),
    );

    let name = "";
    let newx = currentLoc.x;
    let newy = currentLoc.y;
    let distance = 210; // the 210 represents the distance from midpoint to new coordinates
    if (!this.entity.linkedToUid) {
      distance = -210;
    }

    if (typeof this.entity.customNodeId !== "undefined") {
      name = context.nodes.find(
        (node) =>
          node.id === this.entity.customNodeId ||
          node.uid === this.entity.customNodeId ||
          // We need to check dbid because legacy nodes are missing the id/uid attribute.
          node.dbid === this.entity.customNodeId,
      )!.name;

      const entities = Array.from(
        (
          this.globalStore.entitiesInLevel.get(
            this.document.uiState.levelUid,
          ) || new Set()
        ).values(),
      ).map((u) => this.globalStore.get(u)!.entity as DrawableEntityConcrete);

      // get the other node
      let other = null;
      if (!this.entity.linkedToUid) {
        for (let i = 0; i < entities.length; i++) {
          const e = entities[i];
          if (
            e.type === EntityType.LOAD_NODE &&
            e.linkedToUid === this.entity.uid
          ) {
            other = this.globalStore.get(e.uid);
          }
        }
      } else {
        other = this.globalStore.get(this.entity.linkedToUid);
      }

      if (other) {
        const otherLoc = this.toObjectCoord(other.toWorldCoord());
        // to get midpoint
        const midx = (otherLoc.x + currentLoc.x) / 2;
        const midy = (otherLoc.y + currentLoc.y) / 2;

        const angleAB = Math.atan2(
          otherLoc.y - currentLoc.y,
          otherLoc.x - currentLoc.x,
        );
        // to get the angle of the line from minpoint to a new coordinates, add 90 degrees
        // in radians, that is Math.PI / 2
        const angleCD = angleAB + Math.PI / 2;
        // now we can get the new coordinates
        newx = midx + distance * Math.cos(angleCD);
        newy = midy + distance * Math.sin(angleCD);
      } else {
        newy += distance;
      }
    } else if (this.computedName) {
      name = this.computedName;
      newy += distance;
    }
    ctx.fillStyle = "#AAA";

    ctx.font = 70 + "pt " + DEFAULT_FONT_NAME;
    const nameWidth = ctx.measureText(name).width;
    const offsetx = nameWidth / 2;
    ctx.fillStyle = "rgba(0, 255, 20, 0.13)";
    // the 70 represents the height of the font
    const textHight = 70;
    const offsetY = textHight / 2;
    ctx.fillRect(newx - offsetx, newy - offsetY, nameWidth, 70);
    ctx.fillStyle = "#007b1c";
    ctx.fillTextStable(
      name,
      newx - offsetx,
      newy - offsetY - 4,
      undefined,
      "top",
    );

    // ctx.shadowBlur = 15;
    if (this.auxillaryLabel) {
      const s = context.vp.currToSurfaceScale(context.ctx);
      const fontSize = Math.max(10 / s, (5 / s) * (5 + Math.log(s)));

      ctx.font = Math.round(fontSize) + "pt " + DEFAULT_FONT_NAME;
      const auxillaryLabelWidth = ctx.measureText(this.auxillaryLabel).width;
      const auxillaryLabelOffsetx = auxillaryLabelWidth / 2;
      ctx.fillStyle = "rgba(0, 255, 20, 0.13)";
      // the 70 represents the height of the font
      const auxillaryLabelHight = fontSize;

      const baseRadius = this.baseRadius;

      const auxillaryLabelOffsetY = auxillaryLabelHight + baseRadius;
      ctx.fillRect(
        newx - auxillaryLabelOffsetx,
        currentLoc.y + auxillaryLabelOffsetY - auxillaryLabelHight / 2,
        auxillaryLabelWidth,
        fontSize,
      );
      ctx.fillStyle = "#007b1c";
      ctx.fillTextStable(
        this.auxillaryLabel,
        newx - auxillaryLabelOffsetx,
        currentLoc.y + auxillaryLabelOffsetY,
        undefined,
        "middle",
      );
    }
  }

  get auxillaryLabel() {
    if (this.document.uiState.drawingMode === DrawingMode.Design) {
      if (this.entity.node.type === NodeType.VENTILATION) {
        const activeSystem =
          this.context.drawing.metadata.flowSystems[
            this.document.activeflowSystemUid
          ];

        if (isVentilation(activeSystem) && this.isActive()) {
          const liveCalc = this.globalStore.getOrCreateLiveCalculation(
            this.entity,
          );
          const calc = this.globalStore.getOrCreateCalculation(this.entity);

          const flowRate = liveCalc.flowRateLS || calc.flowRateLS;
          if (flowRate !== null) {
            const conv = convertMeasurementSystemNonNull(
              this.context.drawing.metadata.units,
              Units.LitersPerSecond,
              flowRate,
              UnitsContext.VENTILATION,
            );

            return `${Number(conv[1]).toFixed(1)} ${conv[0]}`;
          }
        }
      }
    }
    return "";
  }

  drawEntity(context: DrawingContext, args: EntityDrawingArgs): void {
    applyHoverEffects(context, this);
    const { ctx } = context;
    const radius = this.getRadius(context);

    if (this.entity.node.type === NodeType.FIRE) {
      super.drawEntity(context, args);
      const fireSubGroup = findFireSubGroup(
        context.drawing,
        this.entity.node.customEntityId,
        this.entity.node.subGroupId,
      );

      if (fireSubGroup === undefined) {
        console.warn(
          "Fire entity not found",
          this.entity.node.customEntityId,
          this.entity.node.subGroupId,
        );
        return;
      }
      const hexColor = fireSubGroup.nodeGroupHex;

      // Add your custom drawing instructions for the fire entity
      ctx.fillStyle = hexColor;
      ctx.strokeStyle = hexColor;

      // Example: Draw a simple rectangle with the fire entity's color
      // You can replace this with your desired shape and drawing instructions
      ctx.beginPath();
      ctx.moveTo(0, radius);
      for (let i = 1; i <= 7; i++) {
        ctx.lineTo(
          Math.sin((Math.PI * 2 * i) / 6) * radius,
          Math.cos((Math.PI * 2 * i) / 6) * radius,
        );
      }
      ctx.fillStyle = hexColor;
      ctx.lineWidth = 30;
      ctx.stroke();
      ctx.closePath();
    } else {
      super.drawEntity(context, args);
    }
  }

  strokeShape(context: DrawingContext, radius: number) {
    const { ctx } = context;
    switch (this.entity.node.type) {
      case NodeType.LOAD_NODE:
      case NodeType.FIRE:
        ctx.moveTo(0, radius);
        for (let i = 1; i < 6; i++) {
          ctx.lineTo(
            Math.sin((Math.PI * 2 * i) / 6) * radius,
            Math.cos((Math.PI * 2 * i) / 6) * radius,
          );
        }
        ctx.closePath();
        break;
      case NodeType.DWELLING:
        for (let i = 0.5; i < 3.5; i++) {
          ctx.lineTo(
            Math.sin((Math.PI * 2 * (i - 1)) / 4) * radius,
            Math.cos((Math.PI * 2 * (i - 1)) / 4) * radius,
          );
          ctx.lineTo(
            Math.sin((Math.PI * 2 * i) / 4) * radius,
            Math.cos((Math.PI * 2 * i) / 4) * radius,
          );
        }
        ctx.closePath();
        break;
      case NodeType.VENTILATION:
        this.drawVentGrills(context);
        break;
      default:
        assertUnreachable(this.entity.node);
    }
  }

  drawVentGrills(context: DrawingContext) {
    const { ctx } = context;
    const filled = fillDefaultLoadNodeFields(context, this.entity);
    assertType<VentilationNode>(filled.node);

    let systemUid = determineConnectableSystemUid(
      this.globalStore,
      this.entity,
    );
    if (!systemUid) {
      systemUid = StandardFlowSystemUids.VentilationSupply;
    }
    let angle = 0;
    const sideDuct = this.getConnectedSidePipe("")[0];
    if (sideDuct) {
      const endPointIdx = sideDuct.entity.endpointUid.findIndex(
        (e) => e === this.entity.uid,
      );
      if (endPointIdx === 1 || endPointIdx === 0) {
        angle = sideDuct.getSlopeWrt(endPointIdx);
      } else {
        angle = (sideDuct.shape as Flatten.Segment).slope;
      }
    }

    const baseWidth = this.baseWidth(context);
    ctx.lineWidth = baseWidth * 0.5;
    let fsRole = this.document.drawing.metadata.flowSystems[systemUid].role;
    if (fsRole === "vent-fan-exhaust") {
      if (filled.node.flowDirection === "in") {
        fsRole = "vent-extract";
      } else {
        fsRole = "vent-exhaust";
      }
    }

    const key = `${fsRole}.${filled.node.shape}.${filled.node.orientation}`;
    let yScale = this.heightMM / 300;
    const xScale = this.widthMM / 300;

    const da = 30; // arrow offset
    const dx = this.widthMM / 2;
    const dy = this.heightMM / 2;

    const oldTransform = ctx.getTransform();

    switch (key) {
      case "vent-intake.square.horizontal":
      case "vent-intake.slot.horizontal":
        this.drawArrow(context, DIR.DOWN, 0, -(dy + da));
        this.drawArrow(context, DIR.UP, 0, dy + da);
        this.drawArrow(context, DIR.RIGHT, -(dx + da), 0);
        this.drawArrow(context, DIR.LEFT, dx + da, 0);

        ctx.translate(-dx, -dy);
        ctx.scale(xScale, yScale);
        ctx.stroke(SvgPathLoader.get(SVG_PATH.INTAKE_SLOT_HORZ));
        break;
      case "vent-intake.square.vertical":
      case "vent-intake.slot.vertical":
        yScale = this.heightMM / 125;
        ctx.rotate(angle);
        this.drawArrow(context, DIR.RIGHT, -(dy + da), -this.widthMM / 3);
        this.drawArrow(context, DIR.RIGHT, -(dy + da), 0);
        this.drawArrow(context, DIR.RIGHT, -(dy + da), this.widthMM / 3);

        ctx.translate(-dy, -dx);
        ctx.scale(yScale, xScale);
        ctx.stroke(SvgPathLoader.get(SVG_PATH.INTAKE_SLOT_VERT));
        break;
      case "vent-intake.circ.horizontal":
        this.drawArrow(context, DIR.DOWN, 0, -(dy + da));
        this.drawArrow(context, DIR.UP, 0, dy + da);
        this.drawArrow(context, DIR.RIGHT, -(dx + da), 0);
        this.drawArrow(context, DIR.LEFT, dx + da, 0);

        ctx.translate(-dx, -dy);
        ctx.scale(xScale, yScale);
        ctx.stroke(SvgPathLoader.get(SVG_PATH.INTAKE_CIRC_HORZ));
        break;
      case "vent-intake.circ.vertical":
        ctx.rotate(angle + Math.PI / 2);
        this.drawArrow(context, DIR.UP, -this.widthMM / 3, dy + da);
        this.drawArrow(context, DIR.UP, 0, dy + da);
        this.drawArrow(context, DIR.UP, this.widthMM / 3, dy + da);

        yScale = this.heightMM / 110;
        ctx.translate(-dx, -dy);
        ctx.scale(xScale, yScale);
        ctx.stroke(SvgPathLoader.get(SVG_PATH.INTAKE_CIRC_VERT));
        break;
      case "vent-supply.square.horizontal":
      case "vent-supply.slot.horizontal":
        this.drawArrow(context, DIR.UP, 0, -dy - da, true);
        this.drawArrow(context, DIR.DOWN, 0, dy + da, true);
        this.drawArrow(context, DIR.LEFT, -dx - da, 0, true);
        this.drawArrow(context, DIR.RIGHT, dx + da, 0, true);

        ctx.translate(-dx, -dy);
        ctx.scale(xScale, yScale);
        ctx.stroke(SvgPathLoader.get(SVG_PATH.SUPPLY_SLOT_HORZ));
        break;
      case "vent-supply.square.vertical":
      case "vent-supply.slot.vertical":
        yScale = this.heightMM / 125;
        ctx.rotate(angle);
        this.drawArrow(context, DIR.LEFT, -(dy + da), -this.widthMM / 3, true);
        this.drawArrow(context, DIR.LEFT, -(dy + da), 0, true);
        this.drawArrow(context, DIR.LEFT, -(dy + da), this.widthMM / 3, true);

        ctx.translate(-dy, -dx);
        ctx.scale(yScale, xScale);
        ctx.stroke(SvgPathLoader.get(SVG_PATH.SUPPLY_SLOT_VERT));
        break;
      case "vent-supply.circ.horizontal":
        this.drawArrow(context, DIR.UP, 0, -dy - da, true);
        this.drawArrow(context, DIR.DOWN, 0, dy + da, true);
        this.drawArrow(context, DIR.LEFT, -dx - da, 0, true);
        this.drawArrow(context, DIR.RIGHT, dx + da, 0, true);

        ctx.translate(-dx, -dy);
        ctx.scale(xScale, yScale);
        ctx.stroke(SvgPathLoader.get(SVG_PATH.SUPPLY_CIRC_HORZ));
        break;
      case "vent-supply.circ.vertical":
        yScale = this.heightMM / 110;
        ctx.rotate(angle + Math.PI / 2);
        this.drawArrow(context, DIR.DOWN, 0, dy + da, true);
        this.drawArrow(context, DIR.DOWN, this.widthMM / 3, dy + da, true);
        this.drawArrow(context, DIR.DOWN, -this.widthMM / 3, dy + da, true);

        ctx.translate(-dx, -dy);
        ctx.scale(xScale, yScale);
        ctx.stroke(SvgPathLoader.get(SVG_PATH.SUPPLY_CIRC_VERT));
        break;
      case "vent-extract.square.horizontal":
      case "vent-extract.slot.horizontal":
        this.drawArrow(context, DIR.DOWN, 0, -(dy + da));
        this.drawArrow(context, DIR.UP, 0, dy + da);
        this.drawArrow(context, DIR.RIGHT, -(dx + da), 0);
        this.drawArrow(context, DIR.LEFT, dx + da, 0);

        ctx.translate(-dx, -dy);
        ctx.scale(xScale, yScale);
        ctx.stroke(SvgPathLoader.get(SVG_PATH.EXTRACT_SLOT_HORZ));
        break;
      case "vent-extract.square.vertical":
      case "vent-extract.slot.vertical":
        yScale = this.heightMM / 40;
        ctx.rotate(angle);
        this.drawArrow(context, DIR.RIGHT, -dy - da, -this.widthMM / 3);
        this.drawArrow(context, DIR.RIGHT, -dy - da, 0);
        this.drawArrow(context, DIR.RIGHT, -dy - da, this.widthMM / 3);

        ctx.translate(-dy, -dx);
        ctx.scale(yScale, xScale);
        ctx.stroke(SvgPathLoader.get(SVG_PATH.EXTRACT_SLOT_VERT));
        break;
      case "vent-extract.circ.horizontal":
        this.drawArrow(context, DIR.DOWN, 0, -(dy + da));
        this.drawArrow(context, DIR.UP, 0, dy + da);
        this.drawArrow(context, DIR.RIGHT, -(dx + da), 0);
        this.drawArrow(context, DIR.LEFT, dx + da, 0);

        ctx.translate(-dx, -dy);
        ctx.scale(xScale, yScale);
        ctx.stroke(SvgPathLoader.get(SVG_PATH.EXTRACT_CIRC_HORZ));
        break;
      case "vent-extract.circ.vertical":
        yScale = this.heightMM / 110;
        ctx.rotate(angle + Math.PI / 2);
        this.drawArrow(context, DIR.UP, 0, dy + da);
        this.drawArrow(context, DIR.UP, this.widthMM / 3, dy + da);
        this.drawArrow(context, DIR.UP, -this.widthMM / 3, dy + da);

        ctx.translate(-dx, -dy);
        ctx.scale(xScale, yScale);
        ctx.stroke(SvgPathLoader.get(SVG_PATH.EXTRACT_CIRC_VERT));
        break;
      case "vent-exhaust.square.horizontal":
      case "vent-exhaust.slot.horizontal":
        this.drawArrow(context, DIR.UP, 0, -dy - da, true);
        this.drawArrow(context, DIR.DOWN, 0, dy + da, true);
        this.drawArrow(context, DIR.LEFT, -dx - da, 0, true);
        this.drawArrow(context, DIR.RIGHT, dx + da, 0, true);

        ctx.translate(-dx, -dy);
        ctx.scale(xScale, yScale);
        ctx.stroke(SvgPathLoader.get(SVG_PATH.EXHAUST_SLOT_HORZ));
        break;
      case "vent-exhaust.square.vertical":
      case "vent-exhaust.slot.vertical":
        yScale = this.heightMM / 125;
        ctx.rotate(angle);
        this.drawArrow(context, DIR.LEFT, -dy - da, 0, true);
        this.drawArrow(context, DIR.LEFT, -dy - da, this.widthMM / 3, true);
        this.drawArrow(context, DIR.LEFT, -dy - da, -this.widthMM / 3, true);

        ctx.translate(-dy, -dx);
        ctx.scale(yScale, xScale);
        ctx.stroke(SvgPathLoader.get(SVG_PATH.EXHAUST_SLOT_VERT));
        break;
      case "vent-exhaust.circ.horizontal":
        this.drawArrow(context, DIR.UP, 0, -dy - da, true);
        this.drawArrow(context, DIR.DOWN, 0, dy + da, true);
        this.drawArrow(context, DIR.LEFT, -dx - da, 0, true);
        this.drawArrow(context, DIR.RIGHT, dx + da, 0, true);

        ctx.translate(-dx, -dy);
        ctx.scale(xScale, yScale);
        ctx.stroke(SvgPathLoader.get(SVG_PATH.EXHAUST_CIRC_HORZ));
        break;
      case "vent-exhaust.circ.vertical":
        ctx.rotate(angle + Math.PI / 2);
        this.drawArrow(context, DIR.DOWN, -this.widthMM / 3, dy + da, true);
        this.drawArrow(context, DIR.DOWN, 0, dy + da, true);
        this.drawArrow(context, DIR.DOWN, this.widthMM / 3, dy + da, true);

        yScale = this.heightMM / 110;
        ctx.translate(-dx, -dy);
        ctx.scale(xScale, yScale);
        ctx.stroke(SvgPathLoader.get(SVG_PATH.EXHAUST_CIRC_VERT));
        break;
    }

    ctx.setTransform(oldTransform);
  }

  drawLine(
    context: DrawingContext,
    startX: number,
    startY: number,
    endX: number,
    endY: number,
  ) {
    const { ctx } = context;
    ctx.beginPath();
    ctx.moveTo(startX, startY);
    ctx.lineTo(endX, endY);
    ctx.stroke();
  }

  drawArrow(
    context: DrawingContext,
    angle: number,
    xOffset: number,
    yOffset: number,
    isOutwards = false,
    length = 200,
  ) {
    const { ctx } = context;
    const arrowHeadLength = 20; // Length of the arrowhead lines
    const arrowHeadWidth = Math.PI / 6; // Width of the arrowhead in radians

    let lineStartX, lineStartY;

    if (isOutwards) {
      // Line starts at (xOffset, yOffset) and extends in the direction of the angle
      lineStartX = xOffset;
      lineStartY = yOffset;
    } else {
      // Line starts backwards from the arrowhead position
      lineStartX = xOffset - length * Math.cos(angle);
      lineStartY = yOffset - length * Math.sin(angle);
    }

    // Determine the end of the line based on the direction
    const lineEndX = isOutwards ? xOffset + length * Math.cos(angle) : xOffset;
    const lineEndY = isOutwards ? yOffset + length * Math.sin(angle) : yOffset;

    // Use the new drawLine function to draw the line
    this.drawLine(context, lineStartX, lineStartY, lineEndX, lineEndY);

    // Determine arrowhead base (where to draw the arrowhead)
    const arrowBaseX = isOutwards ? lineEndX : xOffset;
    const arrowBaseY = isOutwards ? lineEndY : yOffset;

    // Calculate arrowhead points
    const arrowLeftX =
      arrowBaseX - arrowHeadLength * Math.cos(angle - arrowHeadWidth);
    const arrowLeftY =
      arrowBaseY - arrowHeadLength * Math.sin(angle - arrowHeadWidth);
    const arrowRightX =
      arrowBaseX - arrowHeadLength * Math.cos(angle + arrowHeadWidth);
    const arrowRightY =
      arrowBaseY - arrowHeadLength * Math.sin(angle + arrowHeadWidth);

    // Draw the arrowhead
    ctx.beginPath();
    ctx.moveTo(arrowBaseX, arrowBaseY); // Arrowhead tip
    ctx.lineTo(arrowLeftX, arrowLeftY);
    ctx.lineTo(arrowRightX, arrowRightY);
    ctx.closePath(); // Completes the triangle for the arrowhead
    ctx.stroke();
  }

  inBounds(objectCoord: Coord, _objectRadius?: number): boolean {
    if (!this.isActive()) {
      return false;
    }
    return (
      objectCoord.x ** 2 + objectCoord.y ** 2 <=
      (this.baseRadius + this.attachmentOffset) ** 2
    );
  }

  flowSystemsCompatible(a: string, b: string): boolean {
    if (a === b) {
      return true;
    }
    if (isDrainage(this.document.drawing.metadata.flowSystems[a])) {
      return true;
    }
    return false;
  }

  offerInteraction(interaction: Interaction): DrawableEntityConcrete[] | null {
    // get the thing that is connectable
    const result = super.offerInteraction(interaction);
    if (!result) {
      return result;
    }

    let system2: string | null = null;
    switch (interaction.type) {
      case InteractionType.CONTINUING_CONDUIT:
      case InteractionType.STARTING_CONDUIT:
        system2 = interaction.system?.uid || null;
        break;
      case InteractionType.INSERT:
        system2 = interaction.systemUid;
        break;
      case InteractionType.SNAP_ONTO_RECEIVE:
        if (hasExplicitSystemUid(interaction.src)) {
          system2 = interaction.src.systemUid;
        }
        break;
      case InteractionType.SNAP_ONTO_SEND:
        if (hasExplicitSystemUid(interaction.dest)) {
          system2 = interaction.dest.systemUid;
        }
        break;
      case InteractionType.EXTEND_NETWORK:
        system2 = interaction.systemUid;
        break;
      case InteractionType.LINK_ENTITY:
        return [this.entity];
    }

    const systemUid = determineConnectableSystemUid(
      this.globalStore,
      this.entity,
    );

    const connections = this.globalStore.getConnections(this.entity.uid);
    // if it's a stormwater node, it can only connect to a stormwater pipe or/and a backup stormwater pipe

    // the result is with the assumption that there are 2 max connections.
    // but the 2 connections is only to manage allowing a sewer connection.
    // So be more restrictive than the super behavior that allows 2 max connections,
    // and make sure that only sewer + other is allowed for that case.

    if (connections.length !== 1) {
      // connections.length === 0 or connections.length === 2
      return result;
    }

    // need to restrict it.
    const p1 = this.globalStore.getObjectOfTypeOrThrow(
      EntityType.CONDUIT,
      connections[0],
    );
    const system1 = p1.entity.systemUid;

    if (isStormwater(this.document.drawing.metadata.flowSystems[systemUid!])) {
      return isStormwater(
        this.document.drawing.metadata.flowSystems[system1!],
      ) && isStormwater(this.document.drawing.metadata.flowSystems[system2!])
        ? system1 === system2
          ? null
          : result
        : null;
    }

    if (
      system2 &&
      isDrainage(this.document.drawing.metadata.flowSystems[system2]) &&
      !isDrainage(this.document.drawing.metadata.flowSystems[system1])
    ) {
      return result;
    }

    if (
      isDrainage(this.document.drawing.metadata.flowSystems[system1]) &&
      !(
        system2 &&
        isDrainage(this.document.drawing.metadata.flowSystems[system2])
      )
    ) {
      return result;
    }

    return null;
  }

  getCopiedObjects(): ConnectableObjectConcrete[] {
    const res: ConnectableObjectConcrete[] = [this];
    if (this.entity.linkedToUid) {
      const linkedNode = this.globalStore.get(
        this.entity.linkedToUid,
      ) as ConnectableObjectConcrete;
      if (linkedNode) {
        res.push(linkedNode);
      }
    }
    return res;
  }

  getPopupContent(): EntityPopupContent[] {
    return [];
  }

  getHoverSiblings(): HoverSiblingResult[] {
    return [];
  }

  getAttachCoords(): [Coord, Coord, Coord, Coord] {
    return [
      // left
      { x: -this.baseRadius - this.attachmentOffset, y: 0 },
      // right
      { x: this.baseRadius + this.attachmentOffset, y: 0 },
      // top
      { x: 0, y: -this.baseRadius - this.attachmentOffset },
      // bottom
      { x: 0, y: this.baseRadius + this.attachmentOffset },
    ];
  }

  updating = false;
  onUpdate() {
    if (this.updating) {
      return;
    }
    this.updating = true;
    super.onUpdate();

    this.validateVentNodeFlowDirection();

    this.updating = false;
  }

  validateVentNodeFlowDirection() {
    if (this.entity.node.type !== NodeType.VENTILATION) {
      return;
    }

    const systemUid = determineConnectableSystemUid(
      this.globalStore,
      this.entity,
    );
    if (!systemUid) {
      return;
    }

    const fs = this.drawing.metadata.flowSystems[systemUid];
    const validFlows = validGrillTypes[fs.role]?.map((x) => x.flowDirection);

    if (validFlows && validFlows.length === 1) {
      if (this.entity.node.flowDirection !== validFlows[0]) {
        this.entity.node.flowDirection = validFlows[0];
      }
    }
  }
}
