import Flatten from "@flatten-js/core";
import * as pc from "martinez-polygon-clipping";
import { RoomCalcDebug } from "../../api/document/calculations-objects/room-calculation";
import { Coord, coorEquals, coord2Point, coordDist2 } from "../coord";
import { Logger } from "../logger";
import { SentryError } from "../sentry-error";
import { EPS, cloneNaive } from "../utils";
/**
 * What percentage of `a` is `b`, expressed as a string with a fixed number of decimal places.
 */
export function percentString(a: number, b: number, fixed: number = 2) {
  return (100 * (a / b)).toFixed(fixed);
}

export function numberFormatter(num: number) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
}

// check if p2 is on the projection ray, starting from p1, with a direction vector of vec
export function isPointOnRay(
  p1: Flatten.Point,
  vec: Flatten.Vector,
  p2: Flatten.Point,
): boolean {
  const dx = p2.x - p1.x;
  const dy = p2.y - p1.y;
  if (Math.abs(dx * vec.y - dy * vec.x) > EPS) {
    return false;
  }
  return Math.abs(dx * vec.x + dy * vec.y) < EPS || dx * vec.x + dy * vec.y > 0;
}
// Represents the angle in the range [-PI, PI]
export function canonizeAngleRad(a: number) {
  return (
    ((((a + Math.PI) % (Math.PI * 2)) + Math.PI * 2) % (Math.PI * 2)) - Math.PI
  );
}

export function canonizeAngleDeg(a: number) {
  return ((((a + 180) % 360) + 360) % 360) - 180;
}

export function angleDiffRad(a: number, b: number) {
  return canonizeAngleRad(a - b);
}

export function angleDiffCWRad(from: number, to: number) {
  const a = canonizeAngleRad(to - from);
  return a < 0 ? a + Math.PI * 2 : a;
}

export function angleDiffCWDeg(from: number, to: number) {
  return (
    (angleDiffCWRad((from * Math.PI) / 180, (to * Math.PI) / 180) * 180) /
    Math.PI
  );
}

export function bisectAngleRadCW(a: number, b: number) {
  return a + angleDiffCWRad(a, b) / 2;
}

export function bisectAngleDegCW(a: number, b: number) {
  return (
    (bisectAngleRadCW((a * Math.PI) / 180, (b * Math.PI) / 180) * 180) / Math.PI
  );
}

export function angleDiffDeg(a: number, b: number) {
  return ((a - b + (180 % 360) + 360) % 360) - 180;
}

export function isRightAngleRad(a: number, tolerance: number = EPS) {
  return (
    Math.abs(angleDiffRad(canonizeAngleRad(a), Math.PI / 2)) <= tolerance ||
    Math.abs(angleDiffRad(canonizeAngleRad(a), -Math.PI / 2)) <= tolerance
  );
}

export function is45AngleRad(a: number, tolerance: number = EPS) {
  return (
    Math.abs(angleDiffRad(angleDiffRad(a, Math.PI), Math.PI / 4)) <=
      tolerance ||
    Math.abs(angleDiffRad(angleDiffRad(a, Math.PI), -Math.PI / 4)) <= tolerance
  );
}

export function isStraightRad(a: number, tolerance: number = EPS) {
  return angleDiffRad(Math.PI, canonizeAngleRad(a)) <= tolerance;
}

export function isParallelRad(a: number, b: number, tolerance: number = EPS) {
  return (
    Math.abs(angleDiffRad(a, b)) <= tolerance ||
    Math.abs(angleDiffRad(a, b + Math.PI)) <= tolerance
  );
}

export function isParallelDeg(a: number, b: number, tolerance: number = EPS) {
  return (
    Math.abs(angleDiffDeg(a, b)) <= tolerance ||
    Math.abs(angleDiffDeg(a, b + 180)) <= tolerance
  );
}

export function isHorizontalRad(a: number, tolerance: number = EPS) {
  return (
    Math.abs(canonizeAngleRad(a)) <= tolerance ||
    Math.abs(canonizeAngleRad(a - Math.PI)) <= tolerance
  );
}

export function isSlashAngleRad(a: number, tolerance: number = EPS) {
  return (
    Math.abs(a % (Math.PI / 2)) >= tolerance &&
    Math.abs(a % (Math.PI / 2)) <= Math.PI / 2 - tolerance
  );
}
export function polygonClipping(
  a: Coord[],
  b: Coord[],
  isLegacy = true,
): Coord[][] {
  if (a.length < 3) {
    Logger.warn(
      "Attempted to clip polygon with less than 3 vertices",
      { a },
      { b },
    );
    return [];
  }

  if (b.length < 3) {
    Logger.warn(
      "Attempted to clip polygon with less than 3 vertices, returning 'a' without clipping",
      {},
      { a },
      { b },
    );
    return [a];
  }

  if (polygonDirectedAreaM2(a) < 0) {
    a.reverse();
  }
  if (polygonDirectedAreaM2(b) < 0) {
    b.reverse();
  }
  return polygonClippingOneWay(
    cloneNaive(a),
    cloneNaive(b),
    POLYGON_OPERATION.CLIPPING,
    isLegacy,
  );
}

export function polygonDifference(
  a: Coord[],
  b: Coord[],
  isLegacy = true,
  print = false,
  debugObj?: RoomCalcDebug,
): Coord[][] {
  if (polygonDirectedAreaM2(a) < 0) {
    a.reverse();
  }
  if (polygonDirectedAreaM2(b) < 0) {
    b.reverse();
  }
  let ans = polygonClippingOneWay(
    cloneNaive(a),
    cloneNaive(b),
    POLYGON_OPERATION.DIFFERENCE,
    isLegacy,
    print,
    debugObj,
  );
  return ans;
}

export enum POLYGON_OPERATION {
  CLIPPING = "CLIPPING",
  DIFFERENCE = "DIFFERENCE",
}

function coordsToPolygon(coords: Coord[]): pc.Geometry | null {
  if (coords.length < 3) {
    Logger.warn("Cannot create Polygon, it must have at least 3 vertices", {
      coords,
    });
    return null;
  }

  const geom = [coords.map((x) => [x.x, x.y])];
  geom[0].push(geom[0][0]);

  return geom;
}

function polygonClippingOneWay(
  a: Coord[],
  b: Coord[],
  operation: POLYGON_OPERATION,
  isLegacy = true,
  print = false,
  debugObj?: RoomCalcDebug,
): Coord[][] {
  if (!isLegacy) {
    const aGeom: pc.Geometry | null = coordsToPolygon(a);
    const bGeom: pc.Geometry | null = coordsToPolygon(b);
    if (!aGeom || !bGeom) {
      throw new SentryError(
        "Cannot clip polygons, one of them is not valid",
        {},
        { aGeom, bGeom },
      );
    }

    const result = (
      operation === POLYGON_OPERATION.CLIPPING ? pc.intersection : pc.diff
    )(aGeom, bGeom) as [number, number][][][];

    return result
      ? result.map((x) =>
          x[0].slice(0, x[0].length - 1).map((y) => ({ x: y[0], y: y[1] })),
        )
      : [];
  }
  if (print) {
    console.log("In polygonClippingOneWay", { debugObj });
  }
  if (a.length <= 2 || b.length <= 2) return [];
  for (let i = 0; i < a.length; i++) {
    // Keep this, ensure the point not exactly the same
    // minor effect to the result as unit here is mm, each adjust will adjust 0.3mm like very unlikely to have more than one adjustment
    a[i].x += Math.PI / 20;
    a[i].y += Math.PI / 20;
  }

  for (let i = 0; i < a.length; i++) {
    if (b.some((x) => coorEquals(x, a[i], EPS))) {
      if (print) {
        console.log("Same point", { a, b });
      }
      return polygonClippingOneWay(a, b, operation, isLegacy, print, debugObj);
    }
  }

  class Graph {
    positions: Coord[] = [];
    edges: number[][] = [];
    addVertex(pos: Coord) {
      if (this.positions.some((x) => coorEquals(x, pos)))
        return this.positions.findIndex((x) => coorEquals(x, pos));
      this.positions.push(pos);
      this.edges.push([]);
      return this.positions.length - 1;
    }
    addEdge(a: number, b: number) {
      this.edges[a].push(b);
      this.edges[b].push(a);
    }

    // isInside means the vertex choiced to do DFS
    DFSFindValidPolygon(
      isInside: boolean[],
      checkMidCond: (a: Coord[], b: Coord[], midPoint: Coord) => boolean,
      print = false,
    ) {
      if (print) {
        console.log("In DFSFindValidPolygon", { isInside });
      }
      let visited = new Set<number>();
      let polygons: Coord[][] = [];
      for (let i = 0; i < this.positions.length; i++) {
        if (visited.has(i) || !isInside[i]) continue;
        visited.add(i);

        let currentPolygon: number[] = [i];
        if (print) {
          console.log("Checking polygon starting from", i);
        }
        while (1) {
          let ida = currentPolygon[currentPolygon.length - 1];
          let pa = this.positions[ida];
          let found = false;
          for (let j = 0; j < this.edges[ida].length; j++) {
            let idb = this.edges[ida][j];
            let pb = this.positions[idb];
            if (!isInside[idb]) continue;
            let mid = { x: (pa.x + pb.x) / 2, y: (pa.y + pb.y) / 2 };
            if (!checkMidCond(a, b, mid)) {
              if (print) {
                console.log(
                  "Midpoint",
                  mid,
                  " between ",
                  ida,
                  idb,
                  "is not valid",
                );

                if (ida === 10 && idb === 11) {
                  console.log({
                    a,
                    b,
                    pointInsidePolygonA: pointInsidePolygon(mid, a),
                    pointInsidePolygonB: pointInsidePolygon(mid, b),
                    pointOnPolygonEdgeA: pointOnPolygonEdge(mid, a),
                    pointOnPolygonEdgeB: pointOnPolygonEdge(mid, b),
                  });
                }
              }
              continue;
            }
            if (
              currentPolygon.length >= 2 &&
              idb === currentPolygon[currentPolygon.length - 2]
            )
              continue;
            visited.add(idb);
            currentPolygon.push(idb);
            found = true;
            break;
          }
          if (found && print) {
            console.log("Adding", currentPolygon[currentPolygon.length - 1]);
          } else if (print) {
            console.log("Didn't find any valid next point");
          }
          if (currentPolygon[0] === currentPolygon[currentPolygon.length - 1]) {
            currentPolygon.pop();
            break;
          }
          if (!found) {
            currentPolygon = [];
            break;
          }
          if (
            currentPolygon
              .slice(0, -1)
              .some((x) => x === currentPolygon[currentPolygon.length - 1])
          ) {
            while (
              currentPolygon[0] !== currentPolygon[currentPolygon.length - 1]
            )
              currentPolygon.shift();
            currentPolygon.pop();
            break;
          }
        }
        if (currentPolygon.length === 0) continue;
        polygons.push(currentPolygon.map((x) => this.positions[x]));
      }

      return polygons;
    }
    getClippedPolygons() {
      let isInside: boolean[] = [];
      for (let i = 0; i < this.positions.length; i++) {
        if (
          pointInsidePolygon(this.positions[i], a) &&
          pointInsidePolygon(this.positions[i], b)
        )
          isInside.push(true);
        else isInside.push(false);
      }
      return this.DFSFindValidPolygon(
        isInside,
        (a: Coord[], b: Coord[], midPoint: Coord) => {
          return (
            pointInsidePolygon(midPoint, a) && pointInsidePolygon(midPoint, b)
          );
        },
      );
    }
    getDifferencePolygons(print = false, debugObj?: RoomCalcDebug) {
      // The idea is, skimming through cycles that are purely outside the target polygon
      // should get all the intersecting "difference" polygons.
      let isInside: boolean[] = [];
      for (let i = 0; i < this.positions.length; i++) {
        if (
          pointInsidePolygon(this.positions[i], a) &&
          !pointInsidePolygon(this.positions[i], b, true)
        )
          isInside.push(true);
        else isInside.push(false);
      }

      for (const [i, inside] of isInside.entries()) {
        if (inside) {
          const p0 = this.positions[i];
          const p1 = this.positions[(i + 1) % this.positions.length];

          let vector = Flatten.vector(
            Flatten.point(p0.x, p0.y),
            Flatten.point(p1.x, p1.y),
          );
          if (vector.length > EPS) {
            vector = vector.normalize();
          } else {
            vector = Flatten.vector(1, 0);
          }

          if (debugObj) {
            debugObj.push({
              chain: [
                p0,
                Flatten.point(p0.x, p0.y).translate(
                  vector.normalize().multiply(500),
                ),
              ],
              color: "#00FFFF",
              dash: [],
              role: "polygon-difference",
            });
          }
        }
      }

      if (debugObj) {
        for (let i = 0; i < this.positions.length; i++) {
          const p0 = this.positions[i];
          const p1 = this.positions[(i + 1) % this.positions.length];
          debugObj.push({
            chain: [p0, p1],
            color: isInside[i] ? "#FF0033" : "#FF3300",
            dash: [1, 10],
            text: "   ".repeat(i % 5) + i.toString(),
            role: "polygon-difference",
          });
        }
      }

      return this.DFSFindValidPolygon(
        isInside,
        (a: Coord[], b: Coord[], midPoint: Coord) => {
          return (
            pointInsidePolygon(midPoint, a) &&
            !pointInsidePolygon(midPoint, b, true)
          );
        },
        print,
      );
    }
  }
  let graph = new Graph();
  // Make list of midpoints within each line - generated by intersections
  // with the other polygon.
  const lineMidsA: Coord[][] = [];
  const lineMidsB: Coord[][] = [];
  for (let i = 0; i < a.length; i++) {
    lineMidsA.push([]);
  }
  for (let i = 0; i < b.length; i++) {
    lineMidsB.push([]);
  }
  for (let i = 0; i < a.length; i++) {
    const a0 = a[i];
    const a1 = a[(i + 1) % a.length];
    const pa0 = Flatten.point(a0.x, a0.y);
    const pa1 = Flatten.point(a1.x, a1.y);

    for (let j = 0; j < b.length; j++) {
      const b0 = b[j];
      const b1 = b[(j + 1) % b.length];
      const pb0 = Flatten.point(b0.x, b0.y);
      const pb1 = Flatten.point(b1.x, b1.y);
      const linea = Flatten.segment(pa0, pa1);
      const lineb = Flatten.segment(pb0, pb1);

      let intersection = linea.intersect(lineb);
      if (intersection.length === 0) continue;
      let newPoint = intersection[0];
      lineMidsA[i].push({ x: newPoint.x, y: newPoint.y });
      lineMidsB[j].push({ x: newPoint.x, y: newPoint.y });
    }
  }

  // Sort midpoints array of polygon by distance order to the start point.
  let sort = (lineMids: Coord[][], point: Coord[]) => {
    for (let i = 0; i < point.length; i++) {
      const a0 = point[i];
      const a1 = point[(i + 1) % point.length];
      if (!lineMids[i].some((x) => coorEquals(x, a0))) {
        lineMids[i].push({ x: a0.x, y: a0.y });
      }
      if (!lineMids[i].some((x) => coorEquals(x, a1))) {
        lineMids[i].push({ x: a1.x, y: a1.y });
      }
      lineMids[i].sort((a, b) => {
        let va = { x: a.x - a0.x, y: a.y - a0.y };
        let vb = { x: b.x - a0.x, y: b.y - a0.y };
        let length = (v: Coord) => v.x * v.x + v.y * v.y;
        return length(va) - length(vb);
      });
    }
  };
  sort(lineMidsA, a);
  sort(lineMidsB, b);

  // Build graph. Connect intersections of the polygons together into a
  // "mesh" graph.
  for (let i = 0; i < lineMidsA.length; i++) {
    for (let j = 0; j < lineMidsA[i].length - 1; j++) {
      const a0 = lineMidsA[i][j];
      const a1 = lineMidsA[i][j + 1];
      const a0pos = graph.addVertex(a0);
      const a1pos = graph.addVertex(a1);
      graph.addEdge(a0pos, a1pos);
    }
  }
  for (let i = 0; i < lineMidsB.length; i++) {
    for (let j = 0; j < lineMidsB[i].length - 1; j++) {
      let b0 = lineMidsB[i][j],
        b1 = lineMidsB[i][j + 1];
      let b0pos = graph.addVertex(b0);
      let b1pos = graph.addVertex(b1);
      graph.addEdge(b0pos, b1pos);
    }
  }

  switch (operation) {
    case POLYGON_OPERATION.CLIPPING:
      return graph.getClippedPolygons();
    case POLYGON_OPERATION.DIFFERENCE:
      if (print) {
        console.warn("Clipped polygons", {
          clippedPolygons: graph.getClippedPolygons(),
          debugObj: debugObj,
          graph,
        });
      }
      return graph.getDifferencePolygons(print, debugObj);
  }
}

export function polygonIntersectionAreaM2(
  a: Coord[],
  b: Coord[],
  isLegacy = true,
): number {
  let clipped = polygonClipping(a, b, isLegacy);

  if (a.length === 0 || b.length === 0) return 0;
  if (clipped.length === 0) return 0;
  else {
    let area = clipped.map((x) => getAreaM2(x)).reduce((a, b) => a + b, 0);
    return area;
  }
}

export function polygonDirectedAreaM2(polygon: Coord[]) {
  let area = 0;
  for (let i = 0; i < polygon.length; i++) {
    let j = (i + 1) % polygon.length;
    area += polygon[i].x * polygon[j].y;
    area -= polygon[i].y * polygon[j].x;
  }
  area /= 2;
  return area / 1e6;
}

export function removeDuplicates<T extends Coord>(
  polygon: T[],
  EPS: number = 1e-8,
) {
  let ans: T[] = [];
  for (let i = 0; i < polygon.length; i++) {
    if (!coorEquals(polygon[i], polygon[(i + 1) % polygon.length], EPS)) {
      ans.push(polygon[i]);
    }
  }
  return ans;
}

export function getAreaM2(polygon: Coord[]) {
  return Math.abs(polygonDirectedAreaM2(polygon));
}

export function isClockWise(coords: Coord[]): boolean {
  let sum = 0;
  for (let i = 0; i < coords.length; i++) {
    let x1 = coords[i].x;
    let y1 = coords[i].y;
    let x2 = coords[(i + 1) % coords.length].x;
    let y2 = coords[(i + 1) % coords.length].y;
    sum += (x2 - x1) * (y2 + y1);
  }
  return sum > 0;
}
export function distanceToPoint(p1: Coord, p2: Coord) {
  return Math.hypot(p1.x - p2.x, p1.y - p2.y);
}

/**
 * @deprecated Use {@link distanceToSegment} instead.
 */
export function distanceToLine(p: Coord, a: Coord, b: Coord) {
  return Flatten.point(p.x, p.y).distanceTo(
    Flatten.segment(Flatten.point(a.x, a.y), Flatten.point(b.x, b.y)),
  )[0];
}

/**
 * Calculation of distance from a point to a segment. Faster than using the
 * distanceTo method from flatten-js.
 * @param p The point
 * @param a An endpoint of the segment
 * @param b The other endpoint of the segment
 * @returns The distance squared
 */
export function squaredDistanceToSegment(p: Coord, a: Coord, b: Coord) {
  const squaredSegLength = coordDist2(a, b);
  if (squaredSegLength < EPS) {
    return coordDist2(p, a);
  }

  const dot = (b.x - a.x) * (p.x - a.x) + (b.y - a.y) * (p.y - a.y);

  const ratio = Math.max(0, Math.min(1, dot / squaredSegLength));

  const proj = {
    x: a.x + ratio * (b.x - a.x),
    y: a.y + ratio * (b.y - a.y),
  };

  return coordDist2(p, proj);
}

export function distanceToSegment(p: Coord, a: Coord, b: Coord) {
  return Math.sqrt(squaredDistanceToSegment(p, a, b));
}

export function pointOnPolygonEdge(
  point: Coord,
  poly: Coord[],
  options?: { tolerance?: number },
) {
  const tolerance = options?.tolerance ?? 3e-7;
  for (let i = 0; i < poly.length; i++) {
    const p0 = poly[i];
    const p1 = poly[(i + 1) % poly.length];
    if (distanceToSegment(point, p0, p1) < tolerance) return true;
  }
  return false;
}

export function deduplicatePolygon(polygon: Coord[]) {
  let ans: Coord[] = [];
  for (let i = 0; i < polygon.length; i++) {
    if (i === 0 || !coorEquals(polygon[i], polygon[i - 1], 1e-7)) {
      ans.push(polygon[i]);
    }
  }
  return ans;
}

// Strict means that points on the edge of the polygon are not considered inside
export function pointInsidePolygon(
  point: Coord,
  poly: Coord[],
  strict: boolean = false,
): boolean {
  let inside = false;
  poly = deduplicatePolygon(poly);
  let onEdge = pointOnPolygonEdge(point, poly);
  if (onEdge) return !strict;
  for (let i = 0, j = poly.length - 1; i < poly.length; j = i++) {
    const xi = poly[i].x;
    const yi = poly[i].y;
    const xj = poly[j].x;
    const yj = poly[j].y;
    const intersect =
      yi > point.y !== yj > point.y &&
      point.x < ((xj - xi) * (point.y - yi)) / (yj - yi) + xi;

    if (intersect) inside = !inside;
  }

  return inside;
}

// T is a number between 0 and 1
export function lerp(a: number, b: number, t: number): number {
  return a + (b - a) * t;
}

export function coordsWithinRadius(
  A: Coord,
  B: Coord,
  radius: number,
): boolean {
  return Math.hypot(A.x - B.x, A.y - B.y) <= radius;
}

/** Calculates the directed area using Shoelace formula */
export function directedPolygonArea(verticesCoordsInOrder: Coord[]) {
  let polygonArea = 0;
  for (let i = 0; i < verticesCoordsInOrder.length; i++) {
    const p1 = verticesCoordsInOrder[i];
    const p2 = verticesCoordsInOrder[(i + 1) % verticesCoordsInOrder.length];
    polygonArea += (p2.x - p1.x) * (p2.y + p1.y);
  }
  return polygonArea / 2;
}

export function polygonArea(verticesCoordsInOrder: Coord[]) {
  return Math.abs(directedPolygonArea(verticesCoordsInOrder));
}

/** Checks if the coord chain forms simply connected region on the plane (does not cut itself, etc.) */
export function isASimplyConnectedRegion(coordsInOrder: Coord[]) {
  if (coordsInOrder.length <= 2) {
    return false;
  }

  const segments: Flatten.Segment[] = coordsInOrder.map((coord, i) => {
    const nextCoord = coordsInOrder[(i + 1) % coordsInOrder.length];
    return Flatten.segment(coord2Point(coord), coord2Point(nextCoord));
  });

  return segments.every((first, i) =>
    segments.every(
      (second, j) =>
        i === j ||
        (i + 1) % segments.length === j ||
        (j + 1) % segments.length === i ||
        first.intersect(second).length === 0, // Any pairs of segments that are not adjacent must not have any intersections
    ),
  );
}
