import { SentryEntityError } from "../sentry-entity-error";

export class IncorrectEntityTagError extends SentryEntityError {
  constructor(expected: string, actual: Set<string>, uid: string) {
    super(
      `Entity did not have expected tag.`,
      uid,
      {
        expected,
      },
      {
        actual: [...actual].join(", "),
      },
    );
  }
}
