import { CoreContext } from "../calculations/types";
import { getStickyCalculationFields } from "../calculations/utils";
import { isCalculated } from "../document/calculations-objects";
import { CalculationConcrete } from "../document/calculations-objects/calculation-concrete";
import {
  applyCalcFieldSelection,
  extractCalcFieldSelection,
} from "../document/calculations-objects/utils";
export const REPORT_S3_LINKS_EXPIRY_S = 60 * 60; // 1 hour

// Call this after.
export function makeStickyCalculationReport(context: CoreContext) {
  const result: Record<string, Partial<CalculationConcrete>> = {};

  for (const obj of context.globalStore.find("calculatable")) {
    const calc = context.globalStore.getCalculation(obj.entity);

    if (!calc) {
      continue;
    }

    const fields = getStickyCalculationFields(obj.entity);
    if (!fields) {
      continue;
    }

    const stickyCalculation = extractCalcFieldSelection(calc, fields);
    result[obj.entity.uid] = stickyCalculation;
  }

  return result;
}

export function acceptStickyCalculationReport(
  context: CoreContext,
  report: Record<string, Partial<CalculationConcrete>>,
) {
  for (const [uid, stickyCalc] of Object.entries(report)) {
    const obj = context.globalStore.get(uid);
    if (!obj) {
      continue;
    }
    if (!isCalculated(obj.entity)) {
      continue;
    }
    const fields = getStickyCalculationFields(obj.entity);
    if (!fields) {
      continue;
    }
    const realCalc = context.globalStore.getOrCreateCalculation(obj.entity);

    applyCalcFieldSelection(
      realCalc,
      stickyCalc as CalculationConcrete,
      fields,
    );
  }
}
