import axios from "axios";
import {
  MagicPlanProjectDataRes,
  MagicplanListProjectsRes,
  MagicplanValidateRequest,
} from "../../../common/src/api/integrations/magicplan/magicplan";
import { APIResult } from "./../../../common/src/api/document/types";
import { handleApiError } from "./sentry-api-error";

export async function validateTokens(
  tokens: MagicplanValidateRequest,
): Promise<APIResult<boolean>> {
  try {
    return (await axios.post("/api/magicplan/validate", tokens)).data;
  } catch (e: any) {
    return handleApiError("Failed to validate magic plan token", e);
  }
}

export async function listProjects(): Promise<
  APIResult<MagicplanListProjectsRes>
> {
  try {
    return (await axios.get("/api/magicplan/projects")).data;
  } catch (e: any) {
    return handleApiError("Failed to list magic plan projects", e);
  }
}

export async function getProjectData(
  projectId: string,
): Promise<APIResult<MagicPlanProjectDataRes>> {
  try {
    return (await axios.get(`/api/magicplan/projects/${projectId}`)).data;
  } catch (e: any) {
    return handleApiError("Failed to get project data", e, { projectId });
  }
}
