import Flatten from "@flatten-js/core";
import * as TM from "transformation-matrix";
import { ICoreVirtualEdge } from "../../../../../common/src/api/coreObjects/core-traits/coreVirtualEdge";
import CoreConduit from "../../../../../common/src/api/coreObjects/coreConduit";
import CoreBaseBackedObject from "../../../../../common/src/api/coreObjects/lib/coreBaseBackedObject";
import { CalculationData } from "../../../../../common/src/api/document/calculations-objects/calculation-field";
import { VirtualEdgeEntityConcrete } from "../../../../../common/src/api/document/entities/concrete-entity";
import { Coord } from "../../../../../common/src/lib/coord";
import { DrawingContext } from "../types";
import { FIELD_HEIGHT } from "./calculated-object-const";
import { IDrawableObject } from "./core2drawable";
import { SnapIntention, SnapTarget } from "./snappable-object";

const lastDrawnScale: number = 1;

// Difference between virtual-edge-object (Wall) and edge-object (Room Edge, conduit)
// - Virtual Edge allows to be multiple segments
export function VirtualEdgeObject<
  // I extends ConnectableEntityConcrete,
  Obj extends abstract new (
    ...args: any[]
  ) => CoreBaseBackedObject<VirtualEdgeEntityConcrete> &
    IDrawableObject &
    ICoreVirtualEdge,
>(Base: Obj) {
  abstract class Generated extends Base {
    override get tags() {
      const tags = super.tags;
      tags.add("virtual-edge");
      return tags;
    }

    lastDrawnWidthInternal!: number;

    get lastDrawnWidth() {
      if (this.lastDrawnWidthInternal !== undefined) {
        return this.lastDrawnWidthInternal;
      }
      return 10;
    }

    set lastDrawnWidth(value: number) {
      this.lastDrawnWidthInternal = value;
    }

    getSnapTargets(request: SnapIntention[], _mouseWc: Coord): SnapTarget[] {
      const result: SnapTarget[] = [];
      const worldEndpoints = this.worldEndpoints();
      for (const r of request) {
        switch (r.accept) {
          case "block": {
            break;
          }
          case "point": {
            result.push({
              type: "line",
              wcA: worldEndpoints[0],
              wcB: worldEndpoints[1],
              offset: r.offset,
            });
          }
        }
      }

      return result;
    }

    locateCalculationBoxWorld(
      context: DrawingContext,
      data: CalculationData[],
      scale: number,
    ): TM.Matrix[] {
      const { vp } = context;
      // Manage to draw on screen first

      if (!context.globalStore.getCalculation(this.entity)) {
        return [];
      }

      const results: TM.Matrix[] = [];

      this.getWorldSegments().forEach((seg) => {
        const shape = new Flatten.Segment(
          new Flatten.Point(seg[0].x, seg[0].y),
          new Flatten.Point(seg[1].x, seg[1].y),
        );
        [0.5, 0.6, 0.4, 0.7, 0.3, 0.8, 0.2, 0.9, 0.1].forEach((ratio) => {
          const avgx = shape.start.x * ratio + shape.end.x * (1 - ratio);
          const avgy = shape.start.y * ratio + shape.end.y * (1 - ratio);

          const length = vp.toSurfaceLength(shape.length);

          if (length < 100) {
            return [];
          }

          let angle = CoreConduit.calculateAngleRad(shape);
          if (Math.abs(angle) > Math.PI / 2) {
            angle += Math.PI;
          }

          results.push(
            TM.transform(
              TM.identity(),
              TM.translate(avgx, avgy),
              TM.rotate(-angle),
              TM.scale(scale, scale),
              TM.translate(0, 0),
            ),

            TM.transform(
              TM.identity(),
              TM.translate(avgx, avgy),
              TM.rotate(-angle),
              TM.scale(scale, scale),
              TM.translate(0, (+data.length * FIELD_HEIGHT) / 3),
            ),

            TM.transform(
              TM.identity(),
              TM.translate(avgx, avgy),
              TM.rotate(-angle),
              TM.scale(scale, scale),
              TM.translate(0, (-data.length * FIELD_HEIGHT) / 3),
            ),
          );
        });
      });

      return results;
    }

    prepareTransformForName(context: DrawingContext) {
      const { ctx } = context;

      const shape = this.shape;
      if (shape instanceof Flatten.Point) {
        return;
      }

      if (shape instanceof Flatten.Polygon) {
        const x = (shape.box.xmax + shape.box.xmin) / 2;
        const y = (shape.box.ymax + shape.box.ymin) / 2;

        ctx.translate(x, y);
        return;
      }

      const avgx = (shape.start.x + shape.end.x) / 2;
      const avgy = (shape.start.y + shape.end.y) / 2;

      ctx.translate(avgx, avgy);

      let angle = CoreConduit.calculateAngleRad(shape);
      if (Math.abs(angle) > Math.PI / 2) {
        angle += Math.PI;
      }

      ctx.rotate(-angle);
      ctx.translate(0, -this.lastDrawnWidthInternal / 2);
    }

    project(wc: Coord, minWorldEndpointDist: number = 0): Coord {
      const snipped = this.snipEnds(minWorldEndpointDist);
      const p = Flatten.point(wc.x, wc.y).distanceTo(snipped)[1];
      return { x: p.end.x, y: p.end.y };
    }

    snipEnds(worldLength: number): Flatten.Segment | Flatten.Point {
      let ts: Flatten.Segment | Flatten.Point | Flatten.Polygon = this.shape;

      if (ts instanceof Flatten.Polygon) {
        const x = (ts.box.xmax + ts.box.xmin) / 2;
        const y = (ts.box.ymax + ts.box.ymin) / 2;
        return Flatten.point(x, y);
      }

      const l = ts as Flatten.Segment;

      const ep = this.worldEndpoints();
      const middle = Flatten.point(
        (ep[0].x + ep[1].x) / 2,
        (ep[0].y + ep[1].y) / 2,
      );
      if (l.length <= worldLength * 2) {
        ts = middle;
      } else {
        const pe2m = Flatten.vector(l.pe, middle);
        const ps2m = Flatten.vector(l.ps, middle);
        ts = Flatten.segment(
          l.pe.translate(pe2m.normalize().multiply(worldLength)),
          l.ps.translate(ps2m.normalize().multiply(worldLength)),
        );
      }
      return ts;
    }

    inBounds(oc: Coord, radius: number = 0): boolean {
      if (!this.isActive()) {
        return false;
      }
      const shape = this.shape;
      let width = this.lastDrawnWidth;
      if (width === undefined) {
        width = 5;
      }

      // make it clickable
      if (lastDrawnScale) {
        width = Math.max(width, 8 / lastDrawnScale);
      }

      return (
        shape.distanceTo(new Flatten.Point(oc.x, oc.y))[0] <
        Math.max(radius, width)
      );
    }
  }

  return Generated;
}
