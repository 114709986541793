import Flatten from "@flatten-js/core";
import assert from "assert";
import { collect } from "../../../lib/array-utils";
import { Coord, coord2Point } from "../../../lib/coord";
import { tupleMap } from "../../../lib/tuple-utils";
import { CoreContext } from "../../calculations/types";
import { CalculatableEntityConcrete } from "../../document/entities/concrete-entity";
import { PipeFittingEntity } from "../../document/entities/fitting-entity";
import { fillPlantDefaults } from "../../document/entities/plants/plant-defaults";
import {
  V2RadiatorEntity,
  isV2RadiatorEntity,
} from "../../document/entities/plants/plant-entity";
import { topDownLeftRightIOData } from "../../document/entities/plants/v2-radiator/ios-data-in-order";
import { isLayoutSameEnd } from "../../document/entities/plants/v2-radiator/radiator-layout";
import { SystemNodeEntity } from "../../document/entities/system-node-entity";
import { EntityType } from "../../document/entities/types";
import CorePlant, {
  DEFAULT_SYSTEM_NODE_SIZE,
  InletsOutletsSpec,
} from "../corePlant";
import { getOtherEndpoint } from "../utils";
import {
  ASSUMED_V2_RAD_SYSTEM_NODE_HEIGHT_ABOVE_FLOOR_M,
  flattenV2RadiatorCalcEntities,
  generateCalcEntities,
} from "./calculation-entities-generation/generation";
import { getSameEndYCoords } from "./pipe-routing/io-positions";
import {
  RoutingGuide,
  fromGeneratedToDrawn,
  getRoutingGuides,
  getRoutingParams,
} from "./pipe-routing/pipe-routing";
import { getSegmentsFromPath } from "./pipe-routing/utils";

export type CoreV2RadiatorPlant = CorePlant & { entity: V2RadiatorEntity };

export function isCoreV2Radiator(
  plant: CorePlant,
): plant is CoreV2RadiatorPlant {
  return isV2RadiatorEntity(plant.entity);
}

type SystemNodePosition = {
  side: "left" | "right";
  y: number;
};

/**
 * This is not an actual class, but more of a namespace containing relavant methods
 * for CoreV2RadiatorPlant
 */
export class CoreV2Radiator {
  static getDimensions(radiator: CoreV2RadiatorPlant) {
    return {
      widthMM: radiator.widthMM,
      depthMM: fillPlantDefaults(radiator.context, radiator.entity).depthMM!,
    };
  }

  static position2ObjectCoord(
    radiator: CoreV2RadiatorPlant,
    position: SystemNodePosition,
  ) {
    const x =
      position.side === "left" ? -radiator.widthMM / 2 : radiator.widthMM / 2;
    return {
      x,
      y: position.y,
    };
  }

  /**
   * @param radiator
   * @param systemNodeUid
   * @returns The position of the generated system node.
   */
  static getGeneratedSystemNodePosition(
    radiator: CoreV2RadiatorPlant,
    systemNodeUid: string,
  ): SystemNodePosition {
    const layout = radiator.entity.plant.connectionsLayout;
    const nodesData = topDownLeftRightIOData(radiator.entity);
    const index = nodesData.findIndex(
      (nodeData) => nodeData.uid === systemNodeUid,
    );
    if (isLayoutSameEnd(layout)) {
      const { side } = layout;
      return {
        side,
        y: getSameEndYCoords(this.getDimensions(radiator).depthMM)[index],
      };
    } else {
      return {
        side: index === 0 ? "left" : "right",
        y: 0,
      };
    }
  }

  static getGeneratedSystemNodeObjCoord(
    radiator: CoreV2RadiatorPlant,
    systemNodeUid: string,
  ) {
    return this.position2ObjectCoord(
      radiator,
      this.getGeneratedSystemNodePosition(radiator, systemNodeUid),
    );
  }

  static getRoutingGuides(
    radiator: CoreV2RadiatorPlant,
  ): [RoutingGuide | null, RoutingGuide | null] {
    const store = radiator.context.globalStore;
    const radiatorDimensions = this.getDimensions(radiator);
    const routingParams = getRoutingParams(radiator);

    const radEntity = radiator.entity;
    const layout = radEntity.plant.connectionsLayout;

    const systemNodesDataInOrder = topDownLeftRightIOData(radiator.entity);

    return getRoutingGuides({
      dimensions: {
        width: radiatorDimensions.widthMM,
        height: radiatorDimensions.depthMM,
      },
      connectionLayout: layout,
      sourcesInOrder: tupleMap(
        systemNodesDataInOrder,
        (nodeData: (typeof systemNodesDataInOrder)[number]) => {
          const { uid: systemNodeUid, systemUid } = nodeData;
          const connectedEntities = CorePlant.getConnectedEntities({
            context: radiator.context,
            systemNodeUid,
            systemUid,
          });
          if (!connectedEntities) return null;

          const { connectable, conduit } = connectedEntities;

          const otherConnectionsUids = store
            .getConnections(connectable.uid)
            .filter((connectionUid) => connectionUid !== conduit.uid);

          const otherEndpoints = collect(
            otherConnectionsUids,
            (otherConnectionUid) => {
              const otherConduit = store.getObjectOfType(
                EntityType.CONDUIT,
                otherConnectionUid,
              );
              if (!otherConduit) return null;

              // Don't care about pipes on other levels
              const level = store.levelOfEntity.get(otherConduit.uid);
              if (!level || level !== store.levelOfEntity.get(conduit.uid))
                return null;

              const otherEndpoint = getOtherEndpoint(
                otherConduit.entity.endpointUid,
                connectable.uid,
              );
              const otherConnectable = store.ofTag(
                "connectable",
                otherEndpoint,
              );
              if (!otherConnectable) return null;
              if (
                otherConnectable.type === EntityType.SYSTEM_NODE &&
                otherConnectable.isParentV2Radiator()
              ) {
                return null;
              }

              return coord2Point(
                radiator.toObjectCoord(otherConnectable.toWorldCoord()),
              );
            },
          );
          const pos = {
            objCoord: coord2Point(
              radiator.toObjectCoord(connectable.toWorldCoord()),
            ),
            otherEndpoints,
          };
          return pos;
        },
      ),
      params: routingParams,
    });
  }

  /**
   * @param radiator
   * @returns The specs of system nodes, with positions being the generated system
   * nodes' position instead of the drawn position
   */
  static getInletsOutletsSpecs(
    radiator: CoreV2RadiatorPlant,
  ): InletsOutletsSpec[] {
    const nodesDataInOrder = topDownLeftRightIOData(radiator.entity);
    if (isLayoutSameEnd(radiator.entity.plant.connectionsLayout))
      nodesDataInOrder.reverse();
    return nodesDataInOrder.map((nodeData) => ({
      uid: nodeData.uid,
      systemUid: nodeData.systemUid,
      type: "ambiguous",
      isReturn: false,
      isRecirculation: false,
      position: this.getGeneratedSystemNodePosition(radiator, nodeData.uid)
        .side, // N/A
      length: DEFAULT_SYSTEM_NODE_SIZE, // N/A
      heightAboveFloorM: ASSUMED_V2_RAD_SYSTEM_NODE_HEIGHT_ABOVE_FLOOR_M, // Not entirely accurate
    }));
  }

  /**
   * @param radiator
   * @returns The position of the drawn system node if the system node is
   * connected, otherwise the position of the generated system node
   */
  static getInletsOutletsPositions(
    radiator: CoreV2RadiatorPlant,
  ): Record<string, { center: Coord; systemUid: string }> {
    const nodesData = topDownLeftRightIOData(radiator.entity);

    const ret = nodesData.map((nodeData) => {
      const { uid: systemNodeUid, systemUid } = nodeData;
      return [
        systemNodeUid,
        {
          systemUid,
          center: this.getGeneratedSystemNodeObjCoord(radiator, systemNodeUid),
        },
      ];
    });

    return Object.fromEntries(ret);
  }

  static getCalculationEntities(
    calcContext: CoreContext,
    radiator: CoreV2RadiatorPlant,
  ): CalculatableEntityConcrete[] {
    return flattenV2RadiatorCalcEntities(
      generateCalcEntities({
        calcContext,
        radiator,
      }),
    );
  }

  static getCalculationNodeForSystemNode(args: {
    calcContext: CoreContext;
    radiator: CoreV2RadiatorPlant;
    systemNodeUid: string;
    connectionUid: string;
  }): SystemNodeEntity | PipeFittingEntity {
    const { calcContext, systemNodeUid, radiator, connectionUid } = args;

    const calcEntities = generateCalcEntities({ calcContext, radiator })
      .systemNodeUid2CalcEntities[systemNodeUid];
    if (connectionUid === radiator.uid) {
      return calcEntities.systemNode;
    } else {
      return calcEntities.pipesAndAfterFittings.at(-1)![1];
    }
  }

  private static doRoutingPipesIntersect(
    radiator: CoreV2RadiatorPlant,
  ): boolean {
    const nodesData = topDownLeftRightIOData(radiator.entity);
    const routingGuides = this.getRoutingGuides(radiator);

    const fullPaths: Flatten.Point[][] = [];
    for (let i = 0; i < nodesData.length; i++) {
      const { uid: systemNodeUid, systemUid } = nodesData[i];
      const connectedEntities = CorePlant.getConnectedEntities({
        context: radiator.context,
        systemNodeUid,
        systemUid,
      });
      if (!connectedEntities) {
        return false;
      }
      const routingGuide = routingGuides[i];
      assert(routingGuide);
      fullPaths.push([
        coord2Point(
          radiator.toObjectCoord(connectedEntities.connectable.toWorldCoord()),
        ),
        ...fromGeneratedToDrawn(routingGuide),
      ]);
    }

    const [firstSegments, secondSegments] = fullPaths.map(getSegmentsFromPath);

    return firstSegments.some((seg) =>
      secondSegments.some((otherSeg) => seg.intersect(otherSeg).length > 0),
    );
  }

  static resolveRoutingIntersectionIfNeeded(radiator: CoreV2RadiatorPlant) {
    const layout = radiator.entity.plant.connectionsLayout;
    if (!this.doRoutingPipesIntersect(radiator)) return;
    layout.swapNormalPositions = !layout.swapNormalPositions;
    if (!this.doRoutingPipesIntersect(radiator)) return;
    // If after swapping around, the routed pipes still intersect, swap them back.
    layout.swapNormalPositions = !layout.swapNormalPositions;
  }

  static getLSVSpec(radiator: CoreV2RadiatorPlant) {
    const context = radiator.context;
    const store = context.globalStore;
    const systemNodeDataInOrder = topDownLeftRightIOData(radiator.entity);

    for (const { uid: systemNodeUid, systemUid } of systemNodeDataInOrder) {
      const connectedEntities = CorePlant.getConnectedEntities({
        context: radiator.context,
        systemNodeUid,
        systemUid,
      });
      if (!connectedEntities) continue;

      const connectedPipe = connectedEntities.conduit;

      const pCalc = store.getOrCreateCalculation(connectedPipe.entity);
      const pLiveCalc = store.getOrCreateLiveCalculation(connectedPipe.entity);
      const flowFrom = pCalc.flowFrom ?? pLiveCalc.flowFrom;
      if (flowFrom?.includes(systemNodeUid)) {
        return {
          systemNodeUid,
          connectedPipe,
        };
      }
    }
    return null;
  }
}
