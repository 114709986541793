import Flatten from "@flatten-js/core";
import RBush from "rbush";
import { EntityType } from "../../../../common/src/api/document/entities/types";
import { SpatialIndex } from "../../../../common/src/api/types";
import { Coord } from "../../../../common/src/lib/coord";
import {
  angleDiffCWDeg,
  bisectAngleDegCW,
} from "../../../../common/src/lib/mathUtils/mathutils";
import { cloneSimple } from "../../../../common/src/lib/utils";
import { MAX_INTERACTION_RECIPIENT_RADIUS_WC } from "../layers/layer";
import CanvasContext from "../lib/canvas-context";
import {
  PointSnapTarget,
  SnapIntention,
  SnapTarget,
} from "../lib/object-traits/snappable-object";
import { DrawingContext } from "../lib/types";
import {
  SnappableObjectConcrete,
  isSnappableObject,
} from "../objects/concrete-object";
import { MouseMoveResult } from "../types";
import { KeyCode } from "../utils";
import PointTool from "./point-tool";
import { SnapResult, snapPoint } from "./snap-geometry";
import { KeyHandlers, drawAngleMarkers } from "./utils";

export const CONNECTABLE_SNAP_RADIUS_PX = 5;

export interface SnappingToolConstructorProps {
  context: CanvasContext;
  onFinish: (interrupted: boolean, displaced: boolean) => void;
  onMove: (
    worldCoord: Coord,
    event: MouseEvent,
    angleBounds?: { min: number; max: number } | null,
  ) => void;
  onPointChosen: (worldCoord: Coord, event: MouseEvent) => void;
  clickActionName: string;
  keyHandlers?: KeyHandlers;
  getInfoText?: () => string[];
  onDraw?: (context: DrawingContext, lastEvent: MouseEvent | undefined) => void;
  chainingObjectUids?: string[];
  sourceWC?: Coord;
  dontSnapToUids?: string[];

  // Any entities that should always be highlighted as snap targets
  baseSnapHovers?: string[];
  name: string;
  getSnapIntentions: () => SnapIntention[];
  activeUidsToIgnore?: () => string[];
  performRotations?: boolean;
  initialAngleBounds?: { min: number; max: number } | null;
  angleOffsetDeg?: number; // How many degrees to add onto the angle bounds to get the perceived angle in the GUI.
  getTitleCallback: () => string;
  snapToVerticesBelow?: boolean;
  pickPointOnRightClick?: boolean;
}

export default class SnappingTool extends PointTool {
  nSnapEvents: number;
  // We will have a max of 2 groups of targets. Each group can have many targets,
  // but will only cross with targets in the other group.
  snapTargets: SnapTarget[][];

  baseSnapHovers: string[];

  lastSnapHover: string | null;

  lastSnapResult?: SnapResult;
  chainingObjectUids: string[] | undefined;
  activeUidsToIgnore?: () => string[];
  preventSnappingTo: Set<string> = new Set();

  sourceWC?: Coord;

  onDraw:
    | ((context: DrawingContext, lastEvent: MouseEvent) => void)
    | undefined;

  getSnapIntentions: () => SnapIntention[];

  context: CanvasContext;

  angleBounds: { min: number; max: number } | null = null;
  performRotations = false;
  rotationHalfRangeDeg = 30;
  angleOffsetDeg = 0;
  snapToVerticesBelow?: boolean;

  constructor(props: SnappingToolConstructorProps) {
    const {
      context,
      onFinish,
      onMove,
      onPointChosen,
      clickActionName,
      keyHandlers,
      getInfoText,
      onDraw,
      activeUidsToIgnore,
      chainingObjectUids,
      dontSnapToUids,
      baseSnapHovers,
      name,
      performRotations,
      initialAngleBounds,
      angleOffsetDeg,
      getTitleCallback,
      snapToVerticesBelow,
      pickPointOnRightClick,
    } = props;

    const newOnMove = (worldCoord: Coord, event: MouseEvent) => {
      if (event) {
        if (!event.shiftKey) {
          worldCoord = this.snapWorldCoord(worldCoord);
        }
        context.$store.dispatch("document/revert", false);
        onMove(worldCoord, event, this.angleBounds);
      } else context.$store.dispatch("document/revert", false);
    };
    const newOnPointChosen = (worldCoord: Coord, event: MouseEvent) => {
      if (!event.shiftKey) {
        worldCoord = this.snapWorldCoord(worldCoord);
      }
      onPointChosen(worldCoord, event);
    };

    super({
      onFinish,
      onMove: newOnMove,
      onPointChosen: newOnPointChosen,
      clickActionName,
      keyHandlers,
      getInfoText,
      name,
      getTitleCallback,
      pickPointOnRightClick,
    });

    this.angleOffsetDeg = angleOffsetDeg || 0;

    if (performRotations) {
      this.keyHandlers.unshift(...this.rotationHandlers);
    }

    this.context = context;
    this.chainingObjectUids = chainingObjectUids;
    this.sourceWC = props.sourceWC;
    this.onDraw = onDraw;
    this.baseSnapHovers = baseSnapHovers || [];
    this.getSnapIntentions = props.getSnapIntentions || (() => []);
    this.clearSnapHovers();
    this.activeUidsToIgnore = activeUidsToIgnore;

    this.keyHandlers.push([
      [[KeyCode.SHIFT]],
      {
        name: "+ don't snap",
        fn: () => {
          this.clearSnapHovers.bind(this)();
        },
      },
    ]);

    this.preventSnappingTo = new Set(dontSnapToUids);
    if (this.chainingObjectUids) {
      for (const uid of this.chainingObjectUids) {
        this.preventSnappingTo.add(uid);
      }
    }

    if (initialAngleBounds) {
      this.angleBounds = cloneSimple(initialAngleBounds);
      this.rotationHalfRangeDeg =
        angleDiffCWDeg(initialAngleBounds.min, initialAngleBounds.max) / 2;
    }
    this.performRotations = performRotations || false;
    this.snapToVerticesBelow = snapToVerticesBelow;
  }

  clearSnapHovers() {
    this.nSnapEvents = 0;
    this.snapTargets = [];
    this.lastSnapHover = null;
    this.snapTargets = [];

    for (const uid of this.baseSnapHovers) {
      const o = this.context.globalStore.get<SnappableObjectConcrete>(uid);
      if (o) {
        this.addSnapHover(o);
      }
    }
  }

  setBaseSnapHovers(uids: string[]) {
    this.baseSnapHovers = uids;
    this.clearSnapHovers();
  }

  onMouseMove(event: MouseEvent, context: CanvasContext): MouseMoveResult {
    if (!event.shiftKey) {
      const wc = context.viewPort.toWorldCoord({
        x: event.clientX,
        y: event.clientY,
      });

      const intentions = this.getSnapIntentions();

      const lvs = Object.values(context.drawing.levels)
        .sort((a, b) => a.floorHeightM - b.floorHeightM)
        .map((o) => o.uid);
      const currentLevel = context.document.uiState.levelUid;
      const currentLevelIndex = lvs.indexOf(currentLevel!);
      const belowLevel =
        currentLevelIndex > 0 ? lvs[currentLevelIndex - 1] : null;
      let tree: RBush<SpatialIndex> | null =
        context.globalStore.spatialIndex.get(currentLevel!)!;
      const query = {
        minX:
          wc.x -
          CONNECTABLE_SNAP_RADIUS_PX -
          MAX_INTERACTION_RECIPIENT_RADIUS_WC,
        minY:
          wc.y -
          CONNECTABLE_SNAP_RADIUS_PX -
          MAX_INTERACTION_RECIPIENT_RADIUS_WC,
        maxX:
          wc.x +
          CONNECTABLE_SNAP_RADIUS_PX +
          MAX_INTERACTION_RECIPIENT_RADIUS_WC,
        maxY:
          wc.y +
          CONNECTABLE_SNAP_RADIUS_PX +
          MAX_INTERACTION_RECIPIENT_RADIUS_WC,
      };
      const inRangeCurr = (tree?.search(query) || []).map((o) => o.uid);
      tree = belowLevel
        ? context.globalStore.spatialIndex.get(belowLevel)!
        : null;
      const inRangeBelow = this.snapToVerticesBelow
        ? (tree?.search(query) || []).map((o) => o.uid)
        : [];
      const uids = inRangeCurr

        .map((o) => context.globalStore.get(o)!)
        .filter(Boolean) //TODO disable pipe snap to wall
        .concat(
          inRangeBelow
            .map((o) => context.globalStore.get(o)!)
            .filter((o) => o.type === EntityType.VERTEX),
        )
        .sort(
          (a, b) =>
            context.hydraulicsLayer.getEntityZIndex(a.entity) -
            context.hydraulicsLayer.getEntityZIndex(b.entity),
        )
        .map((o) => o.uid)
        .reverse();
      const interactiveUids = context.interactive
        ? context.interactive.map((o) => o.uid)
        : [];

      uids.push(...interactiveUids);

      let found = false;

      for (const ouid of uids) {
        if (!inRangeCurr.includes(ouid) && !inRangeBelow.includes(ouid)) {
          continue;
        }
        if (this.preventSnappingTo.has(ouid)) {
          continue;
        }
        const o = context.globalStore.get(ouid)!;
        if (isSnappableObject(o)) {
          const r = o.toObjectLength(
            context.viewPort.toWorldLength(CONNECTABLE_SNAP_RADIUS_PX),
          );
          if (
            interactiveUids.includes(o.uid) ||
            o.inBounds(o.toObjectCoord(wc), r)
          ) {
            found = true;
            if (o.uid !== this.lastSnapHover) {
              this.lastSnapHover = o.uid;
              this.nSnapEvents++;
              const thisSnapEvent = this.nSnapEvents;
              const ignore = this.activeUidsToIgnore
                ? this.activeUidsToIgnore()
                : [];

              const targets = o.getSnapTargets(intentions, wc, ignore);

              const fn = () => {
                if (
                  o.uid === this.lastSnapHover &&
                  this.nSnapEvents === thisSnapEvent
                ) {
                  this.addSnapHover(o, targets);
                }
              };
              if (o.snapHoverTimeoutMS) {
                setTimeout(() => {
                  fn.bind(this)();
                }, o.snapHoverTimeoutMS);
              } else {
                fn.bind(this)();
              }
            }
            break;
          }
        }
      }

      if (!found) {
        this.lastSnapHover = null;
      }
    }

    return super.onMouseMove(event, context);
  }

  addSnapHover(o: SnappableObjectConcrete, targets?: SnapTarget[]) {
    if (!targets) {
      const intentions = this.getSnapIntentions();
      const wc = this.context.viewPort.toWorldCoord({
        x: this.lastEvent?.clientX || 0,
        y: this.lastEvent?.clientY || 0,
      });
      const ignore = this.activeUidsToIgnore ? this.activeUidsToIgnore() : [];

      targets = o.getSnapTargets(intentions, wc, ignore);
    }

    this.snapTargets.push(targets);
  }

  snapWorldCoord(wc: Coord): Coord {
    const ignore = this.activeUidsToIgnore ? this.activeUidsToIgnore() : [];

    const chainingSnapTargets: PointSnapTarget[] = [];
    if (this.chainingObjectUids) {
      for (const uid of this.chainingObjectUids) {
        const o = this.context.globalStore.get<SnappableObjectConcrete>(uid);
        if (o) {
          chainingSnapTargets.push(
            ...o
              .getSnapTargets(this.getSnapIntentions(), wc, ignore)
              .filter((o): o is PointSnapTarget => o.type === "point"),
          );
        }
      }
    }

    const snapped = snapPoint(
      this.context,
      this.snapTargets,
      wc,
      chainingSnapTargets,
      this.sourceWC,
    );

    this.lastSnapResult = snapped;
    if (snapped.wc) {
      return snapped.wc;
    } else {
      return wc;
    }
  }

  draw(context: DrawingContext) {
    super.draw(context);

    const ctx = context.ctx;

    // also draw snap points
    for (const group of this.snapTargets) {
      for (const target of group) {
        switch (target.type) {
          case "point": {
            // draw crosshair
            const sc = context.vp.toScreenCoord(target.wc);

            ctx.strokeStyle = "rgba(0, 0, 255, 0.5)";
            ctx.lineWidth = 3;

            ctx.beginPath();
            ctx.moveTo(sc.x - 10, sc.y);
            ctx.lineTo(sc.x + 10, sc.y);
            ctx.moveTo(sc.x, sc.y - 10);
            ctx.lineTo(sc.x, sc.y + 10);
            ctx.stroke();
            break;
          }
          case "line": {
            // highlight pipe

            ctx.strokeStyle = "#002299";
            ctx.lineWidth = 1;
            ctx.setLineDash([3, 3]);

            const a = context.vp.toScreenCoord(target.wcA);
            const b = context.vp.toScreenCoord(target.wcB);

            ctx.beginPath();
            ctx.moveTo(a.x, a.y);
            ctx.lineTo(b.x, b.y);
            ctx.stroke();

            break;
          }
        }
      }
    }

    if (this.lastSnapResult) {
      // draw dotted line for each reference
      if (this.lastSnapResult.references) {
        ctx.strokeStyle = "#000000";
        ctx.lineWidth = 1;
        ctx.setLineDash([3, 3]);

        for (const l of this.lastSnapResult.references) {
          if (l instanceof Flatten.Line) {
            const wc = this.context.viewPort.toScreenCoord(l.pt);
            const dir = l.norm.rotate90CCW().normalize();

            const a = { x: wc.x + dir.x * -5000, y: wc.y + dir.y * -5000 };
            const b = { x: wc.x + dir.x * 5000, y: wc.y + dir.y * 5000 };

            ctx.beginPath();
            ctx.moveTo(a.x, a.y);
            ctx.lineTo(b.x, b.y);
            ctx.stroke();
          }
        }
      }

      ctx.setLineDash([]);
    }

    drawAngleMarkers(
      context,
      [this.lastEvent?.clientX || 0, this.lastEvent?.clientY || 0],
      this.angleBounds
        ? {
            min: this.angleBounds.min + this.angleOffsetDeg,
            max: this.angleBounds.max + this.angleOffsetDeg,
          }
        : null,
    );

    if (this.onDraw) {
      this.onDraw(context, this.lastEvent);
    }
  }

  onMouseDown(event: MouseEvent, context: CanvasContext): number | boolean {
    if (event.button === 2) {
      this.clearSnapHovers();
    }
    const res = super.onMouseDown(event, context);
    if (this.clickActionName === "insert-vertex") {
      this.onPointChosen(
        context.viewPort.toWorldCoord({ x: event.clientX, y: event.clientY }),
        event,
      );
      this.onFinish(false, false);
      return -1;
    }
    return res;
  }

  setDontSnapToUids(uids: string[]) {
    this.preventSnappingTo = new Set(
      uids.concat(this.chainingObjectUids || []),
    );
  }

  get rotationHandlers(): KeyHandlers {
    return [
      [
        [[KeyCode.UP], [KeyCode.DOWN], [KeyCode.LEFT], [KeyCode.RIGHT]],
        {
          name: "Set Rotation",
          fn: (
            event: KeyboardEvent,
            onRefresh: () => void,
            keyDown: Map<KeyCode, boolean>,
          ) => {
            if (event.shiftKey || event.ctrlKey) {
              // No-op
            } else {
              const oldAngleBounds = cloneSimple(this.angleBounds);
              const leftDown = Boolean(keyDown.get(KeyCode.LEFT));
              const rightDown = Boolean(keyDown.get(KeyCode.RIGHT));
              const upDown = Boolean(keyDown.get(KeyCode.UP));
              const downDown = Boolean(keyDown.get(KeyCode.DOWN));

              const totPressed =
                Number(leftDown) +
                Number(rightDown) +
                Number(upDown) +
                Number(downDown);

              if (totPressed >= 3) {
                this.angleBounds = null;
                return;
              }

              // check multi keys first. Opposite directions is cancel.
              if (
                (keyDown.get(KeyCode.UP) && keyDown.get(KeyCode.DOWN)) ||
                (keyDown.get(KeyCode.LEFT) && keyDown.get(KeyCode.RIGHT))
              ) {
                this.angleBounds = null;
                return;
              }

              let angleTarget = 0;
              let guide = false;
              const currAngle = this.angleBounds
                ? bisectAngleDegCW(this.angleBounds.min, this.angleBounds.max)
                : null;

              // If the keys are perpendicular, set to diagonal
              if (keyDown.get(KeyCode.UP) && keyDown.get(KeyCode.RIGHT)) {
                angleTarget = 45;
              } else if (keyDown.get(KeyCode.UP) && keyDown.get(KeyCode.LEFT)) {
                angleTarget = -45;
              } else if (
                keyDown.get(KeyCode.DOWN) &&
                keyDown.get(KeyCode.RIGHT)
              ) {
                angleTarget = 135;
              } else if (
                keyDown.get(KeyCode.DOWN) &&
                keyDown.get(KeyCode.LEFT)
              ) {
                angleTarget = -135;
              } else if (keyDown.get(KeyCode.UP)) {
                angleTarget = 0;
                guide = true;
              } else if (keyDown.get(KeyCode.DOWN)) {
                angleTarget = 180;
                guide = true;
              } else if (keyDown.get(KeyCode.LEFT)) {
                angleTarget = -90;
                guide = true;
              } else if (keyDown.get(KeyCode.RIGHT)) {
                angleTarget = 90;
                guide = true;
              }

              angleTarget -= this.angleOffsetDeg;
              if (guide) {
                if (currAngle !== null) {
                  if (angleDiffCWDeg(currAngle, angleTarget) < 180) {
                    if (angleDiffCWDeg(currAngle, angleTarget) >= 45) {
                      angleTarget = currAngle + 45;
                    }
                  } else {
                    if (angleDiffCWDeg(angleTarget, currAngle) >= 45) {
                      angleTarget = currAngle - 45;
                    }
                  }
                }
              }

              this.angleBounds = {
                min: angleTarget - this.rotationHalfRangeDeg,
                max: angleTarget + this.rotationHalfRangeDeg,
              };

              if (
                this.angleBounds != null &&
                oldAngleBounds != null &&
                Math.abs(this.angleBounds.min - oldAngleBounds.min) < 0.01 &&
                Math.abs(this.angleBounds.max - oldAngleBounds.max) < 0.01
              ) {
                // Pressing the same key to cancel the bounds
                this.angleBounds = null;
              }
            }
            onRefresh();
          },
        },
      ],
      [
        [[KeyCode.SHIFT, KeyCode.LEFT]],
        {
          name: "Rotate counterclockwise",
          fn: (event: KeyboardEvent, _onRefresh: () => void) => {
            if (event.shiftKey) {
              if (!this.angleBounds) {
                this.angleBounds = {
                  min: -this.rotationHalfRangeDeg,
                  max: this.rotationHalfRangeDeg,
                };
              }
              this.angleBounds.min -= 5;
              this.angleBounds.max -= 5;
            }
          },
        },
      ],
      [
        [[KeyCode.SHIFT, KeyCode.RIGHT]],
        {
          name: "Rotate Clockwise",
          fn: (event: KeyboardEvent, _onRefresh: () => void) => {
            if (event.shiftKey) {
              if (!this.angleBounds) {
                this.angleBounds = {
                  min: -this.rotationHalfRangeDeg,
                  max: this.rotationHalfRangeDeg,
                };
              }
              this.angleBounds.min += 5;
              this.angleBounds.max += 5;
            }
          },
        },
      ],
    ];
  }
}
