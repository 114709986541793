import { isEqual } from "lodash";
import { MultiMap } from "./multi-map";
import { Primitive } from "./primitive";
import { SetMultiMap } from "./set-multi-map";

/**
 * A Bi-Directional SetMultiMap.
 *
 * There is always an inverse of the SetMultiMap which can be used by getInverted.
 *
 * Useful for maintaining bidirectional relationships between values.
 *
 *
 * Eg A => B, C
 *    B => A
 *    C => A
 */
export class BiSetMultiMap<T extends Primitive> {
  private internal: SetMultiMap<T, T> = new SetMultiMap<T, T>();
  private inverted: SetMultiMap<T, T> = new SetMultiMap<T, T>();

  /**
   * Special Sauce.
   *
   * tl;dr is that without keeping a reference counter, with incrementally updates via replace,
   * we can end up with a desynced internal/inverted Map
   *
   * Example:
   *
   * add(a, [b])
   * add(b, [a])
   * replace(a, [c])
   *
   * Without this refCounter, replace would end up deleting the d => a ref. because it doesnt know about it.
   *
   * It might be easier to understand if you think about it as a Parent/Child relationship
   *
   * The "replacer" doesnt know if some parent added a reference to it or not.
   */
  private refCounter: MultiMap<T, T> = new MultiMap<T, T>();

  add(key: T, value: T): void {
    this.internal.add(key, value);
    this.inverted.add(value, key);
    this.refCounter.add(key, value);
  }

  addAll(key: T, values: Iterable<T>): void {
    for (let v of values) {
      this.add(key, v);
    }
  }

  keyset(): Set<T> {
    return new Set(this.internal.keys());
  }

  valueset(): Set<T> {
    return new Set(this.inverted.keys());
  }

  get(key: T): Set<T> {
    return this.internal.get(key);
  }

  getInverted(key: T): Set<T> {
    return this.inverted.get(key);
  }

  /**
   * Test only: This will always be true
   */
  isInSync() {
    this.trim();
    return (
      isEqual(SetMultiMap.invert(this.internal), this.inverted) &&
      isEqual(SetMultiMap.invert(this.inverted), this.internal)
    );
  }

  trim() {
    this.internal.trim();
    this.inverted.trim();
  }

  delete(key: T) {
    const internalSet = this.inverted.get(key);
    internalSet.forEach((value) => {
      this.internal.deleteValue(value, key);
    });
    const invertedSet = this.internal.get(key);
    invertedSet.forEach((value) => {
      this.inverted.deleteValue(value, key);
    });
    this.internal.delete(key);
    this.inverted.delete(key);
    this.refCounter.delete(key);
  }

  /**
   * Replaces any values specified in the key map
   *
   * Does not replace all references to a specific key
   * If `this.add(something, yourKey)` has been called, that reference remains.
   */
  replace(key: T, values: Set<T> | Array<T>) {
    const oldInternalValues = [...this.internal.get(key)];
    this.internal.delete(key);
    oldInternalValues.forEach((value) => {
      this.refCounter.deleteValue(key, value);
      if (!this.refCounter.hasValue(key, value)) {
        this.inverted.deleteValue(value, key);
      }
    });

    this.addAll(key, values);
  }

  clear(): void {
    this.internal.clear();
    this.inverted.clear();
    this.refCounter.clear();
  }
}
