import { AllUserProperties } from "../../../common/src/api/allUserProperties";
import { APIResult } from "../../../common/src/api/document/types";
import { ApiClient } from "./ApiClient";

const userPropertiesApi = new ApiClient("/api/userProperties");

// The types work as well as you have hoped!
export async function getOwnUserProperties<T extends keyof AllUserProperties>(
  fields: T[],
): Promise<APIResult<Partial<{ [key in T]: AllUserProperties[key] }>>> {
  return userPropertiesApi.get("own", {
    params: { key: fields },
  });
}

export async function setOwnUserProperties(
  properties: Partial<AllUserProperties>,
): Promise<APIResult<void>> {
  return userPropertiesApi.post("own", properties);
}
