import Flatten from "@flatten-js/core";
import {
  DEFAULT_SYSTEM_NODE_SIZE,
  RECIRCULATION_SYSTEM_NODE_SIZE,
} from "../../../../common/src/api/coreObjects/corePlant";
import { PlantShapes } from "../../../../common/src/api/document/entities/plants/plant-types";
import { PressureFlowSystem } from "../../../../common/src/api/document/flow-systems";
import { getFlowSystem } from "../../../../common/src/api/document/utils";
import { Color } from "../../../../common/src/lib/color";
import { Coord } from "../../../../common/src/lib/coord";
import { Logger } from "../../../../common/src/lib/logger";
import {
  assertUnreachable,
  assertUnreachableAggressive,
} from "../../../../common/src/lib/utils";
import { globalStore } from "../../store/globalCoreContext";
import { DrawingContext } from "../lib/types";
import { DrawableObjectConcrete } from "../objects/concrete-object";
import DrawableSystemNode from "../objects/drawableSystemNode";
import { Rectangle } from "./rectangle";

const inactiveColor = "rgba(150, 150, 150, 0.65)";
export function drawPipeCap(
  ctx: CanvasRenderingContext2D,
  offset: Coord,
  color: Color = { hex: "#000000" },
) {
  ctx.save();
  const x3 = offset.x;
  const y3 = offset.y;

  ctx.beginPath();
  ctx.fillStyle = "#fff"; // set the fill color to white
  ctx.arc(x3, y3, 15, 0, Math.PI * 2, false);
  ctx.fill(); // fill the circle with the fill color

  ctx.lineWidth = 5;
  ctx.strokeStyle = color.hex; // set the outline color to given color
  ctx.stroke();

  ctx.beginPath();
  ctx.fillStyle = "#000"; // set the fill color to black
  ctx.arc(x3, y3, 5, 0, Math.PI * 2, false);
  ctx.fill(); // fill the circle with the fill color

  ctx.restore();
}
export function drawRectangles(
  ctx: CanvasRenderingContext2D,
  rectangles: Rectangle[],
) {
  ctx.beginPath();
  ctx.lineWidth = 0;
  rectangles.forEach((rectangle: Rectangle) => {
    ctx.strokeStyle = rectangle.strokeStyle || "#000";
    ctx.fillStyle = rectangle.fillStyle || "#000";
    const left = Math.min(rectangle.x1, rectangle.x2);
    const top = Math.min(rectangle.y1, rectangle.y2);
    const width = Math.abs(rectangle.x1 - rectangle.x2);
    const height = Math.abs(rectangle.y1 - rectangle.y2);
    ctx.fillRect(left, top, width, height);
  });
  ctx.stroke();
}

export function drawCircle(
  ctx: CanvasRenderingContext2D,
  coord: Coord,
  radius: number,
  lineWidth: number,
  color: Color = { hex: "#000000" },
) {
  ctx.save(); // Save current context state

  ctx.beginPath();
  ctx.lineWidth = lineWidth; // setting the line width
  ctx.strokeStyle = color.hex;
  ctx.fillStyle = "#ffffff"; // set the fill color to white
  ctx.arc(coord.x, coord.y, radius, 0, Math.PI * 2, false);
  ctx.fill(); // fill the circle with the fill color
  ctx.stroke();

  ctx.restore(); // Restore context state to how it was when save() was called
}

export function drawEquilateralTriangle(
  ctx: CanvasRenderingContext2D,
  center: Coord,
  size: number,
  lineWidth: number,
  color: Color = { hex: "#000000" },
) {
  ctx.save(); // Save current context state

  const height = size; // Calculate height of the equilateral triangle

  // Calculate coordinates of the triangle
  const point1 = { x: center.x + size / 2, y: center.y + height / 2 };
  const point2 = { x: center.x - size / 2, y: center.y };
  const point3 = { x: center.x + size / 2, y: center.y - height / 2 };

  ctx.beginPath();
  ctx.lineWidth = lineWidth; // setting the line width
  ctx.moveTo(point1.x, point1.y);
  ctx.lineTo(point2.x, point2.y);
  ctx.lineTo(point3.x, point3.y);
  ctx.closePath();

  // Fill the triangle
  ctx.fillStyle = "#ffffff"; // set the fill color to white
  ctx.fill(); // fill the triangle with the fill color

  // Stroke the triangle
  ctx.strokeStyle = color.hex;
  ctx.stroke();

  ctx.restore(); // Restore context state to how it was when save() was called
}

/* Draw Round Rectangle */
export function drawRoundRectangle(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number,
  fill: boolean = true,
  stroke: boolean = false,
) {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();
  if (fill) {
    ctx.fill();
  }
  if (stroke) {
    ctx.stroke();
  }
}

export function drawArrow(options: {
  ctx: CanvasRenderingContext2D;
  x: number;
  y: number;
  bodyLength: number;
  headLength: number;
  bodyWidth: number;
  headWidth: number;
  angle: number;
  color: Color;
}) {
  const {
    ctx,
    x,
    y,
    bodyLength,
    headLength,
    bodyWidth,
    headWidth,
    angle,
    color,
  } = options;
  const existingFillStyle = ctx.fillStyle;
  const existingTransform = ctx.getTransform();

  ctx.translate(x, y);
  ctx.rotate(angle);

  ctx.fillStyle = color.hex;

  const totalLength = bodyLength + headLength;
  ctx.fillRect(-totalLength / 2, -bodyWidth / 2, bodyLength, bodyWidth);

  ctx.beginPath();
  ctx.moveTo(totalLength / 2 - headLength, -headWidth / 2);
  ctx.lineTo(totalLength / 2, 0);
  ctx.lineTo(totalLength / 2 - headLength, headWidth / 2);
  ctx.closePath();
  ctx.fill();

  ctx.fillStyle = existingFillStyle;
  ctx.setTransform(existingTransform);
}

/* Draw Warning Icon */
export function drawWarningIcon(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
) {
  // Triangle
  ctx.beginPath();
  ctx.moveTo(x + width / 2, y);
  ctx.lineTo(x + width, y + height);
  ctx.lineTo(x, y + height);
  ctx.closePath();

  ctx.lineWidth = 2;
  ctx.lineJoin = "round";
  ctx.stroke();
  // Exclamation mark
  withFont(ctx, `bold ${height * 0.8}px serif`, () => {
    ctx.fillTextStable(
      "!",
      x + width / 2 - 3,
      y + height / 2 + 2.5,
      undefined,
      "middle",
    );
  });
}

export function prepareStroke(
  entity: DrawableObjectConcrete,
  ctx: CanvasRenderingContext2D,
) {
  if (!entity.isActive()) ctx.strokeStyle = inactiveColor;
}
export function prepareFill(
  entity: DrawableObjectConcrete,
  ctx: CanvasRenderingContext2D,
) {
  if (!entity.isActive()) ctx.fillStyle = inactiveColor;
}

export function drawSystemNodeAccentIfNeeded(options: {
  context: DrawingContext;
  systemNode: DrawableSystemNode;
  style: "mushroom" | "cap";
  orientation: "horizontal" | "vertical";
  isReturn?: boolean;
  isRecirculation?: boolean;
  length:
    | typeof DEFAULT_SYSTEM_NODE_SIZE
    | typeof RECIRCULATION_SYSTEM_NODE_SIZE;
  ioType?: "inlet" | "outlet" | "ambiguous"; // Null if the system node is neither an inlet nor outlet
}) {
  const {
    context,
    systemNode,
    style,
    orientation,
    isReturn,
    isRecirculation,
    length,
    ioType,
  } = options;

  if (!systemNode || !systemNode.entity) {
    Logger.error("system node is null");
    return;
  }

  const oldTransform = context.ctx.getTransform();
  const system = getFlowSystem(
    context.doc.drawing,
    systemNode.entity.systemUid,
  );

  const connections = context.globalStore.getConnections(systemNode.uid);
  const shouldDrawCaps = connections.length === 0;

  let color = (isReturn
    ? (system as PressureFlowSystem)?.return?.color
    : system?.color) || {
    hex: "#000000",
  };

  if (systemNode.entity.allowAllSystems) {
    color = { hex: "#777777" };
  }

  const center = systemNode.effectiveCenter;

  context.ctx.translate(center.x, center.y);

  switch (orientation) {
    case "horizontal": {
      if (center.x > 0) {
        context.ctx.rotate(Math.PI / 2);
      } else {
        context.ctx.rotate(-Math.PI / 2);
      }
      break;
    }
    case "vertical": {
      if (center.y <= 0) {
        context.ctx.rotate(0);
      } else {
        context.ctx.rotate(Math.PI);
      }
      break;
    }
    default:
      assertUnreachable(orientation);
  }

  let yCompensation = 0;
  if (systemNode.entity.parentUid) {
    const parent = globalStore.get(systemNode.entity.parentUid).entity;
    if (
      parent.type === "PLANT" &&
      parent.plant?.shape === PlantShapes.CYLINDER
    ) {
      const r = Math.abs(center.x);
      const y = center.y;
      yCompensation = r - Math.sqrt(r * r - y * y);
    }
  }

  switch (style) {
    case "mushroom": {
      drawRectangles(context.ctx, [
        {
          x1: -10,
          x2: 10,
          y1: shouldDrawCaps ? -length : 0,
          y2: 0 + yCompensation,
          fillStyle: color.hex,
          strokeStyle: color.hex,
        },
      ]);

      // Draw the inlet/outlet arrow if needed
      switch (ioType) {
        case "ambiguous":
        case undefined:
          break;
        case "inlet":
        case "outlet":
          if (
            shouldDrawCaps &&
            !isRecirculation // Do not draw the arrow; there's already a recirculation pump symbol
          ) {
            const arrowAngle = ioType === "outlet" ? -Math.PI / 2 : Math.PI / 2;
            drawArrow({
              ctx: context.ctx,
              x: 0,
              y: -length * 0.35, // Needs to avoid overlapping the pipe cap...
              bodyLength: length * 0.3,
              headLength: length * 0.2,
              bodyWidth: 8,
              headWidth: 24,
              angle: arrowAngle,
              color: {
                hex: "#000000",
              },
            });
          }
          break;
        default:
          assertUnreachableAggressive(ioType);
      }

      shouldDrawCaps && drawPipeCap(context.ctx, { x: 0, y: -length }, color);
      break;
    }
    case "cap": {
      shouldDrawCaps && drawPipeCap(context.ctx, { x: 0, y: 0 }, color);
      break;
    }
    default:
      assertUnreachable(style);
  }

  context.ctx.setTransform(oldTransform);
}

export function drawWindow(
  _context: DrawingContext,
  ctx: CanvasRenderingContext2D,
  drawCoords: [Coord, Coord],
  width: number,
  filledFensHex: string = "#3E4448",
  roomColorHex: string = "#ffffff",
  _wallColorHex: string = "#9D8181",
) {
  const oldTransform = ctx.getTransform();
  const [start, end] = drawCoords;

  // Calculate the angle of the window
  const angle = Math.atan2(end.y - start.y, end.x - start.x);

  // Move the canvas origin to the window start point and rotate the canvas
  ctx.translate(start.x, start.y);
  ctx.rotate(angle);

  // Calculate the length of the window
  const length = Math.sqrt(
    Math.pow(end.y - start.y, 2) + Math.pow(end.x - start.x, 2),
  );

  // Define the stripe width (assuming three stripes in the bottom half)
  const stripeWidth = width / 18;

  // Draw the upper half window detail
  ctx.beginPath();
  ctx.fillStyle = roomColorHex;
  ctx.fillRect(0, -width / 2, length, width / 2);
  ctx.closePath();

  // Draw the lower half background
  const stripeStartX = length / 12;
  ctx.beginPath();
  ctx.fillStyle = roomColorHex;
  ctx.fillRect(stripeStartX, 0, length - 2 * stripeStartX, width / 2);
  ctx.closePath();

  // Draw the top stripe on the lower half
  ctx.beginPath();
  ctx.moveTo(stripeStartX, width / 2 - stripeWidth / 2);
  ctx.lineTo(length - stripeStartX, width / 2 - stripeWidth / 2);
  ctx.strokeStyle = filledFensHex;
  ctx.lineWidth = stripeWidth;
  ctx.stroke();

  // Draw the middle stripe on the lower half
  ctx.beginPath();
  ctx.moveTo(stripeStartX, width / 4);
  ctx.lineTo(length - stripeStartX, width / 4);
  ctx.strokeStyle = filledFensHex;
  ctx.lineWidth = stripeWidth;
  ctx.stroke();

  // Draw the bottom stripe on the lower half
  ctx.beginPath();
  ctx.moveTo(stripeStartX, stripeWidth / 2);
  ctx.lineTo(length - stripeStartX, stripeWidth / 2);
  ctx.strokeStyle = filledFensHex;
  ctx.lineWidth = stripeWidth;
  ctx.stroke();

  // Reset the transform to avoid affecting other drawings
  ctx.setTransform(oldTransform);
}

export function drawLoopEntry(
  context: DrawingContext,
  ctx: CanvasRenderingContext2D,
  drawCoords: [Coord, Coord],
  width: number,
  color: string,
) {
  const oldTransform = ctx.getTransform();
  const [start, end] = drawCoords;

  // Calculate the angle of the window
  const angle = Math.atan2(end.y - start.y, end.x - start.x);

  // Move the canvas origin to the window start point and rotate the canvas
  ctx.translate(start.x, start.y);
  ctx.rotate(angle);

  const length = Math.sqrt(
    Math.pow(end.y - start.y, 2) + Math.pow(end.x - start.x, 2),
  );

  ctx.beginPath();
  ctx.fillStyle = color;
  ctx.fillRect(0, -width / 2, length, width);
  ctx.closePath();

  const lineWidth = context.vp.toWorldLength(2);
  // have black "handlebars" on each side of it to make it more pronounced.
  // hal = handlebar accent length
  const hal = Math.max(context.vp.toWorldLength(2), width / 4);
  ctx.beginPath();
  ctx.strokeStyle = "#000000";
  ctx.lineWidth = lineWidth;
  ctx.moveTo(-hal, -width / 2 - hal);
  ctx.lineTo(0, -width / 2);
  ctx.lineTo(0, width / 2);
  ctx.lineTo(-hal, width / 2 + hal);
  ctx.stroke();
  ctx.moveTo(length + hal, -width / 2 - hal);
  ctx.lineTo(length, -width / 2);
  ctx.lineTo(length, width / 2);
  ctx.lineTo(length + hal, width / 2 + hal);
  ctx.stroke();

  // TODO: arrow pointing in.

  // Reset the transform to avoid affecting other drawings
  ctx.setTransform(oldTransform);
}

export function drawDebugPolygon(
  ctx: CanvasRenderingContext2D,
  shape: Flatten.Polygon,
) {
  if (shape.edges.size === 0) return;
  // retain the original stroke, change it to a thin red line
  const oldStrokeStyle = ctx.strokeStyle;
  const oldLineWidth = ctx.lineWidth;
  const oldAlpha = ctx.globalAlpha;
  ctx.strokeStyle = "#ff0000";
  ctx.lineWidth = 15;
  ctx.globalAlpha = 1;

  ctx.beginPath();
  let first = true;
  shape.edges.forEach((edge: Flatten.Edge) => {
    const { ps: start, pe: end } = edge.shape as Flatten.Segment;
    if (first) {
      ctx.moveTo(start.x, start.y);
      first = false;
    }
    ctx.lineTo(end.x, end.y);
  });
  ctx.stroke();
  ctx.strokeStyle = oldStrokeStyle;
  ctx.lineWidth = oldLineWidth;
  ctx.globalAlpha = oldAlpha;
}

export function drawSingleDoor(
  _context: DrawingContext,
  ctx: CanvasRenderingContext2D,
  drawCoords: [Coord, Coord],
  width: number,
  _isOpenTowardInside: boolean,
  isClockWise: boolean,
  _filledFensHex: string = "#3E4448",
  roomColorHex: string = "#ffffff",
  wallColorHex: string = "#525252",
) {
  const oldTransform = ctx.getTransform();
  const [end, start] = drawCoords;

  // Calculate the angle of the door
  const angle = Math.atan2(end.y - start.y, end.x - start.x);

  // Move the canvas origin to the door start point and rotate the canvas
  ctx.translate(start.x, start.y);
  ctx.rotate(angle);

  // Calculate the length of the door
  const length = Math.sqrt(
    Math.pow(end.y - start.y, 2) + Math.pow(end.x - start.x, 2),
  );

  if (isClockWise) {
    ctx.translate(length, 0);
    ctx.scale(-1, 1);
  }

  const lineWidth = 10;
  const halfLineWidth = lineWidth / 2;
  ctx.lineWidth = lineWidth;

  // draw blockout region
  ctx.beginPath();
  ctx.rect(0, -width / 2 - halfLineWidth, length, width + lineWidth);
  ctx.fillStyle = roomColorHex;
  ctx.fill();
  ctx.closePath();

  ctx.strokeStyle = wallColorHex;
  const doorJambWidth = width;
  const doorJambLength = width / 2;
  const doorBodyWidth = width / 2;

  // draw door jambs
  {
    const vertOffset = doorJambWidth / 2 - halfLineWidth;
    const widthSubLineWidth = doorJambWidth - lineWidth;

    // draw door jamb one
    ctx.beginPath();
    ctx.rect(0, -vertOffset, doorJambLength, widthSubLineWidth);
    ctx.stroke();
    ctx.closePath();

    // draw door jamb two
    ctx.beginPath();
    ctx.rect(length, -vertOffset, -doorJambLength, widthSubLineWidth);
    ctx.stroke();
    ctx.closePath();
  }

  // draw door body
  {
    ctx.beginPath();
    ctx.rect(
      doorJambLength,
      -doorBodyWidth / 2 - halfLineWidth,
      length - 2 * doorJambLength,
      doorBodyWidth + lineWidth,
    );
    ctx.stroke();
    ctx.closePath();
  }

  ctx.setTransform(oldTransform);
}

export function drawBiFoldingDoor(
  _context: DrawingContext,
  ctx: CanvasRenderingContext2D,
  drawCoords: [Coord, Coord],
  width: number,
  _isOpenTowardInside: boolean,
  isClockWise: boolean,
  _filledFensHex: string = "#3E4448",
  roomColorHex: string = "#ffffff",
  wallColorHex: string = "#525252",
) {
  const oldTransform = ctx.getTransform();
  const [end, start] = drawCoords;

  // Calculate the angle of the door
  const angle = Math.atan2(end.y - start.y, end.x - start.x);

  // Move the canvas origin to the door start point and rotate the canvas
  ctx.translate(start.x, start.y);
  ctx.rotate(angle);

  // Calculate the length of the door
  const length = Math.sqrt(
    Math.pow(end.y - start.y, 2) + Math.pow(end.x - start.x, 2),
  );

  if (isClockWise) {
    ctx.translate(length, 0);
    ctx.scale(-1, 1);
  }

  // Draw each half of the bi-folding door as a rectangle
  ctx.beginPath();
  ctx.rect(0, -width / 2, length, width);
  ctx.fillStyle = roomColorHex;
  ctx.fill();
  ctx.closePath();

  // Draw the squeezed V shape on each half
  ctx.beginPath();
  ctx.moveTo(0, width / 4);
  ctx.lineTo(length / 4 - length / 64, -width / 2);
  ctx.lineTo(length / 2 - length / 32, width / 4);

  ctx.moveTo(length / 2 + length / 32, width / 4);
  ctx.lineTo((3 * length) / 4 + length / 64, -width / 2);
  ctx.lineTo(length, width / 4);

  ctx.strokeStyle = wallColorHex;
  ctx.lineWidth = Math.min(20, width / 4);
  ctx.stroke();
  ctx.closePath();

  // Draw the door outline

  if (isClockWise) {
    ctx.translate(-length, 0);
  }
  // Reset the transform to avoid affecting other drawings
  ctx.setTransform(oldTransform);
}

export function withFont(
  ctx: CanvasRenderingContext2D,
  font: string,
  callback: () => void,
) {
  const original = ctx.font;
  ctx.font = font;
  callback();
  ctx.font = original;
}
