import { NullablePrimitive } from "./primitive";

/**
 * Extend this Error class to ensure good sentry error reporting.
 *
 * @param message The Title of the error in sentry
 * @param tags Sentry tags, these are meant to be short, and are indexed, and searchable in sentry,
 *     that we may want so see what percentage are caused by x. Think project id, entity type etc.
 * @param extras Sentry extras, these are more of the nitty gritty details about the error, that are more nuanced.
 */
export class SentryError extends Error {
  /**
   * Wrappers an error so you can rethrow it while adding extra context.
   *
   * @param message The title of this error
   * @param cause The cause
   * @param tags Any extra tags you want to add
   * @param extras Any extras you want to add
   */
  static wrapError(
    message: string,
    cause: unknown,
    tags: Record<string, NullablePrimitive> = {},
    extras: Record<string, any> = {},
  ): SentryError {
    if (cause instanceof Error) {
      return new SentryError(
        `${message}: ${cause.message}`,
        tags,
        extras,
        cause,
      );
    } else if (typeof cause === "string") {
      return new SentryError(`${message}: ${cause}`, tags, extras);
    }
    return new SentryError(`${message}: ${cause}`, tags, {
      ...extras,
      cause: JSON.stringify(cause),
    });
  }

  errorType = "sentry";

  constructor(
    // Name is whats used for the Title of the error in sentry
    // Technically "message", passed to super, is the description.
    // But for simplicity, just keeping them the same, and using tags/extra for everything else.
    readonly name: string,
    readonly tags: Record<string, NullablePrimitive> = {},
    readonly extras: Record<string, any> = {},
    readonly cause?: unknown,
  ) {
    super(name);
  }
}

// Note: Extending Error can be a bit funky, so instance of checks down work out of the box.
// There is a way to do it (assigning a title to the Error prototype), but it's a bit of a hack.
// Doing this is a little safer
export function isSentryError(error: any): error is SentryError {
  if (!error) {
    return false;
  }
  return (
    typeof error === "object" &&
    "errorType" in error &&
    (error["errorType"] as string).includes("sentry")
  );
}
