import assert from "assert";
import { isNull } from "lodash";
import CorePlant from "../../../../../common/src/api/coreObjects/corePlant";
import { CoreV2Radiator } from "../../../../../common/src/api/coreObjects/v2-radiators/coreV2Radiator";
import { GAP_BETWEEN_SIDE_AND_PIPE_MM } from "../../../../../common/src/api/coreObjects/v2-radiators/pipe-routing/constants";
import {
  fromGeneratedToDrawn,
  RoutingGuide,
} from "../../../../../common/src/api/coreObjects/v2-radiators/pipe-routing/pipe-routing";
import { PipeConduitEntity } from "../../../../../common/src/api/document/entities/conduit-entity";
import DirectedValveEntity from "../../../../../common/src/api/document/entities/directed-valves/directed-valve-entity";
import { ValveType } from "../../../../../common/src/api/document/entities/directed-valves/valve-types";
import { topDownLeftRightIOData } from "../../../../../common/src/api/document/entities/plants/v2-radiator/ios-data-in-order";
import { EntityType } from "../../../../../common/src/api/document/entities/types";
import { fillEntityDefaults } from "../../../../../common/src/api/document/utils";
import { Coord } from "../../../../../common/src/lib/coord";
import { cloneSimple } from "../../../../../common/src/lib/utils";
import { DrawableV2RadiatorPlant } from "../../../../../frontend/src/htmlcanvas/objects/v2-radiator/drawableV2RadiatorPlant";
import {
  AutoCADExportConduitEntity,
  AutoCADExportEntity,
  ConduitEntityWithEndpointCoords,
  flattenRadiatorDims,
} from "../../lib/autocad-export/jsonExport";
export function generateAutoCADPipeEntities(args: {
  radiator: DrawableV2RadiatorPlant;
  connectedPipe: PipeConduitEntity;
  routingGuide: RoutingGuide;
  systemNodeUid: string;
}): AutoCADExportConduitEntity[] {
  const { radiator, routingGuide, connectedPipe, systemNodeUid } = args;

  const makePipe = (
    uid: string,
    endpointUids: [string, string],
    endpointCoords: [Coord, Coord],
  ): ConduitEntityWithEndpointCoords => ({
    parentUid: null,
    type: EntityType.CONDUIT,
    uid,
    conduitType: "pipe",
    systemUid: connectedPipe.systemUid,
    lengthM: null,
    heightAboveFloorM: connectedPipe.heightAboveFloorM,
    color: connectedPipe.color,
    entityName: null,
    endpointUid: cloneSimple(endpointUids),
    endpointCoords: cloneSimple(endpointCoords),
    conduit: {
      diameterMM: null,
      material: connectedPipe.conduit.material,
      maximumVelocityMS: connectedPipe.conduit.maximumVelocityMS,
      maximumPressureDropRateKPAM:
        connectedPipe.conduit.maximumPressureDropRateKPAM,
      network: connectedPipe.conduit.network,
      gradePCT: null,
      configurationCosmetic: null,
    },
  });

  const generatedToDrawn = fromGeneratedToDrawn(routingGuide);
  const worldCoords = generatedToDrawn.map((coord) =>
    radiator.toWorldCoord(coord),
  );
  const generatedPipes: ConduitEntityWithEndpointCoords[] = worldCoords.flatMap(
    (coord, index) => {
      if (index === 0) return [];

      const prevIndex = index - 1;

      const prevConnectableUid = `${systemNodeUid}.generatedConnectables.${prevIndex}`;
      const connectableUid =
        index + 1 === worldCoords.length
          ? systemNodeUid // Last connectable has the same uid as the system node itself, since the connected pipe also uses this uid
          : `${systemNodeUid}.generatedConnectables.${index}`;
      const prevCoord = worldCoords[prevIndex];
      const calcPipe = makePipe(
        `${systemNodeUid}.generatedPipes.${prevIndex}`,
        [prevConnectableUid, connectableUid],
        [prevCoord, coord],
      );
      return [calcPipe];
    },
  );

  return generatedPipes.map((pipe) => {
    return {
      ...pipe,
      levelUid: radiator.context.globalStore.levelOfEntity.get(pipe.uid)!,
    };
  });
}

export function generateAutoCADEntitiesForV2Radiator(
  radiator: DrawableV2RadiatorPlant,
): AutoCADExportEntity[] {
  const store = radiator.context.globalStore;

  const levelUid = store.levelOfEntity.get(radiator.entity.uid)!;

  const systemNodesInOrder = topDownLeftRightIOData(radiator.entity);

  const routingGuides = CoreV2Radiator.getRoutingGuides(radiator);

  const generatedPipes: AutoCADExportConduitEntity[] = [];
  let generatedInletUid: string | null = null;
  let generatedOutletuid: string | null = null;

  systemNodesInOrder.forEach((nodeData, index) => {
    const { uid: systemNodeUid, systemUid } = nodeData;
    const routingGuide = routingGuides[index];

    if (!routingGuide) {
      if (systemNodeUid === radiator.entity.inletUid) {
        generatedInletUid = systemNodeUid;
      } else {
        generatedOutletuid = systemNodeUid;
      }
      return;
    }

    const connectedEntities = CorePlant.getConnectedEntities({
      context: radiator.context,
      systemNodeUid,
      systemUid,
    });
    assert(!isNull(connectedEntities));
    const connectedPipe = connectedEntities.conduit.entity as PipeConduitEntity;

    generatedPipes.push(
      ...generateAutoCADPipeEntities({
        radiator,
        connectedPipe,
        routingGuide,
        systemNodeUid,
      }),
    );

    const generatedSystemNodeUid = `${systemNodeUid}.generatedConnectables.0`;
    if (systemNodeUid === radiator.entity.inletUid) {
      generatedInletUid = generatedSystemNodeUid;
    } else {
      generatedOutletuid = generatedSystemNodeUid;
    }
  });

  // Lockshield valve
  const lsvEntity = (() => {
    const lsvSpec = CoreV2Radiator.getLSVSpec(radiator);
    if (!lsvSpec) return null;

    const { systemNodeUid } = lsvSpec;
    const systemNodeObjCoord = CoreV2Radiator.getGeneratedSystemNodeObjCoord(
      radiator,
      systemNodeUid,
    );

    const lsvObjCoord = {
      x:
        systemNodeObjCoord.x < 0
          ? systemNodeObjCoord.x - GAP_BETWEEN_SIDE_AND_PIPE_MM
          : systemNodeObjCoord.x + GAP_BETWEEN_SIDE_AND_PIPE_MM,
      y: systemNodeObjCoord.y,
    };

    const lsv: DirectedValveEntity & { levelUid: string } = {
      type: EntityType.DIRECTED_VALVE,
      valve: {
        type: ValveType.LSV,
        catalogId: "lsv",
      },
      center: radiator.toWorldCoord(lsvObjCoord),
      systemUidOption: radiator.entity.plant.outletSystemUid,
      sourceUid: null,
      parentUid: null,
      uid: `${radiator.uid}.generatedLSV`,
      entityName: null,
      color: null,
      calculationHeightM: radiator.entity.plant.outletHeightAboveFloorM,
      levelUid,
    };

    return lsv;
  })();

  const clone = fillEntityDefaults(radiator.context, radiator.entity);
  clone.inletUid = generatedInletUid;
  clone.plant.outletUid = generatedOutletuid!;

  const radiatorEntity: AutoCADExportEntity = {
    ...clone,
    levelUid,
  };

  flattenRadiatorDims(radiatorEntity);

  return [radiatorEntity, ...(lsvEntity ? [lsvEntity] : []), ...generatedPipes];
}
