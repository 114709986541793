import Flatten from "@flatten-js/core";
import stringify from "json-stable-stringify";
import * as TM from "transformation-matrix";
import CalculationEngine from "../../../../common/src/api/calculations/calculation-engine";
import { getEntitySystem } from "../../../../common/src/api/calculations/utils";
import { StandardFlowSystemUids } from "../../../../common/src/api/config";
import { levelIncludesRiser } from "../../../../common/src/api/coreObjects/lib/utils";
import { determineConnectableSystemUid } from "../../../../common/src/api/coreObjects/utils";
import { isCalculated } from "../../../../common/src/api/document/calculations-objects";
import {
  CalculationData,
  CalculationFieldWithValue,
} from "../../../../common/src/api/document/calculations-objects/calculation-field";
import RiserCalculation from "../../../../common/src/api/document/calculations-objects/riser-calculation";
import { CalculationType } from "../../../../common/src/api/document/calculations-objects/types";
import { SelectedMaterialManufacturer } from "../../../../common/src/api/document/drawing";
import { fillDefaultBigValveFields } from "../../../../common/src/api/document/entities/big-valve/big-valve-entity";
import {
  DrawableEntityConcrete,
  isConnectableEntity,
} from "../../../../common/src/api/document/entities/concrete-entity";
import {
  fillDefaultConduitFields,
  isDuctEntity,
  isPipeEntity,
} from "../../../../common/src/api/document/entities/conduit-entity";
import { fillDirectedValveFields } from "../../../../common/src/api/document/entities/directed-valves/directed-valve-entity";
import { ValveType } from "../../../../common/src/api/document/entities/directed-valves/valve-types";
import { fillFixtureFields } from "../../../../common/src/api/document/entities/fixtures/fixture-entity";
import { fillPlantDefaults } from "../../../../common/src/api/document/entities/plants/plant-defaults";
import { isSpecifyRadiator } from "../../../../common/src/api/document/entities/plants/plant-entity";
import { PlantType } from "../../../../common/src/api/document/entities/plants/plant-types";
import { getFilterTypeToCatalogUid } from "../../../../common/src/api/document/entities/plants/utils";
import { fillRiserDefaults } from "../../../../common/src/api/document/entities/riser-entity";
import {
  EntityType,
  getEntityResultFieldName,
} from "../../../../common/src/api/document/entities/types";
import { getPipeManufacturerByMaterial } from "../../../../common/src/api/document/entities/utils";
import { diffObject } from "../../../../common/src/api/document/state-differ";
import AbbreviatedCalculationReport, {
  ConduitCalculationReportEntry,
  FixtureSpecificationData,
  PipeSpecificationData,
  RiserCalculationReportEntry,
  SpecificationData,
  pipeSpecKey,
} from "../../../../common/src/api/reports/calculation-report";
import { makeStickyCalculationReport } from "../../../../common/src/api/reports/stickyCalculationReport";
import { Logger } from "../../../../common/src/lib/logger";
import {
  assertUnreachable,
  cloneSimple,
} from "../../../../common/src/lib/utils";
import { LayerImplementation } from "../../../src/htmlcanvas/layers/layer";
import CanvasContext from "../../../src/htmlcanvas/lib/canvas-context";
import { MIN_SCALE } from "../../../src/htmlcanvas/lib/object-traits/calculated-object-const";
import { DrawingContext } from "../../../src/htmlcanvas/lib/types";
import {
  CalculationFilterSettings,
  CalculationFilters,
} from "../../../src/store/document/types";
import CalculationReportManager from "../../api/calculationReportManager";
import { trackEvent } from "../../api/mixpanel";
import { updateCalculationReport } from "../../api/reports";
import {
  getEffectiveFilter,
  getFilterSettings,
  setInitFilterSettings,
} from "../../lib/filters/results";
import {
  PINNED_CALC_ENTITY_UIDS_KEY,
  savePreference,
} from "../../lib/localStorage";
import { MainEventBus } from "../../store/main-event-bus";
import { getCalcsWebworkerUrl } from "../../webworker-utils";
import { CalculateCommand, CalculateReturn } from "../../workers/types";
import LayoutAllocator from "../lib/layout-allocator";
import { isArchitecturalEntity } from "../lib/object-traits/util";
import {
  DrawableCalculatedObjectConcrete,
  DrawableObjectConcrete,
  EdgeObjectConcrete,
} from "../objects/concrete-object";
import { DrawingMode, MouseMoveResult } from "../types";

export const SIGNIFICANT_FLOW_THRESHOLD = 1e-5;
export const LABEL_RESOLUTION_PX = 20;
export const MIN_LABEL_RESOLUTION_WL = 20;

export default class CalculationLayer extends LayerImplementation {
  name = "Calculation Layer";

  calculator: CalculationEngine = new CalculationEngine();

  layout = new Map<
    string,
    LayoutAllocator<[string, TM.Matrix, CalculationData[], boolean]>
  >();

  cancelCalculations: (() => void) | null = null;

  constructor(context: CanvasContext) {
    super(context);

    MainEventBus.$on(
      "cancelCalculations",
      this.onCancelCalculations.bind(this),
    );
  }

  onCancelCalculations() {
    if (this.cancelCalculations) {
      this.cancelCalculations();
    }
  }

  getCurrentLayout(context: DrawingContext, forExport: boolean) {
    const { vp } = context;

    setInitFilterSettings(
      Array.from(this.uidsByZIndex.keys()).map(
        (uid) => context.globalStore.get(uid)!,
      ),
      context.doc.uiState.calculationFilterSettings,
      this.context,
    );

    const filterSettings = getFilterSettings(
      context.doc.uiState.calculationFilterSettings,
      context.doc,
    );
    const filters = getEffectiveFilter(
      // Typechecking Perf (any): DrawingContext === CoreContext
      context as any,
      context.doc,
      Array.from(this.uidsByZIndex.keys()).map(
        (uid) => context.globalStore.get(uid)!,
      ),
      filterSettings,
    );

    const resolutionWL = Math.max(
      vp.surfaceToWorldLength(LABEL_RESOLUTION_PX),
      MIN_LABEL_RESOLUTION_WL,
    );
    const rexp = Math.ceil(Math.log2(resolutionWL));
    const effRes = Math.pow(2, rexp);

    return this.getOrCreateLayout(
      context,
      effRes,
      filters,
      filterSettings,
      forExport,
    );
  }

  async draw(
    context: DrawingContext,
    active: boolean,
    shouldContinueInternal: () => boolean,
    reactive: Set<string>,
    mode: DrawingMode,
    withCalculation: boolean,
    forExport: boolean,
    showExport: boolean,
  ) {
    // TODO: asyncify
    if (active && withCalculation) {
      const { ctx, vp } = context;

      const layout = this.getCurrentLayout(context, forExport);
      if (!layout) {
        return;
      }

      const resolutionWL = Math.max(
        vp.surfaceToWorldLength(LABEL_RESOLUTION_PX),
        MIN_LABEL_RESOLUTION_WL,
      );
      const rexp = Math.ceil(Math.log2(resolutionWL));
      const effRes = Math.pow(2, rexp);
      const scaleWarp = effRes / resolutionWL;

      for (const label of layout.getLabels()) {
        const loc = TM.applyToPoint(label[1], { x: 0, y: 0 });

        if (!vp.someOnScreen(Flatten.point(loc.x, loc.y))) {
          continue;
        }
        const o = context.globalStore.get<DrawableCalculatedObjectConcrete>(
          label[0],
        )!;

        if (!o) {
          // can happen after entities are deleted - either live calculations or when
          // editing is enabled in review mode.
          continue;
        }
        if (
          !(showExport && context.doc.uiState.exportSettings.isAppendix) &&
          this.istempVisibleSystemUidsOff(context, o)
        ) {
          continue;
        }
        const [uid, matrix, data, isExport] = label;
        if (!isExport) {
          // actual message
          vp.prepareContext(context.ctx, matrix);
          context.ctx.scale(1 / scaleWarp, 1 / scaleWarp);
          o!.drawCalculationBox(context, data, undefined, undefined, forExport);
        } else if (
          context.doc.uiState.warningFilter.activeEntityUids.includes(uid)
        ) {
          vp.prepareContext(context.ctx, ...o.world2object);
          const s = context.vp.currToSurfaceScale(ctx);
          context.ctx.scale(MIN_SCALE / s, MIN_SCALE / s);
          o!.drawCalculationBox(context, data, false, false, forExport);
        } else {
          /* if (!forExport) {
              // warning icon only
              vp.prepareContext(context.ctx, ...o.world2object);
              const s = context.vp.currToSurfaceScale(ctx);
              context.ctx.scale(MIN_SCALE / s, MIN_SCALE / s);
              o!.drawCalculationBox(context, [], false, true);
            } */
        }
      }
    }
  }

  getOrCreateLayout(
    context: DrawingContext,
    resolution: number,
    calculationFilters: CalculationFilters,
    calculationFilterSettings: CalculationFilterSettings,
    forExport: boolean,
  ):
    | LayoutAllocator<[string, TM.Matrix, CalculationData[], boolean]>
    | undefined {
    const lvlUid = context.doc.uiState.levelUid;
    const key =
      stringify(calculationFilters) +
      stringify(calculationFilterSettings) +
      ".." +
      lvlUid +
      ".." +
      resolution +
      "." +
      forExport;

    if (this.layout.has(key)) {
      return this.layout.get(key)!;
    }

    const res = new LayoutAllocator<
      [string, TM.Matrix, CalculationData[], boolean]
    >(resolution);

    let { vp } = context;
    // standardize the layers to factors of 2.
    const rescale = resolution / vp.surfaceToWorldLength(LABEL_RESOLUTION_PX);
    vp = vp.copy();
    vp.rescale(rescale, 0, 0);
    context = { ...context, vp };

    // 1. Load all calculation data and record them
    // 2. Load all message layout options for this data. Not explcitly needed as a separate step
    // 3. Order objects by importance
    // 4. Draw messages for objects, keeping track of what was drawn and avoid overlaps by drawing
    // in a new place.

    const obj2props = new Map<string, CalculationData[]>();

    for (const uid of this.uidsByZIndex.keys()) {
      const o = this.context.globalStore.get<EdgeObjectConcrete>(uid)!;
      const eName = getEntityResultFieldName(o.entity, context);

      if (
        isCalculated(o.entity) &&
        eName in calculationFilters &&
        calculationFilters[eName].enabled &&
        context.globalStore.getCalculation(o.entity)
      ) {
        const fields = o.getCalculationFields(context, calculationFilters);

        fields.forEach((f: CalculationData) => {
          if (!obj2props.has(f.attachUid)) {
            obj2props.set(f.attachUid, []);
          }
          obj2props.get(f.attachUid)!.push(f);
        });
      }

      // Add an empty record to obj2props to notify the script further down to add the full warning box.
      // We only withhold adding records to obj2props on empty stuff because empty data generates empty
      // boxes, which sadly causes ILLEGAL_PARAMETERS in Flatten further down (a bug) :( so this is a
      // workaround but in theory is not needed.
      if (
        isCalculated(o.entity) &&
        o.hasWarning(context, forExport) &&
        !forExport
      ) {
        if (!obj2props.has(o.uid)) {
          obj2props.set(o.uid, []);
        }
      }

      // don't let labels overlap fittings.
      if (isConnectableEntity(o.entity)) {
        res.placeBlock(o.shape!);
      }
    }

    const filterSystemSetting = calculationFilterSettings.systems.filters;
    let isShowAll = true;
    const disallowedSystems = new Set<string>();
    for (const sName in filterSystemSetting) {
      if (!filterSystemSetting[sName]?.enabled) {
        if (sName !== "all") {
          disallowedSystems.add(sName);
        }
        isShowAll = false;
      }
    }

    const objList = Array.from(this.uidsByZIndex.keys())
      .map(
        (uid) =>
          this.context.globalStore.get<DrawableCalculatedObjectConcrete>(uid)!,
      )
      .filter((o) => {
        const entitySystem = getEntitySystem(
          o.entity,
          this.context.globalStore,
        );
        return (
          (isShowAll ||
            !entitySystem ||
            !disallowedSystems.has(entitySystem)) &&
          o.calculated
        );
      });
    objList.sort((a, b) => {
      return (
        this.messagePriority(context, b) - this.messagePriority(context, a)
      );
    });

    if (lvlUid !== context.doc.uiState.levelUid) {
      return;
    }

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < objList.length; i++) {
      const o = objList[i];

      vp.prepareContext(context.ctx);
      let drawn = false;

      if (obj2props.has(o.uid)) {
        const boxes = o.measureCalculationBox(
          context,
          obj2props.get(o.uid) || [],
          forExport,
        );
        for (const [position, shape] of boxes) {
          if (
            res.tryPlace(shape, [
              o.uid,
              position,
              obj2props.get(o.uid) || [],
              false,
            ])
          ) {
            drawn = true;
            break;
          }
        }
      }

      if (!drawn) {
        // warnings must be drawn. Just show warning symbol.
        if (!(o.calculated && o.hasWarning(context, forExport) && !forExport)) {
          // TODO: References will ALL have to be displayed
          const wc = o.toWorldCoord();
          res.place(Flatten.point(wc.x, wc.y), [
            o.uid,
            o.position,
            obj2props
              .get(o.uid)
              ?.filter(
                (e) =>
                  (e as CalculationFieldWithValue)?.property === "reference",
              ) || [],
            true,
          ]);
        }
      }
    }

    this.layout.set(key, res);
    return res;
  }

  getEntityZIndex(entity: DrawableEntityConcrete): number {
    switch (entity.type) {
      case EntityType.VERTEX:
      case EntityType.ANNOTATION:
        return 140;
      case EntityType.LINE:
        return 130;
      case EntityType.FLOW_SOURCE:
      case EntityType.DAMPER:
        return 120;
      case EntityType.FITTING:
      case EntityType.GAS_APPLIANCE:
      case EntityType.DIRECTED_VALVE:
      case EntityType.MULTIWAY_VALVE:
      case EntityType.LOAD_NODE:
        return 100;
      case EntityType.RISER:
        return 70;
      case EntityType.SYSTEM_NODE:
        return 70;
      case EntityType.CONDUIT:
        return 50;
      case EntityType.BIG_VALVE:
      case EntityType.PLANT:
      case EntityType.FIXTURE:
        return 10;
      case EntityType.COMPOUND:
        return 6;
      case EntityType.FENESTRATION:
        return 4;
      case EntityType.WALL:
        return 3;
      case EntityType.EDGE:
        return 2;
      case EntityType.ARCHITECTURE_ELEMENT:
        return 2;
      case EntityType.AREA_SEGMENT:
        return entity.areaType === "heated-area" ? 1.5 : 2;
      case EntityType.ROOM:
        return 1;
      case EntityType.BACKGROUND_IMAGE:
        return 0;
    }
    assertUnreachable(entity);
  }

  shouldAccept(entity: DrawableEntityConcrete): boolean {
    const showFloorPlan = this.context.document.uiState.showArchitecture;
    switch (entity.type) {
      case EntityType.RISER:
        return levelIncludesRiser(
          this.context.document.drawing.levels[
            this.context.document.uiState.levelUid!
          ],
          entity,
          this.context.$store.getters["document/sortedLevels"],
        );
      case EntityType.FITTING:
      case EntityType.CONDUIT:
      case EntityType.SYSTEM_NODE:
      case EntityType.BIG_VALVE:
      case EntityType.FIXTURE:
      case EntityType.GAS_APPLIANCE:
      case EntityType.DIRECTED_VALVE:
      case EntityType.MULTIWAY_VALVE:
      case EntityType.LOAD_NODE:
      case EntityType.PLANT:
      case EntityType.FLOW_SOURCE:
      case EntityType.COMPOUND:
      case EntityType.EDGE:
      case EntityType.VERTEX:
      case EntityType.ROOM:
      case EntityType.WALL:
      case EntityType.FENESTRATION:
      case EntityType.LINE:
      case EntityType.ANNOTATION:
      case EntityType.ARCHITECTURE_ELEMENT:
      case EntityType.DAMPER:
      case EntityType.AREA_SEGMENT:
        if (!showFloorPlan && isArchitecturalEntity(entity)) return false;
        return (
          this.context.globalStore.levelOfEntity.get(entity.uid) ===
          this.context.document.uiState.levelUid
        );
      case EntityType.BACKGROUND_IMAGE:
        return false;
      default:
        assertUnreachable(entity);
    }
    return false;
  }

  messagePriority(
    context: DrawingContext,
    object: DrawableObjectConcrete,
  ): number {
    if (isCalculated(object.entity)) {
      const calc = context.globalStore.getCalculation(object.entity);
      if (calc && calc.warnings !== null && calc.warnings.length > 0) {
        return 10000; // High priority to warnings.
      }
    }

    // second highest priority to pinned calculations
    if (context.doc.uiState.pinnedCalcEntityUids.includes(object.uid)) {
      return 9999;
    }

    switch (object.type) {
      case EntityType.LINE:
      case EntityType.ANNOTATION:
        return 140;
      case EntityType.FLOW_SOURCE:
        return 130;
      case EntityType.RISER:
        return 120;
      case EntityType.LOAD_NODE:
      case EntityType.FIXTURE:
      case EntityType.GAS_APPLIANCE:
        return 110;
      case EntityType.BIG_VALVE:
      case EntityType.PLANT:
      case EntityType.DIRECTED_VALVE:
      case EntityType.MULTIWAY_VALVE:
      case EntityType.COMPOUND:
      case EntityType.DAMPER:
        return 100;
      case EntityType.FITTING:
        return 90;
      case EntityType.SYSTEM_NODE:
      case EntityType.VERTEX:
        return 80;
      case EntityType.EDGE:
      case EntityType.CONDUIT:
        return 70 + 10 - 10 / (object.computedLengthM + 1);
      case EntityType.ROOM:
      case EntityType.AREA_SEGMENT:
        return 70;
      case EntityType.WALL:
        return 71;
      case EntityType.FENESTRATION:
        return 72;
      case EntityType.ARCHITECTURE_ELEMENT:
        return 73;
      case EntityType.BACKGROUND_IMAGE:
        throw new Error("shouldn't have calculations");
    }
    assertUnreachable(object);
  }

  drawReactiveLayer(_context: DrawingContext, _interactive: string[]): any {
    //
  }

  clearTempCalculationsPreferences(context: CanvasContext) {
    context.document.uiState.skippedCalculations.skipFireCalcs = false;
  }

  calculate(
    context: CanvasContext,
    doneCallback: () => void,
    _numRecalculates: number,
  ) {
    const done = () => {
      this.layout.clear();
      this.clearTempCalculationsPreferences(context);
      if (context.document.uiState.viewOnlyReason === "Calculating") {
        context.document.uiState.viewOnlyReason = null;
      }
      doneCallback();
    };
    const lastLastCalculationId = context.document.uiState.lastCalculationId;

    context.document.uiState.lastCalculationId =
      context.document.nextId + context.document.optimisticHistory.length;

    if (!context.document.uiState.alwaysShowCalculations) {
      context.document.uiState.isCalculating = true;
    }
    context.document.uiState.calculationProgress = null;
    context.document.uiState.viewOnlyReason =
      context.document.uiState.viewOnlyReason || "Calculating";
    context.document.uiState.lastCalculationSuccess = false;

    const worker = new Worker(getCalcsWebworkerUrl(), {
      type: "module",
    });

    function terminateWorker() {
      // in development, we need the worker thread to stay alive
      // so that source maps can be loaded.
      if (process.env.NODE_ENV !== "development") {
        worker.terminate();
      }
    }

    const msg: CalculateCommand = {
      type: "calculate",
      drawing: cloneSimple(context.document.drawing),
      catalog: context.effectiveCatalog,
      priceTable: context.effectivePriceTable,
      nodes: context.$store.getters["customEntity/nodes"],
      opId:
        context.document.nextId - 1 + context.document.optimisticHistory.length,
      locale: context.document.locale,
      skip: context.document.uiState.skippedCalculations,
      featureAccess: context.featureAccess,
      featureFlags: context.featureFlags,
    };

    console.log("Sending message to worker", context.document.nextId - 1);
    worker.postMessage(msg);
    worker.onmessage = (d: MessageEvent<CalculateReturn>) => {
      const { initialOpId } = d.data;

      if (
        initialOpId !==
        context.document.nextId - 1 + context.document.optimisticHistory.length
      ) {
        // This is an old calculation, ignore it.
        console.warn(
          "Old calculation result, ignoring",
          initialOpId,
          context.document.nextId -
            1 +
            context.document.optimisticHistory.length,
        );
        return;
      }

      switch (d.data.type) {
        case "result": {
          const { calculations, drawing, success, warnings } = d.data;

          context.document.uiState.isCalculating = false;

          this.cancelCalculations = null;
          terminateWorker();
          const diff = diffObject(context.document.drawing, drawing, undefined);

          if (diff) {
            Logger.error("Calculation mutated objects:", {}, diff);
          }

          context.globalStore.calculationStore = new Map(calculations);
          context.globalStore.calculationWarnings = new Map(warnings);

          context.globalStore.forEach((o) => {
            context.globalStore.bustDependencies(o.uid);
            o.onRedrawNeeded();
          });

          context.document.uiState.lastCalculationFdrLog =
            d.data.fdrLog || null;
          context.document.uiState.lastCalculationErrorMsg =
            d.data.errorMsg || null;

          if (success) {
            context.document.uiState.lastCalculationUiSettings = {};
            context.document.uiState.lastCalculationSuccess = true;

            if (context.$store.getters["document/shouldSyncWithServer"]) {
              const calculationReport = createCalculationReport(context);

              updateCalculationReport(
                context.document.documentId,
                context.document.nextId +
                  context.document.optimisticHistory.length,
                calculationReport,
              );

              const stickyCalculationReport = makeStickyCalculationReport(
                this.context,
              );

              CalculationReportManager.putStickyCalculations(
                context.document.documentId,
                context.document.nextId - 1,
                stickyCalculationReport,
              );
            }

            trackEvent({
              type: "Calculations Succeeded",
              props: {
                initialOpId: d.data.initialOpId,
                timeMs: d.data.timeMs,
              },
            });
          } else {
            trackEvent({
              type: "Calculations Failed",
              props: {
                fdrFocus: d.data.fdrLog?.at(-1)?.focusedData?.join(","),
                fdrStep: d.data.fdrLog?.at(-1)?.text,
                message: d.data.errorMsg ?? "Unknown",
                initialOpId: d.data.initialOpId,
              },
            });
          }
          done();
          break;
        }
        case "progress": {
          context.document.uiState.calculationProgress = d.data.progress;
          break;
        }
        case "validation-errors": {
          this.cancelCalculations = null;
          context.document.uiState.isCalculating = false;

          terminateWorker();

          // we will allow re-calculation in case the user wanted to be reminded of
          // the validation errors.
          context.document.uiState.lastCalculationId = lastLastCalculationId;

          if (d.data.errors.length > 0) {
            console.warn("Validation errors", d.data.errors);
            MainEventBus.$emit("select", d.data.errors[0]);
          }

          trackEvent({
            type: "Calculations Failed Validation",
            props: {
              initialOpId: d.data.initialOpId,
            },
          });

          done();
          break;
        }
      }
    };
    worker.onerror = (e) => {
      this.cancelCalculations = null;
      terminateWorker();
      Logger.error(`Calculation crash ${e.message}`);
      context.document.uiState.isCalculating = false;

      trackEvent({
        type: "Calculations Crashed",
        props: {
          // Its very likely both of these are undefined
          message: e.message ?? JSON.stringify(e.error),
          initialOpId: msg.opId,
        },
      });

      done();
    };

    this.cancelCalculations = () => {
      terminateWorker();
      this.cancelCalculations = null;
      context.document.uiState.lastCalculationId = lastLastCalculationId;
      context.document.uiState.isCalculating = false;
      context.document.uiState.drawingMode = DrawingMode.Design;
      done();
    };
  }

  onMouseDown(event: MouseEvent, context: CanvasContext): boolean {
    const uiState = context.document.uiState;
    if (uiState.hoveredResultsUid) {
      return true;
    }

    return super.onMouseDown(event, context);
  }

  onMouseUp(event: MouseEvent, context: CanvasContext) {
    const coord = context.viewPort.toWorldCoord({
      x: event.clientX,
      y: event.clientY,
    });

    const point = new Flatten.Point(coord.x, coord.y);

    for (const [_key, value] of this.layout) {
      const intersection = value.findIntersectionOf(point);
      if (intersection && intersection[2]) {
        const entityUid = intersection[2][0];
        this.toggleResultPin(context, entityUid);
        return true;
      }
    }

    if (super.onMouseUp(event, context)) {
      return true;
    }

    return false;
  }

  toggleResultPin(context: CanvasContext, entityUid: string) {
    // toggle pin state
    const uiState = context.document.uiState;
    const isPinned = uiState.pinnedCalcEntityUids.includes(entityUid);

    if (isPinned) {
      const index = uiState.pinnedCalcEntityUids.indexOf(entityUid);
      uiState.pinnedCalcEntityUids.splice(index, 1);
      MainEventBus.$emit("result-label-unpin");
    } else {
      uiState.pinnedCalcEntityUids.push(entityUid);
      MainEventBus.$emit("result-label-pin");
    }

    // clear cache to redraw with new priorities
    this.layout.clear();
    MainEventBus.$emit("redraw");

    // save to local storage
    savePreference(
      window,
      PINNED_CALC_ENTITY_UIDS_KEY(context.document.documentId),
      uiState.pinnedCalcEntityUids,
    );
  }

  onMouseMove(
    event: MouseEvent,
    context: CanvasContext,
    fromUid: string | undefined,
  ): MouseMoveResult {
    // find and handle hovered results
    const uiState = context.document.uiState;
    uiState.hoveredResultsUid = null;
    const coord = context.viewPort.toWorldCoord({
      x: event.clientX,
      y: event.clientY,
    });
    const point = new Flatten.Point(coord.x, coord.y);
    let redraw = false;

    if (context.lastDrawingContext) {
      const layout = this.getCurrentLayout(context.lastDrawingContext, false);
      if (layout) {
        const intersection = layout.findIntersectionOf(point);

        if (intersection && intersection[2]) {
          redraw = true;

          if (uiState.hoveredResultsUid === intersection[2][0]) {
            redraw = false;
          }
          uiState.hoveredResultsUid = intersection[2][0];
        }
      }
    }

    if (redraw) {
      MainEventBus.$emit("redraw");
      return { handled: true, cursor: "pointer" };
    }

    return super.onMouseMove(event, context, fromUid);
  }
}

// legacy calculation report. This part provides the backend some data
// that is only available after frontend calculations, which the backend
// would do to generate the legacy periodic reports.
function generateLegacyCalculationReport(context: CanvasContext) {
  const calculations: AbbreviatedCalculationReport["calculations"] = {};
  for (const k of context.globalStore.getCalculations().keys()) {
    const calc = context.globalStore.getCalculations().get(k);
    if (k.length == 36 && calc) {
      switch (calc.type) {
        case CalculationType.PipeCalculation:
          if ("realNominalPipeDiameterMM" in calc) {
            const pipeCalcEntry: ConduitCalculationReportEntry = {
              type: EntityType.CONDUIT,
              nominalSizeMM: calc.realNominalPipeDiameterMM,
              lengthM: calc.lengthM,
            };
            calculations[k] = pipeCalcEntry;
          }
          break;
        case CalculationType.RiserCalculation:
          const riserCalc = calc as RiserCalculation;
          const pipesComponents: ConduitCalculationReportEntry[] = [];
          const sortedLevels = Object.values(
            context.document.drawing.levels,
          ).sort((a, b) => a.floorHeightM - b.floorHeightM);
          for (
            let lvlIndex = 0;
            lvlIndex < sortedLevels.length - 1;
            lvlIndex++
          ) {
            const level = sortedLevels[lvlIndex];
            pipesComponents.push({
              type: EntityType.CONDUIT,
              nominalSizeMM: riserCalc.heights[level.uid].sizeMM,
              lengthM:
                lvlIndex < sortedLevels.length
                  ? sortedLevels[lvlIndex + 1].floorHeightM - level.floorHeightM
                  : 0,
            });
          }
          const riserCalcEntry: RiserCalculationReportEntry = {
            type: EntityType.RISER,
            expandedEntities: pipesComponents,
          };
          calculations[k] = riserCalcEntry;
          break;
        default:
          break;
      }
    }
  }
  return calculations;
}

// TODO: test that every manufacturer is represented here.
// New reporting fuctionality. Generate speceification records from the frontend
// that the backend will just put into the database.
function generateSpecificationReport(context: CanvasContext) {
  // by partType, manufacturer
  const specificationsIndexed: Record<
    string,
    Record<string, SpecificationData[]>
  > = {};

  // by material, manufacturer, size
  const pipeSpecifications: AbbreviatedCalculationReport["specifications"]["pipeSpecifications"] =
    {};

  const specifications: AbbreviatedCalculationReport["specifications"] = {
    byPartType: specificationsIndexed,
    pipeSpecifications,
  };

  function addSpecification(
    partType: string,
    manufacturer: string,
    spec: SpecificationData,
  ) {
    if (!specificationsIndexed[partType]) {
      specificationsIndexed[partType] = {};
    }
    if (!specificationsIndexed[partType][manufacturer]) {
      specificationsIndexed[partType][manufacturer] = [];
    }
    specificationsIndexed[partType][manufacturer].push(spec);
  }

  function addPipeSpecification(
    material: string,
    manufacturer: string,
    spec: PipeSpecificationData,
  ) {
    const key = pipeSpecKey(material, manufacturer, spec.flowSystemUid);
    if (!pipeSpecifications[key]) {
      pipeSpecifications[key] = {
        material,
        manufacturer,
        segments: 0,
        flowSystemUid: spec.flowSystemUid,
        bySize: {},
      };
    }

    pipeSpecifications[key].segments++;

    if (!pipeSpecifications[key].bySize[Number(spec.nominalSizeMM)]) {
      pipeSpecifications[key].bySize[Number(spec.nominalSizeMM)] = {
        flowSystemUid: spec.flowSystemUid,
        lengthM: 0,
        nominalSizeMM: spec.nominalSizeMM,
      };
    }

    if (spec.lengthM != null) {
      pipeSpecifications[key].bySize[Number(spec.nominalSizeMM)].lengthM! +=
        spec.lengthM;
    }
  }

  for (const o of context.globalStore.values()) {
    switch (o.entity.type) {
      case EntityType.RISER: {
        const filled = fillRiserDefaults(context, o.entity);
        const calc = context.globalStore.getCalculation(o.entity);
        if (!calc || !calc.expandedEntities) {
          break;
        }

        for (const segment of Object.values(calc.expandedEntities)) {
          // TODO: specifications for ducts, (vents) and other conduits.
          if (segment.type === EntityType.CONDUIT) {
            switch (segment.conduitType) {
              case "pipe":
                const pCalc = context.globalStore.getCalculation(segment);
                addPipeSpecification(
                  filled.riser.material!,
                  getPipeManufacturerByMaterial(
                    context.document.drawing,
                    filled.riser.material!,
                  ),
                  {
                    flowSystemUid: segment.systemUid,
                    lengthM: segment.lengthM,
                    nominalSizeMM: pCalc?.realNominalPipeDiameterMM || null,
                  },
                );
                break;
              case "duct":
                // TODO Ducts
                break;
              case "cable":
                throw new Error("Not implemented");
              default:
                assertUnreachable(segment);
            }
          }
        }
        break;
      }
      case EntityType.CONDUIT: {
        if (isPipeEntity(o.entity)) {
          // TODO: specifications for ducts, (vents) and other conduits.
          const calc = context.globalStore.getCalculation(o.entity);
          const filled = fillDefaultConduitFields(context, o.entity);

          addPipeSpecification(
            filled.conduit.material!,
            getPipeManufacturerByMaterial(
              context.document.drawing,
              filled.conduit.material!,
            ),
            {
              flowSystemUid: o.entity.systemUid,
              lengthM: calc?.lengthM || null,
              nominalSizeMM: calc?.realNominalPipeDiameterMM || null,
            },
          );
        } else if (isDuctEntity(o.entity)) {
          // TODO Ducts
        } else {
          throw new Error("Not implemented");
        }
        break;
      }
      case EntityType.BIG_VALVE: {
        const calc = context.globalStore.getCalculation(o.entity);
        const filled = fillDefaultBigValveFields(context, o.entity);
        switch (filled.valve.catalogId) {
          case "RPZD": {
            // This is a hot-cold rpzd
            const manObject =
              context.document.drawing.metadata.catalog.backflowValves.find(
                (mat: SelectedMaterialManufacturer) =>
                  mat.uid === filled.valve.catalogId,
              );
            const manufacturer = manObject ? manObject.manufacturer : "generic";

            addSpecification(
              `backflowValves.${filled.valve.catalogId}`,
              manufacturer,
              {
                sizeMM: calc?.rpzdSizeMM?.["hot-water"] || null,
                flowSystemUid: StandardFlowSystemUids.HotWater,
              },
            );
            addSpecification(
              `backflowValves.${filled.valve.catalogId}`,
              manufacturer,
              {
                sizeMM: calc?.rpzdSizeMM?.["cold-water"] || null,
                flowSystemUid: StandardFlowSystemUids.ColdWater,
              },
            );
            break;
          }
          case "temperingValve":
          case "tmv": {
            const manObject =
              context.document.drawing.metadata.catalog.mixingValves.find(
                (mat: SelectedMaterialManufacturer) =>
                  mat.uid === filled.valve.catalogId,
              );
            const manufacturer = manObject ? manObject.manufacturer : "generic";

            addSpecification(
              `mixingValves.${filled.valve.catalogId}`,
              manufacturer,
              {
                flowRateLS:
                  Math.max(
                    calc?.coldPeakFlowRate || 0,
                    calc?.hotPeakFlowRate || 0,
                  ) ?? null,
                sizeMM: calc?.mixingValveSizeMM || null,
                pressureDropKPA:
                  calc?.outputs?.[StandardFlowSystemUids.WarmWater]
                    ?.pressureDropKPA || null,
              },
            );

            break;
          }
        }
        break;
      }
      case EntityType.FIXTURE: {
        const calc = context.globalStore.getCalculation(o.entity);
        const filled = fillFixtureFields(context, o.entity);
        const fixtureDocumentEntry =
          context.document.drawing.metadata.catalog.fixtures.find(
            (f) => f.uid === filled.name,
          );
        const manufacturer = fixtureDocumentEntry
          ? fixtureDocumentEntry.manufacturer
          : "generic";

        addSpecification(`fixtures.${filled.name}`, manufacturer, {
          // None
          inletResidualPressureKPA:
            (calc?.inlets &&
              Object.entries(calc.inlets).reduce(
                (acc, [flowSystemUid, cur]) => {
                  acc![flowSystemUid] = cur.pressureKPA;
                  return acc;
                },
                {} as FixtureSpecificationData["inletResidualPressureKPA"],
              )) ||
            null,
        });
        break;
      }
      case EntityType.DIRECTED_VALVE: {
        const calc = context.globalStore.getCalculation(o.entity);
        const filled = fillDirectedValveFields(context, o.entity);

        switch (filled.valve.type) {
          case ValveType.BALANCING: {
            const manObject =
              context.document.drawing.metadata.catalog.balancingValves.find(
                (mat: SelectedMaterialManufacturer) =>
                  // there is a bug not worth fixing, which is a mismatch between catalogUid here
                  // and balancing valve there. meh meh meh
                  (mat.uid = "balancingValves"),
                //mat.uid === filled.valve.catalogId
              );
            const manufacturer = manObject ? manObject.manufacturer : "generic";
            addSpecification(`balancingValves`, manufacturer, {
              pressureDropKPA: calc?.pressureDropKPA || null,
              kv: calc?.kvValue || null,
              returnLoopFlowRateLS: calc?.flowRateLS || null,
              flowRateLS: calc?.flowRateLS || null,
              flowSystemUid:
                determineConnectableSystemUid(context.globalStore, filled) ||
                null,
            });
            break;
          }
          case ValveType.PRV_SINGLE:
          case ValveType.PRV_DOUBLE:
          case ValveType.PRV_TRIPLE: {
            const manObject =
              context.document.drawing.metadata.catalog.prv.find(
                (mat: SelectedMaterialManufacturer) =>
                  mat.uid === filled.valve.catalogId,
              );
            const manufacturer = manObject ? manObject.manufacturer : "generic";

            let cnt = 1;
            if (filled.valve.type === ValveType.PRV_DOUBLE) {
              cnt = 2;
            } else if (filled.valve.type === ValveType.PRV_TRIPLE) {
              cnt = 3;
            }

            for (let i = 0; i < cnt; i++) {
              addSpecification(`prv`, manufacturer, {
                sizeMM: calc?.sizeMM || null,
                targetPressureKPA: filled.valve.targetPressureKPA,
                flowRateLS:
                  calc?.flowRateLS == null ? null : calc.flowRateLS / cnt,
                residualIncomingPressureKPA: calc?.pressureKPA || null,
                flowSystemUid:
                  determineConnectableSystemUid(context.globalStore, filled) ||
                  null,
              });
            }
            break;
          }
          case ValveType.FLOOR_WASTE: {
            const manObject =
              context.document.drawing.metadata.catalog.floorWaste.find(
                (mat: SelectedMaterialManufacturer) =>
                  mat.uid === filled.valve.catalogId,
              );
            const manufacturer = manObject ? manObject.manufacturer : "generic";
            addSpecification(`floorWaste`, manufacturer, {
              variant: filled.valve.variant,
              bucketTrapSize: filled.valve.bucketTrapSize,
              flowSystemUid:
                determineConnectableSystemUid(context.globalStore, filled) ||
                null,
            });
            break;
          }
          case ValveType.INSPECTION_OPENING: {
            const manObject =
              context.document.drawing.metadata.catalog.inspectionOpening.find(
                (mat: SelectedMaterialManufacturer) =>
                  mat.uid === filled.valve.catalogId,
              );
            const manufacturer = manObject ? manObject.manufacturer : "generic";
            addSpecification(`inspectionOpening`, manufacturer, {
              sizeMM: calc?.sizeMM || null,
            });
            break;
          }
          case ValveType.RPZD_SINGLE:
          case ValveType.RPZD_DOUBLE_SHARED:
          case ValveType.RPZD_DOUBLE_ISOLATED: {
            const cnt = filled.valve.type === ValveType.RPZD_SINGLE ? 1 : 2;
            const manObject =
              context.document.drawing.metadata.catalog.backflowValves.find(
                (mat: SelectedMaterialManufacturer) =>
                  mat.uid === filled.valve.catalogId,
              );
            const manufacturer = manObject ? manObject.manufacturer : "generic";
            for (let i = 0; i < cnt; i++) {
              addSpecification(
                `backflowValves.${filled.valve.catalogId}`,
                manufacturer,
                {
                  sizeMM: calc?.sizeMM || null,
                  flowSystemUid:
                    determineConnectableSystemUid(
                      context.globalStore,
                      filled,
                    ) || null,
                },
              );
            }
            break;
          }
        }
        break;
      }
      case EntityType.PLANT: {
        const calc = context.globalStore.getCalculation(o.entity);
        const filled = fillPlantDefaults(context, o.entity);

        switch (filled.plant.type) {
          case PlantType.PUMP: {
            const manufacturer = filled.plant.manufacturer!;
            addSpecification(`pump`, manufacturer, {
              model:
                (Array.isArray(calc?.model) ? calc?.model[0] : calc?.model) ||
                null,
              pressureKPA: calc?.exitPressureKPA || null,
              dutyKPA: calc?.pumpDutyKPA || null,
              configuration: filled.plant.configuration || null,
              flowRateLS: calc?.pumpFlowRateLS || null,
              flowSystemUid: filled.inletSystemUid || null,
            });
            break;
          }
          case PlantType.PUMP_TANK: {
            const manufacturer = filled.plant.manufacturer!;
            addSpecification(`pumpTank`, manufacturer, {
              model:
                (Array.isArray(calc?.model) ? calc?.model[0] : calc?.model) ||
                null,
              pressureKPA: calc?.exitPressureKPA || null,
              dutyKPA: calc?.pumpDutyKPA || null,
              configuration: filled.plant.configuration || null,
              flowRateLS: calc?.pumpFlowRateLS || null,
              flowSystemUid: filled.inletSystemUid || null,
              capacityL: calc?.capacityL || null,
            });
            break;
          }
          case PlantType.RETURN_SYSTEM: {
            const pCalc = context.globalStore.getCalculation(filled);

            {
              const manObject =
                context.document.drawing.metadata.catalog.hotWaterPlant.find(
                  (mat: SelectedMaterialManufacturer) =>
                    mat.uid === "hotWaterPlant",
                );
              const manufacturer = manObject
                ? manObject.manufacturer
                : "generic";
              addSpecification(`hotWaterPlant`, manufacturer, {
                variant:
                  (manufacturer === "rheem" && filled.plant.rheemVariant) ||
                  null,
                storageTank:
                  (manufacturer === "rheem" &&
                    filled.plant.rheemStorageTankSize) ||
                  null,
                flowRateLS: calc?.pumpFlowRateLS || null,
                peakHourFlowRateLS: filled.plant.rheemPeakHourCapacity,
                models: pCalc?.model || null,
              });
            }

            {
              const manObject =
                context.document.drawing.metadata.catalog.hotWaterPlant.find(
                  (mat: SelectedMaterialManufacturer) =>
                    mat.uid === "circulatingPumps",
                );
              const manufacturer = manObject
                ? manObject.manufacturer
                : "generic";

              const numReturns = filled.plant.outlets.filter(
                (o) => o.outletReturnUid,
              ).length;

              for (let i = 0; i < numReturns; i++) {
                addSpecification(`circulatingPumps`, manufacturer, {
                  model: (pCalc && pCalc.circulatingPumpModel[i]) || null,
                });
              }
            }

            break;
          }
          case PlantType.DRAINAGE_GREASE_INTERCEPTOR_TRAP: {
            const manObject =
              context.document.drawing.metadata.catalog.greaseInterceptorTrap?.find(
                (mat: SelectedMaterialManufacturer) =>
                  mat.uid === "greaseInterceptorTrap",
              );
            const gitCalc = context.globalStore.getCalculation(filled);
            const manufacturer = manObject ? manObject.manufacturer : "generic";
            addSpecification(`greaseInterceptorTrap`, manufacturer, {
              capacity: calc?.capacityL || null,
              location: filled.plant.location,
              position: filled.plant.position,
              flowSystemUid: filled.inletSystemUid || null,
              model:
                (gitCalc &&
                  (Array.isArray(gitCalc.model)
                    ? gitCalc.model[0]
                    : gitCalc.model)) ||
                null,
            });
            break;
          }
          case PlantType.FILTER: {
            const filterType = getFilterTypeToCatalogUid(
              filled.plant.filterType,
            );
            addSpecification(
              `filters.${filterType}`,
              filled.plant.manufacturer!,
              {
                model: calc?.model ? String(calc.model) : null,
                configuration: filled.plant.configuration,
              },
            );
            break;
          }
          case PlantType.RO: {
            addSpecification(`roPlant`, filled.plant.manufacturer!, {
              model: calc?.model ? String(calc.model) : null,
            });
            break;
          }
          case PlantType.RADIATOR: {
            const manufacturer = isSpecifyRadiator(filled.plant)
              ? filled.plant.manufacturer
              : "generic";
            const rangeType = isSpecifyRadiator(filled.plant)
              ? filled.plant.rangeType
              : null;
            addSpecification(`heatEmitters.radiators`, manufacturer, {
              rangeType,
              model: calc?.model ? String(calc.model) : null,
              ratingKw: calc?.heatingRatingKW ?? null,
            });
            break;
          }
        }
        break;
      }
      case EntityType.FITTING:
      case EntityType.MULTIWAY_VALVE:
      case EntityType.SYSTEM_NODE:
      case EntityType.LOAD_NODE:
      case EntityType.FLOW_SOURCE:
      case EntityType.GAS_APPLIANCE:
      case EntityType.COMPOUND:
      case EntityType.BACKGROUND_IMAGE:
      case EntityType.EDGE:
      case EntityType.VERTEX:
      case EntityType.ROOM:
      case EntityType.WALL:
      case EntityType.FENESTRATION:
      case EntityType.LINE:
      case EntityType.ANNOTATION:
      case EntityType.ARCHITECTURE_ELEMENT:
      case EntityType.DAMPER:
      case EntityType.AREA_SEGMENT:
        break;
      default:
        assertUnreachable(o.entity);
    }
  }

  return specifications;
}

function createCalculationReport(context: CanvasContext) {
  const calculationReport: AbbreviatedCalculationReport = {
    calculations: generateLegacyCalculationReport(context),
    specifications: generateSpecificationReport(context),
  };

  return calculationReport;
}
