import {
  Precision,
  Units,
  convertMeasurementSystem,
} from "../../../lib/measurements";
import { SentryError } from "../../../lib/sentry-error";
import { CoreContext } from "../../calculations/types";
import LoadNodeEntity from "../entities/load-node-entity";
import { PumpConfiguration } from "../entities/plants/plant-types";
import { WarningDescription, WarningDetail } from "./warnings";

function dv(context: CoreContext, units: Units, value: number) {
  const val = convertMeasurementSystem(
    context.drawing.metadata.units,
    units,
    value,
    Precision.DISPLAY,
  );
  return val[1] + val[0];
}

export const WarningDefinitions = {
  MAX_PRESSURE_EXCEEDED_NODE: (
    args: { pressureKPA: number; targetKPA: number },
    c: CoreContext,
  ) => ({
    title: `Maximum Pressure of ${dv(
      c,
      Units.KiloPascals,
      args.targetKPA,
    )} Exceeded (${dv(c, Units.KiloPascals, args.pressureKPA)})`,
    description: `The maximum inlet pressure of the node has been exceeded.<br />
        To resolve this, you will need to add pressure reducing valves to your design.
        Alternatively, consider reducing the pressure at the pump or overriding the inlet pressure properties of the node.
        If you are surprised to receive this warning, please review the flow source and floor level heights as they may have been entered incorrectly.`,
    helpLink: "",
  }),
  MAX_PRESSURE_EXCEEDED: (
    args: { pressureKPA: number; targetKPA: number },
    c: CoreContext,
  ) => ({
    title: `Maximum Pressure of ${dv(
      c,
      Units.KiloPascals,
      args.targetKPA,
    )} Exceeded (${dv(c, Units.KiloPascals, args.pressureKPA)})`,
    description: `The maximum inlet pressure has been exceeded.<br />
        To resolve this, you will need to add pressure reducing valves to your design.
        Alternatively, consider reducing the pressure at the pump.
        If you are surprised to receive this warning, please review the flow source and floor level heights as they may have been entered incorrectly.`,
    helpLink: "",
  }),
  MAX_PRESSURE_EXCEEDED_PIPE: (
    args: { pressureKPA: number; actualKPA: number },
    c: CoreContext,
  ) => ({
    title: `Safe Working Pressure of ${dv(
      c,
      Units.KiloPascals,
      args.actualKPA,
    )} Exceeded (${dv(c, Units.KiloPascals, args.pressureKPA)})`,
    description: `The safe working pressure of the chosen pipe material has been exceeded.<br/>
        To resolve this, you can choose a new pipe material in the Settings > Flow Systems.
        Alternatively, consider adding pressure reduction valves to your system or reduce the pressure at the pump.
        If you are surprised to receive this warning, please review the flow source and floor level heights as they may have been entered incorrectly.`,
    helpLink: "https://youtu.be/R_PIJg7i6uE",
  }),
  MAX_FLOW_RATE_EXCEEDED: (args: { flowRateLS: number }, c: CoreContext) => ({
    title: `Max Flow Rate ${dv(
      c,
      Units.LitersPerSecond,
      args.flowRateLS,
    )} exceeded`,
    description: `The maximum flow rate of the valve has been exceeded.<br /> To resolve this, you should add more mixing valves to share the load of the flow rate.

        Alternatively, you can override the 'Maximum Flow Rate' in the properties of the valve.
        
        If you are surprised to receive this warning, please review the fixtures and nodes that are connected as there may be incorrect inputs.`,
    helpLink: "https://youtu.be/KPzWxiQsHt0",
  }),
  MAX_UNVENTED_DRAINAGE_FLOW_EXCEEDED: (
    args: { maxWCs: number; valueWCs: number },
    c: CoreContext,
  ) => ({
    title: `Unvented Drain Exceeds ${dv(
      c,
      Units.LitersPerSecond,
      args.maxWCs,
    )} WCs (${args.valueWCs})`,
    shortTitle: "Max. Unvented WC's",
    description: `There are too many WC's connected to the unvented pipe based on the flow system settings.<br />
        To resolve this, you can add a vent to the system or modify the pipe layout.<br />
        Alternatively, you can edit the maximum number of WC's allowed to connect to an unvented pipe length in Settings > Flow Systems.`,
    helpLink: "",
  }),
  MAX_UNVENTED_LENGTH: (
    args: { valueM: number; maxM: number },
    c: CoreContext,
  ) => ({
    title: `Max unvented length of ${dv(
      c,
      Units.Meters,
      args.maxM,
    )} exceeded (${dv(c, Units.Meters, args.valueM)})`,
    description: `The fixture at the upstream end of the pipe is too far from a vent or vented drain based on the flow system settings.<br /> 

        To resolve this, you can add a vent to the system or modify the pipework layout so the distance to the fixture does not exceed the maximum length.
        
        Alternatively, you can edit the maximum unvented length in Settings > Flow Systems.`,
    helpLink: "",
  }),
  MAX_PER_LEVEL_EXCEEDED: (
    args: { value: string; level: string },
    c: CoreContext,
  ) => ({
    title: `Max ${args.value} per level exceeded on level ${args.level}`,
    description: `The fixtures connecting to the stack on this level exceed the maximum that is allowed based on the flow system settings.<br /> 

        To resolve this, you will need to take some of the fixtures to a different stack so that the load is reduced below the maximum that is allowed .
        
        Alternatively, you can edit the maximum allowance in Settings > Flow Systems.`,
    helpLink: "",
  }),
  MAX_PRESSURE_OVERLOAD: (
    args: { maxKPA: number; systemName: string },
    c: CoreContext,
  ) => ({
    title: `${args.systemName} pressure overload. Max: ${dv(
      c,
      Units.KiloPascals,
      args.maxKPA,
    )}`,
    description: `The maximum inlet pressure of the fixture has been exceeded. <br />

        To resolve this, you will need to add pressure reducing valves to your design.
        
        Alternatively, consider reducing the pressure at the pump or overriding the inlet pressure properties of the fixture.
        
        If you are surprised to receive this warning, please review the flow source and floor level heights as they may have been entered incorrectly.`,
    helpLink: "https://youtu.be/y9MQQZVQLJw",
  }),
  CONNECT_THE_FIXTURE_TO_A_FLOW_SYSTEM: {
    title: "Connect the fixture to a flow system",
    description: `The fixture is missing a pipe connection.<br /> The warning may have appeared because you are trying to connect a flow system to the fixture that is currently being prevented. 

        To resolve this, you can 'Allow Other Systems to Connect' in the properties of a fixture. If you need to make this change to a lot of fixtures, you can right-click on the fixture to 'select similar' to make a change for all of the fixtures. You will then need to re-connect the pipe to each fixture.
        
        If you do not want to connect another pipe to the fixture because it is not required, you can hide the warning.`,
    helpLink: "",
  },
  FLOW_SYSTEM_NOT_CONNECTED_TO_PLANT: {
    title: "Flow System Not Connected to Plant",
    description: `The plant is missing a pipe connection.<br /> The warning may have appeared because you are trying to connect a flow system to the plant that is currently being prevented. 

        To resolve this, you can change the 'Inlet Flow System' or  'Outlet Flow System' in the properties of the plant to suit your design.
        
        If you do not want to connect another pipe to the plant because it is not required, you can hide the warning.`,
    helpLink: "",
  },
  UPDATE_FLOW_SYSTEM_SETTINGS: {
    title: "Update Flow System Settings",
    description: `There is no suitable pipe size to choose based on the fixture units connected to the pipe and the flow system settings. <br />

        To resolve this, you will need to change the pipe sizing table (Settings > Flow Systems).
        
        Alternatively, you can reduce the amount of loading units connected to the pipe.`,
    helpLink: "",
  },
  LOOP_IN_PIPESYSTEM_DETECTED: {
    title: "Loop in pipesystem detected",
    description: "",
  },
  INVALID_LOOP: {
    title: "Loop in Pipe System",
    description: "An invalid loop has been detected in the pipe system.",
    helpLink: "",
  },
  CHANGE_THE_PEAK_FLOW_RATE_CALCULATION_METHOD: {
    title: "Change the Peak Flow Rate Calculation Method",
    description: `The loading units (full flow rate for DIN) on the pipe have exceeded the limit in the Standard/Guideline. <br />

        To resolve this, you will need to change the 'Peak Flow Rate Calculation Method' by going to the Settings > Calculations.
        
        Alternatively, you can reduce the amount of loading units (full flow rate for DIN) that are connected to that pipe so that the conversion to a flow rate can be undertaken.`,
    helpLink: "",
  },
  NO_SUITABLE_PIPE_SIZE: {
    title: "No suitable pipe size in the catalog for this flow rate",
    description: `There is no suitable pipe size to choose based on the flow rate, pipe material, and maximum velocity parameter. <br />

        To resolve this, you will need to change the pipe material (Settings > Flow Systems) or increase the maximum velocity parameter (Settings > Flow System).
        
        Alternatively, you can reduce the amount of flow rate going through the pipe.`,
    helpLink: "",
  },
  PRESSURE_AT_UPSTREAM_NEEDS_HIGHER_THAN_DOWNSTREAM: {
    title:
      "Pressure at upstream source/regulator needs to be higher than downstream regulator/appliance",
    description: `The pressure at the upstream source/regulator is equal to or greater than the pressure at the downstream regulator/appliance. <br />

        To resolve this, increase the pressure at the upstream source/regulator or the downstream regulator/appliance.`,
    helpLink: "",
  },
  EXTRAPOLATED: {
    title: "Extrapolated",
    description: `The conversion from loading units to a flow rate has exceeded the limit in the Standard/Guideline.<br /> Therefore, an estimate flow rate has been provided based on extrapolation. 

        If you want to change this, you will need to change the 'Peak Flow Rate Calculation Method' by going to the Settings > Calculations.`,
    helpLink: "",
  },
  VALVE_IN_WRONG_DIRECTION: {
    title: "Valve in wrong direction",
    description: "",
    helpLink: "",
  },
  DIRECTED_VALVE_FORBIDDEN_HERE: {
    title: "Directed Valve Forbidden Here",
    description: "Directed valves are not allowed on the recirculating system.",
  },
  SOUTHLAND_RO_PLANT: {
    title:
      "Contact Southland Filtration 1800 656 771 for site specific sizing and process design configuration ",
    description:
      "Contact Southland Filtration 1800 656 771 for site specific sizing and process design configuration. Choose to 'hide' this warning when you are happy with the RO system sizing and design",
  },
  PRVS_ARE_FORBIDDEN_HERE: {
    title: "PRVs are forbidden here",
    description: `PRV on the recirculating system cause issues with the ability to calculate and balance the system. <br />

        To resolve this, relocate the PRV on to the cold water system or on to the dead legs that branch off the recirculating system.`,
    helpLink: "",
  },
  MISSING_BALANCING_VALVE_FOR_RETURN: (args: {
    pipeUid: string;
    connectableUid: string;
  }) => ({
    title: "Missing Balancing Valve for Return",
    description: `In the hot water recirculating system, at least one balancing valve is required.<br /> Additionally, every loop/circuit created will also require a balancing valve. 

        To resolve this, choose a 'Balancing Valve' from the 'Valve' drop down menu and add one to each hot water loop/circuit that has the warning.`,
    helpLink: "",
  }),
  MISSING_LOCKSHIELD_VALVE_FOR_RETURN: (args: {
    pipeUid: string;
    connectableUid: string;
  }) => ({
    title: "Missing Lockshield Valve for Return",
    description: `In the mechanical systems (heating/chilled), at least one lockshield valve is required.<br /> Additionally, every loop/circuit created will also require a lockshield valve. 
        To resolve this, choose a 'Lockshield Valve' from the 'Valve' drop down menu and add one to each mechanical systems (heating/chilled) loop/circuit that has the warning.`,
    helpLink: "",
  }),
  NOT_ENOUGH_PRESSURE: (
    args: { systemName: string; requiredKPA: number; entity: string },
    c: CoreContext,
  ) => ({
    title: `Not enough ${args.systemName} pressure. Required: ${dv(
      c,
      Units.KiloPascals,
      args.requiredKPA,
    )}`,
    description: `The minimum inlet pressure of the ${args.entity} has not been reached.<br /> To resolve this, you will need to add a pump to your design. 

        Alternatively, consider changing the minimum pipe size or the maximum velocity in the flow system settings, or overriding the inlet pressure properties of the \${entity}. 
        
        If you are surprised to receive this warning, please review the flow source and floor level heights as they may have been entered incorrectly.`,
    helpLink: "https://youtu.be/mXg5FMYT8eY",
  }),
  TARGET_PRESSURE_IS_LESS_THAN_HALF_OF_THE_PRV_INLET_PRESSURE: (
    args: { pressureKPA: number; ratio: number; targetKPA: number },
    c: CoreContext,
  ) => ({
    title: `Pressure of ${dv(
      c,
      Units.KiloPascals,
      args.pressureKPA,
    )} is more than ${args.ratio}x the target pressure of ${dv(
      c,
      Units.KiloPascals,
      args.targetKPA,
    )}`,
    description: `The inlet pressure at the pressure reduction valve is more than \${ratio}x the specified outlet pressure of the pressure reduction valve which can cause maintenance issues.
        To resolve this, you can install multiple pressure reduction valves in series, ensuring not to reduce the pressure by more than a \${ratio}:1 ratio each time.
        Alternatively, consider reducing the pressure at the pump or overriding the inlet pressure properties of the fixtures/nodes.`,
    helpLink: "",
  }),
  OVERRIDEN_PIPE_DIAMETER_INSUFFICIENT: {
    title: "Overriden pipe diameter insufficient for drainage vent size",
    description: "",
    helpLink: "",
  },
  RHEEM_ADVICE: {
    title: "Contact Rheem for Advice",
    description:
      "The hot water requirement is slightly outside of the product’s capabilities, please contact Rheem for an updated specification.",
    helpLink: "",
  },
  PIPE_NOT_CONNECTED_TO_FLOW_SOURCE: {
    title: "Pipe Not Connected To Flow Source",
    description: `To resolve this, follow the grey pipe towards the flow source until you see a coloured pipe or the flow source
         - this is where the pipe is disconnected from the system.`,
    helpLink: "",
  },
  PIPE_HIGHER_THAN_FLOOR_LEVEL: {
    title: "Pipe Higher Than Floor Level",
    description: `The pipe is higher than the floor level above.<br />

        If this is unintentional, you can resolve this by selecting the pipe and changing the 'Height Above Floor' in the properties.<br />
        
        Alternatively, you can change the floor height of the floor level above by opening the panel on the left-hand side of the screen and modifying the numbers.`,
    helpLink: "",
  },
  NOT_CONNECTED_TO_FLOW_SYSTEM: {
    title: "Not Connected to Flow System",
    description: `The node/appliance is missing a pipe connection.<br />

        To resolve this, draw a pipe to the node/appliance. If you intentionally do not want to connect a pipe, you can hide the warning.`,
    helpLink: "",
  },
  ADD_FLOW_SOURCE_TO_SYSTEM: {
    title: "Add Flow Source to System",
    description: "There is no flow source in drainage",
    helpLink: "",
  },
  ISOLATION_VALVES_REQUIRED_ON_RING_MAIN: {
    title: "2 Isolation Valves Required on Ring Main",
    description: `Due to the complex nature of calculating ring mains where there is a diversified flow rate 
        (there is no industry standard / recognised calculation method) the results through the ring main are not able to be calculated.<br />

        To resolve this, place at least two isolation valves on the ring main. 
        This allows the calculations to be iterated as various dead legs, simulating peak scenarios where the ring main is isolated in different areas.`,
    helpLink: "",
  },
  REMOVE_PLANT_FROM_FLOW_AND_RETURN_PIPEWORK: {
    title: "Remove Plant from Flow and Return Pipework",
    description: `The plant on the return system pipework is causing the results to not calculate.<br />

        To resolve this, remove any plant from the return system pipework and place it on other parts of the system such as the inlet to the plant or the branches off the return system pipework.<br />
        
        Note that the hot water plant is inclusive of the circulating pump, so you do not need to add one.`,
    helpLink: "",
  },
  ARRANGEMENT_OF_PIPEWORK_NOT_SUITABLE_FOR_CALCULATION: {
    title: "Arrangement of Pipework not Suitable for Calculation",
    description: `Due to the complex nature of calculating pipework that is interconnected when there is a diversified flow rate 
        (there is no industry standard / recognised calculation method) the results are not able to be calculated.<br />

        To resolve this on a cold water system, place isolation valves on each pipe that interconnects the different loops and 
        in the properties of the valve, you will need to mark some of them as 'closed'. This allows the calculations 
        to be iterated as various dead legs, simulating peak scenarios where the system is isolated in different areas.<br />
        
        To resolve this on a hot water circulating system, you will need to simplify the layout so that the loops/circuits branch off 
        from each other, and join together in the same order. `,
    helpLink: "",
  },
  RETURN_PIPE_NOT_CONNECTED_TO_OUTLET_OR_RETURN: {
    title: "Return Pipe Not Connected To Outlet or Return",
    description: `Either flow from the outlet cannot reach this pipe or flow from this pipe cannot reach 
        the return.<br />`,
    helpLink: "",
  },
  RETURN_PIPE_FLOW_DIRECTION_IS_REVERSABLE: {
    title: "Return Pipe Flow Direction Is Ambiguous",
    description: `Pipes inside a return network must have a single fow direction. This can be achieved with check valves, or by arranging the pipes in a series-parallel layout.<br />
        
        To resolve this, you can either:<br />
        - Double check the direction of radiators or other directed entities like check valves on the return loop.<br />
        - Add a check valve to the return system in places where backflow is possible.<br />
        - Reorganize the pipes into a series-parallel layout (not possible in a reverse-return system).<br />
        `,

    helpLink: "",
  },
  RETURN_PREHEAT_LOOP: {
    title: "Return Preheat Loop Detected",
    description: `A preheat loop has been detected. A plant is inadvertently supplying flow to another plant's preheat system, which then circulates back to the original plant. To resolve this issue, it is recommended to identify and break any unintended links in the preheat system.<br />`,
  },
  TOO_MANY_DIVERTER_VALVES: {
    title: "Too Many Diverter Valves",
    description: `Reverse return systems (and non-series-parallel return systems) can only have one diverter valve.<br />`,
  },
  PUMP_CONFIG_TOO_LOW: (
    args: {
      dutyKPA: number;
      flowRateLS: number;
      suggestedConfig: PumpConfiguration;
      plantUid: string;
    },
    c: CoreContext,
  ) => ({
    title: "Pump Configuration does not provide enough pressure",
    description: `There are no suitable pump models in h2x for the given pump duty (${dv(
      c,
      Units.KiloPascals,
      args.dutyKPA,
    )}) and flow rate (${dv(c, Units.LitersPerSecond, args.flowRateLS)}).<br />
                    Please consider changing the pump configuration to ${
                      args.suggestedConfig
                    }`,
  }),
  NO_PUMP_CAN_SUPPLY: (
    args: { dutyKPA: number; flowRateLS: number },
    c: CoreContext,
  ) => ({
    title: "Pump cannot supply pressure",
    description: `There are no suitable pump models in h2x for the given pump duty (${dv(
      c,
      Units.KiloPascals,
      args.dutyKPA,
    )}) and flow rate (${dv(c, Units.LitersPerSecond, args.flowRateLS)}).<br />
                    Please consider changing the pump brand`,
  }),
  NON_STANDARD_PUMP_SELECTION: (
    args: { dutyKPA: number; flowRateLS: number; contact: string },
    c: CoreContext,
  ) => ({
    title: `Non Suitable Pump Model ${args.contact}`,
    description: `There are no suitable pump models for the given pump duty (${dv(
      c,
      Units.KiloPascals,
      args.dutyKPA,
    )}) and flow rate (${dv(
      c,
      Units.LitersPerSecond,
      args.flowRateLS,
    )})).<br />`,
  }),
  PUMP_DOWNSTREAM_AMBIGUOUS: (
    args: { blockingUids: string[] },
    c: CoreContext,
  ) => ({
    title: "Downstream Pressure Is Ambiguous - Duty May Be Inaccurate",
    description: `Pipes downstream are in an invalid configuration and therefore pump duty may not be accurate.<br />`,
  }),
  PUMP_UPSTREAM_AMBIGUOUS: (
    args: { blockingUids: string[] },
    c: CoreContext,
  ) => ({
    title: "Upstream Pressure Is Ambiguous - Duty May Be Inaccurate",
    description: `Pipes upstream are in an invalid configuration and therefore pump duty may not be accurate.<br />`,
  }),
  CAPACITY_EXCEEDS_MAX_AVAILABLE: (
    args: { currentL: number; maxL: number; type: string },
    c: CoreContext,
  ) => ({
    title: `Capacity ${dv(c, Units.Liters, args.currentL)} Exceeds Max of ${dv(
      c,
      Units.Liters,
      args.maxL,
    )} for${args.type}`,
    description: `The capacity exceeds the maximum available for ${args.type} in components that h2x list for this manufacturer. However, the manufacturer may have other configurations. Please contact the manufacturer for assistance. Otherwise, enter a smaller capacity, or choose a lower 'Peak Flow Rate Storage Minutes'.`,
  }),
  PIPE_DIAMETER_EXCEEDS_VALVE_MAX_DIAMETER: (
    args: { diameterMM: number; maxMM: number; type: string },
    c: CoreContext,
  ) => ({
    title: `Pipe Diameter ${dv(
      c,
      Units.Millimeters,
      args.diameterMM,
    )} Exceeds Max Diameter of ${dv(c, Units.Millimeters, args.maxMM)} for ${
      args.type
    } Valve`,
    description: `The connected pipe diameter exceeds the maximum diameter for \${type} valve. Please change to other type of valve or reduce the diameter of the pipe connected to the valve.<br />`,
  }),
  PREVENTING_PUMP_FROM_REACHING_TARGET: {
    title: "Preventing Upstream Pump from Reaching Target",
    description: `The entity is preventing an upstream pump from serving the most disadvantaged load downstream.<br />`,
  },
  PUMP_PREVENTED_FROM_REACHING_TARGET: (args: { blockerUid: string }) => ({
    title: "Prevented from Reaching Disadvantaged Target",
    description: `The pump is prevented from serving the most disadvantaged load downstream by a pressure reducing component along the way.<br />`,
  }),
  INVALID_CAP_END: {
    title: "Invalid Cap End",
    description: `The dangling cap end is not connected to a pipe or inlet/outlet.<br />`,
  },
  FIXTURE_DOWNSTREAM_OF_BALANCING_VALVE: {
    title: "Fixture Cannot be Downstream of Balancing Valves",
    description: `Fixtures cannot be downstream of balancing valves. Please move the fixture to upstream of balancing valves in your design.<br />`,
  },
  NODE_DOWNSTREAM_OF_BALANCING_VALVE: {
    title: "Node Cannot be Downstream of Balancing Valves",
    description: `Node cannot be downstream of balancing valves. Please move the node to upstream of balancing valves in your design.<br />`,
  },
  HEAT_EMITTER_CONNECTED_WRONG_WAY: {
    title: "Heat Emitter is Connected in Wrong Direction",
    description:
      "Heat Emitter is connected in the wrong direction. Please swap the inlet and outlet connections.",
  },
  RETURN_NETWORK_MISCONFIGURED: {
    title: "Plant Return System Misconfigured",
    description:
      "Plant's return network is misconfigured. This could be due to missing valves or heat emitters in the wrong direction.",
  },
  CIBSE_DIVERSIFIED_FLOW_CAN_NOT_BE_CALCULATED: {
    title: "CIBSE Diversified Flow Can Not Be Calculated",
    description: `Due to the complex nature of calculating pipwork that is interconnected when there is a diversified flow rate 
        (there is no industry standard / recognised calculation method) the results are not able to be calculated. CIBSE Diversified Flow can not be calculated.`,
  },
  MULTIPLE_CONNECTED_FLOW_SOURCES: {
    title: "Multiple Connected Flow Sources",
    description: `Multiple flow sources are connected. <br />`,
  },
  UNBALANCED_GRILLS: (args: { grills: LoadNodeEntity[] }) => ({
    title: "Grill needs a damper",
    description: `The pressure at grill is too high, it can be fixed by placing a damper. <br />`,
    helpLink: "",
  }),
  FITTING_NOT_RECOGNIZED: (args: { reasons: string[] }) => ({
    title: "Fitting Not Recognized",
    description: `This fitting's form could not be transformed into a physical fitting. ${
      args.reasons.length ? "Reason(s): " + args.reasons.join(", ") : ""
    }`,
  }),
  BEND_TOO_TIGHT: {
    title: "Bend Too Tight",
    description: `The bend is too close together to fit. Move the fittings further apart.`,
  },
  RADIATOR_MODEL_NOT_FOUND: {
    title: "Radiator Model Not Found",
    description: `The radiator model is not found based on shape, range and dimension given.`,
  },
  RADIATOR_MODEL_RATING_INSUFFICIENT: {
    title: "Radiator Model Rating Insufficient",
    description: `A radiator model is found, however it does not compensate the rating.`,
  },
  DUCT_SIZE_NOT_FOUND: {
    title: "No Duct Size is Available for your flow rate",
    description: `We could not find a suitable duct size for your required flow rate. Please change the sizing method to Unrestricted in Flow System settings or change the flow rate.`,
  },
  UFH_INSUFFICIENT: {
    title: "Underfloor Heating Insufficient",
    description: `The underfloor heating system is insufficient for the required heat output. Please increase the water temperature or use a different underfloor heating manufacturer.`,
  },
  UFH_NO_SOLUTION: {
    title: "Cannot find suitable solution for Underfloor Heating",
    description:
      "Please make sure your mean water temperature and room temperature are within the bounds of the manufacturer's specifications.",
  },
  UFH_NO_LOOP_SOLUTION: {
    title: "Cannot find suitable solution for Underfloor Heating Loop",
    description:
      "Please make sure that there is a path through doors/loop entrances from the manifold to the room",
  },
  UFH_NO_LOOP_SPACING: {
    title: "Missing Loop Spacing",
    description: "Please make sure that loop spacing is correctly configured",
  },
  UFH_FLOOR_TEMP_TOO_HIGH: {
    title: "Underfloor Heating Floor Temperature Too High",
    description:
      "The calculated floor temperature is more than 9 degrees higher than the room temperature. To resolve this, you can lower the water temperature, increase the floor TOG, or set wider pipe spacings.",
  },
  UFH_MANIFOLD_MODEL_NOT_FOUND: {
    title: "No suitable model found for manifold",
    description:
      "No manifold model found for the number of loop outlets required for your design",
  },
  HEAT_PUMP_SYSTEM_VOLUME_NOT_MET: {
    title: "Heat Pump System Volume Is Not Met",
    description:
      "Add a volumiser and  enter the required volume in the properties or increase pipe sizes/emitters",
  },
  HEAT_PUMP_FLOW_EXCEEDED: {
    title: "Heat Pump Flow Exceeded",
    description: "Use a larger heat pump",
  },
  HEAT_PUMP_PRESSURE_EXCEEDED: {
    title: "Heat Pump Pressure Exceeded",
    description: `
    There are several options to resolve this: <br />
    1. Use a larger heat pump <br/>
    2. Reduce the pressure drop per metre in the settings if it is close to the limit <br/>
    3. Add hydraulic separation to the system`,
  },
  MANIFOLD_PUMP_PRESSURE_TOO_HIGH: (
    args: {
      dutyKPA: number;
      maxKPA: number;
      flowRateLS: number;
    },
    c: CoreContext,
  ) => ({
    title: "Manifold Pump Duty Too High",
    description: `The pump duty pressure is too high for the flow rate. The maximum pressure is ${dv(
      c,
      Units.KiloPascals,
      args.maxKPA,
    )}. The current pressure is ${dv(c, Units.KiloPascals, args.dutyKPA)} for a flow rate of ${dv(
      c,
      Units.LitersPerSecond,
      args.flowRateLS,
    )}.`,
  }),
  MANIFOLD_PUMP_FLOW_RATE_TOO_HIGH: (
    args: {
      flowRateLS: number;
      maxFlowRateLS: number;
    },
    c: CoreContext,
  ) => ({
    title: "Manifold Pump Flow Too High",
    description: `The pump flow rate is too high. The maximum flow rate is ${dv(
      c,
      Units.LitersPerSecond,
      args.maxFlowRateLS,
    )}. The current flow rate is ${dv(c, Units.LitersPerSecond, args.flowRateLS)}.`,
  }),
  MANIFOLD_VALVE_HEAT_OUTPUT_TOO_HIGH: (
    args: {
      heatOutputKW: number;
      maxHeatOutputKW: number;
    },
    c: CoreContext,
  ) => ({
    title: "Manifold Heat Output Too High",
    description: `The valve heat output is too high for the blending valve. The maximum heat output is ${dv(
      c,
      Units.KiloWatts,
      args.maxHeatOutputKW,
    )}. The current heat output is ${dv(c, Units.Watts, args.heatOutputKW)}.`,
  }),
  MANIFOLD_VALVE_FLOW_TEMP_TOO_HIGH: (
    args: {
      flowTempC: number;
      maxFlowTempC: number;
    },
    c: CoreContext,
  ) => ({
    title: "Manifold Flow Temperature Too High",
    description: `The valve flow temperature is too high for the blending valve. The maximum flow temperature is ${dv(
      c,
      Units.Celsius,
      args.maxFlowTempC,
    )}. The current flow temperature is ${dv(
      c,
      Units.Celsius,
      args.flowTempC,
    )}.`,
  }),
  MANIFOLD_VALVE_FLOW_TEMP_TOO_LOW: (
    args: {
      flowTempC: number;
      minFlowTempC: number;
    },
    c: CoreContext,
  ) => ({
    title: "Manifold Flow Temperature Too Low",
    description: `The valve flow temperature is too low for the blending valve. The minimum flow temperature is ${dv(
      c,
      Units.Celsius,
      args.minFlowTempC,
    )}. The current flow temperature is ${dv(
      c,
      Units.Celsius,
      args.flowTempC,
    )}.`,
  }),
} satisfies Record<
  string,
  ((...args: any[]) => WarningDescription) | WarningDescription
>;

export type Warnings = keyof typeof WarningDefinitions;

export type WarningDefinitions = typeof WarningDefinitions;

export function renderWarning(
  context: CoreContext,
  warning: WarningDetail,
): WarningDescription {
  const warningInstance = context.globalStore.calculationWarnings.get(
    warning.instanceId,
  );
  if (!warningInstance) {
    throw new SentryError(
      "Malformed warning detail: No warning instance found",
    );
  }
  const record = WarningDefinitions[warning.type];
  if (typeof record === "function") {
    if (warningInstance.params)
      return record(warningInstance.params as any, context);
    throw new SentryError(
      "Malformed warning detail: Functional warning has no arguments",
    );
  } else {
    return record;
  }
}
