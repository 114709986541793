import { ConsoleLogger } from "./console-logger";
import { NullablePrimitive } from "./primitive";

export class ILogger {
  log: (message?: any, ...optionalParams: any[]) => void;
  warn: (message?: any, ...optionalParams: any[]) => void;
  debug: (message?: any, ...optionalParams: any[]) => void;
  error: (
    error: unknown,
    tags: Record<string, NullablePrimitive>,
    extras: Record<string, any>,
    cause?: unknown,
  ) => void;
}

export class Logger {
  static LOGGERS: ILogger[] = [new ConsoleLogger()];

  static addLogger(logger: ILogger) {
    Logger.LOGGERS.push(logger);
  }

  static log(message?: any, ...optionalParams: any[]) {
    for (let logger of Logger.LOGGERS) {
      logger.log(message, optionalParams);
    }
  }

  static debug(message?: any, ...optionalParams: any[]) {
    for (let logger of Logger.LOGGERS) {
      logger.debug(message, optionalParams);
    }
  }

  static warn(message?: any, ...optionalParams: any[]) {
    for (let logger of Logger.LOGGERS) {
      logger.warn(message, optionalParams);
    }
  }

  /**
   * This is the core replacement for console.error.
   *
   * It captures a stacktrace, adds extra context, and logs the error to Sentry..
   *
   * - Rate Limited
   * - Supports Tags
   * - Supports Extra Context
   *
   * Important! Sentry Log messages must be statically known strings,
   * try not to interpolate variables like uids. as it will not merge those issues together easily
   *
   * @param error The Title of the error in sentry or an error object
   * @param tags Sentry tags, these are meant to be short, and are indexed, and searchable in sentry,
   *     that we may want so see what percentage are caused by x. Think project id, entity type etc.
   * @param extras Sentry extras, these are more of the nitty gritty details about the error, that are more nuanced.
   * @param cause If you need to nest errors, use this.
   */
  static error(
    error: unknown,
    tags: Record<string, NullablePrimitive> = {},
    extras: Record<string, any> = {},
    cause?: unknown,
  ) {
    for (let logger of Logger.LOGGERS) {
      logger.error(error, tags, extras, cause);
    }
  }
}
