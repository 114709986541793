import { APIResult } from "../../../common/src/api/document/types";
import {
  ActiveUsersOnDateResult,
  DailyActiveUsersResult,
} from "../../../common/src/api/users/activeUsers";
import {
  AccessLevel,
  AdminsCreate,
  User,
  UserSummary,
  UserWithMFA,
} from "../../../common/src/models/User";
import { UserBlock } from "../../../common/src/models/UserBlocks";
import { ApiClient } from "./ApiClient";

const usersApi = new ApiClient("/api/users");
const cognitoApi = new ApiClient("/api/cognito");

export async function getUsers(): Promise<APIResult<User[]>> {
  return usersApi.get();
}

export async function getUserSummaries(): Promise<APIResult<UserSummary[]>> {
  return usersApi.get("summaries");
}

export async function getUser(
  username: string,
): Promise<APIResult<UserWithMFA>> {
  return usersApi.get(encodeURIComponent(username));
}

export async function getUserEmailsByOrganization(
  organizationId: string,
): Promise<APIResult<string[]>> {
  return usersApi.post("user-emails-by-organization", { organizationId });
}

export async function createUser(data: AdminsCreate): Promise<APIResult<User>> {
  return usersApi.post("", data);
}

/**
 * Managers only
 */
export async function updateUserSummary(
  username: string,
  updates: Partial<UserSummary>,
): Promise<APIResult<UserSummary>> {
  return usersApi.post(
    encodeURIComponent(username) + "/manager-update",
    updates,
  );
}

export async function updateUser(
  username: string,
  name: string,
  email: string | undefined,
  subscribed: boolean,
  accessLevel: AccessLevel,
  activated: boolean,
  organization?: string,
): Promise<APIResult<User>> {
  const data = {
    name,
    accessLevel,
    email,
    organization,
    subscribed,
    activated,
  };
  return usersApi.put(encodeURIComponent(username), data);
}

export async function dailyActiveUsers(
  from: Date,
  to: Date,
): Promise<APIResult<DailyActiveUsersResult>> {
  return usersApi.post("daily-active-users", { from, to });
}

export async function activeUsersOnDate(
  date: Date,
): Promise<APIResult<ActiveUsersOnDateResult>> {
  return usersApi.post("daily-active-users-on-date", { date });
}

export async function blockUser(
  username: string,
  reason: string,
): Promise<APIResult<{ user: User; block: UserBlock }>> {
  return usersApi.post(encodeURIComponent(username) + "/block", {
    username,
    reason,
  });
}

export async function unblockUser(
  username: string,
  reason: string,
): Promise<APIResult<User>> {
  return usersApi.post(encodeURIComponent(username) + "/unblock", { reason });
}

export async function getUserBlocks(
  username: string,
): Promise<APIResult<Array<UserBlock>>> {
  return usersApi.get(encodeURIComponent(username) + "/blocks");
}

export async function adminResetUserPassword(
  username: string,
): Promise<APIResult<string>> {
  return cognitoApi.post("reset-password", { username });
}

export async function adminDisableMFA(
  username: string,
): Promise<APIResult<string>> {
  return cognitoApi.post("disable-mfa" + encodeURIComponent(username));
}

export async function listRemoteUsers(props: {
  remoteBase: string;
  remoteUsername: string;
  remotePassword: string;
  orgId: string;
}): Promise<APIResult<User[]>> {
  return usersApi.post("remote/list", props);
}

export async function importRemoteUsers(props: {
  remoteBase: string;
  remoteUsername: string;
  remotePassword: string;
  usernames: string[];
  mapToOrgId: string;
}): Promise<APIResult<string>> {
  return usersApi.post("remote/import", props);
}
