<template>
  <b-container data-testid="login-form">
    <div class="initial-container">
      <div>
        <div class="logo-container">
          <img
            src="@/assets/h2x.png"
            class="d-inline-block align-top"
            height="70"
          />
        </div>

        <!-- Login Screen -->
        <b-card
          v-if="state === 'singIn'"
          class="login-card"
          body-class="login-body"
        >
          <div class="login-header">
            <h3 style="margin-bottom: 10px; font-weight: bold">
              Sign In to h2x
            </h3>
            <span style="color: #606e79">
              Mechanical & Plumbing Design Software</span
            >
          </div>

          <b-form style="text-align: left" @submit.prevent="login">
            <b-form-group label="Email or Username" class="login-group">
              <b-form-input
                ref="emailInput"
                v-model="emailRaw"
                :disabled="isLoading"
                type="text"
                name="email"
                placeholder="Enter here"
                class="login-inputs"
              />
            </b-form-group>

            <b-form-group
              label="Password"
              class="login-group"
              :class="strategy !== 'local' ? 'hide' : ''"
            >
              <b-form-input
                ref="passwordInput"
                v-model="password"
                :disabled="isLoading"
                type="password"
                name="password"
                class="login-inputs"
              />
            </b-form-group>

            <div style="margin-top: 20px">
              <HeroButton type="submit">
                <b-spinner v-if="isLoading" style="width: 1rem; height: 1rem" />
                <span v-else>Next</span>
              </HeroButton>
            </div>

            <br />

            <span class="login-links password-link" @click="goToResetPass">
              Forgot Password?
            </span>

            <span class="login-links" @click="goToSignUp">
              Don't have an account yet?
            </span>
          </b-form>
        </b-card>

        <!-- New Password Required for Login -->
        <b-card
          v-if="state === 'challenge'"
          class="login-card"
          body-class="login-body"
        >
          <div class="login-header">
            <b-alert variant="info" show>
              {{ challengeInfo }}
            </b-alert>
          </div>

          <b-form
            style="text-align: left"
            @submit.prevent="handleConfirmSignIn"
          >
            <b-form-group :label="challengeFieldName" class="login-group">
              <b-form-input
                v-model="challengeRes"
                :disabled="isLoading"
                :type="challengeFieldType"
                :name="challengeFieldType"
                class="login-inputs"
              />
            </b-form-group>

            <HeroButton type="submit">
              <b-spinner v-if="isLoading" style="width: 1rem; height: 1rem" />
              <span v-else>Submit</span>
            </HeroButton>
          </b-form>
        </b-card>
      </div>
    </div>
  </b-container>
</template>

<script lang="ts">
import {
  SignInOutput,
  autoSignIn,
  confirmSignIn,
  fetchAuthSession,
  signIn,
  signInWithRedirect,
} from "aws-amplify/auth";
import { BFormInput } from "bootstrap-vue";
import { PropType, defineComponent } from "vue";
import { assertUnreachable } from "../../../common/src/lib/utils";
import { validateEmail } from "../../../common/src/lib/validation/validators";
import { getLoginStrategy } from "../../src/api/logins";
import { CLIENT_METADATA } from "../auth/amplify.config";
import HeroButton from "../components/molecules/HeroButton.vue";

export default defineComponent({
  components: { HeroButton },
  props: {
    next: {
      required: false,
      default: undefined,
      type: Object as PropType<string | undefined>,
    },
  },
  data() {
    const challengeFieldType = "password" as "password" | "text";
    const verificationCode: string = "";
    const challengeInfo: string = "";
    const challengeRes: string = "";
    const strategy: string = "";
    const state = "singIn" as "singIn" | "challenge";
    const isLoading: boolean = false;
    const password: string = "";
    const emailRaw: string = "";

    return {
      emailRaw,
      password,
      isLoading,
      state,
      strategy,
      challengeRes,
      challengeInfo,
      verificationCode,
      challengeFieldName: "",
      challengeFieldType,
    };
  },
  computed: {
    email() {
      return this.emailRaw.trim();
    },
  },
  watch: {
    strategy: [
      {
        handler: "onStrategyChange",
      },
    ],
  },
  async mounted() {
    setTimeout(() => {
      document.getElementById("email-input")?.focus();
    }, 500);
    await this.checkExistingAuth();
    try {
      const res = await autoSignIn();
      this.handleSignInResponse(res);
    } catch (e) {
      // don't really care if it fails (runs after successful sing up)
    }
  },
  methods: {
    async checkExistingAuth() {
      try {
        const a = await fetchAuthSession();
        if (a.tokens?.idToken) {
          this.$router.push("/");
        }
      } catch (e) {
        //noop
      }
    },
    async loginStrategy() {
      const res = await getLoginStrategy(this.email);
      if (res.success) {
        return res.data;
      }
      return "local";
    },
    async handleConfirmSignIn() {
      try {
        this.isLoading = true;
        const res = await confirmSignIn({
          challengeResponse: this.challengeRes,
        });
        this.handleSignInResponse(res);
      } catch (e: any) {
        this.handleError(e);
      } finally {
        this.isLoading = false;
      }
    },
    handleSignInResponse(res: SignInOutput) {
      const { isSignedIn, nextStep } = res;

      if (isSignedIn) {
        return this.$router.push(this.next ?? "/");
      }

      switch (nextStep.signInStep) {
        case "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED":
          this.state = "challenge";
          this.challengeInfo = "You are required to create a new password.";
          this.challengeFieldName = "New Password";
          this.challengeFieldType = "password";
          break;
        case "RESET_PASSWORD":
          this.$router.push({
            name: "resetPassword",
            query: { username: this.email },
          });
          break;
        case "CONFIRM_SIGN_IN_WITH_SMS_CODE":
          this.state = "challenge";
          const dest = nextStep.codeDeliveryDetails?.destination;
          this.challengeInfo = `A verification code has been sent to ${dest}`;
          this.challengeFieldName = "Verification Code";
          this.challengeFieldType = "text";
          break;
        case "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE":
        // N/A
        case "CONFIRM_SIGN_IN_WITH_TOTP_CODE":
        // N/A
        case "CONTINUE_SIGN_IN_WITH_TOTP_SETUP":
        // N/A
        case "CONTINUE_SIGN_IN_WITH_MFA_SELECTION":
        // N/A

        case "CONFIRM_SIGN_UP":
        // N/A
        case "DONE":
          // Done!
          break;
        default:
          assertUnreachable(nextStep);
      }
    },
    handleError(e: Error) {
      this.$bvToast.toast(e.message, {
        variant: "warning",
        toaster: " b-toaster-top-center",
        title: "Something is not right",
        noAutoHide: true,
      });
    },
    getValidUsername() {
      const { success: isValidEmail } = validateEmail(this.email);
      return isValidEmail ? this.email.toLowerCase() : this.email;
    },
    async localLogin() {
      try {
        this.isLoading = true;
        const username = this.getValidUsername();
        const res = await signIn({
          username,
          password: this.password,
          options: {
            clientMetadata: CLIENT_METADATA,
          },
        });
        this.handleSignInResponse(res);
      } catch (e: any) {
        this.handleError(e);
      } finally {
        this.isLoading = false;
      }
    },
    async login() {
      if (this.strategy === "local") {
        return this.localLogin();
      }

      this.isLoading = true;
      this.strategy = await this.loginStrategy();

      if (this.strategy !== "local") {
        signInWithRedirect({
          // use diff syntax for social AuthProviders
          provider: { custom: this.strategy },
        });
      }

      this.isLoading = false;
    },
    goToSignUp() {
      this.$router.push({ name: "signUp" });
    },
    goToResetPass() {
      this.$router.push({ name: "resetPassword" });
    },
    onStrategyChange() {
      if (this.strategy === "local") {
        this.$nextTick(() => {
          if (this.$refs.passwordInput instanceof BFormInput) {
            this.$refs.passwordInput.focus();
          }
        });
      }
    },
  },
});
</script>

<style>
.login-header {
  margin-bottom: 30px;
}

.login-card {
  border: none;
  margin-top: 20px;
  width: 460px;
  padding: 35px 40px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 6px;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
}

.login-body {
  padding: 5px;
}

@media (max-width: 770px) {
  .login-card {
    padding: 0;
    width: 350px;
  }

  .login-body {
    padding: 30px;
  }
}

.login-left-side {
  background-color: white;
  text-align: right;
  z-index: -1;
  padding-right: 50px;
}

.login-links {
  margin: 10px;
  color: #606e79;
  padding-top: 4px;
  display: block;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
}

.login-inputs {
  padding: 25px 20px;
  font-size: 14px;
}

.login-inputs:focus {
  border: 1px solid #006fbe;
  box-shadow: none;
  border-radius: 4px;
  color: #374047;
}

.login-inputs::placeholder {
  color: #adb4b9;
}

.login-inputs:-webkit-autofill,
.login-inputs:-webkit-autofill:hover,
.login-inputs:-webkit-autofill:focus,
.login-inputs:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: #333333 !important;
}

.initial-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.hide {
  display: none;
}

.forgot-password-help-container {
  position: absolute;
  top: calc(50% + 220px);
  left: 0;
  width: 100%;
}

.forgot-password-help-text {
  max-width: 28rem;
  display: inline-block;
  line-height: 100%;
}

.login-group {
  color: #374047;
  font-size: 14px;
}

.password-link {
  text-decoration: underline;
}
</style>
