import Flatten from "@flatten-js/core";
import { v4 } from "uuid";
import { Logger } from "../../../../lib/logger";
import {
  isParallelRad,
  isPointOnRay,
} from "../../../../lib/mathUtils/mathutils";
import { SentryEntityError } from "../../../../lib/sentry-entity-error";
import { VISIABLE_EPS, cloneSimple } from "../../../../lib/utils";
import CoreFen from "../../../coreObjects/coreFenestration";
import { RoomCalcDebug } from "../../../document/calculations-objects/room-calculation";
import { EntityType } from "../../../document/entities/types";
import CalculationEngine from "../../calculation-engine";
import Graph, { Edge } from "../../graph";
import { RoomGraph } from "./room-graph";
import {
  GraphEdge,
  GraphNode,
  GraphNodeEntryData,
  serializeNode,
} from "./utils";

export interface LevelGraphNode {
  point: Flatten.Point;
  uid: string;
  entryNormal?: Flatten.Vector;
  roomUid: "manifold" | "manifold-fen" | string;
}

export interface LevelGraphEdge extends GraphEdge {
  roomUid: string;
  isInverted?: boolean;
}

export class LevelGraph extends Graph<LevelGraphNode, LevelGraphEdge> {
  node2entry = new Map<string, GraphNodeEntryData[]>();
  constructor(private context: CalculationEngine) {
    super(
      serializeNode,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      (e) => ({
        align: e.align,
        normal: Flatten.vector(e.normal.x, e.normal.y),
        roomUid: e.roomUid,
        siteUid: e.siteUid,
      }),
    );
  }

  addEntryToNode(node: GraphNode, entry: GraphNodeEntryData) {
    if (!this.node2entry.has(node.uid)) {
      this.node2entry.set(node.uid, []);
    }
    this.node2entry.get(node.uid)!.push(entry);
  }

  addEdge(
    a: LevelGraphNode,
    b: LevelGraphNode,
    edgeValue: LevelGraphEdge,
    uid?: string | undefined,
  ) {
    if (a.point.distanceTo(b.point)[0] < VISIABLE_EPS) {
      console.warn("Adding 0 length target edge", {
        x1: a.point.x,
        x2: b.point.x,
        edgeValue,
      });
    }
    return super.addEdge(a, b, edgeValue, uid);
  }

  addRoom(roomGraph: RoomGraph, roomUid: string) {
    for (const node of roomGraph.id2Node.values()) {
      this.addNode({
        point: node.point,
        uid: node.uid,
        roomUid: roomUid,
      });
    }

    for (const edge of roomGraph.edgeList.values()) {
      const from = this.id2Node.get(roomGraph.sn(edge.from));
      const to = this.id2Node.get(roomGraph.sn(edge.to));
      this.addEdge(from!, to!, {
        normal: edge.value.normal,
        roomUid: roomUid,
        siteUid: edge.value.siteUid,
        align: edge.value.align,
      });
    }

    for (const [nodeUid, entries] of roomGraph.node2entry) {
      for (const entry of entries) {
        if (!this.node2entry.has(nodeUid)) {
          this.node2entry.set(nodeUid, []);
        }
        this.node2entry.get(nodeUid)!.push(entry);
      }
    }
  }

  addOpening(obj: CoreFen, roomStartUids: string[]) {
    let entryNormal: Flatten.Vector | null = null;
    let entryCenter: Flatten.Point | null = null;
    let normalReferenceSiteUid: string | null = null;
    if (!obj.entity.polygonEdgeUid) {
      entryNormal = Flatten.vector(0, 1); // what
    } else {
      const edgeUid = obj.entity.polygonEdgeUid[0];
      normalReferenceSiteUid =
        this.context.globalStore.getPolygonsByEdge(edgeUid)[0];
      const edge = this.context.globalStore.getObjectOfTypeOrThrow(
        EntityType.EDGE,
        edgeUid,
      );
      entryNormal = edge.normal ?? Flatten.vector(0, 1);
    }
    const endpoints = obj.getWorldSegments()[0];
    entryCenter = Flatten.point(
      (endpoints[0].x + endpoints[1].x) / 2,
      (endpoints[0].y + endpoints[1].y) / 2,
    );

    const matchesByRoom = new Map<
      string,
      Edge<LevelGraphNode, LevelGraphEdge>[]
    >();

    for (const edge of this.edgeList.values()) {
      const edgeSegment = Flatten.segment(edge.from.point, edge.to.point);

      if (
        entryCenter.distanceTo(edgeSegment)[0] < 200 &&
        isParallelRad(edge.value.normal.angleTo(entryNormal), 0, Math.PI / 6) &&
        edgeSegment.length > 200
      ) {
        if (!matchesByRoom.has(edge.value.roomUid)) {
          matchesByRoom.set(edge.value.roomUid, []);
        }
        matchesByRoom.get(edge.value.roomUid)!.push(edge);
      }
    }

    const bestRoomMatches: Array<{
      roomUid: string;
      dist: number;
      edge: Edge<LevelGraphNode, LevelGraphEdge>;
    }> = [];
    for (const [roomUid, edges] of matchesByRoom) {
      let bestDistance = Infinity;
      let bestEdge: Edge<LevelGraphNode, LevelGraphEdge> | null = null;
      for (const edge of edges) {
        const edgeSegment = Flatten.segment(edge.from.point, edge.to.point);
        const distance = entryCenter.distanceTo(edgeSegment)[0];
        if (distance < bestDistance) {
          bestDistance = distance;
          bestEdge = edge;
        }
      }
      if (bestEdge) {
        bestRoomMatches.push({ roomUid, dist: bestDistance, edge: bestEdge });
      }
    }

    if (matchesByRoom.size > 2) {
      // This can happen when a room is drawn inside another room. Which is an error
      // No-op now.
      console.warn(
        "More than 2 matching edges found for fenestration",
        obj.uid,
        { matchesByRoom, bestRoomMatches },
      );
    }

    if (bestRoomMatches.length === 0) {
      Logger.error(
        new SentryEntityError(
          "No matching edges found for fenestration",
          obj.uid,
        ),
      );
      return;
    } else {
      let nodesToConnect: LevelGraphNode[] = [];
      let closestDist = Infinity;
      let closestSide: Flatten.Point | null = null;
      let closestVector: Flatten.Vector | null = null as any;

      for (const { roomUid, edge } of bestRoomMatches.splice(0, 2)) {
        // Split edge by the entry center.
        const segment = Flatten.segment(edge.from.point, edge.to.point);
        const distance = entryCenter.distanceTo(segment);
        const newPoint = distance[1].pe;
        const newNode = {
          point: newPoint,
          uid: v4(),
          entryNormal: edge.value.normal,
          roomUid: edge.value.roomUid,
        };
        nodesToConnect.push(newNode);

        const checkPointForClosest = (point: Flatten.Point) => {
          if (point.distanceTo(newPoint)[0] < closestDist) {
            closestDist = point.distanceTo(entryCenter)[0];
            closestSide = point;
            closestVector = Flatten.vector(newPoint, point);
          }
        };

        this.splitUndirectedEdge(edge, newNode);

        checkPointForClosest(edge.from.point);
        checkPointForClosest(edge.to.point);

        if (roomStartUids.includes(edge.value.roomUid)) {
          const normal =
            edge.value.roomUid === normalReferenceSiteUid
              ? entryNormal
              : entryNormal.multiply(-1);

          // It is possible for a door to not be an entry to its rooms or heated areas if the
          // room has a larger partitioned area that's not at the door, and there's no
          // heated area at the door. (This is an undesireable case in the first place, but
          // we just gotta handle it until multi-heated zone splits for a room is supported.
          if (edge.value.siteUid) {
            this.addEntryToNode(newNode, {
              type: "fen-entry",
              entrySiteUid: edge.value.siteUid,
              numEntries: 1,
              normal,
            });
          }
        }
      }

      if (nodesToConnect.length === 2) {
        let p1 = nodesToConnect[0];
        let p2 = nodesToConnect[1];
        const p1IsRoom = p1.roomUid === normalReferenceSiteUid;
        if (p1.point.distanceTo(p2.point)[0] < VISIABLE_EPS) {
          // move one of the points slightly to give it a direcion
          // It is possible to supply a vector and pass this down but this is easier
          const newP2 = {
            point: p2.point.translate(
              entryNormal
                .normalize()
                .multiply(1e-3)
                .multiply(p1IsRoom ? -1 : 1),
            ),
            uid: p2.uid,
            roomUid: p2.roomUid,
          };
          this.displaceVertex(p2, newP2);
          p2.point = newP2.point;
        }

        let align: "center" | "ccw" | "cw" | "ccw-thru" | "cw-thru" = "center";

        const openingNormal = entryNormal.rotate90CCW().normalize();

        // If the door is close to a side wall, align it to the side wall
        if (
          closestDist < (obj.getFenLengthMM() / 2) * 1.2 &&
          closestVector &&
          closestSide
        ) {
          const isCW = entryNormal.cross(closestVector) > 0;
          if (isCW) {
            align = "cw-thru";
          } else {
            align = "ccw-thru";
          }

          for (const node of nodesToConnect) {
            const adjustedNode = cloneSimple(node);
            // We want to snap the door's transit lines to the wall.
            // Going 100% of the way results in a zero length vector somewhere.
            // Full solution is to merge nodes appropriately, but let's just
            // do this nasty hack (0.99) for now.
            adjustedNode.point = node.point.translate(
              closestVector.multiply(1 - 0.1 / closestVector.length),
            );
            this.displaceVertex(node, adjustedNode);
            node.point = adjustedNode.point;
          }
        }
        const newEdge = this.addEdge(p1, p2, {
          normal: openingNormal,
          align,
          isInverted: isPointOnRay(p1.point, p1.entryNormal!, p2.point),
          siteUid: null,
          roomUid: "",
        });

        return { nodes: nodesToConnect, edge: newEdge };
      }

      return { nodes: nodesToConnect };
    }
  }

  writeToDebug(debugObj: RoomCalcDebug, color = "#0044FF") {
    let i = 0;
    const colors = ["#0044FF", "#0066EE", "#0088CC", "#00AA99", "#00CC77"];
    for (const edge of this.edgeList.values()) {
      i++;
      debugObj.push({
        chain: [edge.from.point, edge.to.point],
        color: colors[i % 5],
        dash: [1, 2 + (i % 5)],
        thickness: 10,
        role: "level-graph",
      });
    }
  }
}
