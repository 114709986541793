import { GetPressureLossOptions } from "../../calculations/entity-pressure-drops";
import {
  CoreContext,
  CostBreakdown,
  PressureLossResult,
} from "../../calculations/types";
import { makeCalculationFields } from "../../calculations/utils";
import { CalculationField } from "../../document/calculations-objects/calculation-field";
import {
  Calculation,
  LiveCalculation,
} from "../../document/calculations-objects/types";
import { CalculatableEntityConcrete } from "../../document/entities/concrete-entity";
import CoreBaseBackedObject from "./coreBaseBackedObject";

export abstract class CoreCalculatableObject<
  T extends CalculatableEntityConcrete = CalculatableEntityConcrete,
> extends CoreBaseBackedObject<T> {
  abstract getCalculationEntities(
    context: CoreContext,
  ): CalculatableEntityConcrete[];

  override get tags() {
    const tags = super.tags;
    tags.add("calculatable");
    return tags;
  }

  abstract collectCalculations(context: CoreContext): Calculation;
  abstract collectLiveCalculations(context: CoreContext): LiveCalculation;

  // Quantity, and string into index.
  abstract getFrictionPressureLossKPA(
    options: GetPressureLossOptions,
  ): PressureLossResult;

  getCalculationUid(_context: CoreContext): string {
    return this.entity.uid + ".calculation";
  }

  abstract costBreakdown(context: CoreContext): CostBreakdown | null;

  getCoreCalculationFields(levelUid: string | null): CalculationField[] {
    return makeCalculationFields(this.context, this.entity, levelUid);
  }
}
